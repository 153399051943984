<button
  tabindex="0"
  *ngIf="
    trueBlueSection.template === 'TRUEBLUE' &&
    window.location.href !== 'about:blank'
  "
  (click)="toggleSignInModal()"
  class="sign-in-button klarheit f6 fw6 white no-underline nowrap dib pointer pa0 relative"
>
  <span
    class="flex flex-column justify-start items-center w2 h2"
    *ngFor="let link of trueBlueSection.link"
  >
    <img
      [src]="link.image[0]?.src || ''"
      [alt]="link.image[0]?.alt"
      height="32"
      width="32"
    />
  </span>
  <hr
    [ngClass]="(isModalOpen$ | async) ? 'w-100' : 'w-0'"
    class="db absolute bottom-0 hr-underline"
  />
</button>

<div *ngIf="isModalOpen$ | async">
  <dot-tb-signed-out
    class="sign-in-modal bg-white"
    [content]="trueBlueSection.loggedOutSection"
  ></dot-tb-signed-out>
</div>
