import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JbIconModule } from 'jb-component-library';

import { LinkIconComponent } from './link-icon.component';
import { LinkIconDirective } from './link-icon.directive';

@NgModule({
  declarations: [LinkIconDirective, LinkIconComponent],
  imports: [CommonModule, JbIconModule],
  exports: [LinkIconDirective, LinkIconComponent],
  providers: [],
})
export class LinkIconModule {}
