import { HotelLocation } from '@core/hotel-location/hotel-location.type';
import { DateRange } from '@dynamic-components/booker-v2/shared/date-range-picker/types/date-range.type';
import { SelectedTravelers } from '@dynamic-components/booker-v2/shared/traveler-selector/types';

export const defaultTravelers = {
  adults: 2,
  children: 0,
  infants: 0,
  childAges: [],
};

export interface HotelBookingForm {
  location: HotelLocation;
  travelDates: DateRange;
  travelers: SelectedTravelers;
}
