<div class="jb-grid one-up grid-offset">
  <div [ngClass]="[classStyles]">
    <div class="flex flex-column mb3 mt4">
      <div
        class="ba"
        [ngStyle]="{
          'height.px': inputHeight,
          'border-color': boarderColor
        }"
      ></div>
    </div>
    <div class="flex flex-column mb2 mt2">
      <div
        class="ba"
        [ngStyle]="{
          'height.px': inputHeight,
          'border-color': boarderColor
        }"
      ></div>
    </div>
    <dot-text-block class="db mt3 mb4" [width]="35"></dot-text-block>
    <div class="db mv3">
      <button
        class="submit-button w-100-important"
        jbButton
        theme="ctaPrimary"
        [isRounded]="true"
        [isPaired]="false"
        type="button"
      >
        &nbsp;
      </button>
    </div>
  </div>
</div>
