import { Component } from '@angular/core';
import { JBLogoModule } from '@shared/jb-logo';

/**
 * @description Formatted full width header with jb logo
 * @WhereIsItUsed
 * - dot-okta-container
 * - manage-flights-email
 */
@Component({
  selector: 'dot-okta-header',
  template: `
    <div class="bg-core-blue ph5 w-100">
      <div
        class="flex justify-center justify-start-ns w-100 lh-solid-ns pa2 ph5-ns pv3-ns db"
      >
        <jb-logo class="db ph5"></jb-logo>
      </div>
    </div>
  `,
  standalone: true,
  imports: [JBLogoModule],
})
export class DotOktaHeaderComponent {}
