import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'jb-hero-skeleton',
  templateUrl: './hero-skeleton.component.html',
  styleUrls: ['./hero-skeleton.component.scss'],
})
export class HeroSkeletonComponent {
  @Input('titleOnly') public titleOnly = false;
}
