import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LinksModule } from '@shared/ui';

import { Link } from './../ui/links/types/link.type';
import { LinkTextConfig } from './config';
import { LinkText } from './types';
// TODO: determine if this can be deleted. Only used in bff which is set to be removed eventually
/**
 * @description Formatted string of text and links
 * @WhereIsItUsed Bff component
 */
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, LinksModule],
  selector: 'jb-link-text',
  templateUrl: './jb-link-text.component.html',
  styleUrls: ['./jb-link-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JBLinkTextComponent {
  @Input('linkTextContent')
  set linkTextContent(linkTextContent: LinkText) {
    this.textParts = linkTextContent.content.split(
      LinkTextConfig.linkReplaceSymbol,
    );
    this.textlinks = linkTextContent.link;
  }

  @Output() modalLinkClick: EventEmitter<any> = new EventEmitter();

  textParts: string[] = [];
  textlinks: Link[] = [];

  constructor(protected sanitizer: DomSanitizer) {}

  isShowInModal(textLink: Link) {
    return textLink.newWindow === LinkTextConfig.newWindowModalSymbol;
  }

  onModalLinkClick(index, event) {
    if (this.isShowInModal(this.textlinks[index])) {
      this.modalLinkClick.emit({
        link: this.textlinks[index],
        target: event.target,
      });
    }
  }
}
