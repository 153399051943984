import { Injectable } from '@angular/core';

import { StoreService } from '../shared/store.service';
import {
  CheckForItinerary,
  ItineraryError,
  ItineraryFound,
} from './itinerary.actions';
import * as ItinerarySelectors from './itinerary.selectors';
import { Flight } from './types';

@Injectable({ providedIn: 'root' })
export class ItineraryFacade {
  itineraryHasDispatched = false;
  itinerary = this.storeService.getState(ItinerarySelectors.itinerary);
  flight = this.storeService.getState(ItinerarySelectors.flight);
  upcomingFlightError$ = this.storeService.getState(
    ItinerarySelectors.upcomingFlightError,
  );
  constructor(private storeService: StoreService) {}

  checkForItinerary() {
    if (!this.itineraryHasDispatched) {
      this.storeService.dispatchAction(new CheckForItinerary());
      this.itineraryHasDispatched = true;
    }
  }

  itineraryFound(itinerary: Flight[]): ItineraryFound {
    return new ItineraryFound(itinerary);
  }

  itineraryError(error: object): object {
    return new ItineraryError(error);
  }
}
