import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';

import { PipesModule, UIModule } from '../ui';
import { JbContentPodComponent } from './jb-content-pod.component';

const MODULES = [CommonModule, UIModule, PipesModule, LinkIconModule];

const DECLARATIONS = [JbContentPodComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class JBContentPodModule {}
