import { Injectable } from '@angular/core';
import { DealsService } from '@core/deals';
import { HttpService } from '@core/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, share, switchMap, withLatestFrom } from 'rxjs/operators';

import { DealsActions, RequestLegalCopy } from './deals.actions';
import { DealsFacade } from './deals.facade';

@Injectable()
export class DealsEffects {
  constructor(
    private actions$: Actions,
    private dealsFacade: DealsFacade,
    private dealsService: DealsService,
  ) {}

  requestLegalCopy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RequestLegalCopy>(DealsActions.REQUEST_LEGAL_COPY),
        withLatestFrom(this.dealsFacade.legalCopy),
        filter(([_, legalCopy]) => !legalCopy),
        switchMap(() => this.dealsService.getLegalCopy()),
        share(),
      ),
    { dispatch: false },
  );

  requestLegalCopySuccess$ = createEffect(() =>
    this.requestLegalCopy$.pipe(
      filter(HttpService.isSuccessful),
      map(payload => this.dealsFacade.setLegalCopy(payload)),
    ),
  );
}
