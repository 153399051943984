import { CookieStorageService } from '@core/storage';
import { AuthProfile } from '@store/auth/types';

import { booleanStringCheck } from './boolean-string-check';

/**
 * Function that register Logs into Full Story
 * @param {string} eventName The name of the event that will be registered on FS.
 * @param {{}} eventProperties Properties set on FS for each Event.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function registerLogFullStory(
  eventName: string,
  eventProperties = {},
  fsWindow: Window,
): void {
  if (isSSR()) {
    return;
  }

  if (fsWindow['FS']) {
    fsWindow['FS'].event(eventName, eventProperties);
  }
}

/**
 * FullStory is an asynchronous script and for some cases, we must wait it to load before trying to register a log.
 * Use this function when you need to register a log BEFORE any user interaction.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function waitForFullStory(fsWindow: Window) {
  if (isSSR()) {
    return Promise.resolve(null);
  }
  return new Promise<void>((resolve, reject) => {
    let attempts = 0;
    const fsInterval = setInterval(() => {
      if (fsWindow['FS']) {
        clearInterval(fsInterval);
        resolve();
      } else {
        attempts++;
      }
      if (attempts > 50) {
        clearInterval(fsInterval);
        reject();
      }
    }, 250);
  });
}

/**
 * Function that register user identity
 * @param {AuthProfile} authProfile Auth Profile Data.
 * @param {number} travelBankBalance User travel balance.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function fullstoryIdentify(
  membershipid: string,
  travelBankBalance: number,
  fsWindow: Window,
  authProfile?: AuthProfile,
) {
  try {
    if (isSSR()) {
      return;
    }
    if (
      membershipid?.toLowerCase() === 'guest' ||
      !fsWindow['FS'] ||
      !membershipid
    ) {
      return;
    }

    if (authProfile) {
      const identifyData = mapAuthProfileToFSData(
        authProfile,
        travelBankBalance,
      );
      fsWindow['FS'].identify(membershipid, identifyData);
    } else {
      fsWindow['FS'].identify(membershipid);
    }
  } catch (_) {
    return;
  }
}

/**
 * Function that Anonymize authenticated users from Full Story
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function anonymizeUserFullStory(
  fsWindow: Window,
  cookieStorageService: CookieStorageService,
): void {
  if (isSSR()) {
    return;
  }
  cookieStorageService.removeCookie('jbFSIdentify');
  if (fsWindow['FS']) {
    fsWindow['FS'].anonymize();
  }
}

function isSSR(): boolean {
  return typeof window === 'undefined' ? true : false;
}

export function mapAuthProfileToFSData(
  authProfile: AuthProfile,
  travelBankBalance: number,
) {
  const {
    fname = '',
    lname = '',
    age = 0,
    cardStatus = [],
    isMosaic = 'false',
    mosaicTier = 'TrueBlue',
    points = 0,
  } = authProfile || {};

  const trueBlueStatus = booleanStringCheck(isMosaic) ? mosaicTier : 'TrueBlue';
  const pointsBalance = points ? Number(points) : null;

  const identifyData = {
    displayName: `${fname} ${lname}`,
    TrueBlueStatus: trueBlueStatus,
    age: age,
    pointsBalance,
    travelBankBalance,
    cardType: cardStatus && cardStatus?.[0],
  };
  return identifyData;
}
