import {
  FooterResponse,
  FooterResponseRaw,
} from '@core/cms/types/cms-footer.response.type';
import { HeaderResponse } from '@core/cms/types/cms-header.response.type';
import { CmsTemplateRouteData } from '@core/index';
import { Action } from '@ngrx/store';
import {
  HeaderInnerType,
  TravelAlerts,
} from '@shared/header/types/header.inner.type';
import { Image } from '@shared/types';

import { GlobalComponentState } from './types';

export enum GlobalActions {
  CHOOSE_GLOBAL_COMPONENT_BACKGROUND_URL = '[Global] Choose Component Background URL',
  FETCH_GLOBAL_COMPONENT_BACKGROUND_URL = '[Global] Fetch Component Background URL',
  LOAD_FOOTER = '[CMS API] Load Footer',
  LOAD_FOOTER_RAW = '[CMS API] Load Footer Raw',
  LOAD_FOOTER_TRANSLATIONS = '[TRANSLATION API] Load Footer Translations',
  LOAD_HEADER = '[CMS API] Load Header',
  LOAD_HEADER_RAW = '[CMS API] Load Header Raw',
  LOAD_HEADER_TRANSLATIONS = '[TRANSLATION API] Load Header Translations',
  LOAD_TEMPLATE = '[CMS API] Load Template',
  REQUEST_FOOTER = '[NgRx Init] Request Footer',
  REQUEST_HEADER = '[NgRx Init] Request Header',
  REQUEST_TEMPLATE = '[Router Effect] Request Template',
  RESOLVE_FALLBACK_FOOTER = '[Global] Resolve Fallback Footer',
  RESOLVE_FALLBACK_HEADER = '[Global] Resolve Fallback Header',
  RESOLVE_FALLBACK_TEMPLATE = '[Global] Resolve Fallback Template',
  SET_GLOBAL_COMPONENT_CONFIG = '[Global] Set Component Config',
  SET_GLOBAL_HEADER_TRAVEL_ALERTS_CONTENT = '[Global] Set Header Travel Alerts Content',
  SET_LAST_REQUESTED_ENDPOINT = '[Root Template || Router Effect] Set Last Requested Endpoint',
  SET_PRIMARY_TEMPLATE_RENDERING_STATUS = 'Set Primary Template Rendering Status',
  SET_RENDERED_HEADER = 'Set Rendered Header Status',
  SET_SECONDARY_TEMPLATE_RENDERING_STATUS = 'Set Secondary Template Rendering Status',
  SET_TERTIARY_TEMPLATE_RENDERING_STATUS = 'Set Tertiary Template Rendering Status',
}

export class SetGlobalComponentConfig implements Action {
  readonly type = GlobalActions.SET_GLOBAL_COMPONENT_CONFIG;
  constructor(public payload: GlobalComponentState) {}
}

export class FetchGlobalComponentBackgroundUrl implements Action {
  readonly type = GlobalActions.FETCH_GLOBAL_COMPONENT_BACKGROUND_URL;
}

export class ChooseGlobalComponentBackgroundUrl implements Action {
  readonly type = GlobalActions.CHOOSE_GLOBAL_COMPONENT_BACKGROUND_URL;
  constructor(public payload: Image[]) {}
}

export class SetHeaderTravelAlertsContent implements Action {
  readonly type = GlobalActions.SET_GLOBAL_HEADER_TRAVEL_ALERTS_CONTENT;
  constructor(public payload: TravelAlerts) {}
}

export class RequestHeader implements Action {
  readonly type = GlobalActions.REQUEST_HEADER;
  constructor(public payload: CmsTemplateRouteData) {}
}

export class LoadHeader implements Action {
  readonly type = GlobalActions.LOAD_HEADER;
  constructor(public payload: HeaderInnerType) {}
}

export class LoadHeaderRaw implements Action {
  readonly type = GlobalActions.LOAD_HEADER_RAW;
  constructor(public payload: HeaderResponse) {}
}

export class RequestFooter implements Action {
  readonly type = GlobalActions.REQUEST_FOOTER;
  constructor(public payload: CmsTemplateRouteData) {} // payload required for personalizations
}

export class LoadFooter implements Action {
  readonly type = GlobalActions.LOAD_FOOTER;
  constructor(public payload: FooterResponse) {}
}

export class LoadFooterRaw implements Action {
  readonly type = GlobalActions.LOAD_FOOTER_RAW;
  constructor(public payload: FooterResponseRaw) {}
}

export class LoadHeaderTranslation implements Action {
  readonly type = GlobalActions.LOAD_HEADER_TRANSLATIONS;
  constructor(public payload: Partial<HeaderResponse>) {}
}

export class LoadFooterTranslation implements Action {
  readonly type = GlobalActions.LOAD_FOOTER_TRANSLATIONS;
  constructor(public payload: Partial<FooterResponseRaw>) {}
}

export class RequestTemplate implements Action {
  readonly type = GlobalActions.REQUEST_TEMPLATE;
  constructor(public payload: any) {}
}

export class LoadTemplate implements Action {
  readonly type = GlobalActions.LOAD_TEMPLATE;
  constructor(public payload: any) {}
}

export class ResolveFallbackTemplate implements Action {
  readonly type = GlobalActions.RESOLVE_FALLBACK_TEMPLATE;
  constructor(public payload: any) {}
}

export class ResolveFallbackHeader implements Action {
  readonly type = GlobalActions.RESOLVE_FALLBACK_HEADER;
  constructor(public payload: any) {}
}

export class ResolveFallbackFooter implements Action {
  readonly type = GlobalActions.RESOLVE_FALLBACK_FOOTER;
  constructor(public payload: any) {}
}

export class SetLastRequestedEndpoint implements Action {
  readonly type = GlobalActions.SET_LAST_REQUESTED_ENDPOINT;
  constructor(public payload: string) {}
}

export class SetPrimaryRenderingStatus implements Action {
  readonly type = GlobalActions.SET_PRIMARY_TEMPLATE_RENDERING_STATUS;
  constructor(public payload: boolean) {}
}

export class SetSecondaryRenderingStatus implements Action {
  readonly type = GlobalActions.SET_SECONDARY_TEMPLATE_RENDERING_STATUS;
  constructor(public payload: boolean) {}
}

export class SetTertiaryRenderingStatus implements Action {
  readonly type = GlobalActions.SET_TERTIARY_TEMPLATE_RENDERING_STATUS;
  constructor(public payload: boolean) {}
}

export class SetRenderedHeader implements Action {
  readonly type = GlobalActions.SET_RENDERED_HEADER;
  constructor(public payload: boolean) {}
}

export type GlobalConfigActionsUnion =
  | ChooseGlobalComponentBackgroundUrl
  | FetchGlobalComponentBackgroundUrl
  | LoadFooter
  | LoadFooterRaw
  | LoadFooterTranslation
  | LoadHeader
  | LoadHeaderRaw
  | LoadHeaderTranslation
  | LoadTemplate
  | RequestFooter
  | RequestHeader
  | RequestTemplate
  | ResolveFallbackFooter
  | ResolveFallbackHeader
  | ResolveFallbackTemplate
  | SetGlobalComponentConfig
  | SetHeaderTravelAlertsContent
  | SetLastRequestedEndpoint
  | SetPrimaryRenderingStatus
  | SetRenderedHeader
  | SetSecondaryRenderingStatus
  | SetTertiaryRenderingStatus;
