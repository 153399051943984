import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotCapitalizeFlightStatus',
})
export class DotCapitalizeFlightStatusPipe implements PipeTransform {
  transform(
    value: string,
    originAndDestinationAirport: { origin: string; destination: string },
    diversion: boolean = false,
  ): string {
    if (!value || value.length <= 1) {
      return value || '';
    }
    const result = [];
    let capitalizeNext = false;
    let index = 0;
    for (const char of value) {
      if (['&', '<', ':', '-'].includes(char)) {
        // Case of 'Pre-boarding' vs '<Station code> - Diversion'
        if (
          char === '-' &&
          value.length > index + 1 &&
          value.charAt(index + 1) !== ' '
        ) {
          capitalizeNext = false;
        } else {
          capitalizeNext = true;
        }
        result.push(char);
      } else if (capitalizeNext) {
        result.push(char.toUpperCase());
        if (char !== ' ') {
          capitalizeNext = false;
        }
      } else {
        result.push(char.toLowerCase());
      }
      index++;
    }
    // Capitalize first letter and last letter if last letter is a single character with a space before it
    if (result.length > 1 && result[result.length - 2] === ' ') {
      result[result.length - 1] = result[result.length - 1].toUpperCase();
    }
    const stringResult = result.join('');
    if (diversion) {
      return capitalizeDivertedAirportCode(stringResult);
    } else {
      return capitalizeOriginAndDestination(
        stringResult.charAt(0).toUpperCase() + stringResult.substring(1),
        originAndDestinationAirport,
      );
    }
  }
}

function capitalizeOriginAndDestination(
  str: string,
  airport: { origin: string; destination: string },
): string {
  let result = str;
  const originLower = airport.origin.toLowerCase();
  const destinationLower = airport.destination.toLowerCase();
  if (result.includes(originLower)) {
    result = result.replace(originLower, originLower.toUpperCase());
  }
  if (result.includes(destinationLower)) {
    result = result.replace(destinationLower, destinationLower.toUpperCase());
  }
  return result;
}

function capitalizeDivertedAirportCode(divertedFlightStatus: string) {
  const parts = divertedFlightStatus.split(' ');
  if (parts.length) {
    parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].substring(1);
    parts[2] = parts[2].toUpperCase();
  }
  return parts.join(' ');
}
