/**
 * Function that deep merge object 1 into object 2
 * @param {any} obj1 Object 1 that will be merged into Object 2.
 * @param {any} obj2 Object 2 that will be merged into Object 1.
 * @returns {any} merged objects
 */
export function deepMerge(obj1, obj2 = {}): any {
  const result = { ...obj1 };
  for (const key in obj1) {
    if (obj2?.hasOwnProperty(key)) {
      if (typeof obj2[key] === 'object') {
        if (Array.isArray(obj2[key])) {
          result[key] = obj2[key].map((item, index) => {
            if (Array.isArray(item) || typeof item === 'object') {
              return deepMerge(obj1[key][index], item);
            } else {
              return item;
            }
          });
        } else {
          result[key] = deepMerge(obj1[key], obj2[key]);
        }
      } else {
        result[key] = obj2[key];
      }
    }
  }
  return result;
}
