import {
  DestroyRef,
  Directive,
  HostListener,
  Inject,
  inject,
  Input,
  NgZone,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StickyHeaders } from '@core/document-actions/types';
import {
  AppConfigService,
  DOCUMENT,
  DocumentService,
  WINDOW,
  WindowService,
} from '@core/index';
import { RouterFacade } from '@store/router/router.facade';

@Directive({
  selector: '[jbFocusOn]',
})
export class FocusOnDirective {
  private _destroy = inject(DestroyRef);

  @Input('skipToMain') skipToMain = false;
  @Input('moveFocusTo') moveFocusTo = '';
  @Input('scroll') scroll = false;
  @Input('firstFocusable') firstFocusable = false;
  @Input('sectionHTML') sectionHTML;
  stickyHeaders: StickyHeaders;
  homePage;
  isSignInPage;
  bffPage;
  element;
  isMobile: boolean;
  stickyHeaderHeight: number;
  scrollTo: number;
  elementOffsetTop: number;

  constructor(
    private routerFacade: RouterFacade,
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) private window,
    private ngZone: NgZone,
    private documentService: DocumentService,
    private windowService: WindowService,
    private appConfigService: AppConfigService,
  ) {
    this.routerFacade.isHomePage
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(isHomePage => (this.homePage = isHomePage));

    this.routerFacade.isSignInPage
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(isSignInPage => (this.isSignInPage = isSignInPage));

    this.routerFacade.isBffPage
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(isBffPage => (this.bffPage = isBffPage));

    this.windowService.isMobile
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(mobile => {
        this.isMobile = mobile;
      });
  }

  getStickyHeadersHeight(): void {
    this.stickyHeaders = this.documentService.getStickyHeaders();
    if (!!this.stickyHeaders) {
      this.stickyHeaderHeight = this.isMobile
        ? this.stickyHeaders.mobile.offsetHeight
        : this.stickyHeaders.desktop.offsetHeight;
    }
  }

  @HostListener('click', ['$event'])
  moveFocus(): void {
    if (
      this.moveFocusTo.length === 0 &&
      !this.sectionHTML &&
      !this.skipToMain
    ) {
      return;
    }
    if (!this.sectionHTML) {
      this.element =
        !this.homePage && this.skipToMain
          ? this.document.querySelector('h1')
          : this.document.querySelector(`#${this.moveFocusTo}`);
      if (this.bffPage) {
        this.element = this.document.querySelector(`#${this.moveFocusTo}`);
      }

      if (this.isSignInPage) {
        this.element = this.appConfigService.useWidget
          ? this.document.querySelector(`#${this.moveFocusTo}`)
          : this.document.querySelector('h1');
      }
    } else {
      this.element = this.sectionHTML;
    }

    if (this.firstFocusable) {
      this.element = this.element.querySelector(
        'button, [href], input, select, textarea, [tabindex]',
      );
    } else {
      this.element.setAttribute('tabindex', '-1');
    }

    if (this.scroll) {
      this.getStickyHeadersHeight();
      this.elementOffsetTop = this.element.getBoundingClientRect().top;
      const top = this.elementOffsetTop - this.stickyHeaderHeight;
      // position of an element within a window, despite fixed positioned elements
      this.scrollTo = top + this.window.pageYOffset;
      // smoth scroll to an element
      this.ngZone.runOutsideAngular(this.scrollToElement);
    } else {
      setTimeout(() => {
        this.element.focus();
      }, 10);
    }
  }

  scrollToElement = (): void => {
    this.window.scroll({
      top: this.scrollTo,
      behavior: 'smooth',
    });
    setTimeout(() => {
      this.element.focus({ preventScroll: true });
    }, 500);
  };
}
