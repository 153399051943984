import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
// @ts-ignore
import truncatedTemplate from '@core/cms/fixtures/truncated-bookerAir.json';
// @ts-ignore
import templateSchema from '@core/cms/validation/schemas/template.json';
import { HttpService } from '@core/http';
import { networkError } from '@store/shared/action.utils';
import Ajv from 'ajv';
import { of as observableOf } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export const SESSION_KEY_RETRIES = 'jb-request-retries';
const TO_VALIDATE = ['bookerAir'];
const ajv = new Ajv();

@Injectable({
  providedIn: 'root',
})
export class CmsValidationService {
  $cms_endpoint;
  constructor(
    public appConfig: AppConfigService,
    private httpService: HttpService,
  ) {}

  get isTruncatedDetectionEnabled() {
    return this.appConfig.truncatedResponseRetries;
  }

  get isCdnPurgeComplete() {
    const jbRequestRetries = sessionStorage.getItem('jb-request-retries');
    const jbCacheCleared = sessionStorage.getItem('jb-cache-cleared');
    return jbRequestRetries && jbCacheCleared && jbCacheCleared === '1';
  }

  purgeCdnCache() {
    return this.httpService.post(this.appConfig.purgeCdnUrl, {}).pipe(
      catchError(() => {
        return observableOf(networkError());
      }),
    );
  }

  validateNodeAndRetry(component, sessionKey) {
    const jbCacheCleared = sessionStorage.getItem('jb-cache-cleared');
    if (!jbCacheCleared) {
      sessionStorage.setItem('jb-cache-cleared', '0');
    }
    if (TO_VALIDATE.includes(component.name)) {
      const validate = ajv.compile(templateSchema);
      const valid = validate(component.data);
      if (!valid) {
        const isCachePurged = +jbCacheCleared;
        if (!isCachePurged) {
          const retryAfterPurge = this.purgeCdnCache().pipe(
            tap(() => {
              sessionStorage.setItem('jb-cache-cleared', '1');
              this.retry(component, sessionKey);
            }),
          );
          retryAfterPurge.subscribe();
        } else {
          this.retry(component, sessionKey);
        }
      }
    }
  }

  retry(component, sessionKey) {
    const retries = Number(sessionStorage.getItem(sessionKey)) || 0;
    console.error(
      `${component.name} is invalid, attempting to fetch template again`,
    );
    if (retries < this.appConfig.truncatedResponseRetries) {
      console.info(`executing template retrieval retry ${retries + 1}`);
      const retriesParsed = String(retries + 1);
      sessionStorage.setItem(sessionKey, retriesParsed);
      window.location.reload();
    }
  }

  get cmsUrlWithTimeQuery() {
    return this.$cms_endpoint;
  }

  set cmsUrlWithTimeQuery(url) {
    const retriesSessionProperty = sessionStorage.getItem(SESSION_KEY_RETRIES);
    const query = `time=${Date.now()}`;
    if (retriesSessionProperty) {
      this.$cms_endpoint = url.includes('?')
        ? `${url}&${query}`
        : `${url}?${query}`;
    } else {
      this.$cms_endpoint = url;
    }
  }

  testTruncatedResponse(response, template, endpoint) {
    const detectTruncatedResponse = this.appConfig.truncatedResponseRetries;
    if (detectTruncatedResponse) {
      const retries = +sessionStorage.getItem(SESSION_KEY_RETRIES);
      if (retries === detectTruncatedResponse) {
        response.templateName = template;
        response.endpoint = endpoint;
        return response;
      } else {
        truncatedTemplate.templateName = template;
        truncatedTemplate.endpoint = endpoint;
        return truncatedTemplate;
      }
    } else {
      response.templateName = template;
      response.endpoint = endpoint;
      return response;
    }
  }
}
