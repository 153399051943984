import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { AppConfigService } from '@core/index';
import { WidgetService } from '@core/widgets/widget.service';
import { Theme } from '@store/global/types/layout-theme.enum';

import { BodyTextImage } from './types';

/**
 * @description Formatted container for a text and image element
 * @WhereIsItUsed
 * - body-text-image-container
 * - not-found-page
 * - calendar-error
 * - calendar-no-flights
 * - accordion-container
 */
@Component({
  selector: 'jb-text-image',
  templateUrl: './jb-text-image.component.html',
  styleUrls: ['./jb-text-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JBTextImageComponent {
  hasImageHeightWidthAttr: boolean;
  enableLazyLoadImage: boolean;
  placeholderParams: string;
  @Input('content') bodyTextImage: BodyTextImage[];
  // This input is for grid alignment when inside an accordion
  @Input('insideAccordion') insideAccordion = false;
  themeEnum = Theme;
  @Input('theme') theme = this.themeEnum.light;
  @HostBinding('class.dark-theme') get color() {
    return this.theme === this.themeEnum.dark;
  }

  constructor(
    private widgetService: WidgetService,
    private appConfig: AppConfigService,
  ) {
    this.hasImageHeightWidthAttr = this.appConfig.imageHeightWidthAttr;
    this.enableLazyLoadImage = this.appConfig.lazyLoadImages;
    this.placeholderParams = this.appConfig.paths[0].placeholderImageParams;
  }

  onClick(event: Event, showChat, isCookieConsent): void {
    if (showChat === 'true') {
      event.preventDefault();
      this.widgetService.openASAPP();
    }

    if (isCookieConsent === 'true') {
      this.widgetService.openCookieConsent();
    }
  }
}
