<div
  *ngIf="!!profile"
  class="new-header profile-wrapper relative-ns h-100 w-100 fr-ns flex items-center-ns dib-ns"
  jbAnchor
  #anchorSignedIn="jbAnchor"
  [flyout]="flyoutSignedIn"
>
  <a
    (click)="anchorSignedIn.toggle()"
    href="javascript:void(0);"
    class="w-100 flex items-center white no-underline profile-container"
  >
    <div
      class="flex justify-end items-center mr0-ns"
      [ngClass]="{ nt2: isMobile, mr1: !isMobile }"
    >
      <jb-icon
        *ngIf="profile.isMosaic === 'true'"
        name="avatarMosaicStatus"
        class="nr2 z-2 mosaic-logo mt3"
        width="22"
        label="mosaic"
        data-qaid="mosaicStatus"
      >
      </jb-icon>
      <div
        class="flex justify-center z-1 white overflow-hidden f5 mr0-m bg-bright-blue br-100 mt2 mt0-ns"
      >
        <jb-avatar
          *ngIf="!(profilePicture$ | async) && !profile.photoUrl"
          initials="{{ firstNameInitial }}{{ lastNameInitial }}"
          size="small"
        >
        </jb-avatar>
        <jb-avatar
          *ngIf="(profilePicture$ | async) || profile.photoUrl as pic"
          [src]="pic"
          size="small"
        >
        </jb-avatar>
      </div>
    </div>
    <p class="pl2 mb0 klarheit f6 fw6 lh-copy dn-m db-l" *ngIf="!isMobile">
      {{ points }} {{ content?.topSection?.children?.pts }}
    </p>
  </a>
  <jb-flyout
    #flyoutSignedIn
    [placement]="'bottom-end'"
    [minWidth]="375"
    [fullscreenOnPhone]="isMobile"
    class="jb-shadow-1"
    [minIsAnchorWidth]="false"
  >
    <div class="scrollable">
      <dot-tb-signed-in
        class="db"
        [trueBlueLoggedInSection]="content"
        (closeEvent)="closeModal($event)"
      ></dot-tb-signed-in>
    </div>
  </jb-flyout>
  <hr
    [ngClass]="(newHeaderIsOpen$ | async) ? 'w-100 active' : 'w-0'"
    class="absolute bottom-0 hr-underline"
  />
</div>
