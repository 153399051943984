import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dynamicImageWidthPipe',
})
export class DynamicImageWidthPipe implements PipeTransform {
  transform(
    baseUrl: string,
    template: string,
    placeholderParams?: string,
  ): string {
    if (!baseUrl) return '';
    switch (template) {
      case 'background':
        return baseUrl + '?width=1440';
      case 'overlay':
        return baseUrl + '?width=1120';
      case 'image':
        return baseUrl + '?width=764';
      case 'routemap':
        return baseUrl + '?width=224';
      case 'placeholder':
        return (
          baseUrl +
          '?' +
          (placeholderParams || 'width=60&blur=30&brightness=30')
        );
      default:
        return baseUrl;
    }
  }
}
