import { DealsActions } from './deals.actions';
import { DealsState } from './types/deals-state.type';

export const initialDealsConfigState: DealsState = {
  legalCopy: undefined,
};

export function dealsReducer(state: any, action: any): any {
  switch (action.type) {
    case DealsActions.SET_LEGAL_COPY:
      return {
        ...state,
        legalCopy: action.payload,
      };

    default:
      return state;
  }
}
