import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * @description Block of hard coded text explaining taxes and fees.
 * @WhereIsItUsed bff-taxes-fees-dialog
 */
@Component({
  selector: 'jb-taxes-fees-content',
  templateUrl: './taxes-fees-content.component.html',
  styleUrls: ['./taxes-fees-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxesFeesContentComponent {}
