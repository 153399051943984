import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StaticBookerPodModule } from '@shared/static-booker-pod';
import { JbRadioModule } from 'jb-component-library';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
} from 'ng-recaptcha';

import { DialogTempModule } from '../dialog';
import { ENV_CONFIG } from './../../core/injection-tokens';
import { captchaFactory, configFactory } from './../../global-factories';
import { JBBookerAirWarningDialogModule } from './submits/booker-air-submit/booker-air-warning-dialog';
import { JBBookerVacationWarningDialogModule } from './submits/booker-vacation-submit/booker-vacation-warning-dialog';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  DialogTempModule,
  JbRadioModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  JBBookerAirWarningDialogModule,
  JBBookerVacationWarningDialogModule,
  StaticBookerPodModule,
];

const DECLARATIONS = [];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
  providers: [
    { provide: ENV_CONFIG, useFactory: configFactory },
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: captchaFactory,
      deps: [ENV_CONFIG],
    },
  ],
})
export class BookerModule {}
