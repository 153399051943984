<div class="pt3">
  <!-- <h1 class="din db mb3 mt5 royal-blue ">Taxes and Fees</h1>  -->
  <h2 class="mb0 klarheit db royal-blue l-body">
    For domestic travel within the Continental United States:
  </h2>
  <ul class="s-body mv0 ph4">
    <li>Passenger Facility Charges of up to $9.00 each way</li>
    <li>September 11 Security Fees of up to $11.20 each way</li>
    <li>
      Domestic Segment Tax of $4.10 per domestic segment (a segment is one
      take-off and landing).
    </li>
    <li>
      U.S. government excise tax of $9.00 per departure for Alaska and Hawaii
      travel.
    </li>
    <li>
      US domestic transportation tax of 7.5% of the base fare for travel within
      the Continental United States.
    </li>
  </ul>
  <h2 class="mt2 mb0 klarheit db royal-blue l-body">
    For travel between the United States and Mexico:
  </h2>
  <ul class="s-body mv0 ph4">
    <li>Mexican Tourism Tax of 306 MXN applies.</li>
    <li>Airport Departure Tax (In'tl) ~ 408 MXN (frequently fluctuates)</li>
  </ul>
  <h2 class="mt2 mb0 klarheit db royal-blue l-body">
    For travel between the Continental United States and Puerto Rico:
  </h2>
  <ul class="s-body mv0 ph4">
    <li>Passenger Facility Charges of up to $9.00 each way</li>
    <li>September 11 Security Fees of up to $11.20 each way</li>
    <li>US Transportation Tax of $18.00 each way.</li>
  </ul>
  <h2 class="mt2 mb0 klarheit db royal-blue l-body">
    For travel between the United States and international locations:
  </h2>
  <ul class="s-body mv0 ph4">
    <li>Passenger Facility Charges of up to $9.00 each way.</li>
    <li>
      September 11 Security Fees of up to $11.20 each way on U.S. originating
      flights, and on the domestic leg of an international one-way trip
      originating outside the U.S.
    </li>
    <li>US Transportation Tax of $18.00 each way</li>
    <li>US Dept. of Agriculture APHIS User Fee of $3.96.</li>
    <li>US Federal Inspection Fee of $7.00.</li>
    <li>US Customs User Fee of $5.50.</li>
    <li>
      Non-U.S. government taxes of up to $120.80 each way, excluding VAT
      (below).
    </li>
    <li>
      For travel between the United States and Africa, carrier imposed
      surcharges of up to $304 each way
    </li>
  </ul>
  <h2 class="mt2 klarheit db royal-blue l-body">
    VAT of up to 18% of the base fare depending on international destination.
    Non-US Value Added Taxes based on itinerary are as follows:
  </h2>
  <table class="mt3 w-100 w-70-m w-60-l" border="0">
    <thead>
      <tr class="bg-medium-gray">
        <th scope="col">Country of Originating travel</th>
        <th scope="col">VAT</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">Barbados</th>
        <td><strong>17.5%</strong> of base fare</td>
      </tr>
      <tr class="bg-medium-gray">
        <th scope="row">Colombia</th>
        <td><strong>16%</strong> of Base Fare</td>
      </tr>
      <tr>
        <th scope="row">Mexico</th>
        <td><strong>4%</strong> of Base Fare</td>
      </tr>
      <tr class="bg-medium-gray">
        <th scope="row">Dominican Republic&nbsp;</th>
        <td><strong>18%</strong> of Base Fare</td>
      </tr>
      <tr>
        <th scope="row">Costa Rica</th>
        <td><strong>5%</strong> of Base Fare</td>
      </tr>
      <tr class="bg-medium-gray">
        <th scope="row">Saint Lucia</th>
        <td><strong>7.5%</strong> of Base Fare</td>
      </tr>
      <tr>
        <th scope="row">Peru</th>
        <td><strong>18%</strong> of Base Fare</td>
      </tr>
      <tr class="bg-medium-gray">
        <th scope="row">Haiti</th>
        <td><strong>10%</strong> of Base Fare</td>
      </tr>
      <tr>
        <th scope="row">Trinidad</th>
        <td><strong>15%</strong> of Base Fare</td>
      </tr>
      <tr class="bg-medium-gray">
        <th scope="row">South Africa</th>
        <td><strong>14%</strong> of Base Fare</td>
      </tr>
      <tr>
        <th scope="row">Senegal</th>
        <td><strong>18%</strong> of Base Fare</td>
      </tr>
    </tbody>
  </table>

  <p class="mt4 mb0 s-body">
    When canceling one flight of an international itinerary, you may be subject
    to an increase in the amount of taxes owed.
  </p>
</div>
