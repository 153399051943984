import { NavigationExtras, Router } from '@angular/router';
import { EventsService } from '@core/events';
import { InjectionTokenIsWebComponent } from '@core/injection-tokens';

// these links are part of static routes on the frontend and do not exist in the sitemap dictionary
export function checkHardcodedPaths(path: string) {
  return path.includes('preference-center');
}

export const removeLanguageFromPath = (path: string) => {
  // if there is are at least two path segments, check if the first segment is a language code
  // if so, remove it for the sitemap lookup, if you split a path that starts with /, you get an empty string as the first element
  return path.split('/').length > 2 && path.split('/')[1].length === 2
    ? path
        .split('/')
        .filter((segment, index) => index !== 1)
        .join('/')
    : path;
};

// todo: update this function with a referenced list of supported language codes
export function removeLanguageFromHomePath(path: string) {
  const pathSegments = path.split('/');
  return pathSegments.length <= 2 && path.split('/')[1].length === 2
    ? '/'
    : path;
}

export function getPathIfInSitemap(
  url: URL,
  sitemapDictionary: { [key: string]: string },
  host: string,
): string | undefined {
  // if the url starts with a language code, remove it for the sitemap lookup
  const cleanPath = removeLanguageFromPath(url.pathname.replace(/\/$/, ''));
  const isHostOrigin = url.origin === host;
  const isHomepage =
    removeLanguageFromHomePath(url.pathname) === '/' && isHostOrigin;
  const path = isHomepage
    ? removeLanguageFromHomePath(url.pathname)
    : cleanPath;
  const pathExistsInSitemap =
    checkHardcodedPaths(path) || sitemapDictionary[path] !== undefined;
  return pathExistsInSitemap ? url.pathname : undefined;
}

export function keepOnlyRelativePathIfInSitemap(
  url: URL,
  sitemapDictionary: { [key: string]: string },
  host: string,
): string {
  return getPathIfInSitemap(url, sitemapDictionary, host)
    ? (url.pathname + url.search).replace(host, '')
    : undefined;
}

export const splitUrlParamsFromString = (
  url: string,
): { urlPath: string; urlParams: string } => {
  const urlSplit = (url || '').split('?');
  const urlPath = urlSplit[0];
  const urlParams = urlSplit[1];
  return { urlPath, urlParams };
};

export const getQueryParametersAsObject = (
  urlString: string,
): { [key: string]: string } => {
  const { urlParams } = splitUrlParamsFromString(urlString);
  return urlParams
    ? urlParams.split('&').reduce((acc, param) => {
        const [key, value] = param.split('=');
        return { ...acc, [key]: value };
      }, {})
    : {};
};

export const removeParams = (urlString: string): string => {
  const { urlPath } = splitUrlParamsFromString(urlString);
  return urlPath;
};

// Extracted from and used by sitemap-linker and sitemap-nested-linker directives
export const handleRoute = (
  isWebComponent: InjectionTokenIsWebComponent,
  eventService: EventsService,
  href: string,
  routerLink: string,
  url: URL,
  router: Router,
  nestedLinkerProps?: { event: MouseEvent; window: any; safeRel: string },
): void => {
  if (isWebComponent === 'header') {
    eventService.dispatchCustomEvent(
      EventsService.CUSTOM_EVENTS.JB_HEADER_LINK_CLICKED_OUTPUT_EVENT,
      {
        href,
        routerLink,
      },
    );
    eventService.dispatchCustomEvent('click');
    return;
  }
  if (isWebComponent === 'footer') {
    eventService.dispatchCustomEvent(
      EventsService.CUSTOM_EVENTS.JB_FOOTER_LINK_CLICKED_OUTPUT_EVENT,
      {
        href,
        routerLink,
      },
    );
    return;
  }
  if (routerLink) {
    if (nestedLinkerProps) {
      const { event, window, safeRel } = nestedLinkerProps;
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        window.open(routerLink, '_blank', safeRel);
        return;
      }
    }
    const extras: NavigationExtras = { queryParams: {} };
    if (url) {
      url.searchParams.forEach(
        (value, key) => (extras.queryParams[key] = value),
      );
    }
    if (url.hash) {
      extras.fragment = url.hash.replace('#', '');
    }
    router.navigate([`${routerLink}`], extras);
  }
};
