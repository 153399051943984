import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/injection-tokens';
import { registerLogFullStory } from '@shared/ui/utils/global-utils/full-story';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FullstoryErrorReporterInterceptor implements HttpInterceptor {
  constructor(@Inject(WINDOW) private window) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        registerLogFullStory(
          'API Error',
          {
            api_str: request?.urlWithParams,
            status_code_int: error?.status,
            status_message_str: error?.statusText,
            message: error?.message,
          },
          this.window,
        );
        return throwError(error);
      }),
    );
  }
}
