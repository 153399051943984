import { Action } from '../shared/types/action.interface';
import { ScheduleExtension } from './types';

export enum ScheduleExtensionActions {
  REQUEST_EXTENSION_DATE = '[NgRx Init] Extension Date Requested',
  LOAD_EXTENSION_DATE = '[Schedule Extension API] Extension Date Loaded',
}

export class RequestExtensionDate implements Action {
  readonly type = ScheduleExtensionActions.REQUEST_EXTENSION_DATE;
}

export class LoadExtensionDate implements Action {
  readonly type = ScheduleExtensionActions.LOAD_EXTENSION_DATE;
  constructor(public payload: ScheduleExtension) {}
}

export type ScheduleExtensionActionsUnion =
  | RequestExtensionDate
  | LoadExtensionDate;
