import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @description Formatted page header
 * @WhereIsItUsed
 * - page-header-container
 * - not-found-page
 */
@Component({
  selector: 'jb-page-header',
  templateUrl: './temp-jb-page-header.component.html',
  styleUrls: ['./temp-jb-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JBPageHeaderComponent {
  @Input('includeChapterSelector') includeChapterSelector: string;
  @Input('pageHeaderData') pageHeaderData;
  @Input('sectionsData') sectionsData;
  @Input() chapterSelectorHeader: string;
}
