import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@core/injection-tokens';

@Injectable()
export class HeaderStyleService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  public addStyleOverride() {
    const styleElement: HTMLStyleElement = this.renderer.createElement('style');
    const styles = this.renderer.createText(`
    /* JB Header Web Component Override */
    jb-header-wc {
      display: unset !important;
    }`);
    this.renderer.appendChild(styleElement, styles);
    this.renderer.appendChild(this.document.body, styleElement);
  }
}
