import { Holiday } from '@shared/types';

export const MOCK_HOLIDAYS: Holiday[] = [
  {
    date: new Date('September 3, 2018'),
    name: 'LABOR_DAY',
    content: 'Labor Day',
  },
  {
    date: new Date('September 9, 2018'),
    name: 'ROSH_HASHANAH',
    content: 'Rosh Hashanah',
  },
  {
    date: new Date('September 18, 2018'),
    name: 'YOM_KIPPUR',
    content: 'Yom Kippur',
  },
  {
    date: new Date('September 23, 2018'),
    name: 'AUTUMN',
    content: 'First Day of Autumn',
  },
  {
    date: new Date('October 8, 2018'),
    name: 'COLUMBUS_DAY',
    content: 'Columbus Day',
  },
  {
    date: new Date('October 31, 2018'),
    name: 'HALLOWEEN',
    content: 'Halloween',
  },
  {
    date: new Date('November 11, 2018'),
    name: 'VETERANS',
    content: "Veteran's Day",
  },
  {
    date: new Date('November 22, 2018'),
    name: 'THANKSGIVING',
    content: 'Thanksgiving',
  },
  {
    date: new Date('December 2, 2018'),
    content: 'Hanukkah starts',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 3, 2018'),
    content: '',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 4, 2018'),
    content: '',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 5, 2018'),
    content: '',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 6, 2018'),
    content: '',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 7, 2018'),
    content: '',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 8, 2018'),
    content: '',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 9, 2018'),
    content: '',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 10, 2018'),
    content: 'Hanukkah ends',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 22, 2018'),
    name: 'WINTER',
    content: 'First Day of Winter',
  },
  {
    date: new Date('December 25, 2018'),
    name: 'CHRISTMAS',
    content: 'Christmas',
  },
  {
    date: new Date('January 1, 2019'),
    name: 'NEWYEAR',
    content: "New Year's Day",
  },
  {
    date: new Date('January 21, 2019'),
    name: 'MLK',
    content: 'MLK Jr. Day',
  },
  {
    date: new Date('February 5, 2019'),
    name: 'CHINESENEWYEAR',
    content: 'Chinese New Year',
  },
  {
    date: new Date('February 14, 2019'),
    name: 'VALENTINES',
    content: "Valentine's Day",
  },
  {
    date: new Date('January 1, 2019'),
    name: 'NEWYEAR',
    content: "New Year's Day",
  },
  {
    date: new Date('January 21, 2019'),
    name: 'MLK',
    content: 'MLK Jr. Day',
  },
  {
    date: new Date('February 5, 2019'),
    name: 'CHINESENEWYEAR',
    content: 'Chinese New Year',
  },
  {
    date: new Date('February 14, 2019'),
    name: 'VALENTINES',
    content: "Valentine's Day",
  },
  {
    date: new Date('February 18, 2019'),
    content: "President's Day",
    name: ' PRESIDENTS_DAY,',
  },
  {
    date: new Date('April 21, 2019'),
    content: 'Easter',
    name: ' EASTER,',
  },
  {
    date: new Date('May 27, 2019'),
    content: 'Memorial Day',
    name: ' MEMORIAL_DAY,',
  },
  {
    date: new Date('July 4, 2019'),
    content: 'Independence Day',
    name: ' INDEPENDENCE_DAY,',
  },
  {
    date: new Date('September 2, 2019'),
    content: 'Labor Day',
    name: ' LABOR DAY,',
  },
  {
    date: new Date('September 30, 2019'),
    content: 'Rosh Hashanah',
    name: ' ROSH HASHANAH,',
  },
  {
    date: new Date('October 9, 2019'),
    content: 'Yom Kippur',
    name: ' YOM KIPPUR,',
  },
  {
    date: new Date('October 14, 2019'),
    content: 'Columbus Day',
    name: 'COLUMBUS DAY,',
  },
  {
    date: new Date('October 31, 2019'),
    content: 'Halloween',
    name: 'HALLOWEEN,',
  },
  {
    date: new Date('November 11, 2019'),
    content: "Veteran's Day",
    name: 'VETERANS DAY,',
  },
  {
    date: new Date('November 28, 2019'),
    content: 'Thanksgiving',
    name: 'THANKSGIVING',
    image: {
      src: '../../../ui-assets/bff/thanksgiving.svg',
      alt: 'Thanksgiving',
    },
  },
  {
    date: new Date('December 22, 2019'),
    content: 'Hanukkah starts',
    name: 'HANUKKAH',
    image: {
      src: '../../../ui-assets/bff/hanukkah.svg',
      alt: 'Hanukkah',
    },
  },
  {
    date: new Date('December 23, 2019'),
    content: 'Hanukkah',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 24, 2019'),
    content: 'Hanukkah',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 25, 2019'),
    content: 'Hanukkah',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 26, 2019'),
    content: 'Hanukkah',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 27, 2019'),
    content: 'Hanukkah',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 28, 2019'),
    content: 'Hanukkah',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 29, 2019'),
    content: 'Hanukkah',
    name: 'HANUKKAH',
  },
  {
    date: new Date('December 30, 2019'),
    content: 'Hanukkah ends',
    name: 'HANUKKAH',
    image: {
      src: '../../../ui-assets/bff/hanukkah.svg',
      alt: 'Hanukkah',
    },
  },
  {
    date: new Date('December 25, 2019'),
    content: 'Christmas',
    name: 'CHRISTMAS',
    image: {
      src: '../../../ui-assets/bff/christmas.svg',
      alt: 'Christmas',
    },
  },
  {
    date: new Date('January 1, 2020'),
    content: "New Year's Day",
    name: 'NEWYEAR',
  },
  {
    image: {
      src: '../../../ui-assets/bff/birthday_background.svg',
      alt: 'Happy Birthday!',
    },
    date: new Date(''),
    content: 'Happy Birthday!',
    name: 'BIRTHDAY',
  },
];
