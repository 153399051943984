import { NgModule } from '@angular/core';
import { JbLinkModule } from 'jb-component-library';

import { JBInnerHtmlModule } from '../../shared/jb-inner-html';
import { LinksModule } from '../../shared/ui/links';
import { JBTextImageModule } from './../../shared/jb-body-text-image/jb-text-image.module';
import {
  BodyTextImageContainerComponent,
  bodyTextImageContainerResolver,
} from './body-text-image-container.component';

const MODULES = [
  JBInnerHtmlModule,
  JbLinkModule,
  JBTextImageModule,
  LinksModule,
];

const DECLARATIONS = [BodyTextImageContainerComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class BodyTextImageContainerModule {
  static entry = BodyTextImageContainerComponent;
  static componentDataResolver = bodyTextImageContainerResolver;
}
