import { MOCK_FLIGHT_TRACKER_COPY } from '@dynamic-components/flight-tracker/flight-tracker.copy.mock';
import { MOCK_BOOKER_RESPONSE } from '@shared/booker';
import { startOfToday } from 'date-fns';

import {
  FlightTrackerActions,
  FlightTrackerActionsUnion,
} from './flight-tracker.actions';
import { FlightTrackerState } from './types';

export const initialFlightTrackerState: FlightTrackerState = {
  flightResults: undefined,
  selectedTabId: 0,
  formSubmitting: false,
  originAirports: [],
  destinationAirports: [],
  flightTrackerResponse: MOCK_FLIGHT_TRACKER_COPY,
  bookerResponse: MOCK_BOOKER_RESPONSE,
  today: startOfToday(),
};

export function flightTrackerReducer(
  state = initialFlightTrackerState,
  action: FlightTrackerActionsUnion,
): FlightTrackerState {
  switch (action.type) {
    case FlightTrackerActions.SET_FLIGHT_STATUS:
      return {
        ...state,
        flightResults: action.payload,
      };
    case FlightTrackerActions.SET_TAB:
      return {
        ...state,
        selectedTabId: action.payload,
      };
    case FlightTrackerActions.UPDATE_DESTINATION_AIRPORTS:
      return {
        ...state,
        destinationAirports: action.payload,
      };
    case FlightTrackerActions.UPDATE_ORIGIN_AIRPORTS:
      return {
        ...state,
        originAirports: action.payload,
      };
    case FlightTrackerActions.FORM_SUBMITTING:
      return {
        ...state,
        formSubmitting: action.payload,
      };
    case FlightTrackerActions.UPDATE_FLIGHT_TRACKER_RESPONSE:
      return {
        ...state,
        flightTrackerResponse: action.payload,
      };
    case FlightTrackerActions.UPDATE_BOOKER_RESPONSE:
      return {
        ...state,
        bookerResponse: action.payload,
      };
    default:
      return state;
  }
}
