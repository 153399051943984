<div class="breadcrumb-min-h bg-core-blue pa0"></div>
<div
  class="bg-core-blue w-100 pa5 pt8-ns ph5-m pa7-ns pb-5-m pb7 relative ph0-l ph0-m ph2-ns page-margin-m page-margin-l"
>
  <div class="flex justify-center ma0 white pt4 pt0-ns pb5 pb4-ns mb4-ns mb0">
    <dot-text-block class="w-50 w-20-ns"></dot-text-block>
  </div>
  <div class="jb-max-width center">
    <div class="jb-grid one-up grid-offset-l">
      <div>
        <div class="br3 bg-off-white flex flex-column mt2 pa3">
          <div
            class="flex justify-around flex-row-ns flex-column relative mt6-ns mv5 mb0-ns"
          >
            <div class="flex w-two-thirds-ns w-100 flex-row-ns flex-column">
              <div
                class="w-100 w-50-ns bg-white b--medium-gray ba booker-input mb1 mb0-ns mr1-ns"
              ></div>
              <div
                class="icon-swap relative-ns absolute cb z-2 bg-white b--light-gray ba h2 w2 br-100 nl3-ns nr3-ns"
              ></div>
              <div
                class="w-100 w-50-ns bg-white b--medium-gray ba booker-input mb1 mb0-ns mb0-ns mr1-ns"
              ></div>
            </div>
            <div
              class="booker-input bg-white b--medium-gray ba w-third-ns w-100-s pl1-ns order-2-ns flex-ns justify-between-ns"
            ></div>
          </div>
        </div>
        <div class="db tc pt5 mb6">
          <button
            class="dib"
            jbButton
            theme="ctaSecondary"
            [isRounded]="true"
            [isPaired]="false"
            type="button"
          >
            &nbsp;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
