<div class="relative z-0 w-100">
  <jb-background [color]="bottomSection?.bgColor" class="zn-1"></jb-background>
  <div class="flex flex-row pa4">
    <div class="pr4">
      <img
        id="bottom-section-card-image"
        *ngIf="image"
        [src]="image?.src || ''"
        [alt]="image?.alt"
        width="113"
        loading="lazy"
        decoding="async"
      />
    </div>
    <div>
      <jb-inner-html class="s-body" [innerHTML]="cardDesc"></jb-inner-html>
      <a
        jbActionLink
        [jbSitemapLinker]="link?.href"
        [preventDefault]="false"
        class="h-auto"
      >
        <span>
          {{ link?.title }}
          <jb-icon class="pl1 fill-royal-blue" name="rightFilled"></jb-icon>
        </span>
      </a>
    </div>
  </div>
</div>
