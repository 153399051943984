import {
  ScheduleExtensionActions,
  ScheduleExtensionActionsUnion,
} from './schedule-extension.actions';
import { ScheduleExtensionState } from './types';

export const initialScheduleExtensionState: ScheduleExtensionState = {
  extensionDate: { label: '', date: undefined },
};

export function scheduleExtensionReducer(
  state = initialScheduleExtensionState,
  action: ScheduleExtensionActionsUnion,
): ScheduleExtensionState {
  switch (action.type) {
    case ScheduleExtensionActions.LOAD_EXTENSION_DATE:
      return {
        ...state,
        extensionDate: action['error'] ? state.extensionDate : action.payload,
      };
    default:
      return state;
  }
}
