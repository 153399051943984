<dot-okta-container>
  <div class="lh-copy mt3 pt5 db">
    <dot-text-block [width]="40" [height]="19"></dot-text-block>
    <dot-text-block class="dn db-ns mt4" [width]="75"></dot-text-block>
  </div>
  <div class="flex flex-column mt4 mb4">
    <div class="ba sign-in-input"></div>
  </div>
  <div class="db">
    <button
      class="submit-button w-100-important"
      jbButton
      theme="ctaPrimary"
      [isRounded]="true"
      [isPaired]="false"
      type="button"
    >
      &nbsp;
    </button>
  </div>
</dot-okta-container>
