import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOverridesService } from '@feature/appconfig-panel/api-overrides-service/api-overrides.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiOverridesInterceptor implements HttpInterceptor {
  constructor(private apiOverridesService: ApiOverridesService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.apiOverridesService.overrides.size === 0) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map(response => {
        if (
          response instanceof HttpResponse &&
          this.apiOverridesService.overrides.size > 0
        ) {
          // check if the reponse url matches one of the overrides and replace the body
          for (const ovr of this.apiOverridesService.overrides.values()) {
            if (response.url.split('?')[0].endsWith(ovr.url)) {
              response = response.clone({
                body: ovr.payload,
              });
              break; // found url match finish loop
            }
          }
        }
        return response;
      }),
    );
  }
}
