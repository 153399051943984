import { CalendarResponse } from './types';

export const MOCK_CALENDAR__RESPONSE: CalendarResponse = {
  pointsLabel: {
    default: 'pts',
    tablet: 'pts',
    mobile: 'K',
  },
  sameDayReturn: 'Same day return',
  remainingSeats: {
    seat: 'seat left',
    seats: 'seats left',
  },
  errorContent: {
    content: `<h3> [MONTH_NAME] Fares Did Not Load Correctly</h3>
    <p>It's not you, it's us.
    The fares for this month did not load correctly, please reload this module or refresh
    your browser to continue.</p>`,
    type: 't4',
    title: 'Error',
    image: [
      {
        src: '/ui-assets/bff/reload-illustration.svg',
        alt: 'fail',
      },
    ],
  },
};
