import { Component } from '@angular/core';
import { JbDialogService } from 'jb-component-library';

/**
 * @description Modal for displaying legal text
 * @WhereIsItUsed isolated-product-block-container
 */
@Component({
  selector: 'jb-promo-legal-modal-dialog',
  templateUrl: './promo-legal-modal-dialog.component.html',
  styleUrls: ['./promo-legal-modal-dialog.component.scss'],
})
export class PromoLegalModalDialogComponent {
  promoLegalData: any[];

  constructor(public dialogService: JbDialogService) {}

  closeDialog() {
    this.dialogService.closeDialog();
  }

  handleData = (promoLegalData: any[]) => {
    this.promoLegalData = promoLegalData;
  };
}
