import {
  RequestAReceiptActionsUnion,
  RequestAReceiptActionTypes,
} from './request-a-receipt.actions';
import {
  LoadState,
  RequestReceiptState,
} from './types/reqest-a-receipt.state.type';

export const initialRequestAReceiptState: RequestReceiptState = {
  requestPayload: {
    RecordLocator: '',
    DepartureDate: '',
    CustomerFirstName: '',
    CustomerLastName: '',
    EmailAddress: '',
  },
  loadState: LoadState.Idle,
};

export function requestAReceiptReducer(
  state = initialRequestAReceiptState,
  action: RequestAReceiptActionsUnion,
): RequestReceiptState {
  switch (action.type) {
    case RequestAReceiptActionTypes.REQUEST_A_RECEIPT_SUBMIT_REQUESTED:
      return {
        ...state,
        loadState: LoadState.Submitting,
        requestPayload: action.payload,
      };

    case RequestAReceiptActionTypes.REQUEST_A_RECEIPT_SUBMIT_SUCCEEDED:
      return {
        ...state,
        loadState: LoadState.Success,
      };
    case RequestAReceiptActionTypes.REQUEST_A_RECEIPT_SUBMIT_FAILED:
      return {
        ...state,
        loadState: LoadState.Failure,
      };

    default:
      return state;
  }
}
