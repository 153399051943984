import { Component, Input } from '@angular/core';

import { ImageLink } from '../types';

@Component({
  selector: 'jb-bubble-link',
  templateUrl: './bubble-link.component.html',
  styleUrls: ['./bubble-link.component.scss'],
})
export class BubbleLinkComponent {
  @Input() link: ImageLink;
  @Input() round: boolean;
  @Input() smaller: boolean;
  @Input() isMobile: boolean;
}
