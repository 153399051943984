import { Component, HostBinding, Input } from '@angular/core';
import { Theme } from '@store/global/types/layout-theme.enum';

/**
 * @description Formatted text. Injected text bound to inner html or content children is custom formatted with
 * ngdeep in the scss file.
 * @WhereIsItUsed Many components
 */
@Component({
  selector: 'jb-inner-html',
  templateUrl: './jb-inner-html.component.html',
  styleUrls: ['./jb-inner-html.component.scss'],
})
export class JBInnerHtmlComponent {
  @HostBinding('class.dark-theme') get color() {
    return this.theme === this.themeEnum.dark;
  }
  themeEnum = Theme;
  @Input('theme') theme = this.themeEnum.light;
}
