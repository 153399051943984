import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JBBodyTextModule } from '@shared/jb-body-text';
import { JBInnerHtmlModule } from '@shared/jb-inner-html';
import { LinksModule } from '@shared/ui';
import { Layout, Theme } from '@store/global/types/layout-theme.enum';

import { BodyText } from './types';

@Component({
  standalone: true,
  imports: [JBInnerHtmlModule, JBBodyTextModule, LinksModule],
  selector: 'jb-body-text-container',
  template: `
    <jb-body-text
      jbSitemapNestedLinker
      [content]="bodyTextContent"
      [theme]="theme"
      [ngClass]="{
        tl: layout === layoutEnum.left,
        tr: layout === layoutEnum.right,
        tc: layout === layoutEnum.center
      }"
      [insideAccordion]="insideAccordion"
    ></jb-body-text>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyTextContainerComponent {
  @Input('content') bodyTextContent: BodyText[];
  // This input is for grid alignment when inside an accordion
  @Input('insideAccordion') insideAccordion = false;
  @Input() layout: Layout;
  @Input() theme: Theme;
  layoutEnum = Layout;

  static componentDataResolver = (registry, data) => ({
    // bodyTextContent comes from this component's html
    // data.bodyText is the key of the array in the CMS payload
    bodyTextContent: data.bodyText,
    layout: data.layout,
    theme: data.theme,
  });
}
