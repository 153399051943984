import { FlightDeal } from '@shared/types';
import { filter, flatten } from 'ramda';

import { applyFilters } from '../../../store/origins/api';
import { Airport } from '../../../store/origins/types';
import {
  AirportWithCoordinates,
  AirportWithNonStop,
  CountryWithAirportsWithNonStop,
} from '../types/airport.type';
import {
  CmsRoutesBooleanEnum,
  CmsRoutesFlightTypeEnum,
} from '../types/cms-routes.enum';
import { CmsRoutes, CmsRoutesResponseForMac } from '../types/cms-routes.type';

export const findMacAreaAirportsWithMint = (
  destinationsByRoute: CmsRoutes[],
  airport: AirportWithNonStop,
): boolean => {
  return Boolean(
    airport.cc.find((airportCode: string) =>
      destinationsByRoute
        .filter((destination: CmsRoutes) => destination.city3 === airportCode)
        .some(destination => destination.mint === CmsRoutesBooleanEnum.true),
    ),
  );
};

export const findMacAreaAirportsWithNonstop = (
  destinationsByRoute: CmsRoutes[],
  airport: AirportWithNonStop,
): boolean => {
  return Boolean(
    airport.cc.find((airportCode: string) =>
      destinationsByRoute
        .filter((destination: CmsRoutes) => destination.city3 === airportCode)
        .some(
          destination => destination.city2 === CmsRoutesFlightTypeEnum.nonstop,
        ),
    ),
  );
};

export const findMacAreaAirportsWithMintNonstop = (
  destinationsByRoute: CmsRoutes[],
  airport: AirportWithNonStop,
): boolean => {
  return Boolean(
    airport.cc.find((airportCode: string) =>
      destinationsByRoute
        .filter((destination: CmsRoutes) => destination.city3 === airportCode)
        .some(
          destination =>
            destination.mint === CmsRoutesBooleanEnum.true &&
            destination.city2 === CmsRoutesFlightTypeEnum.nonstop,
        ),
    ),
  );
};

export const combineOdAndCmsRoutes = (
  odRoutes: Airport[],
  cmsRoutes: CmsRoutes[],
): AirportWithNonStop[] => {
  return odRoutes.reduce(
    (acc: AirportWithNonStop[], curr: AirportWithNonStop) => {
      const destinationRoutes = cmsRoutes.filter(
        (destination: CmsRoutes) => destination.city3 === curr.code,
      );

      if (Boolean(destinationRoutes.length)) {
        acc.push({
          ...curr,
          // is redundant to have deal and price, but don't want to break price code, todo: revisit after this stabilizes
          nonstop: destinationRoutes.some(
            (destination: CmsRoutes) =>
              destination.city2 === CmsRoutesFlightTypeEnum.nonstop,
          ),
          mint: destinationRoutes.some(
            (destination: CmsRoutes) =>
              destination.mint === CmsRoutesBooleanEnum.true,
          ),
          mintnonstop: destinationRoutes.some(
            (destination: CmsRoutes) =>
              destination.mint === CmsRoutesBooleanEnum.true &&
              destination.city2 === CmsRoutesFlightTypeEnum.nonstop,
          ),
        });
      } else {
        if (curr.cc) {
          // Gives MAC a nonstop/mint status for filtering
          acc.push({
            ...curr,
            nonstop: findMacAreaAirportsWithNonstop(cmsRoutes, curr),
            mint: findMacAreaAirportsWithMint(cmsRoutes, curr),
            mintnonstop: findMacAreaAirportsWithMintNonstop(cmsRoutes, curr),
          });
        } else {
          // Fallback in case CMS and OD are out of sync
          acc.push(curr);
        }
      }
      return acc;
    },
    [],
  );
};

// todo: simplify this to extend the above function
export const appendNonstopToCountryWithAirports = (
  destinationsByODService: Airport[],
  destinationsByRoute: CmsRoutes[],
  seoDeals: FlightDeal[],
): AirportWithNonStop[] => {
  return destinationsByODService.reduce(
    (acc: AirportWithNonStop[], curr: AirportWithNonStop) => {
      const destinationRoutes = destinationsByRoute.filter(
        (destination: CmsRoutes) => destination.city3 === curr.code,
      );

      if (Boolean(destinationRoutes.length)) {
        const foundDeal = seoDeals.find(
          deal => deal.destinationAirportCode === curr.code,
        );

        acc.push({
          ...curr,
          // is redundant to have deal and price, but don't want to break price code, todo: revisit after this stabilizes
          deal: foundDeal,
          price: foundDeal?.price || null,
          nonstop: destinationRoutes.some(
            (destination: CmsRoutes) =>
              destination.city2 === CmsRoutesFlightTypeEnum.nonstop,
          ),
          mint: destinationRoutes.some(
            (destination: CmsRoutes) =>
              destination.mint === CmsRoutesBooleanEnum.true,
          ),
          mintnonstop: destinationRoutes.some(
            (destination: CmsRoutes) =>
              destination.mint === CmsRoutesBooleanEnum.true &&
              destination.city2 === CmsRoutesFlightTypeEnum.nonstop,
          ),
        });
      } else {
        if (curr.cc) {
          // Gives MAC a nonstop/mint status for filtering

          // filter for airports that fall under the MAC
          const filtered = seoDeals
            .filter(deal => curr.cc.indexOf(deal.destinationAirportCode) > -1)
            .sort((a, b) => a.price - b.price); // sort prices ascending

          const foundDeal = filtered[0];

          acc.push({
            ...curr,
            deal: foundDeal,
            price: filtered && filtered.length > 0 ? filtered[0].price : null, // grab first cause sort puts least expensive at index 0
            nonstop: findMacAreaAirportsWithNonstop(destinationsByRoute, curr),
            mint: findMacAreaAirportsWithMint(destinationsByRoute, curr),
            mintnonstop: findMacAreaAirportsWithMintNonstop(
              destinationsByRoute,
              curr,
            ),
          });
        } else {
          // Fallback in case CMS and OD are out of sync
          acc.push(curr);
        }
      }
      return acc;
    },
    [],
  );
};

export const extractNonStopFlightsFromCountryWithAirports = (
  countries: CountryWithAirportsWithNonStop[],
): CountryWithAirportsWithNonStop[] => {
  return countries.map((country: CountryWithAirportsWithNonStop) => {
    return {
      ...country,
      airports: filter(
        (airport: AirportWithNonStop) => Boolean(airport.nonstop),
        country.airports,
      ),
    };
  });
};

export const extractMintFlightsFromCountryWithAirports = (
  countries: CountryWithAirportsWithNonStop[],
): CountryWithAirportsWithNonStop[] => {
  return countries.map((country: CountryWithAirportsWithNonStop) => {
    return {
      ...country,
      airports: filter(
        (airport: AirportWithNonStop) => Boolean(airport.mint),
        country.airports,
      ),
    };
  });
};

export const extractMintNonStopFlightsFromCountryWithAirports = (
  countries: CountryWithAirportsWithNonStop[],
): CountryWithAirportsWithNonStop[] => {
  return countries.map((country: CountryWithAirportsWithNonStop) => {
    return {
      ...country,
      airports: filter(
        (airport: AirportWithNonStop) => Boolean(airport.mintnonstop),
        country.airports,
      ),
    };
  });
};

export const handleFilteredDestinations = (
  userOriginSelection,
  filteredDestinations,
  cities,
  isDestinationsLoading,
) => {
  if (isDestinationsLoading) {
    return [];
  }

  const flattenAirportListResponse = (
    airportListPayload: CountryWithAirportsWithNonStop[],
  ) => airportListPayload.map(x => x.airports);

  const flattenAirportListWithCoordinates = [
    ...flatten(
      flattenAirportListResponse(
        // todo: resolve this coerced typing, moved it here from previous effect logic
        filteredDestinations as CountryWithAirportsWithNonStop[],
      ),
    ),
    userOriginSelection,
  ].reduce((acc: AirportWithCoordinates[], curr) => {
    const index = cities.findIndex(c => c.id === curr?.code);
    if (index > -1) {
      acc.push({
        ...curr,
        lat: parseFloat(cities[index].lat),
        lng: parseFloat(cities[index].lng),
        locationType: cities[index].type,
      });
    }
    return acc;
  }, []);

  return flattenAirportListWithCoordinates;
};

export const handleFilterWithCountryWithAirports = (
  countriesWithAirport: CountryWithAirportsWithNonStop[],
  nonstop: boolean,
  mint: boolean,
): CountryWithAirportsWithNonStop[] => {
  let airportsWithNoPartnersByCountry = countriesWithAirport
    ? (applyFilters(
        countriesWithAirport,
        true,
        false,
        [],
      ) as CountryWithAirportsWithNonStop[])
    : countriesWithAirport;

  if (nonstop && mint) {
    airportsWithNoPartnersByCountry =
      extractMintNonStopFlightsFromCountryWithAirports(
        airportsWithNoPartnersByCountry,
      );
  } else if (nonstop) {
    airportsWithNoPartnersByCountry =
      extractNonStopFlightsFromCountryWithAirports(
        airportsWithNoPartnersByCountry,
      );
  } else if (mint) {
    airportsWithNoPartnersByCountry = extractMintFlightsFromCountryWithAirports(
      airportsWithNoPartnersByCountry,
    );
  }

  return airportsWithNoPartnersByCountry;
};

export const handleFilterDestinationAirportArray = (
  destinationAirports: AirportWithCoordinates[],
  nonstop: boolean,
  mint: boolean,
  vacations: boolean,
  jtpVacations: Set<string>,
): AirportWithCoordinates[] => {
  let filteredAirports: AirportWithCoordinates[] = [];
  const filters = [];

  if (nonstop && mint) {
    filters.push((airport: AirportWithCoordinates) => airport.mintnonstop);
  } else if (nonstop) {
    filters.push((airport: AirportWithCoordinates) => airport.nonstop);
  } else if (mint) {
    filters.push((airport: AirportWithCoordinates) => airport.mint);
  }

  if (vacations) {
    filters.push((airport: AirportWithCoordinates) =>
      jtpVacations.has(airport.code),
    );
  }

  // for each airport run it through each filter in the filters array
  // each filter must return true for it to be included int he filtered destinations
  filteredAirports = destinationAirports.filter(airport =>
    filters.every(f => f(airport)),
  );

  // add the last destination airport to the end of filtered, which is assumed to be the origin airport.
  if (destinationAirports.length > 0) {
    filteredAirports.push(destinationAirports[destinationAirports.length - 1]);
  }
  return filteredAirports;
};

export const getAllMacDestinations = (
  cmsRoutes: CmsRoutesResponseForMac,
): CmsRoutes[] => {
  return Object.values(cmsRoutes).reduce((acc: CmsRoutes[], curr) => {
    if (curr[0]?.route) {
      acc.push(...curr[0].route);
    }
    return acc;
  }, []);
};

export const getAirportByCode = (
  code: string,
  airports: AirportWithCoordinates[],
): AirportWithCoordinates => {
  return airports.find(a => a.code === code);
};
