<jb-dialog dialogType="content" contentModalMaxWidth="31.5625rem">
  <jb-dialog-header>{{ getActiveHeader() }}</jb-dialog-header>
  <jb-dialog-content
    *ngIf="config.flight.selected && config.flight.fare; else vacationDialog"
  >
    <div *ngIf="destinationImage">
      <jb-image
        [src]="destinationImage | dynamicImageWidthPipe: 'image'"
        [alt]="destination.destinationName"
        [isLazy]="enableLazyLoadImage"
        [placeholder]="
          destinationImage
            | dynamicImageWidthPipe: 'placeholder' : placeholderParams
        "
      ></jb-image>
    </div>
    <div class="tl">
      <h2 class="core-blue mt4">
        {{ destination.destinationName
        }}{{
          destination.destinationState
            ? ', ' + destination.destinationState
            : ''
        }}
      </h2>
      <div class="klarheit body core-blue mv2" *ngIf="!origin.isMAC">
        {{ origin.originName }} ({{ origin.origin }}) to
        {{ destination.destinationName }} ({{ destination.destination }})
      </div>
      <div class="klarheit body core-blue mv2" *ngIf="origin.isMAC">
        {{ destination.originName }}, {{ destination.originState }} ({{
          destination.origin
        }}) to {{ destination.destinationName }} ({{ destination.destination }})
      </div>
      <jb-offer-block JbOfferBlock>
        <jb-offer-block-text offerBlockHeader>{{
          offer.header
        }}</jb-offer-block-text>
        <jb-offer-block-value
          [pointsSuffix]="offer.pointsSuffix"
          [valueType]="config.pricingType === 'dollars' ? 'currency' : 'points'"
          [currencySymbol]="
            getCurrencySymbol(
              config.flight.fare.currencycode || defaultCurrencyCode
            )
          "
        >
          {{ config.flight.fare[config.pricingType] }}
        </jb-offer-block-value>
        <jb-offer-block-text offerBlockFooter>
          <ng-container
            [ngTemplateOutlet]="footerWithTax"
            [ngTemplateOutletContext]="{
              footer: offer.footer,
              fare: config.flight.fare,
              pricingType: config.pricingType
            }"
          >
          </ng-container>
        </jb-offer-block-text>
      </jb-offer-block>

      <ul class="mb4">
        <li *ngIf="config.flight.fare.restr1">
          {{ config.flight.fare.restr1 }}
        </li>
        <li *ngIf="config.flight.fare.restr2">
          {{ config.flight.fare.restr2 }}
        </li>
        <li *ngIf="config.flight.fare.restr3">
          {{ config.flight.fare.restr3 }}
        </li>
        <li *ngIf="config.flight.fare.restr4">
          {{ config.flight.fare.restr4 }}
        </li>
        <li *ngIf="config.flight.fare.restr5">
          {{ config.flight.fare.restr5 }}
        </li>
      </ul>
    </div>

    <div class="tc w-100 mb2">
      <div>
        <a
          *ngIf="
            !selectDatesIsLoading && (selectDatesUrl$ | async) as url;
            else loadingUrlButton
          "
          jbButton
          [jbSitemapLinker]="url"
          referrerpolicy="no-referrer-when-downgrade"
          isFull="true"
        >
          <!-- Ticket DOT-11500 to add translation -->
          <span class="no-wrap">Select Dates</span>
        </a>
      </div>
    </div>
  </jb-dialog-content>
</jb-dialog>

<ng-template #vacationDialog>
  <jb-dialog-content
    *ngIf="
      config.flightHotel.selected && config.flightHotel.fare;
      else mintDialog
    "
  >
    <div class="tl">
      <div *ngIf="destinationImage">
        <jb-image
          [src]="destinationImage | dynamicImageWidthPipe: 'image'"
          [alt]="destination.destinationName"
          [isLazy]="enableLazyLoadImage"
          [placeholder]="
            destinationImage
              | dynamicImageWidthPipe: 'placeholder' : placeholderParams
          "
        >
        </jb-image>
      </div>
      <h2 class="core-blue mt4">{{ config.flightHotel.fare.hotelname }}</h2>
      <div class="klarheit body core-blue mv2" *ngIf="!origin.isMAC">
        {{ origin.originName }} ({{ origin.origin }}) to
        {{ destination.destinationName }} ({{ destination.destination }})
      </div>
      <div class="klarheit body core-blue mv2" *ngIf="origin.isMAC">
        {{ destination.originName }}, {{ destination.originState }} ({{
          destination.origin
        }}) to {{ destination.destinationName }} ({{ destination.destination }})
      </div>
      <jb-offer-block JbOfferBlock>
        <jb-offer-block-text offerBlockHeader>{{
          offer.header
        }}</jb-offer-block-text>
        <jb-offer-block-value
          [pointsSuffix]="offer.pointsSuffix"
          [valueType]="'currency'"
          [currencySymbol]="
            getCurrencySymbol(
              config.flightHotel.fare.currencycode || defaultCurrencyCode
            )
          "
        >
          {{ config.flightHotel.fare.dollars }}
        </jb-offer-block-value>
        <jb-offer-block-text offerBlockFooter>{{
          offer.footer.replaceAll(
            VACATIONS_FOOTER_VACATION_NIGHTS,
            getFlightHotelFare(destination.fares).nights
          )
        }}</jb-offer-block-text>
      </jb-offer-block>
      <div class="mv3 klarheit body">
        <jb-star-rating
          [rating]="config.flightHotel.fare.hotelrating"
        ></jb-star-rating>
        <span class="ml3">Trip Advisor Reviews</span>
      </div>
      <ul class="mb4">
        <li *ngIf="config.flight.fare.restr1">
          {{ config.flight.fare.restr1 }}
        </li>
        <li *ngIf="config.flight.fare.restr2">
          {{ config.flight.fare.restr2 }}
        </li>
        <li *ngIf="config.flight.fare.restr3">
          {{ config.flight.fare.restr3 }}
        </li>
        <li *ngIf="config.flight.fare.restr4">
          {{ config.flight.fare.restr4 }}
        </li>
        <li *ngIf="config.flight.fare.restr5">
          {{ config.flight.fare.restr5 }}
        </li>
      </ul>
    </div>
    <div class="tc w-100 mb2">
      <a
        *ngIf="selectDatesUrl$ | async as url; else loadingUrlButton"
        jbButton
        theme="ctaPrimary"
        [jbSitemapLinker]="url"
        referrerpolicy="no-referrer-when-downgrade"
        isFull="true"
      >
        <!-- Ticket DOT-11500 to add translation -->
        Select Dates
      </a>
    </div>
  </jb-dialog-content>
</ng-template>

<ng-template #mintDialog>
  <jb-dialog-content
    *ngIf="config.flightMint.selected && config.flightMint.fare"
  >
    <div class="tl">
      <div *ngIf="destinationImage">
        <jb-image
          [src]="destinationImage | dynamicImageWidthPipe: 'image'"
          [alt]="destination.destinationName"
          [isLazy]="enableLazyLoadImage"
          [placeholder]="
            destinationImage
              | dynamicImageWidthPipe: 'placeholder' : placeholderParams
          "
        >
        </jb-image>
      </div>
      <h2 class="core-blue mt4">
        {{ destination.destinationName }}
        {{
          destination.destinationState
            ? ', ' + destination.destinationState
            : ''
        }}
      </h2>
      <div class="klarheit body core-blue mv2" *ngIf="!origin.isMAC">
        {{ origin.originName }} ({{ origin.origin }}) to
        {{ destination.destinationName }} ({{ destination.destination }})
      </div>
      <div class="klarheit body core-blue mv2" *ngIf="origin.isMAC">
        {{ destination.originName }}, {{ destination.originState }} ({{
          destination.origin
        }}) to {{ destination.destinationName }} ({{ destination.destination }})
      </div>
      <jb-offer-block JbOfferBlock>
        <jb-offer-block-text offerBlockHeader>{{
          offer.header
        }}</jb-offer-block-text>
        <jb-offer-block-value
          [pointsSuffix]="offer.pointsSuffix"
          [valueType]="config.pricingType === 'dollars' ? 'currency' : 'points'"
          [currencySymbol]="
            getCurrencySymbol(
              config.flightMint.fare.currencycode || defaultCurrencyCode
            )
          "
        >
          {{ config.flightMint.fare[config.pricingType] }}
        </jb-offer-block-value>
        <jb-offer-block-text offerBlockFooter>
          <ng-container
            [ngTemplateOutlet]="footerWithTax"
            [ngTemplateOutletContext]="{
              footer: offer.footer,
              fare: config.flightMint.fare,
              pricingType: config.pricingType
            }"
          >
          </ng-container>
        </jb-offer-block-text>
      </jb-offer-block>

      <ul class="mb4">
        <li *ngIf="config.flightMint.fare.restr1">
          {{ config.flightMint.fare.restr1 }}
        </li>
        <li *ngIf="config.flightMint.fare.restr2">
          {{ config.flightMint.fare.restr2 }}
        </li>
        <li *ngIf="config.flightMint.fare.restr3">
          {{ config.flightMint.fare.restr3 }}
        </li>
        <li *ngIf="config.flightMint.fare.restr4">
          {{ config.flightMint.fare.restr4 }}
        </li>
        <li *ngIf="config.flightMint.fare.restr5">
          {{ config.flightMint.fare.restr5 }}
        </li>
      </ul>
    </div>
    <div class="tc w-100 mb2">
      <a
        jbButton
        theme="ctaPrimary"
        [jbSitemapLinker]="selectDatesUrl$ | async"
        referrerpolicy="no-referrer-when-downgrade"
        isFull="true"
      >
        <!-- Ticket DOT-11500 to add translation -->
        Select Dates
      </a>
    </div>
  </jb-dialog-content>
</ng-template>

<ng-template
  #footerWithTax
  let-footer="footer"
  let-fare="fare"
  let-pricingType="pricingType"
>
  <span *ngIf="pricingType === 'points' && fare.pointstaxes" class="b"
    >+{{ getCurrencySymbol(fare.currencycode || defaultCurrencyCode)
    }}{{ fare.pointstaxes | number: '1.2' }}
  </span>
  <span>{{ footer }}</span>
</ng-template>

<ng-template #loadingUrlButton>
  <button jbButton isFull="true" disabled>
    <jb-icon name="loading"></jb-icon>
  </button>
</ng-template>
