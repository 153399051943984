<div class="page-margin jb-max-width center mv6 mv8-ns mv9-l">
  <div class="jb-max-width center">
    <div class="jb-grid one-up center gr-unset grid-offset-l mb3 mb4-ns">
      <div class="bb b--light-gray">
        <dot-text-block class="db w-20 pt2 pb3" [height]="18"></dot-text-block>
      </div>
    </div>
    <div class="w-100 center">
      <div class="jb-grid gr-unset gap-row-none pb6">
        <div class="dn db-l col-1-l"></div>
        <div
          class="col-12 flex flex-column pl0 column col-4-m col-3-l column-subheader"
        >
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-60 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-70 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-60 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-70 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-70 pv2 mb1"
            [height]="14"
          ></dot-text-block>
        </div>
        <div
          class="col-12 flex flex-column pl0 column col-4-m col-3-l column-subheader"
        >
          <dot-text-block
            class="db w-60 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-70 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-90 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-50 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-90 pv2 mb1"
            [height]="14"
          ></dot-text-block>
        </div>
        <div
          class="col-12 flex flex-column pl0 column col-4-m col-3-l column-subheader"
        >
          <dot-text-block
            class="db w-70 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-75 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-90 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-60 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-70 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-75 pv2 mb1"
            [height]="14"
          ></dot-text-block>
          <dot-text-block
            class="db w-80 pv2 mb1"
            [height]="14"
          ></dot-text-block>
        </div>
      </div>
    </div>
  </div>
</div>
