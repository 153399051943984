import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppConfigService } from '@core/app-config';
import { WINDOW } from '@core/injection-tokens';

import { DeviceType } from './device.type';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {
  constructor(
    @Inject(WINDOW) private window,
    private meta: Meta,
    private appConfigService: AppConfigService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  detectDeviceType(): DeviceType {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return null;
    }
    const userAgent = this.window.navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent) && !this.window.MSStream) {
      return DeviceType.iOS;
    } else if (/android/i.test(userAgent)) {
      return DeviceType.Android;
    } else {
      return DeviceType.Other;
    }
  }

  getAppStoreUrl(): string {
    const deviceType = this.detectDeviceType();

    return deviceType === DeviceType.iOS
      ? this.appConfigService.mobileAppStoreBadges.apple.storeUrl
      : this.appConfigService.mobileAppStoreBadges.android.storeUrl;
  }

  updateViewportMetaTag() {
    const deviceType = this.detectDeviceType();

    let content =
      'width=device-width, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover';

    if (deviceType === DeviceType.iOS) {
      content =
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, shrink-to-fit=no, viewport-fit=cover';
    }

    this.meta.updateTag({
      name: 'viewport',
      content,
    });
  }
}
