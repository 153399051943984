export type UrlDictionary = {
  [key: string]: string[] | string;
};

export type DigestedDictionary = {
  static: { [language: string]: { [key: string]: string } };
  cms: { [language: string]: { [key: string]: string } };
};

/**
 * Extracts the language identifier from a given URL.
 *
 * @param {string} url - The URL to extract language from.
 * @returns {string | null} The language identifier or null if not found.
 */
export function extractLanguage(url: string): string | null {
  const matches = url.match(/\/([^\/]+)\.json$/);
  return matches ? matches[1] : null;
}

/**
 * Adds key-value pairs to the output dictionary for a given type ('static' or 'cms').
 *
 * @param {DigestedDictionary} output - The output dictionary to populate.
 * @param {'static' | 'cms'} type - The type of URL ('static' or 'cms').
 * @param {string} lang - The language identifier.
 * @param {string} key - The key to add.
 * @param {string} version - The version to set for the given key.
 */
export function addToOutput(
  output: DigestedDictionary,
  type: 'static' | 'cms',
  lang: string,
  key: string,
  version: string,
) {
  if (!output[type][lang]) {
    output[type][lang] = {};
  }
  output[type][lang][key] = version;
}

/**
 * Transforms a dictionary of URLs based on specified version numbers.
 * The input object contains versions as keys, with each key having an array of URLs or a wildcard.
 * The output object is structured with "static" and "cms" sections, each containing language-specific mappings.
 *
 * @param {UrlDictionary} input - An object containing versions as keys and an array of URLs or a wildcard as values.
 * @returns {DigestedDictionary} An object containing the transformed URLs organized by static and CMS sections, and further by language.
 *
 * @example
 * const inputDictionary = {
    '23.0.1-rc-3-33': '*',
    '23.2.1-rc-1': [
      'home/en.json',
      'home/our-company/en.json',
      'home/safety/fr.json',
      'i18n-next-static/file_name/en.json',
    ],
    '23.2.3-rc-0': [
      'about-us/en.json',
      'i18n-next-static/booker-response/fr.json',
    ],
  }
 *
 * const result = transformUrlDictionary(inputDictionary);
   {
    static: {
      en: {
        file_name: '23.2.1-rc-1',
      },
      fr: {
        'booker-response': '23.2.3-rc-0',
      },
    },
    cms: {
      en: {
        'home': '23.2.1-rc-1',
        'home/our-company': '23.2.1-rc-1',
        'about-us': '23.2.3-rc-0',
      },
      fr: {
        'home/safety': '23.2.1-rc-1',
      },
    },
  };
 */
export function transformUrlDictionary(
  input: UrlDictionary,
): DigestedDictionary {
  const output: DigestedDictionary = {
    static: {},
    cms: {},
  };

  for (const version in input) {
    const urls = input[version];
    if (!Array.isArray(urls)) continue;

    urls.forEach(url => {
      const lang = extractLanguage(url);
      if (!lang) return;

      if (url.startsWith('i18n-next-static')) {
        const fileNameComponents = url.split('/');
        const fileName = fileNameComponents[fileNameComponents.length - 2];
        addToOutput(output, 'static', lang, fileName, version);
      } else {
        const page = url.replace(/\/([^\/]+)\.json$/, '');
        addToOutput(output, 'cms', lang, page, version);
      }
    });
  }

  return output;
}
