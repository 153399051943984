import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BestFareDay, WindowService } from '@core/index';
import { BffStore } from '@feature/bff/bff.component-store';
import { CalendarMonth } from '@feature/bff/bff.component-store.types';
import { ComponentStore } from '@ngrx/component-store';
import { chunk } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import {
  calendarComponentInitialState,
  CalendarComponentState,
  CalendarComponentViewModel,
} from './calendar.component.state';
import { getCalendarContainerId, nextMonth } from './calendar.utils';
import { CalendarResponse } from './types';

/**
 * @description Display a scrollable calendar.
 * @WhereIsItUsed Calendar aggregator which is in turn used in bff
 */
@Component({
  selector: 'jb-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [ComponentStore],
})
export class CalendarComponent {
  @Input() set calendarIndex(calendarIndex: number) {
    this.store.patchState({ calendarIndex });
  }

  @Input() set content(content: CalendarResponse) {
    this.store.patchState({ content });
  }
  @Input() set maxSeats(maxSeats: number) {
    this.store.patchState({ maxSeats });
  }

  @Input() set maxDate(maxDate: Date) {
    this.store.patchState({ maxDate });
  }

  @Input() set legalCopy(legalCopy: string) {
    this.store.patchState({ legalCopy });
  }

  @Input() set isLastMonthOfCalendar(isLastMonthOfCalendar: boolean) {
    this.store.patchState({ isLastMonthOfCalendar });
  }

  @Input() canAutoLoad: boolean;

  @Output() calendarLoaded = new EventEmitter<number>();

  readonly calendarMonth$: Observable<CalendarMonth> = this.store.select(
    this.store.select(state => state.calendarIndex),
    this.bffStore.calendars$,
    (calendarIndex, calendars) => calendars[calendarIndex],
  );

  readonly isFirstLoadedCalendarMonth$: Observable<boolean> = this.store.select(
    this.store.select(state => state.calendarIndex),
    this.bffStore.firstLoadedCalendarMonth$,
    (calendarIndex, firstLoadedCalendarMonth) =>
      calendarIndex === firstLoadedCalendarMonth,
  );

  readonly id$ = this.calendarMonth$.pipe(
    map(({ year, month }) => getCalendarContainerId(year, month)),
  );

  readonly daysOfMonth$: Observable<BestFareDay[]> = this.store.select(
    this.calendarMonth$,
    this.bffStore.isOneWay$,
    this.bffStore.isDepartureSelected$,
    (calendarMonth, isOneWay, isDepartureSelected) =>
      this.getDaysOfMonth(calendarMonth, isOneWay, isDepartureSelected),
  );

  readonly hasFlights$: Observable<boolean> = this.store.select(
    this.calendarMonth$,
    this.daysOfMonth$,
    (calendarMonth, days) => this.getHasFlights(calendarMonth, days),
  );

  readonly daysOfMonthGroupedByWeek$: Observable<BestFareDay[][]> =
    this.daysOfMonth$.pipe(
      map(daysOfMonth => this.getDaysOfMonthGroupedByWeek(daysOfMonth)),
    );

  readonly minHeight$: Observable<string> = this.daysOfMonthGroupedByWeek$.pipe(
    withLatestFrom(this.windowService.isMobile),
    map(([weeksOfMonth, isMobile]) =>
      this.getMinHeight(weeksOfMonth, isMobile),
    ),
  );

  readonly showLoadButton$: Observable<boolean> = this.calendarMonth$.pipe(
    map(
      ({ isLoaded, isLoading, isServerError }) =>
        !(isLoaded || isLoading || isServerError),
    ),
  );

  readonly vm$: Observable<CalendarComponentViewModel> = combineLatest([
    this.store.state$,
    this.calendarMonth$,
    this.id$,
    this.daysOfMonthGroupedByWeek$,
    this.minHeight$,
    this.showLoadButton$,
    this.hasFlights$,
    this.bffStore.isOneWay$,
    this.isFirstLoadedCalendarMonth$,
  ]).pipe(
    map(
      ([
        state,
        calendarMonth,
        id,
        daysOfMonthGroupedByWeek,
        minHeight,
        showLoadButton,
        hasFlights,
        isOneWay,
        isFirstLoadedCalendarMonth,
      ]: [
        CalendarComponentState,
        CalendarMonth,
        string,
        BestFareDay[][],
        string,
        boolean,
        boolean,
        boolean,
        boolean,
      ]) => ({
        ...state,
        calendarMonth,
        id,
        daysOfMonthGroupedByWeek,
        minHeight,
        showLoadButton,
        hasFlights,
        isOneWay,
        isFirstLoadedCalendarMonth,
      }),
    ),
  );

  constructor(
    private windowService: WindowService,
    public store: ComponentStore<CalendarComponentState>,
    public bffStore: BffStore,
  ) {
    this.store.setState(calendarComponentInitialState);

    this.store.patchState(this.bffStore.form$.pipe(map(form => ({ form }))));

    this.store.patchState({
      iOS: this.windowService.checkMobileOperatingSystem() === 'iOS',
    });
  }

  fetchFares(month: number, year: number) {
    this.bffStore.fetchFares({ month, year, scrollToCalendar: true });
  }

  onViewNextMonth(month: number, year: number) {
    this.bffStore.fetchFares({
      ...nextMonth({ month, year }),
      scrollToCalendar: true,
    });
  }

  getDaysOfMonthGroupedByWeek(dayOfMonth: BestFareDay[]): BestFareDay[][] {
    return chunk(dayOfMonth, 7);
  }

  getHasFlights(calendarMonth: CalendarMonth, days: BestFareDay[]) {
    return (
      calendarMonth.isLoaded &&
      days.some(day => !day.disabled && day.amount > 0)
    );
  }

  getDaysOfMonth(
    calendarMonth: CalendarMonth,
    isOneWay: boolean,
    isDepartureSelected: boolean,
  ) {
    return calendarMonth.isLoaded
      ? isDepartureSelected && !isOneWay
        ? calendarMonth.fares.inboundFares?.daysOfMonth ?? []
        : calendarMonth.fares.outboundFares.daysOfMonth
      : calendarMonth.days;
  }

  getMinHeight(weeksOfMonth: BestFareDay[][], isMobile: boolean): string {
    return isMobile ? '' : `${weeksOfMonth.length * 99}px`;
  }
}
