<div class="ti-wrapper w-100 list pt3-ns fl-l fw4">
  <ng-container *ngFor="let section of subNav.section; let last = last">
    <div
      class="fl-l w-100 pb3 pb4-m pb3-l bottom-divider flex-l flex-wrap-l"
      [class.bottom-divider]="isMobile !== true && !last"
      [class.no-bottom-divider]="last && isMobile !== true"
      [class.pb3]="isMobile !== true && !last"
      [class.pb2]="last"
    >
      <ng-container *ngFor="let item of section.children">
        <div class="list klarheit ti-lists-wrapper">
          <jb-icon-link [link]="item"></jb-icon-link>
          <ul *ngIf="!isMobile"
            class="sub-list f6 list klarheit lh-title pa0 dn db-ns ml8 mt1 nt1-m"
          >
            <li class="mb2" *ngFor="let child of item.children">
              <a
                [jbSitemapLinker]="child.href"
                [target]="child.newWindow"
                [dotLinkIcon]="child.newWindow === '_self'? null : child.newWindow"
                rel="noopener"
                class="link royal-blue ti-child-link"
                >{{ child.title }}</a
              >
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
