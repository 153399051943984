import { TypedDictionary } from '@core/utils';
import { HotelBookingForm } from '@dynamic-components/booker-v2/bookers/hotel/booker-hotel-points/booker-hotel-points.types';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
// do not barrel, it will break tests
import { correctlyFormatTravellers } from '@shared/booker/utils/booker-utils';
import { RecentAirSearch, RecentVacationSearch } from '@shared/types';
import { PairedRecentAirportSearches } from '@store/booker/types/booker-selector.types';
import { applyFilters, codesToAirports } from '@store/origins/api';
import {
  airportsMap,
  countriesWithAirports,
  flattenedBookerAirDestinationAirports,
} from '@store/origins/origins.selectors';
import { Airport } from '@store/origins/types';
import { addDays, isBefore, parseISO, startOfToday } from 'date-fns';
import { pickBy } from 'ramda';

import { AppState } from '../shared/types/app-state.type';
import { BookerState } from './types';

const today = startOfToday();

export const booker: MemoizedSelector<AppState, BookerState> =
  createFeatureSelector('booker');

/** Get all recent air searches that are >= today */
export const recentAirSearches: MemoizedSelector<AppState, RecentAirSearch[]> =
  createSelector(booker, state =>
    state.recentAirSearches.filter(
      search =>
        !isBefore(
          typeof search.departDate[0] === 'string'
            ? parseISO(search.departDate[0])
            : search.departDate[0],
          today,
        ),
    ),
  );

/** Get all submitted searches */
export const submittedSearches = createSelector(
  booker,
  state => state.submittedAirSearches,
);

/** Get all submitted searches that are >= today */
export const recentSubmittedSearches = createSelector(
  submittedSearches,
  state =>
    state.filter(
      search =>
        !isBefore(
          typeof search.departureDate === 'string'
            ? new Date(search.departureDate)
            : search.departureDate,
          today,
        ),
    ),
);

/**
 * Get recent submitted air searches filtered for one way and round trip >= today
 */
export const recentSubmittedOWRTSearches = createSelector(
  recentSubmittedSearches,
  state =>
    state.filter(
      search => search.tripType !== 'MC', // no multicity
    ),
);

/** Get the first recent search of the recent air searches */
export const currentSearchState = createSelector(
  recentAirSearches,
  recentSearch => recentSearch[0] || { originCode: [], destinationCode: [] },
);

export const currentFlightCodes = createSelector(
  currentSearchState,
  ({ originCode, destinationCode }) =>
    Array.from(
      { length: Math.max(originCode.length, destinationCode.length) },
      (_, i) => ({
        originCode: originCode[i],
        destinationCode: destinationCode[i],
      }),
    ),
);

export const bookerGlobalSettings = createSelector(
  booker,
  bookerState => bookerState.bookerGlobalSettings,
);

export const recentLocations = createSelector(
  booker,
  bookerState => bookerState.recentLocations,
);

export const allAvailableAirports = createSelector(
  countriesWithAirports,
  countryWithAirports =>
    countryWithAirports.length ? applyFilters(countryWithAirports) : [],
);

export const recentVacationSearches: MemoizedSelector<
  AppState,
  RecentVacationSearch[]
> = createSelector(booker, state =>
  state.recentVacationSearches.filter(
    search =>
      search.departDate &&
      !isBefore(new Date(search.departDate[0]), addDays(today, 1)),
  ),
);

export const recentHotelPtsSearch: MemoizedSelector<
  AppState,
  HotelBookingForm
> = createSelector(booker, state =>
  state.recentHotelPtsSearch.travelDates.startDate &&
  !isBefore(new Date(state.recentHotelPtsSearch.travelDates.startDate), today)
    ? state.recentHotelPtsSearch
    : null,
);

export const recentDepartureDate = createSelector(
  recentAirSearches,
  airSearches => (airSearches[0] ? airSearches[0].departDate[0] : undefined),
);

export const recentReturnDate = createSelector(
  recentAirSearches,
  airSearches =>
    airSearches[0]
      ? airSearches[0].returnDate && airSearches[0].returnDate[0]
      : undefined,
);

export const recentPromoCode = createSelector(recentAirSearches, airSearches =>
  airSearches[0] ? airSearches[0].promoCode : undefined,
);

export const recentOriginCode = createSelector(
  recentAirSearches,
  airSearches => (airSearches[0] ? airSearches[0].originCode[0] : undefined),
);

export const recentDestinationCode = createSelector(
  recentAirSearches,
  airSearches =>
    airSearches[0] ? airSearches[0].destinationCode[0] : undefined,
);

export const recentTripType = createSelector(recentAirSearches, airSearches =>
  airSearches[0] ? airSearches[0].intineraryType : undefined,
);

export const recentTravellerCount = createSelector(
  recentAirSearches,
  airSearches => {
    return correctlyFormatTravellers(airSearches[0]);
  },
);

export const recentSearchIsTrueBlue = createSelector(
  recentAirSearches,
  airSearches => airSearches[0]?.fare.toLowerCase() === 'tb' || null,
);

// note: returns null if airportsHashmap has not loaded yet
export const recentAirportsDeduplicated = filterOutPartnerAirlines =>
  createSelector(
    airportsMap,
    currentFlightCodes,
    recentLocations,
    flattenedBookerAirDestinationAirports,
    (
      airportsHashMap: TypedDictionary<Airport>,
      currentFlight,
      recentLocationList,
      destinationAirports,
    ): PairedRecentAirportSearches[] | null => {
      if (airportsHashMap) {
        // removes partner airlines from hashmap, reducing the pool of legitimate airports
        const updatedAirportsHashMap: TypedDictionary<Airport> =
          filterOutPartnerAirlines
            ? pickBy((value: Airport) => value.jb, airportsHashMap)
            : airportsHashMap;

        return currentFlight.map(({ originCode, destinationCode }, i) => {
          return {
            originRecentAirports: codesToAirports(
              updatedAirportsHashMap,
              recentLocationList.origins[i]
                .filter(
                  flattenedOriginCode =>
                    flattenedOriginCode !== destinationCode,
                )
                .reverse()
                .slice(0, 3),
            ),
            destinationRecentAirports: codesToAirports(
              updatedAirportsHashMap,
              recentLocationList.destinations[i]
                .filter(
                  flattenedDestinationCode =>
                    flattenedDestinationCode !== originCode &&
                    (!originCode ||
                      destinationAirports.some(
                        airport => flattenedDestinationCode === airport.code,
                      )),
                )
                .reverse()
                .slice(0, 3),
            ),
          };
        });
      } else {
        return null;
      }
    },
  );
