import {
  BookerMetrics,
  ContentMetrics,
  HeaderMetrics,
} from '../mocks/page-metrics.type';

export const sendBookerLoadFSEvent = (
  window: Window,
  payload: BookerMetrics,
) => {
  if (window['FS']) {
    window['FS'].event('Page Metrics: Booker Load', payload);
  }
};

export const sendHeaderLoadFSEvent = (
  window: Window,
  payload: HeaderMetrics,
) => {
  if (window['FS']) {
    window['FS'].event('Page Metrics: Header Load', payload);
  }
};

export const sendContentLoadFSEvent = (
  window: Window,
  payload: ContentMetrics,
) => {
  if (window['FS']) {
    window['FS'].event('Page Metrics: Content Load', payload);
  }
};

export const sendUpdatePaymentsFailureFSEvent = (
  window: Window,
  errorMessage: string,
) => {
  if (window['FS']) {
    window['FS'].event('UPDATE Payments Failure', {
      reason: errorMessage,
    });
  }
};

export const sendDeletePaymentsFailureFSEvent = (
  window: Window,
  errorMessage: string,
) => {
  if (window['FS']) {
    window['FS'].event('DELETE Payments Failure', {
      reason: errorMessage,
    });
  }
};

export const PAGES_WITH_THEIR_OWN_CONTENT_DONE_LOADING_CALL = ['route-map'];
