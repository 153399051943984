import { isValid, parse as parseDate } from 'date-fns';

export function parseDateQueryParameter(param: string): Date {
  let paramDate: Date = null;

  try {
    const parsedFullyQualified = parseDate(param, 'yyyy-MM-dd', new Date());
    const parsedWithoutDay = parseDate(param, 'yyyy-MM', new Date());

    if (parsedFullyQualified && isValid(parsedFullyQualified)) {
      paramDate = parsedFullyQualified;
    } else if (parsedWithoutDay && isValid(parsedWithoutDay)) {
      paramDate = parsedWithoutDay;
    }
  } catch (e) {
    paramDate = null;
  }

  return paramDate;
}
