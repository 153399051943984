<a
  rel="noopener"
  [jbSitemapLinker]="link.href"
  [target]="link.newWindow"
  class="icon-link pv2 flex display-copy-typeface royal-blue no-underline w-100 w-auto-ns body"
>
  <jb-icon
    [name]="link.image?.src"
    class="symbol-icon nt2 pointer v-top fill-royal-blue"
  ></jb-icon>

  <div class="pl2">
    <div>
        {{ link.title }}
        <dot-link-icon
          class="arrow-icon absolute"
          [newWindow]="link.newWindow === '_self' ? null : link.newWindow"
        >
        </dot-link-icon>
    </div>
    <div *ngIf="!!link.subtitle" class="db f6 lh-copy">{{
      link.subtitle
    }}</div>
  </div>


</a>
