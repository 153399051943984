import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { SubNavSection } from '@shared/header';
import { generateUniqueId } from '@shared/ui/utils/global-utils/generate-unique-id';
import { AuthFacade } from '@store/auth/auth.facade';
import { Subscription } from 'rxjs';

/**
 * @description Component on the header that contains all the site navigation to be used on a mobile viewport
 * @WhereIsItUsed Header mobile component
 */
@Component({
  selector: 'jb-subnav-mobile',
  templateUrl: './subnav-mobile.component.html',
  styleUrls: ['./subnav-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavMobileComponent implements OnDestroy {
  @Input('subNavSections') sections: SubNavSection[] = [];
  isLoggedIn = false;
  subnavWrapperId = generateUniqueId('subnav-wrapper');
  isMobile = true;
  private subscriptions: Subscription = new Subscription();

  constructor(private authFacade: AuthFacade) {
    this.subscriptions.add(
      this.authFacade.isProfileLoaded.subscribe(
        loggedIn => (this.isLoggedIn = loggedIn),
      ),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
