import {
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  fromIntersectionObserver,
  IntersectionStatus,
} from './from-intersection-observer';

@Directive({
  selector: '[jbIntersectionObserver]',
})
export class IntersectionObserverDirective implements OnInit {
  private _destroy = inject(DestroyRef);

  @Input() intersectionDebounce = 0;
  @Input() intersectionRootMargin = '0px';
  @Input() intersectionRoot: HTMLElement;
  @Input() intersectionThreshold: number | number[];
  @Input() cmsAutoLoadFlag: boolean;

  @Output() visibilityChange = new EventEmitter<IntersectionStatus>();

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    const canAutoLoad = this.cmsAutoLoadFlag;

    if (canAutoLoad) {
      const element = this.element.nativeElement;
      const config = {
        root: this.intersectionRoot,
        rootMargin: this.intersectionRootMargin,
        threshold: this.intersectionThreshold,
      };

      fromIntersectionObserver(element, config, this.intersectionDebounce)
        .pipe(takeUntilDestroyed(this._destroy))
        .subscribe(status => {
          this.visibilityChange.emit(status);
        });
    }
  }
}
