import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { WINDOW } from '@core/injection-tokens';
import { LanguageService } from '@core/language/language.service';
import { Observable } from 'rxjs';

import {
  DigestedDictionary,
  transformUrlDictionary,
} from './transform-dictionary';
import {
  CMS_PAGE_PREFIX,
  STATIC_PAGE_PREFIX,
} from './url-dictionary-versioning.interceptor.const';

@Injectable()
export class UrlDictionaryVersioningInterceptor implements HttpInterceptor {
  private enabled = this.appConfig.useUrlVersionDictionary;
  private dictionary: DigestedDictionary;
  private baseDomain: string;

  constructor(
    @Inject(WINDOW) private window,
    private appConfig: AppConfigService,
    private languageService: LanguageService,
  ) {
    this.baseDomain = this.appConfig.baseDomain;
    this.dictionary = transformUrlDictionary(
      this.window['__URL_VERSION_DICTIONARY'],
    );
  }

  private cloneRequestWithVersion(
    request: HttpRequest<any>,
    version: string,
  ): HttpRequest<any> {
    // Clone the request and set a new version
    return request.clone({
      params: request.params.set('ver', version),
    });
  }

  extractPageName(
    url: string,
    baseDomain: string,
    prefix: string,
  ): string | null {
    let pattern: RegExp;
    const escapedPrefix = prefix.replace(/\./g, '\\.'); // Escape dots in the base domain

    // Check if the URL starts with "http" or "https"
    if (url.startsWith('http')) {
      const escapedBaseDomain = baseDomain.replace(/\./g, '\\.'); // Escape dots in the base domain
      pattern = new RegExp(`^${escapedBaseDomain}.*${escapedPrefix}([^?#]+)`);
    } else {
      // Assume it's a relative URL
      pattern = new RegExp(`^${escapedPrefix}([^?#]+)`);
    }

    const match = url.match(pattern);
    const pageName = match?.[1]?.replace(/\/$/, '').replace(/\.json$/, ''); // strip slash or .json from the end
    return pageName ?? null;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.enabled) {
      return next.handle(request);
    }
    const lang = this.languageService.getLanguage();

    // Handle static content requests
    const staticPageName = this.extractPageName(
      request.url,
      this.baseDomain,
      STATIC_PAGE_PREFIX,
    );

    if (staticPageName) {
      const version = this.dictionary.static[lang]?.[staticPageName];

      if (version) {
        const newRequest = this.cloneRequestWithVersion(request, version);
        return next.handle(newRequest);
      }
    }

    // Handle CMS requests
    const cmsPageName = this.extractPageName(
      request.url,
      this.baseDomain,
      CMS_PAGE_PREFIX,
    );
    if (cmsPageName) {
      const version = this.dictionary.cms[lang]?.[cmsPageName];

      if (version) {
        const newRequest = this.cloneRequestWithVersion(request, version);
        return next.handle(newRequest);
      }
    }

    return next.handle(request);
  }
}
