import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WindowService } from '@core/window-actions';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Weekdays } from './types';
import {
  weekdaysBarInitialState,
  WeekdaysBarState,
  WeekdaysBarViewModel,
} from './weekdays-bar.component.state';

/**
 * @description Display all the days of the week
 * @WhereIsItUsed best-fare-calendar
 */
@Component({
  selector: 'jb-weekdays-bar',
  templateUrl: './weekdays-bar.component.html',
  styleUrls: ['./weekdays-bar.component.scss'],
  providers: [ComponentStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekdaysBarComponent {
  @Input() set content(content: Weekdays) {
    this.store.patchState({ content });
  }

  readonly weekdaysLabel$: Observable<string[]> = this.store.select(
    this.store.select(state => state.content),
    this.store.select(state => state.isMobile),
    (content, isMobile) =>
      isMobile ? content.weekdaysLabelAbbrv : content.weekdaysLabel,
  );

  readonly vm$: Observable<WeekdaysBarViewModel> = combineLatest([
    this.store.state$,
    this.weekdaysLabel$,
  ]).pipe(map(([state, weekdaysLabel]) => ({ ...state, weekdaysLabel })));

  constructor(
    private windowService: WindowService,
    private store: ComponentStore<WeekdaysBarState>,
  ) {
    this.store.setState(weekdaysBarInitialState);

    this.store.patchState(
      this.windowService.isMobile.pipe(map(isMobile => ({ isMobile }))),
    );
  }
}
