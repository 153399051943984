import { ChangeDetectionStrategy, Component } from '@angular/core';
import { JbDialogService } from 'jb-component-library';

/**
 * @description Dialog used in conjunction with debug component that displays a json view of dialog content
 * @WhereIsItUsed debug-component
 */
@Component({
  selector: 'jb-dialog-debug',
  templateUrl: './dialog-debug.component.html',
  styleUrls: ['./dialog-debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDebugComponent {
  dotDebugContent: any;
  constructor(private dialogService: JbDialogService) {}

  handleData = (dotDebugContent: any) => {
    this.dotDebugContent = dotDebugContent;
  };
  closeDialog() {
    this.dialogService.closeDialog();
  }
}
