import { invertObj } from 'ramda';

import { AuthActions } from '../auth/auth.actions';
import { ProfileActions } from '../profile/profile.actions';

const typeMap: { [pendingType: string]: string } = {};

typeMap.TEST_ACTION = 'TEST_ACTION_RESOLVED'; // For Unit Testing
typeMap[ProfileActions.LOAD_PROFILE] = ProfileActions.PROFILE_LOADED;
typeMap[AuthActions.LOGIN_USER_OKTA] = AuthActions.OKTA_USER_LOGGED_IN;

/**
 * Map of pending action types (keys) to their resolved action types (values).
 *
 * When a new async pair is added to the application, add their types here so
 * that the API reducer responds to their state transitions appropriately.
 */

export const pendingToResolvedActionTypeMap = typeMap;

export const pendingActionTypes = Object.keys(pendingToResolvedActionTypeMap);

/**
 * Object.values fails in build.
 * Appears to be specific to Windows. Reverting to map to resolve, see resource below for details.
 * https://stackoverflow.com/questions/38748445/uncaught-typeerror-object-values-is-not-a-function-javascript
 */
// const resolutionActionTypes = Object.values(pendingToResolvedActionTypeMap);
/* tslint:enable:max-len */
export const resolutionActionTypes = Object.keys(
  pendingToResolvedActionTypeMap,
).map(key => {
  return pendingToResolvedActionTypeMap[key];
});
export const resolvedToPendingActionTypeMap = invertObj(
  pendingToResolvedActionTypeMap,
);
