import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  inject,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppConfigService } from '@core/app-config';
import {
  CmsValidationService,
  SESSION_KEY_RETRIES,
} from '@core/cms/validation/cms-validation.service';
import { DynamicComponentsService } from '@core/dynamic-components/index';
import {
  CMSComponentData,
  isPromiseFulfilled,
  LoadedModuleAndData,
  Registry,
} from '@core/dynamic-components/types';
import { Templates } from '@dynamic-templates/types/templates.type';
import { GlobalFacade } from '@store/global/global.facade';
import { ObservableInput } from 'ngx-observable-input';
import { equals } from 'ramda';
import { Observable, Subject, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * @description Component responsible for dynamically rendering components
 * @WhereIsItUsed
 *  - tab component
 *  - section container
 *  - basic template
 */
@Component({
  standalone: true,
  imports: [],
  selector: 'jb-renderer-template',
  template: `<ng-template #container></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RendererTemplateComponent implements AfterViewInit {
  private _destroy = inject(DestroyRef);

  @Input() priority: 'primary' | 'secondary' | 'tertiary';
  @ObservableInput() @Input('components') components: Observable<
    CMSComponentData[]
  >;
  @ObservableInput() @Input('registry') registry: Observable<Registry>;

  @Input() template: keyof Templates;
  @ViewChild('container', { read: ViewContainerRef })
  container;
  private componentRefs: ComponentRef<any>[] = [];
  private _updateRenderer$: Subject<{ priority: string; status: boolean }> =
    new Subject();

  constructor(
    public dynamicComponentsService: DynamicComponentsService,
    private globalFacade: GlobalFacade,
    public appConfig: AppConfigService,
    private cdRef: ChangeDetectorRef,
    private cmsValidationService: CmsValidationService,
  ) {
    this._updateRenderer$
      .pipe(takeUntilDestroyed(this._destroy), debounceTime(0))
      .subscribe(({ priority, status }) => {
        if (priority === 'primary') {
          this.globalFacade.setPrimaryRenderingStatus(status);
        } else if (priority === 'secondary') {
          this.globalFacade.setSecondaryRenderingStatus(status);
        } else if (priority === 'tertiary') {
          this.globalFacade.setTertiaryRenderingStatus(status);
        }
      });
  }

  ngAfterViewInit() {
    if (!this.container) {
      return;
    }
    zip(this.components, this.registry)
      .pipe(
        distinctUntilChanged((prev, next) => equals(prev, next)),
        takeUntilDestroyed(this._destroy),
      )
      .subscribe(([components, registry]) => {
        this.componentRefs.forEach(ref => ref.destroy());
        const loadedComponentFactories = components
          // componentData comes from cms dynamic component metadata
          .filter(componentData =>
            this.dynamicComponentsService.checkComponentMap(
              componentData,
              this.appConfig.isProdEnv,
            ),
          )
          .map(async componentData => {
            if (this.appConfig.truncatedResponseRetries) {
              this.cmsValidationService.validateNodeAndRetry(
                componentData,
                SESSION_KEY_RETRIES,
              );
            }
            const loadedModule =
              await this.dynamicComponentsService.loadComponentConstructor(
                componentData,
              );
            return {
              loadedModule,
              componentData,
            };
          });

        if (loadedComponentFactories.length !== 0) {
          if (this.priority === 'primary') {
            this.globalFacade.setPrimaryRenderingStatus(false);
          } else if (this.priority === 'secondary') {
            this.globalFacade.setSecondaryRenderingStatus(false);
          } else if (this.priority === 'tertiary') {
            this.globalFacade.setTertiaryRenderingStatus(false);
          }
        }

        this.container.clear();
        this.renderComponents(loadedComponentFactories, registry);
      });
  }

  ngOnDestroy() {
    this.componentRefs.forEach(ref => ref.destroy());
    if (this.container) {
      this.container.clear();
    }
  }

  async renderComponents(
    items: Promise<LoadedModuleAndData>[],
    componentRegistry,
  ) {
    const allSettledItems = await Promise.allSettled(items);
    try {
      allSettledItems.forEach((item, index) => {
        if (isPromiseFulfilled(item)) {
          const newComponent = this.dynamicComponentsService.createComponent(
            this.container,
            item.value,
            componentRegistry,
            this.template,
          );
          if (newComponent) {
            newComponent.location.nativeElement.setAttribute(
              'data-order',
              index + 1,
            );
            this.componentRefs.push(newComponent);
          }
        } else {
          // promise rejected
          throw new Error(item.reason);
        }
      });
    } catch (error) {
      console.error(error);
    }

    this.cdRef.detectChanges();
    // Report after detecting changes to ensure all components are rendered
    // If truncated template is detected, we need to wait for purge request to fulfill
    // This ensures odd render state doesn't appear for truncated edge case
    // Only applies when feature flag is enabled
    if (
      !this.cmsValidationService.isTruncatedDetectionEnabled ||
      this.cmsValidationService.isCdnPurgeComplete
    ) {
      /**
       * We cant use the default approach to set the page as loaded when using the OKTA Widget.
       * In this case, the sign-in page will handle the status for setPrimaryRenderingStatus.
       * We are only going to set the Primary Rendering Status as true when the Widget is fully loaded.
       * REF: DOT-10828
       */
      if (
        this.priority === 'primary' &&
        componentRegistry &&
        Object.keys(componentRegistry)?.some(key => key === 'login') &&
        this.appConfig.useWidget
      ) {
        this._updateRenderer$.next({ priority: this.priority, status: false });
      } else {
        this._updateRenderer$.next({ priority: this.priority, status: true });
      }
    }
  }
}
