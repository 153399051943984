<div
  class="clendar-err-div pt0 ph0-ns flex items-center"
  [ngStyle]="{ 'min-height': height }"
>
  <div>
    <jb-text-image [content]="[noFlightContent]"></jb-text-image>
    <div class="gr-unset center nt3 nt6-ns">
      <div class="dib col-4-m col-4-l"></div>
      <div class="dib col-4-s col-8-m col-8-l pl4-ns reload-btn-div">
        <button
          *ngIf="!last"
          #myButton
          jbActionLink
          class="ph0"
          (click)="viewNextMonth.next()"
        >
          <jb-sr-only>{{ title }}</jb-sr-only>
          <span>View next month</span>
        </button>
      </div>
    </div>
  </div>
</div>
