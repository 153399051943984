import {
  SelectedTravelers,
  TravelerAllowedRange,
  TravelerGroup,
} from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import { BOOKER_CONFIG } from '@shared/booker/booker.config';
import { TravelerSelector } from '@shared/booker/types/traveler-selector.type';

const {
  PAX: { MAX_UNACCOMPANIED_MINORS, MAX_INFANTS, GUEST_LIMIT },
} = BOOKER_CONFIG;

// generates a readable string such as "2 adults, 3 children, 1 infant"
export function getTravelersSummary(
  travelerGroup: TravelerGroup,
  content: TravelerSelector,
  compact: boolean,
  rooms: number = null,
) {
  const travelerLabels = {
    adults: { singular: content.adult, plural: content.adults },
    children: { singular: content.kid, plural: content.kids },
    infants: { singular: content.lapInfant, plural: content.lapInfants.title },
  };
  const totalTravelers =
    travelerGroup.adults + travelerGroup.children + travelerGroup.infants;

  let summary = '';
  if (
    compact &&
    travelerGroup.adults > 0 &&
    travelerGroup.children > 0 &&
    travelerGroup.infants > 0
  ) {
    summary = rooms
      ? `${rooms} ${rooms === 1 ? content.room : content.rooms}, `
      : '';
    summary += `${totalTravelers} ${content.travelers}`;
  } else if (totalTravelers === 0) {
    if (compact) {
      summary = `${content.travelers}`;
    } else {
      summary = `0 ${content.travelers}`;
    }
  } else {
    summary = rooms
      ? `${rooms} ${rooms === 1 ? content.room : content.rooms}, `
      : '';

    summary += `${['adults', 'children', 'infants']
      .filter(travelerType => travelerGroup[travelerType] > 0)
      .map(travelerType => {
        const quantity = travelerGroup[travelerType];
        const travelerLabel =
          quantity === 1
            ? travelerLabels[travelerType].singular
            : travelerLabels[travelerType].plural;
        return `${quantity} ${travelerLabel}`;
      })
      .join(', ')}`;
  }
  return summary;
}

export function toSelectedTravelers(
  travelerGroups: TravelerGroup[],
): SelectedTravelers {
  return {
    allTravelers: travelerGroups.reduce(
      (merged, travelerGroup) => ({
        adults: travelerGroup.adults + merged.adults,
        children: travelerGroup.children + merged.children,
        infants: travelerGroup.infants + merged.infants,
        childAges: [...merged.childAges, ...(travelerGroup.childAges ?? [])],
      }),
      { adults: 0, children: 0, infants: 0, childAges: [] },
    ),
    groups: travelerGroups,
  };
}

export function getTravelerAllowedRange(
  { allTravelers, groups }: SelectedTravelers,
  infantsCountTowardsTotalTravelers: boolean,
  allowUnaccompaniedMinors: boolean,
): TravelerAllowedRange[] {
  const availableAdditionalTravelers = Math.max(
    0,
    GUEST_LIMIT -
      (allTravelers.adults +
        allTravelers.children +
        (infantsCountTowardsTotalTravelers ? allTravelers.infants : 0)),
  );
  const availableInfants = Math.max(0, MAX_INFANTS - allTravelers.infants);
  return groups.map(({ adults, children, infants }) => {
    return {
      adults: {
        min: allowUnaccompaniedMinors ? 0 : 1,
        max: adults + availableAdditionalTravelers,
      },
      children: {
        min: 0,
        max:
          allTravelers.adults === 0
            ? MAX_UNACCOMPANIED_MINORS
            : children + availableAdditionalTravelers,
      },
      infants: {
        min: 0,
        max: infantsCountTowardsTotalTravelers
          ? Math.min(
              adults,
              infants + availableInfants,
              infants + availableAdditionalTravelers,
            )
          : Math.min(adults, infants + availableInfants),
      },
    };
  });
}

// Determines if selected travelers are at their allowed limits
export function shouldWarnTravelerRule(
  ruleId: string,
  selectedTravelers: SelectedTravelers,
  includeInfants: boolean,
): boolean {
  const allTravelers = selectedTravelers.allTravelers;

  switch (ruleId) {
    case 'MAX-TRAVELERS':
      return countTravelers(allTravelers, includeInfants) >= GUEST_LIMIT;
    case 'MAX-CHILDREN':
      return (
        allTravelers.adults === 0 &&
        allTravelers.children >= MAX_UNACCOMPANIED_MINORS
      );
    case 'MAX-INFANTS':
    case 'MAX-LAP-INFANTS':
      return allTravelers.infants >= Math.min(MAX_INFANTS, allTravelers.adults);
    case 'MIN-ADULT':
      return (
        selectedTravelers.groups.length > 1 &&
        selectedTravelers.groups.some(
          travelerGroup => travelerGroup.adults === 1,
        )
      );
    case 'FOR-BEST-RESULTS':
      return selectedTravelers.groups.some(
        travelerGroup => countTravelers(travelerGroup, false) >= 4,
      );
    default:
      return null;
  }
}

export function countTravelers(
  { adults, children, infants }: TravelerGroup,
  includeInfants,
) {
  return adults + children + (includeInfants ? infants : 0);
}

export function travelersExceedAllowedRange(
  selectedTravelers: SelectedTravelers,
  travelerAllowedRange: TravelerAllowedRange[],
): boolean {
  return selectedTravelers.groups.some(
    (travelerGroup, index) =>
      travelerGroup?.adults > travelerAllowedRange[index]?.adults.max ||
      travelerGroup?.children > travelerAllowedRange[index]?.children.max ||
      travelerGroup?.infants > travelerAllowedRange[index]?.infants.max,
  );
}

export function updateTravelerGroupsWithinAllowedRange(
  selectedTravelers: SelectedTravelers,
  travelerAllowedRange: TravelerAllowedRange[],
): TravelerGroup[] {
  return selectedTravelers.groups.map((travelerGroup, index) => ({
    adults: Math.min(
      travelerGroup.adults,
      travelerAllowedRange[index].adults.max,
    ),
    children: Math.min(
      travelerGroup.children,
      travelerAllowedRange[index].children.max,
    ),
    infants: Math.min(
      travelerGroup.infants,
      travelerAllowedRange[index].infants.max,
    ),
    ...(travelerGroup.childAges
      ? {
          childAges: travelerGroup.childAges.slice(
            0,
            Math.min(
              travelerGroup.children,
              travelerAllowedRange[index].children.max,
            ),
          ),
        }
      : {}),
  }));
}
