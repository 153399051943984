import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { AppState } from '../shared/types/app-state.type';
import { CartData, CartState } from './types';

export const cart: MemoizedSelector<AppState, CartState> =
  createFeatureSelector('cart');

export const isCartEmpty: MemoizedSelector<AppState, boolean> = createSelector(
  cart,
  state => {
    return state.isCartEmpty;
  },
);

export const cartData: MemoizedSelector<AppState, CartData[]> = createSelector(
  cart,
  state => {
    return state.cartData;
  },
);
