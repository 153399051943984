import { Injectable } from '@angular/core';

import { StoreService } from '../shared/store.service';
import {
  ForgotPasswordPageDestroyed,
  PasswordLinkRequestSucceeded,
  PasswordResetLinkRequested,
  ResetPasswordRequested,
  SetEmailFromSignInPage,
} from './forgot-password.actions';
import * as ForgetPasswordSelectors from './forgot-password.selectors';
import { ResetPasswordRequestedState } from './types';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordFacade {
  serviceErrorMessage = '';

  emailEntryError = this.storeService.getState(
    ForgetPasswordSelectors.forgotPasswordEmailEntryError,
  );
  error = this.storeService.getState(
    ForgetPasswordSelectors.forgotPasswordStateError,
  );
  isLoading = this.storeService.getState(
    ForgetPasswordSelectors.forgotPasswordIsLoading,
  );
  isLoadingFromEmailEntry = this.storeService.getState(
    ForgetPasswordSelectors.forgotPasswordEmailEntryIsLoading,
  );
  mostRecentTraceId = this.storeService.getState(
    ForgetPasswordSelectors.mostRecentTraceId,
  );
  emailFromSignInPage = this.storeService.getState(
    ForgetPasswordSelectors.emailFromSignInPage,
  );

  constructor(private storeService: StoreService) {}

  requestPwdLink(email: string) {
    this.storeService.dispatchAction(new PasswordResetLinkRequested(email));
  }

  resetPasswordRequested(newPasswordRequestObj: ResetPasswordRequestedState) {
    this.storeService.dispatchAction(
      new ResetPasswordRequested(newPasswordRequestObj),
    );
  }

  passwordLinkRequestSucceeded(traceId: string) {
    this.storeService.dispatchAction(new PasswordLinkRequestSucceeded(traceId));
  }

  setEmailFromSignInPage(email: string) {
    this.storeService.dispatchAction(new SetEmailFromSignInPage(email));
  }

  pageDestroyed() {
    this.storeService.dispatchAction(new ForgotPasswordPageDestroyed());
  }
}
