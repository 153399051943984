import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIModule } from '../ui';
import { ConfirmationBarComponent } from './confirmation-bar.component';

const MODULES = [CommonModule, UIModule];

const DECLARATIONS = [ConfirmationBarComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class ConfirmationBarModule {}
