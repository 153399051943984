import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { HOST } from '@core/injection-tokens';
import {
  getQueryParametersAsObject,
  keepOnlyRelativePathIfInSitemap,
  removeParams,
} from '@shared/ui/links/linkers/linker.utils';
import { SITEMAP_DICTIONARY } from '@shared/ui/links/linkers/sitemap-dictionary.token';

@Pipe({
  name: 'routerLinkFormatter',
})
export class RouterLinkUrlFormatterPipe implements PipeTransform {
  constructor(
    @Inject(SITEMAP_DICTIONARY) @Optional() private sitemapDictionary,
    @Inject(HOST) private host: string,
  ) {}

  transform(
    submittedLink: string,
    partial: 'url' | 'params' | 'full' = 'url',
  ): string | Params {
    // if there is a submitted link, use logic to decide what sort of sub-value to return,
    // otherwise return an empty string
    if (submittedLink) {
      // updated sitemap dictionary that includes /best-fare-finder as a valid internal link
      const sitemapDictionary = {
        ...this.sitemapDictionary,
        '/best-fare-finder': '/best-fare-finder',
      };

      const submittedLinkIsRelative = submittedLink.startsWith('/');

      const url = submittedLinkIsRelative
        ? new URL(this.host + submittedLink)
        : new URL(submittedLink);

      const relativePathFromSitemap = keepOnlyRelativePathIfInSitemap(
        url,
        sitemapDictionary,
        this.host,
      );

      const relativeUrlOrParam: string | Params =
        !relativePathFromSitemap || partial === 'url' || !partial
          ? removeParams(relativePathFromSitemap)
          : getQueryParametersAsObject(relativePathFromSitemap);

      if (partial === 'full') {
        return relativePathFromSitemap || submittedLink;
      }

      return relativeUrlOrParam || partial === 'url'
        ? relativeUrlOrParam
        : null;
    } else {
      return '';
    }
  }
}
