import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type Modals = 'nav' | 'signIn' | 'loggedIn';
type ModalMap = { [key in Modals]: boolean };

const defaultModalMap: ModalMap = {
  nav: false,
  signIn: false,
  loggedIn: false,
};

@Injectable()
export class HeaderService {
  modalMap$: BehaviorSubject<ModalMap> = new BehaviorSubject<ModalMap>(
    defaultModalMap,
  );

  showHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isNavMenuOpen$: Observable<boolean> = this.modalMap$.pipe(
    map(modalMap => modalMap.nav),
  );
  isSignInModalOpen$: Observable<boolean> = this.modalMap$.pipe(
    map(modalMap => modalMap.signIn),
  );
  isLoggedInModalOpen$: Observable<boolean> = this.modalMap$.pipe(
    map(modalMap => modalMap.loggedIn),
  );
  isModalOpen$: Observable<boolean> = this.modalMap$.pipe(
    map(modalMap => Object.values(modalMap).some(value => value)),
  );

  showHeader() {
    this.showHeader$.next(true);
  }

  toggleNavMenu() {
    this.modalMap$.next({
      ...defaultModalMap,
      nav: !this.modalMap$.getValue().nav,
    });
  }

  toggleSignInModal() {
    this.modalMap$.next({
      ...defaultModalMap,
      signIn: !this.modalMap$.getValue().signIn,
    });
  }

  toggleLoggedInModal() {
    this.modalMap$.next({
      ...defaultModalMap,
      loggedIn: !this.modalMap$.getValue().loggedIn,
    });
  }

  closeAllModals() {
    this.modalMap$.next(defaultModalMap);
  }
}
