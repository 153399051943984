import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ItineraryApi } from './api';
import {
  CheckForItinerary,
  ItineraryActions,
  ItineraryError,
  ItineraryFound,
} from './itinerary.actions';

@Injectable()
export class ItineraryEffects {
  constructor(
    private actions$: Actions,
    private itineraryApi: ItineraryApi,
  ) {}

  checkItinerary$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckForItinerary>(ItineraryActions.CHECK_FOR_ITINERARY),
      mergeMap(() =>
        this.itineraryApi.itineraryCheck().pipe(
          map((response: any) => {
            if (Array.isArray(response)) {
              // If the response is an array, handle it as a successful response (array of upcoming flights)
              return new ItineraryFound(response);
            } else if (response.error) {
              // If the response contains an error property, handle it as an error response
              return new ItineraryError(response.error);
            }
            throw new Error('Unexpected response format');
          }),
          catchError(error => of(new ItineraryError(error))),
        ),
      ),
    ),
  );
}
