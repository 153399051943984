import { BookProfile } from '../cart/types/book-profile.interface';
import { Action } from '../shared/types/action.interface';
import { TrueBlueProfile } from '../trueblue/types';
import { Pay } from './types/profile-details-response.interface';

export enum ProfileActions {
  LOAD_PROFILE = '[TB Logged-in Component] Load Profile',
  PROFILE_LOADED = '[PROFILE] Profile Loaded',
  RWB_PROFILE_REQUESTED = '[PROFILE] RWB PROFILE REQUESTED',
  RWB_PROFILE_SUCCEEDED = '[PROFILE] RWB PROFILE SUCCEEDED',
  RWB_PROFILE_FAILED = '[PROFILE] RWB PROFILE FAILED',
  LOAD_TRAVEL_BANK_SUCCEEDED = '[PROFILE] LOAD TRAVEL BANK SUCCEEDED',
}

export class LoadProfile implements Action {
  readonly type = ProfileActions.LOAD_PROFILE;
  constructor(public payload: { authToken: string; xAuthToken?: string }) {}
}

export class ProfileLoaded implements Action {
  readonly type = ProfileActions.PROFILE_LOADED;
  constructor(public payload: { profile: TrueBlueProfile }) {}
}

export class RwbProfileRequested implements Action {
  readonly type = ProfileActions.RWB_PROFILE_REQUESTED;
  constructor(
    public accessToken: string,
    public xAuthToken?: string,
  ) {}
}

export class RwbProfileSucceeded implements Action {
  readonly type = ProfileActions.RWB_PROFILE_SUCCEEDED;
  constructor(public response: BookProfile) {}
}

export class RwbProfileFailed implements Action {
  readonly type = ProfileActions.RWB_PROFILE_FAILED;
}

export class LoggedInPaySucceeded implements Action {
  readonly type = ProfileActions.LOAD_TRAVEL_BANK_SUCCEEDED;
  constructor(public payload: Pay[]) {}
}

export type ProfileActionsUnion =
  | LoadProfile
  | ProfileLoaded
  | RwbProfileRequested
  | RwbProfileSucceeded
  | RwbProfileFailed
  | LoggedInPaySucceeded;
