<ng-container *ngIf="!!bodyTextContent && !!bodyTextContent?.length">
  <div
    [ngClass]="
      !insideAccordion
        ? 'jb-body-text jb-grid gr-unset one-up grid-offset-l mb0 klarheit lh-copy charcoal'
        : ''
    "
  >
    <jb-inner-html
      jbSmartLink
      class="lh-copy"
      [innerHTML]="bodyTextContent[0]?.content"
      [theme]="theme"
    ></jb-inner-html>
  </div>
  <div
    *ngIf="bodyTextContent[0]?.link?.length || bodyTextContent[0]?.button"
    [ngClass]="
      !insideAccordion
        ? 'jb-body-text jb-grid gr-unset one-up grid-offset-l mb0 klarheit lh-copy charcoal cta pt3'
        : ''
    "
  >
    <ng-container
      *ngIf="bodyTextContent[0]?.link && bodyTextContent[0]?.link?.length"
    >
      <div *ngFor="let linkProperty of bodyTextContent[0].link">
        <div class="pv2">
          <a
            [jbSitemapLinker]="
              linkProperty?.href
                ? linkProperty?.href
                : linkProperty?.showChat === 'true' ||
                  linkProperty?.isCookieConsent === 'true'
                ? 'ASAPP'
                : null
            "
            [dotLinkIcon]="linkProperty?.newWindow"
            (click)="
              onClick(
                $event,
                linkProperty?.showChat,
                linkProperty?.cookieConsent
              )
            "
            jbActionLink
            >{{ linkProperty?.title }}</a
          >
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="bodyTextContent[0]?.button && bodyTextContent[0]?.button?.length"
    >
      <div *ngFor="let button of bodyTextContent[0]?.button">
        <div class="pt2 pb3">
          <a
            jbButton
            [jbSitemapLinker]="
              button?.href
                ? button?.href
                : button?.showChat === 'true' ||
                  button?.isCookieConsent === 'true'
                ? 'ASAPP'
                : null
            "
            [target]="button.newWindow"
            [theme]="theme === themeEnum.dark ? 'ctaSecondary' : 'ctaPrimary'"
            (click)="onClick($event, button?.showChat, button?.cookieConsent)"
            class="ph4"
          >
            {{ button.title }}
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
