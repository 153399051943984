<div
  id="{{ id }}"
  class="col-12-l col-12-m col-4-s bg-core-blue confirmation-bar"
  [class.page-margin]="!isMobile"
  attr.aria-label="{{ content.total }} {{ totalFares }} {{
    travelerText
  }} See Flights"
  data-qaid="seeFlights"
>
  <div
    class="flex justify-between pv0 pv2-ns jb-max-width center flex-wrap flex-nowrap-ns"
  >
    <div
      class="flex col-9-l col-8-m col-4-s white pv2 ph3 ph0-ns flex-row flex-column-ns"
    >
      <p class="white b w-70 w-100-ns mb0 pb0 pt1 pt2-ns">
        <span class="mr1" data-qaid="contentTotal">{{ content.total }}</span>
        <span data-qaid="totalFares">{{ totalFares }}</span>
      </p>
      <p
        class="white s-body w-30 w-100-ns mb0 pt1 pt0-ns tr tl-ns"
        data-qaid="travelerText"
      >
        {{ travelerText }}
      </p>
    </div>
    <div class="fr see-flights-btn w-100 w-auto-ns pv0 pv2-ns">
      <ng-content></ng-content>
    </div>
  </div>
</div>
