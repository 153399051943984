<ng-container *ngIf="vm$ | async as vm">
  <div #calendarContainer [attr.id]="vm.id">
    <div
      *ngIf="vm.legalCopy && vm.isFirstLoadedCalendarMonth"
      class="col-12-l col-12-m col-4-s mb4 pv3 pv0-ns ph3 ph0-ns bg-off-white legal-container"
    >
      <span class="db legal charcoal"> {{ vm.legalCopy }} </span>
    </div>

    <jb-calendar-title
      class="db"
      [month]="vm.calendarMonth.month"
      [year]="vm.calendarMonth.year"
      [isLoading]="!vm.calendarMonth.isLoaded || vm.calendarMonth.isLoading"
    ></jb-calendar-title>

    <div class="flex justify-center ph2 ph0-ns">
      <button
        jbButton
        theme="ctaPrimary"
        type="button"
        class="absolute self-center mw5 z-3"
        (click)="fetchFares(vm.calendarMonth.month, vm.calendarMonth.year)"
        *ngIf="!canAutoLoad && vm.showLoadButton"
        tabindex="0"
      >
        Load {{ vm.calendarMonth.month | jbMonthName }} fares
      </button>
      <ng-container *ngIf="!vm.calendarMonth.isServerError; else calendarErr">
        <table
          *ngIf="
            !vm.calendarMonth.isLoaded ||
              vm.calendarMonth.isLoading ||
              vm.hasFlights;
            else noFlight
          "
          class="col-12-l col-12-m col-4-s ph2 ph6-m center calendar slate-gray"
        >
          <thead class="clip">
            <tr id="weekdays">
              <th id="Sunday">Sunday</th>
              <th id="Monday">Monday</th>
              <th id="Tuesday">Tuesday</th>
              <th id="Wednesday">Wednesday</th>
              <th id="Thursday">Thursday</th>
              <th id="Friday">Friday</th>
              <th id="Saturday">Saturday</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let week of vm.daysOfMonthGroupedByWeek">
              <tr class="flex justify-center">
                <ng-container *ngFor="let day of week; let dayOfWeek = index">
                  <jb-calendar-date-box
                    [dayOfWeek]="dayOfWeek"
                    class="relative date-box bg-white charcoal db"
                    [isLoading]="vm.calendarMonth.isLoading"
                    [isLoaded]="vm.calendarMonth.isLoaded"
                    [day]="day"
                    [isOneWay]="vm.isOneWay"
                    [month]="vm.calendarMonth.month"
                    [year]="vm.calendarMonth.year"
                    [content]="vm.content"
                    [selectedDepart]="vm.form.departBff"
                    [selectedReturn]="vm.form.returnBff"
                    [isPoints]="vm.form.bookWithPoints"
                    [maxSeats]="vm.maxSeats"
                  ></jb-calendar-date-box>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>

  <ng-template #calendarErr>
    <jb-calendar-error
      [content]="vm.content"
      [month]="vm.calendarMonth.month"
      [height]="vm.minHeight"
      (updateFaresEvent)="
        fetchFares(vm.calendarMonth.month, vm.calendarMonth.year)
      "
    >
    </jb-calendar-error>
  </ng-template>

  <ng-template #noFlight>
    <jb-calendar-no-flights
      [height]="vm.minHeight"
      [last]="vm.isLastMonthOfCalendar"
      (viewNextMonth)="
        onViewNextMonth(vm.calendarMonth.month, vm.calendarMonth.year)
      "
    ></jb-calendar-no-flights>
  </ng-template>
</ng-container>
