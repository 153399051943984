import { Injectable, NgZone } from '@angular/core';
import { Data, Params } from '@angular/router';
import { AnalyticsService, JBABTestService, RouterService } from '@core/index';
import { WidgetService } from '@core/widgets';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import {
  ROUTER_NAVIGATED,
  ROUTER_NAVIGATION,
  RouterNavigatedAction,
} from '@ngrx/router-store';
import { CanonicalService } from '@shared/ui';
import { combineLatest, from as observableFrom, of, timer } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  share,
  switchAll,
  switchMap,
  takeWhile,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';

import { PersonalizationService } from '../../core/cms/personalization/personalization.service';
import { AnalyticsFacade } from '../analytics/analytics.facade';
import { AuthFacade } from '../auth/auth.facade';
import { GlobalFacade } from '../global/global.facade';
import { RouterFacade } from './router.facade';

@Injectable()
export class RouterEffects {
  routerNavigated$ = this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    distinctUntilChanged(
      (prevAction: RouterNavigatedAction, nextAction: RouterNavigatedAction) =>
        prevAction.payload.routerState.url.split('?')[0] ===
        nextAction.payload.routerState.url.split('?')[0],
    ),
    share(),
  );

  // observers when angular router triggers a navigation
  routerNavigation$ = this.actions$.pipe(ofType(ROUTER_NAVIGATION), share());
  onInit$ = this.actions$.pipe(ofType(ROOT_EFFECTS_INIT), share());

  routeDataAfterNavigation$ = this.routerNavigation$.pipe(
    withLatestFrom(this.routerFacade.routeData),
    map(([action, routeData]) => routeData),
    share(),
  );

  // when angular router starts a navigation, this eventually invoke
  // getTempateData passing the routeData to request dynamic components
  routeDataAndQueryParamsAfterNavigation$ = this.routerNavigation$.pipe(
    withLatestFrom(
      combineLatest([
        // routeData is provided by the sitemap
        this.routerFacade.routeData,
        this.routerFacade.queryParams,
      ]).pipe(map(([routeData, queryParams]) => ({ routeData, queryParams }))),
    ),
    map(([action, data]) => data),
    share(),
  );

  isTemplateRequestNeeded(params: {
    isProfileLoaded: boolean;
    routeData: Data;
    queryParams: Params;
    navigate: any;
    lastRequestedEndpoint: string;
  }): boolean {
    const {
      isProfileLoaded,
      routeData,
      queryParams,
      navigate,
      lastRequestedEndpoint,
    } = params;

    const previousBase = this.routerService.extractBaseURL(
      navigate.previousUrl === '' ? navigate.url : navigate.previousUrl,
    );
    const currentBase = this.routerService.extractBaseURL(navigate.url);

    const isTransitionWithinSamePage = previousBase === currentBase;

    /* If routeData does not contain an endpoint, it indicates that this page is not published on Magnolia.
     * In this case, avoid calling the Magnolia endpoint. */
    if (!routeData.endpoint) {
      return false;
    }

    // Check if one of these values are true. If it is true, make a call to Magnolia to fetch the CMS content.
    const hasNewEndpoint = routeData.endpoint !== lastRequestedEndpoint;
    const hasTranslation = Boolean(queryParams?.translateLanguage);
    const isNavigatingAway = isProfileLoaded && !isTransitionWithinSamePage;

    return hasNewEndpoint || hasTranslation || isNavigatingAway;
  }

  constructor(
    private actions$: Actions,
    private routerFacade: RouterFacade,
    private routerService: RouterService,
    private analyticsFacade: AnalyticsFacade,
    private globalFacade: GlobalFacade,
    private zone: NgZone,
    private abTestService: JBABTestService,
    private canonicalService: CanonicalService,
    private authFacade: AuthFacade,
    private analyticsService: AnalyticsService,
    private widgetService: WidgetService,
    private personalizationService: PersonalizationService,
  ) {}

  handleASAPPNavigation$ = createEffect(
    () =>
      this.routerNavigated$.pipe(
        delay(100),
        tap(data => {
          // Suspecting that asapp not detecting the change in url in time 8% of the time.
          this.widgetService.handleASAPPWidgetVisibility(
            data.payload.event.url,
          );
        }),
      ),
    { dispatch: false },
  );

  scrollToTopOnNavigated$ = createEffect(
    () =>
      this.routerNavigated$.pipe(
        map(data => {
          if (!data?.payload?.event?.url?.includes('#')) {
            this.routerService.scrollToTop();
          }
        }),
      ),
    { dispatch: false },
  );

  updateCanonicalLinkOnNavigated$ = createEffect(
    () =>
      this.routerNavigated$.pipe(
        map((response: RouterNavigatedAction) => {
          this.canonicalService.updateCanonicalURL(
            response.payload.routerState.url,
          );
        }),
      ),
    { dispatch: false },
  );

  dispatchAnalyticsEvent$ = createEffect(
    () =>
      this.routerNavigated$.pipe(
        tap(() => this.analyticsFacade.trackSubmitEventCall()),
      ),
    { dispatch: false },
  );

  setPageTitle$ = this.routeDataAndQueryParamsAfterNavigation$.pipe(
    filter(data => Boolean(data.routeData)),
    tap(data => this.routerService.setPageTitle(data.routeData)),
    distinctUntilChanged((prev, curr) => {
      return (
        prev.routeData?.path === curr.routeData?.path &&
        prev.routeData?.endpoint === curr.routeData?.endpoint
      );
    }),
  );

  waitForLeanProfile$ = combineLatest([
    this.authFacade.oktaToken,
    this.authFacade.isProfileLoaded,
  ]).pipe(
    map(([oktaToken, isProfileLoaded]) => {
      if (oktaToken) {
        return timer(0, 1000).pipe(
          takeWhile(() => !isProfileLoaded, true),
          timeout({ each: 5000, with: () => of(false) }),
          map(() => isProfileLoaded),
        );
      } else {
        return of(false);
      }
    }),
    switchAll(),
    distinctUntilChanged(),
  );

  distinctNavigation$ = this.routerService.isNavigatingToTemplate
    .asObservable()
    .pipe(
      filter(nav => !nav.navigating),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
    );

  // request cms template everytime we navigate to new page
  setPageTitleAndRequestTemplate$ = createEffect(() =>
    combineLatest([
      // this debounce is to prevent safari from calling a requestTemplate on
      // cases where the observable flips from true to false or vice versa,
      // and we are on a page that is not a CMS route/internal route
      // this causes a weird error where we make a new API call RIGHT before
      // the page reloads (because it's not an internal route), and the browser
      // cancels the request - in cancelling the request, it also triggers
      // the error handler, which is being logged on FullStory as a spike in errors
      this.waitForLeanProfile$.pipe(debounceTime(150)),
      this.setPageTitle$, // returns routeData <from sitemap> & queryParams
      this.distinctNavigation$, // true if we navigate to new page, false if we navigate within same page
      this.globalFacade.lastRequestedEndpoint, // it will fire the last magnolia request or APPLICATION_BASE_PATH if it is a static page.
    ]).pipe(
      filter(
        ([
          isProfileLoaded,
          { routeData, queryParams },
          navigate,
          lastRequestedEndpoint,
        ]) =>
          this.isTemplateRequestNeeded({
            isProfileLoaded,
            routeData,
            queryParams,
            navigate,
            lastRequestedEndpoint,
          }),
      ),
      switchMap(([waitForLeanProfile, { routeData, queryParams }]) => {
        this.analyticsFacade.logProfileInfoEventCall(waitForLeanProfile);
        return this.personalizationService
          .getPersonalizationData(routeData, waitForLeanProfile)
          .pipe(
            map(personalizationData => ({
              ...routeData,
              ...personalizationData,
              queryParams,
              loggedIn: waitForLeanProfile,
            })),
          );
      }),
      mergeMap(requestData =>
        observableFrom([
          this.globalFacade.setLastRequestedEndpoint(requestData.endpoint),
          // this will eventually call getTemplateData to request dynamic component metadata from cms
          this.globalFacade.requestTemplate(requestData),
        ]),
      ),
    ),
  );

  // When a non-dynamic-template page is loaded, we want to clear the lastRenderedPath value
  clearLastRenderedPath$ = createEffect(() =>
    this.routeDataAfterNavigation$.pipe(
      distinctUntilKeyChanged('path'),
      filter(routeData => Boolean(routeData) && !Boolean(routeData.endpoint)),
      tap(() => {
        this.zone.runOutsideAngular(() =>
          // Need request animation frame to reset focus on h1
          requestAnimationFrame(() => this.routerService.resetFocus()),
        );
        this.abTestService.getOmnitureABTestExperienceValue(() =>
          this.analyticsService.trackEvent('AB Test Experience'),
        );
      }),
      map(() =>
        this.globalFacade.setLastRequestedEndpoint('APPLICATION_BASE_PATH'),
      ),
    ),
  );
}
