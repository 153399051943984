<div
  class="booker-container bg-core-blue relative negative-page-margin"
  [style.background-image]="bgUrl ? 'url(' + bgUrl + ')' : ''"
  [style.background-size]="'100%'"
>
  <div class="w-100 h-100 z-0 overflow-hidden absolute">
    <div class="bg-gradient absolute">
      <div class="left-gradient relative"></div>
    </div>
  </div>
  <div
    [ngClass]="
      !!addPadding
        ? isContentSection
          ? 'negative-section-margin center pv6 pv8-ns ph3 ph5-m ph7-ns relative ph0-l ph0-m ph2-ns page-margin-m page-margin-l'
          : 'center pv6 pv8-ns ph3 ph5-m ph7-ns relative ph0-l ph0-m ph2-ns page-margin-m page-margin-l'
        : ''
    "
  >
    <div class="flex flex-column jb-max-width center">
      <ng-content></ng-content>
    </div>
  </div>
</div>
