import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StringToIdPipeModule } from '@shared/ui/pipes/string-to-id/string-to-id.module';
import { JbBreadcrumbsModule, JbHeroModule } from 'jb-component-library';

import { JbChapterSelectorModule } from '../jb-chapter-selector';
import { UIModule } from '../ui';
import { JBPageHeaderComponent } from './temp-jb-page-header.component';

const MODULES = [
  CommonModule,
  UIModule,
  JbChapterSelectorModule,
  JbHeroModule,
  JbBreadcrumbsModule,
  StringToIdPipeModule,
];

const DECLARATIONS = [JBPageHeaderComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class JBPageHeaderModule {}
