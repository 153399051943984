import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config/app-config.service';

import { LINK_ICON_MAPPINGS } from './link-icon.const';
import { LinkIconData, LinkIconDataMap } from './link-icon.types';

@Injectable({
  providedIn: 'root',
})
export class LinkIconService {
  public readonly iconDataMap: LinkIconDataMap;

  constructor(private appConfig: AppConfigService) {
    // set map with updated linkDefaults values.
    // caching it here in service so we don't have to do this in every component
    this.iconDataMap = Object.keys(LINK_ICON_MAPPINGS).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          ...LINK_ICON_MAPPINGS[key],
          label: this.appConfig.linkDefaults[LINK_ICON_MAPPINGS[key].label],
        },
      }),
      {} as LinkIconDataMap,
    );
  }

  public getIconData(target: string): LinkIconData {
    return this.iconDataMap[target] || null;
  }
}
