import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { WINDOW } from '@core/injection-tokens';
import {
  sendDeletePaymentsFailureFSEvent,
  sendUpdatePaymentsFailureFSEvent,
} from '@store/analytics/utils';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthFacade } from '../../auth/auth.facade';
import { createErrorAction, networkError } from '../../shared/action.utils';
import { StoreService } from '../../shared/store.service';
import { PreferenceCenterActions } from '../preference-center.actions';
import {
  CreditCardRetrievalRequestState,
  PersonalInfoState,
  TravelPreferencesState,
} from '../types';

@Injectable({ providedIn: 'root' })
export class PreferenceCenterApi {
  preferenceCenterUrl: string;
  profileServiceCreditCardUrl: string;
  profilePicture: Observable<Blob>;
  headers: HttpHeaders;
  creditCardHeaders: HttpHeaders;
  token: string;
  private subscriptions: Subscription = new Subscription();
  sendUpdatePaymentsFailureFSEvent: (window: any, message: string) => void;
  sendDeletePaymentsFailureFSEvent: (window: any, message: string) => void;

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService,
    private storeService: StoreService,
    private authFacade: AuthFacade,
    @Inject(WINDOW) private window,
  ) {
    this.preferenceCenterUrl = this.appConfig.preferenceCenterServiceUrl;
    this.profileServiceCreditCardUrl =
      this.appConfig.profileServiceCreditCardUrl;
    this.sendUpdatePaymentsFailureFSEvent = sendUpdatePaymentsFailureFSEvent;
    this.sendDeletePaymentsFailureFSEvent = sendDeletePaymentsFailureFSEvent;

    this.subscriptions.add(
      this.authFacade.oktaToken.subscribe(token => {
        if (Boolean(token)) {
          this.token = token;
          this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            apiKey: `${this.appConfig.preferenceCenter.membersApiKey}`,
            Authorization: `Bearer ${this.token}`,
          });
        }
      }),
    );

    this.creditCardHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      apiKey: `${this.appConfig.preferenceCenter.creditCardApiKey}`,
    });
  }

  getMemberInfo(): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/profile`;

    return this.httpClient
      .get(url, {
        headers: this.headers,
      })
      .pipe(
        map(this.extractData),
        catchError(res => {
          this.logoutUser(res);
          this.storeService.dispatchAction(
            createErrorAction(PreferenceCenterActions.STORE_MEMBER_DATA, res),
          );
          return observableOf(networkError());
        }),
      );
  }

  getProfilePicture(): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/avatar`;

    return this.httpClient
      .get(url, {
        headers: this.headers,
        responseType: 'blob',
      })
      .pipe(
        map(profilePicture => {
          return profilePicture;
        }),
        catchError(res => {
          const reader = new FileReader();
          reader.readAsText(res.error);
          reader.addEventListener('loadend', (e: any) => {
            if (e?.srcElement?.result) {
              const errData = JSON.parse(e.srcElement.result);
              this.storeService.dispatchAction(
                createErrorAction(
                  PreferenceCenterActions.STORE_PROFILE_PICTURE,
                  errData,
                ),
              );
            }
          });
          return observableOf(networkError());
        }),
      );
  }

  saveMemberInfo(data: PersonalInfoState): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/profile`;
    return this.httpClient
      .patch(url, data, {
        headers: this.headers,
        responseType: 'text',
      })
      .pipe(
        map(x => x),
        catchError(res => {
          this.logoutUser(res);
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.UPDATE_STORE_MEMBER_DATA,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  getMemberPreferences(): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/preferences/v1`;

    return this.httpClient
      .get(url, {
        headers: this.headers,
      })
      .pipe(
        map(this.extractData),
        catchError(res => {
          this.logoutUser(res);
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.STORE_MEMBER_PREFERENCES,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  saveMemberPreferences(data: TravelPreferencesState): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/preferences`;

    return this.httpClient
      .patch(url, data, {
        headers: this.headers,
        responseType: 'text',
      })
      .pipe(
        map(x => x),
        catchError(res => {
          this.logoutUser(res);
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.UPDATE_STORE_MEMBER_PREFERENCES,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  getFutureReservationStatus(): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/futureReservations`;

    return this.httpClient
      .get(url, {
        headers: this.headers,
      })
      .pipe(
        map(futureReservationStatus => {
          return Boolean(futureReservationStatus);
        }),
        catchError(res => {
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.UPDATE_STORE_MEMBER_PREFERENCES,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  getSavedCreditCards(data: CreditCardRetrievalRequestState): Observable<any> {
    const url = `${this.profileServiceCreditCardUrl}/profile/v1/retrieveTBProfile/token`;

    return this.httpClient
      .post(url, data, {
        headers: this.creditCardHeaders,
        responseType: 'text',
      })
      .pipe(
        catchError(res => {
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.STORE_MEMBER_PAYMENT,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  savePaymentsInfo(data): Observable<any> {
    const url = `${this.profileServiceCreditCardUrl}/profile/v1/storeCreditCard`;

    return this.httpClient
      .post(url, data, {
        headers: this.creditCardHeaders,
        responseType: 'text',
      })
      .pipe(
        map(responseText => {
          const response = JSON.parse(responseText);
          const errorEntry =
            response?.Response?.ResponseMessage?.Errors?.ErrorMessage?.[0];

          if (errorEntry) {
            const errorMessage = errorEntry.value || errorEntry.ErrorCode;

            switch (
              data?.StoreCreditCardRequest?.CreditCardInfo?.CreditCardAction
            ) {
              case 'UPDATE':
                this.sendUpdatePaymentsFailureFSEvent(
                  this.window,
                  errorMessage,
                );
                break;
              case 'DELETE':
                this.sendDeletePaymentsFailureFSEvent(
                  this.window,
                  errorMessage,
                );
                break;
              default:
                console.warn('Unknown CreditCardAction');
                break;
            }
          }

          return response;
        }),

        catchError(res => {
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.UPDATE_STORE_PAYMENTS_INFO,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  saveProfilePicture(data): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/avatar`;
    const profilePicheaders = new HttpHeaders({
      apiKey: `${this.appConfig.preferenceCenter.membersApiKey}`,
      Authorization: `Bearer ${this.token}`,
    });
    return this.httpClient
      .put(url, data, {
        headers: profilePicheaders,
        responseType: 'text',
      })
      .pipe(
        map(x => x),
        catchError(res => {
          this.logoutUser(res);
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.UPDATE_STORE_PROFILE_PICTURE,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  deleteProfilePicture(): Observable<any> {
    const url = `${this.preferenceCenterUrl}/preferences/members/avatar`;

    const profilePicheaders = new HttpHeaders({
      apiKey: `${this.appConfig.preferenceCenter.membersApiKey}`,
      Authorization: `Bearer ${this.token}`,
    });

    return this.httpClient
      .delete(url, {
        headers: profilePicheaders,
        responseType: 'text',
      })
      .pipe(
        map(x => x),
        catchError(res => {
          this.storeService.dispatchAction(
            createErrorAction(
              PreferenceCenterActions.UPDATE_DELETE_STORE_PROFILE_PICTURE,
              res,
            ),
          );
          return observableOf(networkError());
        }),
      );
  }

  logoutUser(res) {
    if (
      (res.status === 401 && res.statusText === 'Unauthorized') ||
      (res.status === 401 &&
        res.error &&
        res.error.details[0].match('400') != null)
    ) {
      this.authFacade.logoutUserOktaOrLegacyGuard();
    }
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
}
