/**
 * Replace all [{}] with {}. Arrays with length > 1 not effected.
 * @param obj Object to traverse
 * @returns Updated object
 */
export function traverse(obj) {
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key]) && obj[key].length === 1) {
          obj[key] = obj[key][0];
          traverse(obj);
        } else {
          traverse(obj[key]);
        }
      }
    }
  }
  return obj;
}
