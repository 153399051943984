import { Component, Input } from '@angular/core';

/**
 * @description Formatted list of text and icons to be used as a legend
 * @WhereIsItUsed region-selector-v2
 */
@Component({
  selector: 'jb-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent {
  @Input() legends; // Legend | Legend[]
  @Input() column = false;
  @Input() filterOutPartner = false;
  @Input() hideLegend = false;
  @Input() customLegendMessage: string;
}
