<jb-dialog *ngIf="promoLegalData">
  <jb-dialog-header>{{ promoLegalData[0].title }}</jb-dialog-header>
  <jb-dialog-content>
    <span *ngIf="promoLegalData[0].specificRestrictions"
      >{{ promoLegalData[0].specificRestrictions }}.
    </span>
    <span>
      <jb-inner-html
        jbSmartLink
        [innerHTML]="promoLegalData[0].content"
      ></jb-inner-html>
    </span>
  </jb-dialog-content>
</jb-dialog>
