import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@shared/ui/pipes/pipes.module';
import {
  JbAutocompleteModule,
  JbButtonModule,
  JbFormFieldContainerModule,
  JbIconModule,
  JbSrOnlyModule,
} from 'jb-component-library';

import {
  SearchBoxContainerComponent,
  searchBoxContainerResolver,
} from './search-box-container.component';

const MODULES = [
  CommonModule,
  PipesModule,
  JbFormFieldContainerModule,
  JbSrOnlyModule,
  JbAutocompleteModule,
  JbButtonModule,
  JbIconModule,
  ReactiveFormsModule,
];

const DECLARATIONS = [SearchBoxContainerComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class SearchBoxContainerModule {
  static entry = SearchBoxContainerComponent;
  static componentDataResolver = searchBoxContainerResolver;
}
