<div
  *ngIf="vm$ | async as vm"
  class="pv3 pl2 bw2"
  [ngClass]="{
    'bb b--orange charcoal': vm.isActive,
    'slate-gray': vm.disable
  }"
>
  <ng-template [ngIf]="!vm.bff" [ngIfElse]="dateSelected">
    <div class="mb1 legal">
      {{ vm.blockLabelCopy.title.dateNotSelectedLabel }}
    </div>
    <div class="b s-body">
      {{ vm.blockLabelCopy.placeholder | jbDynamicLabel: vm.windowSize }}
    </div>
  </ng-template>
  <ng-template #dateSelected>
    <div class="mb1 legal">
      {{ vm.blockLabelCopy.title.dateSelectedLabel }}
    </div>
    <div class="flex flex-row">
      <div class="flex flex-row-l flex-column items-baseline">
        <div class="b s-body mr1">{{ vm.bff.date | jbDate: 'EEE MMM d' }}</div>
        <div class="legal mr2" *ngIf="!!vm.bff.amount || vm.bff.amount === 0">
          {{
            [vm.bff]
              | jbPointsCashPipe
                : vm.isPoints
                : { large: true }
                : vm.content.copy.ptsLabel
                : vm.currencyCode
          }}
          {{ vm.content.copy.personCopy | jbDynamicLabel: vm.windowSize }}
          <span class="dn di-ns" *ngIf="vm.bff.seats <= vm.maxSeats">
            ({{ vm.bff.seats }} {{ vm.seatCopy }})
          </span>
        </div>
      </div>
      <div
        class="flex flex-row self-stretch-m items-end-m fill-royal-blue pointer"
        *ngIf="vm.isEditable"
      >
        <!-- replace with Arrow jb-icon once jb-component library issue is fix -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g transform="translate(0 1)">
            <path
              d="M5.14994883,12.1558153 L12.8869376,4.46446371 C13.0540283,4.2983586 13.0083798,3.95244899 12.9256958,3.65534346 C12.8499021,3.35995035 12.5768724,2.95410591 12.2883395,2.66727492 C11.7078285,2.09104431 10.884433,1.8102068 10.5511129,2.14156081 L2.81412409,9.83376857 L2.00537061,12.627588 C1.95972212,12.7894121 2.21552593,13.0419946 2.3765876,12.9940467 L5.14994883,12.1558153 Z M13.9444557,5.52825667 L5.94210143,13.4834084 L5.58392559,13.5916649 L2.80457334,14.4316935 C1.5100223,14.8170798 0.19534064,13.519129 0.564526456,12.2104932 L1.48123206,9.04375569 L1.75654679,8.77003446 L9.49359479,1.07776784 C10.5913785,-0.0135404648 12.2399541,0.505732339 13.3458576,1.60348195 C13.8216339,2.0764519 14.2293366,2.70069494 14.3707784,3.25317895 C14.6148254,4.13010403 14.6191649,4.85752735 13.9444557,5.52825667 Z"
            />
            <path
              d="M1.96966991,10.0303301 C1.6767767,9.73743687 1.6767767,9.26256313 1.96966991,8.96966991 C2.26256313,8.6767767 2.73743687,8.6767767 3.03033009,8.96966991 L6.03033009,11.9696699 C6.3232233,12.2625631 6.3232233,12.7374369 6.03033009,13.0303301 C5.73743687,13.3232233 5.26256313,13.3232233 4.96966991,13.0303301 L1.96966991,10.0303301 Z"
            />
          </g>
        </svg>
      </div>
    </div>
  </ng-template>
</div>
