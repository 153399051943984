import { Injectable } from '@angular/core';

import { StoreService } from '../shared/store.service';
import {
  LoadExtensionDate,
  RequestExtensionDate,
} from './schedule-extension.actions';
import * as ScheduleExtensionSelectors from './schedule-extension.selectors';
import { ScheduleExtension } from './types';

@Injectable({ providedIn: 'root' })
export class ScheduleExtensionFacade {
  scheduleExtension = this.storeService.getState(
    ScheduleExtensionSelectors.scheduleExtension,
  );
  extensionDate = this.storeService.getState(
    ScheduleExtensionSelectors.extensionDate,
  );

  constructor(private storeService: StoreService) {}

  requestExtensionDate(): RequestExtensionDate {
    return new RequestExtensionDate();
  }

  loadExtensionDate(scheduleExtension: ScheduleExtension): LoadExtensionDate {
    return new LoadExtensionDate(scheduleExtension);
  }
}
