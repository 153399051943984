import { Injectable } from '@angular/core';
import { AppConfigService, CookieStorageService } from '@core/index';
import { getAirportByCode } from '@dynamic-components/route-map/store/route-map-store.logic';
import { AirportWithCoordinates } from '@dynamic-components/route-map/types/airport.type';
import { BOOKER_TRIP_TYPES } from '@shared/booker/types/booker-trip-type.enum';
import { extractAirports } from '@store/origins/api';

import {
  convertBookerFormDataToQueryString,
  createMobileBookingUrlMainBooker,
  createVanillaFormData,
} from './booker-air.utils';

@Injectable({ providedIn: 'root' })
export class BookerAirService {
  constructor(
    private appConfigService: AppConfigService,
    private cookieStorageService: CookieStorageService,
  ) {}

  getFlightResultsUrl(
    queryParamString: string,
    isPoints: boolean,
    isMobile: boolean,
    destinationAirports,
    countriesWithAirports,
  ) {
    const params = new URLSearchParams(queryParamString);
    const upperParams = new URLSearchParams();
    for (const [name, value] of params) {
      upperParams.append(name.toUpperCase(), value);
    }
    const defaultTravelers = {
      adults: 1,
      children: 0,
      infants: 0,
      childAges: [],
    };
    const destination = getAirportByCode(
      upperParams.get('DESTINATION'),
      destinationAirports,
    );
    const originAirports = extractAirports(countriesWithAirports);
    const origin = getAirportByCode(
      upperParams.get('ORIGIN'),
      originAirports as AirportWithCoordinates[],
    );
    const formData = {
      bookWithPoints: isPoints,
      // to avoid offset through specifying local timezone
      departureDate: new Date(`${upperParams.get('DEPARTURE')}T00:00:00`),
      returnDate: null,
      flights: [
        {
          destination,
          origin,
        },
      ],
      origin,
      destination,
      promoCode: null,
      recaptchaReactive: null,
      travelers: {
        allTravelers: defaultTravelers,
        groups: [defaultTravelers],
      },
      tripType:
        upperParams.get('ROUNDTRIP') === 'true'
          ? BOOKER_TRIP_TYPES.ROUNDTRIP
          : BOOKER_TRIP_TYPES.ONEWAY,
    };
    if (isMobile) {
      return createMobileBookingUrlMainBooker(
        this.appConfigService.baseBook,
        this.appConfigService.mobileBaseUrl,
        this.appConfigService.isProdEnv,
        formData,
      );
    } else {
      const querystring = convertBookerFormDataToQueryString(
        createVanillaFormData(
          formData,
          this.cookieStorageService.getCountryCodeCookie(),
          [],
        ),
        [],
      );
      return `${this.appConfigService.rwbBookerUrl}?${querystring}`;
    }
  }
}
