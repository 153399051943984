// Hard coded to Dominican Repulic since it is the country we are comparing against for tax
import { HttpParams } from '@angular/common/http';
import { compose, isEmpty, isNil, path } from 'ramda';

const dominicanRepublicCountryCode = 'DO';

const getPOSValueForDO = bookWithPoints =>
  bookWithPoints ? 'JETBLUEREDEMPTION_DO' : 'JETBLUEWEB_DO';

const getPOSValueForNonDO = bookWithPoints =>
  bookWithPoints ? 'JETBLUEREDEMPTION_US' : 'JETBLUEWEB_US';

// If the akamai country is Dominican Republic, tax will be exempted from all purchases
export const buildBookerPOSValue = (
  akamaiCountry: string,
  bookWithPoints: boolean,
): string =>
  akamaiCountry === dominicanRepublicCountryCode
    ? getPOSValueForDO(bookWithPoints)
    : getPOSValueForNonDO(bookWithPoints);

export const convertBookerFormValuesToQuerystring = (
  formReference,
  isOneWay,
) => {
  const formValue = formProp =>
    compose(
      innerValue => (isEmpty(innerValue) ? undefined : innerValue),
      path<any>([formProp, 'value']),
    )(formReference);

  const base = {
    from: formValue('origin'),
    to: formValue('destination'),
    depart: formValue('departureDate'),
    return: (!isOneWay && formValue('returnDate')) || null,
    adults: formValue('numAdults'),
    children: formValue('numChildren'),
    infants: formValue('numInfants'),
    sharedMarket: formValue('sharedMarket'),
    roundTripFaresFlag: formValue('roundTripFaresFlag'),
    usePoints: formValue('fareFamily') === 'TRUEBLUE',
    airPromo: formValue('promoCode') || null,
  };

  const queryparams = new HttpParams();
  return Object.keys(base)
    .reduce(
      (acc, paramKey) =>
        !isNil(base[paramKey]) ? acc.append(paramKey, base[paramKey]) : acc,
      queryparams,
    )
    .toString();
};
