import { defaultTravelers } from '@dynamic-components/booker-v2/bookers/hotel/booker-hotel-points/booker-hotel-points.types';
import { BookerFormState, correctlyFormatTravellers } from '@shared/booker';
import { BOOKER_CONFIG } from '@shared/booker/booker.config';
import {
  RecentAirSearch,
  RecentSearch,
  RecentVacationSearch,
} from '@shared/types';
import isSameDay from 'date-fns/isSameDay';

import { BookerActions, BookerActionsUnion } from './booker.actions';
import { BookerState } from './types';

export const initialBookerState: BookerState = {
  recentAirSearches: [],
  submittedAirSearches: [],
  recentVacationSearches: [],
  recentHotelPtsSearch: {
    travelDates: { startDate: null, endDate: null },
    location: undefined,
    travelers: {
      allTravelers: defaultTravelers,
      groups: [defaultTravelers],
    },
  },
  recentLocations: {
    origins: [[], [], [], []],
    destinations: [[], [], [], []],
  },
  bookerGlobalSettings: {
    showPromoCode: 'false',
    displayMessage: 'false',
    messageContent: [
      {
        bodyText: [
          {
            content: '',
            title: '',
          },
        ],
      },
    ],
  },
};

export function bookerReducer(
  state = initialBookerState,
  action: BookerActionsUnion,
): BookerState {
  switch (action.type) {
    case BookerActions.SET_RECENT_AIR_SEARCH:
      // aggregates upto three user searches in localstorage
      return {
        ...state,
        recentAirSearches: [
          ...(action['error'] || !action.payload
            ? state.recentAirSearches
            : (reduceSearches(state.recentAirSearches, {
                ...action.payload,
                ...correctlyFormatTravellers(action.payload),
              }) as RecentAirSearch[])),
        ],
      };
    case BookerActions.MODIFY_LAST_RECENT_AIR_SEARCH:
      const lastSearch: RecentAirSearch = state.recentAirSearches.slice(
        0,
        1,
      )[0]; // get copy of most recent search
      const modifiedSearch = { ...lastSearch, ...action.payload };
      return {
        ...state,
        recentAirSearches: [
          ...(action['error'] || !action.payload
            ? state.recentAirSearches
            : (reduceSearches(state.recentAirSearches, {
                ...modifiedSearch,
                ...correctlyFormatTravellers(modifiedSearch),
              }) as RecentAirSearch[])),
        ],
      };
    case BookerActions.SET_SUBMITTED_AIR_SEARCH:
      return {
        ...state,
        submittedAirSearches: [
          ...(action['error'] || !action.payload
            ? state.submittedAirSearches
            : reduceSubmittedSearches(state.submittedAirSearches, {
                ...action.payload,
              })),
        ],
      };
    case BookerActions.UPDATE_CURRENT_AIR_SEARCH:
      return {
        ...state,
        recentAirSearches: [
          action.payload,
          ...(state.recentAirSearches || []).slice(1),
        ],
      };
    case BookerActions.SET_RECENT_LOCATIONS:
      return {
        ...state,
        recentLocations: action.payload,
      };
    case BookerActions.SET_RECENT_VACATION_SEARCH:
      return {
        ...state,
        recentVacationSearches: [
          ...(action['error'] || !action.payload
            ? state.recentVacationSearches
            : (reduceSearches(
                state.recentVacationSearches,
                action.payload,
              ) as RecentVacationSearch[])),
        ],
      };

    case BookerActions.SET_RECENT_HOTEL_PTS_SEARCH:
      return {
        ...state,
        recentHotelPtsSearch: {
          ...(action['error'] || !action.payload
            ? state.recentHotelPtsSearch
            : action.payload),
        },
      };
    case BookerActions.SET_BOOKER_GLOBAL_SETTINGS:
      return {
        ...state,
        bookerGlobalSettings: {
          ...state.bookerGlobalSettings,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

function reduceSearches(
  searches: RecentSearch[],
  currentSearch: RecentSearch,
): RecentSearch[] {
  if (searches.length >= BOOKER_CONFIG.MAX_RECENT_AIR_SEARCHES) {
    searches.pop();
  }
  searches.unshift(currentSearch);
  return searches;
}

function reduceSubmittedSearches(
  previousSearches: BookerFormState[],
  newSearch: BookerFormState,
): BookerFormState[] {
  // check if the new search is a duplicate of on that exists
  const foundIndex = previousSearches.findIndex(
    value =>
      isSameDay(value.departureDate, newSearch.departureDate) &&
      isSameDay(value.returnDate, newSearch.returnDate) &&
      value.tripType === newSearch.tripType &&
      value.bookWithPoints === newSearch.bookWithPoints &&
      value.flights[0].origin.code === newSearch.flights[0].origin.code &&
      value.flights[0].destination?.code ===
        newSearch.flights[0].destination?.code,
  );

  if (foundIndex !== -1) {
    previousSearches.splice(foundIndex); // remove the duplicate
  }
  // remove oldest search if length largest than config
  if (previousSearches.length >= BOOKER_CONFIG.MAX_SUBMITTED_AIR_SEARCHES) {
    previousSearches.pop();
  }
  // add new search to front of array
  previousSearches.unshift(newSearch);
  return previousSearches;
}
