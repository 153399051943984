import {
  BffForm,
  CalendarMonth,
} from '../../../feature/bff/bff.component-store.types';
import { ScheduleExtension } from '../../../store/schedule-extension/types';
import { CalendarAggregator } from './types';

export interface CalendarAggregatorComponentState {
  form: BffForm;
  calendars: CalendarMonth[];
  content: CalendarAggregator;
  dealStartDate: Date;
  isMobile: boolean;
  dateOfBirth: string;
  paramDate: Date;
  today: Date;
  scheduleExtension: ScheduleExtension;
  maxDate: Date;
}
export const calendarAggregatorComponentInitialState: CalendarAggregatorComponentState =
  {
    scheduleExtension: undefined,
    paramDate: undefined,
    calendars: [],
    content: undefined,
    dealStartDate: undefined,
    form: undefined,
    isMobile: false,
    dateOfBirth: undefined,
    maxDate: undefined,
    today: undefined,
  };

export interface CalendarAggregatorComponentViewModel
  extends CalendarAggregatorComponentState {
  isSeasonalRoute: boolean;
  maxDate: Date;
}
