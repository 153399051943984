import { Pipe, PipeTransform } from '@angular/core';
import { BestFareDay } from '@core/bff';
import { CalendarResponse } from '@shared/calendar';
import format from 'date-fns/format';

/**
 * Create this custom date pipe since angular date pipe formats date differently
 * on google pixel chrome browser
 * Formatting info can be found at https://date-fns.org/v1.29.0/docs/format
 */
@Pipe({
  name: 'jbCalendarAriaLabel',
  pure: false,
})
export class CalendarCellAriaLabel implements PipeTransform {
  transform(
    { amount = 0, date, seats, tax, disabled = false, holiday }: BestFareDay,
    content: CalendarResponse,
    maxSeats: number,
    isPoints = false,
  ): string {
    const dateText = date ? format(date, 'EEEE, MMMM do, yyyy') : undefined;
    const holidayText =
      !!holiday && !!holiday.content ? `${holiday.content} ` : '';
    const amountText = `${!isPoints ? '$' : ''}${amount.toFixed(
      !isPoints ? 2 : 0,
    )} ${isPoints ? `points ` : ''}`;
    const taxText = isPoints && tax ? `plus $${tax.toFixed(2)} ` : '';
    const showSeats = !disabled && seats && seats <= maxSeats;
    const ending = showSeats
      ? ` ${seats} ${
          seats === 1
            ? content.remainingSeats.seat
            : content.remainingSeats.seats
        }`
      : '';
    return `${dateText} ${holidayText}${amountText}${taxText}per person${ending}`;
  }
}
