// eslint-disable max-classes-per-file
import { FareSaleOrigin } from '@models/fare-sale.models';
import { FareSaleAirportSelection } from '@shared/fare-sale-sort-link/fare-sale-utils/types/fare-sale-list.type';

import { Action } from '../shared/types/action.interface';
import { SortParameters } from './types';

export enum FareSaleActions {
  UPDATE_ORIGIN_SORT_FLAG = '[National Fare Sale Page] Update originSortEnabled flag',
  INITIALIZE_FARE_SALE_DATA = '[National Fare Sale Page] Initialize NFS data',
  SELECTED_ORIGIN_AIRPORT = '[National Fare Sale Page] Origin airport has been selected',
  CHANGED_LIST_SORT_PARAMETERS = '[National Fare Sale Page] List sort parameters have been changed',
}

export class UpdateOriginSortFlag implements Action {
  readonly type = FareSaleActions.UPDATE_ORIGIN_SORT_FLAG;
  constructor(public payload: boolean) {}
}

export class InitializeFareSaleData implements Action {
  readonly type = FareSaleActions.INITIALIZE_FARE_SALE_DATA;
  constructor(
    public fareSaleDataPayload: FareSaleOrigin[],
    public fareSaleDataGroupedByMACsPayload: FareSaleOrigin[],
  ) {}
}

export class SelectedOriginAirport implements Action {
  readonly type = FareSaleActions.SELECTED_ORIGIN_AIRPORT;
  constructor(public payload: FareSaleAirportSelection) {}
}

export class ChangedListSortParameters implements Action {
  readonly type = FareSaleActions.CHANGED_LIST_SORT_PARAMETERS;
  constructor(public payload: Partial<SortParameters>) {}
}

export type FareSaleActionsUnion =
  | UpdateOriginSortFlag
  | InitializeFareSaleData
  | SelectedOriginAirport
  | ChangedListSortParameters;
