import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchSection } from '@core/cms/types/cms-footer.response.type';
import { EventsService } from '@core/events';
import {
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  WINDOW,
} from '@core/injection-tokens';
import { ComponentStore } from '@ngrx/component-store';
import { JbButtonTypeEnum } from 'jb-component-library';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import {
  SearchBoxComponentInitialState,
  SearchBoxComponentState,
  SearchBoxContainerViewModel,
} from './search-box.component.state';
import { SearchOption } from './types/search-options.type';
@Component({
  selector: 'jb-search-box-container',
  templateUrl: './search-box-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore],
  styleUrls: ['./search-box-container.component.scss'],
})
export class SearchBoxContainerComponent implements OnDestroy {
  formControl: AbstractControl = new UntypedFormControl();
  ctaTheme: JbButtonTypeEnum;
  fillIcon: boolean;
  shouldClear: boolean;
  @ViewChild('autocomplete') autocomplete: any; // can not import JbAutocompleteComponent;

  @Input() set searchSection(content: SearchSection) {
    this.initializeTheme(content);
    this.store.patchState({
      content,
      groups: this.transformGroups(content.groups),
    });
  }

  @Input() set isInFooter(value: boolean) {
    this.store.patchState({ isInFooter: value });
  }

  @Input() set isInHero(value: boolean) {
    this.store.patchState({ isInHero: value });
  }

  @Input() set heroExists(value: boolean) {
    this.store.patchState({ heroExists: value });
  }

  @Input() set isHelpPage(value: boolean) {
    this.store.patchState({ isHelpPage: value });
  }

  readonly vm$: Observable<SearchBoxContainerViewModel> = combineLatest([
    this.store.state$,
  ]).pipe(
    map(([state]) => ({
      ...state,
    })),
  );

  private subscriptions: Subscription = new Subscription();
  constructor(
    @Inject(WINDOW) private window,
    private router: Router,
    private route: ActivatedRoute,
    private readonly store: ComponentStore<SearchBoxComponentState>,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
    private eventsService: EventsService,
  ) {
    this.store.setState(SearchBoxComponentInitialState);
    this.subscriptions.add(
      this.route.queryParams
        .pipe(tap(() => this.formControl.setValue('')))
        .subscribe(params => {
          this.formControl.setValue(
            params.search ? { name: params.search, value: '' } : null,
          );
          this.store.patchState({
            userSearch: params.search ? params.search : '',
          });
        }),
    );
  }

  initializeTheme(content: SearchSection) {
    const { button, theme } = content;
    const { ctaColor } = button[0];

    switch (theme) {
      case 'light':
        this.ctaTheme =
          ctaColor === 'theme'
            ? JbButtonTypeEnum.ctaPrimary
            : JbButtonTypeEnum.standard;
        break;
      case 'dark':
        this.ctaTheme =
          ctaColor === 'theme'
            ? JbButtonTypeEnum.ctaSecondary
            : JbButtonTypeEnum.standard;
        break;
      default:
        // default to primary
        this.ctaTheme = JbButtonTypeEnum.ctaPrimary;
    }

    this.fillIcon = this.ctaTheme === JbButtonTypeEnum.standard;
  }

  onClick(): void {
    this.toggleDropdown(null);
  }

  onOptionSelected(urlString: string): void {
    this.shouldClear = true;
    this.redirectToUrl(urlString);
  }

  onKeydown(event): void {
    if (event.keyCode === 13) {
      this.store
        .select(state => state?.content)
        .subscribe(content =>
          this.redirectBackToSearch(content.button[0]?.href),
        );
    }
    this.store.patchState({ userSearch: event.target.value });
    this.toggleDropdown(event.target.value);
  }

  toggleDropdown(value: string | SearchOption): void {
    this.store.patchState(state => {
      return {
        insertedOptions: value ? [] : state.groups,
      };
    });
  }

  redirectToUrl(url) {
    if (url && url.length) {
      if (this.isWebComponent === 'footer') {
        const payload = url.startsWith('/')
          ? { routerLink: url }
          : { href: url };
        this.eventsService.dispatchCustomEvent(
          EventsService.CUSTOM_EVENTS.JB_FOOTER_LINK_CLICKED_OUTPUT_EVENT,
          payload,
        );
      } else {
        if (url.startsWith('/')) {
          this.router.navigate([url], {});
        } else {
          this.window.location.href = decodeURIComponent(url);
        }
      }
    }
  }

  redirectBackToSearch(url: string): void {
    this.store
      .select(state => state?.userSearch)
      .pipe(
        take(1),
        filter(userSearch => !!userSearch),
        tap(() => (this.shouldClear = true)),
      )
      .subscribe(userSearch => {
        if (this.isWebComponent === 'footer') {
          this.eventsService.dispatchCustomEvent(
            EventsService.CUSTOM_EVENTS.JB_FOOTER_LINK_CLICKED_OUTPUT_EVENT,
            { routeLink: '/search', queryParams: { search: userSearch } },
          );
        } else {
          this.router.navigate([url], { queryParams: { search: userSearch } });
        }
      });
  }

  transformGroups(groups) {
    if (!!groups && groups.length) {
      const transformed = groups[0]?.options?.map(option => {
        return { name: option.title, value: option.href };
      });
      const newObj = [{ title: groups[0].title, options: transformed }];
      return newObj;
    } else {
      return [];
    }
  }

  onFocus() {
    // reset form field on focus after navigation
    if (this.shouldClear) {
      this.formControl.setValue(null);
      this.store.patchState({
        userSearch: '',
      });
      this.shouldClear = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export const searchBoxContainerResolver = (registry, data) => {
  const heroExists = Boolean(registry['hero']);

  return {
    heroExists,
    searchSection: data?.search ? data.search[0] : data,
    isHelpPage: !!registry.searchResults,
  };
};
