import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  LoggedOutBottomSectionChild,
  TBImage,
  TBLink,
} from '@shared/header/navigation';

@Component({
  selector: 'dot-tb-bottom-section',
  templateUrl: './tb-bottom-section.component.html',
  styleUrls: ['./tb-bottom-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TbBottomSectionComponent {
  @Input() set content(content: LoggedOutBottomSectionChild) {
    this.bottomSection = content;
    this.cardDesc = content?.bodyTextWithImage?.[0]?.content;
    this.link = content?.bodyTextWithImage?.[0]?.link?.[0];
    this.image = content?.bodyTextWithImage?.[0]?.image?.[0];
  }
  bottomSection: LoggedOutBottomSectionChild;
  cardDesc: string;
  link: TBLink;
  image: TBImage;
}
