import { LinkIconData } from './link-icon.types';

export const LINK_ICON_TARGETS = ['_blank', '_document', '_self'] as const;

export const LINK_ICON_MAPPINGS: {
  [key in (typeof LINK_ICON_TARGETS)[number]]: LinkIconData;
} = {
  _blank: {
    iconName: 'externalLink',
    label: 'external', // this matches property name on defaultLinks
    width: '0.5em',
    height: '0.5em',
  },
  _document: {
    iconName: 'externalDocument',
    label: 'document', // this matches property name on defaultLinks
    width: '0.65em',
    height: '0.65em',
  },
  _self: {
    iconName: 'rightFilled',
    label: 'internal', // this matches property name on defaultLinks
    width: '0.6em',
    height: '0.6em',
  },
};
