<!-- Dialog for all types, except for  RESTRICTED_ORIGINS -->
<jb-dialog jbFocusTrap>
  <jb-dialog-title data-qaid="dialogTitle">
    {{ warningsContent[warningIndex].title }}
  </jb-dialog-title>
  <jb-dialog-content data-qaid="dialogMessageContent">
    {{ warningsContent[warningIndex].content }}
  </jb-dialog-content>
  <jb-dialog-actions>
    <ng-container
      *ngIf="
        warningsContent[warningIndex].warningType !==
          warningTypesEnum.RESTRICTED_ORIGINS;
        else restrictedOriginsActions
      "
    >
      <button
        jbButton
        data-qaid="continueToNextWarningMessage"
        theme="ctaPrimary"
        class="mb3"
        (click)="
          warningsContent.length - 1 === warningIndex
            ? continue(true, $event)
            : nextWarning()
        "
      >
        {{
          warningsContent.length - 1 === warningIndex
            ? content?.airSearch
            : content?.airContinue
        }}
        <dot-link-icon
          *ngIf="showExternalLink"
          newWindow="_blank"
        ></dot-link-icon>
      </button>
      <jb-dialog-cancel-button
        (click)="continue(false, $event)"
        data-qaid="cancelButton"
      >
        {{ content?.airCancel }}
      </jb-dialog-cancel-button>
    </ng-container>
  </jb-dialog-actions>
</jb-dialog>

<!-- Actions for RESTRICTED_ORIGINS -->
<ng-template #restrictedOriginsActions>
  <!-- Redirect Buttons -->
  <a
    *ngFor="let linkButton of warningsContent[warningIndex].linkButtons"
    jbButton
    [jbSitemapLinker]="linkButton.href"
    [target]="linkButton.target"
    class="mb3"
    (click)="dialogService.closeDialog()"
  >
    <span>{{ linkButton.title }}</span>

    <!-- Do not add Icon if target is not _blank - REF-DOT-9909 -->
    <dot-link-icon
      *ngIf="linkButton.target === '_blank'"
      [newWindow]="linkButton.target"
    ></dot-link-icon>
  </a>

  <!-- Add a Continue Button if continueButton is present in the warning data -->
  <a
    *ngIf="warningsContent[warningIndex].continueButton"
    data-qaid="continueToNextWarningMessage"
    jbButton
    theme="ctaPrimary"
    class="mb3"
    (click)="
      warningsContent.length - 1 === warningIndex
        ? continue(true, $event)
        : nextWarning()
    "
  >
    {{
      warningsContent.length - 1 === warningIndex
        ? content?.airSearch
        : content?.airContinue
    }}
  </a>

  <!-- Close Modal -->
  <jb-dialog-cancel-button
    (click)="continue(false, $event)"
    data-qaid="cancelButton"
  >
    {{ content?.airCancel }}
  </jb-dialog-cancel-button>
</ng-template>
