import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TraceIdService {
  generateId(length = 16) {
    let ret = '';
    while (ret.length < length) {
      ret += Math.random().toString(16).substring(2);
    }
    return ret.substring(0, length);
  }
}
