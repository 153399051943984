import { parseHeader } from './api-transform/cms-header.api-transform';

export const CMS_HEADER_FALLBACK = {
  component: [
    {
      name: 'jetblueLogo',
      data: {
        jetblueLogo: [
          {
            newWindow: '_self',
            href: '/',
            type: 'internal',
            image: [
              {
                src: 'https://www.jetblue.com/ui-assets/jetblue-logo.svg',
                height: '0',
                width: '0',
              },
            ],
          },
        ],
      },
    },
    {
      name: 'subNavSections',
      data: {
        section: [
          {
            name: 'manage',
            data: {
              template: 'MANAGE',
              link: [
                {
                  newWindow: '_blank',
                  href: '/manage-trips',
                  title: 'Manage Trips',
                  type: 'internal',
                },
              ],
            },
          },
        ],
      },
    },
    {
      name: 'login',
      data: {
        template: 'TRUEBLUE',
        loggedInSection: [
          {
            name: 'loggedIn',
            data: {
              points: 'pts',
              TrueBlueGreetings: 'Hi, ',
              TrueBlueNrtxt: 'TrueBlue #',
              travelBanktxt: 'Travel Bank credit',
              signoutSection: [
                {
                  name: 'signoutLinkTrueblue',
                  data: {
                    children: [
                      {
                        name: 'children',
                        data: {
                          href: 'https://www-dev2.jetblue.com/signout',
                          newWindow: '_self',
                          linkType: 'SIGNOUT_LINK',
                          title: 'Sign out',
                          type: 'internal',
                          actionType: 'LINK',
                        },
                      },
                    ],
                  },
                },
              ],
              topSection: [
                {
                  name: 'topLinkTrueblue',
                  data: {
                    children: [
                      {
                        name: 'children',
                        data: {
                          href: 'https://www-dev2.jetblue.com/preference-center/profile',
                          newWindow: '_self',
                          type: 'internal',
                          title: 'Profile & Settings',
                        },
                      },
                      {
                        name: 'children',
                        data: {
                          href: 'https://truebluetest.jetblue.com/my-dashboard',
                          newWindow: '_self',
                          type: 'internal',
                          title: 'My Dashboard',
                        },
                      },
                      {
                        name: 'children',
                        data: {
                          href: 'https://travelbank.jetblue.com/tbank/user/login.html?airlineCode=279',
                          newWindow: '_blank',
                          type: 'external',
                          title: 'Travel Bank Credit',
                        },
                      },
                      {
                        name: 'children',
                        data: {
                          newWindow: '_self',
                          href: '/manage-trips',
                          title: 'Manage Trips',
                          type: 'internal',
                        },
                      },
                      {
                        name: 'children',
                        data: {
                          href: 'https://checkin.qa2.cua.ibx.jetblue.com/WCI//',
                          newWindow: '_self',
                          title: 'Check In',
                          type: 'internal',
                        },
                      },
                    ],
                  },
                },
              ],
              bottomSection: [
                {
                  name: 'bottomLinkTrueblue',
                  data: {
                    children: [
                      {
                        name: 'children',
                        data: {
                          newWindow: '_self',
                          href: '/trueblue',
                          title: 'TrueBlue Benefits',
                          type: 'internal',
                        },
                      },
                      {
                        name: 'children',
                        data: {
                          href: 'https://truebluetest.jetblue.com/enroll/child',
                          newWindow: '_self',
                          type: 'internal',
                          title: 'Enroll a Child',
                        },
                      },
                      {
                        name: 'children',
                        data: {
                          newWindow: '_self',
                          href: '/trueblue/faqs',
                          type: 'internal',
                          title: 'TrueBlue FAQs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        loggedOutSection: [
          {
            name: 'loggedOut',
            data: {
              trueBlueText: 'Sign up for TrueBlue',
              trueBlueSubText:
                'Join to manage your bookings, points, get rewarded, and much more.',
              topSection: [
                {
                  name: 'topLinkTrueblue',
                  data: {
                    children: [],
                  },
                },
              ],
              ctaSection: [
                {
                  name: 'ctaLinkTrueblue',
                  data: {
                    children: [
                      {
                        name: 'children',
                        data: {
                          href: 'https://truebluetest.jetblue.com/enroll/join-us',
                          newWindow: '_self',
                          linkType: 'JOIN_LINK',
                          type: 'internal',
                          title: 'Join today',
                          actionType: 'BUTTON',
                        },
                      },
                      {
                        name: 'children',
                        data: {
                          href: 'https://www-dev2.jetblue.com/signin',
                          newWindow: '_self',
                          linkType: 'SIGNIN_LINK',
                          type: 'internal',
                          title: 'Sign in',
                          actionType: 'LINK',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        link: [
          {
            href: 'http://truebluetest.jetblue.com',
            newWindow: '_self',
            title: 'TrueBlue',
            type: 'internal',
            image: [
              {
                src: 'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/menu/Silhouette-White2.svg',
                height: '0',
                width: '0',
                alt: 'TrueBlue',
              },
            ],
          },
        ],
      },
    },
    {
      name: 'trueBlue',
      data: {
        template: 'TRUEBLUE',
        loggedInSection: [
          {
            name: 'loggedIn',
            data: {
              topSection: [
                {
                  name: 'topLoggedInLinkTrueblue',
                  data: {
                    children: [
                      {
                        name: 'children',
                        data: {
                          points: 'My points',
                          travelBank: 'My Travel Bank',
                          trueBlueTheme: 'light',
                          myTiles: 'My tiles',
                          showTileTracker: 'true',
                          title: 'New TrueBlue Top Section',
                          greeting: 'Hello',
                          TrueBlueNrtxt: '#',
                          mosaicTheme: 'dark',
                          backgroundColor: [
                            {
                              bgColorMosaic: '#00205b',
                              bgColorTrueblue: '#f6f6f6',
                            },
                          ],
                          image: [
                            {
                              srcMosaic:
                                'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/MosaicBadgeDropdown.svg',
                              height: '0',
                              width: '0',
                              srcDefault:
                                'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/TrueBlue_dropdown_Logo.svg',
                            },
                          ],
                          link: [
                            {
                              href: 'https://truebluetest.jetblue.com/my-dashboard',
                              newWindow: '_self',
                              buttonStyle: 'secondary',
                              type: 'internal',
                              title: 'View dashboard',
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
              middleSection: [
                {
                  name: 'middleLinkTrueblue',
                  data: {
                    section: [
                      {
                        name: 'children',
                        data: {
                          theme: 'dark',
                          bodyText: [
                            {
                              content:
                                '<p><a href="/preference-center/profile" style="cursor: pointer;text-decoration: none;" target="_self" type="external">Profile & Settings</a></p>\n\n<p><a href="/manage-trips" style="cursor: pointer;text-decoration: none;" target="_self" type="external">Manage Trips</a></p>\n\n<p><a href="https://travelbank.jetblue.com/tbank/user/login.html?airlineCode=279" style="cursor: pointer;text-decoration: none;" target="_blank" type="external">Use Travel Bank Credit</a></p>\n\n<p><a href="/trueblue" style="cursor: pointer;text-decoration: none;" target="_self" type="external">TrueBlue & Mosaic Benefits</a><br />\n<br />\n<a href="http://www-dev2.jetblue.com" target="_self" type="external">Home</a></p>\n',
                              title: 'New TrueBlue Signed In Middle Section',
                            },
                          ],
                          layout: 'center',
                        },
                      },
                    ],
                  },
                },
              ],
              signoutSection: [
                {
                  name: 'signoutLinkTrueblue',
                  data: {
                    children: [
                      {
                        name: 'children',
                        data: {
                          newWindow: '_self',
                          href: '/',
                          linkType: 'SIGNOUT_LINK',
                          type: 'internal',
                          title: 'Sign Out',
                          actionType: 'LINK',
                        },
                      },
                    ],
                  },
                },
              ],
              bottomSection: [
                {
                  name: 'bottomLinkTrueblue',
                  data: {
                    section: [
                      {
                        name: 'children',
                        data: {
                          theme: 'light',
                          layout: 'left',
                          bgColor: '#fff',
                          bodyTextWithImage: [
                            {
                              content:
                                '<p>Earn 50,000 bonus points after qualifying activity. Terms apply.</p>\n',
                              type: 't2',
                              title: 'New TrueBlue Barclay Card Placement Test',
                              image: [
                                {
                                  src: 'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/imagery/JB-Credit-Card-Plus.png',
                                  height: '480',
                                  width: '640',
                                },
                              ],
                              link: [
                                {
                                  href: '/best-fare-finder',
                                  newWindow: '_blank',
                                  title: 'Get the JetBlue card',
                                  type: 'internal',
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        loggedOutSection: [
          {
            name: 'loggedOut',
            data: {
              topSection: [
                {
                  name: 'topLinkTrueblue',
                  data: {
                    '0': [
                      {
                        name: 'ctaLinkTrueblue',
                        data: {
                          children: [
                            {
                              name: 'children',
                              data: {
                                newWindow: '_self',
                                href: '/signin',
                                linkType: 'SIGNIN_LINK',
                                title: 'Sign In',
                                type: 'internal',
                                actionType: 'LINK',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
              middleSection: [
                {
                  name: 'middleLinkTrueblue',
                  data: {
                    section: [
                      {
                        name: 'children',
                        data: {
                          theme: 'dark',
                          bodyText: [
                            {
                              title: 'TrueBlue Dropdown Middle Section',
                              content:
                                '<h3>Rewards are around the corner with TrueBlue.</h3>\n\n<ul class="Checkmark-list">\n\t<li>Manage bookings & points</li>\n\t<li>Pool points with others</li>\n\t<li>Get rewarded for traveling</li>\n</ul>\n',
                              link: [
                                {
                                  newWindow: '_self',
                                  href: '/trueblue',
                                  type: 'internal',
                                  title: 'See TrueBlue & Mosaic benefits',
                                },
                              ],
                              button: [
                                {
                                  href: 'https://truebluetest.jetblue.com',
                                  newWindow: '_self',
                                  title: 'Join today',
                                  type: 'external',
                                },
                              ],
                            },
                          ],
                          layout: 'left',
                        },
                      },
                    ],
                  },
                },
              ],
              bottomSection: [
                {
                  name: 'bottomLinkTrueblue',
                  data: {
                    section: [
                      {
                        name: 'children',
                        data: {
                          theme: 'light',
                          layout: 'center',
                          bgColor: '#f6f6f6',
                          bodyTextWithImage: [
                            {
                              type: 't4',
                              content:
                                '<p>Org</p>\n\n<p></p>\n\n<p>Earn 50,000 bonus points after qualifying activity. Terms apply.</p>\n',
                              title:
                                'New TrueBlue Barclay Card Placement original',
                              image: [
                                {
                                  src: 'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/imagery/JB-Credit-Card-Plus.png',
                                  height: '480',
                                  width: '640',
                                },
                              ],
                              link: [
                                {
                                  href: '/best-fare-finder',
                                  newWindow: '_blank',
                                  title: 'Get the JetBlue card',
                                  type: 'internal',
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
              ctaSection: [
                {
                  name: 'ctaLinkTrueblue',
                  data: {
                    children: [
                      {
                        name: 'children',
                        data: {
                          newWindow: '_self',
                          href: '/',
                          linkType: 'SIGNIN_LINK',
                          title: 'CTA Section',
                          type: 'internal',
                          actionType: 'LINK',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        link: [
          {
            newWindow: '_self',
            href: '/',
            title: 'NTrublue',
            type: 'internal',
            image: [
              {
                src: 'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/menu/Silhouette-White2.svg',
                height: '0',
                width: '0',
              },
            ],
          },
        ],
      },
    },
  ],
};

export const CMS_HEADER_FALLBACK_PARSED = parseHeader(
  CMS_HEADER_FALLBACK as any,
);
