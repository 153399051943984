import { CMS_FALLBAK_TEMPLATES_DICT } from '@core/cms/cms-fallback-templates-dict.const';
import { CMS_FOOTER_FALLBACK_PARSED } from '@core/cms/cms-footer-fallback.const';
import { CMS_HEADER_FALLBACK_PARSED } from '@core/cms/cms-header-fallback.const';
import { CMS_HOMEPAGE_FALLBACK } from '@core/cms/cms-homepage-fallback.const';
import { includes, keys } from 'ramda';

import { GlobalActions, GlobalConfigActionsUnion } from './global.actions';
import { GlobalState } from './types';

export const initialGlobalConfigState: GlobalState = {
  componentConfig: {
    backToTop: false,
    bgUrl: '',
  },
  travelAlerts: undefined,
  header: undefined,
  footer: undefined,
  template: undefined,
  lastRequestedEndpoint: undefined,
  primaryTemplateRendering: false,
  secondaryTemplateRendering: [],
  tertiaryTemplateRendering: [],
  headerTranslations: undefined,
  footerTranslations: undefined,
  rawHeader: undefined,
  rawFooter: undefined,
  isHeaderRendered: false,
  isCmsFailed: false,
};

export function globalReducer(
  state: GlobalState,
  action: GlobalConfigActionsUnion,
): GlobalState {
  switch (action.type) {
    case GlobalActions.SET_GLOBAL_COMPONENT_CONFIG:
      return {
        ...state,
        componentConfig: {
          ...state.componentConfig,
          ...(!!action.payload ? action.payload : state.componentConfig),
        },
      };
    case GlobalActions.SET_GLOBAL_HEADER_TRAVEL_ALERTS_CONTENT:
      return {
        ...state,
        travelAlerts: !!action.payload ? action.payload : state.travelAlerts,
      };
    case GlobalActions.LOAD_HEADER:
      return {
        ...state,
        header: action['error'] ? state.header : action.payload,
      };
    case GlobalActions.LOAD_HEADER_RAW:
      return {
        ...state,
        rawHeader: action['error'] ? state.rawHeader : action.payload,
      };
    case GlobalActions.SET_RENDERED_HEADER:
      return {
        ...state,
        isHeaderRendered: action.payload,
      };
    case GlobalActions.LOAD_FOOTER:
      return {
        ...state,
        footer: action['error'] ? state.footer : action.payload,
      };
    case GlobalActions.LOAD_FOOTER_RAW:
      return {
        ...state,
        rawFooter: action['error'] ? state.rawFooter : action.payload,
      };
    case GlobalActions.LOAD_HEADER_TRANSLATIONS:
      return {
        ...state,
        headerTranslations: action.payload,
      };
    case GlobalActions.LOAD_FOOTER_TRANSLATIONS:
      return {
        ...state,
        footerTranslations: action.payload,
      };
    case GlobalActions.LOAD_TEMPLATE:
      return {
        ...state,
        template: action['error'] ? state.template : action.payload,
      };
    case GlobalActions.RESOLVE_FALLBACK_TEMPLATE:
      return {
        ...state,
        isCmsFailed: true,
        header: CMS_HEADER_FALLBACK_PARSED,
        footer: CMS_FOOTER_FALLBACK_PARSED,
        template: includes(action.payload, keys(CMS_FALLBAK_TEMPLATES_DICT))
          ? CMS_FALLBAK_TEMPLATES_DICT[action.payload]
          : CMS_HOMEPAGE_FALLBACK,
      };
    case GlobalActions.RESOLVE_FALLBACK_FOOTER:
      return {
        ...state,
        isCmsFailed: true,
        footer: CMS_FOOTER_FALLBACK_PARSED,
      };
    case GlobalActions.RESOLVE_FALLBACK_HEADER:
      return {
        ...state,
        isCmsFailed: true,
        header: CMS_HEADER_FALLBACK_PARSED,
      };
    case GlobalActions.SET_LAST_REQUESTED_ENDPOINT:
      return {
        ...state,
        lastRequestedEndpoint: action['error']
          ? state.lastRequestedEndpoint
          : action.payload,
      };
    case GlobalActions.SET_PRIMARY_TEMPLATE_RENDERING_STATUS:
      return {
        ...state,
        primaryTemplateRendering: action.payload,
      };
    case GlobalActions.SET_SECONDARY_TEMPLATE_RENDERING_STATUS:
      return {
        ...state,
        secondaryTemplateRendering: !action.payload
          ? state.secondaryTemplateRendering.concat([action.payload])
          : state.secondaryTemplateRendering.slice(
              0,
              state.secondaryTemplateRendering.length - 1,
            ),
      };
    case GlobalActions.SET_TERTIARY_TEMPLATE_RENDERING_STATUS:
      return {
        ...state,
        tertiaryTemplateRendering: !action.payload
          ? state.tertiaryTemplateRendering.concat([action.payload])
          : state.tertiaryTemplateRendering.slice(
              0,
              state.tertiaryTemplateRendering.length - 1,
            ),
      };

    default:
      return state;
  }
}
