import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppConfigService } from '@core/app-config/app-config.service';
import { HeaderService } from '@shared/header/header.service';
import { isOver21 } from '@shared/trueblue/tb-logged-in/tb-logged-in.utils';
import { TBLoggedInContentResponse } from '@shared/trueblue/tb-logged-in/types';
import { TrueBlueLoggedInSection } from '@shared/trueblue/tb-logged-in/types/new-tb-logged-in.response.type';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { PreferenceCenterFacade } from '@store/preference-center/preference-center.facade';
import { ProfileFacade } from '@store/profile';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @description Logged in users and dropdown in a mobile viewport
 * @WhereIsItUsed Header mobile component
 */
@Component({
  selector: 'jb-header-mobile-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileLoggedInComponent {
  @Input() loggedInSection: TBLoggedInContentResponse;
  @Input() trueBlueLoggedInSection: TrueBlueLoggedInSection;

  isModalOpen$ = this.headerService.isLoggedInModalOpen$;
  travelBankBalance$: Observable<number>;
  profile: AuthProfile;
  profilePicture$: Observable<string>;
  firstName: string;
  points: string;
  tbNumber: string;
  firstNameInitial: string;
  lastNameInitial: string;
  enrollChild: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private authFacade: AuthFacade,
    private profileFacade: ProfileFacade,
    private preferenceCenterFacade: PreferenceCenterFacade,
    private headerService: HeaderService,
    private appConfig: AppConfigService,
  ) {
    this.profilePicture$ = this.authFacade.profilePictureBlob;
    this.travelBankBalance$ = this.preferenceCenterFacade.travelBankBalance;
    this.subscriptions.add(
      combineLatest([
        this.authFacade.authProfile.pipe(filter<AuthProfile>(Boolean)),
        this.profileFacade.profileIsLoading,
        this.profileFacade.memberProfile,
      ]).subscribe(([authProfile, tbProfileIsLoading, tbProfile]) => {
        this.profile = authProfile;
        this.enrollChild = isOver21(this.profile.birthDate);
        this.firstName =
          this.profile.fname.charAt(0).toUpperCase() +
          this.profile.fname.slice(1);
        this.firstNameInitial = this.profile.fname.charAt(0).toUpperCase();
        this.lastNameInitial = this.profile.lname.charAt(0).toUpperCase();
        if (!!tbProfile) {
          const curLoyalty = tbProfile.loyalty?.find(
            loyalty => loyalty.membershipId === this.profile.membershipid,
          );
          this.points = curLoyalty?.amount
            ? parseInt(curLoyalty.amount).toLocaleString()
            : null;
        } else if (!tbProfileIsLoading) {
          this.points = this.profile.points
            ? parseInt(this.profile.points).toLocaleString()
            : null;
        } else {
          this.points = null;
        }
        this.tbNumber = this.profile.membershipid;
      }),
    );
  }

  toggleLoggedInModal() {
    this.headerService.toggleLoggedInModal();
  }

  handleSignOut() {
    this.authFacade.redirectToLogoutPageOktaFlow();
  }
}
