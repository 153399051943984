import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONFIG_X_AUTH_TOKEN } from '@core/app-config/types/mocks';
import {
  AppConfigService,
  HttpService,
  LocalStorageService,
} from '@core/index';
import { createErrorAction, networkError } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import { NetworkError } from '@store/shared/types/network-error.type';
import { Observable, of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CartActions } from '../cart.actions';
import { CartResponse } from '../types/cart-state.interface';

@Injectable({ providedIn: 'root' })
export class CartApi {
  constructor(
    private http: HttpService,
    private storeService: StoreService,
    private appConfig: AppConfigService,
    private localStorageService: LocalStorageService,
  ) {}

  getCart(
    cartJwtToken?: string,
    xAuthToken?: string,
  ): Observable<HttpResponse<CartResponse> | NetworkError> {
    const url = this.appConfig.shoppingCartUrl;
    // Assume the user is a guest and there is a cartJwtToken
    let body: any = { cartJwtToken };
    // Headers used for guest user
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-Version': this.appConfig.shoppingCartConfig.apiVersion,
      'Booking-Application-Type': 'DOTCOM',
    });
    /*
    If xAuthToken is provided, then the user is authenticated.
    Hence, add in the call:
    xAuthToken in the header.
    "validate:false" in the body.
    Our backend is going to locate the user using the xAuthToken.
    */
    if (xAuthToken) {
      body = { validate: false };
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'API-Version': this.appConfig.shoppingCartConfig.apiVersion,
        'Booking-Application-Type': 'DOTCOM',
        'x-auth-token': xAuthToken,
      });
    }

    return this.http
      .postObserveResponse<CartResponse>(url, body, { headers })
      .pipe(
        catchError(err => {
          this.localStorageService.removeItem(CONFIG_X_AUTH_TOKEN);
          this.storeService.dispatchAction(
            createErrorAction(CartActions.FETCH_CART_FAILED, err),
          );
          return observableOf(networkError());
        }),
      );
  }
}
