import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { BodyTextImage } from '@dynamic-components/body-text-image-container/types';

/**
 * @description Display message theres no flights on a month with a button to go next
 * @WhereIsItUsed calendar component
 */
@Component({
  selector: 'jb-calendar-no-flights',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-no-flights.component.html',
})
export class CalendarNoFlightsComponent implements AfterViewInit {
  @Input() height: string;
  @Input() last = false;

  @Output() viewNextMonth = new EventEmitter<void>();

  @ViewChild('myButton') button: ElementRef;

  constructor(private appConfig: AppConfigService) {}

  readonly title = 'No flights found this month.';
  readonly description =
    'Flights may be sold out, operate seasonally, or begin service at a later time.';
  readonly noFlightContent: BodyTextImage = {
    content: `<h3>${this.title}</h3><p>${this.description}</p>`,
    type: 't4',
    image: [
      {
        src: `${this.appConfig.paths[0].imagesRootPath}/bff/no-flights-illustration.svg`,
        alt: 'no flights',
      },
    ],
  };

  ngAfterViewInit(): void {
    this.button?.nativeElement.focus({ preventScroll: true });
  }
}
