import { HttpParams } from '@angular/common/http';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BookerRestrictedOriginsModalWarning } from '@core/app-config/types/booker-restricted-origins-modal-warning.type';
import { convertToDate } from '@core/utils/date.utils';
import { toSelectedTravelers } from '@dynamic-components/booker-v2/shared/traveler-selector/utils';
import {
  BOOKER_CONFIG,
  BookerFormState,
  BookerResponse,
  isBothInternationalFlights,
  isOneWayAndInternational,
  isRestrictedOrigin,
  isTodayWarning,
  isUnnCount,
  WarningMessage,
} from '@shared/booker';
import { BookerAirVanillaForm } from '@shared/booker/types/booker-air-vanilla-js-form.type';
import { BookerFlight } from '@shared/booker/types/booker-flight.type';
import { buildBookerPOSValue } from '@shared/booker/utils/booker-air-submit.logic';
import { RecentAirSearch } from '@shared/types';
import { Airport } from '@store/origins/types';
import { format, isValid } from 'date-fns';
import { isNil, mergeDeepRight } from 'ramda';

export const getAppropriateDateString = (date: string | Date): string => {
  const shortDateFormat = 'yyyy-MM-dd';
  const isShortDateString =
    typeof date === 'string' && date.length === shortDateFormat.length;

  const newDateObject = typeof date === 'string' ? new Date(date) : date;

  return isShortDateString && typeof date === 'string'
    ? date
    : format(newDateObject, shortDateFormat);
};

// todo: MOVE TO A PLACE LIKE.... TYPING UTILS
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type FormType<T> = {
  [P in keyof T]: [T[P]];
};

export const formDataToSimpleAirSearchData = (
  formData: BookerFormState,
  bookerConfig = BOOKER_CONFIG,
): RecentAirSearch => ({
  originCode: formData.flights[0].origin?.code
    ? [formData.flights[0].origin?.code]
    : [],
  destinationCode: formData.flights[0].destination?.code
    ? [formData.flights[0].destination?.code]
    : [],
  departDate: isValid(formData.departureDate)
    ? [format(formData.departureDate, 'yyyy-MM-dd')]
    : [],
  returnDate: isValid(formData.returnDate)
    ? [format(formData.returnDate, 'yyy-MM-dd')]
    : [],
  promoCode: formData.promoCode,
  // todo: check to see if this typo is necessary
  intineraryType: formData.tripType,
  ADT: formData.travelers.allTravelers.adults,
  CHD: formData.travelers.allTravelers.children,
  INF: formData.travelers.allTravelers.infants,
  fare: formData.bookWithPoints
    ? bookerConfig.FARE_TYPES.POINTS
    : bookerConfig.FARE_TYPES.DOLLARS,
});

const formatForFormBuilder = (fb, bookerValues: Partial<BookerFormState>) => {
  const flightFormGroup = {
    origin: bookerValues.flights && bookerValues.flights[0]?.origin,
    destination: bookerValues.flights && bookerValues.flights[0]?.destination,
    // departureDate: ''
  };

  return fb.group({
    flights: fb.array([flightFormGroup]),
    departureDate: [bookerValues.departureDate],
    returnDate: [bookerValues.returnDate],
    promoCode: [bookerValues.promoCode],
    tripType: [bookerValues.tripType],
    // todo: bookWithPoints only cares about the boolean, should be b
    bookWithPoints: [bookerValues.bookWithPoints],
    recaptchaReactive: [bookerValues.recaptchaReactive],
    travelers: [bookerValues.travelers],
  });
};

export function createAirBookerForm(fb: UntypedFormBuilder): UntypedFormGroup {
  const defaultFormValues = BOOKER_CONFIG.DEFAULT_FORM_VALUES;
  const defaultTravelers = {
    adults: defaultFormValues.PAX.ADT,
    children: defaultFormValues.PAX.CHD,
    infants: defaultFormValues.PAX.INF,
    childAges: [],
  };
  const form = fb.group({
    fromAirport: [undefined],
    toAirport: [undefined],
    promoCode: [undefined],
    tripType: [defaultFormValues.TRIP_TYPE],
    bookWithPoints: [defaultFormValues.BOOK_WITH_POINTS],
    departureDate: [defaultFormValues.DEPARTURE_DATE],
    returnDate: [defaultFormValues.RETURN_DATE],
    recaptchaReactive: [undefined],
    travelers: [defaultTravelers, Validators.required],
  });
  return form;
}

export function createAirBookerFormV2(
  fb: UntypedFormBuilder,
  initialConfig: Partial<BookerFormState> = {},
): UntypedFormGroup {
  const defaultTravelers = {
    adults: BOOKER_CONFIG.DEFAULT_PAX_VALUES.ADT,
    children: BOOKER_CONFIG.DEFAULT_PAX_VALUES.CHD,
    infants: BOOKER_CONFIG.DEFAULT_PAX_VALUES.INF,
    childAges: [],
  };
  const defaultConfigToInitialConfig = (
    bookerConfig,
  ): RecursivePartial<BookerFormState> => ({
    bookWithPoints: bookerConfig.BOOK_WITH_POINTS,
    departureDate: bookerConfig.DEPARTURE_DATE,
    returnDate: bookerConfig.RETURN_DATE,
    flights: [],
    travelers: toSelectedTravelers([defaultTravelers]),
  });

  const defaultFormValues = mergeDeepRight(
    defaultConfigToInitialConfig(BOOKER_CONFIG.DEFAULT_FORM_VALUES),
    initialConfig,
  ) as any;

  return formatForFormBuilder(fb, defaultFormValues);
}

export function getAirBookerWarnings(
  response: BookerResponse['warningMessages'],
  depart: string,
  numAdults: number,
  numChildren: number,
  tripType: string,
  origin: Airport,
  destination: Airport,
  bookerRestrictedOriginsModalWarning: BookerRestrictedOriginsModalWarning,
): WarningMessage[] {
  return [
    isRestrictedOrigin(origin.code, bookerRestrictedOriginsModalWarning),
    isTodayWarning(response, depart),
    isUnnCount(response, numAdults, numChildren),
    isOneWayAndInternational(response, tripType, origin, destination),
    isBothInternationalFlights(response, origin, destination),
  ].filter(e => e);
}

export function getMultiCityBookerWarnings(
  response: BookerResponse['warningMessages'],
  departs: string[],
  numAdults: number,
  numChildren: number,
  origins: Airport[],
  destinations: Airport[],
  bookerRestrictedOriginsModalWarning: BookerRestrictedOriginsModalWarning,
): WarningMessage[] {
  return [
    // Avoid showing the same message twice if both origins are the same.
    origins[0].code === origins[1].code
      ? null
      : isRestrictedOrigin(
          origins[0].code,
          bookerRestrictedOriginsModalWarning,
        ),
    isRestrictedOrigin(origins[1].code, bookerRestrictedOriginsModalWarning),
    isTodayWarning(response, departs[0]),
    isTodayWarning(response, departs[1]),
    isUnnCount(response, numAdults, numChildren),
    isBothInternationalFlights(response, origins[0], destinations[0]),
    isBothInternationalFlights(response, origins[1], destinations[1]),
  ].filter(e => e);
}

export function captchaValidator(message) {
  return (control: AbstractControl): { [key: string]: string } => {
    const value = control.value;
    if (!value) {
      return {
        captchaInvalid: message,
      };
    }
    return null;
  };
}

export function setCaptchaValidation(
  control: AbstractControl | UntypedFormControl,
  message: string,
): void {
  if (control) {
    control.setValidators(captchaValidator(message));
    control.markAsTouched();
    control.updateValueAndValidity();
  }
}

// todo: refactor to be a little clearer, this is only a slight refactor, mostly moved to it's own function
export function createMobileBookingUrlMainBooker(
  baseBookerUrl: string,
  baseMobileUrl: string,
  isProdEnv: boolean,
  formResult: any,
) {
  // usablenet flow
  const formattedDepart = format(formResult.departureDate, 'dd-MM-yyyy');
  const formattedReturn = formResult.returnDate
    ? format(convertToDate(formResult.returnDate), 'dd-MM-yyyy')
    : '';

  const relativeMobileBook = baseBookerUrl.replace('https://', '');
  const promoCodeParam = formResult.promoCode
    ? `&airPromo=${formResult.promoCode}`
    : '';
  return `${baseMobileUrl}/h5/r/${relativeMobileBook}/shop/search/?submitted-form=bkSearch\
&journeySpan=${formResult.tripType}\
&departureAirportCode=${formResult.flights[0].origin.code}\
&returnAirportCode=${formResult.flights[0].destination.code}\
&numAdults=${formResult?.travelers?.allTravelers?.adults}\
&numChildren=${formResult?.travelers?.allTravelers?.children}\
&numInfants=${formResult?.travelers?.allTravelers?.infants}\
&startDate=${formattedDepart}\
&endDate=${formattedReturn}\
&jbBookerCurrency-flights=${!!formResult.bookWithPoints ? 'tb' : 'usd'}\
&env=${isProdEnv ? 'prod' : 'stg3'}\
${promoCodeParam}`;
}

// todo: refactor to be a little clearer, this is only a slight refactor, mostly moved to it's own function
export function createMobileBookingUrlBFF(
  baseBookerUrl: string,
  baseMobileUrl: string,
  isProdEnv: boolean,
  formResult: any,
) {
  const relativeMobileBook = baseBookerUrl.replace('https://', '');
  const promoCodeParam = formResult.promoCode
    ? `&airPromo=${formResult.promoCode}`
    : '';
  return `${baseMobileUrl}/h5/r/${relativeMobileBook}/shop/search/?submitted-form=bkSearch\
&journeySpan=${formResult.journeySpan}\
&departureAirportCode=${formResult.origin}\
&returnAirportCode=${formResult.destination}\
&numAdults=${formResult.numAdults}\
&numChildren=${formResult.numChildren}\
&numInfants=${formResult.numInfants}\
&startDate=${formResult.departureDate}\
&endDate=${formResult.returnDate}\
&jbBookerCurrency-flights=${formResult['jbBookerCurrency-flights'] || 'usd'}\
&env=${isProdEnv ? 'prod' : 'stg3'}\
${promoCodeParam}`;
}

export const createVanillaFormData = (
  {
    origin,
    destination,
    bookWithPoints,
    tripType,
    departureDate,
    returnDate,
    travelers,
    promoCode,
  }: Omit<BookerFormState, 'flights'> & {
    origin: Airport;
    destination: Airport;
  },
  countryCode: string,
  multiCityFlights: BookerFlight[] = [],
  dateFormat: string = 'yyyy-MM-dd',
): BookerAirVanillaForm => {
  // handles the flight formData for either multiCity or regular flights
  const flightFormData = multiCityFlights.length
    ? multiCityFlights.reduce(
        (acc, nextFlight, i) => ({
          ...acc,
          [`originDestinationOptions[${i}].originPoint`]:
            nextFlight.fromAirport.code,
          [`originDestinationOptions[${i}].destinationPoint`]:
            nextFlight.toAirport.code,
          [`originDestinationOptions[${i}].departureDate`]:
            getAppropriateDateString(nextFlight.departureDate),
        }),
        {},
      )
    : {
        origin: origin.code,
        destination: destination.code,
        departureDate: format(departureDate, dateFormat),
        returnDate: returnDate ? format(returnDate, dateFormat) : undefined,
      };

  return {
    ...flightFormData,
    pos: buildBookerPOSValue(countryCode, bookWithPoints),
    flexibleSearch: 'TRUE',
    searchType: 'NORMAL',
    journeySpan: tripType,
    numAdults: travelers.allTravelers.adults,
    numChildren: travelers.allTravelers.children,
    numInfants: travelers.allTravelers.infants,
    refundable: 'false',
    jbBookerSearchType: 'flights',
    sharedMarket:
      (!!origin?.interline && !!origin.jb) ||
      (!!destination?.interline && !!destination?.jb),
    roundTripFaresFlag: !!origin?.interline || !!destination?.interline,
    'jbBookerCurrency-getaway': bookWithPoints ? 'tb' : 'usd',
    'jbBookerCurrency-flights': bookWithPoints ? 'tb' : 'usd',
    fareFamily: bookWithPoints ? 'TRUEBLUE' : 'LOWESTFARE',
    fareDisplay: bookWithPoints ? 'points' : 'lowest',
    promoCode: promoCode || '',
    coupon: '', // was always blank in our submit component, may be used in the future?
  };
};

export const convertBookerFormDataToQueryString = (
  data: BookerAirVanillaForm,
  multiCityFlights: BookerFlight[] = [],
): string => {
  // handles the flight formData for either multiCity or regular flights
  const flightFormData = multiCityFlights.length
    ? multiCityFlights.reduce(
        (acc, nextFlight, i) => ({
          ...acc,
          [`from[${i}]`]: nextFlight.fromAirport.code,
          [`to[${i}]`]: nextFlight.toAirport.code,
          [`travelDate[${i}]`]: getAppropriateDateString(
            nextFlight.departureDate,
          ),
        }),
        {},
      )
    : {
        from: data.origin,
        to: data.destination,
        depart: data.departureDate,
        return: (data.journeySpan !== 'OW' && data.returnDate) || null,
      };

  const base = {
    ...flightFormData,
    isMultiCity: !!multiCityFlights.length,
    noOfRoute: multiCityFlights.length || 1,
    adults: data.numAdults,
    children: data.numChildren,
    infants: data.numInfants,
    sharedMarket: data.sharedMarket,
    roundTripFaresFlag: data.roundTripFaresFlag,
    usePoints: data.fareFamily === 'TRUEBLUE',
    airPromo: data.promoCode || null,
  };

  const queryparams = new HttpParams();
  return Object.keys(base)
    .reduce(
      (acc, paramKey) =>
        !isNil(base[paramKey]) ? acc.append(paramKey, base[paramKey]) : acc,
      queryparams,
    )
    .toString();
};
