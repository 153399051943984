import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  /*
    Adapted from MDN :
    https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
  */
  try {
    const storage = window.localStorage;
    const check = '_jb_local_storage_check_';
    storage.setItem(check, check);
    storage.removeItem(check);
  } catch (localStorageErr) {
    console.warn('Cannot use LocalStorage.');
    return reducer;
  }

  try {
    return localStorageSync({
      keys: [
        {
          auth: [
            'authProfile',
            'authProfileForOktaAndLeanProfileService',
            'oktaSessionCheckStatus',
            'idToken',
            'accessToken',
            'uid',
          ],
        },
        'schedule-extension',
        'booker',
        'traveler',
        'preferenceCenter',
      ],
      rehydrate: true,
      storageKeySerializer: key => {
        if (key === 'auth') {
          return 'authJBDotcom';
        }

        return key;
      },
    })(reducer);
  } catch (error) {
    console.warn(`Failed to create LocalStorage`, error);
  }
}
