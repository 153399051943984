<div class="bg-white progress-container">
  <jb-progress-indicator
    class="db bt b--off-white bw2 page-margin"
    [content]="content.progressIndicator"
    [maxSeats]="content.calendarAggregator.maxSeats"
  >
  </jb-progress-indicator>
  <jb-weekdays-bar class="db" [content]="content.weekdays"></jb-weekdays-bar>
</div>
<jb-calendar-aggregator
  class="db"
  [content]="content.calendarAggregator"
  [formValue]="formValue"
  [dealStartDate]="dealStartDate"
>
</jb-calendar-aggregator>
