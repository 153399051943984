import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  inject,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppConfigService } from '@core/app-config';
import { DisableBodyScrollService } from '@core/document-actions';
import { DOCUMENT, WINDOW } from '@core/injection-tokens';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { PreferenceCenterFacade } from '@store/preference-center/preference-center.facade';
import { ProfileFacade } from '@store/profile';
import { RouterFacade } from '@store/router/router.facade';
import { TrueBlueProfile } from '@store/trueblue/types';
import { JbFlyoutComponent } from 'jb-component-library';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { isOver21 } from './tb-logged-in.utils';
import { TrueBlueLoggedInSection } from './types/new-tb-logged-in.response.type';

/**
 * @description Logged in user and dropdown in a desktop viewport
 * @WhereIsItUsed Subnav-desktop
 */
@Component({
  selector: 'jb-tb-logged-in',
  templateUrl: './tb-logged-in.component.html',
  styleUrls: ['./tb-logged-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TbLoggedInComponent implements AfterViewInit, AfterViewChecked {
  private _destroy = inject(DestroyRef);

  @Input() content: TrueBlueLoggedInSection;
  @Input() open = false;
  @Input() isMobile = false;

  @ViewChild('flyoutSignedIn') flyoutSignedIn: JbFlyoutComponent;

  isPreferenceCenterPage: Observable<boolean> =
    this.routerFacade.isPreferenceCenterPage;
  travelBankBalance: Observable<number> =
    this.preferenceCenterFacade.travelBankBalance;

  profile: AuthProfile;
  profilePicture$: Observable<string>;
  firstName: string;
  points: string;
  tbNumber: string;
  firstNameInitial: string;
  lastNameInitial: string;
  enrollChild: boolean;
  clm5: boolean;
  mySettings = {
    href: '/preference-center/profile',
    text: 'My Settings',
  };
  newHeaderIsOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private routerFacade: RouterFacade,
    private authFacade: AuthFacade,
    private profileFacade: ProfileFacade,
    private preferenceCenterFacade: PreferenceCenterFacade,
    private appConfig: AppConfigService,
    private scrollService: DisableBodyScrollService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) public window,
  ) {
    this.profilePicture$ = this.authFacade.profilePictureBlob;
    this.clm5 = this.appConfig.clm5; // DOT-1889: Unhide links on mobile if clm5
    // Check for a prior instance of this logged-in component (two will be loaded).
    // Because we're in the constructor, and any inputs are processed in ngOnInit,
    // we can't just pass in true and then false for loading the profile just once.
    combineLatest([
      this.authFacade.authProfile.pipe(filter<AuthProfile>(Boolean)),
      this.profileFacade.profileIsLoading,
      this.profileFacade.memberProfile.pipe(
        takeUntilDestroyed(this._destroy),
        filter<TrueBlueProfile>(Boolean),
        distinctUntilChanged(
          (prev, curr) =>
            prev?.loyalty[0]?.membershipId === curr?.loyalty[0]?.membershipId,
        ),
      ),
    ]).subscribe(([authProfile, tbProfileIsLoading, tbProfile]) => {
      this.profile = authProfile;
      this.enrollChild = isOver21(this.profile.birthDate);
      this.firstName =
        this.profile.fname.charAt(0).toUpperCase() +
        this.profile.fname.slice(1);
      this.firstNameInitial = this.profile.fname.charAt(0).toUpperCase();
      this.lastNameInitial = this.profile.lname.charAt(0).toUpperCase();
      // prints the number of points with commas
      // get latest points from member profile only if tbProfile is available
      if (!!tbProfile) {
        const curLoyalty = (tbProfile?.loyalty || []).find(
          loyalty => loyalty.membershipId === this.profile.membershipid,
        );
        this.points = curLoyalty?.amount
          ? parseInt(curLoyalty.amount, 0).toLocaleString()
          : null;
      } else if (!tbProfileIsLoading) {
        // fallback to points from authprofile
        this.points = this.profile.points
          ? parseInt(this.profile.points, 0).toLocaleString()
          : null;
      } else {
        // Do not display anything just yet.
        this.points = null;
      }
      this.tbNumber = this.profile.membershipid;
      this.cdRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    if (this.flyoutSignedIn) {
      from(this.flyoutSignedIn.afterOpened)
        .pipe(takeUntilDestroyed(this._destroy))
        .subscribe(() => this.handleOnFlyoutOpen());

      from(this.flyoutSignedIn.beforeClosed)
        .pipe(takeUntilDestroyed(this._destroy))
        .subscribe(() => this.handleOnFlyoutClose());
    }
  }
  ngAfterViewChecked() {
    const signedInTbHeight =
      this.document.getElementsByTagName('dot-tb-signed-in')[0]?.offsetHeight;
    signedInTbHeight
      ? this.document.documentElement.style.setProperty(
          '--signed-in-tb-height',
          `${signedInTbHeight}px`,
        )
      : this.document.documentElement.style.setProperty(
          '--signed-in-tb-height',
          `0px`,
        );
  }

  handleInsideClick(event: any) {
    event.stopPropagation();
  }

  toggleTBDropdown() {
    this.open = !this.open;

    if (this.open && this.isMobile) {
      this.scrollService.activate();
      this.renderer.addClass(this.document.body, 'fixed');
    } else {
      this.scrollService.deactivate();
      this.renderer.removeClass(this.document.body, 'fixed');
    }
  }

  disableScrollingOnMobile(newHeaderIsOpen: boolean) {
    if (newHeaderIsOpen && this.isMobile) {
      this.scrollService.activate();
      this.renderer.addClass(this.document.body, 'fixed');
    } else {
      this.scrollService.deactivate();
      this.renderer.removeClass(this.document.body, 'fixed');
    }
  }

  handleSignOut() {
    this.authFacade.redirectToLogoutPageOktaFlow();
  }
  closeModal($event: any) {
    this.flyoutSignedIn.toggle();
  }

  handleOnFlyoutOpen() {
    this.newHeaderIsOpen$.next(true);
    // Make flyout scrollable on landscape mode
    const navBox = this.elementRef.nativeElement.getBoundingClientRect();
    this.document.documentElement.style.setProperty(
      '--header-desktop-height',
      `${navBox.top + navBox.height}px`,
    );
    this.disableScrollingOnMobile(true);
  }

  handleOnFlyoutClose() {
    this.newHeaderIsOpen$.next(false);
    this.disableScrollingOnMobile(false);
  }
}
