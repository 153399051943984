<!-- cart empty -->
<a
  *ngIf="!!(cartFacade.isCartEmpty | async)"
  class="white pv3 relative-ns w-2"
  [jbSitemapLinker]="shoppingCart.emptyCart[0].link[0].href"
  [attr.target]="shoppingCart.emptyCart[0].link[0].newWindow"
  [attr.aria-label]="shoppingCart.emptyCart[0].alt"
>
  <jb-image
    [src]="shoppingCart.emptyCart[0].src"
    class="fl pointer fill-white shopping-cart flex justify-center"
  ></jb-image>
</a>
<!-- end cart empty -->
<!-- cart not empty -->
<a
  *ngIf="!(cartFacade.isCartEmpty | async)"
  class="white pv3 relative-ns w-2"
  [jbSitemapLinker]="shoppingCart.fullCart[0].link[0].href"
  [attr.target]="shoppingCart.fullCart[0].link[0].newWindow"
  [attr.aria-label]="shoppingCart.fullCart[0].alt"
>
  <jb-image
    [src]="shoppingCart.fullCart[0].src"
    class="fl pointer fill-white shopping-cart flex justify-center"
  ></jb-image>
</a>
<!-- end cart not empty -->
