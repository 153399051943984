import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BestFareDay, WindowService } from '@core/index';
import {
  SelectedTravelers,
  TravelerGroup,
} from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import { getControlVal } from '@shared/ui/utils/form-control-utils/get-control-val';
import { generateUniqueId } from '@shared/ui/utils/global-utils/generate-unique-id';
import { combineLatest, Subscription } from 'rxjs';

import { PointsWithCashPipe } from '../ui/pipes/points-with-cash';
import { ConfirmationBar } from './types';

/**
 * @description Formatted bar for display final information on bff before seeing flights
 * @WhereIsItUsed bff component
 */
@Component({
  selector: 'jb-confirmation-bar',
  templateUrl: './confirmation-bar.component.html',
  styleUrls: ['./confirmation-bar.component.scss'],
  providers: [PointsWithCashPipe],
})
export class ConfirmationBarComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() content: ConfirmationBar;
  @Input() currencyCode: string;
  id = generateUniqueId('confirmation-bar');
  departBff: UntypedFormControl;
  returnBff: UntypedFormControl;
  bookWithPoints: UntypedFormControl;

  isMobile = false;
  isPoints = false;
  travelers: TravelerGroup;
  totalFares: string;
  travelerText = '';

  private subscriptions: Subscription = new Subscription();

  constructor(
    public windowService: WindowService,
    private pwcPipe: PointsWithCashPipe,
  ) {
    this.subscriptions.add(
      this.windowService.isMobile.subscribe(mobile => {
        this.isMobile = mobile;
      }),
    );
  }

  ngOnInit(): void {
    if (!!this.form) {
      this.subscriptions.add(
        getControlVal(this.form.get('bookWithPoints')).subscribe(val => {
          this.isPoints = val;
        }),
      );

      this.subscriptions.add(
        getControlVal(this.form.get('travelers')).subscribe(
          (travelers: SelectedTravelers) => {
            this.travelers = travelers.allTravelers;
            const totalTravelers =
              this.travelers.adults +
              this.travelers.children +
              this.travelers.infants;
            this.travelerText = `${totalTravelers} ${
              totalTravelers > 1
                ? this.content.travelers
                : this.content.traveler
            }`;
          },
        ),
      );

      this.subscriptions.add(
        combineLatest([
          getControlVal(this.form.get('departBff')),
          getControlVal(this.form.get('returnBff')),
        ]).subscribe((bestFareDays: BestFareDay[]) => {
          // use pipe here so that it doesn't need to be piped twice in the template
          this.totalFares = this.pwcPipe.transform(
            bestFareDays.filter(val => !!val),
            this.isPoints,
            { large: true },
            this.content.ptsLabel,
            this.currencyCode,
            this.travelers,
          );
        }),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
