import { TypedDictionary } from '@core/utils';

import { Action } from '../shared/types/action.interface';
import { CountryWithAirportCodes, CountryWithAirports, Region } from './types';
import { AirportListAndMap } from './types/airport-list-and-map.type';

export enum OriginsActions {
  REQUEST_AIRPORTS = '[NgRx Init] Airports Requested',
  LOAD_AIRPORTS = '[Origins API] Airports Loaded',
  REQUEST_COUNTRIES = '[NgRx Init] Countries Requested',
  LOAD_COUNTRIES = '[Origins API] Countries Loaded',
  REQUEST_ORIGIN_AIRPORTS = '[City Pair Selector Component] Origin Countries Requested',
  LOAD_ORIGIN_AIRPORTS = '[Origins API] Origin Countries Loaded',
  REQUEST_DESTINATION_AIRPORTS = '[City Pair Selector Component] Destination Countries Requested',
  LOAD_DESTINATION_AIRPORTS = '[Origins API] Destination Countries Loaded',
  REQUEST_REGIONS = '[NgRx Init] Regions Requested',
  LOAD_REGIONS = '[Origins API] Regions Loaded',
  REQUEST_NEARBY_AIRPORTS = '[NgRx Init] Nearby Airports Requested',
  LOAD_NEARBY_AIRPORTS = '[Origins API] Nearby Airports Loaded',
  LOAD_COUNTRY_CODE_FOR_COOKIE_POLICY = '[Origins API] Country Code (for Cookie Policy) Loaded',
}

export class RequestAirports implements Action {
  readonly type = OriginsActions.REQUEST_AIRPORTS;
}

export class LoadAirports implements Action {
  readonly type = OriginsActions.LOAD_AIRPORTS;
  constructor(public payload: AirportListAndMap) {}
}

export class RequestCountries implements Action {
  readonly type = OriginsActions.REQUEST_COUNTRIES;
}

export class LoadCountries implements Action {
  readonly type = OriginsActions.LOAD_COUNTRIES;
  constructor(public payload: CountryWithAirportCodes[]) {}
}

export class RequestOriginAirports implements Action {
  readonly type = OriginsActions.REQUEST_ORIGIN_AIRPORTS;
  constructor(public payload: { destinationCode: string; id: string }) {}
}

export class LoadOriginAirports implements Action {
  readonly type = OriginsActions.LOAD_ORIGIN_AIRPORTS;
  constructor(
    public payload: { countryWithAirports: CountryWithAirports[]; id: string },
  ) {}
}

export class RequestDestinationAirports implements Action {
  readonly type = OriginsActions.REQUEST_DESTINATION_AIRPORTS;
  constructor(public payload: { originCode: string; id: string }) {}
}

export class LoadDestinationAirports implements Action {
  readonly type = OriginsActions.LOAD_DESTINATION_AIRPORTS;
  constructor(
    public payload: { countryWithAirports: CountryWithAirports[]; id: string },
  ) {}
}

export class RequestRegions implements Action {
  readonly type = OriginsActions.REQUEST_REGIONS;
}

export class LoadRegions implements Action {
  readonly type = OriginsActions.LOAD_REGIONS;
  constructor(public payload: TypedDictionary<Region>) {}
}

export class RequestNearbyAirports implements Action {
  readonly type = OriginsActions.REQUEST_NEARBY_AIRPORTS;
}

export class LoadNearbyAirports implements Action {
  readonly type = OriginsActions.LOAD_NEARBY_AIRPORTS;
  constructor(public payload: string[]) {}
}

export type OriginsActionsUnion =
  | RequestAirports
  | LoadAirports
  | RequestCountries
  | LoadCountries
  | RequestOriginAirports
  | LoadOriginAirports
  | RequestDestinationAirports
  | LoadDestinationAirports
  | RequestRegions
  | LoadRegions
  | RequestNearbyAirports
  | LoadNearbyAirports;
