import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { apiReducer } from './api/api.reducer';
import { authReducer } from './auth/auth.reducer';
import { bookerReducer } from './booker/booker.reducer';
import { cartReducer } from './cart/cart.reducer';
import { dealsReducer } from './deals/deals.reducer';
import { fareSalesReducer } from './fare-sale/fare-sale.reducer';
import { flightTrackerReducer } from './flight-tracker/flight-tracker.reducer';
import { forgotPwdReducer } from './forgot-password/forgot-password.reducer';
import { globalReducer } from './global/global.reducers';
import { itineraryReducer } from './itinerary/itinerary.reducer';
import { localStorageSyncReducer } from './local-storage.reducer';
import { mfaReducer } from './mfa/mfa.reducer';
import { originsReducer } from './origins/origins.reducer';
import { preferenceCenterReducer } from './preference-center/preference-center.reducer';
import { profileReducer } from './profile/profile.reducer';
import { requestAReceiptReducer } from './request-a-receipt/request-a-receipt.reducer';
import { scheduleExtensionReducer } from './schedule-extension/schedule-extension.reducer';
import { AppState } from './shared/types/app-state.type';

export const reducers: ActionReducerMap<AppState> = {
  api: apiReducer,
  auth: authReducer,
  booker: bookerReducer,
  cart: cartReducer,
  deals: dealsReducer,
  fareSales: fareSalesReducer,
  flightTracker: flightTrackerReducer,
  forgotPassword: forgotPwdReducer,
  global: globalReducer,
  itinerary: itineraryReducer,
  mfa: mfaReducer,
  origins: originsReducer,
  preferenceCenter: preferenceCenterReducer,
  requestReceipt: requestAReceiptReducer,
  router: routerReducer,
  scheduleExtension: scheduleExtensionReducer,
  trueblue: profileReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];

export function getMetaReducers() {
  return metaReducers;
}
