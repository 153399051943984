import {
  ForgotPasswordActions,
  ForgotPasswordActionsUnion,
} from './forgot-password.actions';
import { ForgotPasswordState } from './types';

export const initialForgotPasswordState: ForgotPasswordState = {
  emailEntry: {
    error: null,
    isLoading: false,
  },
  enterNewPassword: {
    error: null,
    isLoading: false,
  },
  mostRecentTraceId: null,
  emailFromSignInPage: null,
};

function updateTraceIdState(
  state: ForgotPasswordState,
  action: ForgotPasswordActionsUnion,
) {
  switch (action.type) {
    case ForgotPasswordActions.PASSWORD_RESET_LINK_SUCCESS:
    case ForgotPasswordActions.PASSWORD_RESET_LINK_FAILURE:
    case ForgotPasswordActions.RESET_PASSWORD_FAILED:
    case ForgotPasswordActions.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        mostRecentTraceId: action.traceId || null,
      };

    // Clear trace id if we navigate away from password link/reset pages.
    case ForgotPasswordActions.FORGOT_PASSWORD_PAGE_DESTROYED: {
      if (!state.mostRecentTraceId) {
        return state;
      }

      return {
        ...state,
        mostRecentTraceId: initialForgotPasswordState.mostRecentTraceId,
      };
    }

    default:
      return state;
  }
}

function mainReducer(
  state: ForgotPasswordState,
  action: ForgotPasswordActionsUnion,
) {
  switch (action.type) {
    case ForgotPasswordActions.PASSWORD_RESET_LINK_REQUEST:
      return {
        ...state,
        emailEntry: {
          isLoading: true,
        },
      };
    case ForgotPasswordActions.PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
        emailEntry: {
          error: null,
          isLoading: false,
        },

        enterNewPassword: {
          error: null,
          isLoading: false,
        },
      };
    case ForgotPasswordActions.PASSWORD_RESET_LINK_FAILURE:
      return {
        ...state,
        emailEntry: {
          isError: true,
          error: action.payload,
          isLoading: false,
        },
      };
    case ForgotPasswordActions.RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        enterNewPassword: {
          isLoading: true,
        },
      };
    case ForgotPasswordActions.RESET_PASSWORD_FAILED:
      return {
        ...state,
        enterNewPassword: {
          error: action.payload,
          isLoading: false,
        },
      };
    case ForgotPasswordActions.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        enterNewPassword: {
          error: null, // must be NULL in success
          isLoading: false,
        },
      };
    case ForgotPasswordActions.SET_EMAIL_FROM_SIGN_IN_PAGE:
      return {
        ...state,
        emailFromSignInPage: action.payload,
      };
    default:
      return state;
  }
}

export function forgotPwdReducer(
  state = initialForgotPasswordState,
  action: ForgotPasswordActionsUnion,
) {
  const statePostTraceIdUpdate = updateTraceIdState(state, action);
  return mainReducer(statePostTraceIdUpdate, action);
}
