import { Component, Input } from '@angular/core';

/**
 * @description Display a formatted dialog title
 * @WhereIsItUsed popup-dialog
 */
@Component({
  selector: 'jb-dialog-temp-title',
  templateUrl: './dialog-title.component.html',
})
export class DialogTempTitleComponent {
  @Input() hideTopCloseButton = false;
}
