<ng-container *ngIf="bodyTextImage?.length">
  <!--equal width, picture on right-->
  <div
    class="mv4 center jb-grid gr-unset two-up"
    [ngClass]="{ 'grid-offset-l': !insideAccordion }"
    *ngIf="bodyTextImage[0]?.type == 't1'"
  >
    <div class="dib mr4-l order-1 order-0-m order-0-l">
      <ng-container
        *ngTemplateOutlet="
          LinksAndBtns;
          context: { bodyTextImage: this.bodyTextImage }
        "
      ></ng-container>
    </div>
    <div
      class="dib tc mv4 mv0-m mv0-l image-container"
      [style.--aspect-ratio]="
        (hasImageHeightWidthAttr &&
        +(bodyTextImage[0]?.image)[0]?.height > 0 &&
        +(bodyTextImage[0]?.image)[0]?.width > 0
          ? ((bodyTextImage[0]?.image)[0]?.height /
              (bodyTextImage[0]?.image)[0]?.width) *
            100
          : 56.25) + '%'
      "
    >
      <jb-image
        class="h-auto"
        *ngIf="bodyTextImage[0]?.image"
        [src]="(bodyTextImage[0]?.image)[0]?.src || ''"
        [alt]="(bodyTextImage[0]?.image)[0]?.alt"
        [height]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.height > 0
            ? (bodyTextImage[0]?.image)[0]?.height
            : null
        "
        [width]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.width > 0
            ? (bodyTextImage[0]?.image)[0]?.width
            : '100%'
        "
        [placeholder]="
          (bodyTextImage[0]?.image)[0]?.src
            | dynamicImageWidthPipe: 'placeholder':placeholderParams
        "
        [isLazy]="enableLazyLoadImage"
      >
      </jb-image>
    </div>
  </div>
  <!--equal width, picture on left-->
  <div
    class="mv4 center gr-unset jb-grid two-up"
    [ngClass]="{ 'grid-offset-l': !insideAccordion }"
    *ngIf="bodyTextImage[0]?.type == 't2'"
  >
    <div
      class="dib mr4-l tc image-container"
      [style.--aspect-ratio]="
        (hasImageHeightWidthAttr &&
        +(bodyTextImage[0]?.image)[0]?.height > 0 &&
        +(bodyTextImage[0]?.image)[0]?.width > 0
          ? ((bodyTextImage[0]?.image)[0]?.height /
              (bodyTextImage[0]?.image)[0]?.width) *
            100
          : 56.25) + '%'
      "
    >
      <jb-image
        class="h-auto"
        *ngIf="bodyTextImage[0]?.image"
        [src]="(bodyTextImage[0]?.image)[0]?.src || ''"
        [alt]="(bodyTextImage[0]?.image)[0]?.alt"
        [height]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.height > 0
            ? (bodyTextImage[0]?.image)[0]?.height
            : null
        "
        [width]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.width > 0
            ? (bodyTextImage[0]?.image)[0]?.width
            : '100%'
        "
        [placeholder]="
          (bodyTextImage[0]?.image)[0]?.src
            | dynamicImageWidthPipe: 'placeholder':placeholderParams
        "
        [isLazy]="enableLazyLoadImage"
      >
      </jb-image>
    </div>
    <div class="dib mt0-m mt4 mt0-l">
      <ng-container
        *ngTemplateOutlet="
          LinksAndBtns;
          context: { bodyTextImage: this.bodyTextImage }
        "
      ></ng-container>
    </div>
  </div>
  <!--offset width, image on right-->
  <div
    class="mv4 jb-grid gr-unset center"
    *ngIf="bodyTextImage[0]?.type == 't3'"
  >
    <div
      class="dib mr4-l order-1 order-0-m order-0-l"
      [ngClass]="!insideAccordion ? 'col-8-m-left-temp' : 'col-8-m col-8-l'"
    >
      <ng-container
        *ngTemplateOutlet="
          LinksAndBtns;
          context: { bodyTextImage: this.bodyTextImage }
        "
      ></ng-container>
    </div>
    <div
      class="dib tc image-container"
      [ngClass]="!insideAccordion ? 'col-4-m-right-temp' : 'col-4-m col-4-l'"
      [style.--aspect-ratio]="
        (hasImageHeightWidthAttr &&
        +(bodyTextImage[0]?.image)[0]?.height > 0 &&
        +(bodyTextImage[0]?.image)[0]?.width > 0
          ? ((bodyTextImage[0]?.image)[0]?.height /
              (bodyTextImage[0]?.image)[0]?.width) *
            100
          : 56.25) + '%'
      "
    >
      <jb-image
        class="h-auto"
        *ngIf="bodyTextImage[0]?.image"
        [src]="(bodyTextImage[0]?.image)[0]?.src || ''"
        [alt]="(bodyTextImage[0]?.image)[0]?.alt"
        [height]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.height > 0
            ? (bodyTextImage[0]?.image)[0]?.height
            : null
        "
        [width]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.width > 0
            ? (bodyTextImage[0]?.image)[0]?.width
            : '100%'
        "
        [placeholder]="
          (bodyTextImage[0]?.image)[0]?.src
            | dynamicImageWidthPipe: 'placeholder':placeholderParams
        "
        [isLazy]="enableLazyLoadImage"
      >
      </jb-image>
    </div>
  </div>
  <!--offset width, image on left-->
  <div
    class="mv4 jb-grid gr-unset center"
    *ngIf="bodyTextImage[0]?.type == 't4'"
  >
    <div
      class="dib mr4-l tc image-container"
      [ngClass]="!insideAccordion ? 'col-4-m-left-temp' : 'col-4-m col-4-l'"
      [style.--aspect-ratio]="
        (hasImageHeightWidthAttr &&
        +(bodyTextImage[0]?.image)[0]?.height > 0 &&
        +(bodyTextImage[0]?.image)[0]?.width > 0
          ? ((bodyTextImage[0]?.image)[0]?.height /
              (bodyTextImage[0]?.image)[0]?.width) *
            100
          : 56.25) + '%'
      "
    >
      <jb-image
        class="h-auto"
        *ngIf="bodyTextImage[0]?.image"
        [src]="(bodyTextImage[0]?.image)[0]?.src || ''"
        [alt]="(bodyTextImage[0]?.image)[0]?.alt"
        [height]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.height > 0
            ? (bodyTextImage[0]?.image)[0]?.height
            : null
        "
        [width]="
          hasImageHeightWidthAttr && +(bodyTextImage[0]?.image)[0]?.width > 0
            ? (bodyTextImage[0]?.image)[0]?.width
            : '100%'
        "
        [placeholder]="
          (bodyTextImage[0]?.image)[0]?.src
            | dynamicImageWidthPipe: 'placeholder':placeholderParams
        "
        [isLazy]="enableLazyLoadImage"
      >
      </jb-image>
    </div>
    <div
      class="dib mv4 mv0-m mv0-l"
      [ngClass]="!insideAccordion ? 'col-8-m-right-temp' : 'col-8-m col-8-l'"
    >
      <ng-container
        *ngTemplateOutlet="
          LinksAndBtns;
          context: { bodyTextImage: this.bodyTextImage }
        "
      ></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #LinksAndBtns let-bodyTextImage="bodyTextImage">
  <jb-inner-html
    jbSmartLink
    jbSitemapNestedLinker
    [innerHTML]="bodyTextImage[0]?.content"
    class="db pb2 lh-copy"
    [theme]="theme"
  ></jb-inner-html>
  <ng-container
    *ngIf="!!bodyTextImage[0]?.link && bodyTextImage[0]?.link?.length"
  >
    <div *ngFor="let linkProperty of bodyTextImage[0].link">
      <div class="pv2 lh-copy">
        <a
          class="f6"
          [jbSitemapLinker]="
          linkProperty?.href
              ? linkProperty?.href
              : linkProperty?.showChat === 'true' ||
              linkProperty?.isCookieConsent === 'true'
              ? 'ASAPP'
              : null
          "
          [dotLinkIcon]="linkProperty?.newWindow"
          (click)="
            onClick($event, linkProperty?.showChat, linkProperty?.cookieConsent)
          "
          jbActionLink
          >{{ linkProperty?.title }}</a
        >
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="!!bodyTextImage[0]?.button && bodyTextImage[0]?.button?.length"
  >
    <div *ngFor="let button of bodyTextImage[0]?.button">
      <div class="pt2 pb3">
        <a
          jbButton
          [jbSitemapLinker]="button?.href"
          [target]="button?.newWindow"
          [theme]="theme === themeEnum.dark ? 'ctaSecondary' : 'ctaPrimary'"
          class="f6 ph4"
        >
          {{ button?.title }}
        </a>
      </div>
    </div>
  </ng-container>
</ng-template>
