import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
  addDays,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isValid,
  parse,
  startOfDay,
} from 'date-fns';

/**
 * Return error if control value is empty or not a valid date from the given format (eg: 'MM/dd/yyyy')
 */
export function getDateStringValidator(dateFormat: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const isValidDt = isValid(parse(control.value, dateFormat, new Date()));
    // setting default as error state
    let dateError = {
      date: false,
    };

    if (control?.value?.length === 10 && isValidDt) {
      // if date string is 10 characters long and is valid then there are no errors
      dateError = null;
    }

    return dateError;
  };
}

/**
 * Return error if control value is empty or not a valid date
 */
export function isValidDate(message: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value: string = control.value;
    if (!value || (!!value && !isValid(new Date(value)))) {
      return {
        dateInvalid: message,
      };
    }
    return null;
  };
}

/**
 * Return error if control value is before minDate
 * @param { Date } minDate Minimum date
 * @param { string } message Error message
 * @param { Date } loadDay Start of a day when component was loaded. MinDate shifts with days elapsed.
 */
export function isBeforeMinDate(
  minDate: Date,
  message: string,
  loadDay = startOfDay(new Date()),
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const daysElapsed: number = differenceInDays(
      startOfDay(new Date()),
      loadDay,
    );
    const value: Date = control.value;
    const shiftedMinDate = addDays(minDate, daysElapsed);
    if (value && isBefore(value, shiftedMinDate)) {
      return {
        minDateError: `${message} ${format(
          addDays(shiftedMinDate, -1),
          'MMM dd, yyyy',
        )}`,
      };
    }
    return null;
  };
}

/**
 * Return error if control value is after maxdate
 */
export function isAfterMaxDate(maxDate: Date, message: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value: string = control.value;
    if (value && isAfter(new Date(value), maxDate)) {
      return {
        maxDateError: `${message} ${format(
          addDays(maxDate, 1),
          'MMM dd, yyyy',
        )}`,
      };
    }
    return null;
  };
}

/**
 * Return error if control value is before depart date
 */
export function isReturnBeforeDepart(departDate: Date, message): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value: string = control.value;
    if (value && isBefore(new Date(value), departDate)) {
      return {
        returnBeforeDepartDateError: `${message} ${format(
          departDate,
          'MMM dd, yyyy',
        )}`,
      };
    }
    return null;
  };
}
