<div class="w-100 bb b--light-gray page-margin-m page-margin-l pt7 pb9 ph3">
  <div class="flex justify-center mt6 mb4">
    <dot-text-block class="w-20"></dot-text-block>
  </div>
  <div class="jb-max-width center">
    <div class="jb-grid one-up grid-offset-l">
      <div>
        <dot-base-booker-skeleton></dot-base-booker-skeleton>
        <div class="dn-ns db tc pt4">
          <button
            class="dib"
            jbButton
            theme="ctaPrimary"
            [isRounded]="true"
            [isPaired]="false"
            type="button"
          >
            &nbsp;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="min-h-10"></div>
<div class="min-h-10"></div>
