import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IconCacheService {
  private _cache: {
    [key: string]: BehaviorSubject<HttpResponse<unknown>>;
  } = {};

  constructor(private httpClient: HttpClient) {}

  cacheAndPreloadIcons(urls: string[]) {
    urls.forEach(url => this.cacheAndPreloadIcon(url));
  }

  cacheAndPreloadIcon(url: string) {
    if (!url || url in this.cache) {
      return;
    }

    this.cache[url] = null;
    this.httpClient.get(url, { responseType: 'text' }).subscribe();
  }

  get cache() {
    return this._cache;
  }

  setCache(url: string, value: BehaviorSubject<HttpResponse<unknown>>) {
    this._cache[url] = value;
  }
}
