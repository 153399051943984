import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { AppState } from '../shared/types/app-state.type';
import { flightDataIsNotEmptyAndIsNotMalformed } from './flight.util';
import { Flight, ItineraryState } from './types';

export const itinerary: MemoizedSelector<AppState, ItineraryState> =
  createFeatureSelector('itinerary');

export const upcomingFlightError: MemoizedSelector<AppState, object> =
  createSelector(itinerary, state => state.error);

export const flight: MemoizedSelector<AppState, Flight[]> = createSelector(
  itinerary,
  state => {
    return flightDataIsNotEmptyAndIsNotMalformed(state.flight)
      ? state.flight
      : [];
  },
);
