export const deIndexPages = [
  'mx/maintenance',
  'mx/book-maintenance',
  'mx/book-error',
  'mx/trueblue-error',
  'mx/trueblue-maintenance',
  'mx/vacations',
  'mx/check-in-error',
  'mx/check-in-maintenance',
  'mx/404',
];
