import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@core/injection-tokens';
import { fromEvent as observableFromEvent } from 'rxjs';

import { CookieStorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsClickTrackingService {
  private isMobile: boolean;
  constructor(
    @Inject(WINDOW) private window,
    @Inject(LOCAL_STORAGE) private localStorage,
    private cookie: CookieStorageService,
  ) {}

  initialize() {
    if (
      this.window.location.pathname.indexOf('/destinations') === 0 ||
      this.window.location.pathname === '/'
    ) {
      this.isMobile = this.isMobileDevice();
      this.clickTracking();
    }
  }

  clickTracking() {
    const keyName = 'clickTracking';
    if (this.isMobile) {
      observableFromEvent(this.window, 'touchstart').subscribe(event => {
        this.saveToLocalStorage(keyName, this.getClickTrackingVal(event));
        this.saveToCookie(keyName, this.getClickTrackingVal(event));
      });
    } else if (!this.isMobile) {
      observableFromEvent(this.window, 'mousedown').subscribe(event => {
        this.saveToLocalStorage(keyName, this.getClickTrackingVal(event));
        this.saveToCookie(keyName, this.getClickTrackingVal(event));
      });
    }
  }

  getClickTrackingVal(event): string {
    let clickTrackingVal = '';
    let clickName: string;
    const which = event.which;
    const path =
      typeof event.path === 'undefined'
        ? this.structureDomPath(event)
        : event.path;

    if (event.target.textContent.trim() !== '') {
      clickName = event.target.textContent.trim();
    } else if (
      event.target.textContent.trim() === '' &&
      typeof event.target.alt !== 'undefined' &&
      event.target.alt.trim() !== ''
    ) {
      clickName = event.target.alt.trim();
    } else if (
      event.target.textContent.trim() === '' &&
      (typeof event.target.alt === 'undefined' ||
        event.target.alt.trim() === '')
    ) {
      clickName = this.findNearestClickName(path);
    }

    if (which === 0 || which === 1) {
      if (
        ((this.isInSpecificLocation(path, 'button') ||
          this.isInSpecificLocation(path, 'a') ||
          this.isInSpecificLocation(path, 'span')) &&
          !this.isInSpecificLocation(path, 'jb-booker')) ||
        clickName === 'Multi-city'
      ) {
        clickTrackingVal = `${this.findClickLocation(path)} | ${clickName}`;
      }
    } else if (which === 3) {
      clickTrackingVal = 'right click';
    }
    return clickTrackingVal;
  }

  private saveToLocalStorage(keyName: string, clickTrackingVal: string): void {
    // additional check to make sure cookies are enabled and localStorage exists
    if (!!this.localStorage) {
      this.localStorage.setItem(keyName, clickTrackingVal);
    }
  }

  private saveToCookie(keyName: string, clickTrackingVal: string): void {
    this.cookie.setTrackingCookie(keyName, clickTrackingVal);
  }

  private findClickLocation(path): string {
    let prefix = 'BODY';
    path.forEach(element => {
      if (element.localName === 'jb-header') {
        prefix = 'HEADER';
      } else if (element.localName === 'jb-footer') {
        prefix = 'FOOTER';
      }
    });
    return prefix;
  }

  private isInSpecificLocation(path, locationTagName: string): boolean {
    let flag = false;
    path.forEach(element => {
      if (element.localName === locationTagName) {
        flag = true;
      }
    });
    return flag;
  }

  private findNearestClickName(path): string {
    let nearestClickName = '';
    path.forEach(element => {
      if (element.localName === 'a' || element.localName === 'button') {
        nearestClickName = element.textContent.trim();
        return nearestClickName;
      }
    });
    return nearestClickName;
  }

  private isMobileDevice(): boolean {
    let isMobile = false;
    const rex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (rex.test(this.window.navigator.userAgent)) {
      isMobile = true;
    }
    return isMobile;
  }

  private structureDomPath(event) {
    const path = [];
    let parentElement = event['target'];
    while (parentElement !== null && typeof parentElement !== 'undefined') {
      path.push(parentElement);
      parentElement = parentElement.parentElement;
    }
    return path;
  }
}
