import { MfaActions, MfaActionsUnion } from './mfa.actions';
import { MfaState } from './types/mfa-state.type';

export const initialMfaState: MfaState = {
  userFactors: undefined,
  isMfaFactorsLoading: true,
  isMfaGroupsLoading: false,
  mfaFactorsResponseCode: 200,
  mfaGroupsResponseCode: 200,
};

export function mfaReducer(
  state = initialMfaState,
  action: MfaActionsUnion,
): MfaState {
  switch (action.type) {
    case MfaActions.FETCH_USER_FACTORS:
      return {
        ...state,
        isMfaFactorsLoading: true,
      };
    case MfaActions.FETCH_USER_FACTORS_DONE:
      return {
        ...state,
        userFactors: action.payload,
        isMfaFactorsLoading: false,
        mfaFactorsResponseCode: 200,
      };
    case MfaActions.FETCH_USER_FACTORS_ERROR:
      return {
        ...state,
        userFactors: {},
        isMfaFactorsLoading: false,
        mfaFactorsResponseCode: action.payload.status,
      };
    case MfaActions.CHANGE_USER_GROUP:
      return {
        ...state,
        isMfaGroupsLoading: true,
        mfaGroupsResponseCode: 200,
      };
    case MfaActions.CHANGE_USER_GROUP_DONE:
      return {
        ...state,
        isMfaGroupsLoading: false,
        mfaGroupsResponseCode: 200,
      };
    case MfaActions.CHANGE_USER_GROUP_ERROR:
      return {
        ...state,
        isMfaGroupsLoading: false,
        mfaGroupsResponseCode: action.payload.status,
      };
    default:
      return state;
  }
}
