import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import { JbLinkModule } from 'jb-component-library';

import { JBInnerHtmlModule } from '../jb-inner-html';
import { TravelAlertsModule } from '../travel-alerts';
import { JBBodyTextComponent } from './jb-body-text.component';

const MODULES = [
  CommonModule,
  TravelAlertsModule,
  JBInnerHtmlModule,
  JbLinkModule,
  LinkIconModule,
];

const DECLARATIONS = [JBBodyTextComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class JBBodyTextModule {}
