import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/storage';

export const API_OVERRIDES_LOCALSTORAGE_KEY = 'dotcomApiOverrides';

export enum ApiOverrideKeys {
  upcomingFlights = 'upcomingFlights',
}

const urls: Record<ApiOverrideKeys, string> = {
  upcomingFlights: '/mytrips/v2/upcoming',
};

type ApiOverrideObject = {
  url: string;
  payload: any;
};

@Injectable({ providedIn: 'root' })
export class ApiOverridesService {
  /** Map of current api overrides */
  overrides: Map<ApiOverrideKeys, ApiOverrideObject>;

  private get overridesObj() {
    return this.mapToObj(this.overrides);
  }

  constructor(private localStorageService: LocalStorageService) {
    // get values from localStorage if they exist
    const storage = this.localStorageService.getItem<
      Partial<Record<ApiOverrideKeys, ApiOverrideObject>>
    >(API_OVERRIDES_LOCALSTORAGE_KEY);
    // convert object to a map
    this.overrides = storage ? new Map(Object.entries(storage)) : new Map();
  }

  /** Set the whole overrides object */
  setOverrides(obj: Partial<Record<ApiOverrideKeys, any>>) {
    this.localStorageService.removeItem(API_OVERRIDES_LOCALSTORAGE_KEY);
    this.overrides.clear();

    if (!obj || Object.keys(obj).length === 0) {
      return;
    }

    for (const key in obj) {
      this.overrides.set(key as ApiOverrideKeys, {
        url: urls[key],
        payload: obj[key],
      });
    }
    this.setLocalStorage();
  }

  /** Set a single override */
  setOverride(key, payload) {
    this.overrides.set(key as ApiOverrideKeys, {
      url: urls[key],
      payload,
    });
    this.setLocalStorage();
  }

  /** Remove a single override */
  removeOverride(key) {
    this.overrides.delete(key);
    this.setLocalStorage();
  }

  /** Set the current value of the overrides object in local storage */
  private setLocalStorage() {
    // set the object in local storage
    this.localStorageService.setItem(
      API_OVERRIDES_LOCALSTORAGE_KEY,
      this.overridesObj,
    );
  }

  /**
   * Convert a ES6 Map to an object.
   * Could alternatively use Object.fromEntries when supported lib includes es2019.
   */
  private mapToObj(map: Map<any, any>) {
    const obj = {};
    for (const prop of map) {
      obj[prop[0]] = prop[1];
    }
    return obj;
  }
}
