import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BestFareDay, WindowService } from '@core/index';
import { BffStore } from '@feature/bff/bff.component-store';
import { ComponentStore } from '@ngrx/component-store';
import { SEARCH_TYPES } from '@shared/booker';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  progressIndicatorInitialState,
  ProgressIndicatorState,
  ProgressIndicatorViewModel,
} from './progress-indicator.component.state';
import { ProgressIndicatorContent, ProgressIndicatorType } from './types';

/**
 * @description Display the progress of selecting a departure and return date on bff
 * @WhereIsItUsed best fare calendar
 */
@Component({
  selector: 'jb-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore],
})
export class ProgressIndicatorComponent {
  @Input() set content(content: ProgressIndicatorContent) {
    this.store.patchState({ content });
  }

  @Input() set maxSeats(maxSeats: number) {
    this.store.patchState({ maxSeats });
  }

  progressIndicatorType = ProgressIndicatorType;

  readonly isDepartBffFocusable$: Observable<boolean> = this.store.select(
    this.store.select(state => state.departBff),
    this.store.select(state => state.returnBff),
    (departBff, returnBff) =>
      this.getIsDepartBffFocusable(departBff, returnBff),
  );

  readonly isReturnBffFocusable$ = this.store.select(
    this.store.select(state => state.isOneWay),
    this.isDepartBffFocusable$,
    (isOneWay, isDepartBffFocusable) =>
      this.getIsReturnBffFocusable(isOneWay, isDepartBffFocusable),
  );

  readonly vm$: Observable<ProgressIndicatorViewModel> = combineLatest([
    this.store.state$,
    this.isDepartBffFocusable$,
    this.isReturnBffFocusable$,
  ]).pipe(
    map(([state, isDepartBffFocusable, isReturnBffFocusable]) => ({
      ...state,
      isDepartBffFocusable,
      isReturnBffFocusable,
    })),
  );

  constructor(
    private windowService: WindowService,
    private bffStore: BffStore,
    private store: ComponentStore<ProgressIndicatorState>,
  ) {
    this.store.setState(progressIndicatorInitialState);

    this.store.patchState(this.bffStore.form$);

    this.store.patchState(
      this.bffStore.isOneWay$.pipe(map(isOneWay => ({ isOneWay }))),
    );

    this.store.patchState(
      this.windowService.windowSizeChanged.pipe(
        map(windowSize => ({ windowSize })),
      ),
    );
  }

  getIsDepartBffFocusable(departBff: BestFareDay, returnBff: BestFareDay) {
    return !!(departBff || returnBff);
  }

  getIsReturnBffFocusable(isOneWay: boolean, isDepartBffFocusable: boolean) {
    return isDepartBffFocusable && !isOneWay;
  }

  // when pencil icon is clicked, progress bar clears out selected dates
  clearSelectedFares() {
    this.bffStore.clearSelectedFares();
  }

  // when return sections is clicked on ow booking, toggles booking to rt
  makeRoundTrip() {
    this.bffStore.updateSearchType(SEARCH_TYPES.ROUND_TRIP);
  }

  // Inner function will either be clearSelectedDates or MakeRoundTrip.
  // Needs to have context re-bound due to nature of TS classes
  handleKeyPress($event: KeyboardEvent, innerFunction) {
    if ($event.keyCode === 13) {
      return innerFunction.call(this);
    }
  }
}
