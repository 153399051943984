import { ActivatedRouteSnapshot } from '@angular/router';
// https://github.com/ngrx/platform/blob/0407c5bc37668c19c009f37813a099b986d08df6/modules/router-store/src/router_selectors.ts
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BOOKER_CONFIG } from '@shared/booker/booker.config';
import { toUpperObject } from '@shared/ui/utils/global-utils/to-upper-object';
import { toUpper } from 'ramda';

import { AppState } from '../shared/types/app-state.type';
import { deIndexPages } from './de-index-pages';

export const router = createFeatureSelector<AppState, RouterReducerState<any>>(
  'router',
);

const selectRouterState = createSelector(
  router,
  routerState => routerState && routerState.state,
);

export const currentRoute = createSelector(selectRouterState, routerState => {
  if (!routerState) {
    return undefined;
  }
  let route = routerState.root;
  while (route.firstChild) {
    route = route.firstChild;
  }
  return route as ActivatedRouteSnapshot;
});

export const selectQueryParams = createSelector(
  currentRoute,
  route => route && route.queryParams,
);

export const selectQueryParamsUpperCase = createSelector(
  currentRoute,
  route => route && toUpperObject(route.queryParams),
);

export const selectValidQueryParamsUpperCase = createSelector(
  selectQueryParamsUpperCase,
  upperQueryParams =>
    upperQueryParams &&
    Object.keys(upperQueryParams)
      .filter(
        key =>
          // check if valid key (i.e: valid query param)
          Object.values(BOOKER_CONFIG.URL_PARAMETERS).includes(key) &&
          // and valid value (i.e: valid value for associated query param)
          BOOKER_CONFIG.URL_PARAMETERS_VALUES_VALIDATORS[key](
            upperQueryParams[key],
          ),
      )
      .reduce((obj, key) => {
        obj[key] = upperQueryParams[key];
        return obj;
      }, {}),
);

export const selectQueryParam = paramKey =>
  createSelector(
    selectQueryParamsUpperCase,
    queryParams => queryParams && queryParams[toUpper(paramKey)],
  );

export const selectRouteParams = createSelector(
  currentRoute,
  route => route && route.params,
);

export const selectRouteData = createSelector(
  currentRoute,
  route => route && route.data,
);

export const selectRouteDataPath = createSelector(
  selectRouteData,
  routeData => routeData && routeData.path,
);

export const selectUrlWithQueryParams = createSelector(
  selectRouterState,
  routerState => routerState && routerState.url,
);

export const selectUrl = createSelector(
  selectUrlWithQueryParams,
  url => url && url.split('?')[0],
);

export const isHomePage = createSelector(selectRouteData, routeData =>
  routeData && routeData.path ? routeData.path === '/' : false,
);

export const isSignInPage = createSelector(selectRouteData, routeData =>
  routeData && routeData.path ? routeData.path === '/signin' : false,
);

export const isLogOutPage = createSelector(selectRouteData, routeData =>
  routeData && routeData.path ? routeData.path === '/logout' : false,
);

export const isBffPage = createSelector(selectUrlWithQueryParams, url =>
  url ? url.split('?')[0] === '/best-fare-finder' : false,
);

export const isMaintenancePage = createSelector(
  selectUrlWithQueryParams,
  url => (url ? deIndexPages.indexOf(url) !== -1 : false),
);

export const isPreferenceCenterPage = createSelector(
  selectUrlWithQueryParams,
  url => (url ? url.split('/')[1] === 'preference-center' : false),
);

export const isForgotPasswordPage = createSelector(
  selectUrlWithQueryParams,
  url => (url ? url.split('/')[1] === 'forgot-password' : false),
);

export const isAddTripEmailPage = createSelector(
  selectUrlWithQueryParams,
  url => (url ? url.startsWith('/manage-trips/add-trip-email') : false),
);
