import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[jbFocusTrap]',
})
export class FocusTrapDirective implements AfterViewInit, OnDestroy {
  private focusableElements: HTMLElement[] = [];
  private first: HTMLElement;
  private last: HTMLElement;
  private mutationObserver: MutationObserver;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.updateFocusableElements();
    this.observeDOMChanges();

    if (this.first) {
      this.first.focus();
    }
  }

  ngOnDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private updateFocusableElements() {
    const focusableSelectors = [
      'a[href]:not([disabled])',
      'button:not([disabled]):not([tabindex="-1"])',
      'textarea:not([disabled]):not([tabindex="-1"])',
      'input:not([type="hidden"]):not([disabled]):not([tabindex="-1"])',
      'select:not([disabled]):not([tabindex="-1"])',
      '[tabindex]:not([tabindex="-1"])',
    ].join(', ');

    const queryResult =
      this.elementRef?.nativeElement?.querySelectorAll(focusableSelectors);
    this.focusableElements = queryResult ? Array.from(queryResult) : [];

    this.first = this.focusableElements[0] || null;
    this.last =
      this.focusableElements[this.focusableElements.length - 1] || null;
  }

  private observeDOMChanges() {
    this.mutationObserver = new MutationObserver(() => {
      this.updateFocusableElements();
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key !== 'Tab' || this.focusableElements.length === 0) {
      return;
    }

    const { first, last } = this;

    if (event.shiftKey && document.activeElement === first) {
      event.preventDefault();
      last.focus();
    } else if (!event.shiftKey && document.activeElement === last) {
      event.preventDefault();
      first.focus();
    }
  }
}
