import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
/** Polyfill object-fit/object-position on <img>: IE9, IE10, IE11, Edge, Safari, ... */
import objectFitImages from 'object-fit-images';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

const hmrload = () => {
  hmrBootstrap(module, bootstrap);
  objectFitImages();
};

if (environment.hmr) {
  if (module['hot']) {
    if (window['s'] == null) {
      document.addEventListener('DOMContentLoaded', hmrload);
    } else {
      hmrload();
    }
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.error(err));
  objectFitImages();
}
