import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, of } from 'rxjs';

import { DOCUMENT } from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class EventsService {
  public static CUSTOM_EVENTS = {
    // Output Events
    JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT:
      'JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT',
    JB_HEADER_LOGOUT_CLICKED_OUTPUT_EVENT:
      'JB_HEADER_LOGOUT_CLICKED_OUTPUT_EVENT',
    JB_HEADER_LINK_CLICKED_OUTPUT_EVENT: 'JB_HEADER_LINK_CLICKED_OUTPUT_EVENT',
    JB_HEADER_AUTH_ERROR_OUTPUT_EVENT: 'JB_HEADER_AUTH_ERROR_OUTPUT_EVENT',

    JB_FOOTER_LINK_CLICKED_OUTPUT_EVENT: 'JB_FOOTER_LINK_CLICKED_OUTPUT_EVENT',
    JB_LANGUAGE_CHANGE_OUTPUT_EVENT: 'JB_LANGUAGE_CHANGE_OUTPUT_EVENT',

    // Input Events
    JB_HEADER_LOGIN_SUCCESS_INPUT_EVENT: 'JB_HEADER_LOGIN_SUCCESS_INPUT_EVENT',
    JB_HEADER_PROFILE_UPDATE_INPUT_EVENT:
      'JB_HEADER_PROFILE_UPDATE_INPUT_EVENT',
    JB_HEADER_LOGOUT_SUCCESS_INPUT_EVENT:
      'JB_HEADER_LOGOUT_SUCCESS_INPUT_EVENT',
  };
  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  dispatchCustomEvent(name: string, detail?: any, delay: number = 0) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return;
    }
    const event = new CustomEvent(name, {
      detail,
      bubbles: true,
      cancelable: true,
    });
    if (delay) {
      setTimeout(() => this.document.dispatchEvent(event), delay);
    } else {
      this.document.dispatchEvent(event);
    }
  }

  subscribeToEvent(name: string) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return of(null);
    }
    return fromEvent(this.document, name);
  }
}
