import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { DateService } from '@core/date/date.service';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';

import { CountdownService } from './countdown.service';
import { CountdownDetails } from './types/countdown.type';

/**
 * @description Display a numerical count down
 * @WhereIsItUsed Header component
 */
@Component({
  selector: 'dot-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnDestroy {
  @HostBinding('style.display')
  get hostDisplayStyle(): string {
    return this.showBanner ? 'block' : 'none';
  }

  @Input('details') set details(value: CountdownDetails) {
    this.setDetails(value);
  }
  saleDetails: CountdownDetails;

  timer: {
    totalMinutes?: string;
    totalSeconds?: string;
    totalHours?: string;
  } = {
    totalMinutes: '00',
    totalSeconds: '00',
    totalHours: '00',
  };

  started = false;
  showBanner = false;
  showClock = true;
  private theInterval;

  constructor(
    private zone: NgZone,
    private countdownService: CountdownService,
    private dateService: DateService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.theInterval);
  }

  // Seperate function to make it testable instead of setting it in Input()
  setDetails(details: CountdownDetails): void {
    /**
     * Reset banner to the original state. A bug was reported at DOT-11441 when changing the app language.
     * After updating the language. The app is going to re-fetch the header data.
     * Consequently, this new header data might not return the countdown component.
     * For this cases, we need to reset the showBanner state to false. Avoiding the app to crash.
     * */
    this.showBanner = false;
    this.saleDetails = this.countdownService.getCountdown(details);
    if (this.saleDetails) {
      this.showBanner = true;
      this.countDown(this.saleDetails.dateEnd);
      if (!booleanStringCheck(this.saleDetails.showCountdown)) {
        this.showClock = false;
      }
    }
  }

  countDown(endTime: Date): void {
    this.zone.runOutsideAngular(() => {
      // Set the date we're counting down to
      const countDownDate = endTime.getTime();
      clearInterval(this.theInterval);
      this.theInterval = setInterval(() => {
        // Update the count down every 1 second
        // const x = setInterval(() => {
        // Get today's date and time
        const now = this.dateService.getNewDate().getTime();

        // Find the distance between now and the count down date
        const distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is finished, write some text
        if (distance < 0) {
          this.showBanner = false;
          clearInterval(this.theInterval);
          this.timer = {
            totalMinutes: '00',
            totalSeconds: '00',
            totalHours: '00',
          };
        } else {
          this.zone.run(() => {
            this.updateTimer(days, hours, minutes, seconds);
          });
        }

        this.started = true;
        this.cdRef.detectChanges();
      }, 1000);
    });
  }

  updateTimer(days, hours, minutes, seconds): void {
    // Display as double digits
    this.timer.totalHours = (days * 24 + hours).toString().padStart(2, '0');
    this.timer.totalMinutes = minutes.toString().padStart(2, '0');
    this.timer.totalSeconds = seconds.toString().padStart(2, '0');
  }
}
