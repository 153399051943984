import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import {
  FooterResponse,
  LanguageLink,
} from '@core/cms/types/cms-footer.response.type';
import { LanguageService } from '@core/language/language.service';
import { GlobalFacade } from '@store/global/global.facade';
import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
} from 'rxjs/operators';

/**
 * @description Main footer component for jetblue.com
 * @WhereIsItUsed
 * - app.component
 * - footer web component
 */
@Component({
  selector: 'jb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: {
    role: 'contentinfo',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  private _destroy = inject(DestroyRef);

  footerResponse$: Observable<FooterResponse>;
  languageLinks$: Observable<LanguageLink[]>;
  languageControl = new FormControl<LanguageLink>(null);

  @Input() hideLanguageSelect;

  constructor(
    private languageService: LanguageService,
    private globalFacade: GlobalFacade,
  ) {
    this.footerResponse$ = this.globalFacade.footer.pipe(
      filter(Boolean),
      distinctUntilChanged(),
    );

    this.languageLinks$ = this.globalFacade.footerLanguageLinks.pipe(
      filter(Boolean),
      map(languageLinks => {
        // filter out link if it's not supported
        return languageLinks.filter(link =>
          this.languageService.supportedLanguages.includes(link.lang),
        );
      }),
    );

    combineLatest([
      // fires when a language is selected
      this.languageService.language$,
      this.languageLinks$.pipe(filter(links => !!links.length)),
    ])
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(([lang, links]) => {
        this.languageControl.setValue(links.find(l => l.lang === lang));
      });

    // Worth considering creating language selector component
    this.languageControl.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroy),
        distinctUntilKeyChanged('lang'),
        filter(Boolean),
      )
      .subscribe(value => {
        this.languageService.switchLanguage(value.lang);
      });
  }
}
