import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@core/http';
import { TypedDictionary } from '@core/utils';
import { AccessToken } from '@okta/okta-auth-js';
import { networkError } from '@store/shared/action.utils';
import { NetworkError } from '@store/shared/types/network-error.type';
import { catchError, map, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { MfaFacade } from './mfa.facade';
import { factorCatalogTransform } from './mfa.utils';
import { MfaCatalogResponseError } from './types/mfa-catalog-error-response.type';
import { MfaCatalogResponse } from './types/mfa-catalog-response.type';
import { MfaGroupResponseError } from './types/mfa-group-error-response.type';
import { MfaGroupRequest } from './types/mfa-group-request.type';
import { MfaGroupResponse } from './types/mfa-group-response.type';
import { UserFactor } from './types/mfa-state.type';

@Injectable({ providedIn: 'root' })
export class MfaApi {
  constructor(
    private _httpService: HttpService,
    private _mfaFacade: MfaFacade,
  ) {}

  getFactorsCatalog(
    token: AccessToken,
  ): Observable<TypedDictionary<UserFactor> | NetworkError> {
    const headers = new HttpHeaders()
      .set('X-Dotcom-Request-Id', uuidv4())
      .set('Authorization', `Bearer ${token.accessToken}`);
    return this._httpService
      .get<MfaCatalogResponse[]>(`/mfa/catalog`, {
        headers,
      })
      .pipe(
        map(response => factorCatalogTransform(response)),
        catchError((error: MfaCatalogResponseError) => {
          this._mfaFacade.fetchUserFactorsError({
            ...error,
            status: error.status ? error.status : 400,
          });
          return of(networkError());
        }),
      );
  }

  changeUserGroup(
    token: AccessToken,
    payload: MfaGroupRequest,
  ): Observable<MfaGroupResponse | NetworkError> {
    const body = {
      ...payload,
    };
    const headers = new HttpHeaders()
      .set('X-Dotcom-Request-Id', uuidv4())
      .set('Authorization', `Bearer ${token.accessToken}`);
    return this._httpService
      .post<MfaGroupResponse>(`/mfa/groups`, body, {
        headers,
      })
      .pipe(
        map(response => response),
        catchError((error: MfaGroupResponseError) => {
          this._mfaFacade.changeUserGroupError({
            ...error,
            status: error.status ? error.status : 400,
          });
          return of(networkError());
        }),
      );
  }
}
