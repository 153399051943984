import { Pipe, PipeTransform } from '@angular/core';
import { Airport } from '@store/origins/types';

@Pipe({
  name: 'dotAirportShortName',
  pure: true,
})
export class DotAirportShortNamePipe implements PipeTransform {
  transform(airport: Airport): string {
    // Check if there is an airport.pm or if there is a comma in the airport name
    if (airport.pm || /\,/.test(airport.name)) {
      // Remove all the text after coma with a empty string. However, keep the airport code.
      return airport.name.replace(/\,[^]*\(/, ' (');
    }

    // Return Default Name.
    return airport.name;
  }
}
