import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  JbBackgroundImageModule,
  JbBreadcrumbsModule,
  JbButtonModule,
  JbCalloutModule,
  JbCheckboxModule,
  JbDialogModule,
  JbErrorModule,
  JbExpansionPanelModule,
  JbHeroModule,
  JbIconModule,
  JbImageModule,
  JbInputModule,
  JbLinkModule,
  JbNavigationBlockModule,
  JbRadioModule,
  JbSelectModule,
} from 'jb-component-library';

import { BLUR_ON_SCROLL_DECLARATIONS } from './blur-on-scoll';
import { FOCUS_INVALID_DECLARATIONS } from './focus-invalid';
import { FOCUS_ON_DECLARATIONS } from './focus-on';
import { FOCUS_TRAP_DECLARATIONS } from './focus-trap';
import { LinksModule } from './links';
import { PipesModule } from './pipes';
import { SMART_LINK_DECLARATIONS } from './smart-link';
import { WRAP_DETECTOR_DECLARATIONS } from './wrap-detect';

export const JB_COMPONENT_LIBRARY_MODULES = [
  JbLinkModule,
  JbButtonModule,
  JbIconModule,
  JbDialogModule,
  JbExpansionPanelModule,
  JbErrorModule,
  JbRadioModule,
  JbSelectModule,
  JbHeroModule,
  JbImageModule,
  JbBackgroundImageModule,
  JbNavigationBlockModule,
  JbInputModule,
  JbCheckboxModule,
  JbBreadcrumbsModule,
  JbCalloutModule,
];

const MODULES = [
  CommonModule,
  RouterModule,
  PipesModule,
  LinksModule,
  ...JB_COMPONENT_LIBRARY_MODULES,
];

const DECLARATIONS = [
  FOCUS_ON_DECLARATIONS,
  FOCUS_TRAP_DECLARATIONS,
  FOCUS_INVALID_DECLARATIONS,
  SMART_LINK_DECLARATIONS,
  BLUR_ON_SCROLL_DECLARATIONS,
  WRAP_DETECTOR_DECLARATIONS,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class UIModule {}
