import { Inject, Injectable } from '@angular/core';
import { AbtestExperienceGeneratorService } from '@core/ab-testing/abtest-experience-generator.service';
import { WINDOW } from '@core/index';
import {
  registerLogFullStory,
  waitForFullStory,
} from '@shared/ui/utils/global-utils/full-story';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { ABTestFullServedExperience } from './types';

@Injectable({ providedIn: 'root' })
export class JBABTestService {
  public omnitureABTestExperienceValue: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  fullstoryExperienceCookieName = 'dotcom-FS-experience-tracker';

  constructor(
    @Inject(WINDOW) private window,
    private abtestExperienceGeneratorService: AbtestExperienceGeneratorService,
  ) {}

  getOmnitureABTestExperienceValue(callback?: any): void {
    const omnitureTrackingValue = [];
    this.getExperience()
      .pipe(
        filter(experiences => experiences != null),
        take(1),
      )
      .subscribe(experiences => {
        experiences.forEach(experience => {
          omnitureTrackingValue.push(
            `${experience.name} ${experience.experience.name}`,
          );
        });
        this.omnitureABTestExperienceValue.next(
          omnitureTrackingValue.join(' | '),
        );
        if (callback) {
          callback();
        }
      });
  }

  trackABTestInFullStory(): void {
    waitForFullStory(this.window)
      .then(() => {
        this.getExperience()
          .pipe(
            filter(experiences => experiences != null),
            take(1),
          )
          .subscribe(experiences => {
            experiences.forEach(experience => {
              registerLogFullStory(
                'Track Campaign',
                {
                  campaign_str: experience.name,
                  experience_str: experience.experience.name,
                },
                this.window,
              );
            });
          });
      })
      .catch(() => {
        console.warn('FullStory failed to load');
      });
  }

  private getExperience(): Observable<ABTestFullServedExperience[]> {
    return this.abtestExperienceGeneratorService.retrieveAbTestConfig();
  }
}
