import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'jb-form-skeleton',
  templateUrl: './form-skeleton.component.html',
  styleUrls: ['./form-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSkeletonComponent {
  @Input('classStyles') public classStyles = 'w-100';
  @Input('inputHeight') public inputHeight = 57; // unit: percent
  @Input('boarderColor') public boarderColor = '#d3d3d3';
}
