import { Injectable } from '@angular/core';

import { AppConfigService } from '../app-config';

@Injectable({
  providedIn: 'root',
})
export class InstallService {
  showPrompt = true;
  promptIntercepted = false;
  isStandalone = false;
  deferredPrompt;
  userInstalled = false;
  whereIsShare = 'bottom';

  userAgent = '';
  /**
   * Used to display message based on the device
   * For Safari and IOS Use message below to display propmpt.
   * Add a shortcut to your home screen: Share button at the {{installService.whereIsShare}} of the browser.
   * Scroll left (if needed) to find the Add to Home Screen button.
   */
  isSafari = false;
  isIOS = false;
  isMobile = false;

  // For testing debug display only
  promptSaved = false;
  customButtonClicked = false;
  deferredPromptShown = false;
  deferredPromptRejected = false;

  constructor(private appConfig: AppConfigService) {}

  checkUserAgent() {
    this.userAgent = navigator.userAgent.toLowerCase();
    this.isSafari = /safari/.test(this.userAgent);
    this.isIOS = /ipad|iphone|ipod/.test(this.userAgent);
    this.isMobile = /mobile/.test(this.userAgent);

    if (/ipad/.test(this.userAgent)) {
      this.whereIsShare = 'top';
    }
  }

  trackStandalone() {
    // called once from app.component
    if (this.checkStandalone()) {
      this.isStandalone = true;
    }
  }

  checkStandalone(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  trackInstalled() {
    // called from listener in app.component
    this.userInstalled = true;
  }

  addToHomeScreen() {
    // call on custom button click
    this.customButtonClicked = true;

    if (!this.deferredPrompt) {
      return;
    }

    // Show the prompt
    this.deferredPrompt.prompt();
    this.deferredPromptShown = true;

    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        // no matter the outcome, the prompt cannot be reused ON MOBILE
        // for 3 months or until browser cache is cleared?
      } else {
        this.deferredPromptRejected = true;
      }
    });
  }

  showHide(checkWhat: boolean) {
    if (checkWhat) {
      return 'block';
    } else {
      return 'none';
    }
  }

  browserPromptBtn() {
    if (this.appConfig.overridePWAInstallPrompt) {
      if (this.promptIntercepted && !this.userInstalled) {
        if (this.showPrompt) {
          return 'flex';
        } else {
          return 'none';
        }
      } else {
        return 'none';
      }
    } else {
      return 'none';
    }
  }

  browserPromptBtnSafari() {
    if (this.appConfig.overridePWAInstallPrompt) {
      if (this.isSafari && this.isIOS && !this.isStandalone) {
        if (this.showPrompt) {
          return 'flex';
        } else {
          return 'none';
        }
      } else {
        return 'none';
      }
    } else {
      return 'none';
    }
  }

  cancelPrompt() {
    this.showPrompt = false;
  }

  trueOrFalse(checkWhat: boolean) {
    if (checkWhat) {
      return 'green';
    } else {
      return 'red';
    }
  }
}
