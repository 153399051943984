import { isEmpty, isNil } from 'ramda';

/**
 * Removes empty values from an object or array. Also removes empty nested
 * values of both types.
 */
export function removeEmptyValues(value: any): any {
  if (Array.isArray(value)) {
    return value.map(removeEmptyValues).filter(x => !isNil(x));
  }

  if (typeof value === 'object' && !isNil(value)) {
    return Object.keys(value).reduce((acc, key: string) => {
      const objValue = value[key];
      if (!isNil(objValue)) {
        acc[key] = removeEmptyValues(objValue);
      }
      return acc;
    }, {});
  }

  return isEmpty(value) ? undefined : value;
}
