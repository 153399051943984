import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dot-text-block',
  template: ``,
})
export class TextBlockComponent {
  /** Background color */
  @Input('bgColor') bgColor = '#fff';
  /** Gradient color on loading animation */
  @Input('gradientColor') gradientColor = '#e8e8e9'; // light gray
  /** Border radius of input in pixels */
  @Input('radius') radius = 4;
  /** Disable loading shine animation */
  @Input('disableAnimation') disableAnimation = false;

  /** Required. Width of the text block in percent. Alternatively can be defined with a css class on the host element.
   * @example <dot-text-block width="20"></dot-text-block>
   * <dot-text-block [width]="some_variable"></dot-text-block>
   * <dot-text-block class="w-20"></dot-text-block>
   * <dot-text-block class="w-20 w-100-ns"></dot-text-block> for different viewport sizes
   */
  @HostBinding('style.width.%')
  @Input('width')
  width;

  /** Required. Height of the text block in pixels. Alternatively can be defined with a css class on the host element.
   * @example <dot-text-block height="32"></dot-text-block>
   * <dot-text-block [height]="some_variable"></dot-text-block>
   * <dot-text-block class="h1"></dot-text-block>
   * <dot-text-block class="h1 h3-ns"></dot-text-block> for different viewport sizes
   */
  @HostBinding('style.height.px')
  @Input('height')
  height;

  @HostBinding('style') get hostStyling(): string {
    const animation = !this.disableAnimation
      ? `
      background-image: linear-gradient(
        135deg,
        transparent 14%,
        ${this.gradientColor} 22%,
        ${this.gradientColor} 36%,
        transparent 45%
      );
      background-size: 200%;
      background-position: 100%;
      animation: shine-lines 3s ease-out 0.5s infinite;
    `
      : '';

    return `
      box-sizing: border-box;
      display: block;
      border-radius: ${this.radius}px;
      background-color: ${this.bgColor};
      ${animation}
    `;
  }
}
