import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIModule } from '../ui';
import { JbChapterSelectorComponent } from './jb-chapter-selector.component';

const MODULES = [CommonModule, UIModule];

const DECLARATIONS = [JbChapterSelectorComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class JbChapterSelectorModule {}
