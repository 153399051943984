import { Inject, Injectable } from '@angular/core';

import { DOCUMENT } from '../injection-tokens';

// TODO: move this logic to document.service
/**
 * Utility service that allows you to disable/re-enable
 * scrolling of the viewport.
 */

@Injectable({ providedIn: 'root' })
export class DisableBodyScrollService {
  html;

  constructor(@Inject(DOCUMENT) private document) {
    this.html = this.document.documentElement;
  }

  // Used by header mobile V1
  /**
   * Disable scrolling
   */
  activate(): void {
    this.html.style.overflowY = 'hidden';
  }

  /**
   * Re-enable scrolling
   */
  deactivate(): void {
    this.html.style.overflowY = '';
  }

  // Used by header mobile V2
  disableScroll() {
    this.document.body.classList.add('disable-scroll');
    this.html.classList.add('disable-scroll');
  }

  enableScroll() {
    this.document.body.classList.remove('disable-scroll');
    this.html.classList.remove('disable-scroll');
  }
}
