import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { compareAsc, isValid, startOfToday } from 'date-fns';

export const twoDigitNumeric = /^[0-9]{2}$/;
export const fourDigitNumeric = /^[0-9]{4}$/;

export function dayValidator(control: AbstractControl): { [key: string]: any } {
  const MAX_DAY = 31;
  const MIN_DAY = 1;
  if (
    (control.value && !control.value.match(twoDigitNumeric)) ||
    parseInt(control.value, 10) > MAX_DAY ||
    parseInt(control.value, 10) < MIN_DAY
  ) {
    return { dob: { invalid: true } };
  }

  return null;
}

export function monthValidator(control: AbstractControl): {
  [key: string]: any;
} {
  const MAX_MONTH = 12;
  const MIN_MONTH = 1;
  if (
    (control.value && !control.value.match(twoDigitNumeric)) ||
    parseInt(control.value, 10) > MAX_MONTH ||
    parseInt(control.value, 10) < MIN_MONTH
  ) {
    return { dob: { invalid: true } };
  }

  return null;
}

export function yearValidator(control: AbstractControl): {
  [key: string]: any;
} {
  const MAX_YEAR = new Date().getFullYear();
  if (
    (control.value && !control.value.match(fourDigitNumeric)) ||
    parseInt(control.value, 10) > MAX_YEAR
  ) {
    return { dob: { invalid: true } };
  }

  return null;
}

const today = startOfToday(); // declare outside so its not re-declared every time
export const dobValidator: ValidatorFn = (control: AbstractControl) => {
  // if control is a formgroup and has the controls year/month/day, dont show errors for this control unless all the other ones have passed
  if (
    control instanceof FormGroup &&
    (control.controls.year?.errors ||
      control.controls.month?.errors ||
      control.controls.day?.errors)
  ) {
    return null;
  }
  // only do validate after all date values have been entered
  if (!control.value.year || !control.value.month || !control.value.day) {
    return null;
  }

  const dob = new Date(
    control.value.year,
    control.value.month - 1, // month index starts at 0, subtract 1
    control.value.day,
  );

  if (
    !isValid(dob) ||
    compareAsc(dob, today) !== -1 || // compare: 1 === dob after today, 0 === dob is today, -1 === dob is before today
    // check if its a legal date by comparing the parent date with the values from the form
    // if it was something like Feb 31, the parsed date will put it in March cause its not real and the compared months wont match
    !(
      dob.getFullYear() === +control.value.year &&
      dob.getMonth() === control.value.month - 1 && // subtract 1 cause months are 0 based
      dob.getDate() === +control.value.day
    )
  ) {
    return { dob: { invalid: true } };
  }

  return null;
};
