<jb-dialog>
  <jb-dialog-header>Offer details</jb-dialog-header>
  <jb-dialog-content>
    <p>
      <b>Price/Availability: </b>Price is per person, based on double occupancy,
      and includes hotel rates, hotel taxes, roundtrip airfare, and gov't
      taxes/fees applicable to airfare based on specified departure city. Price
      may vary for other departure cities. Price shown is sample price found
      3/1/18 on jetblue.com/vacations for travel departing Ft. Lauderdale (FLL);
      is available on select dates between 5/7/18 and 5/10/18; and may not
      represent current savings. Package/price subject to availability; may
      change without notice; valid for new bookings only; capacity controlled;
      may not be available on all dates or with all flights; and may be
      restricted to certain hotel room categories.
    </p>
    <p>
      <b>Baggage/Other Charges: </b>As part of a JetBlue Vacations Package,
      first checked bag on JetBlue flights is $25, the second checked bag is $35
      and additional checked bags (over two) are $100. Price does not include
      fees for oversized/overweight/extra baggage or charges for
      products/services sold separately or not expressly included in package
      (such as airport transfers, resort fees, etc.). For
      <a
        [jbSitemapLinker]="serviceFeeLink"
        target="_blank"
        class="no-underline royal-blue underline-hover"
        >JetBlue Optional Services and Fees (incl. baggage fees), click here.</a
      >
    </p>
    <p>
      <b>Changes/Cancellations: </b>Changes/cancellations must be in accordance
      with JetBlue and hotel policies. If change/cancel is made 60 days or more
      prior to departure date: $70 fee per person, plus any applicable hotel or
      other Travel Supplier penalties. If change/cancel is made within 60 days
      of departure date: $135 fee per person, plus any applicable hotel or other
      Travel Supplier penalties. JetBlue requires changes/cancellations to be
      made prior to scheduled departure, otherwise all money for package will be
      forfeited. Hotel change/cancel policies and fees apply, vary by property,
      and may be more restrictive than the JetBlue policy. Call 1-800-JETBLUE
      (538-2583), option 3 for the specific change/cancellation policies and
      fees that apply to a particular package. For changes, there may be an
      increase in package price. Valid cancellations are for JetBlue Vacations
      credit only, valid for 1 year.
    </p>
    <p>
      <b>Important Additional Terms: </b>Proper documentation required for
      boarding. Other restrictions apply.
      <a
        [jbSitemapLinker]="termsConditionLink"
        target="_blank"
        class="no-underline royal-blue underline-hover"
        >See JetBlue Vacations Terms and Conditions.</a
      >
    </p>
  </jb-dialog-content>
</jb-dialog>
