import { AnalyticsDataLayerValues } from './types/analyticsDataLayer.type';

// legacy analytics object required to populate page metrics
export const analyticsData: AnalyticsDataLayerValues = {
  Channel: '',
  Language: '',
  PageName: '',
  ServiceError: '',
  TBStatus: '',
  TBNumber: '',
  TBOffer: '',
  TBPoints: '',
  URL: '',
  ValidationError: '',
  FareType: '',
  Segment1: '',
  TripDesignation: '',
  Adults: '',
  Kids: '',
  LapInfants: '',
  PreviousPageName: '',
  Events: '',
  Evar70: '',
};
