import { Component, Input } from '@angular/core';
// TODO: determine if this can be deleted
/**
 * @description Simple component to display a message/text
 * @WhereIsItUsed Unknown
 */
@Component({
  selector: 'jb-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
  @Input() message = 'An error occured.';
}
