import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pass the value to the regular currency pipe, then strip the zeros from the end of a
 *  currency value when the cents value is only 0.
 * @example $ 560.00 => $ 560
 * @example $ 450.40 => $ 450.40
 */
@Pipe({ name: 'dotCurrency' })
export class DotCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: string | number,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): any {
    const transformed = super.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      locale,
    );
    const matched = transformed.match(/\.0+$/); // test is there are trailing 0's
    return !matched // return null if none found
      ? transformed
      : transformed.slice(0, transformed.length - matched[0].length);
  }
}
