import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { AppState } from '../shared/types/app-state.type';
import { ScheduleExtension, ScheduleExtensionState } from './types';

export const scheduleExtension: MemoizedSelector<
  AppState,
  ScheduleExtensionState
> = createFeatureSelector('scheduleExtension');

export const extensionDate: MemoizedSelector<AppState, ScheduleExtension> =
  createSelector(scheduleExtension, state => state.extensionDate);
