export function isWhitelistedUrl(url: string, document): boolean {
  if (!!url) {
    // extract hostname from url and compare with current domain name
    let hostname =
      url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0];

    hostname = hostname.split(':')[0].split('?')[0];
    const urlDomain = getDomain(hostname);
    const curDomain = getDomain(document.location.hostname);
    return urlDomain === curDomain || urlDomain.toLowerCase() === 'jetblue.com';
  }
  return false;
}

export function getDomain(hostname: string): string {
  return hostname.split('.').length < 2
    ? hostname
    : `${hostname.split('.').slice(-2).join('.')}`;
}
