import { ModuleWithProviders, NgModule } from '@angular/core';

import { WindowService } from './window.service';

@NgModule({})
export class WindowActionsModule {
  static forRoot(): ModuleWithProviders<WindowActionsModule> {
    return {
      ngModule: WindowActionsModule,
      providers: [WindowService],
    };
  }
}
