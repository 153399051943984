import { Pipe, PipeTransform } from '@angular/core';

import { memo } from '../../../ui/decorators/index';

/**
 * Create this custom date pipe since angular date pipe formats date differently
 * on google pixel chrome browser
 * Formatting info can be found at https://date-fns.org/v1.29.0/docs/format
 */
@Pipe({
  name: 'jbIncludesSubstring',
})
export class IncludesSubstringPipe implements PipeTransform {
  @memo()
  transform(status: string, includedWord: string): boolean {
    if (!status || !includedWord) {
      return false;
    } else {
      return status.includes(includedWord);
    }
  }
}
