import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @description Formatted month title for calendar
 * @WhereIsItUsed calendar component
 */
@Component({
  selector: 'jb-calendar-title',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-title.component.html',
  styleUrls: ['./calendar-title.component.scss'],
})
export class CalendarTitleComponent {
  @Input() year: number;
  @Input() month: number;
  @Input() isLoading: boolean;
}
