import { Pipe, PipeTransform } from '@angular/core';
import { getFullMonthName } from '@shared/calendar';

@Pipe({
  name: 'jbMonthName',
})
// to-do: turn into memoized pipe
export class MonthNamePipe implements PipeTransform {
  transform(month: number): string {
    if (month == null) {
      return '';
    }
    return getFullMonthName(month);
  }
}
