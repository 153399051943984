import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JBInnerHtmlModule } from '@shared/jb-inner-html';
import { UIModule } from '@shared/ui';

import { TbBottomSectionComponent } from './tb-bottom-section.component';

const MODULES = [CommonModule, UIModule, JBInnerHtmlModule];

const DECLARATIONS = [TbBottomSectionComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class TbBottomSectionModule {}
