export enum LoadState {
  Idle = 'Idle',
  Submitting = 'Submitting',
  Failure = 'Failure',
  Success = 'Success',
}

export interface RequestReceiptState {
  requestPayload: RequestReceiptPayload;
  loadState: LoadState;
}
export interface RequestReceiptPayload {
  RecordLocator: string;
  DepartureDate: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  EmailAddress: string;
}
