import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import { TrueblueModule } from '@shared/trueblue/trueblue.module';
import {
  JbExpandableButtonModule,
  JbFlyoutModule,
  JbLinkModule,
  JbPopoverModule,
} from 'jb-component-library';

import { UIModule } from '../../ui';
import { TbSignedInModule } from '../tb-signed-in/tb-signed-in.module';
import { TbSignedOutModule } from '../tb-signed-out/tb-signed-out.module';
import { SIDENAV_DECLARATIONS } from './sidenav';
import { SUBNAV_DECLARATIONS } from './subnav';
import { SUPERGLOBALNAV_DECLARATIONS } from './super-global-nav';

const MODULES = [
  CommonModule,
  UIModule,
  JbLinkModule,
  JbPopoverModule,
  JbFlyoutModule,
  JbExpandableButtonModule,
  TbSignedOutModule,
  TbSignedInModule,
  LinkIconModule,
  TrueblueModule,
];

const DECLARATIONS = [
  SIDENAV_DECLARATIONS,
  SUBNAV_DECLARATIONS,
  SUPERGLOBALNAV_DECLARATIONS,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class NavigationModule {}
