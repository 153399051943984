import {
  MOCK_FLIGHT_FARE,
  MOCK_FLIGHT_HOTEL_FARE,
  MOCK_FLIGHT_MINT_FARE,
} from '@models/mocks/fare-sale.mocks';

import {
  FareSaleDialogConfig,
  FlightFare,
  FlightHotelFare,
  FlightMintFare,
} from '../fare-sale.models';

const isFlightFare = (
  fare: FlightFare | FlightHotelFare | FlightMintFare,
): fare is FlightFare => fare.variant === 'flight';

const isFlightHotelFare = (
  fare: FlightFare | FlightHotelFare,
): fare is FlightHotelFare => fare.variant === 'hotels+flight';

const isFlightMintFare = (
  fare: FlightFare | FlightHotelFare | FlightMintFare,
): fare is FlightMintFare => fare.variant === 'mint';

export const getFlightFare = (
  fares: (FlightFare | FlightHotelFare | FlightMintFare)[],
): FlightFare => fares.find(isFlightFare);

export const getFlightHotelFare = (
  fares: (FlightFare | FlightHotelFare | FlightMintFare)[],
): FlightHotelFare => fares.find(isFlightHotelFare);

export const getFlightMintFare = (
  fares: (FlightFare | FlightHotelFare | FlightMintFare)[],
): FlightMintFare => fares.find(isFlightMintFare);

export const INITIAL_NFS_MODAL_CONFIG: FareSaleDialogConfig = {
  flight: {
    selected: false,
    header: 'Flight details',
    fare: { ...MOCK_FLIGHT_FARE },
  },
  flightHotel: {
    selected: false,
    header: 'Flights + Hotel details',
    fare: { ...MOCK_FLIGHT_HOTEL_FARE },
  },
  flightMint: {
    selected: false,
    header: 'Mint details',
    fare: { ...MOCK_FLIGHT_MINT_FARE },
  },
  pricingType: 'dollars',
};
