import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpService, WINDOW } from '@core/index';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import {
  BehaviorSubject,
  combineLatest,
  from as observableFrom,
  of,
} from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  share,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { ScheduleExtensionFacade } from '../schedule-extension/schedule-extension.facade';
import {
  buildAirportMapListWithCountryCode,
  buildCountryListFromAirports,
  OriginsApi,
  updateNeedleWithMacInfoAndSort,
} from './api';
import {
  OriginsActions,
  RequestAirports,
  RequestCountries,
  RequestDestinationAirports,
  RequestOriginAirports,
} from './origins.actions';
import { OriginsFacade } from './origins.facade';

@Injectable()
export class OriginsEffects {
  isIdle$ = new BehaviorSubject(false);

  onInit$ = this.actions$.pipe(ofType(ROOT_EFFECTS_INIT), share());

  getAirports$ = this.actions$.pipe(
    ofType<RequestAirports>(OriginsActions.REQUEST_AIRPORTS),
    switchMap(() => this.originsApi.getAirports()),
    share(),
  );

  getCountries$ = this.actions$.pipe(
    ofType<RequestCountries>(OriginsActions.REQUEST_COUNTRIES),
    switchMap(() => this.originsApi.getCountries()),
    share(),
  );

  getOriginAirports$ = this.actions$.pipe(
    ofType<RequestOriginAirports>(OriginsActions.REQUEST_ORIGIN_AIRPORTS),
    mergeMap(({ payload }) =>
      this.originsApi.getOriginAirportsFromDestination(
        payload.destinationCode,
        payload.id,
      ),
    ),
    share(),
  );

  getDestinationAirports$ = this.actions$.pipe(
    ofType<RequestDestinationAirports>(
      OriginsActions.REQUEST_DESTINATION_AIRPORTS,
    ),
    mergeMap(({ payload }) =>
      this.originsApi.getDestinationAirportsFromOrigin(
        payload.originCode,
        payload.id,
      ),
    ),
    share(),
  );

  getRegions$ = this.actions$.pipe(
    ofType(OriginsActions.REQUEST_REGIONS),
    switchMap(() => this.originsApi.getRegions()),
    share(),
  );

  getNearbyAirports$ = this.actions$.pipe(
    ofType(OriginsActions.REQUEST_NEARBY_AIRPORTS),
    switchMap(() => this.originsApi.getNearbyAirports()),
    share(),
  );

  constructor(
    private actions$: Actions,
    private originsApi: OriginsApi,
    private originsFacade: OriginsFacade,
    private scheduleExtensionFacade: ScheduleExtensionFacade,
    @Inject(WINDOW) private windowRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return;
    }
    windowRef.requestIdleCallback(() => this.isIdle$.next(true));
  }

  getAllOnInit$ = createEffect(() =>
    this.onInit$.pipe(
      init$ => combineLatest([init$, this.isIdle$]),
      filter(([init, isIdle]) => Boolean(init) && isIdle),
      mergeMap(() =>
        observableFrom([
          this.originsFacade.requestNearbyAirports(),
          this.scheduleExtensionFacade.requestExtensionDate(),
          this.originsFacade.requestCountries(),
          this.originsFacade.requestAirports(),
          this.originsFacade.requestRegions(),
        ]),
      ),
    ),
  );

  getAirportsSuccess$ = createEffect(() =>
    combineLatest([
      this.getAirports$.pipe(filter(HttpService.isSuccessful)),
      this.getCountries$.pipe(filter(HttpService.isSuccessful)),
    ]).pipe(
      switchMap(([airportListAndMap, countryWithAirportCodes]) =>
        of(
          this.originsFacade.loadAirports(
            buildAirportMapListWithCountryCode(
              airportListAndMap,
              countryWithAirportCodes,
            ),
          ),
        ),
      ),
    ),
  );

  getCountriesSuccess$ = createEffect(() =>
    this.getCountries$.pipe(
      filter(HttpService.isSuccessful),
      map(countries => this.originsFacade.loadCountries(countries)),
    ),
  );

  getOriginAirportsSuccess$ = createEffect(() =>
    combineLatest([
      this.getOriginAirports$.pipe(filter(HttpService.isSuccessful)),
      this.originsFacade.airportsMap.pipe(filter(Boolean), take(1)),
    ]).pipe(
      map(([{ response, id }, airportsMap]) => ({
        response: updateNeedleWithMacInfoAndSort(response, airportsMap),
        id,
      })),
      withLatestFrom(this.originsFacade.countryByAirportMap),
      map(([{ response, id }, airportCountryMap]) => {
        return {
          list: buildCountryListFromAirports(response, airportCountryMap),
          id,
        };
      }),
      map(result =>
        this.originsFacade.loadOriginAirports(result.list, result.id),
      ),
    ),
  );

  getDestinationAirportsSuccess$ = createEffect(() =>
    this.getDestinationAirports$.pipe(
      filter(HttpService.isSuccessful),
      withLatestFrom(this.originsFacade.airportsMap),
      map(([{ response, id }, airportsMap]) => ({
        response: updateNeedleWithMacInfoAndSort(response, airportsMap),
        id,
      })),
      withLatestFrom(this.originsFacade.countryByAirportMap),
      map(([{ response, id }, airportCountryMap]) => ({
        list: buildCountryListFromAirports(response, airportCountryMap),
        id,
      })),
      map(result =>
        this.originsFacade.loadDestinationAirports(result.list, result.id),
      ),
    ),
  );

  getRegionsSuccess$ = createEffect(() =>
    this.getRegions$.pipe(
      filter(HttpService.isSuccessful),
      map(regions => this.originsFacade.loadRegions(regions)),
    ),
  );

  getNearbyAirportsSuccess$ = createEffect(() =>
    this.getNearbyAirports$.pipe(
      filter(HttpService.isSuccessful),
      map(response => this.originsFacade.loadNearbyAirports(response)),
    ),
  );
}
