import { BestFareDay, WindowSize } from '../../../core';
import { CalendarResponse } from '../types';

export interface CalendarDateBoxComponentState {
  day: BestFareDay;
  dayOfWeek: number;
  isLoaded: boolean;
  iOS: boolean;
  content: CalendarResponse;
  isLoading: boolean;
  isOneWay: boolean;
  isPoints: boolean;
  maxSeats: number;
  month: number;
  year: number;
  progressFocus: number;
  selectedDepart: BestFareDay;
  selectedReturn: BestFareDay;
  windowSize: WindowSize;
}

export const calendarDateBoxComponentInitialState: CalendarDateBoxComponentState =
  {
    content: undefined,
    day: undefined,
    dayOfWeek: 0,
    iOS: false,
    isLoaded: false,
    isLoading: false,
    isOneWay: false,
    isPoints: false,
    maxSeats: 0,
    month: 0,
    progressFocus: 0,
    selectedDepart: undefined,
    selectedReturn: undefined,
    windowSize: undefined,
    year: 0,
  };

export interface CalendarDateBoxComponentViewModel
  extends CalendarDateBoxComponentState {
  background: string;
  content: CalendarResponse;
  dateBoxClass: Record<string, boolean>;
  day: BestFareDay;
  dayOfWeek: number;
  iOS: boolean;
  isBeforeDepartDate: boolean;
  isBetweenDepartAndReturn: boolean;
  isDepartDate: boolean;
  isFocusable: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  isMobile: boolean;
  isOneWay: boolean;
  isPoints: boolean;
  isReturnDate: boolean;
  isTablet: boolean;
  maxSeats: number;
  month: number;
  progressFocus: number;
  selectedDepart: BestFareDay;
  selectedReturn: BestFareDay;
  windowSize: WindowSize;
  year: number;
}
