import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

import { JetBlueLogoSection } from '../header/types/header.inner.type';

/**
 * @description Jb logo image wrapped in a link
 * @WhereIsItUsed
 * - logo icon array container
 * - dot-okta-header
 * - header-desktop
 * - header-mobile
 * - header-skeleton
 */
@Component({
  selector: 'jb-logo',
  templateUrl: './jb-logo.component.html',
  styleUrls: ['./jb-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JBLogoComponent {
  @ViewChild('logoLink', { static: true }) logoLink: ElementRef;
  @ViewChild('cmsLogo') cmsLogo: HTMLImageElement;
  @Input() logoData: JetBlueLogoSection;
}
