import { BestFareDay, WindowSize } from '@core/index';

import {
  ProgressIndicatorBlockLabel,
  ProgressIndicatorContent,
  ProgressIndicatorType,
} from '../types';

export interface ProgressIndicatorBlockState {
  content: ProgressIndicatorContent;
  type: ProgressIndicatorType;
  windowSize: WindowSize;
  isActive: boolean;
  isPoints: boolean;
  isEditable: boolean;
  disable: boolean;
  maxSeats: number;
  bff: BestFareDay;
}

export const progressIndicatorBlockInitalState: ProgressIndicatorBlockState = {
  bff: undefined,
  content: undefined,
  disable: false,
  isActive: false,
  isEditable: false,
  isPoints: false,
  maxSeats: 0,
  type: undefined,
  windowSize: undefined,
};

export interface ProgressIndicatorBlockViewModel
  extends ProgressIndicatorBlockState {
  seatCopy: string;
  blockLabelCopy: ProgressIndicatorBlockLabel;
}
