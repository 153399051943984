<div class="page-margin jb-max-width pt3 bb b--medium-gray">
  <div class="pv8 pv10-ns pl6-l ml1 ml10-l mb3">
    <dot-text-block
      class="db w-40 pb7"
      bgColor="#eeeeee"
      [height]="18"
    ></dot-text-block>
    <dot-text-block
      class="db w-80 pb3"
      bgColor="#eeeeee"
      [height]="14"
    ></dot-text-block>
    <dot-text-block
      class="db w-50"
      bgColor="#eeeeee"
      [height]="14"
    ></dot-text-block>
  </div>
</div>
<div class="pt4 bg-white min-h-10"></div>
<div class="pt4 bg-white min-h-10"></div>
