import { mock as airports } from '@shared/fare-sale-sort-link/fare-sale-utils/fare-sale-airports-map-mock';

import { IS_USER_BIRTHDAY_CMS_KEY } from '../types';
import { PersonalizationConfig } from '../types/personalization.type';

const airportList = Object.keys(airports);

export const PERSONALIZATION_CONFIG: PersonalizationConfig = {
  currentLang: ['en', 'es', 'fr'], // languages that are supported by personalization
  cardType: [
    'defaultCard',
    'defaultNoCard',
    'BC05',
    'BC01',
    'BC04',
    'BC02',
    'BC03',
    'BC00',
  ],
  points: [
    '0-10000',
    '10001-20000',
    '20001-30000',
    '30001-40000',
    '40001-50000',
    '50001-60000',
    '60001-70000',
    '70001-80000',
    '80001-90000',
    '90001-100000',
  ],
  recentSearch: ['true', 'false'],
  firstVisit: ['true', 'false'],
  lastVisitSixMonths: ['true', 'false'],
  hasUpcomingTrip: ['true', 'false'],
  mosaicStatus: ['mosaic1', 'mosaic2', 'mosaic3', 'mosaic4', 'mosaic5'],
  searchDestination: airportList,
  [IS_USER_BIRTHDAY_CMS_KEY]: ['true', 'false'],
  visitor: ['standard', 'mosaic', 'guest'],
  trueBluePointsBalance: [
    'RANGE_0',
    'RANGE_1',
    'RANGE_2',
    'RANGE_3',
    'RANGE_4',
    'RANGE_5',
    'RANGE_6',
    'RANGE_7',
  ],
  travelBankBalance: [
    'TIER_0',
    'TIER_1',
    'TIER_2',
    'TIER_3',
    'TIER_4',
    'TIER_5',
    'TIER_6',
    'TIER_7',
  ],
};

// this will be controlled by cms user
export const PERSONALIZATION_PRIORITY_CONFIG = {
  origin: ['recentSearch'],
};
