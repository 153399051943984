import { Injectable } from '@angular/core';
import { TypedDictionary } from '@core/utils';
import { Observable } from 'rxjs';

import { StoreService } from '../shared/store.service';
import {
  LoadAirports,
  LoadCountries,
  LoadDestinationAirports,
  LoadNearbyAirports,
  LoadOriginAirports,
  LoadRegions,
  RequestAirports,
  RequestCountries,
  RequestDestinationAirports,
  RequestNearbyAirports,
  RequestOriginAirports,
  RequestRegions,
} from './origins.actions';
import * as OriginsSelectors from './origins.selectors';
import {
  Airport,
  CountryWithAirportCodes,
  CountryWithAirports,
  OriginsState,
  Region,
} from './types';
import { AirportListAndMap } from './types/airport-list-and-map.type';

@Injectable({ providedIn: 'root' })
export class OriginsFacade {
  isOdServiceDown: Observable<boolean> = this.storeService.getState(
    OriginsSelectors.isOdServiceDown,
  );
  origins: Observable<OriginsState> = this.storeService.getState(
    OriginsSelectors.origins,
  );
  airports: Observable<Airport[]> = this.storeService.getState(
    OriginsSelectors.airports,
  );
  airportsMap: Observable<TypedDictionary<Airport>> =
    this.storeService.getState(OriginsSelectors.airportsMap);
  countriesWithAirportCodes: Observable<CountryWithAirportCodes[]> =
    this.storeService.getState(OriginsSelectors.countriesWithAirportCodes);
  countriesWithAirports: Observable<CountryWithAirports[]> =
    this.storeService.getState(OriginsSelectors.countriesWithAirports);
  countryByAirportMap: Observable<TypedDictionary<CountryWithAirportCodes>> =
    this.storeService.getState(OriginsSelectors.countryByAirportMap);
  regions: Observable<TypedDictionary<Region>> = this.storeService.getState(
    OriginsSelectors.regions,
  );
  nearbyAirportCodes = this.storeService.getState(
    OriginsSelectors.nearbyAirportCodes,
  );
  airportsByCountryMap: Observable<TypedDictionary<Airport[]>> =
    this.storeService.getState(OriginsSelectors.airportsByCountryMap);

  // TODO: fix "Failed to construct 'Worker'" error on dev2
  // Complex Functions that we will handle in webworkers to improve initial app load time
  // airportsByCountryMap = fromWorker(
  //   () =>
  //     new Worker(new URL('./airportsByCountryMap.worker.ts', import.meta.url), {
  //       type: 'module',
  //     }),
  //   this.storeService.getState(OriginsSelectors.airportsByCountryMap),
  // );

  constructor(private storeService: StoreService) {}

  originAirports = id =>
    this.storeService.getState(OriginsSelectors.originAirports(id));

  originAirportsMap = id =>
    this.storeService.getState(OriginsSelectors.originAirportsMap(id));

  destinationAirports = id =>
    this.storeService.getState(OriginsSelectors.destinationAirports(id));

  destinationAirportsMap = id =>
    this.storeService.getState(OriginsSelectors.destinationAirportsMap(id));

  getFullAirportFromCode = code =>
    this.storeService.getState(OriginsSelectors.getAirportWithCode(code));

  requestAirports(): RequestAirports {
    return new RequestAirports();
  }

  loadAirports(airportListAndMap: AirportListAndMap): LoadAirports {
    return new LoadAirports(airportListAndMap);
  }

  requestCountries() {
    return new RequestCountries();
  }

  loadCountries(airports: CountryWithAirportCodes[]): LoadCountries {
    return new LoadCountries(airports);
  }

  requestOriginAirports(destinationCode, id) {
    this.storeService.dispatchAction(
      new RequestOriginAirports({ destinationCode, id }),
    );
  }

  loadOriginAirports(
    countryWithAirports: CountryWithAirports[],
    id: string,
  ): LoadOriginAirports {
    return new LoadOriginAirports({ countryWithAirports, id });
  }

  requestDestinationAirports(originCode, id) {
    this.storeService.dispatchAction(
      new RequestDestinationAirports({ originCode, id }),
    );
  }

  loadDestinationAirports(
    countryWithAirports: CountryWithAirports[],
    id: string,
  ): LoadDestinationAirports {
    return new LoadDestinationAirports({ countryWithAirports, id });
  }

  requestRegions() {
    return new RequestRegions();
  }

  loadRegions(regions: TypedDictionary<Region>): LoadRegions {
    return new LoadRegions(regions);
  }

  requestNearbyAirports(): RequestNearbyAirports {
    return new RequestNearbyAirports();
  }

  loadNearbyAirports(nearbyAirports: string[]) {
    return new LoadNearbyAirports(nearbyAirports);
  }
}
