/**
 * Function that checks whether a given value is a boolean;
 * If it is not, the function should convert it into a boolean value.
 * @param {boolean | string | undefined} val value to be tested
 * @returns {boolean} After validation, the function returns a boolean value, either true or false.
 */
export function booleanStringCheck(val: boolean | string | undefined): boolean {
  return typeof val === 'string'
    ? val === 'true' || val === 'True'
    : Boolean(val);
}
