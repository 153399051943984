import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JBInnerHtmlModule } from '@shared/jb-inner-html';
import { JbTravelAlertModule } from 'jb-component-library';

import { UIModule } from './../ui/ui.module';
import {
  travelAlertResolver,
  TravelAlertsComponent,
} from './travel-alerts.component';

const MODULES = [
  CommonModule,
  UIModule,
  JbTravelAlertModule,
  JBInnerHtmlModule,
];

const DECLARATIONS = [TravelAlertsComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class TravelAlertsModule {
  static entry = TravelAlertsComponent;
  static componentDataResolver = travelAlertResolver;
}
