import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BestFareCalendar } from './best-fare-calendar.type';

/**
 * @description Scrollable calendar for selecting dates for a selected route
 * @WhereIsItUsed bff component
 */
@Component({
  selector: 'jb-best-fare-calendar',
  templateUrl: './best-fare-calendar.component.html',
  styleUrls: ['./best-fare-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BestFareCalendarComponent {
  @Input() content: BestFareCalendar;
  @Input() dealStartDate: Date;
  @Input() formValue: any;
}
