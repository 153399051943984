import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SessionStorageService } from '@core/storage';
import { GlobalFacade } from '@store/global/global.facade';
import { filter } from 'rxjs/operators';

import { TravelAlerts } from '../header/types/header.inner.type';

/**
 * @description Formatted section to display a travel alerts message in header
 * @WhereIsItUsed header component
 */
@Component({
  selector: 'jb-travel-alerts',
  templateUrl: './travel-alerts.component.html',
})
export class TravelAlertsComponent implements OnInit {
  travelAlert: TravelAlerts;
  showAlert = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private globalFacade: GlobalFacade,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (!this.sessionStorageService.getItem('closeAlerts')) {
      this.showAlert = true;
      this.globalFacade.travelAlerts
        .pipe(filter(Boolean))
        .subscribe((travelAlerts: TravelAlerts) => {
          this.travelAlert = travelAlerts;
          this.cdr.detectChanges();
        });
    }
  }

  toggle(): void {
    this.sessionStorageService.setItem('closeAlerts', true);
  }
}

export const travelAlertResolver = (registry, data) => ({
  travelAlert: data,
});
