import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UIModule } from '../ui';
import { CountdownComponent } from './countdown.component';

const DECLARATIONS = [CountdownComponent];

const IMPORTS = [CommonModule, RouterModule, UIModule];

@NgModule({
  imports: IMPORTS,
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class CountdownModule {}

export const __TEST__ = {
  DECLARATIONS,
  IMPORTS,
};
