import { Pipe, PipeTransform } from '@angular/core';
import { WindowSize } from '@core/window-actions';
import { DynamicLabel } from '@shared/types';
import { getDynamicLabel } from '@shared/ui/utils/global-utils/get-dynamic-label';

/**
 * Display Device specific label
 */
@Pipe({
  name: 'jbDynamicLabel',
})
export class DynamicLabelPipe implements PipeTransform {
  transform(
    labelContainer: DynamicLabel,
    screenSize: WindowSize = { large: true },
  ): string {
    if (labelContainer) {
      return getDynamicLabel(labelContainer, screenSize);
    }
    return '';
  }
}
