import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { getErrorAction } from '../api/api.selectors';
import { AppState } from '../shared/types/app-state.type';
import {
  PreferenceCenterActions,
  StoreMemberData,
  StoreMemberPayment,
  StoreMemberPreferences,
  StoreProfilePicture,
  UpdateDeleteStoreProfilePicture,
  UpdateStoreMemberData,
  UpdateStoreMemberPreferences,
  UpdateStorePaymentsInfo,
  UpdateStoreProfilePicture,
} from './preference-center.actions';
import {
  CreditCardRetrievalResponseState,
  PersonalInfoState,
  PreferenceCenterState,
  TravelPreferencesState,
} from './types';

export const preferenceCenterSelector: MemoizedSelector<
  AppState,
  PreferenceCenterState
> = createFeatureSelector('preferenceCenter');

export const personalInfo: MemoizedSelector<AppState, PersonalInfoState> =
  createSelector(preferenceCenterSelector, state => state.personalInfo);

export const profilePicture: MemoizedSelector<AppState, Blob> = createSelector(
  preferenceCenterSelector,
  state => state.profilePicture,
);

export const prefererencesApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<StoreMemberData>(PreferenceCenterActions.STORE_MEMBER_DATA),
    action => action && action.payload,
  );

export const profilePictureApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<StoreProfilePicture>(
      PreferenceCenterActions.STORE_PROFILE_PICTURE,
    ),
    action => action && action.payload,
  );

export const travelPreferences: MemoizedSelector<
  AppState,
  TravelPreferencesState
> = createSelector(preferenceCenterSelector, state => state.travelPreferences);

export const travelPrefererencesApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<StoreMemberPreferences>(
      PreferenceCenterActions.STORE_MEMBER_PREFERENCES,
    ),
    action => action && action.payload,
  );

export const prefererencesUpdateApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<UpdateStoreMemberData>(
      PreferenceCenterActions.UPDATE_STORE_MEMBER_DATA,
    ),
    action => action && action.payload,
  );

export const memberPrefererencesUpdateApiError: MemoizedSelector<
  AppState,
  any
> = createSelector(
  getErrorAction<UpdateStoreMemberPreferences>(
    PreferenceCenterActions.UPDATE_STORE_MEMBER_PREFERENCES,
  ),
  action => action && action.payload,
);

export const futureReservationStatus: MemoizedSelector<AppState, Boolean> =
  createSelector(
    preferenceCenterSelector,
    state => state.futureReservationStatus,
  );

export const paymentInfo: MemoizedSelector<
  AppState,
  CreditCardRetrievalResponseState
> = createSelector(preferenceCenterSelector, state => state.paymentInfo);

export const travelBankBalance: MemoizedSelector<AppState, number> =
  createSelector(
    preferenceCenterSelector,
    state =>
      state.paymentInfo?.Response?.TravelBankBalance?.BalanceRS?.AccountBalance,
  );

export const paymentInfoApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<StoreMemberPayment>(
      PreferenceCenterActions.STORE_MEMBER_PAYMENT,
    ),
    action => action && action.payload,
  );

export const paymentUpdateApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<UpdateStorePaymentsInfo>(
      PreferenceCenterActions.UPDATE_STORE_PAYMENTS_INFO,
    ),
    action => action && action.payload,
  );
export const profilePictureUpdateApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<UpdateStoreProfilePicture>(
      PreferenceCenterActions.UPDATE_STORE_PROFILE_PICTURE,
    ),
    action => action && action.payload,
  );

export const profilePictureDeleteApiError: MemoizedSelector<AppState, any> =
  createSelector(
    getErrorAction<UpdateDeleteStoreProfilePicture>(
      PreferenceCenterActions.UPDATE_DELETE_STORE_PROFILE_PICTURE,
    ),
    action => action && action.payload,
  );
