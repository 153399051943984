import { Injectable } from '@angular/core';
import { AppConfigService, HttpService } from '@core/index';
import { createErrorAction, networkError } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import { NetworkError } from '@store/shared/types/network-error.type';
import { isValid, parse } from 'date-fns';
import { path, pathOr, pipe } from 'ramda';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ScheduleExtensionActions } from '../schedule-extension.actions';
import { ScheduleExtension } from '../types';

export const EXTENSION_LABEL_DEFAULT = 'Flights available for sale through';

@Injectable({ providedIn: 'root' })
export class ScheduleExtensionApi {
  private scheduleExtensionUrl: string;

  constructor(
    private http: HttpService,
    private appConfig: AppConfigService,
    private storeService: StoreService,
  ) {
    this.scheduleExtensionUrl = appConfig.odServiceScheduleExtensionUrl;
  }

  getExtensionDate(): Observable<ScheduleExtension | NetworkError> {
    return this.http.get<any>(this.scheduleExtensionUrl).pipe(
      map(response => this.extractScheduledDate(response)),
      catchError(error => {
        this.storeService.dispatchAction(
          createErrorAction(
            ScheduleExtensionActions.LOAD_EXTENSION_DATE,
            error,
          ),
        );
        return observableOf(networkError());
      }),
    );
  }

  private getNow() {
    return new Date();
  }

  private extractScheduledDate(res: { [key: string]: any }): ScheduleExtension {
    const now = this.getNow();

    const scheduledExtensionDate = pipe(
      path<string>(['data', 'ScheduleExtension', 'ExtensionDate']),
      dateStr => (dateStr ? parse(dateStr, 'yyyy-MM-dd', now) : now),
      date => (isValid(date) ? date : now),
    )(res);

    const scheduledExtensionMsg = pathOr(
      EXTENSION_LABEL_DEFAULT,
      ['data', 'ScheduleExtension', 'Label'],
      res,
    );

    return {
      label: scheduledExtensionMsg.toString(),
      date: scheduledExtensionDate,
    };
  }
}
