<div
  *ngIf="vm$ | async as vm"
  class="calendar-aggregator col-12-l col-12-m col-4-s"
  [ngClass]="vm.isMobile ? 'bg-white' : 'page-margin bg-off-white'"
>
  <div class="jb-max-width center">
    <!-- calendars -->

    <ng-container
      *ngFor="let calendar of vm.calendars; let i = index; let last = last; trackBy: trackMonthsBy"
    >
      <jb-calendar
        class="db pt4"
        [calendarIndex]="i"
        [content]="vm.content.calendarContent"
        [ngClass]="{ mb2: vm.isSeasonalRoute }"
        [maxDate]="vm.maxDate"
        [maxSeats]="vm.content.maxSeats"
        [isLastMonthOfCalendar]="last"
        [legalCopy]="vm.content.lowestFareLabel"
        [canAutoLoad]="canAutoLoad"
        jbIntersectionObserver
        [intersectionDebounce]="appConfigService.intersectionDebounce"
        [intersectionRootMargin]="appConfigService.intersectionRootMargin"
        [intersectionThreshold]="appConfigService.intersectionThreshold"
        [cmsAutoLoadFlag]="canAutoLoad"
        (visibilityChange)="onVisibilityChanged(calendar, $event)"
      >
      </jb-calendar>
      <div
        class="col-12-l col-12-m col-4-s ph3 ph0-ns flex justify-center justify-start-ns"
        *ngIf="vm.isSeasonalRoute"
      >
        <span tabindex="0" class="s-body pv3 tc tl-ns">{{
          vm.content.seasonalLabel
        }}</span>
      </div>
    </ng-container>
  </div>
</div>
