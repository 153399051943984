import moize from 'moize';

export function memo() {
  return (
    target: any,
    key: string,
    descriptor: PropertyDescriptor,
  ): PropertyDescriptor => {
    if (typeof descriptor.value !== 'function') {
      throw new Error('Memoization can be applied only to methods');
    }

    descriptor.value = moize(descriptor.value);
    return descriptor;
  };
}
