export const CMS_MANAGE_TRIPS_FALLBACK = {
  endpoint: '/home/manage-trips',
  name: 'manage-trips',
  data: {
    hidePageinSEO: 'false',
    hideHeaderFooter: 'false',
    siteMapChangeFreq: 'weekly',
    siteMapPriority: '0.5',
    title: 'Manage Trips',
    personalizationTrait: '',
  },
  area: [
    {
      component: [
        {
          name: 'breadcrumbs',
          data: {
            breadcrumbs: [
              {
                name: 'Manage Trips',
                href: '/manage-trips',
                parentPages: [{ name: 'Home', href: '' }],
              },
            ],
          },
        },
        {
          name: 'hero',
          data: {
            hero: [
              { theme: 'light', type: 'secondary', title: 'Manage trips' },
            ],
          },
        },
      ],
    },
    {
      component: [
        {
          name: 'sectionHeader',
          data: {
            sectionHeader: [
              {
                name: 'section',
                data: {
                  theme: 'light',
                  layout: 'left',
                  bgColor: '#fff',
                  title: 'Change or cancel flights, add bags, & more',
                  section: [
                    {
                      name: 'manageFlights',
                      data: {
                        ManageFlights: [
                          {
                            lastNameField: [
                              {
                                label: 'Last name',
                                description: 'Please enter your last name',
                                validationLabels: [
                                  { required: 'Last name is required' },
                                ],
                              },
                            ],
                            confirmationCode: [
                              {
                                label: 'Confirmation code or ticket #',
                                description: 'ex. ABCDEF or 1234567890123',
                                validationLabels: [
                                  {
                                    invalidTicket:
                                      'Please enter a valid ticket number',
                                    invalid:
                                      'Please enter a valid confirmation code',
                                    required:
                                      'A confirmation code or ticket # is required',
                                  },
                                ],
                              },
                            ],
                            submitCTA: [{ label: 'Manage trip' }],
                            submitErrors: [
                              {
                                confirmationSubmitError:
                                  "We couldn't find this trip. Please try again or search by ticket #.",
                                TicketNumberSubmitError:
                                  "We couldn't find this trip. Please try again.",
                                serviceError:
                                  "It's not you it's us. Please try again later.",
                              },
                            ],
                            submitMessages: [
                              {
                                PnrReservation: 'What is this?',
                                technicalError: 'What is this?',
                              },
                            ],
                            configs: [
                              {
                                mybEnableHealthCheckService: 'true',
                                mybGoToDX: 'true',
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      name: 'bodyText',
                      data: {
                        theme: 'light',
                        bodyText: [
                          {
                            content: 'Need help?',
                            title: 'manage trips myb need help page',
                            link: [
                              {
                                href: '/help/checked-bags#liability-limits',
                                newWindow: '_self',
                                title: 'Find your confirmation code',
                                type: 'internal',
                              },
                            ],
                          },
                        ],
                        layout: 'left',
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  ],
};
