import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TextContentService } from '@core/text-content/text-content.service';
import { DealLegalTextFormatterPipe } from '@shared/ui/pipes/dealLegalFormatterPipe/deal-legal-text-formatter.pipe';
import { JbDialogService } from 'jb-component-library';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { DealLegalModalDialogLinkStaticCopy } from './types/deal-legal-modal-dialog-link-static-copy.type';

/**
 * @description Display Formatted legal text in modal
 * @WhereIsItUsed
 * - jb-seo-deal
 * - isolated-flight-block
 */
@Component({
  selector: 'jb-deal-legal-modal-dialog',
  templateUrl: './deal-legal-modal-dialog.component.html',
  styleUrls: ['./deal-legal-modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealLegalModalDialogComponent implements OnInit {
  // mock data and test, need API JSON data in the future
  dealLegalData: any;
  legalCopy: any;
  noFlights: any;
  noFlightsText: string;
  baggageFeesLink: string;
  restrictionsApplyLink: string;
  formattedLegalCopy: string;
  dealLegalLinks$: Observable<DealLegalModalDialogLinkStaticCopy> =
    this.textContentService
      .getJsonAsset('deal-legal-modal-dialog')
      .pipe(share());

  constructor(
    public dialogService: JbDialogService,
    private textContentService: TextContentService,
    private dealLegalTextFormatterPipe: DealLegalTextFormatterPipe,
  ) {}

  handleData = (
    dealLegalData: any,
    legalCopy?: { content: string; title: string },
  ) => {
    this.legalCopy = legalCopy;
    this.dealLegalData = dealLegalData;
  };

  ngOnInit() {
    this.formattedLegalCopy = this.dealLegalTextFormatterPipe.transform(
      this.dealLegalData,
      this.legalCopy.content,
    );
  }

  closeDialog() {
    this.dialogService.closeDialog();
  }
}
