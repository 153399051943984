import { Pax } from '@shared/types';

export function convertValidationErrorsToString(errors: any): string {
  /**
   * Object.values fails in build.
   * Appears to be specific to Windows. Reverting to map to resolve, see resource below for details.
   * https://stackoverflow.com/questions/38748445/uncaught-typeerror-object-values-is-not-a-function-javascript
   */
  // return Object.values(errors).join(',');
  /* tslint:enable:max-len */
  return Object.keys(errors)
    .map(key => {
      return errors[key];
    })
    .join(',');
}

export function getTotalPassengers(paxValue: Pax): number {
  return Object.keys(paxValue)
    .map(i => paxValue[i])
    .reduce((totalPax, pax) => {
      return totalPax + pax;
    }, 0);
}
