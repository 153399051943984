<div
  class="ph3 flex align-center items-center title-height justify-between"
  [ngClass]="!hideTitle ? [titleBgColor, titleColor] : null"
>
  <jb-dialog-temp-title
    *ngIf="!hideTitle"
    [hideTopCloseButton]="topCloseButtonHide"
    class="pv3"
    [ngClass]="{ center: centerTitle }"
  >
    <ng-content select="[jbDialogTitle]"></ng-content>
  </jb-dialog-temp-title>
  <jb-close-button
    *ngIf="!topCloseButtonHide"
    size="large"
    jbCloseDialog
    fill="white"
  >
  </jb-close-button>
</div>

<jb-dialog-temp-content
  [hasPadding]="hasPadding"
  class="pt0 mb1"
  [ngClass]="{ ph5: hasPadding, 'dialog-no-bottom': hideBottomCloseButton }"
>
  <!-- pb5 -->
  <ng-content></ng-content>
</jb-dialog-temp-content>

<div *ngIf="!hideBottomCloseButton">
  <jb-dialog-temp-actions>
    <ng-content select="[jbDialogActionsBottom]"></ng-content>
  </jb-dialog-temp-actions>
</div>
