import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AppConfigService } from '@core/app-config';

/**
 * @description Formatted block to display a message for consistency across pages.
 * It can display an image with text content and links
 * @WhereIsItUsed
 * - fare sale block component
 * - flight tracker
 */
@Component({
  selector: 'jb-message-block',
  templateUrl: './jb-message-block.component.html',
  styleUrls: ['./jb-message-block.component.scss'],
  host: {
    class: 'tc mv8-ns mv4 pv8-ns pv4 flex flex-column',
  },
})
export class JBMessageBlockComponent implements OnInit {
  @Input() icon: { src: string; alt?: string; isUrlSrc?: boolean };
  @Input() img: { src: string; alt?: string };
  @Input() title: string;
  @Input() body: string;
  /** Link for redirection within the application */
  @Input() link: { href: string; text: string; isExternal: boolean };
  /** Set background color of component. Defauted to 'off-white' */
  @HostBinding('style.backgroundColor')
  @Input()
  backgroundColor: string;
  enableLazyLoadImage: boolean;

  constructor(public appConfigService: AppConfigService) {
    this.enableLazyLoadImage = appConfigService.lazyLoadImages;
  }

  ngOnInit(): void {
    // check if src passed is a url string
    if (
      this.icon?.src &&
      this.icon.src.match(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      )
    ) {
      // url src
      this.icon.isUrlSrc = true;
    } else if (this.icon?.src) {
      // icon name src
      this.icon.isUrlSrc = false;
    }
  }
}
