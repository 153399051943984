<a
  [jbSitemapLinker]="link.href"
  [target]="link.newWindow"
  rel="noopener"
  [ngClass]="{ 'smaller-bubble': smaller, 'dib': !isMobile }"
  class="bubble-link no-underline center-ns tc-ns w-100 w-auto-ns royal-blue"
  >
  <div [ngClass]="isMobile ? 'bubble-img-title-wrapper' : ''">
    <jb-image
      *ngIf="isMobile"
      [ngClass]="{ 'br-100': round }"
      class="ma2-ns mb3-ns v-mid dib overflow-hidden h-auto"
      [src]="link.image?.src?.replace('Desktop.svg', 'Mobile.svg') || ''"
      [alt]="link.image?.alt"
      [defer]="true"
    >
    </jb-image>
    <jb-image
      *ngIf="!isMobile"
      [ngClass]="{ 'br-100': round }"
      class="w3 w4-ns ma2-ns mb3-ns mt0-ns v-mid db overflow-hidden h-auto"
      [src]="link.image?.src || ''"
      [alt]="link.image?.alt"
      [defer]="true"
    >
    </jb-image>
    <span
      class="dib tc-ns fill-royal-blue db-ns display-copy-typeface v-mid ml3 ml0-ns"
      ><span class="link-title">{{ link.title }}</span>
      <span
        *ngIf="!!link.subtitle"
        [ngClass]="{ 'tc ml3': !isMobile }"
        class="link-subtitle f6 db display-copy-typeface ml0-ns mt1"
      >
        {{ link.subtitle }}
        <dot-link-icon
          class="pl1"
          [newWindow]="link.newWindow === '_self' ? null : link.newWindow"
        ></dot-link-icon>
      </span>
    </span>
  </div>
</a>
