<div class="bg-off-white min-h-10">
  <div class="page-margin jb-max-width center-l">
    <div class="jb-grid relative">
      <div class="col-6-l col-8-m pt8 pt10-ns">
        <dot-text-block
          class="db mt0 mt2-m mt4-ns"
          bgColor="#eeeeee"
          [width]="40"
          [height]="18"
        ></dot-text-block>
        <dot-text-block
          *ngIf="!titleOnly"
          class="db mt4 mt6-l"
          [width]="50"
          [height]="14"
          bgColor="#eeeeee"
        ></dot-text-block>
        <dot-text-block
          *ngIf="!titleOnly"
          class="db mt3 mt4-ns"
          [width]="100"
          [height]="12"
          bgColor="#eeeeee"
        ></dot-text-block>
        <dot-text-block
          *ngIf="!titleOnly"
          class="db mt3 mb3 mb6-m mb8-l"
          [width]="80"
          [height]="12"
          bgColor="#eeeeee"
        ></dot-text-block>
      </div>
    </div>
  </div>
</div>
