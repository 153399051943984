<td
  *ngIf="vm$ | async as vm"
  #calendarDateBox
  id="{{ vm.day.date | date: 'MM-dd-yyyy' }}"
  class="relative calendar-date-box pa2-ns db no-flying-focus w-100 h-100"
  role="button"
  [tabindex]="vm.isFocusable | jbTabindex"
  [ngStyle]="{ background: vm.background }"
  (click)="dayClicked()"
  (keyup.enter)="dayClicked()"
  attr.aria-label="{{ vm.selectedDepart ? 'Return' : 'Depart' }} {{
    vm.day | jbCalendarAriaLabel: vm.content:vm.maxSeats:vm.isPoints
  }} {{
    vm.day.sameDaySelected && !vm.isOneWay ? vm.content.sameDayReturn : ''
  }}"
  attr.aria-busy="{{ vm.isLoading }}"
  aria-live="polite"
  [ngClass]="vm.dateBoxClass"
>
  <div class="dn left-gap-fill" *ngIf="vm.dayOfWeek !== 0"></div>
  <div class="dn right-gap-fill" *ngIf="vm.dayOfWeek !== 6"></div>
  <ng-container *ngIf="vm.day.dateOfMonth > 0">
    <svg
      class="dn arrow-right-svg"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      focusable="false"
    >
      <polyline
        class="polyline-arrow-right"
        points="20,-6 90,50 20,106"
      ></polyline>
    </svg>

    <svg
      class="dn arrow-left-svg"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      focusable="false"
    >
      <polyline
        class="polyline-arrow-left"
        points="80,-6 10,50 80,106"
      ></polyline>
    </svg>

    <svg
      class="dn arrow-right-fill-svg"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      focusable="false"
    >
      <polyline
        class="arrow-right-fill-polyline polyline-fill"
        points="0,-12 100,50 0,112"
      ></polyline>
    </svg>

    <svg
      class="dn arrow-left-fill-svg"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      focusable="false"
    >
      <polyline
        class="arrow-left-fill-polyline polyline-fill"
        points="100,-12 0,50 100,112"
      ></polyline>
    </svg>

    <svg
      class="dn ract-right-svg"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      focusable="false"
    >
      <polyline class="polyline-ract-right" points="100,-4 100,104"></polyline>
    </svg>
    <!-- date -->
    <div
      class="mt1 mt0-ns flex justify-between-ns justify-center calendar-date-box-text"
      [ngClass]="{ 'medium-gray': !vm.isOneWay && vm.isBeforeDepartDate }"
    >
      <span
        class="f7 db tc tl-ns"
        [ngClass]="{ 'day-of-month': !!vm.day.amount }"
        >{{ vm.day.dateOfMonth }}</span
      >
      <ng-container
        *ngIf="
          !vm.isLoading &&
          (!!vm.windowSize.large || !!vm.windowSize.medium) &&
          !!vm.day.date &&
          !!vm.day.holiday &&
          !!vm.day.holiday.image
        "
      >
        <img
          [src]="vm.day.holiday.image.src"
          width="16"
          height="16"
          class="holiday-image"
          [ngClass]="{
            'svg-img-white':
              vm.isDepartDate || vm.isReturnDate || vm.isBetweenDepartAndReturn
          }"
          [alt]="vm.day.holiday.image.alt"
        />
      </ng-container>
    </div>
    <div
      *ngIf="
        (vm.isLoaded && !vm.isLoading) ||
          (!vm.isLoaded && vm.isBeforeDepartDate);
        else notLoaded
      "
      class="core-blue calendar-date-box-text"
    >
      <ng-container
        *ngIf="
          (!vm.day.disabled && !!vm.day.amount && !vm.isBeforeDepartDate) ||
          (!vm.day.disabled && !!vm.day.amount && vm.isOneWay)
        "
      >
        <!-- cash -->
        <span
          *ngIf="!vm.isPoints; else points"
          [ngClass]="{ 'db-copy-l': !vm.isMobile }"
          class="db-copy-xs flex justify-center price-points db absolute right-0 left-0 tc mv1 mb3-ns"
        >
          <span
            [ngClass]="{ 'db-copy-s': !vm.isMobile, b: vm.day.isLowestFare }"
            class="mt0"
          >
            {{ vm.currencySymbol }}
          </span>
          <span [ngClass]="{ b: vm.day.isLowestFare }" class="lh-solid-ns">
            {{ vm.day.amount }}
          </span>
        </span>

        <!-- points -->
        <ng-template #points>
          <!-- nested spans will autoinsert &nbsp between them if they are seperated by a new line.
            Do not seperate spans below.
          -->
          <span
            [ngClass]="{
              b: vm.day.isLowestFare,
              'db-copy-m': !vm.isMobile && !vm.isTablet,
              'db-copy-s': !vm.isMobile
            }"
            *ngIf="!!vm.day.amount || vm.day.amount === 0"
            class="db-copy-xs mt3-l mt3-xl mt3-m price-points points db absolute right-0 left-0 tc nowrap mv1 mb3-ns"
          >
            {{ vm.day.amount | jbPointsPipe: vm.windowSize
            }}<span
              [ngClass]="{ b: vm.day.isLowestFare }"
              class="point-label db-copy-xs pl0 pl1-ns"
              >{{ vm.content.pointsLabel | jbDynamicLabel: vm.windowSize }}
            </span>
          </span>
        </ng-template>
      </ng-container>

      <!-- text content -->
      <div
        class="db-xl db-l db-m dn tc db-copy-x mt0 mh1 calendar-date-box-footer"
      >
        <span
          *ngIf="
            !vm.day.disabled &&
              vm.day.seats &&
              vm.day.seats <= vm.maxSeats &&
              !vm.isBeforeDepartDate &&
              !vm.isLoading;
            else blockPlaceHolder
          "
          class="db f7 tc b seats-text"
        >
          <span class="mr1">{{ vm.day.seats }}</span>
          <span>{{
            vm.day.seats === 1
              ? vm.content.remainingSeats.seat
              : vm.content.remainingSeats.seats
          }}</span>
        </span>
        <span
          *ngIf="
            (vm.day.sameDaySelected && !vm.isOneWay && !!vm.day.amount) ||
              (!!vm.day.holiday && !!vm.day.holiday.content);
            else blockPlaceHolder
          "
          class="db tc db-copy-xs"
          [ngClass]="{
            b: vm.day.sameDaySelected && !vm.isOneWay && !!vm.day.amount,
            'royal-blue': vm.day.sameDaySelected
          }"
        >
          {{
            vm.day.sameDaySelected && !vm.isOneWay && !!vm.day.amount
              ? vm.content.sameDayReturn
              : vm.day.holiday?.content
          }}</span
        >
      </div>
    </div>

    <ng-template #blockPlaceHolder>
      <span class="db">&nbsp;</span>
    </ng-template>

    <ng-template #notLoaded>
      <div class="flex w-100 pt1 pt2-ns">
        <div *ngIf="vm.isLoading" class="w-60 center h1 h2-ns bg-loading"></div>
      </div>
    </ng-template>
  </ng-container>
</td>
