import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import { UIModule } from '@shared/ui';

import { JBBookerAirWarningDialogComponent } from './booker-air-warning-dialog.component';

const MODULES = [
  CommonModule,
  UIModule,
  ReactiveFormsModule,
  FormsModule,
  LinkIconModule,
];

const DECLARATIONS = [JBBookerAirWarningDialogComponent];
@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class JBBookerAirWarningDialogModule {}
