import omit from 'ramda/src/omit';

import { CMSSiteMap } from './types';

export function removeHomeFromPath(path: string): string {
  return path.indexOf('/home/') === -1
    ? path.replace('/home', '/')
    : path.replace('/home', '');
}

export function flattenSitemapToPathTemplateDictionary(sitemap: CMSSiteMap[]) {
  return sitemap.reduce((acc, val) => {
    const path = removeHomeFromPath(val.endpoint);
    const newAcc = { [path]: val.template };
    for (const routeKey of Object.keys(acc)) {
      newAcc[routeKey] = acc[routeKey];
    }
    if (val.children && Array.isArray(val.children)) {
      const childrenRoutes = flattenSitemapToPathTemplateDictionary(
        val.children,
      );
      for (const routeKey of Object.keys(childrenRoutes)) {
        newAcc[routeKey] = childrenRoutes[routeKey];
      }
      return newAcc;
    }
    return newAcc;
  }, {});
}

/**
 * Search the provided sitemap array recursively for an path and if found return that site map object.
 * @param sitemap Site map array to search
 * @param path Path to search site map for
 * @returns Sitemap object without children by default.
 */
export function searchSiteMapFromPath(
  sitemap: CMSSiteMap[],
  path: string,
  includeChildren = false,
): CMSSiteMap | undefined {
  if (!path || path.length === 0) {
    return;
  }

  let found;

  for (const item of sitemap) {
    if (item.path === path) {
      found = item;
    } else if (item.children?.length > 0) {
      found = searchSiteMapFromPath(item.children, path);
    }

    if (found) {
      if (!includeChildren) {
        // make shallow copy without children property
        found = omit(['children'], found);
      }
      // found, break from loop
      break;
    }
  }

  return found;
}
