import { ApiError } from '@core/http';

import { NetworkError } from './types/network-error.type';

export function createErrorAction(actionType: any, error: ApiError) {
  return {
    type: actionType,
    payload: error,
    error: true,
  };
}

export function removeErrorOfAction(
  actionType: any,
  errorActionTobeRemoved: any,
) {
  return {
    type: actionType,
    payload: errorActionTobeRemoved,
    reset: true,
  };
}

export function networkError(): NetworkError {
  return { error: true };
}
