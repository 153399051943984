import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BodyText } from '@dynamic-components/body-text-container/types';

/**
 * @description Component to display a static message in the bookers when either the service is out
 * or a global message is set to override the bookers
 * @WhereIsItUsed
 * - booker-air
 * - booker-flights-hotel
 * - booker-hotel
 */
@Component({
  selector: 'jb-static-booker-pod',
  templateUrl: './static-booker-pod.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticBookerPodComponent {
  displayContent: BodyText;
  @Input() textDark = false;

  @Input() set content(content: BodyText) {
    this.displayContent = content;
  }
}
