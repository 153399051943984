import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @description Formatted container that centers content and applies a semi-transparent blue background
 * @WhereIsItUsed
 * - bff
 * - booker-v2-page
 */
@Component({
  selector: 'jb-pattern-block',
  templateUrl: './pattern-block.component.html',
  styleUrls: ['./pattern-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatternBlockComponent {
  @Input() addPadding = false;
  @Input() bgUrl: string;
  @Input('isContentSection') isContentSection = true;
}
