import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from '@core/language/language.service';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { JsonAssets } from './text-content.service.types';

@Injectable({
  providedIn: 'root',
})
export class TextContentService {
  private FOLDER = '/ui-assets/text-content';
  private cache: Map<string, Observable<any>> = new Map();
  private lang: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
  ) {}

  // todo: type better
  getJsonAsset(fileName: JsonAssets): Observable<any> {
    // observes when language is set
    return this.languageService.language$.pipe(
      // debounce to prevent multiple requests
      debounceTime(300),
      distinctUntilChanged(),
      map(lang => ({
        lang,
        cachedData: this.cache.get(fileName + '_' + lang),
      })),
      switchMap(({ lang, cachedData }) => {
        if (!cachedData) {
          return this.http.get(`${this.FOLDER}/${fileName}.json`).pipe(
            catchError(error => {
              console.error('Error fetching JSON asset:', error);
              return of(null);
            }),
            filter(data => data !== null),
            tap(data => {
              if (data !== null) {
                this.cache.set(fileName + '_' + lang, of(data));
              }
            }),
          );
        }
        return cachedData;
      }),
    );
  }
}
