import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pathOr } from 'ramda';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, startWith, tap } from 'rxjs/operators';

import { AnalyticsFacade } from '../analytics/analytics.facade';
import { ForgotPasswordApi } from './api/forgot-password.api';
import {
  ForgotPasswordActions,
  PasswordLinkRequestSucceeded,
  PasswordResetLinkRequested,
  RequestPasswordResetLinkFailure,
  ResetPasswordRequested,
  ResetPasswordRequestFailed,
  ResetPasswordRequestSucceeded,
} from './forgot-password.actions';
import { ForgotPasswordFacade } from './forgot-password.facade';
import { transformPasswordRequestError } from './forgot-password.logic';
@Injectable()
export class ForgotPasswordEffect {
  constructor(
    private actions$: Actions,
    private forgetPasswordApi: ForgotPasswordApi,
    private analyticsFacade: AnalyticsFacade,
    private forgotPasswordFacade: ForgotPasswordFacade,
  ) {}

  private static extractTraceId<T>(response: HttpResponse<T>) {
    let traceId: string = null;
    try {
      traceId = response.headers.get('X-B3-TraceId');
    } catch (e) {
      // no-op
    }

    return traceId;
  }

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PasswordResetLinkRequested>(
        ForgotPasswordActions.PASSWORD_RESET_LINK_REQUEST,
      ),
      // exhaust map will prevent multiple calls, it will wait for the first call to finish before making any more
      exhaustMap(({ payload }) =>
        this.forgetPasswordApi.resetPassword(payload).pipe(
          map(response => {
            const traceId = ForgotPasswordEffect.extractTraceId(response);
            return new PasswordLinkRequestSucceeded(traceId);
          }),
          catchError(error => {
            const traceId = ForgotPasswordEffect.extractTraceId(error);
            this.analyticsFacade.forgotPasswordServiceError(
              this.forgotPasswordFacade.serviceErrorMessage,
            );
            return of(
              new RequestPasswordResetLinkFailure(
                pathOr(undefined, ['error', 'error'])(error),
                traceId,
              ),
            );
          }),
        ),
      ),
    ),
  );

  resetPasswordRequestedResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetPasswordRequested>(
        ForgotPasswordActions.RESET_PASSWORD_REQUESTED,
      ),
      exhaustMap(actionPayload =>
        this.forgetPasswordApi
          .resetUserPasswordRequest(actionPayload.payload)
          .pipe(
            tap(response => {
              const traceId = ForgotPasswordEffect.extractTraceId(response);
              this.forgotPasswordFacade.passwordLinkRequestSucceeded(traceId);
            }),
            catchError(error => {
              const traceId = ForgotPasswordEffect.extractTraceId(error);
              const transformedPasswordRequestError =
                transformPasswordRequestError(error);
              this.analyticsFacade.forgotPasswordServiceError(
                transformedPasswordRequestError.errorMessage,
              );
              return of(
                new ResetPasswordRequestFailed(
                  transformedPasswordRequestError,
                  traceId,
                ),
              );
            }),
          ),
      ),
    ),
  );

  readonly resetRequestSuccess$ = this.actions$.pipe(
    ofType<PasswordLinkRequestSucceeded>(
      ForgotPasswordActions.PASSWORD_RESET_LINK_SUCCESS,
    ),
    map(() => true),
    startWith(false),
  );

  readonly passwordResetSuccess$ = this.actions$.pipe(
    ofType<ResetPasswordRequestSucceeded>(
      ForgotPasswordActions.RESET_PASSWORD_SUCCEEDED,
    ),
    map(() => true),
    startWith(false),
  );
}
