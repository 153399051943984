import { TypedDictionary } from '@core/utils';
import { dissoc } from 'ramda';

import { CountryWithAirports, Region } from '../origins/types';
import { FareSaleActions, FareSaleActionsUnion } from './fare-sale.actions';
import { FareSaleState } from './types';

export const initialFareSale: FareSaleState = {
  originSortEnabledFlag: false,
  fareSaleData: [],
  fareSaleDataGroupedByMACs: [],
  sortParameters: {
    priceType: '1',
    sortType: '1',
    vacationsExcluded: true,
  },
};

export const regionsAndCountriesDefault: [
  CountryWithAirports[],
  TypedDictionary<Region>,
] = [[], {}];

export function fareSalesReducer(
  state = initialFareSale,
  action: FareSaleActionsUnion,
): FareSaleState {
  switch (action.type) {
    case FareSaleActions.UPDATE_ORIGIN_SORT_FLAG:
      return {
        ...state,
        originSortEnabledFlag: action.payload,
      };
    case FareSaleActions.INITIALIZE_FARE_SALE_DATA:
      return dissoc('userAirportSelection', {
        ...state,
        fareSaleData: action.fareSaleDataPayload,
        fareSaleDataGroupedByMACs: action.fareSaleDataGroupedByMACsPayload,
      });
    case FareSaleActions.SELECTED_ORIGIN_AIRPORT:
      return {
        ...state,
        userAirportSelection: action.payload,
      };
    case FareSaleActions.CHANGED_LIST_SORT_PARAMETERS:
      return {
        ...state,
        sortParameters: {
          ...state.sortParameters,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
