import { isValid, parseISO } from 'date-fns';
import { range } from 'ramda';

export const convertToDate = (dateOrString: Date | string): Date => {
  let date: Date;
  if (dateOrString) {
    if (dateOrString.constructor === Date) {
      date = dateOrString;
    } else {
      const parsedISO = parseISO(dateOrString as string);
      date = isValid(parsedISO) ? parsedISO : new Date(dateOrString);
    }
  }
  return date;
};

export const isValidDateString = (dateString: string): boolean =>
  isValid(parseISO(dateString));

export const convertMillisecondsToDays = (ms: number) => {
  return ms / (1000 * 60 * 60 * 24);
};

export const months = [
  { label: 'Jan', value: '01' },
  { label: 'Feb', value: '02' },
  { label: 'Mar', value: '03' },
  { label: 'Apr', value: '04' },
  { label: 'May', value: '05' },
  { label: 'Jun', value: '06' },
  { label: 'Jul', value: '07' },
  { label: 'Aug', value: '08' },
  { label: 'Sep', value: '09' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' },
];

export const fullMonths = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const days = range(1, 31 + 1)
  .map(day => `0${day}`.slice(-2))
  .map(day => ({ label: day, value: day }));

export const years = (minYear, maxYear) => {
  return range(minYear, maxYear + 1).map(year => ({
    label: year.toString(),
    value: year.toString(),
  }));
};
