import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { pathOr } from 'ramda';

import { actionIsPending, getErrorAction } from '../api/api.selectors';
import { AppState } from '../shared/types/app-state.type';
import { TrueBlueProfile, TrueBlueState } from '../trueblue/types';
import { ProfileActions, ProfileLoaded } from './profile.actions';
import { ProfilePayType } from './types/profile-details-response.enum';

export const trueBlue: MemoizedSelector<AppState, TrueBlueState> =
  createFeatureSelector('trueblue');

export const memberProfile: MemoizedSelector<AppState, TrueBlueProfile> =
  createSelector(trueBlue, state => state.profile);

export const error: MemoizedSelector<AppState, any> = createSelector(
  getErrorAction<ProfileLoaded>(ProfileActions.PROFILE_LOADED),
  action => action && action.payload,
);

export const profileIsLoading: MemoizedSelector<AppState, boolean> =
  actionIsPending(ProfileActions.LOAD_PROFILE);

export const trueBlueNumber: MemoizedSelector<AppState, string> =
  createSelector(memberProfile, state =>
    pathOr<string>(undefined, ['loyalty', 0, 'membershipId'], state),
  );

export const dateOfBirth: MemoizedSelector<AppState, string> = createSelector(
  memberProfile,
  state => pathOr<string>(undefined, ['dateOfBirth'], state),
);

const tbPayIndex: MemoizedSelector<AppState, number> = createSelector(
  memberProfile,
  state =>
    state && state.pay
      ? state.pay.findIndex(p => p.type === ProfilePayType.TravelBank)
      : undefined,
);

export const travelBankBalance: MemoizedSelector<AppState, string> =
  createSelector(memberProfile, tbPayIndex, (state, index) => {
    const balance = pathOr<string>(undefined, ['pay', index, 'balance'], state);
    return balance === '0.0' ? undefined : balance;
  });
