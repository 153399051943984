<h1 class="mt0 pt1 db mb1 f2">
  {{ trueBlueResponse?.header }}
</h1>

<span class="body l-body db pt0 mt3 charcoal">
  {{ trueBlueResponse?.subheader }}
</span>

<jb-tb-login
  [nextPage]="nextPage"
  [trueBlueResponse]="trueBlueResponse"
  class="db mt3"
>
</jb-tb-login>
