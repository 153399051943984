import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '@shared/ui';

import { JBBookerVacationWarningDialogComponent } from './booker-vacation-warning-dialog.component';

const MODULES = [CommonModule, UIModule, ReactiveFormsModule, FormsModule];

const DECLARATIONS = [JBBookerVacationWarningDialogComponent];
@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class JBBookerVacationWarningDialogModule {}
