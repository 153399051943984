import { Action } from '../shared/types/action.interface';
import { CartData } from './types';

export enum CartActions {
  ADD_GUEST_CART_TO_AUTH_CART = '[CART] Add Guest Cart to Auth Cart',
  CLEAR_CART = '[CART] Clear Cart',
  FETCH_CART_REQUESTED = '[CART] Fetch Cart Requested',
  FETCH_CART_SUCCEEDED = '[CART] Fetch Cart Succeeded',
  FETCH_CART_FAILED = '[CART] Fetch Cart Failed',
}

export class AddGuestCartToAuthCart implements Action {
  readonly type = CartActions.ADD_GUEST_CART_TO_AUTH_CART;
  constructor(public xAuthToken: string) {}
}
export class ClearCart implements Action {
  readonly type = CartActions.CLEAR_CART;
}

export class FetchCartRequested implements Action {
  readonly type = CartActions.FETCH_CART_REQUESTED;
  constructor(
    public cartJwtToken: string | undefined,
    public xAuthToken: string | undefined,
  ) {}
}

export class FetchCartSuccess implements Action {
  readonly type = CartActions.FETCH_CART_SUCCEEDED;
  constructor(public payload: CartData[]) {}
}

export class FetchCartFailure implements Action {
  readonly type = CartActions.FETCH_CART_FAILED;
  constructor(public err: any) {}
}

export type CartActionsUnion =
  | AddGuestCartToAuthCart
  | ClearCart
  | FetchCartRequested
  | FetchCartSuccess
  | FetchCartFailure;
