import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    // separate our query string from the url
    const [urlWithoutQueryString, queryString] = url.split('?');

    return super.parse(
      `${urlWithoutQueryString.toLowerCase()}${
        queryString ? '?' + queryString : ''
      }`,
    );
  }
}
