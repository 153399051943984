import { Legend } from './types';

// convert Legend data to Legend[]
export function convertLegendToArray(legend: any): Legend[] {
  if (!!legend.length) {
    return legend;
  } else if (!!legend.code) {
    const legendArray: Legend[] = [];
    legendArray.push(legend);
    return legendArray;
  } else {
    return [];
  }
}
