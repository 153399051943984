import { HotelBookingForm } from '@dynamic-components/booker-v2/bookers/hotel/booker-hotel-points/booker-hotel-points.types';
import { Action } from '@ngrx/store';
import { BookerFormState } from '@shared/booker';
import { RecentAirSearch, RecentVacationSearch } from '@shared/types';
import { BookerGlobalSettings } from '@shared/types/booker-global-message';
import { LocalStoreRecentLocations } from '@store/booker/types';

export enum BookerActions {
  SET_RECENT_AIR_SEARCH = '[Booker Air Submit Component] Set recent air search',
  MODIFY_LAST_RECENT_AIR_SEARCH = '[Booker Air Submit Component] Modify last recent air search',
  SET_SUBMITTED_AIR_SEARCH = '[Booker Air Submit Component] Set submitted air search',
  SET_RECENT_LOCATIONS = '[Booker Air Updated Location] Set recent locations searched in localstorage',
  SET_RECENT_VACATION_SEARCH = '[Booker Vacation Submit Component] Set recent vacation search',
  SET_RECENT_HOTEL_PTS_SEARCH = '[Booker Hotel+Pts Submit Component] Set recent hotel+pts search',
  UPDATE_CURRENT_AIR_SEARCH = '[Booker Air Update Form] Update the most recent search on every change in form',
  SET_BOOKER_GLOBAL_SETTINGS = '[Bookers components] Set the global booker settings',
}

// Todo: Consider switching out to new Modern/Simpler ActionCreator syntax https://timdeschryver.dev/blog/ngrx-creator-functions-101
export class SetRecentAirSearches implements Action {
  readonly type = BookerActions.SET_RECENT_AIR_SEARCH;
  constructor(public payload: RecentAirSearch) {}
}
export class ModifyLastRecentAirSearch implements Action {
  readonly type = BookerActions.MODIFY_LAST_RECENT_AIR_SEARCH;
  constructor(public payload: Partial<RecentAirSearch>) {}
}
export class SetSubmittedAirSearches implements Action {
  readonly type = BookerActions.SET_SUBMITTED_AIR_SEARCH;
  constructor(public payload: BookerFormState) {}
}

export class SetRecentLocations implements Action {
  readonly type = BookerActions.SET_RECENT_LOCATIONS;
  constructor(public payload: LocalStoreRecentLocations) {}
}

export class UpdateCurrentAirSearch implements Action {
  readonly type = BookerActions.UPDATE_CURRENT_AIR_SEARCH;
  constructor(public payload: RecentAirSearch) {}
}

export class SetRecentVacationSearches implements Action {
  readonly type = BookerActions.SET_RECENT_VACATION_SEARCH;
  constructor(public payload: RecentVacationSearch) {}
}

export class SetRecentHotelPtsSearch implements Action {
  readonly type = BookerActions.SET_RECENT_HOTEL_PTS_SEARCH;
  constructor(public payload: HotelBookingForm) {}
}

export class SetBookerGlobalSettings implements Action {
  readonly type = BookerActions.SET_BOOKER_GLOBAL_SETTINGS;
  constructor(public payload: BookerGlobalSettings) {}
}

export type BookerActionsUnion =
  | UpdateCurrentAirSearch
  | ModifyLastRecentAirSearch
  | SetRecentAirSearches
  | SetSubmittedAirSearches
  | SetRecentLocations
  | SetRecentVacationSearches
  | SetRecentHotelPtsSearch
  | SetBookerGlobalSettings;
