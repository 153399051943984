import { TypedDictionary } from '@core/utils';
import { Action } from '@ngrx/store';
import { AccessToken } from '@okta/okta-auth-js';

import { MfaCatalogResponseError } from './types/mfa-catalog-error-response.type';
import { MfaGroupResponseError } from './types/mfa-group-error-response.type';
import { MfaGroupRequest } from './types/mfa-group-request.type';
import { MfaGroupResponse } from './types/mfa-group-response.type';
import { UserFactor } from './types/mfa-state.type';

export enum MfaActions {
  FETCH_USER_FACTORS = '[MFA] Fetch User Factors',
  FETCH_USER_FACTORS_DONE = '[MFA] Fetch User Factors Done',
  FETCH_USER_FACTORS_ERROR = '[MFA] Fetch User Factors Error',
  CHANGE_USER_GROUP = '[MFA] Change User Group',
  CHANGE_USER_GROUP_DONE = '[MFA] Change User Group Done',
  CHANGE_USER_GROUP_ERROR = '[MFA] Change User Group Error',
}

export class FetchUserFactors implements Action {
  readonly type = MfaActions.FETCH_USER_FACTORS;
  constructor(public accessToken: AccessToken) {}
}

export class FetchUserFactorsDone implements Action {
  readonly type = MfaActions.FETCH_USER_FACTORS_DONE;
  constructor(public payload: TypedDictionary<UserFactor>) {}
}

export class FetchUserFactorsError implements Action {
  readonly type = MfaActions.FETCH_USER_FACTORS_ERROR;
  constructor(public payload: MfaCatalogResponseError) {}
}

export class ChangeUserGroup implements Action {
  readonly type = MfaActions.CHANGE_USER_GROUP;
  constructor(
    public accessToken: AccessToken,
    public payload: MfaGroupRequest,
  ) {}
}

export class ChangeUserGroupDone implements Action {
  readonly type = MfaActions.CHANGE_USER_GROUP_DONE;
  constructor(public payload: MfaGroupResponse) {}
}
export class ChangeUserGroupError implements Action {
  readonly type = MfaActions.CHANGE_USER_GROUP_ERROR;
  constructor(public payload: MfaGroupResponseError) {}
}

export type MfaActionsUnion =
  | FetchUserFactors
  | FetchUserFactorsDone
  | FetchUserFactorsError
  | ChangeUserGroup
  | ChangeUserGroupDone
  | ChangeUserGroupError;
