import { prop, zipObj } from 'ramda';

export interface TypedDictionary<T> {
  [selector: string]: T;
}

export function keyBy<T>(selector: string, list: T[]): TypedDictionary<T> {
  const keys = list.map<string>(prop(selector) as any);
  return zipObj(keys, list);
}
