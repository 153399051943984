<div
  *ngIf="vm$ | async as vm"
  class="col-12-l col-12-m col-4-s pv3 mb1 klarheit date-container"
  [ngClass]="vm.isMobile ? '' : 'page-margin'"
>
  <div
    class="jb-max-width flex items-center items-stretch justify-center flex-wrap center ph2 ph0-ns"
  >
    <ng-container *ngFor="let label of vm.weekdaysLabel">
      <div class="tc date-text charcoal">{{ label }}</div>
    </ng-container>
  </div>
</div>
