import { AllActionsUnion } from '../actions';
import { PreferenceCenterActions } from '../preference-center/preference-center.actions';
import { Action } from '../shared/types/action.interface';
import {
  pendingActionTypes,
  pendingToResolvedActionTypeMap,
  resolutionActionTypes,
  resolvedToPendingActionTypeMap,
} from './api.actions';
import { ApiState } from './types/api-state.type';

export const initialApiState: ApiState = {
  askedFor: [],
  pending: [],
  errors: [],
};

export function apiReducer(
  state = initialApiState,
  action: AllActionsUnion,
): ApiState {
  const newState = { ...state };
  newState.askedFor = askedFor(state.askedFor, action);
  newState.pending = pending(state.pending, action);
  newState.errors =
    action.type === PreferenceCenterActions.MEMBER_DATA_ERROR_STATE_RESET ||
    action.type ===
      PreferenceCenterActions.MEMBER_PREFERENCES_ERROR_STATE_RESET ||
    action.type === PreferenceCenterActions.PAYMENT_INFO_ERROR_STATE_RESET ||
    action.type === PreferenceCenterActions.PROFILE_PICTURE_ERROR_STATE_RESET ||
    action.type ===
      PreferenceCenterActions.PAYMENT_INFO_STORE_ERROR_STATE_RESET ||
    action.type ===
      PreferenceCenterActions.DELETE_STORE_PROFILE_PICTURE_ERROR_STATE_RESET
      ? filterErrors(state.errors, action['payload'])
      : errors(state.errors, action);
  return newState;
}

function askedFor(state = initialApiState.askedFor, action: Action): Action[] {
  if (isPendingAction(action.type)) {
    return [...state, action];
  }
  return state;
}

function pending(state = initialApiState.pending, action: Action): Action[] {
  if (isPendingAction(action.type)) {
    return [...state, action];
  }

  if (isResolutionAction(action.type)) {
    return state.filter(removePendingAction(action.type));
  }

  return state;
}

function errors(state = initialApiState.errors, action: Action): Action[] {
  if (action.error) {
    return [...state, action];
  }

  if (isPendingAction(action.type)) {
    return state.filter(removeErrorAction(action.type));
  }

  return state;
}

function filterErrors(state = initialApiState.errors, action: string) {
  return state.filter(errorAction => errorAction.type !== action);
}

function isPendingAction(actionType: string): boolean {
  return pendingActionTypes.includes(actionType);
}

function isResolutionAction(actionType: string): boolean {
  return resolutionActionTypes.includes(actionType);
}

function removePendingAction(actionType: string) {
  return pendingAction =>
    pendingAction.type !== resolvedToPendingActionTypeMap[actionType];
}

function removeErrorAction(actionType: string) {
  return errorAction =>
    errorAction.type !== pendingToResolvedActionTypeMap[actionType];
}
