import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DealLegalTextFormatterPipe } from '@shared/ui/pipes/dealLegalFormatterPipe/deal-legal-text-formatter.pipe';

import { DialogTempModule } from '../dialog';
import { DealLegalModalDialogComponent } from './deal-legal-modal-dialog.component';

const DECLARATIONS = [DealLegalModalDialogComponent];
const MODULES = [CommonModule, DialogTempModule];
const PROVIDERS = [DealLegalTextFormatterPipe];
@NgModule({
  imports: [...MODULES],
  exports: [...DECLARATIONS],
  declarations: [...DECLARATIONS],
  providers: [...PROVIDERS],
})
export class DealLegalModalDialogModule {}
