import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONFIG_X_AUTH_TOKEN } from '@core/app-config/types/mocks';
import { HttpService } from '@core/http';
import { LocalStorageService } from '@core/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartFacade } from '@store/cart/cart.facade';
import { BookProfile } from '@store/cart/types/book-profile.interface';
import { CookieService } from 'ngx-cookie';
import { from, of } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';

import { ProfileApi } from './api/profile.api';
import { ProfileActions } from './profile.actions';
import { ProfileFacade } from './profile.facade';
import { Pay } from './types/profile-details-response.interface';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private cartFacade: CartFacade,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private profileApi: ProfileApi,
    private profileFacade: ProfileFacade,
  ) {}

  loadRWBProfileDataRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.RWB_PROFILE_SUCCEEDED),
        filter(HttpService.isSuccessful),
        tap(({ response }) => {
          const { pay, isTCAccepted } = response;
          this.profileFacade.dispatchPayDataSuccessAction(pay as Pay[]);
        }),
      ),
    { dispatch: false },
  );

  loadRWBProfileRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.RWB_PROFILE_REQUESTED),
        mergeMap(({ accessToken, xAuthToken }) => {
          return from(
            this.profileApi.requestRwbProfile(accessToken, xAuthToken).pipe(
              filter(HttpService.isSuccessful),
              tap((profile: HttpResponse<BookProfile>) => {
                /*
                If the xAuthToken was not provided.
                Get the xAuthToken from this call and store it in our LocalStorage.
                */
                if (!xAuthToken) {
                  this.localStorageService.setItem(
                    CONFIG_X_AUTH_TOKEN,
                    profile.headers.get(CONFIG_X_AUTH_TOKEN),
                  );
                }

                /*
                Dispatch dispatchFetchCartRequested to get the authenticated user's cart.
                */
                this.cartFacade.dispatchFetchCartRequested({
                  xAuthToken: xAuthToken
                    ? xAuthToken
                    : profile.headers.get(CONFIG_X_AUTH_TOKEN),
                });

                /* Dispatch RwbProfileSuccess to finish the flow */
                this.profileFacade.dispatchRwbProfileSuccess(profile.body);
              }),
              catchError(() =>
                of(this.profileFacade.dispatchRwbProfileFailure()),
              ),
            ),
          );
        }),
      ),
    { dispatch: false },
  );
}
