import { TravelerGroup } from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import {
  addDays,
  differenceInCalendarMonths,
  endOfMonth,
  format,
  getDaysInMonth,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
} from 'date-fns';
import { JbLocaleCodes, JbSupportedLocales } from 'jb-component-library';
import moize from 'moize';
import { assoc, concat, map, merge, range, values } from 'ramda';

import { BestFare, BestFareDay } from '../../core/bff';
import { getDateKey, groupByDate } from './../../core/bff/bff.utils';
import { Day, Holiday } from './../types';
import { Calendar, MonthAndYear } from './types/calendar.type';

// to-do: move some utilities into joint date and calendar module

export const getFullMonthName = moize((month: number): string => {
  return format(new Date(1900, month), 'MMMM');
});

export const getFullMonthNameFromLocale = moize(
  (month: number, locale: string = 'en-US'): string => {
    return JbSupportedLocales[JbLocaleCodes[locale]].localize.month(month);
  },
);

export const generateWeeksOfMonth = (daysOfMonth: Day[]): string[] => {
  return new Array(Math.ceil(daysOfMonth.length / 7));
};

// generates the days in the month view.
// The first day should correctly start on the right day (eg Mon, Tues, ...)

export const generateMonth = moize((month: number, year: number): Day[] => {
  const firstOfMonth = new Date(year, month);
  const lastOfMonth = endOfMonth(firstOfMonth);
  // add blank day slots at beginning of month
  const blankDays: Day[] = range(0, firstOfMonth.getDay()).map(() => ({
    dateOfMonth: 0,
    disabled: true,
  }));
  const endBlankDays: Day[] = range(0, 7 - (lastOfMonth.getDay() + 1)).map(
    () => ({ dateOfMonth: -1, disabled: true }),
  );

  const totalDaysInMonth = getDaysInMonth(firstOfMonth);
  let daysOfMonth: Day[] = range(1, totalDaysInMonth + 1)
    .map(dateOfMonth => new Date(year, month, dateOfMonth))
    .map(date => {
      const today = isToday(date);
      return {
        dateOfMonth: date.getDate(),
        date,
        disabled: !today && isBefore(date, new Date()),
        isToday: today,
      };
    });

  daysOfMonth = concat(blankDays, daysOfMonth);
  daysOfMonth = concat(daysOfMonth, endBlankDays);

  return daysOfMonth;
});

/**
 * Generate number of Calendars from date(fromDate) to maxDate
 */
export const generateCalendars = moize(
  (fromDate: Date, maxDate: Date, holidays: Holiday[] = []): Calendar[] => {
    const holidayHash = holidays.reduce((acc, curr) => {
      const month = getMonth(curr.date);
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(curr);
      return acc;
    }, {});
    const monthDiff = differenceInCalendarMonths(maxDate, fromDate);
    const allCalendars: Calendar[] = new Array(monthDiff + 1)
      .fill(0)
      .map((e, i) => {
        const date = new Date(
          fromDate.getFullYear(),
          fromDate.getMonth() + i,
          1,
        );
        const month = date.getMonth();
        const year = date.getFullYear();
        return {
          month,
          year,
          holidays: holidayHash[month] || [],
          days: generateMonth(month, year),
        };
      });
    return allCalendars;
  },
);

export function applyHolidays(days: Day[], holidays: Holiday[]): BestFareDay[] {
  const holidaysHashMap = groupByDate(holidays);

  days = map(day => {
    const key = getDateKey(day.date);
    if (!!holidaysHashMap[key]) {
      return assoc('holiday', holidaysHashMap[key], day);
    }
    return day;
  }, days);

  return days as BestFareDay[];
}

export function applyBestFares(
  days: Day[],
  fares: BestFare[],
  traveler: TravelerGroup,
): BestFareDay[] {
  const faresHashMap = groupByDate(fares);
  return map(day => {
    const key = getDateKey(day.date);
    let newDay = merge(
      day,
      faresHashMap[key] || { amount: 0, seats: 0, tax: 0 },
    );
    if (newDay.seats < traveler.adults + traveler.children) {
      newDay = merge(day, { amount: 0, seats: 0, tax: 0 });
    }
    newDay['date'] = day.date;
    return newDay;
  }, days) as BestFareDay[];
}

export function applyBirthday(
  days: Day[],
  month: number,
  birthday: Holiday,
): Day[] {
  const daysHashMap = groupByDate(days);

  if (getMonth(birthday.date) === month) {
    const key = getDateKey(birthday.date);
    if (!!daysHashMap[key]) {
      daysHashMap[key].holiday = birthday;
    }
  }
  return values(daysHashMap);
}

export function isDepartDateUtil(day: BestFareDay, departDay: Day): boolean {
  return departDay && isSameDay(day.date, departDay.date);
}

export function isReturnDateUtil(day: BestFareDay, returnDay: Day): boolean {
  return returnDay && isSameDay(day.date, returnDay.date);
}

export function isBeforeDepartDateUtil(
  day: BestFareDay,
  currentDay: Day,
): boolean {
  return isBefore(currentDay.date, day.date);
}

export function isBetweenDepartAndReturnUtil(
  returnDay: any,
  departDay: any,
  currentDay: Day,
): boolean {
  return (
    isBefore(currentDay.date, returnDay.date) &&
    isAfter(currentDay.date, departDay.date)
  );
}

export function isDaysWithData(
  day,
  departDay,
  returnDay,
  isOneWay,
  iOS,
): Record<string, boolean> {
  const isDepart = isDepartDateUtil(day, departDay);
  const isReturn = isReturnDateUtil(day, returnDay);
  const isBeforeDepart = departDay && isBeforeDepartDateUtil(departDay, day);

  return {
    ...(!!day.amount
      ? {
          pointer: true,
          'disable-click': false,
        }
      : {}),
    // set class for hover over state
    // if depart date not selected yet
    // set hover over to right arrow polyline
    // otherwise set it to left arrow polyline
    ...(departDay == null || (isOneWay && !isBeforeDepart)
      ? {
          'arrow-right-focus': !iOS,
          'arrow-right-hover': !iOS && !!day.amount,
        }
      : {
          'arrow-left-focus':
            !isDepart && !isReturn && !isBeforeDepart && !iOS && !isOneWay,
          'arrow-left-hover':
            !isDepart &&
            !isReturn &&
            !isBeforeDepart &&
            !iOS &&
            !isOneWay &&
            !!day.amount,
          pointer: !isBeforeDepart && !isOneWay,
          'ract-hover': !!day.sameDaySelected && !isOneWay,
          'date-box-no-hover': isBeforeDepart || isOneWay,
        }),
    // set class to fill date box
    ...(isDepart && isReturn
      ? {
          'ract-fill': true,
        }
      : isDepart
      ? {
          'arrow-right-fill': true,
          ...(isReturnDateUtil({ date: addDays(day.date, 1) } as any, returnDay)
            ? {
                'next-day-fill-between': true,
              }
            : {}),
        }
      : isReturn
      ? { 'arrow-left-fill': true }
      : {}),
  };
}

export function isOneWayWithData(
  day,
  departDay,
  isOneWay,
  iOS,
): Record<string, boolean> {
  const isDepart = isDepartDateUtil(day, departDay);

  return {
    ...(!!day.amount
      ? {
          pointer: true,
          'disable-click': false,
        }
      : {}),
    ...(departDay == null || isOneWay
      ? {
          'arrow-right-focus': !iOS,
          'arrow-right-hover': !iOS && !!day.amount,
        }
      : {
          'ract-hover': !!day.sameDaySelected,
        }),
    // set class to fill date box
    ...(isDepart && isOneWay
      ? {
          'arrow-right-fill': true,
          'ract-fill': true,
        }
      : {}),
  };
}

export function isBlanksBetweenDates(
  day,
  departDay,
  returnDay,
  month,
  year,
): Record<string, boolean> {
  const departMonth = departDay.date.getMonth();
  const returnMonth = returnDay.date.getMonth();
  const departYear = getYear(departDay.date);
  const returnYear = getYear(returnDay.date);

  return departYear === returnYear
    ? {
        'ract-fill-in-range':
          day.dateOfMonth === 0
            ? departMonth < month && returnMonth >= month
            : departMonth <= month && returnMonth > month,
      }
    : departYear === year
    ? {
        'ract-fill-in-range':
          day.dateOfMonth === 0 ? departMonth < month : departMonth <= month,
      }
    : departYear < year && year < returnYear
    ? {
        'ract-fill-in-range': true,
      }
    : returnYear === year
    ? {
        'ract-fill-in-range':
          day.dateOfMonth === 0 ? returnMonth >= month : returnMonth > month,
      }
    : {};
}

export function isBetweenDates(): Record<string, boolean> {
  return {
    'ract-fill-in-range': true,
    'arrow-left-in-range': true,
  };
}

export function getCalendarContainerId(year: number, month: number): string {
  return `bff-calendar-${year}-${month}`;
}

export function nextMonth({ month, year }: MonthAndYear): MonthAndYear {
  return {
    month: (month + 1) % 12,
    year: year + Math.floor((month + 1) / 12),
  };
}
