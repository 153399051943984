import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CalendarModule } from '../../calendar/calendar.module';
import { PipesModule } from '../../ui';
import { IntersectionObserverModule } from './../intersection-observer/intersection-observer.module';
import { CalendarAggregatorComponent } from './calendar-aggregator.component';

const MODULES = [
  CommonModule,
  PipesModule,
  CalendarModule,
  IntersectionObserverModule,
];

const DECLARATIONS = [CalendarAggregatorComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class CalendarAggregatorModule {}
