import {
  AfterViewInit,
  Component,
  DestroyRef,
  Inject,
  inject,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd } from '@angular/router';
import { JbCampaign } from '@core/ab-testing/abtest-campaign.type';
import { AbtestConfigProviderService } from '@core/ab-testing/abtest-config-provider.service';
import { AbtestExperienceGeneratorService } from '@core/ab-testing/abtest-experience-generator.service';
import { AppConfigService } from '@core/app-config';
import { DeviceDetectionService } from '@core/device-detection/device-detection.service';
import { IsMobileAppService } from '@core/is-mobile-app';
import { LanguageService } from '@core/language/language.service';
import { delayJavascript } from '@core/widgets/utils';
import { HeaderService } from '@shared/header/header.service';
import { JBABTestingDialogComponent } from '@shared/jb-ab-testing-dialog/jb-ab-testing-dialog.component';
import { SITEMAP_DICTIONARY } from '@shared/ui/links/linkers/sitemap-dictionary.token';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { AnalyticsFacade } from '@store/analytics/analytics.facade';
import { GlobalEffects } from '@store/global/global.effects';
import { GlobalEffectsFooter } from '@store/global/partials/global.effects.footer';
import { PageData } from '@store/global/types';
import { JbDialogService } from 'jb-component-library';
import { combineLatest, iif, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { baseRouteDictionary } from './app.routes';
import {
  AnalyticsClickTrackingService,
  EventsService,
  WINDOW,
  WindowService,
} from './core';
import { AnalyticsService } from './core/analytics';
import { RouterService } from './core/router';
import { WidgetService } from './core/widgets';
import { HeaderComponent } from './shared';
import { AuthFacade } from './store/auth/auth.facade';
import { GlobalFacade } from './store/global/global.facade';
import { RouterFacade } from './store/router/router.facade';

/**
 * @description Bootstrapped component and container for the entire application
 * @WhereIsItUsed top level of the html tree
 */
@Component({
  selector: 'jb-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  private _destroy = inject(DestroyRef);

  private hardCodedPages: Set<string>;
  isMobile: Observable<boolean> = this.windowService.isMobile;
  isBFFPage: Observable<boolean> = this.routerFacade.isBffPage;
  displayBackToTop = false;
  isNavigating = false;
  baseTemplate = 'base';
  pageData$: Observable<PageData>;
  isCountdownClock: Observable<boolean> = this.globalFacade.isCountdownClock;
  showHeader$: Observable<boolean>;
  headerRequestFinished$: Observable<boolean>;
  showFooter$: Observable<boolean>;
  isModalOpen$ = this.headerService.isModalOpen$;
  hideSuperGlobalNav$: Observable<boolean>;
  showHeaderSkeleton$: Observable<boolean>;
  pageCampaigns: JbCampaign[] = [];

  isCmsFailed$: Observable<boolean>;

  @ViewChild('headerComponent', { static: true })
  headerComponent: HeaderComponent;

  constructor(
    private routerService: RouterService,
    private routerFacade: RouterFacade,
    private globalFacade: GlobalFacade,
    private globalEffects: GlobalEffects,
    private globalEffectsFooter: GlobalEffectsFooter,
    private windowService: WindowService,
    @Inject(WINDOW) private window,
    private widgetService: WidgetService,
    private analyticsClickTracking: AnalyticsClickTrackingService,
    private analyticsService: AnalyticsService,
    private authFacade: AuthFacade,
    private eventsService: EventsService,
    private deviceDetectionService: DeviceDetectionService,
    private isMobileAppService: IsMobileAppService,
    private analyticsFacade: AnalyticsFacade,
    public appConfig: AppConfigService,
    private headerService: HeaderService,
    @Inject(SITEMAP_DICTIONARY) private sitemap,
    private dialogService: JbDialogService,
    private abtestConfigProviderService: AbtestConfigProviderService,
    private abtestExperienceGeneratorService: AbtestExperienceGeneratorService,
    private languageService: LanguageService,
  ) {
    this.hardCodedPages = new Set(Object.values(baseRouteDictionary));
    // route map is a special page, in that its a hard coded route in app routes but that its dyanamic and
    //  receives its settings from the cms page data. To allow cms to set header/footer and not default to
    // hard coded pages settings need to remove from hard coded pages.
    this.hardCodedPages.delete('route-map');

    // if browser url directory forwarding is off and url has /fr we need to redirect to without /fr
    if (
      !this.appConfig.useLanguageInUrl(this.languageService.getLanguage()) &&
      !this.appConfig.useExperimentalTranslation &&
      this.window.location.pathname.includes('/fr')
    ) {
      // remover /fr from the start of the pathName and redirect
      this.window.location.pathname = this.window.location.pathname.replace(
        '/fr',
        '',
      );
    }

    // To Support Testing of 18N-next release: allows for prod smoke testing
    this.createExperimentalTranslationConsoleCommand();
    this.createExperimentalTranslationv2ConsoleCommand();
    this.createExperimentalTranslationUseLanguageInUrlConsoleCommand();

    this.deviceDetectionService.updateViewportMetaTag();

    this.isCmsFailed$ = this.globalFacade.isCmsFailed;

    this.routerService.isNavigatingToTemplate
      .pipe(takeUntilDestroyed())
      .subscribe(({ navigating, baseTemplate }) => {
        this.isNavigating = navigating;
        this.baseTemplate = baseTemplate;
        if (navigating) {
          this.analyticsFacade.contentLoadStartEventCall();
        }
      });

    // for reuse
    // data comes from cms template see requestTemplate
    this.pageData$ = this.globalFacade.pageData.pipe(filter(data => !!data));

    this.eventsService
      .subscribeToEvent(
        EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT,
      )
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.authFacade.authorizeUser();
      });

    const isMobileApp$ = this.isMobileAppService.getIsMobileApp();

    this.headerRequestFinished$ = this.globalEffects.requestHeaderSuccess$.pipe(
      map(() => true),
      startWith(false),
      distinctUntilChanged(),
    );

    /**
     * Wait for url of route to resolve and check if current page is one of the hard coded pages.
     * Hard coded pages don't receive pageData, so in that case the header will resolve to true.
     * Otherwise dynamic page data will determine if page can show header.
     */
    const pageDataAllowsHeader$ = this.routerFacade.url.pipe(
      filter(Boolean),
      // return url without the trailed hash values
      // hash value could be present for anchor navigation or some fancy in page navigation
      map(url => url.split('#')[0]),
      switchMap(url =>
        iif(
          // show header if url is not in sitemap
          () => this.isNotInSitemap(url),
          of(true),
          this.pageData$.pipe(
            map(pageData => !booleanStringCheck(pageData.hideHeader)), // not hide header
          ),
        ),
      ),
    );

    /**
     * Boolean observable for if all flags that determine if header can be shown on page
     * have resolved to true
     */
    const canShowHeaderOnPage$ = combineLatest([
      pageDataAllowsHeader$,
      isMobileApp$.pipe(map(value => !value)), // not isMobileApp
      this.routerFacade.isLogOutPage.pipe(map(value => !value)), // not logout page
    ]).pipe(
      takeUntilDestroyed(),
      map(arr => {
        return arr.every(value => !!value); // check if every value is true
      }),
    );

    /**
     * Show header if page allows it and header request finished.
     */
    this.showHeader$ = combineLatest([
      canShowHeaderOnPage$,
      this.headerRequestFinished$,
    ]).pipe(map(arr => arr.every(value => !!value)));

    this.showHeader$
      .pipe(
        takeUntilDestroyed(),
        filter(val => !!val),
      )
      .subscribe(_ => {
        this.headerService.showHeader();
      });

    /**
     * show header skeleton when page allows it and header
     * request is loading;
     */
    this.showHeaderSkeleton$ = combineLatest([
      canShowHeaderOnPage$,
      // map to true when header request not finished
      this.headerRequestFinished$.pipe(map(value => !value)),
    ]).pipe(map(arr => arr.every(value => !!value)));

    this.hideSuperGlobalNav$ = this.pageData$.pipe(
      takeUntilDestroyed(),
      map(pageData => booleanStringCheck(pageData.hideSuperGlobalNav)),
      startWith(false),
    );

    /**
     * Wait for url of route to resolve and check if current page is one of the hard coded pages.
     * Hard coded pages don't receive pageData, so in that case the footer will resolve to true.
     * Otherwise dynamic page data will determine if page can show footer.
     */
    const pageDataAllowsFooter$ = this.routerFacade.url.pipe(
      filter(Boolean),
      // return url without the trailed hash values
      // hash value could be present for anchor navigation or some fancy in page navigation
      map(url => url.split('#')[0]),
      switchMap(url =>
        iif(
          // show footer if its a hard coded page or page not found in sitemap
          () => this.isNotInSitemap(url),
          of(true),
          this.pageData$.pipe(
            map(pageData => !booleanStringCheck(pageData.hideFooter)),
          ),
        ),
      ),
    );

    const canShowFooterOnPage$ = combineLatest([
      pageDataAllowsFooter$,
      isMobileApp$.pipe(map(value => !value)), // not isMobileApp
      this.routerFacade.isLogOutPage.pipe(map(value => !value)), // not logout page
    ]).pipe(
      takeUntilDestroyed(),
      map(arr => {
        return arr.every(value => !!value); // check if every value is true
      }),
    );

    const footerRequestFinished$ =
      this.globalEffectsFooter.requestFooterSuccess$.pipe(
        map(() => true),
        startWith(false),
        distinctUntilChanged(),
      );

    this.showFooter$ = combineLatest([
      canShowFooterOnPage$,
      footerRequestFinished$,
    ]).pipe(map(arr => arr.every(value => !!value)));
  }

  ngAfterViewInit() {
    // Get All the experiences (AB Test)
    const allCampaigns = this.abtestConfigProviderService.getConfig();

    // Get the experience (AB Test) for the selected page.
    this.routerService.router.events
      .pipe(
        takeUntilDestroyed(this._destroy),
        filter(
          event => event instanceof NavigationEnd && !this.appConfig.isProdEnv,
        ),
      )
      .subscribe(routerEvent => {
        // Clear AB Experience
        this.pageCampaigns = [];

        // Removing Query Params from this route.
        const cleanRoute = routerEvent['url'].split('?')[0];

        // Getting AbTestPath.
        const abTestPath = cleanRoute === '/' ? '/home' : `/home${cleanRoute}`;

        // Getting AbTests for selected Path.
        const routeCampaigns =
          this.abtestExperienceGeneratorService.getCurrentPageABTest(
            abTestPath,
            allCampaigns,
          );
        routeCampaigns.forEach(routeCampaign =>
          this.pageCampaigns.push(routeCampaign),
        );

        // get global ab tests
        const globalCampaigns =
          allCampaigns?.jbCampaign?.filter(cam =>
            cam.path.includes('global'),
          ) || [];
        globalCampaigns.forEach(globalCampaign =>
          this.pageCampaigns.push(globalCampaign),
        );
      });

    this.window.requestIdleCallback(() => {
      this.analyticsService.waitForConsentThenLoadScripts();
      this.analyticsClickTracking.initialize();
      this.widgetService.appendThirdPartyScripts();
      this.widgetService.appendSherpaWidget();
      if (this.appConfig.delayThirdPartyJS) {
        delayJavascript();
      }
    });
  }

  // moved here from pageDataAllowsHeader$
  isNotInSitemap(url: string) {
    // show header if its a hard coded page or page not found in sitemap
    // additionally handle cases where the url has a language param in the url, eg, /es/flight-status
    // in those cases, remove the language param from the url before checking if its a hard coded page
    // it also handles the case where useLanguageInUrl is true, but we are in english and the url
    // does not have a language param, eg, /flight-status
    if (this.appConfig.useLanguageInUrl(this.languageService.getLanguage())) {
      // update regex to account for /es or /fr
      const urlWithoutLangParam = url.replace(/^\/fr\/?|^\/es\/?/, '/');
      return (
        this.hardCodedPages.has(urlWithoutLangParam.slice(1)) ||
        !this.sitemap[urlWithoutLangParam]
      );
    }
    return this.hardCodedPages.has(url.slice(1)) || !this.sitemap[url];
  }

  /**
   * Testing Functions to be removed
   * @description
   * This method is called on app load, and creates a function that can be called
   * in the console (anywhere window exists) to trigger a switch over to
   * experimental translation.
   */
  createExperimentalTranslationConsoleCommand() {
    const useExperimentalTranslation =
      this.appConfig.getConfigValueFromLocalOrCMSSource(
        'i18n.useExperimentalTranslation',
      );

    if (
      !booleanStringCheck(useExperimentalTranslation) &&
      !this.window.enableExperimentalTranslation
    ) {
      const currentConfig = this.appConfig.prepareAndPickConfigSource();
      this.window.enableExperimentalTranslation = () => {
        const newConfig = {
          ...currentConfig,
          flags: {
            ...(currentConfig?.flags || {}),
            useExperimentalTranslation: true,
            apiUrlsForFrenchTranslation: 'googleSearchUrl',
          },
        };

        this.appConfig.getConfigUtilities().readFromLocalstorage(true);
        this.appConfig.syncConfigToLocalStorage(newConfig);
        this.window.location.reload();
      };
    }
  }

  /**
   * Testing Functions to be removed
   * @description
   * This method is called on app load, and creates a function that can be called
   * in the console (anywhere window exists) to trigger a switch over to
   * experimental translation and language in URL
   */
  createExperimentalTranslationv2ConsoleCommand() {
    const useExperimentalTranslation =
      this.appConfig.getConfigValueFromLocalOrCMSSource(
        'i18n.useExperimentalTranslation',
      );

    if (
      !booleanStringCheck(useExperimentalTranslation) &&
      !this.window.enableExperimentalTranslationUseLanguageInUrl
    ) {
      const currentConfig = this.appConfig.prepareAndPickConfigSource();
      this.window.enableExperimentalTranslationv2 = () => {
        const newConfig = {
          ...currentConfig,
          flags: {
            ...(currentConfig?.flags || {}),
            useExperimentalTranslation: true,
            apiUrlsForFrenchTranslation: 'googleSearchUrl',
            allowAllOrigins: true,
          },
        };

        this.appConfig.getConfigUtilities().readFromLocalstorage(true);
        this.appConfig.syncConfigToLocalStorage(newConfig);
        this.window.location.reload();
      };
    }
  }

  /**
   * Testing Functions to be removed
   * @description
   * This method is called on app load, and creates a function that can be called
   * in the console (anywhere window exists) to trigger a switch over to
   * experimental translation and language in URL
   */
  createExperimentalTranslationUseLanguageInUrlConsoleCommand() {
    const useExperimentalTranslation =
      this.appConfig.getConfigValueFromLocalOrCMSSource(
        'i18n.useExperimentalTranslation',
      );

    if (
      !booleanStringCheck(useExperimentalTranslation) &&
      this.appConfig.useLanguageInUrl(this.languageService.getLanguage()) &&
      !this.window.enableExperimentalTranslationUseLanguageInUrl
    ) {
      const currentConfig = this.appConfig.prepareAndPickConfigSource();
      this.window.enableExperimentalTranslationUseLanguageInUrl = () => {
        const newConfig = {
          ...currentConfig,
          flags: {
            ...(currentConfig?.flags || {}),
            useExperimentalTranslation: true,
            useLanguageInUrl: true,
            apiUrlsForFrenchTranslation: 'googleSearchUrl',
          },
        };

        this.appConfig.getConfigUtilities().readFromLocalstorage(true);
        this.appConfig.syncConfigToLocalStorage(newConfig);
        this.window.location.reload();
      };
    }
  }

  openABTestsDialog() {
    // Opening dialog and setting to constant to be subscribed to later
    const componentRef = this.dialogService.openDialog(
      JBABTestingDialogComponent,
    );

    // Sending data to the dialog component
    componentRef.handleData(this.pageCampaigns);
  }
}
