import { isPlatformServer } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { CONFIG_AUTH_IS_IDX_LOCALSTORAGE } from '@core/app-config/types/mocks';
import { WINDOW } from '@core/injection-tokens';
import { LocalStorageService } from '@core/storage';
import { OktaService } from '@store/auth/api/okta.service';
import { v4 as uuidv4 } from 'uuid';
/**
 * @description
 * Due to the limitations of OKTA, which does not use the Angular HTTP Module,
 * we are unable to intercept calls made from the OKTA SDK directly.
 * This interceptor plays a crucial role in enhancing the security layer.
 * While we have confidence in OKTA's security, Jetblue team has taken additional precautions by implementing this interceptor.
 */
@NgModule()
export class FetchInterceptor {
  constructor(
    private appConfigService: AppConfigService,
    private localStorageService: LocalStorageService,
    private oktaService: OktaService,
    @Inject(WINDOW) private window,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return;
    }
    // Enable/Disable Fetch interceptor by checking useFetchInterceptor Flag.
    if (this.appConfigService.useFetchInterceptor) {
      const { fetch: origFetch } = this.window;
      this.window.fetch = async (
        requestUrl: string,
        init: RequestInit,
      ): Promise<Response> => {
        // Detect if requestUrl is related to OKTA calls.
        if (this._isOktaEndpoint(requestUrl)) {
          // Handle OKTA calls based on our flag useProxy
          return this.appConfigService.useProxy
            ? await this._handleOktaProxyCalls(requestUrl, init, origFetch)
            : await this._handleOktaDefaultCalls(requestUrl, init, origFetch);
        }

        // Return default call for any endpoint not related to OKTA.
        return await origFetch(requestUrl, init);
      };
    }
  }

  /**
   * Detects if requestUrl is from OKTA.
   * @param {string} requestedUrl - Request URL
   * @returns {boolean}
   */
  private _isOktaEndpoint(requestedUrl: string): boolean {
    return requestedUrl.includes(
      this.appConfigService?.okta?.auth?.issuer?.split('/oauth2')?.[0],
    );
  }

  /**
   * Handle OKTA calls, redirecting it to our Proxy that is hosted in our Express Server.
   * Doc: @see{@link https://jetblueairways.atlassian.net/wiki/spaces/ARF/pages/edit-v2/239305021?draftShareId=95b1edc5-7eb8-48ec-b6b5-273fa72bb6fe}
   * @param {string} requestedUrl - Request URL
   * @param {RequestInit} init - The request config with header, body and etc..
   * @param {any} origFetch - The window.fetch.
   * @returns {Promise<Response>} - Returns the override request as a promise.
   */
  private async _handleOktaProxyCalls(
    requestedUrl: string,
    init: RequestInit,
    origFetch,
  ): Promise<Response> {
    const shouldUseIdx: boolean =
      this.localStorageService.getItem(CONFIG_AUTH_IS_IDX_LOCALSTORAGE) ||
      this.oktaService.shouldUseIdx;
    const xOktaUsername = this._getOktaUsernameFromRequestHeader(init);
    /**
     * The following line verifies whether the requested URL includes any endpoints configured in the proxyIntercepts.
     * If this condition holds true, it will get the Jetblue proxy endpoint as the result.
     * */
    const shouldIntercept: boolean = this.appConfigService.proxyIntercepts.some(
      interceptPath => requestedUrl.split('/').includes(interceptPath),
    );
    // Intercept the call if shouldIntercept is true.
    if (shouldIntercept) {
      return await origFetch(
        ...[
          shouldUseIdx ? '/idx' : '/authn',
          {
            ...init,
            headers: {
              ...init.headers,
              ...(xOktaUsername ? { 'x-okta-username': xOktaUsername } : {}),
              'x-dotcom-request-id': uuidv4(),
              'okta-url': requestedUrl,
              'okta-method': init.method,
            },
            /**
             * Fastly does not like DELETE method calls between DOTCOM and our server.
             * In such instances, if the method is 'DELETE', we are going to make a 'POST' request.
             * REF: DOT-11019
             */
            method: init.method === 'DELETE' ? 'POST' : init.method,
          },
        ],
      );
    }
    return await origFetch(...[requestedUrl, init]);
  }

  /**
   * Handles OKTA requests and adds a header named x-okta-username in case the username is present in the OKTA call.
   * REF: DOT-11101
   * @param {string} requestedUrl - Request URL
   * @param {RequestInit} init - The request config with header, body and etc..
   * @param {any} origFetch - The window.fetch.
   * @returns {Promise<Response>} - Returns the override request as a promise.
   */
  private async _handleOktaDefaultCalls(
    requestUrl: string,
    init: RequestInit,
    origFetch,
  ): Promise<Response> {
    const xOktaUsername = this._getOktaUsernameFromRequestHeader(init);
    return await origFetch(
      ...[
        requestUrl,
        {
          ...init,
          headers: {
            ...init.headers,
            ...(xOktaUsername ? { 'x-okta-username': xOktaUsername } : {}),
          },
        },
      ],
    );
  }

  /**
   * Get and return the username from OKTA request.
   * REF: DOT-11101
   * @param {RequestInit} init - The request config with header, body and etc..
   * @returns {string|null} - Returns the username or null
   */
  private _getOktaUsernameFromRequestHeader(init: RequestInit): string {
    try {
      const parsedBody = init.body ? JSON.parse(init.body as any) : null;
      return parsedBody?.identifier || parsedBody?.username;
    } catch (error) {
      return null;
    }
  }
}
