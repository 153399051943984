import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { pick } from 'ramda';

import { FormNode } from './types/form-node.type';

/**
 * Performs a depth-first search for all errors and returns their custom error message strings in an
 * array.
 *
 * @param root          The FormGroup or FormControl.
 * @param errorConfig   Config to shape the messages.
 * @returns {tring}
 */

export function retrieveAllErrorMessages(
  root: AbstractControl,
  errorConfig: any,
): string[] {
  const nodeList: FormNode[] = [
    {
      control: root,
      errorConfig,
    },
  ];
  let errorMessages = [];

  while (nodeList.length > 0) {
    const node = nodeList.pop();

    if (node.control instanceof UntypedFormControl) {
      if (node.control.errors) {
        errorMessages = errorMessages.concat(
          getFormValidationErrors(node.control, node.errorConfig),
        );
      }
    } else if (node.control instanceof UntypedFormArray) {
      for (const value of node.control.controls) {
        nodeList.push({
          control: value,
          errorConfig: node.errorConfig,
        });
      }
    } else {
      const group = node.control as UntypedFormGroup;
      if (node.control.errors) {
        errorMessages = errorMessages.concat(
          getFormValidationErrors(node.control, node.errorConfig),
        );
      }

      for (const key in group.controls) {
        if (group.controls.hasOwnProperty(key)) {
          nodeList.push({
            control: group.controls[key],
            errorConfig: node.errorConfig,
          });
        }
      }
    }
  }
  return errorMessages;
}

function getFormValidationErrors(control, errorConfig): string[] {
  const filterErrors = [];
  // get only dateInvalid errors and not maxDateError & minDateError
  if (control.errors.hasOwnProperty('dateInvalid')) {
    control.errors = pick(['dateInvalid'], control.errors);
  }
  Object.keys(errorConfig).forEach(key => {
    if (control.errors.hasOwnProperty(key)) {
      filterErrors.push(control.errors[key]);
    }
  });
  return filterErrors;
}
