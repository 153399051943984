import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@core/injection-tokens';

import { AppConfigService } from '../app-config/app-config.service';

declare let JBFELoggerSetup: Function;
declare let logOtherEvent: Function;

@Injectable({
  providedIn: 'root',
})
export class FrontEndLoggerService {
  private renderer: Renderer2;
  private loggerLoaded = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appConfigService: AppConfigService,
    private rendererFactory: RendererFactory2,
  ) {
    this.init();
  }

  init() {
    if (
      this.appConfigService.isFeLoggerEnabled &&
      this.appConfigService.feLoggerUrl
    ) {
      this.renderer = this.rendererFactory.createRenderer(null, null);

      this.setupLogger();
    } else if (!this.appConfigService.isFeLoggerEnabled) {
      console.log('Logger service: configured off');
    } else if (!this.appConfigService.feLoggerUrl) {
      console.log('Logger service: logger not provided');
    }
  }

  loadLogger(
    src: string,
  ): Promise<{ src: string; loaded: boolean; status: string; error? }> {
    const script = this.renderer.createElement('script');
    script.src = src;
    this.renderer.appendChild(this.document.head, script);
    return new Promise((resolve, reject) => {
      script.onload = () => {
        resolve({
          src,
          loaded: true,
          status: 'Loaded',
        });
      };
      script.onerror = (error: any) =>
        reject({
          src,
          loaded: false,
          status: 'Error',
          error,
        });
    });
  }

  setupLogger() {
    this.loadLogger(this.appConfigService.feLoggerUrl).then(
      () => {
        this.loggerLoaded = true;
        // see https://github.com/jetblueairways/cb-logger-frontend/tree/main for detail
        try {
          const logData = {
            loggerSettings: {
              captureError: true,
              capturePageLoad: true,
              captureClick: false,
              enableLoggingToService: true,
              batchMessages: true,
              enableConsoleLogs: !this.appConfigService.isProdEnv,
            },
            environment: this.appConfigService.environment,
          };
          JBFELoggerSetup(logData);
        } catch (error) {
          console.warn('FE Logger: Error in initializing logger', error);
        }
      },
      result => {
        console.warn(
          `FE Logger: Unable to load logger from ${result.src}`,
          result.error,
        );
      },
    );
  }

  logError(data) {
    if (!this.loggerLoaded) {
      return;
    }
    logOtherEvent('error', data);
  }

  logData(data) {
    if (!this.loggerLoaded) {
      return;
    }
    logOtherEvent('unknown', data);
  }
}
