import { Templates } from '@dynamic-templates/types/templates.type';

export const TEMPLATE_CMS_VERSION: Templates = {
  main: 'v4',
  promo: 'v4',
  info: 'v4',
  overview: 'v4',
  article: 'v4',
  simple: 'v4',
  list: 'v3',
  signin: 'v4',
  empty: 'v4',
  faresale: 'v4',
  generic: 'v4',
  // @ts-ignore
  preference: 'v4',
};
