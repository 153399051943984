import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jbStringToNumber',
})
export class StringToNumberPipe implements PipeTransform {
  transform(numString: string): number {
    if (!numString) {
      return NaN;
    }
    // Number('') returns 0
    if (numString.trim().length === 0) {
      return NaN;
    }
    return Number(numString);
  }
}
