import { LegalCopy } from '@dynamic-components/isolated-flight-block-container/flight-block.type';
import { Action } from '@ngrx/store';

export enum DealsActions {
  REQUEST_LEGAL_COPY = '[CMS API] Request Legal Copy',
  SET_LEGAL_COPY = '[CMS API] Set Legal Copy',
}

export class RequestLegalCopy implements Action {
  readonly type = DealsActions.REQUEST_LEGAL_COPY;
}

export class SetLegalCopy implements Action {
  readonly type = DealsActions.SET_LEGAL_COPY;
  constructor(public payload: LegalCopy) {}
}

export type DealsConfigActionsUnion = RequestLegalCopy | SetLegalCopy;
