import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { IsMobileAppService } from '@core/is-mobile-app';
import { DotOktaHeaderComponent } from '@shared/dot-okta-header';
import { TrueblueResponse } from '@shared/trueblue/types';
import { JbButtonModule, JbIconModule } from 'jb-component-library';
import { Observable } from 'rxjs';
// default svg the fallback on for background
const defaultBg =
  'data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox%3D%220 0 578 578%22 width%3D%22578px%22 height%3D%22578px%22%3E%3Cstyle%3E.st2%7Bfill%3A%23233d94%7D.st3%7Bfill%3A%2304b2e2%7D.st4%7Bfill%3A%2390c6e6%7D.st5%7Bfill%3A%230087d7%7D%3C%2Fstyle%3E%3Cpath fill%3D%22%2300205b%22 d%3D%22M0 0h578v578H0z%22%2F%3E%3Cg opacity%3D%22.08%22%3E%3Cg%3E%3Cg transform%3D%22translate(-353 -164)%22%3E%3Cg transform%3D%22translate(.163)%22%3E%3Cpath class%3D%22st2%22 d%3D%22M352.8 549.1h192.7v-193H352.8z%22%2F%3E%3Cpath class%3D%22st3%22 d%3D%22M352.8 357.1h192.7V164H352.8z%22%2F%3E%3Cpath class%3D%22st4%22 d%3D%22M545.4 549.1h192.7v-193H545.4z%22%2F%3E%3Cpath class%3D%22st5%22 d%3D%22M738.1 164L545.4 357.1h192.7z%22%2F%3E%3Cpath class%3D%22st5%22 d%3D%22M545.5 742H352.8l192.7-193.1z%22%2F%3E%3Cpath  class%3D%22st2%22 d%3D%22M545.4 742h192.7V548.9H545.4z%22%2F%3E%3Cpath  class%3D%22st3%22 d%3D%22M545.4 742V548.9h192.7z%22%2F%3E%3Cpath  class%3D%22st2%22 d%3D%22M352.8 164v193.1h192.7z%22%2F%3E%3Cpath  class%3D%22st5%22 d%3D%22M545.5 549.1H352.8v-193z%22%2F%3E%3Cpath  class%3D%22st5%22 d%3D%22M738.1 549.1h192.7v-193H738.1z%22%2F%3E%3Cpath  class%3D%22st2%22 d%3D%22M930.8 549.1H738.1l192.7-193z%22%2F%3E%3Cpath  class%3D%22st4%22 d%3D%22M738.1 164v193.1h192.7z%22%2F%3E%3Cpath  class%3D%22st3%22 d%3D%22M738.1 742h192.7V548.9H738.1z%22%2F%3E%3Cpath class%3D%22st4%22 d%3D%22M930.8 742L738.1 548.9h192.7z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
const cssPropertyName = '--oktaContainerBg';

/**
 * @description Formatted page component with header and trace for okta content injection
 * @WhereIsItUsed
 * - siginin-new-password
 * - signin-reset
 * - sign-in-page
 * - forgot-password-skeleton
 * - signin-skeleton
 */
@Component({
  selector: 'dot-okta-container',
  templateUrl: './dot-okta-container.component.html',
  styleUrls: ['./dot-okta-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DotOktaHeaderComponent, JbIconModule, JbButtonModule],
  host: {
    class: 'db min-vh-100 pb5-ns',
  },
})
export class DotOktaContainerComponent {
  isNativeApp$: Observable<boolean> = this.isMobileAppService.getIsMobileApp();
  useWidget: boolean;
  // isOktaWidget is used for conditional rendering and styling
  // in the dot-okta-container-component.html template
  @Input() isOktaWidget: { loading: boolean };
  @Input() trueBlueResponse: TrueblueResponse;
  @Input() isTacAccept: boolean;
  // will override the default bg image set in the scss file
  @Input('bgImage')
  set bgImage(url) {
    if (!url) {
      url = defaultBg;
    }
    // setter needed to wrap the url in the required characters to set to background image
    url = `url('${url}')`;
    // can't do host binding cause need the style to be apart of a media query. Assigning to
    // host binding overrides the media query
    this.elRef.nativeElement.style.setProperty(cssPropertyName, url);
  }

  @Input('logo') logo: { src: string; alt?: string };

  constructor(
    private isMobileAppService: IsMobileAppService,
    private elRef: ElementRef,
    public appConfigService: AppConfigService,
  ) {
    // default bg if none is ever set
    this.elRef.nativeElement.style.setProperty(
      cssPropertyName,
      `url('${defaultBg}')`,
    );
    this.useWidget = this.appConfigService.useWidget;
  }
}
