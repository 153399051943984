<jb-dialog
      dialogType="content"
      contentModalWidth="80%"
    >
      <jb-dialog-header>
      </jb-dialog-header>
      <jb-dialog-content>
        <ngx-json-viewer class="legal" [json]="dotDebugContent" [depth]="3"></ngx-json-viewer>
      </jb-dialog-content>
      <jb-dialog-actions>
        <button jbButton theme="ctaPrimary" (click)="closeDialog()">
          Continue
        </button>
      </jb-dialog-actions>
  </jb-dialog>
