<div class="pb0 pl0 ma0" *ngIf="!!sections && sections.length">
  <ng-container *ngFor="let section of sections; let i = index">
    <jb-expansion-panel
      [isAlone]="true"
      class="fix-touch-scroll"
      *ngIf="!!section['section']"
      class="jb-accordion-new"
    >
      <jb-expansion-panel-header [isPrimary]="true" class="expansion-title">
        {{ section?.link?.title }}
      </jb-expansion-panel-header>
      <jb-expansion-panel-row [isPrimary]="true">
        <jb-subnav-wrapper
          class="klarheit"
          subnavWrapperId="{{ subnavWrapperId }}-{{ i }}"
          headerSize="small"
          [subNavSection]="section"
          [isLoggedIn]="isLoggedIn"
          [isMobile]="isMobile"
        >
        </jb-subnav-wrapper>
      </jb-expansion-panel-row>
    </jb-expansion-panel>
    <a
      *ngIf="!section['section']"
      [jbSitemapLinker]="section.link.href"
      class="db no-underline white pa3 subnav-mobile-section-link"
    >
      {{ section.link.title }}
    </a>
  </ng-container>
</div>
