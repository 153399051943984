<div
  class="jb-page-header bb b--medium-gray"
  *ngIf="headerContent$ | async as headerContent"
>
  <div class="page-margin">
    <div class="jb-max-width center">
      <jb-page-header
        *ngIf="headerContent"
        [pageHeaderData]="headerContent"
      ></jb-page-header>
    </div>
  </div>
</div>
<div class="mv6 mv8-ns center page-margin relative jb-max-width">
  <jb-text-image-container [content]="bodyTextImage$ | async">
  </jb-text-image-container>
</div>
