<div class="br3 bg-off-white pa3 flex flex-column">
  <!-- desktop -->
  <div class="flex justify-around flex-row-l flex-column mt5">
    <!-- city selectors -->
    <div class="flex-1 flex relative">
      <dot-text-block
        radius="0"
        bgColor="white"
        class="flex-1 b--medium-gray ba jb-input-height mb1 mb0-l mr1"
      ></dot-text-block>
      <div class="icon-swap"></div>
      <dot-text-block
        radius="0"
        bgColor="white"
        class="flex-1 b--medium-gray ba jb-input-height mb1 mb0-l mr1-l"
      ></dot-text-block>
    </div>
    <!-- date pickers -->
    <div class="flex-1 flex">
      <dot-text-block
        radius="0"
        bgColor="white"
        class="flex-1 b--medium-gray ba jb-input-height mb1 mb0-l mr1"
      ></dot-text-block>
      <dot-text-block
        radius="0"
        bgColor="white"
        class="flex-1 b--medium-gray ba jb-input-height mb1 mb0-l mr1-l"
      ></dot-text-block>
      <!-- search flight button desktop -->
      <div class="flex-1 search-flight-btn jb-input-height dn db-l"></div>
    </div>
    <!-- search flight button tablet -->
    <div class="search-flight-btn jb-input-height dn db-m mt3"></div>
    <!-- search flight button mobile  -->
    <div class="w-100 search-flight-btn jb-input-height mt8 dn-ns"></div>
  </div>
</div>
