import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IconCacheService } from './icon-cache.service';

/**
 * @description
 * Caches the results of the icon requests.
 * Urls are supplied by the IconCacheService.getIconUrls() method.
 * Background:
 * CL jb-icon dynamically loads icons if supplied with an url. This interceptor is used to cache the results.
 * Otherwise, http requests are made for each icon, which is not ideal.
 */

@Injectable()
export class IconCacheInterceptor implements HttpInterceptor {
  constructor(private iconCacheService: IconCacheService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const cache = this.iconCacheService.cache;

    // If method is not GET or icon is not cached, handle request normally
    if (request.method !== 'GET' || !(request.url in cache)) {
      return next.handle(request);
    }

    // If it's already cached, use the cached value (or an observable of it)
    if (cache[request.url]) {
      return cache[request.url].getValue()
        ? of(cache[request.url].getValue())
        : cache[request.url].asObservable();
    }

    // Requests can come in before the initial request is finished.
    // We are storing the request in a BehaviorSubject so that we can return the response when it comes in.
    this.iconCacheService.setCache(
      request.url,
      new BehaviorSubject<HttpResponse<unknown>>(null),
    );

    // first OG request
    return next.handle(request.clone()).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // resolve cached requests that were made before the initial request was finished
          cache[request.url].next(event);
        }
      }),
    );
  }
}
