export const mock = {
  JAX: {
    name: 'Jacksonville, FL (JAX)',
    code: 'JAX',
    needle: 'JAX,Jacksonville International Apt,Florida,Florida',
    jb: true,
  },
  SCQ: {
    name: 'Santiago de Compostela, Spain (SCQ)',
    code: 'SCQ',
    needle: 'SCQ,Santiago de Compostela,SPAIN',
    jb: false,
  },
  BRS: {
    name: 'Bristol, United Kingdom (BRS)',
    code: 'BRS',
    needle: 'BRS,Bristol,UNITED KINGDOM',
    jb: false,
  },
  MXP: {
    name: 'Milan - Malpensa (MXP)',
    code: 'MXP',
    needle: 'MXP,Milan Malpensa Apt,ITALY',
    jb: false,
  },
  BRU: {
    name: 'Brussels (BRU)',
    code: 'BRU',
    needle: 'BRU,Brussels Airport,BELGIUM',
    jb: false,
  },
  HNL: {
    name: 'Oahu-Honolulu, HI (HNL)',
    code: 'HNL',
    needle: 'HNL,Honolulu International Apt,Hawaii,Hawaii',
    jb: false,
  },
  CDG: {
    name: 'Paris - Charles de Gaulle (CDG)',
    code: 'CDG',
    needle: 'CDG,Paris Charles de Gaulle Apt,FRANCE',
    jb: false,
  },
  STI: {
    name: 'Santiago, DR (STI)',
    code: 'STI',
    needle: 'STI,Santiago,DOMINICAN REPUBLIC,Caribbean, republica dominicana',
    jb: true,
  },
  MHH: {
    name: 'Marsh Harbour, Bahamas (MHH)',
    code: 'MHH',
    needle: 'MHH,Marsh Harbour,BAHAMAS',
    jb: false,
  },
  STR: {
    name: 'Stuttgart, Germany (STR)',
    code: 'STR',
    needle: 'STR,Stuttgart Airport,GERMANY',
    jb: false,
  },
  ZRH: {
    name: 'Zurich (ZRH)',
    code: 'ZRH',
    needle: 'ZRH,Zurich Airport,SWITZERLAND',
    jb: false,
  },
  STT: {
    name: 'St. Thomas (STT)',
    code: 'STT',
    needle:
      'STT,St Thomas Cyril E King Apt,U.S. VIRGIN ISLANDS,st, saint, caribbean',
    jb: true,
  },
  PNS: {
    name: 'Pensacola, FL (PNS)',
    code: 'PNS',
    needle: 'PNS,Pensacola Municipal Apt,Florida,Florida',
    jb: false,
  },
  DFW: {
    name: 'Dallas/Fort Worth, TX (DFW)',
    code: 'DFW',
    needle: 'DFW,Dallas/Fort Worth Intl Apt,Texas,ft, fort, texas',
    jb: true,
  },
  STX: {
    name: 'St. Croix (STX)',
    code: 'STX',
    needle:
      'STX,St Croix Henry E. Rohlsen Apt,U.S. VIRGIN ISLANDS,st, saint, caribbean',
    jb: false,
  },
  CUN: {
    name: 'Cancun, Mexico (CUN)',
    code: 'CUN',
    needle: 'CUN,Cancun,MEXICO',
    jb: true,
  },
  SDQ: {
    name: 'Santo Domingo, DR (SDQ)',
    code: 'SDQ',
    needle:
      'SDQ,Santo Domingo Las Americas Apt,DOMINICAN REPUBLIC,Caribbean, republica dominicana',
    jb: true,
    pm: 'XDR',
  },
  HOG: {
    name: 'Holguin, Cuba (HOG)',
    code: 'HOG',
    needle: 'HOG,Holguin,CUBA,Caribbean',
    jb: true,
  },
  CUR: {
    name: 'Curacao, Curacao (CUR)',
    code: 'CUR',
    needle: 'CUR,Curacao,CURACAO,Caribbean',
    jb: true,
  },
  EYW: {
    name: 'Key West, FL (EYW)',
    code: 'EYW',
    needle: 'EYW,Key West International Apt,Florida,Florida',
    jb: false,
  },
  LGB: {
    name: 'Long Beach, CA (LGB)',
    code: 'LGB',
    needle: 'LGB,Long Beach Apt,California,California, Disneyland, disney land',
    jb: true,
    pm: 'ZLA',
  },
  LGA: {
    name: 'New York, NY (LGA)',
    code: 'LGA',
    needle: 'LGA,New York La Guardia Apt,New York,New York',
    jb: true,
    pm: 'NYC',
  },
  JST: {
    name: 'Johnstown, PA (JST)',
    code: 'JST',
    needle: 'JST,Johnstown,Pennsylvania,Pennsylvania',
    jb: false,
  },
  BCN: {
    name: 'Barcelona (BCN)',
    code: 'BCN',
    needle: 'BCN,Barcelona Apt,SPAIN',
    jb: false,
  },
  IAD: {
    name: 'Washington, DC (IAD)',
    code: 'IAD',
    needle:
      'IAD,Washington Dulles International Apt,District of Columbia,Maryland, Virginia ',
    jb: false,
    pm: 'WAS',
  },
  KEF: {
    name: 'Reykjavik (KEF)',
    code: 'KEF',
    needle: 'KEF,Reykjavik Keflavik International Apt,ICELAND',
    jb: false,
  },
  SEA: {
    name: 'Seattle, WA (SEA)',
    code: 'SEA',
    needle: 'SEA,Seattle/Tacoma International Apt,Washington,Washington',
    jb: true,
  },
  EIS: {
    name: 'Tortola, Beef Island (EIS)',
    code: 'EIS',
    needle: 'EIS,Beef Island,BRITISH VIRGIN ISLANDS',
    jb: false,
  },
  HOU: {
    name: 'Houston, TX (HOU)',
    code: 'HOU',
    needle: 'HOU,Houston Hobby Apt,Texas,Texas',
    jb: true,
  },
  DXB: {
    name: 'Dubai, United Arab Emirates (DXB)',
    code: 'DXB',
    needle: 'DXB,Dubai International,UNITED ARAB EMIRATES',
    jb: false,
  },
  POP: {
    name: 'Puerto Plata, DR (POP)',
    code: 'POP',
    needle:
      'POP,Puerto Plata,DOMINICAN REPUBLIC,Caribbean, republica dominicana',
    jb: true,
  },
  IAH: {
    name: 'Houston, TX (IAH)',
    code: 'IAH',
    needle: 'IAH,Houston George Bush Intercontinental Ap,Texas',
    jb: true,
  },
  POS: {
    name: 'Port of Spain, Trinidad and Tobago (POS)',
    code: 'POS',
    needle: 'POS,Port of Spain,TRINIDAD AND TOBAGO,Caribbean',
    jb: true,
  },
  RSW: {
    name: 'Fort Myers, FL (RSW)',
    code: 'RSW',
    needle: 'RSW,Fort Myers SW Florida International Apt,Florida,Florida, ft',
    jb: true,
  },
  UIO: {
    name: 'Quito, Ecuador (UIO)',
    code: 'UIO',
    needle: 'UIO,Quito,ECUADOR',
    jb: true,
  },
  GND: {
    name: 'Grenada (GND)',
    code: 'GND',
    needle: 'GND,Grenada,GRENADA,Caribbean',
    jb: true,
  },
  ARN: {
    name: 'Stockholm (ARN)',
    code: 'ARN',
    needle: 'ARN,Stockholm Arlanda Apt,SWEDEN',
    jb: false,
  },
  LGW: {
    name: 'London-Gatwick, United Kingdom (LGW)',
    code: 'LGW',
    needle: 'LGW,London Gatwick Apt,UNITED KINGDOM',
    jb: false,
  },
  BDA: {
    name: 'Bermuda (BDA)',
    code: 'BDA',
    needle: 'BDA,Bermuda L.F Wade International,BERMUDA,Bermuda, Caribbean',
    jb: true,
  },
  SVD: {
    name: 'St. Vincent, Saint Vincent and Grenadines (SVD)',
    code: 'SVD',
    needle:
      'SVD,St Vincent,SAINT VINCENT AND THE GRENADINES,st, saint, caribbean',
    jb: false,
  },
  BTV: {
    name: 'Burlington, VT (BTV)',
    code: 'BTV',
    needle: 'BTV,Burlington,Vermont,Vermont',
    jb: true,
  },
  SVG: {
    name: 'Stavanger, Norway (SVG)',
    code: 'SVG',
    needle: 'SVG,Stavanger,NORWAY',
    jb: false,
  },
  HPN: {
    name: 'Westchester County, NY (HPN)',
    code: 'HPN',
    needle: 'HPN,Westchester County,New York,New York, White Plains',
    jb: true,
    pm: 'NYC',
  },
  FLL: {
    name: 'Fort Lauderdale, FL (FLL)',
    code: 'FLL',
    needle: 'FLL,Fort Lauderdale/Hollywood Intl Apt,Florida,Florida, ft',
    jb: true,
    pm: 'XFL',
  },
  TXL: {
    name: 'Berlin - Tegel (TXL)',
    code: 'TXL',
    needle: 'TXL,Berlin Tegel Apt,GERMANY',
    jb: false,
  },
  BDL: {
    name: 'Hartford, CT (BDL)',
    code: 'BDL',
    needle: 'BDL,Hartford Bradley International Apt,Connecticut,Connecticut',
    jb: true,
  },
  ABQ: {
    name: 'Albuquerque/Santa Fe, NM (ABQ)',
    code: 'ABQ',
    needle: 'ABQ,Albuquerque,New Mexico,New Mexico',
    jb: true,
  },
  BUD: {
    name: 'Budapest (BUD)',
    code: 'BUD',
    needle: 'BUD,Budapest,HUNGARY',
    jb: false,
  },
  BUF: {
    name: 'Buffalo, NY (BUF)',
    code: 'BUF',
    needle: 'BUF,Buffalo,New York,New York',
    jb: true,
  },
  ONT: {
    name: 'Ontario, CA (ONT)',
    code: 'ONT',
    needle:
      'ONT,Ontario LA/Ontario International Apt,California,California, Disneyland, disney land',
    jb: true,
    pm: 'ZLA',
  },
  LHR: {
    name: 'London Heathrow (LHR)',
    code: 'LHR',
    needle: 'LHR,London Heathrow Apt,UNITED KINGDOM',
    jb: false,
  },
  CWL: {
    name: 'Cardiff, UK (CWL)',
    code: 'CWL',
    needle: 'CWL,Cardiff,UNITED KINGDOM',
    jb: false,
  },
  ISB: {
    name: 'Islamabad, Pakistan (ISB)',
    code: 'ISB',
    needle: 'ISB,Islamabad,PAKISTAN',
    jb: false,
  },
  ABZ: {
    name: 'Aberdeen, United Kingdom (ABZ)',
    code: 'ABZ',
    needle: 'ABZ,Aberdeen,UNITED KINGDOM',
    jb: false,
  },
  KWI: {
    name: 'Kuwait, Kuwait (KWI)',
    code: 'KWI',
    needle: 'KWI,Kuwait,KUWAIT',
    jb: false,
  },
  SFO: {
    name: 'San Francisco, CA (SFO)',
    code: 'SFO',
    needle: 'SFO,San Francisco International Apt,California,California',
    jb: true,
    pm: 'XSF',
  },
  BUR: {
    name: 'Burbank, CA (BUR)',
    code: 'BUR',
    needle: 'BUR,Burbank,California,California',
    jb: true,
    pm: 'ZLA',
  },
  ACC: {
    name: 'Accra, Ghana (ACC)',
    code: 'ACC',
    needle: 'ACC,Accra,GHANA',
    jb: false,
  },
  RDU: {
    name: 'Raleigh/Durham, NC (RDU)',
    code: 'RDU',
    needle: 'RDU,Raleigh/Durham,North Carolina,North Carolina',
    jb: true,
  },
  SWF: {
    name: 'Newburgh, NY (SWF)',
    code: 'SWF',
    needle: 'SWF,Newburgh,New York,New York',
    jb: true,
    pm: 'NYC',
  },
  ACE: {
    name: 'Lanzarote, Spain (ACE)',
    code: 'ACE',
    needle: 'ACE,Lanzarote,SPAIN',
    jb: false,
  },
  LYS: {
    name: 'Lyon, France (LYS)',
    code: 'LYS',
    needle: 'LYS,Lyon St-Exupery Apt,FRANCE',
    jb: false,
  },
  ACK: {
    name: 'Nantucket, MA (ACK)',
    code: 'ACK',
    needle: 'ACK,Nantucket,Massachusetts,Massachusetts',
    jb: true,
  },
  LIH: {
    name: 'Kauai-Lihue, HI (LIH)',
    code: 'LIH',
    needle: 'LIH,Kauai Island Lihue Municipal Apt,Hawaii,Hawaii',
    jb: false,
  },
  MKK: {
    name: 'Hoolehua/Molokai, HI (MKK)',
    code: 'MKK',
    needle: 'MKK,Hoolehua,Hawaii,Hawaii',
    jb: false,
  },
  GOT: {
    name: 'Gothenberg Landvetter, Sweden (GOT)',
    code: 'GOT',
    needle: 'GOT,Goteborg Landvetter Apt,SWEDEN',
    jb: false,
  },
  RUT: {
    name: 'Rutland, VT (RUT)',
    code: 'RUT',
    needle: 'RUT,Rutland,Vermont,Vermont',
    jb: false,
  },
  LIN: {
    name: 'Milan - Linate (LIN)',
    code: 'LIN',
    needle: 'LIN,Milan Linate Apt,ITALY',
    jb: false,
  },
  LIM: {
    name: 'Lima, Peru (LIM)',
    code: 'LIM',
    needle: 'LIM,Lima,PERU',
    jb: true,
  },
  LIR: {
    name: 'Liberia, Costa Rica (LIR)',
    code: 'LIR',
    needle: 'LIR,Liberia,COSTA RICA',
    jb: true,
  },
  HAM: {
    name: 'Hamburg, Germany (HAM)',
    code: 'HAM',
    needle: 'HAM,Hamburg Airport,GERMANY',
    jb: false,
  },
  ATH: {
    name: 'Athens, Greece (ATH)',
    code: 'ATH',
    needle: 'ATH,Athens,GREECE',
    jb: false,
  },
  JER: {
    name: 'Jersey, United Kingdom (JER)',
    code: 'JER',
    needle: 'JER,Jersey,UNITED KINGDOM',
    jb: false,
  },
  LIS: {
    name: 'Lisbon, Portugal (LIS)',
    code: 'LIS',
    needle: 'LIS,Lisbon,PORTUGAL',
    jb: false,
  },
  ATL: {
    name: 'Atlanta, GA (ATL)',
    code: 'ATL',
    needle: 'ATL,Atlanta Hartsfield-Jackson Intl Apt,Georgia,Georgia',
    jb: true,
  },
  HAV: {
    name: 'Havana, Cuba (HAV)',
    code: 'HAV',
    needle: 'HAV,Havana,CUBA,Caribbean',
    jb: true,
  },
  PAP: {
    name: 'Port-au-Prince, Haiti (PAP)',
    code: 'PAP',
    needle: 'PAP,Port au Prince,HAITI,Caribbean',
    jb: true,
  },
  ELH: {
    name: 'North Eleuthera, Bahamas (ELH)',
    code: 'ELH',
    needle: 'ELH,North Eleuthera,BAHAMAS',
    jb: false,
  },
  PRG: {
    name: 'Prague, Czech Republic (PRG)',
    code: 'PRG',
    needle: 'PRG,Prague,CZECH REPUBLIC',
    jb: false,
  },
  BFI: {
    name: 'Seattle/King Country, WA (BFI)',
    code: 'BFI',
    needle: 'BFI,Seattle Boeing Field,Washington',
    jb: false,
  },
  ITO: {
    name: 'Big Island-Hilo, HI (ITO)',
    code: 'ITO',
    needle: 'ITO,Hilo,Hawaii,Hawaii',
    jb: false,
  },
  SXM: {
    name: 'St. Maarten (SXM)',
    code: 'SXM',
    needle: 'SXM,St Maarten,SAINT MAARTEN,st, saint, caribbean',
    jb: true,
  },
  AUA: {
    name: 'Aruba (AUA)',
    code: 'AUA',
    needle: 'AUA,Aruba,ARUBA,Aruba, Caribbean',
    jb: true,
  },
  SHD: {
    name: 'Shenandoah/Staunton, VA (SHD)',
    code: 'SHD',
    needle: 'SHD,Staunton,Virginia,Virginia',
    jb: false,
  },
  JFK: {
    name: 'New York, NY (JFK)',
    code: 'JFK',
    needle: 'JFK,New York J F Kennedy International Apt,New York,New York',
    jb: true,
    pm: 'NYC',
  },
  CHS: {
    name: 'Charleston, SC (CHS)',
    code: 'CHS',
    needle: 'CHS,Charleston,South Carolina,South Carolina',
    jb: true,
  },
  AUG: {
    name: 'Augusta, ME (AUG)',
    code: 'AUG',
    needle: 'AUG,Augusta,Maine,Maine',
    jb: false,
  },
  BWI: {
    name: 'Baltimore, MD (BWI)',
    code: 'BWI',
    needle: 'BWI,Baltimore Washington International Apt,Maryland,Maryland',
    jb: true,
    pm: 'WAS',
  },
  PBI: {
    name: 'West Palm Beach, FL (PBI)',
    code: 'PBI',
    needle: 'PBI,West Palm Beach International Apt,Florida,Florida',
    jb: true,
    pm: 'XFL',
  },
  YTZ: {
    name: 'Toronto, Canada (YTZ)',
    code: 'YTZ',
    needle: 'YTZ,Toronto City Centre Apt,CANADA,Canada',
    jb: false,
  },
  AUS: {
    name: 'Austin, TX (AUS)',
    code: 'AUS',
    needle: 'AUS,Austin-Bergstrom International Apt,Texas,Texas',
    jb: true,
  },
  PSA: {
    name: 'Pisa (PSA)',
    code: 'PSA',
    needle: 'PSA,Pisa,ITALY',
    jb: false,
  },
  PSE: {
    name: 'Ponce, PR (PSE)',
    code: 'PSE',
    needle: 'PSE,Ponce,PUERTO RICO,Puerto Rico',
    jb: true,
  },
  XBO: {
    name: 'Boston area',
    code: 'XBO',
    needle:
      'XBO,Boston area,Massachusetts,Massachusetts ORH Worcester, MA (ORH) PVD Providence, RI (PVD) BOS Boston, MA (BOS)',
    jb: true,
    cc: ['ORH', 'PVD', 'BOS'],
  },
  BGI: {
    name: 'Bridgetown, Barbados (BGI)',
    code: 'BGI',
    needle: 'BGI,Barbados,BARBADOS,Barbados, Caribbean',
    jb: true,
  },
  MMH: {
    name: 'Mammoth Lakes, CA (MMH)',
    code: 'MMH',
    needle: 'MMH,Mammoth Lakes,California,California',
    jb: false,
  },
  HSV: {
    name: 'Huntsville International Airport, AL (HSV)',
    code: 'HSV',
    needle: 'HSV,Huntsville International Airport,Alabama',
    jb: false,
  },
  XSF: {
    name: 'San Francisco area',
    code: 'XSF',
    needle:
      'XSF,San Francisco area,California,California SFO San Francisco, CA (SFO) OAK Oakland, CA (OAK) SJC San Jose, CA (SJC)',
    jb: true,
    cc: ['SFO', 'OAK', 'SJC'],
  },
  BGO: {
    name: 'Bergen, Norway (BGO)',
    code: 'BGO',
    needle: 'BGO,Bergen,NORWAY',
    jb: false,
  },
  PSP: {
    name: 'Palm Springs, CA (PSP)',
    code: 'PSP',
    needle: 'PSP,Palm Springs Municipal Apt,California,California',
    jb: true,
  },
  SYR: {
    name: 'Syracuse, NY (SYR)',
    code: 'SYR',
    needle: 'SYR,Syracuse,New York,New York',
    jb: true,
  },
  KIN: {
    name: 'Kingston, Jamaica (KIN)',
    code: 'KIN',
    needle: 'KIN,Kingston Norman Manley Intl Apt,JAMAICA,Caribbean',
    jb: true,
  },
  KIR: {
    name: 'Kerry (KIR)',
    code: 'KIR',
    needle: 'KIR,Kerry,IRELAND',
    jb: false,
  },
  OAK: {
    name: 'Oakland, CA (OAK)',
    code: 'OAK',
    needle: 'OAK,Oakland International Apt,California,California',
    jb: true,
    pm: 'XSF',
  },
  BHB: {
    name: 'Bar Harbor, ME (BHB)',
    code: 'BHB',
    needle: 'BHB,Bar Harbor,Maine,Maine',
    jb: false,
  },
  BHD: {
    name: 'Belfast, UK (BHD)',
    code: 'BHD',
    needle: 'BHD,Belfast George Best City Apt,UNITED KINGDOM',
    jb: false,
  },
  ORD: {
    name: 'Chicago, IL (ORD)',
    code: 'ORD',
    needle: "ORD,Chicago O'Hare International Apt,Illinois,Illinois",
    jb: true,
  },
  ORH: {
    name: 'Worcester, MA (ORH)',
    code: 'ORH',
    needle: 'ORH,Worcester,Massachusetts,Massachusetts',
    jb: true,
    pm: 'XBO',
  },
  FPO: {
    name: 'Freeport, Bahamas (FPO)',
    code: 'FPO',
    needle: 'FPO,Freeport,BAHAMAS',
    jb: false,
  },
  WAS: {
    name: 'Washington DC area (WAS)',
    code: 'WAS',
    needle:
      'WAS,Washington DC area,District of Columbia,Maryland, Virginia  IAD Washington, DC (IAD) BWI Baltimore, MD (BWI) DCA Washington, DC (DCA)',
    jb: true,
    cc: ['IAD', 'BWI', 'DCA'],
  },
  ORK: {
    name: 'Cork, Ireland (ORK)',
    code: 'ORK',
    needle: 'ORK,Cork,IRELAND',
    jb: false,
  },
  WAW: {
    name: 'Warsaw, Poland (WAW)',
    code: 'WAW',
    needle: 'WAW,Warsaw,POLAND',
    jb: false,
  },
  SJC: {
    name: 'San Jose, CA (SJC)',
    code: 'SJC',
    needle: 'SJC,San Jose Norman Y. Mineta Intl,California,California',
    jb: true,
    pm: 'XSF',
  },
  PTP: {
    name: 'Pointe-à-Pitre, Guadeloupe (PTP)',
    code: 'PTP',
    needle: 'PTP,Pointe-a-Pitre,GUADELOUPE',
    jb: true,
  },
  TLH: {
    name: 'Tallahassee, FL (TLH)',
    code: 'TLH',
    needle: 'TLH,Tallahassee,Florida',
    jb: false,
  },
  HDN: {
    name: 'Steamboat Springs, CO (HDN)',
    code: 'HDN',
    needle: 'HDN,Hayden,Colorado,Colorado, steamboat springs',
    jb: true,
  },
  BHX: {
    name: 'Birmingham, United Kingdom (BHX)',
    code: 'BHX',
    needle: 'BHX,Birmingham Airport,UNITED KINGDOM',
    jb: false,
  },
  VPS: {
    name: 'Destin/Fort Walton Beach, FL (VPS)',
    code: 'VPS',
    needle: 'VPS,Valparaiso Fort Walton Beach Apt,Florida',
    jb: false,
  },
  SJO: {
    name: 'San Jose, Costa Rica (SJO)',
    code: 'SJO',
    needle: 'SJO,San Jose Juan Santamaria Apt,COSTA RICA',
    jb: true,
  },
  TLS: {
    name: 'Toulouse, France (TLS)',
    code: 'TLS',
    needle: 'TLS,Toulouse,FRANCE',
    jb: false,
  },
  SJU: {
    name: 'San Juan, PR (SJU)',
    code: 'SJU',
    needle:
      'SJU,San Juan Luis Munoz Marin Intl Apt,PUERTO RICO,Puerto Rico, Caribbean',
    jb: true,
  },
  PUJ: {
    name: 'Punta Cana, DR (PUJ)',
    code: 'PUJ',
    needle: 'PUJ,Punta Cana,DOMINICAN REPUBLIC,Caribbean, republica dominicana',
    jb: true,
  },
  PDX: {
    name: 'Portland, OR (PDX)',
    code: 'PDX',
    needle: 'PDX,Portland,Oregon,Oregon',
    jb: true,
  },
  BIM: {
    name: 'Bimini, Bahamas (BIM)',
    code: 'BIM',
    needle: 'BIM,Bimini International Apt,BAHAMAS',
    jb: false,
  },
  OSL: {
    name: 'Oslo, Norway (OSL)',
    code: 'OSL',
    needle: 'OSL,Oslo Gardermoen Airport,NORWAY',
    jb: false,
  },
  BIO: {
    name: 'Bilbao (BIO)',
    code: 'BIO',
    needle: 'BIO,Bilbao,SPAIN',
    jb: false,
  },
  AXA: {
    name: 'Anguilla - Wall Blake (AXA)',
    code: 'AXA',
    needle: 'AXA,Anguilla,ANGUILLA',
    jb: false,
  },
  AGP: {
    name: 'Malaga (AGP)',
    code: 'AGP',
    needle: 'AGP,Malaga,SPAIN',
    jb: false,
  },
  SKB: {
    name: 'St. Kitts, Saint Kitts and Nevis (SKB)',
    code: 'SKB',
    needle: 'SKB,St Kitts,SAINT KITTS AND NEVIS,st, saint, caribbean',
    jb: false,
  },
  RIC: {
    name: 'Richmond, VA (RIC)',
    code: 'RIC',
    needle: 'RIC,Richmond,Virginia,Virginia',
    jb: true,
  },
  HEL: {
    name: 'Helsinki (HEL)',
    code: 'HEL',
    needle: 'HEL,Helsinki,FINLAND',
    jb: false,
  },
  GCM: {
    name: 'Grand Cayman (GCM)',
    code: 'GCM',
    needle: 'GCM,Grand Cayman Island,CAYMAN ISLANDS,Caribbean',
    jb: true,
  },
  FRA: {
    name: 'Frankfurt (FRA)',
    code: 'FRA',
    needle: 'FRA,Frankfurt International Apt,GERMANY',
    jb: false,
  },
  FAO: {
    name: 'Faro, Portugal (FAO)',
    code: 'FAO',
    needle: 'FAO,Faro,PORTUGAL',
    jb: false,
  },
  PUY: {
    name: 'Pula, Croatia (PUY)',
    code: 'PUY',
    needle: 'PUY,Pula,CROATIA',
    jb: false,
  },
  BZN: {
    name: 'Bozeman, MT (BZN)',
    code: 'BZN',
    needle: 'BZN,Bozeman,Montana,Montana',
    jb: true,
  },
  VQS: {
    name: 'Vieques, PR (VQS)',
    code: 'VQS',
    needle: 'VQS,Vieques,PUERTO RICO,Puerto Rico, caribbean',
    jb: false,
  },
  PVC: {
    name: 'Provincetown, MA (PVC)',
    code: 'PVC',
    needle: 'PVC,Provincetown,Massachusetts,Massachusetts',
    jb: false,
  },
  NAP: {
    name: 'Naples, Italy (NAP)',
    code: 'NAP',
    needle: 'NAP,Naples Capodichino Apt,ITALY',
    jb: false,
  },
  CLE: {
    name: 'Cleveland, OH (CLE)',
    code: 'CLE',
    needle: 'CLE,Cleveland Hopkins International Apt,Ohio,Ohio',
    jb: true,
  },
  PVD: {
    name: 'Providence, RI (PVD)',
    code: 'PVD',
    needle: 'PVD,Providence,Rhode Island,Rhode Island',
    jb: true,
    pm: 'XBO',
  },
  NAS: {
    name: 'Nassau, Bahamas (NAS)',
    code: 'NAS',
    needle: 'NAS,Nassau International Apt,BAHAMAS,Caribbean',
    jb: true,
  },
  MPL: {
    name: 'Montpellier, France (MPL)',
    code: 'MPL',
    needle: 'MPL,Montpellier Mediterranee Apt,FRANCE',
    jb: false,
  },
  CLT: {
    name: 'Charlotte, NC (CLT)',
    code: 'CLT',
    needle: 'CLT,Charlotte,North Carolina,North Carolina',
    jb: true,
  },
  SLC: {
    name: 'Salt Lake City, UT (SLC)',
    code: 'SLC',
    needle: 'SLC,Salt Lake City,Utah,Utah',
    jb: true,
  },
  SLK: {
    name: 'Saranac Lake, NY (SLK)',
    code: 'SLK',
    needle: 'SLK,Saranac Lake,New York,New York',
    jb: false,
  },
  VRN: {
    name: 'Verona, Italy (VRN)',
    code: 'VRN',
    needle: 'VRN,Verona Villafranca Airport,ITALY',
    jb: false,
  },
  LNY: {
    name: 'Lanai, HI (LNY)',
    code: 'LNY',
    needle: 'LNY,Lanai City,Hawaii,Hawaii',
    jb: false,
  },
  SLU: {
    name: 'St. Lucia-Castries, Saint Lucia (SLU)',
    code: 'SLU',
    needle: 'SLU,St Lucia Vigie Apt,SAINT LUCIA',
    jb: false,
  },
  XFL: {
    name: 'Miami area',
    code: 'XFL',
    needle:
      'XFL,Miami area,Florida,South Florida FLL Fort Lauderdale, FL (FLL) PBI West Palm Beach, FL (PBI)',
    jb: true,
    cc: ['FLL', 'PBI'],
  },
  BKK: {
    name: 'Bangkok (BKK)',
    code: 'BKK',
    needle: 'BKK,Bangkok Suvarnabhumi International Apt,THAILAND',
    jb: false,
  },
  DOM: {
    name: 'Dominica, Dominica (DOM)',
    code: 'DOM',
    needle: 'DOM,Dominica Melville Hall Apt,DOMINICA',
    jb: false,
  },
  PWM: {
    name: 'Portland, ME (PWM)',
    code: 'PWM',
    needle: 'PWM,Portland,Maine',
    jb: true,
  },
  RKD: {
    name: 'Rockland, ME (RKD)',
    code: 'RKD',
    needle: 'RKD,Rockland,Maine,Maine',
    jb: false,
  },
  CMW: {
    name: 'Camaguey, Cuba (CMW)',
    code: 'CMW',
    needle: 'CMW,Camaguey,CUBA,Caribbean',
    jb: true,
  },
  SMF: {
    name: 'Sacramento, CA (SMF)',
    code: 'SMF',
    needle: 'SMF,Sacramento International Apt,California,California',
    jb: true,
  },
  PGF: {
    name: 'Perpignan, France (PGF)',
    code: 'PGF',
    needle: 'PGF,Perpignan,FRANCE',
    jb: false,
  },
  GEO: {
    name: 'Georgetown, Guyana (GEO)',
    code: 'GEO',
    needle: 'GEO,Georgetown,GUYANA',
    jb: true,
  },
  NCE: {
    name: 'Nice, France (NCE)',
    code: 'NCE',
    needle: 'NCE,Nice,FRANCE',
    jb: false,
  },
  MAD: {
    name: 'Madrid (MAD)',
    code: 'MAD',
    needle: 'MAD,Madrid Barajas Apt,SPAIN',
    jb: false,
  },
  GVA: {
    name: 'Geneva (GVA)',
    code: 'GVA',
    needle: 'GVA,Geneva,SWITZERLAND',
    jb: false,
  },
  FCO: {
    name: 'Rome (Fiumicino) (FCO)',
    code: 'FCO',
    needle: 'FCO,Rome Fiumicino Apt,ITALY',
    jb: false,
  },
  NCL: {
    name: 'Newcastle, United Kingdom (NCL)',
    code: 'NCL',
    needle: 'NCL,Newcastle,UNITED KINGDOM',
    jb: false,
  },
  VCE: {
    name: 'Venice, Italy (VCE)',
    code: 'VCE',
    needle: 'VCE,Venice Marco Polo Apt,ITALY',
    jb: false,
  },
  MAN: {
    name: 'Manchester (MAN)',
    code: 'MAN',
    needle: 'MAN,Manchester,UNITED KINGDOM',
    jb: false,
  },
  LPA: {
    name: 'Las Palmas, Spain (LPA)',
    code: 'LPA',
    needle: 'LPA,Las Palmas,SPAIN',
    jb: false,
  },
  BLL: {
    name: 'Billund, Denmark (BLL)',
    code: 'BLL',
    needle: 'BLL,Billund,DENMARK',
    jb: false,
  },
  TPA: {
    name: 'Tampa, FL (TPA)',
    code: 'TPA',
    needle: 'TPA,Tampa International Apt,Florida,Florida',
    jb: true,
  },
  BLQ: {
    name: 'Bologna, Italy (BLQ)',
    code: 'BLQ',
    needle: 'BLQ,Bologna,ITALY',
    jb: false,
  },
  SNA: {
    name: 'Orange County, CA (SNA)',
    code: 'SNA',
    needle: 'SNA,Santa Ana John Wayne Apt,California',
    jb: false,
  },
  MAZ: {
    name: 'Mayaguez, PR (MAZ)',
    code: 'MAZ',
    needle: 'MAZ,Mayaguez,PUERTO RICO,Puerto Rico',
    jb: false,
  },
  FDF: {
    name: 'Fort De France, Martinique (FDF)',
    code: 'FDF',
    needle: 'FDF,Fort de France,MARTINIQUE',
    jb: false,
  },
  ZLA: {
    name: 'Los Angeles area',
    code: 'ZLA',
    needle:
      'ZLA,Los Angeles area,California,California, Disneyland, disney land LGB Long Beach, CA (LGB) ONT Ontario, CA (ONT) BUR Burbank, CA (BUR) LAX Los Angeles, CA (LAX)',
    jb: true,
    cc: ['LGB', 'ONT', 'BUR', 'LAX'],
  },
  MRS: {
    name: 'Marseille (MRS)',
    code: 'MRS',
    needle: 'MRS,Marseille Provence Apt,FRANCE',
    jb: false,
  },
  HYA: {
    name: 'Hyannis, MA (HYA)',
    code: 'HYA',
    needle: 'HYA,Hyannis,Massachusetts,Massachusetts',
    jb: true,
  },
  SNN: {
    name: 'Limerick - Shannon (SNN)',
    code: 'SNN',
    needle: 'SNN,Shannon,IRELAND',
    jb: false,
  },
  MBJ: {
    name: 'Montego Bay, Jamaica (MBJ)',
    code: 'MBJ',
    needle: 'MBJ,Montego Bay,JAMAICA,Caribbean',
    jb: true,
  },
  PHL: {
    name: 'Philadelphia, PA (PHL)',
    code: 'PHL',
    needle:
      'PHL,Philadelphia International Apt,Pennsylvania,Pennsylvania, Atlantic City',
    jb: true,
  },
  SNU: {
    name: 'Santa Clara, Cuba (SNU)',
    code: 'SNU',
    needle: 'SNU,Santa Clara,CUBA,Caribbean',
    jb: true,
  },
  KOA: {
    name: 'Big Island-Kona, HI (KOA)',
    code: 'KOA',
    needle: 'KOA,Kona,Hawaii,Hawaii',
    jb: false,
  },
  PHX: {
    name: 'Phoenix, AZ (PHX)',
    code: 'PHX',
    needle:
      'PHX,Phoenix Sky Harbor Intl Apt,Arizona,Arizona, Grand Canyon, Scottsdale',
    jb: true,
  },
  DAB: {
    name: 'Daytona Beach, FL (DAB)',
    code: 'DAB',
    needle: 'DAB,Daytona Beach,Florida,Florida',
    jb: false,
  },
  MSP: {
    name: 'Minneapolis/St. Paul, MN (MSP)',
    code: 'MSP',
    needle: 'MSP,Minneapolis International Apt,Minnesota,Minnesota',
    jb: true,
  },
  OGG: {
    name: 'Maui-Kahului, HI (OGG)',
    code: 'OGG',
    needle: 'OGG,Kahului,Hawaii,Hawaii',
    jb: false,
  },
  MSY: {
    name: 'New Orleans, LA (MSY)',
    code: 'MSY',
    needle: 'MSY,New Orleans Louis Armstrong Intl Apt,Louisiana,Louisiana',
    jb: true,
  },
  ECP: {
    name: 'Panama City, FL (ECP)',
    code: 'ECP',
    needle: 'ECP,Panama City NW Florida Beaches Intl,Florida',
    jb: false,
  },
  BNA: {
    name: 'Nashville, TN (BNA)',
    code: 'BNA',
    needle: 'BNA,Nashville,Tennessee,Tennessee',
    jb: true,
  },
  GGT: {
    name: 'George Town, Bahamas (GGT)',
    code: 'GGT',
    needle: 'GGT,George Town,BAHAMAS',
    jb: false,
  },
  ALB: {
    name: 'Albany, NY (ALB)',
    code: 'ALB',
    needle: 'ALB,Albany International Airport,New York,New York',
    jb: true,
  },
  MCO: {
    name: 'Orlando, FL (MCO)',
    code: 'MCO',
    needle:
      'MCO,Orlando International Apt,Florida,Florida, Disney World, disneyworld',
    jb: true,
  },
  ALC: {
    name: 'Alicante (ALC)',
    code: 'ALC',
    needle: 'ALC,Alicante,SPAIN',
    jb: false,
  },
  OGS: {
    name: 'Ogdensburg, NY (OGS)',
    code: 'OGS',
    needle: 'OGS,Ogdensburg,New York,New York',
    jb: false,
  },
  PIT: {
    name: 'Pittsburgh, PA (PIT)',
    code: 'PIT',
    needle: 'PIT,Pittsburgh International Apt,Pennsylvania,Pennsylvania',
    jb: true,
  },
  CPH: {
    name: 'Copenhagen (CPH)',
    code: 'CPH',
    needle: 'CPH,Copenhagen Kastrup Apt,DENMARK',
    jb: false,
  },
  NEV: {
    name: 'Nevis (NEV)',
    code: 'NEV',
    needle: 'NEV,Nevis,SAINT KITTS AND NEVIS',
    jb: false,
  },
  LAS: {
    name: 'Las Vegas, NV (LAS)',
    code: 'LAS',
    needle:
      'LAS,Las Vegas McCarran International Apt,Nevada,Nevada, Grand Canyon',
    jb: true,
  },
  JNB: {
    name: 'Johannesburg (JNB)',
    code: 'JNB',
    needle: 'JNB,Johannesburg O.R. Tambo International,SOUTH AFRICA',
    jb: false,
  },
  GHB: {
    name: 'Governors Harbour, Bahamas (GHB)',
    code: 'GHB',
    needle: 'GHB,Governors Harbour,BAHAMAS',
    jb: false,
  },
  LAX: {
    name: 'Los Angeles, CA (LAX)',
    code: 'LAX',
    needle: 'LAX,Los Angeles International Apt,California,California',
    jb: true,
    pm: 'ZLA',
  },
  LRM: {
    name: 'La Romana, DR (LRM)',
    code: 'LRM',
    needle: 'LRM,La Romana,DOMINICAN REPUBLIC,Caribbean, republica dominicana',
    jb: true,
    pm: 'XDR',
  },
  CPT: {
    name: 'Cape Town (CPT)',
    code: 'CPT',
    needle: 'CPT,Cape Town,SOUTH AFRICA',
    jb: false,
  },
  TRD: {
    name: 'Trondheim, Norway (TRD)',
    code: 'TRD',
    needle: 'TRD,Trondheim Vaernes Airport,NORWAY',
    jb: false,
  },
  CPX: {
    name: 'Culebra, PR (CPX)',
    code: 'CPX',
    needle: 'CPX,Culebra,PUERTO RICO,Puerto Rico',
    jb: false,
  },
  EDI: {
    name: 'Edinburgh, UK (EDI)',
    code: 'EDI',
    needle: 'EDI,Edinburgh,UNITED KINGDOM',
    jb: false,
  },
  MDE: {
    name: 'Medellin, Colombia (MDE)',
    code: 'MDE',
    needle: 'MDE,Medellin Jose Maria Cordova Intl,COLOMBIA',
    jb: true,
  },
  RNO: {
    name: 'Reno/Tahoe, NV (RNO)',
    code: 'RNO',
    needle: 'RNO,Reno,Nevada,Nevada, Lake Tahoe',
    jb: true,
  },
  GYE: {
    name: 'Guayaquil, Ecuador (GYE)',
    code: 'GYE',
    needle: 'GYE,Guayaquil,ECUADOR',
    jb: true,
  },
  RNS: {
    name: 'Rennes, France (RNS)',
    code: 'RNS',
    needle: 'RNS,Rennes St Jacques Airport,FRANCE',
    jb: false,
  },
  BOD: {
    name: 'Bordeaux, France (BOD)',
    code: 'BOD',
    needle: 'BOD,Bordeaux Merignac Apt,FRANCE',
    jb: false,
  },
  SPU: {
    name: 'Split (SPU)',
    code: 'SPU',
    needle: 'SPU,Split,CROATIA',
    jb: false,
  },
  DBV: {
    name: 'Dubrovnik, Croatia (DBV)',
    code: 'DBV',
    needle: 'DBV,Dubrovnik,CROATIA',
    jb: false,
  },
  MUC: {
    name: 'Munich (MUC)',
    code: 'MUC',
    needle: 'MUC,Munich International Airport,GERMANY',
    jb: false,
  },
  BOG: {
    name: 'Bogota, Colombia (BOG)',
    code: 'BOG',
    needle: 'BOG,Bogota,COLOMBIA',
    jb: true,
  },
  BOJ: {
    name: 'Burgas, Bulgaria (BOJ)',
    code: 'BOJ',
    needle: 'BOJ,Burgas,BULGARIA',
    jb: false,
  },
  AMM: {
    name: 'Amman, Jordan (AMM)',
    code: 'AMM',
    needle: 'AMM,Amman Queen Alia International Apt,JORDAN',
    jb: false,
  },
  DCA: {
    name: 'Washington, DC (DCA)',
    code: 'DCA',
    needle:
      'DCA,Washington Ronald Reagan National Apt,District of Columbia,Maryland',
    jb: true,
    pm: 'WAS',
  },
  DSS: {
    name: 'Dakar, Senegal (DSS)',
    code: 'DSS',
    needle: 'DSS,Dakar, Senegal (DSS),SENEGAL',
    jb: false,
  },
  ROC: {
    name: 'Rochester, NY (ROC)',
    code: 'ROC',
    needle: 'ROC,Rochester,New York,New York',
    jb: true,
  },
  BOS: {
    name: 'Boston, MA (BOS)',
    code: 'BOS',
    needle: 'BOS,Boston Logan International Apt,Massachusetts,Massachusetts',
    jb: true,
    pm: 'XBO',
  },
  AMS: {
    name: 'Amsterdam - Schiphol (AMS)',
    code: 'AMS',
    needle: 'AMS,Amsterdam,NETHERLANDS',
    jb: false,
  },
  TCB: {
    name: 'Treasure Cay, Bahamas (TCB)',
    code: 'TCB',
    needle: 'TCB,Treasure Cay,BAHAMAS',
    jb: false,
  },
  ANC: {
    name: 'Anchorage, AK (ANC)',
    code: 'ANC',
    needle: 'ANC,Anchorage International Apt,Alaska,Alaska',
    jb: true,
  },
  SAN: {
    name: 'San Diego, CA (SAN)',
    code: 'SAN',
    needle: 'SAN,San Diego International,California,California',
    jb: true,
  },
  MEX: {
    name: 'Mexico City, Mexico (MEX)',
    code: 'MEX',
    needle: 'MEX,Mexico City Juarez International Apt,MEXICO,Ciudad de Mexico',
    jb: true,
  },
  UVF: {
    name: 'St. Lucia (UVF)',
    code: 'UVF',
    needle: 'UVF,St Lucia Hewanorra Apt,SAINT LUCIA,Caribbean',
    jb: true,
  },
  DTW: {
    name: 'Detroit, MI (DTW)',
    code: 'DTW',
    needle: 'DTW,Detroit Wayne County,Michigan,Michigan',
    jb: true,
  },
  ANU: {
    name: 'Antigua, Antigua and Barbuda (ANU)',
    code: 'ANU',
    needle: 'ANU,Antigua,ANTIGUA AND BARBUDA,Antigua and Barbuda, Caribbean',
    jb: true,
  },
  SAV: {
    name: 'Savannah/Hilton Head, GA (SAV)',
    code: 'SAV',
    needle:
      'SAV,Savannah/Hilton Head International Apt,Georgia,Georgia, South Carolina',
    jb: true,
  },
  DUB: {
    name: 'Dublin (DUB)',
    code: 'DUB',
    needle: 'DUB,Dublin,IRELAND',
    jb: false,
  },
  MVY: {
    name: "Martha's Vineyard, MA (MVY)",
    code: 'MVY',
    needle: "MVY,Martha's Vineyard,Massachusetts,Massachusetts",
    jb: true,
  },
  EWB: {
    name: 'New Bedford, MA (EWB)',
    code: 'EWB',
    needle: 'EWB,New Bedford,Massachusetts,Massachusetts',
    jb: false,
  },
  SRQ: {
    name: 'Sarasota, FL (SRQ)',
    code: 'SRQ',
    needle: 'SRQ,Sarasota/Bradenton,Florida,Florida',
    jb: true,
  },
  KBL: {
    name: 'Kabul, Afghanistan (KBL)',
    code: 'KBL',
    needle: 'KBL,Kabul,AFGHANISTAN',
    jb: false,
  },
  PLS: {
    name: 'Providenciales, Turks and Caicos (PLS)',
    code: 'PLS',
    needle: 'PLS,Providenciales,TURKS AND CAICOS ISLANDS,Caribbean',
    jb: true,
  },
  DUJ: {
    name: 'DuBois, PA (DUJ)',
    code: 'DUJ',
    needle: 'DUJ,Dubois,Pennsylvania,Pennsylvania',
    jb: false,
  },
  NYC: {
    name: 'New York City area (NYC)',
    code: 'NYC',
    needle:
      'NYC,New York City area,New York,New York, nueva york LGA New York, NY (LGA) HPN Westchester County, NY (HPN) SWF Newburgh, NY (SWF) JFK New York, NY (JFK) EWR Newark, NJ (EWR)',
    jb: true,
    cc: ['LGA', 'HPN', 'SWF', 'JFK', 'EWR'],
  },
  BQN: {
    name: 'Aguadilla, PR (BQN)',
    code: 'BQN',
    needle: 'BQN,Aguadilla,PUERTO RICO,Puerto Rico',
    jb: true,
  },
  DUR: {
    name: 'Durban (DUR)',
    code: 'DUR',
    needle: 'DUR,Durban King Shaka International Apt,SOUTH AFRICA',
    jb: false,
  },
  EWR: {
    name: 'Newark, NJ (EWR)',
    code: 'EWR',
    needle: 'EWR,Newark Liberty International Apt,New Jersey,New Jersey',
    jb: true,
    pm: 'NYC',
  },
  DEC: {
    name: 'Decatur, IL (DEC)',
    code: 'DEC',
    needle: 'DEC,Decatur,Illinois',
    jb: false,
  },
  DUS: {
    name: 'Dusseldorf (DUS)',
    code: 'DUS',
    needle: 'DUS,Duesseldorf International Airport,GERMANY',
    jb: false,
  },
  IOM: {
    name: 'Isle of Man, UK (IOM)',
    code: 'IOM',
    needle: 'IOM,Isle Of Man,UNITED KINGDOM',
    jb: false,
  },
  LEB: {
    name: 'Lebanon, NH (LEB)',
    code: 'LEB',
    needle: 'LEB,Lebanon,New Hampshire,New Hampshire',
    jb: false,
  },
  BAH: {
    name: 'Bahrain (BAH)',
    code: 'BAH',
    needle: 'BAH,Bahrain,BAHRAIN',
    jb: false,
  },
  DEN: {
    name: 'Denver, CO (DEN)',
    code: 'DEN',
    needle: 'DEN,Denver Intl Apt,Colorado,Colorado',
    jb: true,
  },
  PMI: {
    name: 'Palma de Mallorca/Majorca, Spain (PMI)',
    code: 'PMI',
    needle: 'PMI,Palma De Mallorca,SPAIN',
    jb: false,
  },
  CTA: {
    name: 'Catania, Italy (CTA)',
    code: 'CTA',
    needle: 'CTA,Catania,ITALY',
    jb: false,
  },
  CCR: {
    name: 'Concord, CA (CCR)',
    code: 'CCR',
    needle: 'CCR,Concord,California,California',
    jb: false,
  },
  VIE: {
    name: 'Vienna, Austria (VIE)',
    code: 'VIE',
    needle: 'VIE,Vienna,AUSTRIA',
    jb: false,
  },
  VIJ: {
    name: 'Virgin Gorda (VIJ)',
    code: 'VIJ',
    needle: 'VIJ,Virgin Gorda,BRITISH VIRGIN ISLANDS',
    jb: false,
  },
  CTG: {
    name: 'Cartagena, Colombia (CTG)',
    code: 'CTG',
    needle: 'CTG,Cartagena,COLOMBIA',
    jb: true,
  },
  MGW: {
    name: 'Morgantown, WV (MGW)',
    code: 'MGW',
    needle: 'MGW,Morgantown,West Virginia,West Virginia',
    jb: false,
  },
  GLA: {
    name: 'Glasgow, UK (GLA)',
    code: 'GLA',
    needle: 'GLA,Glasgow International Airport,UNITED KINGDOM',
    jb: false,
  },
};
