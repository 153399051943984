import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RequestAReceiptApi } from './api/request-a-receipt.api';
import {
  ReceiptSubmissionFailed,
  ReceiptSubmissionRequested,
  ReceiptSubmissionSucceeded,
  RequestAReceiptActionTypes,
} from './request-a-receipt.actions';

@Injectable()
export class RequestAReceiptEffects {
  constructor(
    private actions$: Actions,
    private requestReceiptApi: RequestAReceiptApi,
  ) {}

  emailValidationResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReceiptSubmissionRequested>(
        RequestAReceiptActionTypes.REQUEST_A_RECEIPT_SUBMIT_REQUESTED,
      ),
      switchMap(payloadResponse => {
        return this.requestReceiptApi
          .requestReceiptFormSubmission(payloadResponse.payload)
          .pipe(
            map(() => {
              return new ReceiptSubmissionSucceeded();
            }),
            catchError(response => {
              return of(new ReceiptSubmissionFailed());
            }),
          );
      }),
    ),
  );
}
