import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { JbCampaign } from '@core/ab-testing/abtest-campaign.type';
import { CookieStorageService, WindowService } from '@core/index';
import {
  JbButtonModule,
  JbDialogModule,
  JbExpandableButtonModule,
  JbExpandableContainerModule,
  JbFormFieldContainerModule,
  JbIconModule,
  JbInputLabelModule,
  JbInputModule,
} from 'jb-component-library';
import { Observable } from 'rxjs';

/**
 * @description Dialog that shows the pages current ab tests. It also lets you edit which test should be running.
 * @WhereIsItUsed App component
 */
@Component({
  selector: 'jb-ab-testing-dialog',
  templateUrl: './jb-ab-testing-dialog.component.html',
  styleUrls: ['./jb-ab-testing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    JbButtonModule,
    JbDialogModule,
    JbExpandableButtonModule,
    JbExpandableContainerModule,
    JbFormFieldContainerModule,
    JbIconModule,
    JbInputLabelModule,
    JbInputModule,
  ],
})
export class JBABTestingDialogComponent {
  public pathName: string;
  public selectedPageCampaigns: JbCampaign[] = [];
  public openedContainers: boolean[] = [];
  public errors: boolean[] = [];
  public isMobile$: Observable<boolean> = this._windowService.isMobile;

  constructor(
    private _router: Router,
    private _cookieStorageService: CookieStorageService,
    private _windowService: WindowService,
  ) {}

  handleData(data: JbCampaign[]) {
    this.pathName = this._router.url.split('?')[0];
    data.forEach(campaign => {
      campaign['cookieValue'] = this.getExperience(campaign.name);
      this.selectedPageCampaigns.push(campaign);
    });
  }

  changeToggle(event: boolean, index: number) {
    this.openedContainers[index] = event;
  }

  getExperience(cookieName: string) {
    const experience = this._cookieStorageService.getCookie(cookieName);
    return experience ? experience : '';
  }

  updateExperience(event, campaign: JbCampaign, index: number) {
    event.preventDefault();
    const inputValue: string = event.target[0].value.toUpperCase();
    const isValidCampaign = campaign.experience.some(
      item => item.name.toUpperCase() === inputValue,
    );
    if (isValidCampaign) {
      this.errors[index] = false;
      this._cookieStorageService.setCookie(campaign.name, inputValue);
    } else {
      this.errors[index] = true;
    }
  }
}
