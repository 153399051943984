import { all, compose, isEmpty, not, propOr } from 'ramda';

/*
  see DOT-4234. this could be deleted when/if a more general-purpose network
  response validation layer is implemented.
*/
export function flightDataIsNotEmptyAndIsNotMalformed(flights: any[]): boolean {
  if (flights.length < 1) {
    return false;
  }

  const notEmpty = compose(not, isEmpty);

  return all(
    compose(
      airLegList =>
        notEmpty(airLegList) &&
        all(compose(notEmpty, propOr([], 'flightInfo')))(airLegList as any),
      propOr([], 'airLegList'),
    ),
  )(flights);
}
