import { DynamicLabel } from '../../../types';

export interface ProgressIndicatorLabel {
  dateNotSelectedLabel: string;
  dateSelectedLabel: string;
}

export interface ProgressIndicatorBlockLabel {
  title: ProgressIndicatorLabel;
  placeholder: DynamicLabel;
}

export interface ProgressIndicatorCopy {
  seatCopy: {
    seat: string;
    seats: string;
  };
  ptsLabel: DynamicLabel;
  personCopy: DynamicLabel;
}

export enum ProgressIndicatorType {
  DEPART = 'DEPART',
  RETURN_RT = 'RETURN_RT',
  RETURN_ONE_WAY = 'RETURN_ONE_WAY',
}

export interface ProgressIndicatorContent {
  [ProgressIndicatorType.DEPART]: ProgressIndicatorBlockLabel;
  [ProgressIndicatorType.RETURN_RT]: ProgressIndicatorBlockLabel;
  [ProgressIndicatorType.RETURN_ONE_WAY]: ProgressIndicatorBlockLabel;
  copy: ProgressIndicatorCopy;
}
