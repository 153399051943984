import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { JBLogoComponent } from '@shared/jb-logo';

import { HeaderInnerType } from '../types/header.inner.type';

/**
 * @description Header for app when in a desktop viewport
 * @WhereIsItUsed header component
 */
@Component({
  selector: 'jb-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderDesktopComponent {
  @Input() headerData: HeaderInnerType;
  @Input() hideTb;

  @ViewChild('headerLogo') headerLogo: JBLogoComponent;
}
