import { initialApiState } from './api/api.reducer';
import { initialAuthState } from './auth/auth.reducer';
import { initialBookerState } from './booker/booker.reducer';
import { initialFareSale } from './fare-sale/fare-sale.reducer';
import { initialGlobalConfigState } from './global/global.reducers';
import { initialItineraryState } from './itinerary/itinerary.reducer';
import { initialOriginsState } from './origins/origins.reducer';
import { initialPreferenceCenterState } from './preference-center/preference-center.reducer';
import { initialProfileState } from './profile/profile.reducer';
import { initialRequestAReceiptState } from './request-a-receipt/request-a-receipt.reducer';
import { initialScheduleExtensionState } from './schedule-extension/schedule-extension.reducer';
import { AppState } from './shared/types/app-state.type';

export const initialState: AppState = {
  itinerary: initialItineraryState,
  origins: initialOriginsState,
  api: initialApiState,
  auth: initialAuthState,
  trueblue: initialProfileState,
  scheduleExtension: initialScheduleExtensionState,
  booker: initialBookerState,
  global: initialGlobalConfigState,
  router: undefined,
  fareSales: initialFareSale,
  requestReceipt: initialRequestAReceiptState,
  preferenceCenter: initialPreferenceCenterState,
};
