export const analyticsDataMap = {
  TBNumber: 'tbNumber',
  TBPoints: 'tbPoints',
  TBStatus: 'tbStatus',
  ValidationError: 'pageValidationError',
  Channel: 'channel',
  PageName: 'pageName',
  ServiceError: 'eVar40',
  Language: 'eVar2',
  URL: 'url',
  FareType: 'fareType',
  Segment1: 'originToDestination',
  TripDesignation: 'tripType',
  Adults: 'adults',
  Kids: 'children',
  LapInfants: 'infants',
  PreviousPageName: 'prop61',
  Events: 'events',
  Evar70: 'eVar70',
};
