import { assocPath, flip, init, last, path, toPairs } from 'ramda';

function isObject(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object Object]';
}

function isFunction(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object Function]';
}

/* eslint-disable */

// { a: { b: 'hello', c: 'world' } }
// => [['a', 'b', 'hello'], ['a', 'c', 'world']]
function getPathsAndValues(rootObj: any): any[] {
  const paths: string[][] = [];

  (function getPathAndValue(obj: any, aPath: string[] = []): void {
    toPairs(obj).forEach(([key, value]: [string, any]) => {
      if (isObject(value)) {
        getPathAndValue(value, aPath.concat(key));
      } else {
        paths.push(aPath.concat([key, value]));
      }
    });
  })(rootObj);

  return paths;
}

/**
 * Example:
 * const object = { A: 1, B: 2, C: { D: 3 }, E: 4, f: 5 }
 * const mapping = { a: 'A', b: { c: 'B' }, d: 'C.D', e: (obj) => obj.E, f: '' }
 * remap(mapping, object);
 *   => { a: 1, b: { c: 2 }, d: 3, e: 4, f: 5 }
 */
// assumes no arrays
export function remap<T>(mapping: any, obj: any, context?: any): T {
  const pathsAndValues = getPathsAndValues(mapping);
  const objGet = flip(path)(obj);

  return pathsAndValues.reduce((newObj, destAndOrigin) => {
    const destinationPath = init<string>(destAndOrigin);
    const originPath = last(destAndOrigin);
    const value = isFunction(originPath)
      ? (originPath as any)(obj, context)
      : originPath !== ''
      ? objGet((originPath as string).split('.'))
      : objGet(destinationPath);

    return assocPath(destinationPath, value, newObj);
  }, {});
}

/**
 * Works with arrays. Can only map arrays to other arrays of same length
 * Meant to be used in conjunction with remap
 * Example:
 * const object = { A: [{ B: 1 }, { B: 2 }], C: 3 }
 * const mapping = { a: remapArray('A', { b: 'B' }), c: 'C' }
 * remap(mapping, object);
 *   => { a: [{ b: 1 }, { b: 2 }], c: 3 }
 */
export function remapArray<T>(strPath: string, mapping: any) {
  const arrayPath = strPath.split('.');

  return (obj: any): T[] => {
    const array = path(arrayPath, obj);

    if (!Array.isArray(array)) {
      throw Error('remapArray: path does not lead to array');
    }

    return array.map((item, index) => remap<T>(mapping, item, index));
  };
}
