<a
  [jbSitemapLinker]="link?.href"
  jbactionlink
  class="b items-baseline inline-flex no-underline tc underline-hover royal-blue"
  [target]="'_self'"
>
  {{ link?.title
  }}<span class="w1"
    ><jb-icon
      class="pl0 fl ml1 fill-royal-blue flex nt2"
      name="rightFilled"
    ></jb-icon
  ></span>
</a>
