import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JbSrOnlyModule } from 'jb-component-library';

import { UIModule } from '../ui';
import { JBTextImageModule } from './../jb-body-text-image/jb-text-image.module';
import { CalendarComponent } from './calendar.component';
import { CalendarDateBoxComponent } from './calendar-date-box/calendar-date-box.component';
import { CalendarErrorComponent } from './calendar-error/calendar-error.component';
import { CalendarNoFlightsComponent } from './calendar-no-flights/calendar-no-flights.component';
import { CalendarTitleComponent } from './calendar-title/calendar-title.component';

const MODULES = [CommonModule, UIModule, JBTextImageModule, JbSrOnlyModule];

const DECLARATIONS = [
  CalendarComponent,
  CalendarTitleComponent,
  CalendarErrorComponent,
  CalendarDateBoxComponent,
  CalendarNoFlightsComponent,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class CalendarModule {}
