import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { SubNavSection } from '../types';

/**
 * @description Container for dropdowns menu's for the navbar on the header component
 * @WhereIsItUsed
 * - subnav-desktop
 * - subnav-mobile
 */
@Component({
  selector: 'jb-subnav-wrapper',
  templateUrl: './subnav-wrapper.component.html',
  styleUrls: ['./subnav-wrapper.component.scss'],
  animations: [
    trigger('slideUpDownNav', [
      state(
        'down',
        style({
          transform: 'translateY(0%)',
          display: 'block',
        }),
      ),
      state(
        'up',
        style({
          transform: 'translateY(-100%)',
          display: 'none',
        }),
      ),
      state(
        'fadein',
        style({
          opacity: '1',
          display: 'block',
        }),
      ),
      state(
        'fadeout',
        style({
          opacity: '0',
          display: 'none',
        }),
      ),

      // Animates the inner content's fade in (first state change - having a menu item already open)
      transition('up => fadein', [
        style({ display: 'block', transform: 'translateY(0%)' }),
        query('.subnav-container', [
          style({ opacity: 0 }),
          animate('300ms ease-in-out', style({ opacity: 1 })),
        ]),
      ]),

      // Animates the fade in effect (state change when a menu item is already open)
      transition('fadeout => fadein', [
        style({ display: 'block', opacity: 1 }),
        query('.subnav-container', [
          style({ opacity: 0 }),
          animate('300ms ease-in-out', style({ opacity: 1 })),
        ]),
      ]),

      // Animates the slide down effect
      transition('up => down', [
        style({ display: 'block' }),
        animate('300ms ease-in-out'),
      ]),

      // Animates the slide up effect
      transition('down => up', [animate('300ms ease-in-out')]),

      // Animates the closing after switching active states
      transition('fadein => up', [animate('300ms ease-in-out')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavWrapperComponent {
  @Input() subNavSection: SubNavSection;
  @Input() activeIndex: number;
  @Input() headerSize: string;
  @Input() animationClass: string;
  @Input() subnavWrapperId: string;
  @Input() isLoggedIn: boolean;
  @Input() isMobile: boolean;

  @HostBinding('@slideUpDownNav') get animation(): string {
    return this.animationClass;
  }
}
