import { Weekdays } from './types';

export interface WeekdaysBarState {
  content: Weekdays;
  isMobile: boolean;
}

export const weekdaysBarInitialState: WeekdaysBarState = {
  content: undefined,
  isMobile: false,
};

export interface WeekdaysBarViewModel extends WeekdaysBarState {
  weekdaysLabel: string[];
}
