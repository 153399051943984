import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotStringToId',
})
export class StringToIdPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    return value
      .replace(/([A-Z])/g, ' $1')
      .replace(/(\W+)/g, '-')
      .replace(/^[-\d]+|-$/g, '')
      .toLowerCase();
  }
}
