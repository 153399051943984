import { anyPass, compose, isEmpty, isNil, pick, propOr } from 'ramda';

import { AuthProfile, AuthState, LeanProfile } from './types';

export const mapLeanProfileToExistingProfile = (
  leanProfile: LeanProfile,
): AuthProfile => {
  return {
    fname: leanProfile.firstName,
    lname: leanProfile.lastName,
    birthDate: leanProfile.birthDate,
    age: getProfileAge(leanProfile.birthDate),
    isMosaic: leanProfile.mosaicStatus.toString(),
    name: `${leanProfile.firstName} ${leanProfile.lastName}`,
    membershipid: leanProfile.tbNumber,
    points: leanProfile.pointsBalance.toString(),
    cardStatus: leanProfile.cardType,
    profileImage: leanProfile.profileImage,
    profileImageType: leanProfile.profileImageType,
    prefAirport: leanProfile.prefAirport,
    authProfileSource: 'leanprofile',
    mosaicTier: leanProfile.mosaicTier,
    numberOfTiles: leanProfile.numberOfTiles,
  };
};

export const tryAssembleAuthProfileFromLeanProfile = (
  input: AuthState,
): AuthProfile => {
  const { leanProfileFragment } = compose(
    pick(['leanProfileFragment']),
    propOr({}, 'authProfileForOktaAndLeanProfileService'),
  )(input) as any;

  if (!leanProfileFragment) {
    return null;
  }

  return {
    ...leanProfileFragment,
  };
};

export const constructPictureBlob = (input: AuthProfile) => {
  if (input.authProfileSource !== 'leanprofile') {
    return null;
  }

  const { profileImageType, profileImage } = input;

  const isInvalid = anyPass([isNil, isEmpty]);
  const bothFieldsAreAvailable = !(
    isInvalid(profileImageType) || isInvalid(profileImage)
  );

  if (!bothFieldsAreAvailable) {
    return null;
  }

  return `data:${profileImageType};base64,${profileImage}`;
};

export function getProfileAge(birthDate: string): number {
  try {
    const plainDate = new Date(birthDate);
    if (plainDate instanceof Date && !isNaN(plainDate.getTime())) {
      const utcDateString = plainDate.toUTCString();
      const formattedBirthDate = new Date(utcDateString);
      const today = new Date();
      return today.getUTCFullYear() - formattedBirthDate.getUTCFullYear();
    } else {
      return undefined;
    }
  } catch (_) {
    return undefined;
  }
}
