import {
  ComponentRef,
  createNgModuleRef,
  Injectable,
  Injector,
  ViewContainerRef,
} from '@angular/core';
import { Templates } from '@dynamic-templates/types/templates.type';

import { dynamicComponentMap } from './dynamic-component.manifest';
import {
  CMSComponentData,
  DynamicItemConstructor,
  DynamicModuleConstructor,
  isComponentConstructor,
  isModuleConstructor,
  LoadedModuleAndData,
  Registry,
} from './types/index';

@Injectable({ providedIn: 'root' })
export class DynamicComponentsService {
  constructor(public injector: Injector) {}

  /*
    This loads a module/component into memory from a remote chunk (over the network) in
    order to build the component module if required or pass along the component.
    Lazy load only what is needed to render the view requested.
  */
  // componentData comes from cms metadata
  async loadComponentConstructor(componentData: CMSComponentData) {
    try {
      const module = dynamicComponentMap.get(componentData.name);
      if (!module) {
        throw new Error(
          `Module/Componenet not found for: ${componentData.name};`,
        );
      }

      const componentConstructor =
        (await module.loadModule()) as DynamicItemConstructor;

      if (isModuleConstructor(componentConstructor)) {
        const ngModuleRef = createNgModuleRef<DynamicModuleConstructor>(
          componentConstructor,
          this.injector,
        );

        return {
          ngModuleRef,
          componentConstructor,
        };
      } else {
        // is standalone component
        return componentConstructor;
      }
    } catch (error) {
      console.error('ERROR LOADING COMPONENT', error);
      return;
    }
  }

  createComponent(
    container: ViewContainerRef,
    component: LoadedModuleAndData,
    registry: Registry,
    template?: keyof Templates,
  ) {
    // componentData comes from cms metadata see CMSComponentData
    const { componentData, loadedModule } = component;
    let componentRef: ComponentRef<any>;
    let resolverData: any;

    if (isComponentConstructor(loadedModule)) {
      // standalone component
      componentRef = container.createComponent(loadedModule);
      // resolver is a static method on the component constructor
      resolverData =
        loadedModule.componentDataResolver &&
        loadedModule.componentDataResolver(
          registry,
          !!componentData.data ? componentData.data : componentData,
          template,
        );
    } else {
      // is a module ref with a component constructor
      componentRef = container.createComponent(
        loadedModule.componentConstructor.entry,
        {
          ngModuleRef: loadedModule.ngModuleRef,
        },
      );
      resolverData = loadedModule.componentConstructor.componentDataResolver(
        registry,
        !!componentData.data ? componentData.data : componentData,
        template,
      );
    }

    // if the resolver returned data apply those to the component as properties
    if (!!resolverData) {
      Object.keys(resolverData).forEach(
        key => (componentRef.instance[key] = resolverData[key]),
      );
      container.insert(componentRef.hostView);
      return componentRef;
    }

    return componentRef;
  }

  insertPageHeader(
    components,
    pageTitle: string,
    template,
  ): CMSComponentData[] {
    const componentNames = components.map(component => component.name);
    const heroIndex = componentNames.indexOf('hero');
    const breadcrumbsIndex = componentNames.indexOf('breadcrumbs');
    const pageHeaderIndex = componentNames.indexOf('pageHeader');
    if (
      template === 'promo' &&
      breadcrumbsIndex !== -1 &&
      pageHeaderIndex === -1
    ) {
      components.splice(breadcrumbsIndex + 1, 0, {
        name: 'pageHeader',
        data: { pageHeader: { title: pageTitle } },
      });
    } else if (heroIndex !== -1 && pageHeaderIndex === -1) {
      const pageHeader = components[heroIndex].data.pageHeader;
      if (pageHeader) {
        components.splice(heroIndex + 1, 0, {
          name: 'pageHeader',
          data: pageHeader,
        });
      }
    }
    // TODO: delete 'this.removeLogin()' when CMS standardizes home & signin payloads
    return template === 'main' ? this.removeLogin(components) : components;
  }

  removeLogin(components) {
    return components.filter(component => component.name !== 'login');
  }

  checkComponentMap(
    componentData: CMSComponentData,
    isProdEnv: boolean,
  ): boolean {
    if (!dynamicComponentMap.has(componentData.name) && !isProdEnv) {
      console.error(
        `----- Component name "${componentData.name}" does not exist. This may crash your app. Please, check the CMS response and try again. -----`,
      );
    }

    return (
      Boolean(componentData) &&
      Boolean(componentData.name) &&
      dynamicComponentMap.has(componentData.name)
    );
  }
}
