import { Injectable } from '@angular/core';

import { DateService } from './../../core/date/date.service';
import { CountdownDetails } from './types/countdown.type';

/**
 * countdown.service
 * checks whether a countdown is active
 * to override dates check, add `debug=1` as a query parameter
 */
@Injectable({
  providedIn: 'root',
})
export class CountdownService {
  constructor(private dateService: DateService) {}

  getCountdown(config: CountdownDetails): CountdownDetails | null {
    const countdownDetails: CountdownDetails =
      this.formatCountdownDetails(config);

    return this.isDateInRange(countdownDetails) ? countdownDetails : null;
  }

  // format countdown details
  private formatCountdownDetails(config: CountdownDetails): CountdownDetails {
    let formattedDetails: CountdownDetails;

    if (config) {
      const startDate = new Date(config.dateStart);
      const endDate = new Date(config.dateEnd);

      formattedDetails = {
        ...config,
        dateStart: startDate,
        dateEnd: endDate,
      };
    }

    return formattedDetails;
  }

  private isDateInRange(countdown: CountdownDetails): boolean {
    const currentDate = this.dateService.getNewDate();
    // Show countdown if time is in range
    const showCountdown =
      new Date(countdown.dateStart) <= currentDate &&
      new Date(countdown.dateEnd) > currentDate;

    return showCountdown;
  }
}
