<div
  class="bg-dark-orange pa1 w80 flex justify-center"
  [style.display]="installService.browserPromptBtn()"
>
  <p class="s-body white">Do you want to install app?</p>
  &nbsp;&nbsp;
  <jb-icon
    name="incrementerUp"
    fill="#fff"
    (click)="installService.addToHomeScreen()"
  ></jb-icon>
  &nbsp;&nbsp;
  <jb-icon
    name="incrementerDown"
    fill="#fff"
    (click)="installService.cancelPrompt()"
  ></jb-icon>
</div>
<div
  class="bg-dark-orange pa1 w80 flex justify-center"
  [style.display]="installService.browserPromptBtnSafari()"
>
  <p class="s-body white">
    Add a shortcut to your home screen: Share button at the
    {{ installService.whereIsShare }} of the browser. Scroll left (if needed) to
    find the Add to Home Screen button.
  </p>
</div>
