import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TravelerGroup } from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { equals } from 'ramda';
import { from, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AppConfigService, DOCUMENT, ScrollService } from '../../core';
import { BffService, groupByDate } from '../../core/bff';
import { BestFare, BestFareDay } from '../../core/bff/types';
import { SEARCH_TYPES } from '../../shared/booker/booker.config';
import {
  applyBestFares,
  generateCalendars,
  generateMonth,
  getCalendarContainerId,
  nextMonth,
} from '../../shared/calendar/calendar.utils';
import { Day, Holiday } from '../../shared/types';
import {
  BffForm,
  BffState,
  CalendarMonth,
  CalendarMonthUnloaded,
  DaysOfMonthWithFaresAsArrayAndDictionary,
  FetchFaresParams,
  TripFares,
} from './bff.component-store.types';

@Injectable()
export class BffStore extends ComponentStore<BffState> {
  // *** UPDATERS ***
  readonly updateForm = this.updater<BffForm>(
    (state, form): BffState => ({
      ...state,
      form,
    }),
  );

  readonly clearLoadedMonths = this.updater(
    (state): BffState => ({
      ...state,
      calendarMonths: [],
    }),
  );

  readonly updateCalendars = this.updater<CalendarMonth[]>(
    (state, calendarMonths): BffState => ({
      ...state,
      calendarMonths,
    }),
  );
  readonly updateCurrencyCode = this.updater<string>(
    (state, currencyCode): BffState => ({
      ...state,
      currencyCode,
    }),
  );

  readonly updateCalendarMonth = this.updater<CalendarMonth>(
    (state, fareUpdate): BffState => ({
      ...state,
      calendarMonths: state.calendarMonths.map(calendar =>
        calendar.month === fareUpdate.month && calendar.year === fareUpdate.year
          ? { ...calendar, ...fareUpdate }
          : calendar,
      ),
    }),
  );

  // *** SELECTORS ***

  readonly calendars$: Observable<CalendarMonth[]> = this.select(
    state => state.calendarMonths,
  );

  readonly firstLoadedCalendarMonth$: Observable<number> = this.calendars$.pipe(
    map(calendarMonths =>
      calendarMonths.findIndex(calendarMonth => calendarMonth.isLoaded),
    ),
  );

  readonly form$: Observable<BffForm> = this.select(state => state.form);

  readonly tripType: Observable<SEARCH_TYPES> = this.select(
    state => state.form.tripType,
  );
  readonly isOneWay$: Observable<boolean> = this.select(
    state => state.form.tripType === SEARCH_TYPES.ONE_WAY,
  );

  readonly isReturnTrip$: Observable<boolean> = this.select(
    state => state.form.tripType === SEARCH_TYPES.ROUND_TRIP,
  );

  readonly departBff$: Observable<BestFareDay> = this.select(
    state => state.form.departBff,
  );

  readonly isDepartureSelected$: Observable<boolean> = this.departBff$.pipe(
    map(Boolean),
    distinctUntilChanged(),
  );

  readonly returnBff$: Observable<BestFareDay> = this.select(
    state => state.form.returnBff,
  );

  readonly currencyCode$: Observable<string> = this.select(
    state => state.currencyCode,
  );

  // *** EFFECTS ***

  readonly initializeCalendars = this.effect(
    (
      calendarInitParams$: Observable<{
        fromDate: Date;
        maxDate: Date;
        holidays: Holiday[];
      }>,
    ) => {
      return calendarInitParams$.pipe(
        map(({ fromDate, maxDate, holidays }) =>
          generateCalendars(fromDate, maxDate, holidays).map(
            (calendar): CalendarMonthUnloaded => ({
              month: calendar.month,
              year: calendar.year,
              holidays: calendar.holidays,
              days: calendar.days,
              isLoading: false,
              isLoaded: false,
              isServerError: false,
            }),
          ),
        ),
        tap((calendars: CalendarMonth[]) => this.updateCalendars(calendars)),
      );
    },
  );

  // Ensures that return fares are loaded for all months when switching from one-way to return fare search
  readonly loadReturnFaresForMissingMonths = this.effect<boolean>(
    isReturnTrip$ =>
      isReturnTrip$.pipe(
        filter(Boolean),
        withLatestFrom(this.calendars$),
        switchMap(([_, calendars]) => from(calendars)),
        filter(calendar => calendar.isLoaded && !calendar.fares.inboundFares),
        tap((calendar: CalendarMonth) =>
          this.fetchFares({
            month: calendar.month,
            year: calendar.year,
            scrollToCalendar: false,
          }),
        ),
      ),
  );

  readonly loadOneWayFaresWhenChangingTripType = this.effect<boolean>(
    isOneWay$ =>
      isOneWay$.pipe(
        filter(Boolean),
        withLatestFrom(this.calendars$),
        switchMap(([_, calendars]) => from(calendars)),
        filter(calendar => calendar.isLoaded),
        tap((calendar: CalendarMonth) =>
          this.fetchFares({
            month: calendar.month,
            year: calendar.year,
            scrollToCalendar: false,
          }),
        ),
      ),
  );

  readonly unselectReturnFareWhenSearchingOneWay = this.effect<boolean>(
    isOneWay$ =>
      isOneWay$.pipe(
        filter(Boolean),
        tap(_ => this.updateReturnBff(undefined)),
      ),
  );

  readonly updateSearchType = this.effect<SEARCH_TYPES>(searchType$ =>
    searchType$.pipe(
      switchMap(searchType =>
        of(this.form.controls['tripType'].setValue(searchType)),
      ),
    ),
  );

  readonly clearSelectedFares = this.effect(trigger$ =>
    trigger$.pipe(
      tap(_ => {
        this.updateDepartBff(null);
        this.updateReturnBff(null);
      }),
    ),
  );

  readonly updateDepartBff = this.effect<BestFareDay>(selectedFare$ =>
    selectedFare$.pipe(
      switchMap(selectedFare =>
        of(this.form.controls['departBff'].setValue(selectedFare)),
      ),
    ),
  );

  readonly updateReturnBff = this.effect<BestFareDay>(selectedFare$ =>
    selectedFare$.pipe(
      switchMap(selectedFare =>
        of(this.form.controls['returnBff'].setValue(selectedFare)),
      ),
    ),
  );

  readonly updateFares = this.effect(updateFares$ =>
    // TODO: actually update the fares with the API?
    updateFares$.pipe(
      withLatestFrom(this.calendars$),
      map(
        ([_, calendars]): CalendarMonth =>
          calendars.find(calendar => calendar.isLoaded),
      ),
      tap((calendar: CalendarMonth) => {
        this.scrollToCalendar(calendar.month, calendar.year);
      }),
    ),
  );

  readonly fetchFares = this.effect<FetchFaresParams>(params$ =>
    params$.pipe(
      tap(({ month, year }) => {
        this.updateCalendarMonth({
          year,
          month,
          isLoading: true,
          isLoaded: false,
          isServerError: false,
        });
      }),
      withLatestFrom(this.form$),
      mergeMap(
        ([
          { month, year, scrollToCalendar, additionalMonths },
          { cityPair, bookWithPoints, tripType, travelers },
        ]) => {
          if (scrollToCalendar) {
            this.scrollToCalendar(month, year);
          }
          return this.fetchBffPrices(
            cityPair.origin?.code,
            cityPair.destination?.code,
            bookWithPoints,
            month,
            year,
            tripType,
            travelers.allTravelers,
          ).pipe(
            tapResponse(
              fares => {
                this.updateCurrencyCode(fares.currencyCode);
                this.updateCalendarMonth({
                  year,
                  month,
                  isLoading: false,
                  isLoaded: true,
                  isServerError: false,
                  fares,
                });
              },
              () =>
                this.updateCalendarMonth({
                  year,
                  month,
                  isLoading: false,
                  isLoaded: false,
                  isServerError: true,
                }),
            ),
            tap(fares => {
              if (additionalMonths) {
                this.fetchFares({
                  ...nextMonth({ month, year }),
                  scrollToCalendar: false,
                  additionalMonths: additionalMonths - 1,
                });
              }
            }),
          );
        },
      ),
    ),
  );

  form: UntypedFormGroup;

  constructor(
    private bffService: BffService,
    private scrollService: ScrollService,
    @Inject(DOCUMENT) private document: any,
    private appConfig: AppConfigService,
  ) {
    super({
      form: {
        cityPair: null,
        tripType: SEARCH_TYPES.ROUND_TRIP,
        departBff: undefined,
        returnBff: undefined,
        bookWithPoints: false,
        travelers: {
          allTravelers: {
            adults: 1,
            children: 0,
            infants: 0,
          },
          groups: [],
        },
      },
      calendarMonths: [],
      currencyCode: undefined,
    });

    this.loadReturnFaresForMissingMonths(this.isReturnTrip$);
    this.loadOneWayFaresWhenChangingTripType(this.isOneWay$);
    this.unselectReturnFareWhenSearchingOneWay(this.isOneWay$);
  }

  private getCalendarMonth(
    month: number,
    year: number,
  ): Observable<CalendarMonth> {
    return this.state$.pipe(
      map(state =>
        state.calendarMonths.find(
          calendarMonth =>
            calendarMonth.month === month && calendarMonth.year === year,
        ),
      ),
      distinctUntilChanged<CalendarMonth>(equals),
    );
  }

  private toDaysOfMonthWithFaresAsArrayAndDictionary(
    days: Day[],
    bestFares: BestFare[],
    travelers: TravelerGroup,
  ): DaysOfMonthWithFaresAsArrayAndDictionary {
    const daysOfMonth = applyBestFares(days, bestFares, travelers);
    return {
      daysOfMonth,
      daysOfMonthHashMap: groupByDate(daysOfMonth),
    };
  }

  private fetchBffPrices(
    origin: string,
    destination: string,
    points: boolean,
    month: number,
    year: number,
    tripType: SEARCH_TYPES,
    travelers: TravelerGroup,
  ): Observable<TripFares> {
    return this.bffService
      .getBestFares2(
        origin,
        destination,
        points,
        month,
        year,
        tripType,
        travelers,
        this.appConfig.allowBffCaching ? true : false,
      )
      .pipe(
        map(bestFareResult => {
          if (bestFareResult['apiFailure']) {
            throw new Error('API Failure');
          }
          const calendar = generateMonth(month, year).map(day => ({
            ...day,
            disabled: false,
          }));
          return {
            currencyCode: bestFareResult.currencyCode,
            outboundFares: this.toDaysOfMonthWithFaresAsArrayAndDictionary(
              calendar,
              bestFareResult.outbound,
              travelers,
            ),
            ...(tripType !== SEARCH_TYPES.ONE_WAY
              ? {
                  inboundFares: this.toDaysOfMonthWithFaresAsArrayAndDictionary(
                    calendar,
                    bestFareResult.inbound,
                    travelers,
                  ),
                }
              : {}),
          };
        }),
      );
  }

  private scrollToCalendar(month: number, year: number) {
    requestAnimationFrame(() => {
      const id = getCalendarContainerId(year, month);
      const container = this.document.getElementById(id);
      if (container) {
        const firstDay = container.querySelector('td:not(.disable-click)');
        if (firstDay) {
          firstDay.focus({ preventScroll: true });
        }
        const navigationBarHeight: number =
          this.document.body.querySelector('jb-header-desktop')?.offsetHeight ??
          0;
        const progressContainerHeight: number =
          this.document.body.querySelector('.progress-container')
            ?.offsetHeight ?? 0;
        const legalContainerHeight: number =
          this.document.body.querySelector('.legal-container')?.offsetHeight ??
          0;
        this.scrollService.scrollToElement(
          container,
          -(
            navigationBarHeight +
            progressContainerHeight +
            legalContainerHeight
          ),
        );
      }
    });
  }
}
