import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ensureNumber',
})
export class EnsureNumberPipe implements PipeTransform {
  transform(value: number | string): number {
    return value ? parseFloat(value as string) : 0; // always assume that we are getting a string, and parse to number
  }
}
