import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import {
  JbButtonModule,
  JbCheckboxModule,
  JbDialogModule,
  JbErrorModule,
  JbExpansionPanelModule,
  JbHeroModule,
  JbIconModule,
  JbImageModule,
  JbInputModule,
  JbLinkModule,
  JbNavigationBlockModule,
  JbRadioModule,
  JbSelectModule,
} from 'jb-component-library';

import { ARROW_LINK_DECLARATIONS } from './arrow-link';
import { BUBBLE_LINK_DECLARATIONS } from './bubble-link';
import { ICON_LINK_DECLARATIONS } from './icon-link';
import { SitemapLinkerDirective } from './linkers/sitemap-linker/sitemap-linker.directive';
import { SitemapNestedLinkerDirective } from './linkers/sitemap-nested-linker/sitemap-nested-linker.directive';

const JB_COMPONENT_LIBRARY_MODULES = [
  JbButtonModule,
  JbIconModule,
  JbDialogModule,
  JbExpansionPanelModule,
  JbErrorModule,
  JbRadioModule,
  JbSelectModule,
  JbHeroModule,
  JbImageModule,
  JbNavigationBlockModule,
  JbInputModule,
  JbCheckboxModule,
  JbLinkModule,
  LinkIconModule,
];

const MODULES = [CommonModule, ...JB_COMPONENT_LIBRARY_MODULES];

const DECLARATIONS = [
  BUBBLE_LINK_DECLARATIONS,
  ICON_LINK_DECLARATIONS,
  ARROW_LINK_DECLARATIONS,
  SitemapLinkerDirective,
  SitemapNestedLinkerDirective,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class LinksModule {}
