export enum Layout {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum Theme {
  dark = 'dark',
  light = 'light',
}
