export const MOCK_TRUEBLUE_RESPONSE = {
  header: 'Sign In',
  pageLink: [
    {
      newWindow: '_self ',
      href: '/legal/privacy ',
      type: 'internal ',
      title: 'Privacy Policy ',
    },
  ],
  subheader: 'Book faster, see your points and more.',
  login: {
    formErrorMessage: "Either your username or password isn't right ",
    email: {
      title: 'Email',
      errorMessages: {
        required: 'Please enter your email to get started.',
        custom: 'We need a valid email address.',
      },
    },
    password: {
      title: 'Password',
      errorMessages: {
        required: 'We need a valid password.',
      },
    },
    forgotPassword: {
      title: 'Forgot password?',
      href: '//trueblue.jetblue.com/forgot-password',
    },
    submitButton: {
      title: 'Sign in',
      loading: 'Loading',
      success: 'Sign-in successful',
    },
    join: {
      title: 'Join now',
      href: '//trueblue.jetblue.com/enroll/join-us',
    },
    bottomCopy: 'Not a member yet?',
  },
};
