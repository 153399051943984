import { AbstractControl } from '@angular/forms';

export const sixCharAlphanumeric = /^[a-zA-Z0-9]{6}$/;
export const thirteenDigitNumeric = /^[0-9]{13}$/;

export function confirmationCodeValidator(control: AbstractControl): {
  [key: string]: any;
} {
  const cleaned = (control.value || '').replace(/\W/g, '');

  if (
    !cleaned.match(sixCharAlphanumeric) &&
    !cleaned.match(thirteenDigitNumeric)
  ) {
    if (cleaned.length === 0) {
      return { required: { invalid: true } };
    } else if (cleaned.length < 6) {
      return { confirmationCode: { invalid: true } };
    } else {
      return { ticketNumber: { invalid: true } };
    }
  }

  return null;
}
