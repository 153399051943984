import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'jb-ssr',
  template: `
    <jb-app>
      <div class="loader">
        <svg
          id="kebab"
          xmlns="http://www.w3.org/2000/svg"
          width="5em"
          height="5em"
          viewBox="0 0 42 5"
          focusable="false"
        >
          <circle
            cx="2.5"
            cy="2.5"
            r="2.5"
            style="
              -webkit-animation-name: animation2;
              animation-name: animation2;
              -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
              animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            "
          ></circle>
          <circle
            cx="20.5"
            cy="2.5"
            r="2.5"
            style="
              -webkit-animation-name: animation3;
              animation-name: animation3;
              -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
              animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            "
          ></circle>
          <circle
            cx="38.5"
            cy="2.5"
            r="2.5"
            style="
              -webkit-animation-name: animation1;
              animation-name: animation1;
              -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
              animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            "
            class=""
          ></circle>
        </svg>
      </div>
    </jb-app>
  `,
})
export class SSRComponent {}
