import { Component, Input } from '@angular/core';

import { Link } from '../types';

@Component({
  selector: 'jb-arrow-link',
  templateUrl: './arrow-link.component.html',
  styleUrls: ['./arrow-link.component.scss'],
})
export class ArrowLinkComponent {
  @Input() link: Link;
}
