import { CartActions, CartActionsUnion } from './cart.actions';
import { CartState } from './types';

export const initialCartState: CartState = {
  isCartEmpty: true,
  cartData: [],
};

export function cartReducer(
  state = initialCartState,
  action: CartActionsUnion,
): CartState {
  switch (action.type) {
    case CartActions.FETCH_CART_REQUESTED:
      return {
        ...state,
        ...initialCartState,
      };
    case CartActions.FETCH_CART_SUCCEEDED:
      return {
        ...state,
        isCartEmpty: !action.payload.some(item => item.status === 'ACTIVE'),
        cartData: action.payload,
      };
    case CartActions.FETCH_CART_FAILED:
    case CartActions.CLEAR_CART:
      return {
        ...state,
        ...initialCartState,
      };
    default:
      return state;
  }
}
