import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/injection-tokens';
import { registerLogFullStory } from '@shared/ui/utils/global-utils/full-story';

import { AppConfigService } from '../app-config/app-config.service';
import { FrontEndLoggerService } from '../front-end-logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(WINDOW) private window,
    private appConfigService: AppConfigService,
    public felog: FrontEndLoggerService,
  ) {}

  handleError(error: any) {
    console.error(error);
    this.felog.logError(error);
    if (this.appConfigService.logPartialPageResponse) {
      registerLogFullStory(
        'Template Error',
        {
          page_path_str: this.window.location.href,
          error_message_str: error?.message,
          error_stack_str: error?.stack?.substring(0, 1000),
        },
        this.window,
      );
    }
  }
}
