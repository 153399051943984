import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { DOCUMENT, WINDOW } from '../../../core/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) private window,
    private rendererFactory: RendererFactory2,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  updateCanonicalURL(key?: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const trimmedKey = key.split('?')[0];
    const canonical = this.document.querySelector('link[rel="canonical"]');
    if (canonical && canonical.getAttribute) {
      const canonicalURL = `${this.window.location.origin}${trimmedKey}`;
      this.renderer.setAttribute(canonical, 'href', canonicalURL);
      const tag = { property: 'og:url', content: canonicalURL };
      this.meta.updateTag(tag);
    }
  }
}
