import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LinkIconService } from './link-icon.service';
import { LinkIconData } from './link-icon.types';

/**
 * @description Wrapper for jb-icon specifically for link icons. It will search the link
 * icon service for the correct icon to show based on the newWindow value passed to the component
 * @WhereIsItUsed
 * - booker-air-warning-dialog
 * - jb-message-block
 * - primary-nav-block-list
 * - secondary-nav-block-list
 * - bubble-link
 * - icon-link
 */
@Component({
  selector: 'dot-link-icon',
  templateUrl: './link-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.dn]': '!iconData' },
})
export class LinkIconComponent {
  iconData: LinkIconData;

  @Input() set newWindow(value: string) {
    this.iconData = this.linkIconService.getIconData(value) || null;
  }

  constructor(private linkIconService: LinkIconService) {
    //
  }
}
