import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { LinkIconComponent } from '@shared/link-icon/link-icon.component';

import { LinkIconService } from './link-icon.service';
/**
 * Apply this directly to an anchor tag in order to render a new window icon
 *
 * @example
 * <a class="some-class"
 *   [target]="link.newWindow">
 *   [href]="link.href"
 *   dotLinkIcon
 * >
 *   {{ link.title }}
 * </a>
 *
 * In the case above, If the link.newWindow is supported target (_blank, _document),
 * then a corresponding icon will be added at the end of the link.
 */
@Directive({
  selector: 'a[dotLinkIcon]',
})
export class LinkIconDirective implements OnInit, OnChanges {
  iconComponentRef: ComponentRef<LinkIconComponent>;
  @Input('dotLinkIcon') target: string;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private linkIconService: LinkIconService,
  ) {
    //
  }

  ngOnInit() {
    this.renderIcon(this.target);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.target) {
      this.renderIcon(changes.target.currentValue);
    }
  }

  private renderIcon(target: string) {
    if (this.iconComponentRef) {
      this.viewContainerRef.clear();
    }
    // check if target is supported
    if (!this.linkIconService.getIconData(target)) {
      return;
    }

    this.iconComponentRef =
      this.viewContainerRef.createComponent(LinkIconComponent);

    this.iconComponentRef.instance.newWindow = target;

    this.renderer.appendChild(
      this.elementRef.nativeElement,
      this.iconComponentRef.location.nativeElement,
    );
  }
}
