import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BodyTextImage } from '@shared/jb-body-text-image/types';
import { clone } from 'ramda';

import { getFullMonthName } from '../calendar.utils';
import { CalendarResponse } from '../types';

/**
 * @description Display formatted error message for calendar
 * @WhereIsItUsed calendar component
 */
@Component({
  selector: 'jb-calendar-error',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-error.component.html',
  styleUrls: ['./calendar-error.component.scss'],
})
export class CalendarErrorComponent implements OnInit {
  @Input() content: CalendarResponse;
  @Input() progressFocus: number;
  @Input() month: number;
  @Input() height: string;

  @Output() updateFaresEvent: EventEmitter<void> = new EventEmitter();

  monthName: string;
  errContent: BodyTextImage;

  ngOnInit(): void {
    if (Number.isInteger(this.month)) {
      this.monthName = getFullMonthName(this.month);
      if (this.content) {
        this.errContent = clone(this.content.errorContent);
        this.errContent.content = this.errContent.content.replace(
          '[MONTH_NAME]',
          this.monthName,
        );
      }
    }
  }

  onClickUpdateFares(): void {
    this.updateFaresEvent.emit();
  }
}
