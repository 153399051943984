import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'jbHighlight' })
export class HighlightPipe implements PipeTransform {
  constructor(public sanitizer: DomSanitizer) {}
  transform(text: any, search): SafeHtml {
    if (search && text.length) {
      const pattern = search
        .split(/[,|\s|/|)|(]+/)
        .filter(t => {
          return t.length > 0;
        })
        .join('|');
      const regex = new RegExp(pattern, 'gi');

      const result = text.replace(
        regex,
        match => `<span class="b">${match}</span>`,
      );
      return this.sanitizer.bypassSecurityTrustHtml(`<span>${result}</span>`);
    } else {
      return `<span>${text}</span>`;
    }
  }
}
