import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from '@core/language/language.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ClientSideTranslationInterceptor implements HttpInterceptor {
  constructor(private languageService: LanguageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // to-do move this inside if condition
    const currentLanguage = this.languageService.getLanguage();

    // to-do: move the logic in this condition to getJsonAsset in textContent service
    if (
      request.url.includes('ui-assets/text-content') &&
      request.url.includes('.json')
    ) {
      // filename without the extension
      const fileName = request.url.split('/').slice(-1)[0].split('.')[0];
      const prefix = 'dotcom';

      // then make request to the /i18n-next-static endpoint, passing in the json object
      // and the url of the request, and the current language
      // and then return the response from that request
      return next
        .handle(
          request.clone({
            url: `/i18n-next-static?path=${prefix}_${fileName}&language=${currentLanguage}`,
            method: 'GET',
          }),
        )
        .pipe(
          catchError(error => {
            console.log('error in client side translation interceptor', error);
            return next.handle(request);
          }),
        );
    }

    return next.handle(request);
  }
}
