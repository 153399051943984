import { Injectable } from '@angular/core';
import { LegalCopy } from '@dynamic-components/isolated-flight-block-container/flight-block.type';
import { Observable } from 'rxjs';

import { StoreService } from '../shared/store.service';
import { RequestLegalCopy, SetLegalCopy } from './deals.actions';
import * as DealsConfigSelectors from './deals.selectors';

@Injectable({ providedIn: 'root' })
export class DealsFacade {
  constructor(private storeService: StoreService) {}

  legalCopy: Observable<LegalCopy> = this.storeService.getState(
    DealsConfigSelectors.legalCopy,
  );

  requestLegalCopy() {
    this.storeService.dispatchAction(new RequestLegalCopy());
  }

  setLegalCopy(payload: LegalCopy): SetLegalCopy {
    return new SetLegalCopy(payload);
  }
}
