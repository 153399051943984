import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WindowService } from '@core/window-actions';
import { SubNavSectionBook } from '@shared/header';

/**
 * @description Flyout contents for Book option on main navigation
 * @WhereIsItUsed Main navigation
 */
@Component({
  selector: 'jb-book-subnav-template',
  templateUrl: './book-subnav-template.component.html',
  styleUrls: ['./book-subnav-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookSubnavTemplateComponent {
  @Input('section') subNav: SubNavSectionBook;
  @Input() isMobile: boolean;

  constructor(public windowService: WindowService) {}
}
