import { Component, HostBinding, Input } from '@angular/core';

/**
 * @description Display formatted dialog content
 * @WhereIsItUsed popup-dialog
 */
@Component({
  selector: 'jb-dialog-temp-content',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        height: 78%;
      }
    `,
  ],
})
export class DialogTempContentComponent {
  @HostBinding('class.pt5')
  @Input()
  hasPadding = true;
}
