import { TypedDictionary } from '@core/utils';

import { MfaCatalogResponse } from './types/mfa-catalog-response.type';
import { UserFactor } from './types/mfa-state.type';

export function factorCatalogTransform(
  factorCatalogResponse: MfaCatalogResponse[],
): TypedDictionary<UserFactor> {
  return factorCatalogResponse.reduce((accumulatedFactors, currentFactor) => {
    return {
      ...accumulatedFactors,
      [currentFactor.factorType]: {
        factorType: currentFactor.factorType,
        status: currentFactor.status,
        enrollment: currentFactor.enrollment,
        factorInfo:
          currentFactor.factorType === 'sms'
            ? currentFactor._embedded?.phones[0]?.profile?.phoneNumber
            : currentFactor._embedded?.emails[0]?.profile?.email,
        factorId:
          currentFactor.factorType === 'sms'
            ? currentFactor._embedded?.phones[0]?.id
            : currentFactor._embedded?.emails[0]?.id,
      },
    };
  }, {});
}
