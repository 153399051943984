<div
  class="clendar-err-div pt0 ph4 ph0-ns flex items-center"
  [ngStyle]="{ 'min-height': height }"
>
  <div>
    <jb-text-image [content]="[errContent]"></jb-text-image>
    <div class="gr-unset center">
      <div class="dib col-4-m col-4-l"></div>
      <div class="dib col-4-s col-8-m col-8-l pl2 pl4-ns reload-btn-div">
        <jb-icon
          class="dib mt2"
          name="reload"
          width="1em"
          height="1em"
          stroke="#0033A0"
        ></jb-icon>
        <button
          class="dib pl1 pt1 reload-btn b pointer body"
          (click)="onClickUpdateFares()"
        >
          Reload {{ monthName }} Fares
        </button>
      </div>
    </div>
  </div>
</div>
