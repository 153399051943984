<dot-okta-container>
  <div class="sign-in-skeleton-container">
    <!-- Main Title -->
    <div class="lh-copy mb4 pt5 db">
      <dot-text-block [width]="40" [height]="30"></dot-text-block>
      <dot-text-block class="dn db-ns mt4" [width]="75"></dot-text-block>
    </div>

    <!-- First Input Container -->
    <div class="mb4">
      <!-- First Input Label -->
      <div>
        <dot-text-block [width]="25" [height]="15"></dot-text-block>
        <dot-text-block class="dn db-ns mb2" [width]="75"></dot-text-block>
      </div>

      <!-- First Input -->
      <dot-text-block
        radius="0"
        bgColor="white"
        class="flex-1 b--medium-gray ba jb-input-height"
      ></dot-text-block>
    </div>

    <!-- Second Input Container -->
    <div>
      <!-- Second Input Label -->
      <div>
        <dot-text-block [width]="25" [height]="15"></dot-text-block>
        <dot-text-block class="dn db-ns mb2" [width]="75"></dot-text-block>
      </div>

      <!-- Second Input -->
      <dot-text-block
        radius="0"
        bgColor="white"
        class="flex-1 b--medium-gray ba jb-input-height mb3"
      ></dot-text-block>
    </div>

    <!-- Submit Button -->
    <div class="db mt4">
      <button
        class="w-100-important submit-button-skeleton"
        jbButton
        theme="ctaPrimary"
        [isRounded]="true"
        type="button"
      >
        <jb-icon class="fill-white" name="loading"></jb-icon>
      </button>
    </div>
  </div>
</dot-okta-container>
