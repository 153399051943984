import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[jbFocusInvalid]',
})
export class FocusInvalidDirective {
  constructor(private el: ElementRef) {}

  @HostListener('submit')
  onFormSubmit() {
    const invalidInput = this.el.nativeElement.querySelector(
      '.ng-invalid:not(.skip-error-focus)',
    );

    if (invalidInput) {
      invalidInput.focus();
    }
  }
}
