import { Directive, HostListener, Input } from '@angular/core';

import { DialogService } from '../dialog-service';

@Directive({
  selector: '[jbCloseDialog]',
})
export class CloseDialogDirective {
  @Input('moveFocusBack') moveFocusBack = true;
  set toMoveFocusBack(moveFocusBack) {
    this.moveFocusBack = moveFocusBack;
  }
  constructor(private dialogService: DialogService) {}
  @HostListener('click', ['$event'])
  handleClose(event) {
    event.stopPropagation();
    this.dialogService.emitDialogCloseEvent(this.moveFocusBack);
  }
}
