const autoLoadDuration = 4; // In Seconds
const eventList = [
  'keydown',
  'mousemove',
  'wheel',
  'touchmove',
  'touchstart',
  'touchend',
];

export const runScripts = () => {
  if (typeof window === 'undefined') {
    // req for ssr
    return;
  }
  const delayedScripts = document.querySelectorAll('script[delay]');
  if (delayedScripts) {
    delayedScripts.forEach((scriptTag: HTMLElement) => {
      scriptTag.setAttribute('src', scriptTag.getAttribute('delay'));
      scriptTag.removeAttribute('delay');
    });
  }
};

const autoLoadTimeout = setTimeout(runScripts, autoLoadDuration * 1000);

export const triggerScripts = () => {
  if (typeof window === 'undefined') {
    // req for ssr
    return;
  }
  runScripts();
  clearTimeout(autoLoadTimeout);
  eventList.forEach(event => {
    window.removeEventListener(event, triggerScripts);
  });
};

export const delayJavascript = () => {
  if (typeof window === 'undefined') {
    // req for ssr
    return;
  }
  eventList.forEach(event => {
    window.addEventListener(event, triggerScripts, {
      passive: true,
    });
  });
};
