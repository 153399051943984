import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppConfigService } from '@core/app-config';
import {
  CONFIG_GUEST_CART_TOKEN,
  CONFIG_X_AUTH_TOKEN,
} from '@core/app-config/types/mocks/app-config-constants';
import { ApiError } from '@core/http';
import { WINDOW } from '@core/injection-tokens';
import { CookieStorageService, LocalStorageService } from '@core/storage';
import { WidgetService } from '@core/widgets';
import { Link } from '@shared/ui';
import { deepMarkAsDirty } from '@shared/ui/utils/form-control-utils/deep-mark-as-dirty';
import { generateUniqueId } from '@shared/ui/utils/global-utils/generate-unique-id';
import { emailValidator } from '@shared/validators';
import { AnalyticsFacade } from '@store/analytics/analytics.facade';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { ForgotPasswordFacade } from '@store/forgot-password/forgot-password.facade';
import { Subscription } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';

/* eslint-disable max-len */
import { TrueblueResponse } from '../types';

/**
 * @description Form for a user to login
 * @WhereIsItUsed trueblue component
 */
@Component({
  selector: 'jb-tb-login',
  templateUrl: './tb-login.component.html',
  styleUrls: ['./tb-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TbLoginComponent implements AfterViewInit, OnInit, OnDestroy {
  tbForm: UntypedFormGroup;
  errorMessage = '';
  submitting = false;
  success = false;
  genErrFocus: boolean;
  emailId: string;
  passwordId: string;
  profile: AuthProfile;
  clm5: boolean;
  showCaptcha = false;
  joinUsLink: string;
  forgotPasswordLink: string;
  privacyPolicyLink: Link;
  isChecked = false;
  private failedAttempts = 0;
  @Input() nextPage = '';
  @Input() isSignInPage = false;
  @Input() trueBlueResponse: TrueblueResponse;

  @ViewChild('genError', { static: true }) genErrorRef: ElementRef;
  @ViewChild('emailRef', { static: true }) emailRef: ElementRef;
  @ViewChild('passwordRef', { static: true }) passwordRef: ElementRef;
  @ViewChild('captchaRef') captchaRef: ElementRef;
  @ViewChild('submitButton', { read: ElementRef })
  submitButton: ElementRef;

  private subscriptions: Subscription = new Subscription();

  loginErrorMessage$ = this.authFacade.loginError.pipe(
    skip(1),
    filter(Boolean),
    map((error: ApiError) => this.handleLoginError(error)),
  );

  constructor(
    public zone: NgZone,
    @Inject(WINDOW) private window: any,
    private analyticsFacade: AnalyticsFacade,
    private authFacade: AuthFacade,
    private cookieStorageService: CookieStorageService,
    private formBuilder: UntypedFormBuilder,
    private appConfig: AppConfigService,
    private widgetService: WidgetService,
    public forgotPasswordFacade: ForgotPasswordFacade,
    private localStorageService: LocalStorageService,
  ) {
    this.emailId = generateUniqueId('login-email');
    this.passwordId = generateUniqueId('password-email');
    this.createTrueBlueForm();

    if (this.cookieStorageService.shouldEnableCaptcha()) {
      this.tbForm.addControl(
        'recaptchaReactive',
        new UntypedFormControl(null, Validators.required),
      );
      this.tbForm.get('recaptchaReactive').markAsDirty();
      // DO NOT REMOVE the zone here, it is a firefox fix
      // to show the captcha when history and data got removed
      this.zone.run(() => (this.showCaptcha = true));
    }
  }

  get email() {
    return this.tbForm.get('email');
  }

  get password() {
    return this.tbForm.get('password');
  }

  get emailErrorMessage() {
    return this.getErrorMessage('email');
  }

  get passwordErrorMessage() {
    return this.getErrorMessage('password');
  }

  resolved(captchaResponse: string) {
    console.info(`Resolved captcha with response ${captchaResponse}:`);
    this.cookieStorageService.setCaptchaTokenCookie(captchaResponse);
  }

  isCaptchInvalid(): boolean {
    return (
      this.tbForm.get('recaptchaReactive').invalid &&
      this.tbForm.get('recaptchaReactive').pristine
    );
  }

  ngOnInit() {
    this.joinUsLink = `${
      this.appConfig.baseTrueBlue
    }/enroll/join-us?returnUrl=${this.window.encodeURIComponent(
      this.nextPage,
    )}`;

    // CLM5 feature flag
    this.clm5 = this.appConfig.clm5;

    this.forgotPasswordLink = '/forgot-password/email-entry';
  }

  ngAfterViewInit() {
    if (this.errorMessage && !this.genErrFocus) {
      this.genErrorRef.nativeElement.focus();
      this.tbForm.markAsPristine();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateFocus() {
    this.genErrFocus = true;
  }

  updateAutoFillToFormControls() {
    /* this code is a hack to fix ios chrome issue with
      saved credentails auto-population to fields */
    if (this.emailRef.nativeElement.value) {
      this.email.setValue(this.emailRef.nativeElement.value);
    }
    if (this.passwordRef.nativeElement.value) {
      this.password.setValue(this.passwordRef.nativeElement.value);
    }
  }

  login() {
    this.isChecked = false;
    this.updateAutoFillToFormControls();

    this.analyticsLoginFormError();
    if (this.password.invalid && this.email.valid) {
      this.tbForm.markAsPristine();
      this.errorMessage = '';
      this.passwordRef.nativeElement.focus();
    } else if (this.email.invalid) {
      this.tbForm.markAsPristine();
      this.errorMessage = '';
      this.emailRef.nativeElement.focus();
    } else if (
      !!this.tbForm.get('recaptchaReactive') &&
      this.tbForm.get('recaptchaReactive').invalid
    ) {
      this.tbForm.markAsPristine();
      this.errorMessage = '';
      this.captchaRef.nativeElement.focus();
      this.loginDetails(this.trueBlueResponse.login[0].formErrorMessage);
    } else {
      this.tbForm.markAsPristine();
      this.errorMessage = '';
      const { email, password } = this.tbForm.value;
      if (!this.submitting) {
        const cartJWTCookie = this.cookieStorageService.getCookie(
          CONFIG_GUEST_CART_TOKEN,
        );
        const xAuthToken: string =
          this.localStorageService.getItem(CONFIG_X_AUTH_TOKEN);

        /*
        Case guest cart does not exist and user is attempting to sign in.
        Clear x-auth-token. This is no longer valid.
        */
        if (!cartJWTCookie && xAuthToken) {
          this.localStorageService.removeItem(CONFIG_X_AUTH_TOKEN);
        }

        this.submitting = true;
        this.success = false;
        this.submitButton.nativeElement.blur();
        this.submitButton.nativeElement.focus();
        this.authFacade.loginUser(email, password);
        this.tbForm.markAsPristine();
      }
    }
  }

  /*
    Before redirecting the user to the Forgot Password.
    Set the email that he was trying to authenticate in our store.
    We are going to get this email later in the forgot password page.
  */
  beforeRedirectToForgotPassword() {
    if (this.tbForm.get('email').status !== 'INVALID') {
      this.forgotPasswordFacade.setEmailFromSignInPage(
        this.tbForm.get('email').value,
      );
    }
  }

  analyticsLoginFormError() {
    if (this.tbForm.invalid) {
      const errorMessage =
        this.email.status === 'INVALID' && this.password.status === 'INVALID'
          ? `${this.emailErrorMessage}, ${this.passwordErrorMessage}`
          : this.email.status === 'INVALID'
          ? this.emailErrorMessage
          : this.passwordErrorMessage;
      this.loginDetails(errorMessage);
    }
  }

  getErrorMessage(control: 'email' | 'password') {
    return this.tbForm.get(control).hasError('required')
      ? this.trueBlueResponse.login[0][control][0].errorMessages[0].required
      : this.trueBlueResponse.login[0][control][0].errorMessages[0].custom;
  }

  loginDetails(message) {
    this.analyticsFacade.tbLoginDetails(message);
  }

  private createTrueBlueForm() {
    const group = {
      email: [undefined, [Validators.required, emailValidator]],
      password: [undefined, [Validators.required, Validators.minLength(8)]],
    };
    this.tbForm = this.formBuilder.group(group);
    // mark form as dirty so that errors don't display before user attempts to submit form
    deepMarkAsDirty(this.tbForm);
  }

  private handleLoginError(error: ApiError): string {
    if (!!error) {
      const errorStatus = error.httpStatus;
      const apiErrorString = errorStatus ? errorStatus.toString() : '';
      if (apiErrorString === '401' || '500' || '501') {
        this.genErrFocus = false;
        this.submitting = false;
      }
      const defaultError = 'An error has occurred. Please retry.';
      this.errorMessage =
        this.trueBlueResponse?.login[0]?.formErrorMessage ||
        error.message ||
        defaultError;
      setTimeout(() => this.genErrorRef.nativeElement.focus(), 0);
    } else {
      this.errorMessage = '';
    }

    this.failedAttempts++;
    if (this.failedAttempts > 1) {
      this.widgetService.unhideChat();
    }

    this.tbForm.markAsPristine();
    return this.errorMessage;
  }
}
