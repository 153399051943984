import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIModule } from '../ui';
// import { CoreModule } from '../../core';
import { LegendComponent } from './legend.component';

const MODULES = [
  CommonModule,
  // CoreModule,
  UIModule,
];

const DECLARATIONS = [LegendComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class LegendModule {}
