import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BodyTextImageContainerModule } from '@dynamic-components/body-text-image-container';

import { JBPageHeaderModule } from '../../shared/temp-jb-page-header/temp-jb-page-header.module';
import { NotFoundPageComponent } from './not-found-page.component';
import { notFoundPageRoutes } from './not-found-page-routing.module';

const MODULES = [
  CommonModule,
  JBPageHeaderModule,
  BodyTextImageContainerModule,
];

const DECLARATIONS = [NotFoundPageComponent];

@NgModule({
  imports: [...MODULES, notFoundPageRoutes],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class NotFoundPageModule {}
