import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { PersonalizationService } from '@core/cms/personalization/personalization.service';
import { LanguageService } from '@core/language/language.service';
import { createEffect } from '@ngrx/effects';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { BookerFacade } from '@store/booker/booker.facade';
import { GlobalFacade } from '@store/global/global.facade';
import { RouterEffects } from '@store/router/router.effects';
import { RouterFacade } from '@store/router/router.facade';
import { combineLatest, from as observableFrom, switchMap } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  share,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class ExperimentalTranslationEffect {
  isExperimentalTranslationEnabled = booleanStringCheck(
    this.appConfig.getConfigValueFromLocalOrCMSSource(
      'i18n.useExperimentalTranslation',
    ),
  );

  onLanguageChangeGetTemplateData$ = this.languageService.language$.pipe(
    filter(() => this.isExperimentalTranslationEnabled),
    withLatestFrom(
      this.bookerFacade.currentOrigin$(false),
      this.routerFacade.routeData,
      this.routerFacade.queryParams,
      (language, origin, routeData, queryParams) => ({
        ...routeData,
        origin,
        queryParams,
      }),
    ),
    share(),
  );

  constructor(
    private appConfig: AppConfigService,
    private languageService: LanguageService,
    private bookerFacade: BookerFacade,
    private routerFacade: RouterFacade,
    private globalFacade: GlobalFacade,
    // would rather not pull in another effect but a method exists on it that I would like to use
    private routerEffects: RouterEffects,
    private personalizationService: PersonalizationService,
  ) {}

  // executed everytime we switch languages from the footer
  requestExperimentalTranslationBodyEffect = createEffect(() =>
    combineLatest(
      // this only executes after user switches language
      this.onLanguageChangeGetTemplateData$,
      // this ALWAYS execute during bootstrap
      this.routerEffects.waitForLeanProfile$.pipe(debounceTime(150)),
      // this run last, after router is init
      this.routerEffects.setPageTitle$, // return routeData (from sitemap)
    ).pipe(
      switchMap(
        ([
          requestTemplatePayload,
          isLeanProfile,
          { routeData, queryParams },
        ]) => {
          // this runs after onLanguageChangeGetTemplateData$ is updated
          return this.personalizationService
            .getPersonalizationData(routeData, isLeanProfile)
            .pipe(
              map(personalizationData => ({
                ...routeData,
                ...personalizationData,
                queryParams,
              })),
            );
        },
      ),
      mergeMap(requestData =>
        observableFrom([
          this.globalFacade.setLastRequestedEndpoint(requestData.endpoint),
          this.globalFacade.requestTemplate(requestData), // request cms template in spanish or french
        ]),
      ),
    ),
  );

  // copied from requestExperimentalTranslationBodyEffect above
  // required to pass personalizations to header request when user switches to spanish or french
  requestExperimentalTranslationHeaderEffect = createEffect(() =>
    combineLatest(
      // this only executes after user switches language
      this.onLanguageChangeGetTemplateData$,
      // this observables always execute during bootstrap
      this.routerEffects.waitForLeanProfile$.pipe(debounceTime(150)),
      this.personalizationService.getGlobalPersonalizationData(), // returns global personalizations from home page in sitemap
      // runs last, after router is init
      this.routerEffects.setPageTitle$, // return queryParams
    ).pipe(
      switchMap(
        ([
          requestTemplatePayload,
          isLeanProfile,
          { routeData },
          { queryParams },
        ]) => {
          // this runs after onLanguageChangeGetTemplateData$ is updated
          return this.personalizationService
            .getPersonalizationData(routeData, isLeanProfile)
            .pipe(
              map(personalizationData => ({
                ...routeData,
                ...personalizationData,
                queryParams,
              })),
            );
        },
      ),
      mergeMap(requestData =>
        observableFrom([
          this.globalFacade.requestHeader(requestData), // request cms template in spanish or french
        ]),
      ),
    ),
  );

  // copied from requestExperimentalTranslationBodyEffect above
  // required to pass personalizations to footer request when user switches to spanish or french
  requestExperimentalTranslationFooterEffect = createEffect(() =>
    combineLatest(
      // this only executes after user switches language
      this.onLanguageChangeGetTemplateData$,
      // this observables always execute during bootstrap
      this.routerEffects.waitForLeanProfile$.pipe(debounceTime(150)),
      this.personalizationService.getGlobalPersonalizationData(), // returns global personalizations from home page in sitemap
      this.routerEffects.setPageTitle$, // return queryParams
    ).pipe(
      switchMap(
        ([
          requestTemplatePayload,
          isLeanProfile,
          { routeData },
          { queryParams },
        ]) => {
          // this runs after onLanguageChangeGetTemplateData$ is updated
          return this.personalizationService
            .getPersonalizationData(routeData, isLeanProfile)
            .pipe(
              map(personalizationData => ({
                ...routeData,
                ...personalizationData,
                queryParams,
              })),
            );
        },
      ),
      mergeMap(requestData =>
        observableFrom([
          this.globalFacade.requestFooter(requestData), // request cms template in spanish or french
        ]),
      ),
    ),
  );
}
