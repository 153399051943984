export const CONFIG_LOCALSTORAGE_SYNC_ENABLE =
  'dotcomFetchConfigurationFromLocalstorage';
export const CONFIG_LOCALSTORAGE_KEY = 'dotcomConfigurationProperties';

// Used to change authentication method for OKTA Idx.
export const CONFIG_AUTH_IS_IDX_LOCALSTORAGE = 'authJBIsIdxMethod';

// Used to fetch and create Auth User Cart
export const CONFIG_X_AUTH_TOKEN = 'x-auth-token';

// Used to fetch Guest Cart
export const CONFIG_GUEST_CART_TOKEN = 'cartJwtToken';

export const PERSONALIZATION_DEFAULT_ORDER =
  'birthday,currentLang,countryCode,targetedOrigin,visitor,mosaicStatus,firstVisit,recentSearch,searchDestination,cardType,hasUpcomingTrip,trueBluePointsBalance,travelBankBalance,lastVisitSixMonths';
