import { AppConfigService } from '@core/app-config';
import { LanguageService } from '@core/language/language.service';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

export function localStorageFactory() {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  try {
    return localStorage;
  } catch (error) {
    console.warn(`Failed to create LocalStorage`, error);
  }
}

export function sessionStorageFactory() {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  // Firfox fails to catch this try block
  // try block fails if cookies are disabled
  if (
    !navigator.cookieEnabled &&
    !!navigator.userAgent &&
    navigator.userAgent.indexOf('Firefox') !== -1
  ) {
    console.warn(
      `Failed to create sessionStorage because cookies are disabled on Firefox`,
    );
  } else {
    try {
      return sessionStorage;
    } catch (error) {
      console.warn(`Failed to create sessionStorage`, error);
    }
  }
}

export function configFactory() {
  if (typeof window === 'undefined') {
    // to-do return server config object for ssr
    return null;
  }
  return window['__DOTCOM_ENV_CONFIG'];
}

export function initialSitemapFactory() {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  return (
    window['__SITEMAP'] || {
      eTag: '',
      sitemap: [],
    }
  );
}
export function initialFlattenedSitemapFactory(
  overrideSitemap: Record<string, string> = {},
  appConfigService: AppConfigService,
) {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  let sitemapDictionary = window['__FLATTENED_SITEMAP'];
  const blackListSitemapPaths = appConfigService.blackListSitemapPaths;

  if (sitemapDictionary && appConfigService.blackListSitemapPaths?.length > 0) {
    sitemapDictionary = Object.fromEntries(
      Object.entries(window['__FLATTENED_SITEMAP']).filter(([key, _]) => {
        const isBlackListed = blackListSitemapPaths?.some(path => path === key);
        return isBlackListed ? false : true;
      }),
    );
  }
  return { ...(sitemapDictionary || {}), ...overrideSitemap } || {};
}

export function sitemapDictionaryFactory(
  appConfigService: AppConfigService,
  evenMoreRoutes: Record<string, string> = {},
) {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  const additionalSitemapRoutes = evenMoreRoutes;
  /*
  It is not possible to add in our CMS APPCONFIG special characters in the object's key..
  To be more specific, "/" is not valid in our CMS. E.g: "/forgot-password/email-entry" - Is not valid.
  To be able to solve it. We decided to pass in our CMS "_" instead of "/".
  So, we are converting all "_" to "/". Creating in that way a valid route.
  */
  if (appConfigService?.additionalSitemapRoutes) {
    Object.keys(appConfigService.additionalSitemapRoutes).forEach(
      (key: string) => {
        additionalSitemapRoutes[key.split('_').join('/')] =
          appConfigService.additionalSitemapRoutes[key];
      },
    );
  }

  return {
    ...initialFlattenedSitemapFactory(
      additionalSitemapRoutes,
      appConfigService,
    ),
  };
}

export function abtestConfigFactory() {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  return { ...(window['__ABTEST_CONFIG'] || {}) };
}

export function windowFactory() {
  return typeof window === 'undefined' ? null : window;
}

export function documentFactory() {
  return typeof window === 'undefined' ? null : document;
}

/* export function workerFactory() {
  return typeof window === 'undefined' ? null : Worker;
}*/

export function hostFactory() {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  return `${window.location.protocol}//${window.location.host}` || '';
}

export function captchaFactory(config) {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  return {
    siteKey: config['widgets']['captchaConfig']['siteKey'],
  };
}

/**
 * Following the OKTA Documentation to initialize OktaAuth that will be used on `okta.service.ts`.
 * OKTA Docs: @see {@link https://developer.okta.com/docs/guides/sign-in-to-spa-embedded-widget/angular/main/#load-the-sign-in-widget}
 */
export function oktaFactory(
  appConfigService: AppConfigService,
  languageService: LanguageService,
) {
  if (typeof window === 'undefined') {
    // req for SSR
    return null;
  }
  const isDotcomFrenchRedirect: boolean =
    languageService.getLanguage() === 'fr';

  const dotcomBaseUri = `${window.location.protocol}//${window.location.host}`;
  const dotcomRedirectUri = isDotcomFrenchRedirect
    ? `${dotcomBaseUri}/fr/login-callback`
    : `${dotcomBaseUri}/login-callback`;

  /**
   * Some applications may provide a clientId, issuer and or redirectUri through query parameters.
   * Ref: DOT-10970.
   */
  const params = new URLSearchParams(window.location.search);
  const clientId: string =
    params.get('client_id') || appConfigService.okta.auth.client_id;
  const codeChallenge: string = params.get('code_challenge');
  const codeChallengeMethod: string = params.get('code_challenge_method');
  const issuer: string =
    params.get('issuer') || appConfigService.okta.auth.issuer;
  const redirectUri: string = params.get('redirect_uri') || dotcomRedirectUri;
  const state = params.get('state');
  const scopes = params.get('scope')?.split(' ');
  // Set up OKTA config.
  const oktaConfig: OktaAuthOptions = {
    clientId,
    codeChallenge,
    codeChallengeMethod,
    issuer,
    redirectUri,
    state,
    scopes,
    tokenManager: {
      expireEarlySeconds: appConfigService.okta.auth.expire_early_time,
    },
  };
  const oktaAuth = new OktaAuth(oktaConfig);
  return {
    oktaAuth,
    // SPIKE: DOT-10772 - Is it possible to use OKTA Guards to detect if user is authenticated on preference-center?
    // onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
    //   const router = injector.get(Router);
    //   // Redirect the user to your custom login page
    //   router.navigate(['/login']);
    // },
  };
}
