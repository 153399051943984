import { Component, Input } from '@angular/core';
import { AppConfigService } from '@core/app-config';

import { PodContent } from './jb-content-pod.type';

// TODO: determine if this can be deleted
/**
 * @description Component for displaying an image, content and link. Purpose unknown.
 * @WhereIsItUsed Marketing secondary component
 */
@Component({
  selector: 'jb-content-pod',
  templateUrl: './jb-content-pod.component.html',
  styleUrls: ['./jb-content-pod.component.scss'],
})
export class JbContentPodComponent {
  @Input() content: PodContent;
  enableLazyLoadImage: boolean;

  constructor(public appConfigService: AppConfigService) {
    this.enableLazyLoadImage = appConfigService.lazyLoadImages;
  }
}
