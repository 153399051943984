<!-- Top Section -->
<div class="pa4 pv3-ns">
  <a
    jbButton
    href="javascript:void(0)"
    [theme]="signInButton?.theme === 'dark' ? 'ctaSecondary' : 'ctaPrimary'"
    [target]="signInButton?.newWindow || '_self'"
    (click)="signIn()"
    class="desktop-button"
  >
    {{ signInButton?.title }}
  </a>
</div>
<hr class="bt-0 bl-0 br-0 bb b--medium-gray mh0 mt0 mb2" />
<!-- Middle Section -->
<div class="pa4 pv3-ns">
  <jb-body-text
    jbSitemapNestedLinker
    [content]="[middleSection]"
  ></jb-body-text>

  <a
    jbButton
    [jbSitemapLinker]="joinNowButton?.href"
    [target]="joinNowButton?.newWindow"
    [preventDefault]="false"
    theme="standard"
    class="desktop-button"
  >
    {{ joinNowButton?.title }}
  </a>
  <div class="flex flex-column items-center pt3 pb2-ns">
    <a
      jbActionLink
      [jbSitemapLinker]="benefitsLink?.href"
      [preventDefault]="false"
    >
      <span>
        {{ benefitsLink?.title }}
        <jb-icon class="pl1 fill-royal-blue" name="rightFilled"></jb-icon>
      </span>
    </a>
  </div>
</div>
<!-- Bottom Section -->
<dot-tb-bottom-section [content]="bottomSection"></dot-tb-bottom-section>
