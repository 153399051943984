import { Action } from '@ngrx/store';

import { Flight } from './types';

export enum ItineraryActions {
  CHECK_FOR_ITINERARY = '[Itinerary Components] Check for itinerary',
  ITINERARY_FOUND = '[Itinerary API] Itinerary found',
  ITINERARY_ERROR = '[Itinerary API] Itinerary error',
}

export class CheckForItinerary implements Action {
  readonly type = ItineraryActions.CHECK_FOR_ITINERARY;
}

export class ItineraryFound implements Action {
  readonly type = ItineraryActions.ITINERARY_FOUND;
  constructor(public payload: Flight[]) {}
}

export class ItineraryError implements Action {
  readonly type = ItineraryActions.ITINERARY_ERROR;
  constructor(public payload: object) {}
}

export type ItineraryActionsUnion =
  | CheckForItinerary
  | ItineraryFound
  | ItineraryError;
