import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SvgIconService {
  private iconsByUrl = new Map<string, SafeHtml>();

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  getSvg(source: string) {
    return this.iconsByUrl.get(source);
  }

  addSvg(source: string): void {
    if (!this.iconsByUrl.get(source)) {
      this.httpClient
        .get(source, { responseType: 'text' })
        .pipe(take(1))
        .subscribe(response => {
          const svgSource = this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.sanitizer.bypassSecurityTrustHtml(response),
          );
          this.iconsByUrl.set(source, svgSource);
        });
    }
  }
}
