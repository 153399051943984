import { ItineraryActions, ItineraryActionsUnion } from './itinerary.actions';
import { ItineraryState } from './types';

export const initialItineraryState: ItineraryState = {
  flight: [],
  hasUpdated: false,
  error: null,
};

export function itineraryReducer(
  state: ItineraryState = initialItineraryState,
  action: ItineraryActionsUnion,
) {
  switch (action.type) {
    case ItineraryActions.ITINERARY_FOUND:
      return {
        ...state,
        flight: action.payload,
        hasUpdated: true,
        error: null,
      };

    case ItineraryActions.ITINERARY_ERROR:
      return {
        ...state,
        flight: [],
        error: action.payload,
        hasUpdated: false,
      };

    default:
      return state;
  }
}
