import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { AppState } from '../shared/types/app-state.type';
import { ForgotPasswordState } from './types';

export const forgotPassword: MemoizedSelector<AppState, ForgotPasswordState> =
  createFeatureSelector('forgotPassword');

export const forgotPasswordEmailEntryError = createSelector(
  forgotPassword,
  state => state.emailEntry.isError,
);

export const forgotPasswordStateError = createSelector(
  forgotPassword,
  state => state.enterNewPassword.error,
);

export const forgotPasswordIsLoading = createSelector(
  forgotPassword,
  state => state.enterNewPassword.isLoading,
);

export const forgotPasswordEmailEntryIsLoading = createSelector(
  forgotPassword,
  state => state.emailEntry.isLoading,
);

export const mostRecentTraceId = createSelector(
  forgotPassword,
  state => state.mostRecentTraceId,
);

export const emailFromSignInPage = createSelector(
  forgotPassword,
  state => state.emailFromSignInPage,
);
