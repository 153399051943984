import { AbstractControl } from '@angular/forms';
import { equals } from 'ramda';
import { distinctUntilChanged, Observable, startWith } from 'rxjs';

/**
 * Function that returns an Observable with the Form Control value.
 * @param {AbstractControl} control form control
 * @param {any} startVal initial value as optional
 * @returns { Observable<any>}
 */
export function getControlVal(
  control: AbstractControl,
  startVal?: any,
): Observable<any> {
  const start = !!startVal ? startVal : control.value;
  return control.valueChanges.pipe(
    startWith(start),
    distinctUntilChanged(equals),
  );
}
