import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { HttpService } from '@core/http';
import { CookieStorageService } from '@core/storage';
import { TypedDictionary } from '@core/utils';
import { createErrorAction, networkError } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import { NetworkError } from '@store/shared/types/network-error.type';
import { merge, Observable, of as observableOf } from 'rxjs';
import {
  catchError,
  delay,
  map,
  retryWhen,
  share,
  tap,
  timeout,
} from 'rxjs/operators';

import { OriginsActions } from '../origins.actions';
import {
  Airport,
  AirportResponseType,
  CountryResponse,
  CountryWithAirportCodes,
  DestinationAirportResponse,
  OriginAirportResponse,
  Region,
  RegionsResponse,
} from '../types';
import { AirportListAndMap } from '../types/airport-list-and-map.type';
import { NearbyAirportsResponse } from '../types/nearby-airports.type';
import {
  extractRegionsData,
  scanErrorCount,
  updateNeedleWithMacInfoForListAndMapAndSort,
} from './origins.utils';

@Injectable({ providedIn: 'root' })
export class OriginsApi {
  private odServiceOriginsUrl: string;
  private odServiceRegionsUrl: string;
  private staticStorageUrl: string;
  private odServiceNearbyAirportsUrl: string;
  private odServiceRegionsCountriesUrl: string;
  private originUrl: string;
  private routesUrl: string;
  private httpRetryMaxCount: number;
  private httpRetryDelay: number;

  constructor(
    private httpService: HttpService,
    private appConfig: AppConfigService,
    private storeService: StoreService,
    private cookieStorageService: CookieStorageService,
  ) {
    this.staticStorageUrl = this.appConfig.staticStorageUrl;
    this.odServiceRegionsUrl = this.appConfig.odServiceRegionsUrl;
    this.odServiceOriginsUrl = this.appConfig.odServiceOriginsUrl;
    this.odServiceNearbyAirportsUrl = this.appConfig.odServiceNearbyAirportsUrl;
    this.odServiceRegionsCountriesUrl =
      this.appConfig.odServiceRegionsCountriesUrl;
    this.originUrl = `${this.odServiceOriginsUrl}?isLatLong=true`;
    this.routesUrl = this.appConfig.odServiceRoutesUrl;
    this.httpRetryMaxCount = 2;
    this.httpRetryDelay = 3000;
  }

  getNearbyAirports(): Observable<string[]> {
    // To test, replace active `latestNearbyAirports` with commented `latestNearbyAirports`
    // const latestNearbyAirports = observableOf<any>({ airports: ['YTZ', 'BUF'], countryCode: 'CA' }).pipe(
    //   tap(() => { console.log("latestNearbyAirports test : http get") }),
    //   share()
    // );
    const latestNearbyAirports = this.httpService
      .post<NearbyAirportsResponse>(this.odServiceNearbyAirportsUrl, {})
      .pipe(timeout(this.httpRetryDelay), share());

    const errorHandler =
      (
        action:
          | OriginsActions.LOAD_NEARBY_AIRPORTS
          | OriginsActions.LOAD_COUNTRY_CODE_FOR_COOKIE_POLICY,
      ) =>
      error => {
        this.storeService.dispatchAction(createErrorAction(action, error));
        return observableOf(networkError());
      };

    // Nearby Airports & Country code pair.
    const nearbyAirportsInCookie =
      this.cookieStorageService.getNearbyAirportsCookie();

    const nearbyAirports = latestNearbyAirports.pipe(
      map(response => response.data.airports.map(airport => airport.code)),
      tap(airports => {
        this.cookieStorageService.setNearbyAirportsCookie(airports);
      }),
      catchError(errorHandler(OriginsActions.LOAD_NEARBY_AIRPORTS)),
    );

    return merge(
      observableOf(nearbyAirportsInCookie),
      nearbyAirports,
    ) as Observable<string[]>;
  }

  getAirports(): Observable<AirportListAndMap | NetworkError> {
    return this.httpService.get<OriginAirportResponse>(this.originUrl).pipe(
      retryWhen(e =>
        e.pipe(
          scanErrorCount(this.httpRetryMaxCount),
          delay(this.httpRetryDelay),
        ),
      ),
      map(response => {
        const airports = response.data[AirportResponseType.ORIGIN];
        return updateNeedleWithMacInfoForListAndMapAndSort(airports);
      }),
      catchError(error => {
        this.storeService.dispatchAction(
          createErrorAction(OriginsActions.LOAD_AIRPORTS, error),
        );
        return observableOf(networkError());
      }),
    );
  }

  getCountries(): Observable<CountryWithAirportCodes[] | NetworkError> {
    const url = this.odServiceRegionsCountriesUrl;

    return this.httpService.get<CountryResponse>(url).pipe(
      retryWhen(e =>
        e.pipe(
          scanErrorCount(this.httpRetryMaxCount),
          delay(this.httpRetryDelay),
        ),
      ),
      map(res => (Boolean(res.countries) ? res.countries : ([] as any))),
      catchError(error => {
        this.storeService.dispatchAction(
          createErrorAction(OriginsActions.LOAD_COUNTRIES, error),
        );
        return observableOf(networkError());
      }),
    );
  }

  getRegions(): Observable<TypedDictionary<Region> | NetworkError> {
    return this.httpService.get<RegionsResponse>(this.odServiceRegionsUrl).pipe(
      map(extractRegionsData),
      catchError(error => {
        this.storeService.dispatchAction(
          createErrorAction(OriginsActions.LOAD_REGIONS, error),
        );
        return observableOf(networkError());
      }),
    );
  }

  getDestinationAirportsFromOrigin(
    originCode: string,
    id: string,
  ): Observable<{ id: string; response: Airport[] } | NetworkError> {
    return this.httpService
      .getObserveResponse<DestinationAirportResponse>(
        `${this.routesUrl}/destinations/origin/${originCode}`,
      )
      .pipe(
        map(response => ({
          id,
          response: response.body.data[AirportResponseType.DESTINATION] || [],
        })),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return [
              {
                id,
                response: [],
              },
            ];
          }
          if (id !== 'DEALS') {
            // to avoid bookers and other components from reacting to LOAD_DESTINATION_AIRPORTS dispatch
            this.storeService.dispatchAction(
              createErrorAction(
                OriginsActions.LOAD_DESTINATION_AIRPORTS,
                error.error,
              ),
            );
          }
          return observableOf(networkError());
        }),
      );
  }

  getOriginAirportsFromDestination(
    destinationCode: string,
    id: string,
  ): Observable<{ id: string; response: Airport[] } | NetworkError> {
    return this.httpService
      .get<OriginAirportResponse>(
        `${this.routesUrl}/origins/destination/${destinationCode}`,
      )
      .pipe(
        map(response => ({
          id,
          response: response.data[AirportResponseType.ORIGIN] || [],
        })),
        catchError(error => {
          this.storeService.dispatchAction(
            createErrorAction(OriginsActions.LOAD_ORIGIN_AIRPORTS, {
              ...error,
              id,
            }),
          );
          return observableOf(networkError());
        }),
      );
  }
}
