import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TravelerGroup } from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import { isOneWayTrip } from '@shared/booker/utils';
import { getFullMonthNameFromLocale } from '@shared/calendar/calendar.utils';
import { pathOr } from 'ramda';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from '../app-config';
import { HttpService } from '../http';
import { convertToDate } from '../utils/date.utils';
import { applyLowestFares } from './bff.utils';
import { BFF_SERVICE_CONFIG } from './bff-service.config';
import { BestFare, BestFareAPIResponse, BestFareDateString } from './types';
import { BestFareResult } from './types/best-fares-response.type';

@Injectable({ providedIn: 'root' })
export class BffService {
  private readonly bffUrl: string;
  private readonly bffUrlObj: { latest: string; legacy: string };
  private readonly apiKey: string;

  constructor(
    private http: HttpService,
    private appConfig: AppConfigService,
  ) {
    this.bffUrl = this.appConfig.bffServiceUrl;
    this.bffUrlObj = this.appConfig.bffServiceUrlObj;
    this.apiKey = this.appConfig.bffApiKey;
  }

  /** Get best fares that only uses legacy bff url for request. Temp function
   * intended to be used for best fare finder.
   */
  getBestFares2(
    origin: string,
    destination: string,
    isPoints: boolean,
    month: number,
    year: number,
    tripType: string,
    traveler: TravelerGroup,
    useGetRequest?: boolean,
  ): Observable<BestFareResult> {
    const url = `${this.bffUrl}/bestFares`;
    return this.sendBestFaresRequest(
      url,
      origin,
      destination,
      isPoints,
      month,
      year,
      tripType,
      traveler,
      useGetRequest,
    );
  }

  /** Get best fares using bff url config object. */
  getBestFares(
    origin: string,
    destination: string,
    isPoints: boolean,
    month: number,
    year: number,
    tripType: string,
    traveler: TravelerGroup,
    useGetRequest?: boolean,
  ): Observable<BestFareResult> {
    let url = `${this.bffUrl}/bestFares`;
    if (this.bffUrlObj && this.bffUrlObj.latest && !isPoints) {
      url = `${this.bffUrlObj[this.appConfig.bffCashUrlPointer]}/bestFares`;
    } else if (this.bffUrlObj && this.bffUrlObj.legacy && isPoints) {
      url = `${this.bffUrlObj[this.appConfig.bffPointsUrlPointer]}/bestFares`;
    }
    return this.sendBestFaresRequest(
      url,
      origin,
      destination,
      isPoints,
      month,
      year,
      tripType,
      traveler,
      useGetRequest,
    );
  }

  // take url and parameters provided and make the bff request
  private sendBestFaresRequest(
    url: string,
    origin: string,
    destination: string,
    isPoints: boolean,
    month: number,
    year: number,
    tripType: string,
    traveler: TravelerGroup,
    useGetRequest?: boolean,
  ) {
    const configFaresType = BFF_SERVICE_CONFIG.FARE_TYPES;
    const transformedFaresType = isPoints
      ? configFaresType.POINTS
      : configFaresType.DOLLARS;

    const configTripType = BFF_SERVICE_CONFIG.TRIP_TYPES;
    const transformedTripType = isOneWayTrip(tripType)
      ? configTripType.ONE_WAY
      : configTripType.ROUND_TRIP;

    // Locales were effecting month calls to bestFares API
    const transformedMonth = `${getFullMonthNameFromLocale(
      month,
    ).toUpperCase()} ${year}`;

    const headers = new HttpHeaders({
      apiKey: this.apiKey,
    });

    let requestObservable: Observable<BestFareAPIResponse>;

    if (!useGetRequest) {
      const body = {
        origin,
        destination,
        fareType: transformedFaresType,
        month: transformedMonth,
        tripType: transformedTripType,
        adult: traveler.adults,
        child: traveler.children,
        infant: traveler.infants,
      };
      const postUrl = `${url}?apiKey=${this.apiKey}`;
      requestObservable = this.http.post<BestFareAPIResponse>(postUrl, body, {
        headers,
      });
    } else {
      const getUrl = `${url}/?apiKey=${this.apiKey}&origin=${origin}&destination=${destination}&fareType=${transformedFaresType}&month=${transformedMonth}&tripType=${transformedTripType}&adult=${traveler.adults}&child=${traveler.children}&infant=${traveler.infants}`;
      requestObservable = this.http.get<BestFareAPIResponse>(getUrl, {
        headers,
      });
    }

    return requestObservable.pipe(
      map(this.extractFares),
      catchError(err =>
        observableOf({
          apiFailure: true,
          err,
        } as any),
      ),
    );
  }

  public extractFares(res: BestFareAPIResponse): BestFareResult {
    const outboundFares: BestFareDateString[] =
      pathOr([], ['outboundFares'], res) || [];
    const inboundFares: BestFareDateString[] = pathOr(
      [],
      ['inboundFares'],
      res,
    );
    const outboundFaresWithDates: BestFare[] = outboundFares.map(fare => ({
      ...fare,
      date: convertToDate(fare.date),
    }));
    const fares = {
      outbound: applyLowestFares(outboundFaresWithDates),
      currencyCode: res.currencyCode || 'USD',
    };
    if (!!inboundFares) {
      const inboundFaresWithDates: BestFare[] = inboundFares.map(fare => ({
        ...fare,
        date: convertToDate(fare.date),
      }));
      fares['inbound'] = applyLowestFares(inboundFaresWithDates);
    }
    return fares;
  }
}
