import { Component, Input } from '@angular/core';
import { BestFareDay, WindowSize } from '@core/index';
import { BffStore } from '@feature/bff/bff.component-store';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProgressIndicatorContent, ProgressIndicatorType } from '../types';
import {
  progressIndicatorBlockInitalState,
  ProgressIndicatorBlockState,
  ProgressIndicatorBlockViewModel,
} from './progress-indicator-block.component.state';

/**
 * @description Display a block of editable information about flight being selected in bff.
 * @WhereIsItUsed progress block
 */
@Component({
  selector: 'jb-progress-indicator-block',
  templateUrl: './progress-indicator-block.component.html',
  providers: [ComponentStore],
})
export class ProgressIndicatorBlockComponent {
  @Input() set content(content: ProgressIndicatorContent) {
    this.store.patchState({ content });
  }
  @Input() set type(type: ProgressIndicatorType) {
    this.store.patchState({ type });
  }
  @Input() set windowSize(windowSize: WindowSize) {
    this.store.patchState({ windowSize });
  }
  @Input() set isActive(isActive: boolean) {
    this.store.patchState({ isActive });
  }
  @Input() set isPoints(isPoints: boolean) {
    this.store.patchState({ isPoints });
  }
  @Input() set isEditable(isEditable: boolean) {
    this.store.patchState({ isEditable });
  }
  @Input() set disable(disable: boolean) {
    this.store.patchState({ disable });
  }
  @Input() set maxSeats(maxSeats: number) {
    this.store.patchState({ maxSeats });
  }
  @Input() set bff(bff: BestFareDay) {
    this.store.patchState({ bff });
  }

  readonly seatCopy$ = this.store.select(
    this.store.select(state => state.bff),
    this.store.select(state => state.content.copy.seatCopy),
    (bff, seatCopy) =>
      bff && bff.seats === 1 ? seatCopy.seat : seatCopy.seats,
  );

  readonly blockLabelCopy$ = this.store.select(
    this.store.select(state => state.content),
    this.store.select(state => state.type),
    (content, type) => content[type],
  );

  readonly vm$: Observable<ProgressIndicatorBlockViewModel> = combineLatest([
    this.store.state$,
    this.seatCopy$,
    this.blockLabelCopy$,
    this.bffStore.currencyCode$,
  ]).pipe(
    map(([state, seatCopy, blockLabelCopy, currencyCode]) => ({
      ...state,
      seatCopy,
      blockLabelCopy,
      currencyCode,
    })),
  );

  constructor(
    private store: ComponentStore<ProgressIndicatorBlockState>,
    private bffStore: BffStore,
  ) {
    this.store.setState(progressIndicatorBlockInitalState);
  }
}
