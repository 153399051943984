import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'jbGetFormControl',
  pure: true,
})
export class GetFormControlPipe implements PipeTransform {
  transform(form: AbstractControl, controlName: string): AbstractControl {
    return form.get(controlName);
  }
}
