import { traverseFlattenPathAware } from '@shared/ui/utils/global-utils/traverse-flatten-path-aware';
import { pipe } from 'ramda';

import { HeaderResponse } from '../types/cms-header.response.type';

type Component = HeaderResponse['component'];
export function parseHeader(input: unknown) {
  return pipe(
    x => x['component'] as Component,
    liftRootEntries,
    flattenAll,
  )(input);
}

type RootEntries = {
  travelAlerts: Component[0]['data']['travelAlerts'];
  jetblueLogo: Component[1]['data']['jetblueLogo'][0];
  subnavSections: Component[2]['data'];
  shoppingCart: Component[4]['data'];
  countdownClock: Component[5]['data'];
  globalNav: Component[6]['data'];
  trueBlue: Component[7]['data'];
};

function liftRootEntries(components: any): RootEntries {
  return components.reduce((acc, component) => {
    /* This is a temporary fix for the CMS discrepancy in the property ‘name’ of trueBlue component.
     MCMS-733 will have  a fix for it*/
    if (component.name === 'trueblue') {
      component.name = 'trueBlue';
    }
    const componentData = (() => {
      switch (component.name) {
        case 'travelAlerts':
          return component.data.travelAlerts;
        case 'jetblueLogo':
          return component.data.jetblueLogo[0];
        case 'subNavSections':
        case 'shoppingCart':
        case 'globalNav':
        case 'trueBlue':
        case 'countdownClock':
        case 'search':
          return component.data;
      }
    })();

    return {
      ...acc,
      [component.name]: componentData,
    };
  }, {});
}

function flattenAll(input: RootEntries) {
  const toFlatten = new Set([
    'travelAlerts.image',
    'jetblueLogo',
    'jetblueLogo.image',
    'subNavSections.link',
    'subNavSections.section',
    'subNavSections.section.leftSection',
    'subNavSections.section.rightSection',
    'subNavSections.section.leftSection.children.image',
    'subNavSections.section.rightSection.children.image',
    'subNavSections.section.leftSection.special',
    'subNavSections.section.rightSection.special',
    'subNavSections.section.children.image',
    'shoppingCart',
    'countdownClock',
    'countdownClock.link',
    'globalNav',
    'trueBlue.loggedInSection',
    'trueBlue.loggedInSection.signoutSection',
    'trueBlue.loggedInSection.topSection',
    'trueBlue.loggedInSection.bottomSection',
    'trueBlue.loggedInSection.middleSection',
    'trueBlue.loggedInSection.topSection.children',
    'trueBlue.loggedInSection.bottomSection.section',
    'trueBlue.loggedInSection.middleSection.section',
    'trueBlue.loggedInSection.signoutSection.children',
    'trueBlue.loggedOutSection',
    'trueBlue.loggedOutSection.bottomSection',
    'trueBlue.loggedOutSection.middleSection',
    'trueBlue.loggedOutSection.topSection',
  ]);

  const parseResult = traverseFlattenPathAware(toFlatten)(input);
  // TODO ? This should ideally be delegated to a tracing subsystem.
  parseResult.conflicts.forEach(x =>
    console.warn('CMS Header Parse Conflict : ', x),
  );
  return parseResult.result;
}
