import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jbBooleanFromString',
  pure: true,
})
export class BooleanFromStringPipe implements PipeTransform {
  transform(value: string | boolean): boolean {
    return typeof value === 'string' ? value === 'true' : Boolean(value);
  }
}
