import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreService } from '../shared/store.service';
import {
  ClearCart,
  FetchCartRequested,
  FetchCartSuccess,
} from './cart.actions';
import * as CartSelectors from './cart.selectors';
import { CartData } from './types';

@Injectable({ providedIn: 'root' })
export class CartFacade {
  constructor(private storeService: StoreService) {}

  isCartEmpty: Observable<boolean> = this.storeService.getState(
    CartSelectors.isCartEmpty,
  );

  dispatchClearCartAction(): void {
    this.storeService.dispatchAction(new ClearCart());
  }

  dispatchFetchCartRequested({
    cartJwtToken,
    xAuthToken,
  }: {
    cartJwtToken?: string;
    xAuthToken?: string;
  }) {
    this.storeService.dispatchAction(
      new FetchCartRequested(cartJwtToken, xAuthToken),
    );
  }

  dispatchFetchCartSuccess(cartData: CartData[]): void {
    this.storeService.dispatchAction(new FetchCartSuccess(cartData));
  }
}
