<div class="jb-max-width center page-margin mt6 mt8-ns mt9-l">
  <div class="jb-grid three-up">
    <div
      class="ba-ns bt bb b--medium-gray flex flex-column overflow-hidden br3-ns pa3"
    >
      <dot-text-block
        class="mt2 mb3 ml2"
        [width]="50"
        [height]="12"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="90"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="88"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="87"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
    </div>
    <div
      class="ba-ns bt bb b--medium-gray flex flex-column overflow-hidden br3-ns pa3"
    >
      <dot-text-block
        class="mt2 mb3 ml2"
        [width]="50"
        [height]="12"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="90"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="88"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="87"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
    </div>
    <div
      class="ba-ns bt bb b--medium-gray flex flex-column overflow-hidden br3-ns pa3"
    >
      <dot-text-block
        class="mt2 mb3 ml2"
        [width]="50"
        [height]="12"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="90"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="88"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2"
        [width]="87"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
    </div>
  </div>
</div>
