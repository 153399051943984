import { Injectable } from '@angular/core';
import { CMSService } from '@core/cms';
import { HttpService } from '@core/http';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { RecentAirSearch } from '@shared/types';
import {
  BookerActions,
  ModifyLastRecentAirSearch,
  SetBookerGlobalSettings,
  SetRecentAirSearches,
  SetRecentLocations,
  UpdateCurrentAirSearch,
} from '@store/booker/booker.actions';
import { BookerFacade } from '@store/booker/booker.facade';
import { LocalStoreRecentLocations } from '@store/booker/types';
import { filter, map, share, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class BookerEffects {
  constructor(
    private actions$: Actions,
    private bookerFacade: BookerFacade,
    private cmsService: CMSService,
  ) {}

  onInit$ = this.actions$.pipe(ofType(ROOT_EFFECTS_INIT), share());

  requestBookerGlobalSettings$ = this.onInit$.pipe(
    switchMap(() => this.cmsService.getBookerGlobalSettings()),
    share(),
  );

  requestBookerGlobalSettingsSuccess$ = createEffect(() =>
    this.requestBookerGlobalSettings$.pipe(
      filter(HttpService.isSuccessful),
      map(result => new SetBookerGlobalSettings(result)),
    ),
  );

  keepUniqueLocations(
    newLocationStateData: RecentAirSearch | Partial<RecentAirSearch>,
    oldLocationStateData: LocalStoreRecentLocations,
  ): LocalStoreRecentLocations {
    const { originCode: originCodes, destinationCode: destinationCodes } =
      newLocationStateData;
    const origins = this.filterAndConcatMostRecentlyUsed(
      originCodes,
      oldLocationStateData,
      'origins',
    );
    const destinations = this.filterAndConcatMostRecentlyUsed(
      destinationCodes,
      oldLocationStateData,
      'destinations',
    );
    return {
      origins,
      destinations,
    };
  }

  filterAndConcatMostRecentlyUsed = (
    codes,
    stateData: LocalStoreRecentLocations,
    type: string,
  ) => {
    return stateData[type].map((flightRow, rowIndex) => {
      let recentList: string[];
      if (!codes) {
        // codes could be undefined as a partial
        return flightRow;
      } else if (codes[rowIndex]) {
        const filteredRow = flightRow.filter(x => x !== codes[rowIndex]);
        recentList = [...filteredRow, codes[rowIndex]];
      } else {
        recentList = [];
      }
      return recentList;
    });
  };

  saveUniqueRecentSearches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | UpdateCurrentAirSearch
        | SetRecentAirSearches
        | ModifyLastRecentAirSearch
      >(
        BookerActions.SET_RECENT_AIR_SEARCH,
        BookerActions.UPDATE_CURRENT_AIR_SEARCH,
        BookerActions.MODIFY_LAST_RECENT_AIR_SEARCH,
      ),
      withLatestFrom(
        this.bookerFacade.recentLocations$,
        (action, recentLocations) =>
          this.keepUniqueLocations(action.payload, recentLocations),
      ),
      map(updatedLocationState => new SetRecentLocations(updatedLocationState)),
    ),
  );
}
