import { concat, Observable } from 'rxjs';
import { mapTo, share, switchMap } from 'rxjs/operators';

export const waitUntil =
  <T>(signal$: Observable<unknown>) =>
  (source$: Observable<T>): Observable<T> => {
    const sharedSource$ = source$.pipe(share());
    return concat(
      sharedSource$.pipe(switchMap(data => signal$.pipe(mapTo(data)))),
    );
  };
