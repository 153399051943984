import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[jbWrapDetector]',
})
export class WrapDetectorDirective implements OnInit, OnDestroy {
  private resizeObserver: ResizeObserver;
  @Output() wrapDetected = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.resizeObserver = new ResizeObserver(() => {
      this.checkIfContentWrapped();
    });

    this.resizeObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  private checkIfContentWrapped() {
    const element = this.el.nativeElement;

    const firstChildBottomPosition =
      element.children[0].getBoundingClientRect().bottom;

    const secondChildBottomPosition =
      element.children[1].getBoundingClientRect().bottom;

    // If isWrapped is true, it indicates that content is wrapped
    //  or overflowing horizontally.
    //  If isWrapped is false, it suggests that the content
    //  fits within the visible area without wrapping.
    const isWrapped = firstChildBottomPosition !== secondChildBottomPosition;

    this.wrapDetected.emit(isWrapped);
  }
}
