import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RouterFacade } from '@store/router/router.facade';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SessionStorageService } from '../storage';

@Injectable({ providedIn: 'root' })
export class IsMobileAppService {
  private storageKey: string;
  private sessionStorage: SessionStorageService;
  private routerFacade: RouterFacade;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.storageKey = 'isMobileApp';
    this.sessionStorage = inject(SessionStorageService);
    this.routerFacade = inject(RouterFacade);
  }

  getIsMobileApp(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      console.log('ssr: getIsMobileApp null');
      return of(null);
    }
    return this.routerFacade.queryParams.pipe(
      filter(queryParams => !!queryParams),
      map(queryParams => {
        let isMobileApp: boolean;
        // if present in queryParams, store in session storage and return value
        if (queryParams.un_jtt_application_platform) {
          isMobileApp =
            queryParams.un_jtt_application_platform.toLowerCase() ===
              'iphone' ||
            queryParams.un_jtt_application_platform.toLowerCase() === 'android';
          this.sessionStorage.setItem(this.storageKey, isMobileApp);
        }
        // otherwise retrieve and return the value of it if in session storage, else return false
        else {
          isMobileApp = !!this.sessionStorage.getItem<boolean>(this.storageKey);
        }
        return isMobileApp;
      }),
    );
  }
}
