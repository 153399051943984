import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DotAirportShortNamePipe } from '@shared/ui/pipes/airport-short-name/airport-short-name.pipe';
import { DealLegalTextFormatterPipe } from '@shared/ui/pipes/dealLegalFormatterPipe/deal-legal-text-formatter.pipe';
import { EnsureNumberPipe } from '@shared/ui/pipes/ensure-number/ensure-number.pipe';
import { RouterLinkUrlFormatterPipe } from '@shared/ui/pipes/router-link-formatter/router-link-formatter.pipe';

import { BooleanFromStringPipe } from './boolean-from-string/boolean-from-string.pipe';
import { CALENDAR_AUTO_LOAD_PIPE_DECLARATIONS } from './calendar-auto-load';
import { CALENDAR_CELL_ARIA_LABEL } from './calendar-cell-aria-label';
import {
  CURRENCY_SYMBOL_DECLARATIONS,
  CurrencySymbolPipe,
} from './currency-symbol';
import { DATALEX_PIPES } from './datalex-strings/index';
import { DATE_PIPE_DECLARATIONS } from './date';
import { DotCurrencyPipe } from './dot-currency/dot-currency.pipe';
import { CAPITALIZE_PIPE_DECLARATIONS } from './dot-flight-status-capitalize';
import { DYNAMIC_IMAGE_PIPE_DECLARATIONS } from './dynamic-image';
import { FILTER_UNDEFINED_PIPE_DECLARATIONS } from './filter-undefined';
import { GetFormControlPipe } from './get-form-control/get-form-control.pipe';
import { HIGHLIGHT_PIPE_DECLARATIONS } from './highlight';
import { INCLUDES_PIPE_DECLARATIONS } from './includes-substring';
import { DYNAMICLABEL_PIPE_DECLARATIONS } from './label';
import { NOSANITIZE_PIPE_DECLARATIONS } from './nosanitize';
import { OBJECT_VALUES_PIPE_DECLARATIONS } from './object-values';
import { PLURAL_PIPE_DECLARATIONS } from './plural/index';
import { DYNAMICPOINTS_PIPE_DECLARATIONS } from './points';
import { POINTSWITHCASH_PIPE_DECLARATIONS } from './points-with-cash';
import { STRING_TO_BOOLEAN_PIPE_DECLARATIONS } from './string-to-boolean';
import { StringToDatePipe } from './string-to-date/string-to-date.pipe';
import { StringToNumberPipe } from './string-to-number/string-to-number.pipe';
import { TABINDEX_PIPE_DECLARATIONS } from './tabindex';
import { TRIM_LEADING_ZEROES_PIPE_DECLARATIONS } from './trim-leading-zeroes';

const MODULES = [CommonModule];

const DECLARATIONS = [
  DATE_PIPE_DECLARATIONS,
  TRIM_LEADING_ZEROES_PIPE_DECLARATIONS,
  HIGHLIGHT_PIPE_DECLARATIONS,
  OBJECT_VALUES_PIPE_DECLARATIONS,
  INCLUDES_PIPE_DECLARATIONS,
  DYNAMICLABEL_PIPE_DECLARATIONS,
  DYNAMICPOINTS_PIPE_DECLARATIONS,
  POINTSWITHCASH_PIPE_DECLARATIONS,
  NOSANITIZE_PIPE_DECLARATIONS,
  CALENDAR_CELL_ARIA_LABEL,
  DATALEX_PIPES,
  PLURAL_PIPE_DECLARATIONS,
  STRING_TO_BOOLEAN_PIPE_DECLARATIONS,
  CAPITALIZE_PIPE_DECLARATIONS,
  StringToNumberPipe,
  TABINDEX_PIPE_DECLARATIONS,
  BooleanFromStringPipe,
  GetFormControlPipe,
  StringToDatePipe,
  CURRENCY_SYMBOL_DECLARATIONS,
  DYNAMIC_IMAGE_PIPE_DECLARATIONS,
  EnsureNumberPipe,
  CurrencySymbolPipe,
  CALENDAR_AUTO_LOAD_PIPE_DECLARATIONS,
  DealLegalTextFormatterPipe,
  RouterLinkUrlFormatterPipe,
  DotCurrencyPipe,
  DotAirportShortNamePipe,
  FILTER_UNDEFINED_PIPE_DECLARATIONS,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class PipesModule {}
