import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shouldAutoLoad',
})
export class ShouldAutoLoadPipe implements PipeTransform {
  transform(
    showSchedule: boolean,
    isMobile: boolean,
    bookerCalendarAutoLoad: boolean,
  ): boolean {
    return isMobile && showSchedule ? bookerCalendarAutoLoad : showSchedule;
  }
}
