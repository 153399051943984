import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Templates } from '@dynamic-templates/types/templates.type';
import { SITEMAP_DICTIONARY } from '@shared/ui/links/linkers/sitemap-dictionary.token';
import { RouterFacade } from '@store/router/router.facade';
import { BehaviorSubject, from as observableFrom, Observable, of } from 'rxjs';

import { AnalyticsService, PageName } from '../analytics';
import { DOCUMENT, WINDOW } from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class RouterService {
  isHomePage = false;
  isNavigatingToTemplate = new BehaviorSubject<{
    url: string;
    previousUrl: string | null;
    navigating: boolean;
    baseTemplate: keyof Templates | string;
    id: number;
  }>({
    navigating: false,
    baseTemplate: 'base',
    id: 0,
    url: '',
    previousUrl: null,
  });

  previousUrl: string;
  currentUrl: string;
  public title: Title;
  public analyticsService: AnalyticsService;
  public router: Router;
  public window: any;
  private document: any;
  private sitemapDictionary: any;
  private routerFacade: RouterFacade;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      this.title = inject(Title);
      return;
    }
    this.title = inject(Title);
    this.analyticsService = inject(AnalyticsService);
    this.router = inject(Router);
    this.window = inject(WINDOW);
    this.document = inject(DOCUMENT);
    this.sitemapDictionary = inject(SITEMAP_DICTIONARY);
    this.routerFacade = inject(RouterFacade);
    this.init();
  }

  init() {
    this.routerFacade.isHomePage.subscribe(
      isHomePage => (this.isHomePage = isHomePage),
    );
    this.router.events.subscribe(
      (
        event:
          | NavigationStart
          | NavigationEnd
          | NavigationCancel
          | NavigationError,
      ) => {
        const baseTemplate = this.setBaseTemplate(event.url);
        switch (true) {
          case event instanceof NavigationStart: {
            this.isNavigatingToTemplate.next({
              url: event.url,
              previousUrl: this.previousUrl,
              navigating: true,
              baseTemplate,
              id: event.id,
            });
            break;
          }
          case event instanceof NavigationEnd:
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.isNavigatingToTemplate.next({
              url: this.currentUrl,
              previousUrl: this.previousUrl,
              navigating: false,
              baseTemplate,
              id: event.id,
            });
            break;
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.isNavigatingToTemplate.next({
              url: event.url,
              previousUrl: this.previousUrl,
              navigating: false,
              baseTemplate,
              id: event.id,
            });
            break;
          }
          default: {
            break;
          }
        }
      },
    );
  }

  setBaseTemplate(url: string): keyof Templates | string {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    let baseTemplate = 'base';
    if (url) {
      switch (true) {
        case url.startsWith('/destinations'):
          baseTemplate = 'destinations';
          break;
        // forgot-password page is no longer static, if the page changes from this hard-coded
        // will need to update this or move skeleton to component instead
        case url.startsWith('/forgot-password'):
          baseTemplate = 'forgot-password';
          break;
        default:
          const path = url.split('?')[0];
          baseTemplate = this.sitemapDictionary[path] || 'base';
          break;
      }
    }
    return baseTemplate;
  }

  setPageTitle(routeData) {
    let title = `${routeData.pageName || routeData.title || ''}`;
    this.isHomePage
      ? (title = `JetBlue | ` + title)
      : (title = title.concat(title.length > 0 ? ' | JetBlue' : 'JetBlue'));
    this.title.setTitle(title);
    if (isPlatformBrowser(this.platformId)) {
      this.analyticsService.setTitle(title as PageName);
    }
  }

  navigate(route: string, ...args: any[]): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      console.log('RouterService.navigate aborted', route);
      return of(null);
    }
    const navPromise = this.router.navigate([route], ...args);
    return observableFrom(navPromise);
  }

  scrollToTop() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.window.scrollTo(0, 0);
  }

  extractBaseURL(url?: string): string {
    return url?.split('#')[0] ?? '';
  }

  resetFocus() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (this.document.body) {
      const element = this.document.body.querySelector('h1');
      if (element && !this.isHomePage) {
        element.setAttribute('tabindex', -1);
        element.focus();
      } else {
        this.document.body.setAttribute('tabindex', -1);
        this.document.body.focus();
      }
    }
  }
}
