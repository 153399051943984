import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ShoppingCartSection } from '@shared/header/types/header.inner.type';
import { CartFacade } from '@store/cart/cart.facade';

/**
 * @description Shopping cart available on the mobile view port of the application header
 * @WhereIsItUsed header mobile component
 */
@Component({
  selector: 'jb-header-mobile-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileShoppingCartComponent {
  @Input() shoppingCart: ShoppingCartSection;

  constructor(public cartFacade: CartFacade) {}
}
