import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JbCloseButtonModule } from 'jb-component-library';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { UIModule } from '../ui';
import { DialogTempActionsComponent } from './dialog-actions';
import { DialogTempContentComponent } from './dialog-content';
import { DialogDebugComponent } from './dialog-debug/dialog-debug.component';
import { DIALOG_SERVICE_PROVIDERS } from './dialog-service';
import { DialogTempTitleComponent } from './dialog-title';
import { DIALOGS_DECLARATIONS } from './dialogs';
import { DIALOG_DIRECTIVES } from './directives';
import { PopupDialogComponent } from './popup-dialog';

export const DECLARATIONS = [
  ...DIALOG_DIRECTIVES,
  DialogTempContentComponent,
  DialogTempActionsComponent,
  DialogTempTitleComponent,
  PopupDialogComponent,
  DialogDebugComponent,
  DIALOGS_DECLARATIONS,
];

const MODULES = [
  CommonModule,
  UIModule,
  JbCloseButtonModule,
  NgxJsonViewerModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
  providers: [...DIALOG_SERVICE_PROVIDERS],
})
export class DialogTempModule {}
