import {
  FareSaleBlock,
  FareSaleDestination,
  FareSaleFlightsOffer,
  FareSaleHero,
  FareSaleHeroImage,
  FareSaleMintsOffer,
  FareSaleOrigin,
  FareSaleVacationsOffer,
  FlightFare,
  FlightHotelFare,
  FlightMintFare,
} from '../fare-sale.models';

export const MOCK_FLIGHT_FARE: FlightFare = {
  points: '6,000',
  origincode: 'ALB',
  pointstaxes: '$5.60',
  days: '0',
  currencycode: 'USD',
  name: 'ALB-FLL',
  restr5: '',
  restr3: '9/28-10/1, 10/12-10/14, 11/6-11/12',
  restr4: '',
  restr1: 'Travel 9/4-11/20',
  restr2: 'Excl. Fri & Sun',
  destinationcode: 'FLL',
  dollars: '94',
  variant: 'flight',
  urlAirwaysDollars: 'https://www.example.com/urlAirwaysDollars',
  urlAirwaysPoints: 'https://www.example.com/urlAirwaysPoints',
  urlAirwaysAppDollars: 'https://www.example.com/urlAirwaysAppDollars',
  urlAirwaysAppPoints: 'https://www.example.com/urlAirwaysAppPoints',
};

export const MOCK_FLIGHT_HOTEL_FARE: FlightHotelFare = {
  departdate: '09/16/19',
  origincode: 'ALB',
  name: 'ALB-FLL',
  days: '0',
  currencycode: 'USD',
  hotelname: 'Fort Lauderdale Marriott Pompano Beach Resort and Spa',
  nights: '2',
  hotelrating: '4',
  hotelimage:
    'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/imagery/T5/DeepBlue-1280x720.jpg',
  returndate: '09/19/19',
  hotelid: '428432',
  destinationcode: 'FLL',
  dollars: '369',
  variant: 'hotels+flight',
  urlVacationsDollars: 'https://www.example.com/urlVacationsDollars',
  urlVacationsPoints: 'https://www.example.com/urlVacationsPoints',
};

export const MOCK_FLIGHT_MINT_FARE: FlightMintFare = {
  points: '6,0000',
  origincode: 'ALB',
  pointstaxes: '$5.60',
  days: '0',
  currencycode: 'USD',
  name: 'ALB-FLL',
  restr5: '',
  restr3: '9/28-10/1, 10/12-10/14, 11/6-11/12',
  restr4: '',
  restr1: 'Travel 9/4-11/20',
  restr2: 'Excl. Fri & Sun',
  destinationcode: 'FLL',
  dollars: '940',
  variant: 'mint',
  urlAirwaysDollars: 'https://www.example.com/urlAirwaysDollars',
  urlAirwaysPoints: 'https://www.example.com/urlAirwaysPoints',
  urlAirwaysAppDollars: 'https://www.example.com/urlAirwaysAppDollars',
  urlAirwaysAppPoints: 'https://www.example.com/urlAirwaysAppPoints',
};

export const MOCK_FARE_SALE_DESTINATION: FareSaleDestination = {
  destination: 'FLL',
  destinationName: 'Fort Lauderdale',
  destinationState: 'FL',
  fares: [MOCK_FLIGHT_FARE, MOCK_FLIGHT_HOTEL_FARE, MOCK_FLIGHT_MINT_FARE],
};

export const MOCK_FARE_SALE_ORIGIN: FareSaleOrigin = {
  origin: 'ALB',
  originName: 'Albany',
  originState: 'NY',
  fare: [MOCK_FARE_SALE_DESTINATION],
};

export const MOCK_FARE_SALE_BLOCK: FareSaleBlock = {
  genericFlightsHotelsRestrictions: 'Generic flights',
  buttonLabel: 'button',
  flightsHotelsBlockLabel: 'flight + hotel',
  title: 'Title',
  vacationsExcluded: 'false',
  flightsBlockLabel: 'flights block',
  fromLabel: 'from',
  genericFlightRestrictions: 'generic',
  reviewsLabel: 'review',
  toLabel: 'to',
  fareSaleBlock: [MOCK_FARE_SALE_ORIGIN],
};

export const MOCK_FARE_SALE_HERO_IMAGE: FareSaleHeroImage = {
  isLazy: 'false',
  cropped: 'true',
  defer: 'false',
  src: 'https://dev.b6orgeng.net/component-library/assets/img/sampleimage.jpg',
  alt: 'alt',
  placeholder: 'place holder',
};

export const MOCK_FARE_SALE_HERO: FareSaleHero = {
  flagTitle: 'Two Day Sale!',
  backgroundRepeat: 'true',
  backgroundColor: '#0033A0',
  background: 'image',
  flagTheme: 'dark',
  title: 'Go (full on) West. For Less',
  type: 'promoHero',
  backgroundImage:
    'https://dev.b6orgeng.net/component-library/assets/img/gplaypattern.png',
  image: [MOCK_FARE_SALE_HERO_IMAGE],
};

export const MOCK_FARE_SALE_FLIGHTS_OFFER: FareSaleFlightsOffer = {
  header: 'Flights from',
  footer: 'one way',
  pointsSuffix: 'pts',
};

export const MOCK_FARE_SALE_VACATIONS_OFFER: FareSaleVacationsOffer = {
  header: 'Flights + Hotel from',
  footer: 'roundtrip',
  notAvailableLabel: 'true',
};

export const MOCK_FARE_SALE_MINT_OFFER: FareSaleMintsOffer = {
  header: 'Flights from',
  footer: 'one way',
  pointsSuffix: 'pts',
};
