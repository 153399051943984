import { isValidDateString } from '@core/utils/date.utils';

import { Pax } from '../types';
import { BOOKER_TRIP_TYPES } from './types/booker-trip-type.enum';
/**
 * This config is not to be touched by marketing/CMS
 */

export enum SEARCH_TYPES {
  ROUND_TRIP = 'RT',
  ONE_WAY = 'OW',
  MULTICITY = 'MC',
}

const INVALID_CONTROL_CLASS =
  'invalid-control, .jb-invalid .jb-form-field, .jb-invalid.jb-form-field ';

const FARE_TYPES = {
  DOLLARS: 'usd',
  CASH: 'usd',
  POINTS: 'tb',
};

const PAX = {
  ADULT_ROOM_MIN: 1,
  VACATIONS_CHILD_LIMIT: 6,
  GUEST_LIMIT: 7,
  ROOM_LIMIT: 7,
  MIN_MINOR_AGE: 2,
  MAX_MINOR_AGE: 18,
  MAX_UNACCOMPANIED_MINORS: 3,
  MAX_INFANTS: 3,
};

const URL_PARAMETERS = {
  ROUND_TRIP: 'ROUNDTRIP',
  ONE_WAY: 'ONEWAY',
  MULTI_CITY: 'MULTICITY',
  FARE: 'FARE',
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
  DEPARTURE_DATE: 'DEPARTURE',
  RETURN_DATE: 'RETURN',
  ADT: 'ADULT',
  CHD: 'CHILD',
  INF: 'INFANT',
  AIRPROMO: 'AIRPROMO',
  OPEN_DATE_PICKER: 'OPENDATEPICKER',
};

const URL_PARAMETERS_VALUES_VALIDATORS = {
  ROUNDTRIP: val => ['TRUE', 'FALSE'].includes(val.toUpperCase()),
  FARE: val => ['POINTS', 'CASH', 'DOLLARS'].includes(val.toUpperCase()),
  ORIGIN: val => /\b[a-zA-Z]{3}\b/.test(val),
  DESTINATION: val => /\b[a-zA-Z]{3}\b/.test(val),
  DEPARTURE: val => isValidDateString(val),
  RETURN: val => isValidDateString(val),
  ADULT: val => /^[0-9]$/.test(val),
  CHILD: val => /^[0-9]$/.test(val),
  INFANT: val => /^[0-9]$/.test(val),
  AIRPROMO: () => true,
  OPENDATEPICKER: val => ['TRUE', 'FALSE'].includes(val.toUpperCase()),
};

export type BookerUrlParams = Partial<{
  ROUNDTRIP: string | boolean;
  ONEWAY: string | boolean;
  MULTICITY: string | boolean;
  FARE: string;
  ORIGIN: string;
  DESTINATION: string;
  DEPARTURE: string;
  RETURN: string;
  ADULT: string;
  CHILD: string;
  INFANT: string;
  AIRPROMO: string;
  VACATIONPROMO: string;
}>;

// The following origins are exceptions to US international warning
const INTERNATIONAL_EXCEPTIONS = {
  STX: 'US',
  STT: 'US',
  BQN: 'US',
  CPX: 'US',
  MAZ: 'US',
  PSE: 'US',
  SJU: 'US',
  VQS: 'US',
};

const DEFAULT_PAX_VALUES: Pax = {
  ADT: 1,
  CHD: 0,
  INF: 0,
  INS: 0,
};

const DEFAULT_FORM_VALUES = {
  TRIP_TYPE: SEARCH_TYPES.ROUND_TRIP,
  BOOK_WITH_POINTS: false,
  DEPARTURE_DATE: undefined,
  RETURN_DATE: undefined,
  PAX: DEFAULT_PAX_VALUES,
};

// only for vacations booker
const CUBA = {
  CMW: 'CMW',
  HAV: 'HAV',
  HOG: 'HOG',
  SNU: 'SNU',
};

const DATE_FORMAT = 'MM/dd/yyyy';

const BOOKER_VACATIONS_CONFIG = {
  CUBA,
};

const BOOK_ERROR_MESSAGE_CONFIG = {
  cityInvalid: 'Please enter a valid city.',
  dateInvalid: 'Please enter a valid date.',
  noPassengerError: 'Please select a passenger.',
  maxDateError: 'Choose date before',
  isValidDate: 'Please enter a valid date.',
  minDateError: 'Choose date after',
  returnBeforeDepartDateError: 'Return must be after the depart date',
  captchaInvalid: 'Please verify that you are not a robot',
};

const FLIGHT_TRACKER_ERROR_MESSAGE_CONFIG = {
  cityInvalid: 'Please enter a valid city.',
  flightNoInput: 'No valid flight number found.',
};

const DEFAULTS = {
  tripType: BOOKER_TRIP_TYPES.ROUNDTRIP,
  trueblue: false,
  // origin: targeted airport, // origin will be the targeted airport, using current logic
  destination: undefined,
  departure: undefined,
  return: undefined,
  travellers: {
    ADT: 1,
    CHD: 0,
    INF: 0,
  },
};

export const BOOKER_CONFIG = {
  SEARCH_TYPES,
  FARE_TYPES,
  PAX,
  INTERNATIONAL_EXCEPTIONS,
  DEFAULT_FORM_VALUES,
  VACATIONS_INACTIVE: false, // In case datalex booker is unstable, the static pod will apply
  VACATIONS_MOBILE_INACTIVE: false, // Go to usablenet on mobile
  MAX_RECENT_AIR_SEARCHES: 3,
  MAX_SUBMITTED_AIR_SEARCHES: 3,
  MAX_RECENT_MULTI_CITY_SEARCHES: 3,
  MAX_RECENT_VACATION_SEARCHES: 3,
  BOOKER_VACATIONS_CONFIG,
  DEFAULT_PAX_VALUES,
  INVALID_CONTROL_CLASS,
  DATE_FORMAT,
  URL_PARAMETERS,
  URL_PARAMETERS_VALUES_VALIDATORS,
  BOOK_ERROR_MESSAGE_CONFIG,
  FLIGHT_TRACKER_ERROR_MESSAGE_CONFIG,
  DEFAULTS,
};
