import { Injectable } from '@angular/core';
import { TypedDictionary } from '@core/utils';
import { AccessToken } from '@okta/okta-auth-js';
import { StoreService } from '@store/shared/store.service';
import { Observable } from 'rxjs';

import {
  ChangeUserGroup,
  ChangeUserGroupDone,
  ChangeUserGroupError,
  FetchUserFactors,
  FetchUserFactorsDone,
  FetchUserFactorsError,
} from './mfa.actions';
import * as MfaSelectors from './mfa.selectors';
import { MfaCatalogResponseError } from './types/mfa-catalog-error-response.type';
import { MfaGroupResponseError } from './types/mfa-group-error-response.type';
import { MfaGroupRequest } from './types/mfa-group-request.type';
import { MfaGroupResponse } from './types/mfa-group-response.type';
import { UserFactor } from './types/mfa-state.type';
@Injectable({ providedIn: 'root' })
export class MfaFacade {
  constructor(private _storeService: StoreService) {}
  userFactors: Observable<TypedDictionary<UserFactor>> =
    this._storeService.getState(MfaSelectors.userFactors);

  isMfaFactorsLoading: Observable<boolean> = this._storeService.getState(
    MfaSelectors.isMfaFactorsLoading,
  );

  mfaFactorsResponseCode: Observable<number> = this._storeService.getState(
    MfaSelectors.mfaFactorsResponseCode,
  );

  isMfaGroupsLoading: Observable<boolean> = this._storeService.getState(
    MfaSelectors.isMfaGroupsLoading,
  );

  mfaGroupsResponseCode: Observable<number> = this._storeService.getState(
    MfaSelectors.mfaGroupsResponseCode,
  );

  fetchUserFactors(accessToken: AccessToken) {
    this._storeService.dispatchAction(new FetchUserFactors(accessToken));
  }

  fetchUserFactorsDone(payload: TypedDictionary<UserFactor>) {
    this._storeService.dispatchAction(new FetchUserFactorsDone(payload));
  }

  fetchUserFactorsError(payload: MfaCatalogResponseError) {
    this._storeService.dispatchAction(new FetchUserFactorsError(payload));
  }

  changeUserGroup(accessToken: AccessToken, payload: MfaGroupRequest) {
    this._storeService.dispatchAction(
      new ChangeUserGroup(accessToken, payload),
    );
  }

  changeUserGroupDone(payload: MfaGroupResponse) {
    this._storeService.dispatchAction(new ChangeUserGroupDone(payload));
  }

  changeUserGroupError(payload: MfaGroupResponseError) {
    this._storeService.dispatchAction(new ChangeUserGroupError(payload));
  }
}
