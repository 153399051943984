<div
  class="bg-core-blue w-100 pa8-ns relative pv6 ph3 page-margin-m page-margin-l"
>
  <dot-text-block radius="12" class="header center mb5"></dot-text-block>

  <div class="center jb-max-width">
    <div class="flex justify-center">
      <div class="booker-tabs dn-s inline-flex justify-center">
        <dot-text-block class="ml5"></dot-text-block>
        <dot-text-block class="ml5"></dot-text-block>
        <dot-text-block class="ml5"></dot-text-block>
        <dot-text-block class="ml5"></dot-text-block>
        <dot-text-block class="ml5"></dot-text-block>
      </div>
      <div class="booker-tabs dn-ns inline-flex justify-center">
        <span class="ml5">
          <dot-text-block
            disableAnimation="true"
            height="24"
            class="w-1-5 mb1 center"
          ></dot-text-block>
          <dot-text-block></dot-text-block>
        </span>
        <span class="ml5">
          <dot-text-block
            disableAnimation="true"
            height="24"
            class="w-1-5 mb1 center"
          ></dot-text-block>
          <dot-text-block></dot-text-block>
        </span>
        <span class="ml5">
          <dot-text-block
            disableAnimation="true"
            height="24"
            class="w-1-5 mb1 center"
          ></dot-text-block>
          <dot-text-block></dot-text-block>
        </span>
      </div>
    </div>
    <dot-base-booker-skeleton></dot-base-booker-skeleton>
  </div>
</div>
