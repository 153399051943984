import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '@core/http';
import { AppConfigService } from '@core/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OktaService } from '@store/auth/api/okta.service';
import { combineLatest, filter, map, of, switchMap } from 'rxjs';

import { ChangeUserGroup, FetchUserFactors, MfaActions } from './mfa.actions';
import { MfaApi } from './mfa.api';
import { MfaFacade } from './mfa.facade';
import { MFA_LOYALTY_GROUPS } from './types/mfa-loyalty-groups.enum';

@Injectable()
export class MfaEffects {
  constructor(
    private _actions$: Actions,
    private _oktaService: OktaService,
    private _mfaApi: MfaApi,
    private _mfaFacade: MfaFacade,
    private _appconfigService: AppConfigService,
    private readonly router: Router,
  ) {}

  fetchUserFactors$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<FetchUserFactors>(MfaActions.FETCH_USER_FACTORS),
        map(({ accessToken }) => {
          if (
            this._oktaService.hasExpiredToken(accessToken) &&
            !this._appconfigService.environment.includes('local-dev')
          ) {
            this._oktaService.login(this.router.url);
            return null;
          }
          return accessToken;
        }),
        filter(accessToken => !!accessToken),
        switchMap(accessToken => this._mfaApi.getFactorsCatalog(accessToken)),
        filter(HttpService.isSuccessful),
        map(userFactors => {
          return this._mfaFacade.fetchUserFactorsDone(userFactors);
        }),
      ),
    { dispatch: false },
  );

  changeUserGroup$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ChangeUserGroup>(MfaActions.CHANGE_USER_GROUP),
        map(action => {
          if (
            this._oktaService.hasExpiredToken(action.accessToken) &&
            !this._appconfigService.environment.includes('local-dev')
          ) {
            this._oktaService.login(this.router.url);
            return null;
          }
          return action;
        }),
        filter(action => !!action),
        switchMap(action =>
          combineLatest([
            this._mfaApi
              .changeUserGroup(action.accessToken, action.payload)
              .pipe(filter(HttpService.isSuccessful)),
            of(action.accessToken),
          ]),
        ),
        map(([newGroup, accessToken]) => {
          // Update UserFactors store for each change
          this._mfaFacade.fetchUserFactors(accessToken);

          if (newGroup['group'] === MFA_LOYALTY_GROUPS.LOYALTY_MFA_EMAIL_SMS) {
            this.router.navigateByUrl(
              `/signin?useIdx=true&returnUrl=${this.router.url}`,
            );
          }
          // @ts-ignore
          return this._mfaFacade.changeUserGroupDone(newGroup);
        }),
      ),
    { dispatch: false },
  );
}
