<ng-container *ngIf="footerResponse$ | async as footerResponse">
  <!-- Search section -->
  <div class="bt b--medium-gray bg-off-white page-margin">
    <div class="jb-max-width center">
      <ng-container *ngIf="footerResponse.search">
        <div class="pt5 pb5 jb-grid one-up">
          <div class="flex flex-column">
            <h3 class="core-blue center mb3">
              {{ footerResponse.search.heading }}
            </h3>
            <jb-search-box-container
              class="w-50-ns w-100 flex center"
              [isInFooter]="true"
              [searchSection]="footerResponse.search"
            ></jb-search-box-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Main section -->
  <div class="page-margin pt5-ns jb-max-width center">
    <div class="jb-grid gr-unset three-up">
      <!-- Main links desktop -->
      <div class="col-8-l col-12-m db-ns flex jb-grid">
        <ul
          *ngFor="
            let list of footerResponse.mainSection.children | values;
            let i = index
          "
          class="pl0 fl db-ns col-4-m col-4-l dn"
        >
          <li class="list">
            <h4 class="body b core-blue mb0">{{ list.heading }}</h4>
          </li>
          <li
            *ngFor="let link of list.children; let i = index"
            class="flex flex-row list"
          >
            <a
              [target]="link.newWindow"
              [dotLinkIcon]="link.newWindow === '_self'? null : link.newWindow"
              rel="noopener"
              class="royal-blue s-body mt3 no-underline klarheit lh-18"
              [ngClass]="{ 'mt2-l': i !== 0 }"
              [jbSitemapLinker]="link.href"
              >{{ link.title }}</a
            >
          </li>
        </ul>
      </div>
      <!-- Main links mobile -->
      <jb-expansion-panel
        *ngFor="
          let list of footerResponse.mainSection.children | values;
          let i = index
        "
        class="footer-accordion dn-ns"
        [ngClass]="{ 'nt4-s': i !== 0 }"
      >
        <jb-expansion-panel-header [isPrimary]="false">
          <span>{{ list.heading }}</span>
        </jb-expansion-panel-header>
        <jb-expansion-panel-row [isPrimary]="false" class="pb2">
          <div
            *ngFor="let link of list.children; let i = index"
            class="w-100 fl popular-links"
          >
            <a
              class="royal-blue s-body no-underline klarheit lh-22"
              [jbSitemapLinker]="link.href"
              [target]="link.newWindow"
              [dotLinkIcon]="link.newWindow === '_self' ? null : link.newWindow"
              rel="noopener"
              >{{ link.title }}</a
            >
          </div>
        </jb-expansion-panel-row>
      </jb-expansion-panel>
      <!-- Get The Latest Section -->
      <div class="fl-ns db col-12-m col-4-l nt4-s pt4 pt0-ns" *ngIf="footerResponse.socialSection">
        <h4 class="body fw9 core-blue mb0 list">
          {{ footerResponse.socialSection.icon.heading }}
        </h4>
        <ul class="pl0 mv0">
          <li
            class="s-body b mt2 list fl-ns social w-50-m w-100-l"
            *ngFor="
              let link of footerResponse.socialSection.icon.socialIcons;
              let i = index
            "
            [ngClass]="{ 'mt0-l': i !== 0 }"
          >
            <a
              class="flex items-center db fl-m royal-blue no-underline klarheit"
              [jbSitemapLinker]="link.href"
              [target]="link.newWindow"
              [dotLinkIcon]="link.newWindow === '_self'? null : link.newWindow"
            >
              <jb-icon
                [name]="link.image?.src"
                class="dib connect-icon pointer v-top fill-royal-blue"
              >
              </jb-icon>
              {{ link.title }}
            </a>
          </li>
        </ul>
        <!-- Social links -->
        <ul class="mw5--l flex fl pl0 w-100 w-auto-m flex-wrap mv0">
          <li
            class="mt3 mt3-m mr4 list social-icons"
            *ngFor="let link of footerResponse.socialSection.social.socialLinks"
          >
            <a
              class="royal-blue s-body no-underline"
              [jbSitemapLinker]="link.href"
              [target]="link.newWindow"
            >
              <jb-icon
                [name]="link.image[0]?.src"
                class="dib social-icon pointer v-top fill-royal-blue"
                [label]="link.image[0]?.alt"
              >
              </jb-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Bottom Section -->
    <div class="jb-max-width flex justify-between center w-100" *ngIf="footerResponse.footnoteSection.topFootNote">
      <jb-arrow-link
        class="db fl s-body mt4 mt3-l klarheit"
        [link]="footerResponse.footnoteSection.topFootNote"
      ></jb-arrow-link>
    </div>
  </div>

  <!-- Bottom Section - Footnotes -->
  <hr class="mv3 bg-light-gray cl" />
  <div
    class="footnotes page-margin jb-max-width center flex justify-between flex-column-reverse flex-row-l page-margin pb3"
  >
    <div class="slate-gray legal klarheit">
      <div class="mb0 pv3 pb1-l pt0-l">
        <jb-icon
          class="mr1 fill-royal-blue"
          name="externalLink"
          width="0.5em"
          height="0.5em"
          *ngIf="footerResponse?.footnoteSection?.bottomFootNote?.copy?.text?.length"
          [label]="
            footerResponse.footnoteSection.bottomFootNote.copy?.image?.alt
          "
        >
        </jb-icon>
        {{ footerResponse.footnoteSection.bottomFootNote.copy?.text }}
      </div>
      <div class="mb0">
        {{ footerResponse.footnoteSection.bottomFootNote?.copyright }}
      </div>
    </div>

    <div class="self-center self-baseline-l mb flex royal-blue items-center" *ngIf="!hideLanguageSelect">
      <jb-icon
        [name]="footerResponse.footnoteSection.utilityNavigation.image?.src"
        width="14px"
        height="14px"
      ></jb-icon>
      <jb-form-field-container>
        <jb-select
          [formControl]="languageControl"
          type="simple"
          placement="bottom-end"
          data-qaid="languageType"
          class="ml1"
        >
          <jb-select-option
            *ngFor="let language of languageLinks$ | async"
            [name]="language.desc"
            [value]="language"
            [attr.data-qaid]="language.desc"
            class="legal center"
          >
          </jb-select-option>
        </jb-select>
      </jb-form-field-container>
    </div>
  </div>
</ng-container>
