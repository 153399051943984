import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { SITEMAP_DICTIONARY } from '@shared/ui/links/linkers/sitemap-dictionary.token';

import { removeHomeFromPath } from '../cms.utils';
import { formatPersonalization } from '../personalization/personalization.utils';
import { CMSQueryParams } from '../types';
import { TEMPLATE_CMS_VERSION } from './template-cms-version.const';

@Injectable({ providedIn: 'root' })
export class CmsApiBuilderService {
  /**
   * magnolia URL structure:
   * https://dev.b6orgeng.net/magnoliapublic/.rest/jetblue/v4/[language]/page/[path]/[A|B]?personalization=value&campaign=value
   */

  private getPageSuffix = (version = 'v4') => {
    return `.rest/jetblue/${version}/page`;
  };

  private appConfig: AppConfigService;
  private sitemapDictionary: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.appConfig = inject(AppConfigService);
    this.sitemapDictionary = inject(SITEMAP_DICTIONARY);
  }

  public buildCmsUrl({
    reqPath,
    ab,
    personalizations,
    toolkit,
    translateLanguage,
    translationEnabled,
  }: CMSQueryParams & {
    toolkit?: string;
    translateLanguage?: string;
    translationEnabled?: boolean;
  }) {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const version = this.sitemapDictionary
      ? TEMPLATE_CMS_VERSION[
          this.sitemapDictionary[removeHomeFromPath(reqPath)]
        ] || this.appConfig.templateVersionDefaults.cms
      : this.appConfig.templateVersionDefaults.global;

    const personalization = personalizations
      ? formatPersonalization(personalizations)
      : '';
    const questionMark = !!personalization ? '?' : '';

    const translationQueryParams =
      translateLanguage && translationEnabled
        ? `translateLanguage=${translateLanguage}`
        : '';

    const firstQueryParamFormat = translationQueryParams
      ? `?${translationQueryParams}`
      : '';
    const notFirstQueryParamFormat = translationQueryParams
      ? `&${translationQueryParams}`
      : '';

    const prefix = translationEnabled
      ? '/i18n-next'
      : this.appConfig.magnoliaUrl;

    switch (reqPath) {
      case '/application/header':
        return `${prefix}/.rest/jetblue/v4/page/home/headerNew/${ab}${questionMark}${personalization}${
          personalization ? notFirstQueryParamFormat : firstQueryParamFormat
        }`;

      case '/application/footer':
        return `${prefix}/.rest/jetblue/v4/page/home/footerNew/${ab}${questionMark}${personalization}${
          personalization ? notFirstQueryParamFormat : firstQueryParamFormat
        }`;

      default:
        return `${prefix}/${this.getPageSuffix(
          version,
        )}${reqPath}/${ab}${questionMark}${personalization}${
          personalization ? notFirstQueryParamFormat : firstQueryParamFormat
        }`;
    }
  }
}
