import { Injectable } from '@angular/core';

import { StoreService } from '../shared/store.service';
import {
  AllContentLoadFinish,
  BffAnalyticsEventCall,
  BookerLoadFinished,
  BookerLoadStart,
  ContentLoadStart,
  ForgotPasswordDetails,
  ForgotPasswordServiceErrorTracking,
  HeaderLoadFinished,
  HeaderStart,
  LoginButtonClicked,
  LoginFailed,
  LogProfileInfoEventCall,
  PriorityContentLoadFinish,
  TbLoginDetails,
  TrackSubmitEventCall,
} from './analytics.actions';

@Injectable({ providedIn: 'root' })
export class AnalyticsFacade {
  constructor(private storeService: StoreService) {}

  loginButtonClicked(message: string) {
    this.storeService.dispatchAction(new LoginButtonClicked(message));
  }

  loginFailed(message: string) {
    this.storeService.dispatchAction(new LoginFailed(message));
  }

  trackSubmitEventCall() {
    this.storeService.dispatchAction(new TrackSubmitEventCall());
  }

  tbLoginDetails(message: string) {
    this.storeService.dispatchAction(new TbLoginDetails(message));
  }

  forgotPasswordDetails(message: string) {
    this.storeService.dispatchAction(new ForgotPasswordDetails(message));
  }

  forgotPasswordServiceError(message: string) {
    this.storeService.dispatchAction(
      new ForgotPasswordServiceErrorTracking(message),
    );
  }

  bffAnalyticsEventCall(controls: any) {
    this.storeService.dispatchAction(new BffAnalyticsEventCall(controls));
  }

  headerStartEventCall() {
    this.storeService.dispatchAction(new HeaderStart());
  }

  headerLoadFinishedEventCall() {
    this.storeService.dispatchAction(new HeaderLoadFinished());
  }

  bookerStartEventCall(eventId: string) {
    this.storeService.dispatchAction(new BookerLoadStart(eventId));
  }

  bookerLoadDoneEventCall(eventId: string) {
    this.storeService.dispatchAction(new BookerLoadFinished(eventId));
  }

  contentLoadStartEventCall() {
    this.storeService.dispatchAction(new ContentLoadStart());
  }

  priorityContentLoadFinishEventCall() {
    this.storeService.dispatchAction(new PriorityContentLoadFinish());
  }

  allContentLoadFinishEventCall() {
    this.storeService.dispatchAction(new AllContentLoadFinish());
  }

  logProfileInfoEventCall(isLoggedIn: boolean) {
    this.storeService.dispatchAction(new LogProfileInfoEventCall(isLoggedIn));
  }
}
