export function moveFocus(
  formWrapper: HTMLElement,
  currentField: HTMLElement,
  moveForward: boolean,
) {
  const inputsArray = Array.prototype.slice.apply(
    formWrapper?.querySelectorAll('input') || [],
  );

  if (inputsArray && inputsArray.length > 0) {
    const currentFieldIndex = inputsArray.indexOf(currentField);
    const nextFieldIndex = moveForward
      ? currentFieldIndex + 1
      : currentFieldIndex - 1;
    if (
      inputsArray[nextFieldIndex] &&
      currentFieldIndex < inputsArray.length - 1
    ) {
      inputsArray[nextFieldIndex].click();
      inputsArray[nextFieldIndex].focus();
    }
  }
}
