import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from '../app-config';

type URL = string;

@Injectable({ providedIn: 'root' })
export class OAuthTokenService {
  static extractTokenFromResponse(tokenResponse: any) {
    return {
      Authorization: `Bearer ${tokenResponse['access_token']}`,
    };
  }

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService,
  ) {}

  postTokenRequest(input: {
    resource: URL;
    apiKey?: string;
    clientId: string;
    clientSecret: string;
  }) {
    const { apiKey, resource, clientId, clientSecret } = input;

    const url = `${this.appConfig.azApiUrl}/oauth2/token`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      request_accept_content: 'application/ion+json; okta-version=1.0.0',
      ...(apiKey ? { apiKey } : {}),
    });

    const body = `Resource=${resource}&client_id=${clientId}&client_secret=${clientSecret}&grant_type=client_credentials`;

    return this.httpClient.post(url, body, { headers });
  }
}
