import { Component, Input } from '@angular/core';

// TODO: determine if this can be deleted
/**
 * @description Display a formatted dialog component
 * @WhereIsItUsed Unknown
 */
@Component({
  selector: 'jb-popup-dialog',
  templateUrl: 'popup-dialog.component.html',
  styleUrls: ['popup-dialog.component.scss'],
})
export class PopupDialogComponent {
  @Input() hasPadding = true;
  @Input() hideTitle = false;
  // can only set the hideTopCloseButton value if title is not hidden
  @Input('hideTopCloseButton')
  set hideTopCloseButton(hide: boolean) {
    this.topCloseButtonHide = this.hideTitle ? true : hide;
  }
  @Input() hideBottomCloseButton = false;
  @Input() titleBgColor = 'bg-core-blue';
  @Input() titleColor = 'white';
  @Input() centerTitle = true;
  topCloseButtonHide = false;
}
