import { Action } from '../shared/types/action.interface';
import { RequestReceiptPayload } from './types/reqest-a-receipt.state.type';

export enum RequestAReceiptActionTypes {
  REQUEST_A_RECEIPT_SUBMIT_REQUESTED = '[Contact Us Receipt] Request Receipt Requested',
  REQUEST_A_RECEIPT_SUBMIT_SUCCEEDED = '[Contact Us Receipt] Request Receipt Succeeded',
  REQUEST_A_RECEIPT_SUBMIT_FAILED = '[Contact Us Receipt] Request Receipt Failed',
}

export class ReceiptSubmissionRequested implements Action {
  readonly type = RequestAReceiptActionTypes.REQUEST_A_RECEIPT_SUBMIT_REQUESTED;
  constructor(public payload: RequestReceiptPayload) {}
}

export class ReceiptSubmissionSucceeded implements Action {
  readonly type = RequestAReceiptActionTypes.REQUEST_A_RECEIPT_SUBMIT_SUCCEEDED;
}

export class ReceiptSubmissionFailed implements Action {
  readonly type = RequestAReceiptActionTypes.REQUEST_A_RECEIPT_SUBMIT_FAILED;
}

export type RequestAReceiptActionsUnion =
  | ReceiptSubmissionRequested
  | ReceiptSubmissionSucceeded
  | ReceiptSubmissionFailed;
