import { MOCK_HOLIDAYS } from '../../../core';
import { MOCK_CALENDAR__RESPONSE } from '../../calendar';
import { CalendarAggregator } from './types';

export const MOCK_CALENDAR_AGGREGATOR_RESPONSE: CalendarAggregator = {
  lowestFareLabel:
    'One-way, per person, lowest fares shown, incl. taxes & fees. Selecting a return date displays the roundtrip fare.',
  expandLinkLabel: 'See more months',
  seasonalLabel: 'The route you’ve selected only operates seasonally.',
  holidays: MOCK_HOLIDAYS,
  calendarContent: MOCK_CALENDAR__RESPONSE,
  maxSeats: 5,
  dealStartDate: new Date(),
};
