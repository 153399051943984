import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { SESSION_STORAGE } from '../injection-tokens';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class SessionStorageService extends StorageService {
  constructor(
    @Inject(SESSION_STORAGE) public storage: Storage,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) {
    super();
    this.storageType = 'session';
  }
}
