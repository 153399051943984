import { AuthActionsUnion } from '../auth/auth.actions';
import { TrueBlueState } from '../trueblue/types';
import { ProfileActions, ProfileActionsUnion } from './profile.actions';

export const initialProfileState: TrueBlueState = {
  profile: null,
};

export function profileReducer(
  state = initialProfileState,
  action: ProfileActionsUnion | AuthActionsUnion,
): TrueBlueState {
  switch (action.type) {
    case ProfileActions.PROFILE_LOADED:
      return {
        profile: action['error']
          ? initialProfileState.profile
          : {
              ...state?.profile,
              ...action.payload.profile,
              pay: state?.profile?.pay,
            },
      };

    case ProfileActions.LOAD_TRAVEL_BANK_SUCCEEDED:
      return {
        ...state,
        profile: { ...state.profile, pay: action.payload },
      };

    default:
      return state;
  }
}
