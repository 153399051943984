import { ProgressIndicatorContent, ProgressIndicatorType } from './types';

export const MOCK_PROGRESS_INDICATOR_RESPONSE: ProgressIndicatorContent = {
  [ProgressIndicatorType.DEPART]: {
    title: {
      dateNotSelectedLabel: 'Step 1',
      dateSelectedLabel: 'Depart',
    },
    placeholder: {
      default: 'Select depart date',
      mobile: 'Depart',
    },
  },
  [ProgressIndicatorType.RETURN_RT]: {
    title: {
      dateNotSelectedLabel: 'Step 2',
      dateSelectedLabel: 'Return',
    },
    placeholder: {
      default: 'Select return date',
      mobile: 'Return',
    },
  },
  [ProgressIndicatorType.RETURN_ONE_WAY]: {
    title: {
      dateNotSelectedLabel: 'Optional',
      dateSelectedLabel: 'Return',
    },
    placeholder: {
      default: 'Add return date',
      mobile: 'Return',
    },
  },
  copy: {
    seatCopy: {
      seat: 'seat left',
      seats: 'seats left',
    },
    personCopy: {
      default: 'per person',
      mobile: '/pp',
    },
    ptsLabel: { default: 'pts' },
  },
};
