<div class="min-h-7"></div>
<div class="w-40 center pt6 pb6">
  <dot-text-block [height]="20"></dot-text-block>
</div>
<div class="w-70 w-30-ns center mt1-ns pt2 pt5-ns pb4 pb5-ns">
  <dot-text-block></dot-text-block>
</div>
<div class="input-container input-ph-ns center mt1 pt5 pb4">
  <div
    class="bg-white b--medium-gray ba input mb1 mb0-ns mr1-ns mh3 mh0-ns"
  ></div>
</div>
<div class="pt2 pb8 mh3 mh0-ns">
  <div class="w-100 mv2 center input-container bg-white b--medium-gray ba br3">
    <dot-text-block class="db mt4 ml5" [width]="40"></dot-text-block>
    <dot-text-block
      class="db mt3 mb4 ml5"
      [width]="20"
      [height]="12"
    ></dot-text-block>
  </div>
  <div class="w-100 mv2 center input-container bg-white b--medium-gray ba br3">
    <dot-text-block class="db mt4 ml5" [width]="40"></dot-text-block>
    <dot-text-block
      class="db mt3 mb4 ml5"
      [width]="20"
      [height]="12"
    ></dot-text-block>
  </div>
</div>
