import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubNavSectionTravelInfo } from '@shared/header';

/**
 * @description Container for the dropdown of Travel Info in navigation bar in the app header
 * @WhereIsItUsed subnav-wrapper
 */
@Component({
  selector: 'jb-travel-info-subnav-template',
  templateUrl: './travel-info-subnav-template.component.html',
  styleUrls: ['./travel-info-subnav-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TravelInfoSubnavTemplateComponent {
  @Input('section') subNav: SubNavSectionTravelInfo;
  @Input() isMobile: boolean;
}
