import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import { JbLinkModule } from 'jb-component-library';

import { JBInnerHtmlModule } from '../jb-inner-html';
import { UIModule } from '../ui/ui.module';
import { JBTextImageComponent } from './jb-text-image.component';

const MODULES = [
  CommonModule,
  UIModule,
  JBInnerHtmlModule,
  JbLinkModule,
  LinkIconModule,
];

const DECLARATIONS = [JBTextImageComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class JBTextImageModule {}
