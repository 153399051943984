import { getCurrencySymbol } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppConfigService, HttpService } from '@core/index';
import { IsMobileAppService } from '@core/is-mobile-app';
import {
  FareSaleDestination,
  FareSaleDialogConfig,
  FareSaleFlightsOffer,
  FareSaleMintsOffer,
  FareSaleOrigin,
  FareSaleVacationsOffer,
  FlightFare,
  FlightHotelFare,
  FlightMintFare,
} from '@models/fare-sale.models';
import {
  getFlightFare,
  getFlightHotelFare,
  getFlightMintFare,
  INITIAL_NFS_MODAL_CONFIG,
} from '@models/utils/fare-sale.utils';
import { BookerAirService } from '@shared/booker/bookers/booker-air/booker-air.service';
import { FareSaleListPriceType } from '@store/fare-sale/types';
import { OriginsApi } from '@store/origins/api';
import { RouterFacade } from '@store/router/router.facade';
import { JbDialogService } from 'jb-component-library';
import { cond } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';

/**
 * @description Dialog opened when flight selected on fare sale to confirm and redirect user
 * @WhereIsItUsed fare-sale-block
 */
@Component({
  selector: 'jb-fare-sale-modal-dialog',
  templateUrl: './fare-sale-modal-dialog.component.html',
  styleUrls: ['./fare-sale-modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FareSaleModalDialogComponent implements OnInit {
  private _destroy = inject(DestroyRef);

  isMobile: boolean;
  flightSelected: boolean;
  flightHotelSelected: boolean;
  priceInDollars: boolean;
  priceInPoints: boolean;
  destinationImage: string;
  offer: FareSaleVacationsOffer | FareSaleFlightsOffer | FareSaleMintsOffer;
  selectDatesIsLoading = true;

  flightFare: FlightFare;
  flightHotelFare: FlightHotelFare;
  flightMintFare: FlightMintFare;
  destination: FareSaleDestination;
  origin: FareSaleOrigin;
  config: FareSaleDialogConfig;

  selectDatesUrl$: BehaviorSubject<string> = new BehaviorSubject(null);

  getFlightFare: typeof getFlightFare;
  getFlightHotelFare: typeof getFlightHotelFare;
  getFlightMintFare: typeof getFlightMintFare;
  VACATIONS_FOOTER_VACATION_NIGHTS = '[VACATION-NIGHTS]';
  defaultCurrencyCode = 'USD';
  getCurrencySymbol = (code: string) => getCurrencySymbol(code, 'narrow');
  enableLazyLoadImage: boolean;
  placeholderParams: string;

  constructor(
    public dialogService: JbDialogService,
    public routerFacade: RouterFacade,
    private isMobileAppService: IsMobileAppService,
    private originsApi: OriginsApi,
    private bookerAirService: BookerAirService,
    private appConfigService: AppConfigService,
  ) {
    this.config = INITIAL_NFS_MODAL_CONFIG;
    this.getFlightFare = getFlightFare;
    this.getFlightHotelFare = getFlightHotelFare;
    this.getFlightMintFare = getFlightMintFare;

    this.isMobileAppService
      .getIsMobileApp()
      .pipe(first())
      .subscribe((isMobileApp: boolean) => {
        this.isMobile = isMobileApp;
      });

    this.enableLazyLoadImage = this.appConfigService.lazyLoadImages;
    this.placeholderParams =
      this.appConfigService.paths[0].placeholderImageParams;
  }

  ngOnInit(): void {
    this.selectDatesUrl$
      .pipe(
        takeUntilDestroyed(this._destroy),
        map(
          url =>
            `${url}${
              this.appConfigService.openDatePickerFromFareSalePage
                ? '&openDatePicker=true'
                : ''
            }`,
        ),
      )
      .subscribe(() => {
        this.selectDatesIsLoading = false;
      });
  }

  handleData = (
    destination: FareSaleDestination,
    origin: FareSaleOrigin,
    pricingModel: FareSaleListPriceType,
    modalType: 'flight' | 'flight + hotel' | 'mint',
    destinationImage: string,
    offer: FareSaleFlightsOffer | FareSaleVacationsOffer | FareSaleMintsOffer,
    flightsGoTo: string,
    pageWithBooker: string,
    destinationsForEachOrigin,
    countriesWithAirports,
  ) => {
    this.destination = destination;
    this.origin = origin;
    this.destinationImage = destinationImage;
    this.offer = offer;

    this.config = {
      flight: {
        selected: modalType === 'flight',
        header: 'Flight details',
        fare: this.getFlightFare(destination['fares']),
      },
      flightHotel: {
        selected: modalType === 'flight + hotel',
        header: 'Flights + Hotel details',
        fare: this.getFlightHotelFare(destination['fares']),
      },
      flightMint: {
        selected: modalType === 'mint',
        header: 'Mint details',
        fare: this.getFlightMintFare(destination['fares']),
      },
      pricingType: this.getPricingType(pricingModel),
    };

    this.constructFlightFareUrls(
      flightsGoTo,
      pageWithBooker,
      destinationsForEachOrigin,
      countriesWithAirports,
    );
  };

  constructFlightFareUrls(
    flightsGoTo: string,
    pageWithBooker: string,
    destinationsForEachOrigin,
    countriesWithAirports,
  ) {
    this.selectDatesIsLoading = true;
    let flight: FlightFare | FlightMintFare;
    if (this.config.flightMint.selected) {
      flight = this.config.flightMint.fare;
    } else {
      flight = this.config.flight.fare;
    }
    // dollar and points query string will include roundtrip parameter from CMS
    const dollarsQueryString = `${flight.urlAirwaysDollars.substring(
      flight.urlAirwaysDollars.indexOf('?') + 1,
    )}`;
    const pointsQueryString = `${flight.urlAirwaysPoints.substring(
      flight.urlAirwaysPoints.indexOf('?') + 1,
    )}`;

    switch (flightsGoTo) {
      case 'pageWithBooker':
        flight.urlAirwaysDollars = `${pageWithBooker}?${dollarsQueryString}`;
        flight.urlAirwaysPoints = `${pageWithBooker}?${pointsQueryString}`;
        this.selectDatesUrl$.next(this.pickUrlForSelectDates());
        break;
      case 'flightResults':
        const destDataForOrigin = destinationsForEachOrigin[flight.origincode];
        if (destDataForOrigin?.destinationAirports) {
          if (
            !destDataForOrigin.destinationFlightUrlsAreSet[
              flight.destinationcode
            ]
          ) {
            this.setFlightResultsUrls(
              flight,
              dollarsQueryString,
              pointsQueryString,
              destDataForOrigin.destinationAirports,
              countriesWithAirports,
            );
            destDataForOrigin.destinationFlightUrlsAreSet[
              flight.destinationcode
            ] = true; // indicates urls for this destination have been set now
          }

          this.selectDatesUrl$.next(this.pickUrlForSelectDates());
        } else {
          this.originsApi
            .getDestinationAirportsFromOrigin(flight.origincode, 'DEALS')
            .pipe(
              filter(HttpService.isSuccessful),
              filter(airportsResponse => !!airportsResponse.response?.length),
              map(airportsResponse => airportsResponse.response),
              take(1),
            )
            .subscribe(destinationAirports => {
              this.setFlightResultsUrls(
                flight,
                dollarsQueryString,
                pointsQueryString,
                destinationAirports,
                countriesWithAirports,
              );
              // Cache the destinationAirports response and set urlsAreSet flag to true
              destinationsForEachOrigin[flight.origincode] = {
                destinationAirports,
                destinationFlightUrlsAreSet: { [flight.destinationcode]: true },
              };

              this.selectDatesUrl$.next(this.pickUrlForSelectDates());
            });
        }
        break;
      default:
        this.selectDatesUrl$.next(this.pickUrlForSelectDates());
        break;
    }
  }

  setFlightResultsUrls(
    flight,
    dollarsQueryString,
    pointsQueryString,
    destinationAirports,
    countriesWithAirports,
  ) {
    flight.urlAirwaysDollars = this.bookerAirService.getFlightResultsUrl(
      dollarsQueryString,
      false,
      false,
      destinationAirports,
      countriesWithAirports,
    );
    flight.urlAirwaysPoints = this.bookerAirService.getFlightResultsUrl(
      pointsQueryString,
      true,
      false,
      destinationAirports,
      countriesWithAirports,
    );
    flight.urlAirwaysAppDollars = this.bookerAirService.getFlightResultsUrl(
      dollarsQueryString,
      false,
      true,
      destinationAirports,
      countriesWithAirports,
    );
    flight.urlAirwaysAppPoints = this.bookerAirService.getFlightResultsUrl(
      pointsQueryString,
      true,
      true,
      destinationAirports,
      countriesWithAirports,
    );
  }

  pickUrlForSelectDates = (): string => {
    const pricing = cond([
      [() => this.config.pricingType === 'dollars', () => 'Dollars'],
      [() => this.config.pricingType === 'points', () => 'Points'],
    ])();
    const appText = this.isMobile ? 'App' : '';

    if (this.config.flight.selected) {
      const key = `urlAirways${appText}${pricing}`;
      return this.config.flight.fare[key];
    }
    if (this.config.flightHotel.selected) {
      const key = `urlVacations${appText}${pricing}`;
      return this.config.flightHotel.fare[key];
    }
    if (this.config.flightMint.selected) {
      const key = `urlAirways${appText}${pricing}`;
      return this.config.flightMint.fare[key];
    }
  };

  getActiveHeader(): string {
    for (const flightType of Object.values(this.config)) {
      if (flightType.selected && flightType.fare) {
        return flightType.header;
      }
    }
    return '';
  }

  getPricingType(id: string) {
    const pricingMap = {
      '1': 'dollars',
      '2': 'points',
    };
    return pricingMap[id];
  }
}
