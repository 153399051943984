export enum DeviceType {
  iOS = 'iOS',
  Android = 'Android',
  Other = 'Other',
}

export interface DeviceInfo {
  type: DeviceType;
  appStoreUrl: string;
}

export interface AppStoreUrls {
  iOS: string;
  Android: string;
}
