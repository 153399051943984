<ng-container *ngIf="vm$ | async; let vm">
  <ng-container *ngIf='vm.isInFooter || vm.isInHero || !vm.heroExists'>
    <jb-form-field-container type="primary" [ngClass]="{
    'center jb-max-width page-margin pt4': !vm.isInFooter && !vm.isInHero && !vm.isHelpPage,
    'flex w-100 justify-center': vm.isInFooter,
    'jb-grid one-up grid-offset-l': vm.isHelpPage}" >
      <div class="w-100 flex">
        <jb-autocomplete
          #autocomplete
          [allowFreeformEntry]="true"
          [isModalOpenedOnMobileClick]="false"
          [formControl]="formControl"
          [options]="[]"
          [groups]="vm.insertedOptions"
          [disableFilter]="true"
          [isLegendShown]="false"
          [label]="vm.content.placeholder"
          class="w-100 body klarheit bg-white"
          [hasDynamicData]="true"
          [shouldFlipVertically]="false"
          (click)="onClick()"
          (keyup)="onKeydown($event)"
          (optionSelected)=onOptionSelected($event?.value)
          (focusin)="onFocus()"
        ></jb-autocomplete>
        <button jbButton [theme]="ctaTheme" [isPaired]="true" *ngIf="vm.content?.button" (click)="redirectBackToSearch(vm.content.button[0]?.href)">
          <jb-icon name="search" [fill]="fillIcon ? white : null"></jb-icon>
          <jb-sr-only>{{ vm.content.button[0]?.alt }}</jb-sr-only>
        </button>
      </div>
    </jb-form-field-container>
  </ng-container>
</ng-container>
