import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { AppConfigService } from '@core/app-config';
import { DateService } from '@core/date/date.service';
import { EventsService } from '@core/events';
import { IS_WEB_COMPONENT, WINDOW } from '@core/injection-tokens';
import { RouterService } from '@core/router';
import { CookieStorageService, LocalStorageService } from '@core/storage';
import { OKTA_AUTH } from '@okta/okta-angular';
import {
  AccessToken,
  IdxTransaction,
  OktaAuth,
  Token,
  TokenParams,
  TokenResponse,
  Tokens,
} from '@okta/okta-auth-js';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { AnalyticsFacade } from '@store/analytics/analytics.facade';
import { GlobalFacade } from '@store/global/global.facade';
import isBefore from 'date-fns/isBefore';
import jwtDecode from 'jwt-decode';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { RouterFacade } from '../../router/router.facade';
import { StoreService } from '../../shared/store.service';
import {
  OktaRenewToken,
  OktaRenewTokenFailure,
  OktaRenewTokenSuccess,
  OktaUIDValue,
  StartLoggingOutClassic,
  StartLoggingOutIdx,
  UserLoggedOutOktaFailure,
} from '../auth.actions';
import { AuthFacade } from '../auth.facade';
import { AuthTimerService } from './auth-timer.service';

@Injectable({ providedIn: 'root' })
export class OktaService {
  isLogoutWithIframes: boolean = true;
  shouldUseIdx = false;
  isSigningInWithIdx$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isFrenchRedirect = false;
  public authClient: OktaAuth;

  constructor(
    private appConfig: AppConfigService,
    private storeService: StoreService,
    private authFacade: AuthFacade,
    private cookieStorageService: CookieStorageService,
    @Inject(WINDOW) private window,
    private routerFacade: RouterFacade,
    public dateService: DateService,
    private routerService: RouterService,
    private authTimerService: AuthTimerService,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(IS_WEB_COMPONENT) private isWebComponent: boolean,
    private eventsService: EventsService,
    private globalFacade: GlobalFacade,
    private analyticsFacade: AnalyticsFacade,
  ) {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      return;
    }

    this.authClient = inject(OKTA_AUTH); // we moved here so ng does not try to create okta during ssr;
    this._handleUserAuthState();

    /**  Injects into the window service a function named useExperimentalAuthorization.
     * This will be used to update OKTA flags using the browser inspector. Making it easy to setup the MFA flow.
     */
    if (!this.appConfig.isProdEnv) {
      this.window.useExperimentalAuthorization =
        this._useExperimentalAuthorization.bind(this);
    }

    this.authFacade.oktaPreExistingSessionCheckUpdate('Idle');

    // When Navigating to the Sign In page.. Get Auth Method (Idx/Classic) from queryParams.
    this.routerService.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationEnd && event.url.includes('/signin'),
        ),
      )
      .subscribe(() => {
        /* Setup oktaConfig, checking if we should use Idx or Classic.  */
        const params = new URLSearchParams(this.window.location.search);
        const useIdxParam: boolean = booleanStringCheck(params.get('useIdx'));

        this.shouldUseIdx = this.appConfig.useIdx && useIdxParam;
        this.isSigningInWithIdx$.next(this.shouldUseIdx);
      });

    if (window.location.search.indexOf('code') <= -1) {
      this.routerService.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          first(),
          switchMap(() => {
            return combineLatest([
              this.authFacade.accessToken,
              this.routerFacade.isSignInPage,
              this.routerFacade.isLogOutPage,
            ]).pipe(
              filter(
                ([_, isSigninPage, isLogoutPage]) =>
                  !isSigninPage && !isLogoutPage,
              ),
              filter(
                ([accessToken]) => this.authCookieExists() && !accessToken,
              ),
              tap(() => this.checkUserSession()),
            );
          }),
        )
        .subscribe();
    }

    this.bindTokenExpiryListener();

    this.authClient.tokenManager.on('error', (err: any) => {
      if (err.errorCode === 'login_required' && err.accessToken) {
        this.storeService.dispatchAction(new OktaRenewTokenFailure(err));
      }
    });
  }

  /**
   * This function is intended for use exclusively in the browser inspector when invoking `window.useExperimentalAuthorization()`.
   * The purpose of this function is to simplify the setup of the Multi-Factor Authentication (MFA) flow for testing purposes.
   * By calling this function, other teams can easily configure the necessary settings for testing without the need for manual setup.
   * @param {boolean} flagState - A boolean flag that defines whether the Okta flags must be set to true or false.
   * @returns {string} A message indicating the status of the configuration.
   */
  private _useExperimentalAuthorization(flagState: boolean): string {
    if (typeof flagState !== 'boolean') {
      return 'Param must be true or false. Eg: window.useExperimentalAuthorization(true)';
    }

    // Update current state for our appConfig.
    this.appConfig.useIdx = flagState;
    this.appConfig.useWidget = flagState;
    this.appConfig.useFetchInterceptor = flagState;

    // Update Localstorage with the new configuration
    const currentConfig = this.appConfig.prepareAndPickConfigSource();
    const newConfig = {
      ...currentConfig,
      oktaFlags: {
        ...(currentConfig.oktaFlags || {}),
        useWidget: this.appConfig.useWidget,
        useIdx: this.appConfig.useIdx,
        useFetchInterceptor: this.appConfig.useFetchInterceptor,
      },
    };
    this.appConfig.getConfigUtilities().readFromLocalstorage(true);
    this.appConfig.syncConfigToLocalStorage(newConfig);

    // Send a message to the user saying that the changes have been applied.
    return 'Authentication Flags have been updated. Refresh your browser to update your changes.';
  }

  public retrieveTokenFromCode(): Promise<TokenResponse | void> {
    return this.authClient.token
      .parseFromUrl()
      .then(res => {
        this.authFacade.setTokenSuccess(res);
        return res;
      })
      .catch(err => this.authFacade.setTokenFailure(err));
  }

  setAccessToken(transaction: IdxTransaction | { tokens: Tokens }) {
    if (transaction?.tokens?.accessToken) {
      this._handleUserAuthState(transaction.tokens);
      this.authFacade.setTokenSuccess(transaction);
    }
  }

  sessionExists(): Observable<boolean> {
    return from(this.authClient.session.exists()).pipe(
      catchError(() => of(false)),
    );
  }

  getWithoutPrompt(params?: TokenParams): Observable<TokenResponse> {
    return from(this.authClient.token.getWithoutPrompt(params)).pipe(
      catchError(err => {
        return throwError(() => new Error(err));
      }),
    );
  }

  async checkUserSession(): Promise<void> {
    this.authFacade.oktaPreExistingSessionCheckUpdate('Checking');

    try {
      const session: any = await this.authClient.session.get();
      const hasSession = session.status !== 'INACTIVE';

      this.authFacade.oktaPreExistingSessionCheckUpdate(
        hasSession ? 'Exists' : 'NoSession',
      );

      if (hasSession) {
        this.authFacade.setToken(session.id);
        await this.authClient.session.refresh();
      } else {
        const authJBDotcom: any =
          this.localStorageService.getItem('authJBDotcom');

        if (authJBDotcom && authJBDotcom.accessToken) {
          this.authFacade.setTokenFailure('NoSession');
        }
      }
    } catch (error) {
      console.error('Error fetching session:', error);
    }
  }

  authCookieExists() {
    return this.cookieStorageService.getCookie(
      this.appConfig.okta.oktaAuthCookie,
    );
  }

  /* Function exclusive for classic. It is generating the UID and renewing the token. */
  dispatchAfterUIDAndRenewToken(tokens: Tokens) {
    this.generateAndDispatchUID(tokens);
    const accessToken = {
      ...tokens.accessToken,
      requestedAt: this.dateService.getNewDate().getTime(),
    };

    this.storeService.dispatchAction(
      new OktaRenewTokenSuccess('accessToken', accessToken),
    );
    this.storeService.dispatchAction(new StartLoggingOutClassic());
  }

  private generateAndDispatchUID(tokens: Tokens) {
    let uid: string | null;
    try {
      uid = jwtDecode(tokens.accessToken.accessToken).uid;
    } catch (err) {
      uid = null;
    }
    this.storeService.dispatchAction(new OktaUIDValue(uid));
  }

  /*
  For Classic, generate UID and Renew the token.
  */
  getUIDAndRenewToken() {
    // Get what process was used to generate the token.
    this.getWithoutPrompt()
      .pipe(
        tap(res => {
          this.dispatchAfterUIDAndRenewToken(res.tokens);
        }),
        catchError(err => {
          return of(
            this.storeService.dispatchAction(new UserLoggedOutOktaFailure(err)),
          );
        }),
        take(1),
      )
      .subscribe();
  }

  /*
  For Idx, it is not necessary to Renew Token. We only need to invalidate the token using revokeAccessToken().
  */
  getUIDAndRevokeIdxToken() {
    try {
      this.authClient.revokeAccessToken().then(() => {
        this.authClient
          .closeSession()
          .then(() =>
            this.storeService.dispatchAction(new StartLoggingOutIdx()),
          )
          .catch(err => {
            this.storeService.dispatchAction(new UserLoggedOutOktaFailure(err));
          });
      });
    } catch (err) {
      this.storeService.dispatchAction(new UserLoggedOutOktaFailure(err));
    }
  }

  clearToken(): void {
    this.authClient.tokenManager.clear();
  }

  closeSession(): Promise<any> {
    return this.authClient.revokeAccessToken().then(() => {
      return this.authClient.closeSession();
    });
  }

  bindTokenExpiryListener(): void {
    this.authClient.tokenManager.on('expired', key => {
      // The expiry time should be after the current date, otherwise it means there
      // were no user activity until the refresh token was called.
      // if (
      if (
        isBefore(
          new Date(this.authTimerService.getTimerExpiry()),
          this.dateService.getNewDate(),
        )
      ) {
        this.authTimerService.subscribeUserEvents();
        return;
      }

      this.storeService.dispatchAction(new OktaRenewToken(key));
    });
  }

  unbindTokenExpiryListener(): void {
    this.authClient.tokenManager.off('expired');
  }

  public setToken(sessionId: string): Observable<TokenResponse> {
    return this.getWithoutPrompt({
      responseType: 'token',
      sessionToken: sessionId,
    }).pipe(
      take(1),
      map(res => {
        this._handleUserAuthState(res.tokens);
        return res;
      }),
    );
  }

  public refreshToken(key): Observable<Token> {
    return from(this.authClient.tokenManager.get(key)).pipe(
      switchMap((token: Token) =>
        from(this.authClient.tokenManager.renew(token[key])),
      ),
      catchError(err => {
        throw err;
      }),
    );
  }

  /**
  @description This is the initial phase of the Authentication flow.
  * Upon clicking the "Sign In" button in our header, a trigger will initiate the dispatch of an event.
  * This event is going to invoke this function.. Any APP that is using our Shared Header can trigger this event.
  */
  public login(returnUrl?: string) {
    /**
     * Depending on the Flow, we may need to navigate the user to a different place using a different Logic.
     */
    /** Idx - It can be used for Regular Idx or Widget Idx (useClassicEngine: false). */
    if (this.appConfig.useIdx) {
      this.routerService.navigate('signin', {
        queryParams: {
          useIdx: true,
          returnUrl:
            returnUrl || this.routerService?.currentUrl?.replace('/', ''),
        },
      });
    } else {
      // ClassicWidget - When using OKTA Widget and useClassicEngine as a true.
      if (this.appConfig.useWidget) {
        this.routerService.navigate('signin', {
          queryParams: {
            useIdx: false,
            returnUrl:
              returnUrl || this.routerService.currentUrl.replace('/', ''),
          },
        });
      } else {
        /*
         * Classic - Refers to our legacy codebase, which orchestrates the user's redirection to the OKTA side by the IDP value.
         */
        this.authClient.token.getWithRedirect({
          idp: this._isFrenchRedirect
            ? this.appConfig.okta.auth.frenchIdp
            : this.appConfig.okta.auth.idp,
          prompt: 'login',
          state: btoa(
            JSON.stringify({
              returnUrl: returnUrl || this.routerService.currentUrl,
            }),
          ),
        });
      }
    }
  }

  // The effect loginOktaIdx$ will call this function. It is exclusive for Idx.
  async loginWithIdx(username: string, password: string) {
    // Start a new transaction with OKTA.
    await this.authClient.idx.startTransaction();

    // Authenticate user with OKTA services using OKTA SDK.
    return this.authClient.idx.authenticate({
      username,
      password,
    });
  }

  hasExpiredToken(token: AccessToken): boolean {
    let isExpired;
    if (token) {
      isExpired = this.authClient.tokenManager.hasExpired(token);

      if (isExpired) {
        this.storeService.dispatchAction(new OktaRenewToken('accessToken'));
      }
      return isExpired;
    } else {
      return true;
    }
  }

  /**
   * @description This function checks the user's authentication status. In case of error, the sign out flow will happen.
   */
  private _handleUserAuthState(tokens?: Tokens): void {
    if (!tokens) {
      this.sessionExists()
        .pipe(
          switchMap(session =>
            combineLatest([
              this.authFacade.accessToken,
              this.authFacade.isProfileLoaded,
              this.routerFacade.isSignInPage,
              this.routerFacade.isLogOutPage,
            ]).pipe(
              filter(
                /* Do not trigger this observable in the following pages */
                ([_, __, isSignInPage, isLogOutPage]) =>
                  !isSignInPage &&
                  !isLogOutPage &&
                  !this.window.location?.pathname?.includes('login-callback'),
              ),
              // Take only one.. There is a OKTA hook that will re-subscribe to it every time the access token is added.
              take(1),
              // Organize the switch map response. Returning the following informations
              map(([storedAccessToken, isProfileLoaded]) => [
                session,
                storedAccessToken,
                isProfileLoaded,
              ]),
            ),
          ),
          switchMap(([session, storedAccessToken, isProfileLoaded]) => {
            // In case session is true, get a token from OKTA without prompt
            if (session && !this.isWebComponent) {
              return this.getWithoutPrompt();
            }

            /** In case session is false and there are any data from previous session, trigger a logout flow without creating Iframes. */
            if (!session) {
              const shouldLogout = isProfileLoaded || storedAccessToken;

              if (shouldLogout) {
                this.isLogoutWithIframes = false;
                this.authFacade.startLogoutUserOktaFlow();
              }
            }
            return of(null);
          }),
          filter(oktaToken => !!oktaToken),
          switchMap(oktaToken => this._dispatchLoginSuccess(oktaToken.tokens)),
        )
        .subscribe();
    } else {
      this._dispatchLoginSuccess(tokens).pipe(take(1)).subscribe();
    }
  }

  /*
    Ensure the header is fully rendered before proceeding. This is important because without this observer,
    the login flow will continue without the header start to listem to the JB_HEADER_LOGIN_SUCCESS_INPUT_EVENT.
    If that happens, the user authentication flow will finish and the user state will be empty in our header component
  */
  private _dispatchLoginSuccess(tokens: Tokens): Observable<void> {
    return this.globalFacade.isHeaderRendered.pipe(
      filter(isHeaderRendered => !!isHeaderRendered),
      take(1),
      map(() => {
        this.authFacade.setTokenSuccess({ tokens });
        this.authClient.tokenManager.add('accessToken', tokens.accessToken);
        this.eventsService.dispatchCustomEvent(
          EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_SUCCESS_INPUT_EVENT,
          {
            accessToken: tokens.accessToken.accessToken,
          },
        );
      }),
    );
  }
}
