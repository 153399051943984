const getServedExperiences = require('jb-abtest');
import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ABTestFullServedExperience } from '@core/analytics/abtest/types/abtest-served-experience.type';
import { DOCUMENT, WINDOW } from '@core/injection-tokens';
import { isEmpty, isNil, or } from 'ramda';
import { BehaviorSubject, of } from 'rxjs';

import { CookieStorageService } from '../storage/cookie-storage.service';
import { AbtestCampaign, JbCampaign } from './abtest-campaign.type';
import { AbtestConfigProviderService } from './abtest-config-provider.service';

@Injectable({
  providedIn: 'root',
})
export class AbtestExperienceGeneratorService {
  // Maybe this could be refactored to a map
  private _currentConfig$: BehaviorSubject<ABTestFullServedExperience[]>;
  private window: any;
  private document: any;
  private abtestConfigProviderService: AbtestConfigProviderService;
  private cookieStorageService: CookieStorageService;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.window = inject(WINDOW);
    this.document = inject(DOCUMENT);
    this.abtestConfigProviderService = inject(AbtestConfigProviderService);
    this.cookieStorageService = inject(CookieStorageService);
    this._currentConfig$ = new BehaviorSubject(null);
  }

  get currentConfig() {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    return this._currentConfig$.value;
  }

  retrieveAbTestConfig() {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    return this._currentConfig$.asObservable();
  }

  setAbTests(path: string): ABTestFullServedExperience {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const userAgent = this.window.navigator.userAgent;
    const cookieObj = {
      require: this.document.cookie,
      response: { cookie: () => null },
    };
    const abTestsOnCurrentPage = this.getCurrentPageABTest(
      path,
      this.abtestConfigProviderService.getConfig(),
    );
    const currentPageConfig = this.generateExperiences(
      abTestsOnCurrentPage,
      cookieObj,
      userAgent,
    );

    let newConfigToStore = [];
    // replacement for currentPageConfig cookie which no longer exists
    // append global and page configs, should find a better way to do this
    if (this.currentConfig) {
      newConfigToStore = [...this.currentConfig];
      currentPageConfig.forEach(newVal => {
        let found = false;
        this.currentConfig.forEach((oldVal, index) => {
          if (oldVal.name === newVal.name) {
            found = true;
            newConfigToStore[index] = newVal;
          }
        });
        if (!found) {
          newConfigToStore.push(newVal);
        }
      });
    } else {
      newConfigToStore = currentPageConfig;
    }
    this._currentConfig$.next(newConfigToStore);

    let experience = {} as ABTestFullServedExperience;
    if (currentPageConfig && currentPageConfig.length !== 0) {
      // what is this foreach loop doing?
      currentPageConfig.forEach(servedExperience => {
        experience = servedExperience;
      });
    }

    return experience;
  }

  private generateExperiences(
    config,
    cookieObj,
    userAgent,
  ): ABTestFullServedExperience[] {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    let experiences: ABTestFullServedExperience[] = [];
    if (typeof config !== 'undefined' && config.length > 0) {
      // if you have values in your cookie name that do not format well in a url/uri - then you will have an issue matching
      // the name of the key in the cookie to the name of the key in the config. This is a workaround to fix that issue.
      // for example config.cookieParam[0].name = 'test name' would be matched to the cookie name 'test%20name' inside the getServedExperiences function
      // without this matching, in some cases the experience will always return a 'random' experience
      const urlFormattedConfigs = config.map(configItem => ({
        ...configItem,
        cookieParam: configItem.cookieParam?.map(param => ({
          ...param,
          name: encodeURI(param.name),
        })),
      }));
      experiences = getServedExperiences(
        urlFormattedConfigs,
        cookieObj,
        userAgent,
      );
    }

    // reverts the encodedURI changes for the rest of the code
    experiences = experiences.map(experience => ({
      ...experience,
      cookieParam: {
        ...experience.cookieParam,
        name: decodeURI(experience.cookieParam.name),
      },
    }));

    experiences.forEach(experience => {
      const cookie: string = this.cookieStorageService.getCookie(
        experience?.cookieParam?.name,
      );
      if (or(isNil(cookie), isEmpty(cookie))) {
        const dayExpiry = experience.cookieParam.days * 24 * 60 * 60 * 1000;
        const hoursExpiry = experience.cookieParam.hours * 60 * 60 * 1000;
        this.cookieStorageService.setCookie(
          experience.cookieParam.name,
          experience.experience.name,
          new Date(new Date().getTime() + dayExpiry + hoursExpiry),
        );
      }
    });

    // TODO: once the jb-test library is updated to return an object with
    // applyCssOverride property, the additional spread can be removed
    return experiences.map(experience => {
      return {
        ...experience,
        applyCssOverride: config.find(
          configObj => configObj.name === experience.name,
        )?.applyCssOverride,
      };
    });
  }

  campaignIncludesPath = (normalizedPath: string) => {
    return (campaign: JbCampaign): boolean => {
      if (isPlatformServer(this.platformId)) {
        return null;
      }
      return Array.isArray(campaign.path)
        ? campaign.path.includes(normalizedPath)
        : campaign.path.includes(normalizedPath);
    };
  };

  campaignIsGlobal = (campaign: JbCampaign): boolean => {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    return Array.isArray(campaign.path)
      ? campaign.path.some(p => p.includes('global'))
      : campaign.path.includes('global');
  };

  pathIsHeaderOrFooter = (normalizedPath: string): boolean => {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    return (
      normalizedPath.includes('/application/header') ||
      normalizedPath.includes('/application/footer')
    );
  };

  campaignIsGlobalAndPathIsHeaderOrFooter = (normalizedPath: string) => {
    return (campaign: JbCampaign): boolean => {
      if (isPlatformServer(this.platformId)) {
        return null;
      }
      return (
        this.campaignIsGlobal(campaign) &&
        this.pathIsHeaderOrFooter(normalizedPath)
      );
    };
  };

  getCurrentPageABTest = (
    path: string,
    campaignConfig: AbtestCampaign,
  ): JbCampaign[] => {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;

    const activeCampaigns = (campaignConfig.jbCampaign ?? []).filter(
      campaign =>
        this.campaignIncludesPath(normalizedPath)(campaign) ||
        this.campaignIsGlobalAndPathIsHeaderOrFooter(normalizedPath)(campaign),
    );

    return activeCampaigns;
  };
}
