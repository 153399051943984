import { ApiError } from '@core/http';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { path, prop } from 'ramda';

import { getErrorAction } from '../api/api.selectors';
import { AppState } from '../shared/types/app-state.type';
import { AuthActions, UserLoggedInOktaFlow } from './auth.actions';
import {
  constructPictureBlob,
  tryAssembleAuthProfileFromLeanProfile,
} from './auth.utils';
import { AuthProfile, AuthState, OktaAccessTokenEntry } from './types';

export const auth: MemoizedSelector<AppState, AuthState> =
  createFeatureSelector('auth');

export const isHeaderWebComponent = createSelector(
  auth,
  state => state.isHeaderWebComponent,
);

export const oktaSessionCheckStatus = createSelector(
  auth,
  state => state.oktaSessionCheckStatus,
);

export const authProfile: MemoizedSelector<AppState, AuthProfile> =
  createSelector(auth, state => {
    /*
      This approach is needed in order to preserve how AuthProfile(Legacy|Okta) interfaces with the rest of the app.

      We have many dependencies on facade.authProfile, which usually have a .pipe(filter(Boolean), first())
      and/or make the assumption that truthiness of AuthProfile means that the profile is fully loaded.

      Thus, setting a partially filled authProfile in the reducer with only jwt breaks those components.
      Old authProfile will remain non-truthy as per initial state, given that we're in okta flow.

      We could attempt to simplify this & refactor everything once legacy flow is completely discarded.
    */
    return tryAssembleAuthProfileFromLeanProfile(state);
  });

export const uid: MemoizedSelector<AppState, string> = createSelector(
  auth,
  state => state.uid,
);

export const idToken: MemoizedSelector<AppState, any> = createSelector(
  auth,
  state => state.idToken,
);

export const accessToken: MemoizedSelector<AppState, OktaAccessTokenEntry> =
  createSelector(auth, state => state.accessToken);

export const oktaToken: MemoizedSelector<AppState, any> = createSelector(
  accessToken,
  state => prop('accessToken', state),
);

export const leanProfileOktaStaging: MemoizedSelector<AppState, AuthProfile> =
  createSelector(
    auth,
    path(['authProfileForOktaAndLeanProfileService', 'leanProfileFragment']),
  );

export const isFetchingProfile: MemoizedSelector<AppState, boolean> =
  createSelector(auth, path(['isFetchingProfile']));

export const userLoggedInOktaFlow: MemoizedSelector<AppState, boolean> =
  createSelector(oktaToken, authProfile, (okta, profile) => {
    return Boolean(okta) && Boolean(profile);
  });

export const userProfileLoaded: MemoizedSelector<AppState, boolean> =
  createSelector(authProfile, profile => {
    return Boolean(profile);
  });

export const tbNumber: MemoizedSelector<AppState, string> = createSelector(
  authProfile,
  state => {
    if (!!state) {
      return state.membershipid;
    }
  },
);

export const profilePictureBlob: MemoizedSelector<AppState, string> =
  createSelector(authProfile, profile => {
    if (!!profile) {
      return constructPictureBlob(profile);
    }
  });

export const error: MemoizedSelector<AppState, any> = createSelector(
  getErrorAction<UserLoggedInOktaFlow>(AuthActions.OKTA_USER_LOGGED_IN),
  action => action && action.payload,
);

export const loginError: MemoizedSelector<AppState, ApiError> = createSelector(
  error,
  authError => authError,
);

export const authProfileError: MemoizedSelector<AppState, any> = createSelector(
  auth,
  path(['authProfileError']),
);

export const oktaError = createSelector(auth, state => state.oktaError);
