import { isPlatformServer } from '@angular/common';
import {
  Inject,
  inject,
  Injectable,
  isDevMode,
  PLATFORM_ID,
} from '@angular/core';
import { AbtestExperienceGeneratorService } from '@core/ab-testing/abtest-experience-generator.service';
import { ABTestFullServedExperience } from '@core/analytics/abtest/types/abtest-served-experience.type';
import { AppConfigService } from '@core/app-config';
import { PERSONALIZATION_DEFAULT_ORDER } from '@core/app-config/types/mocks';
import { ABTest, CmsTemplateRouteData } from '@core/cms/types';
import { LanguageService } from '@core/language/language.service';
import { UserVisitService } from '@core/user/user-first-visit.service';
import { BookerResponse } from '@shared/booker/types';
import { transformDynamicBookerData } from '@shared/booker/utils/booker-utils';
import { Image } from '@shared/types/image.type';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import {
  registerLogFullStory,
  waitForFullStory,
} from '@shared/ui/utils/global-utils/full-story';
import {
  GlobalActions,
  ResolveFallbackFooter,
  ResolveFallbackHeader,
  ResolveFallbackTemplate,
} from '@store/global/global.actions';
import { createErrorAction, networkError } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import { includes, keys } from 'ramda';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  CookieStorageService,
  PersonalizationOptions,
  RouterService,
} from '..';
import { HttpService } from '../http';
import {
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  WINDOW,
} from '../injection-tokens';
import {
  IS_USER_BIRTHDAY,
  IS_USER_MOSAIC,
} from '../storage/cookie-storage.keys';
import { CmsApiBuilderService } from './builders/cms-api-builder.service';
import { CMS_FALLBAK_TEMPLATES_DICT } from './cms-fallback-templates-dict.const';
import { CMS_FOOTER_FALLBACK } from './cms-footer-fallback.const';
import { CMS_HEADER_FALLBACK } from './cms-header-fallback.const';
import {
  getCardType,
  getCountryCode,
  getIsBirthday,
  getIsFirstVisit,
  getIsLastVisitSixMonths,
  getIsMosaic,
  getLanguage,
  getMosaicStatus,
  getPointsBalance,
  getRecentSearchDestination,
  getRecentSearches,
  getTargetedOrigin,
  getTravelBankBalance,
  getUpcomingTrip,
} from './personalization/personalization.utils';

export enum STANDALONE_BOOKERS {
  NGB,
  FLIGHTS,
  BFF,
}

@Injectable({ providedIn: 'root' })
export class CMSService {
  // Temporary until it migrates to app-config
  readonly bgImagesURL: string;

  private isWebComponent: InjectionTokenIsWebComponent;
  private window: any;
  private httpService: HttpService;
  private appConfig: AppConfigService;
  private cookieStorageService: CookieStorageService;
  private storeService: StoreService;
  private abtestExperienceGeneratorService: AbtestExperienceGeneratorService;
  private cmsApiBuilderService: CmsApiBuilderService;
  private languageService: LanguageService;
  private userVisitService: UserVisitService;
  private routerService: RouterService;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.bgImagesURL = 'website/customDelivery/home/global/bgImages';
    this.isWebComponent = inject(IS_WEB_COMPONENT);
    this.window = inject(WINDOW);
    this.httpService = inject(HttpService);
    this.appConfig = inject(AppConfigService);
    this.cookieStorageService = inject(CookieStorageService);
    this.storeService = inject(StoreService);
    this.abtestExperienceGeneratorService = inject(
      AbtestExperienceGeneratorService,
    );
    this.cmsApiBuilderService = inject(CmsApiBuilderService);
    this.languageService = inject(LanguageService);
    this.userVisitService = inject(UserVisitService);
    this.routerService = inject(RouterService);
  }

  getDestinationData(destinationName: string): Observable<any> {
    return this.httpService
      .get<any>(
        `${this.appConfig.magnoliaUrl}/.rest/jetblue/v4/destinations/search/destination/${destinationName}`,
        undefined,
        false,
        false,
      )
      .pipe(catchError(error => observableOf(networkError())));
  }

  // request dynamic component metadata from cms
  // this is triggered when we navigate to a new page or switch to a new language. see requestTemplate.
  getTemplateData(routeData: CmsTemplateRouteData): Observable<any> {
    const {
      personalization,
      personalizationTrait,
      recentSearch,
      searchDestination,
      hasUpcomingTrip,
      mosaicStatus,
      travelBankBalance,
      trueBluePointsBalance,
      loggedIn,
    } = routeData;
    const currentLanguage = this.languageService.getLanguage();

    const supportedI18NNextLanguages =
      typeof this.appConfig.getConfigValueFromLocalOrCMSSource(
        'i18n.i18nSupportedLanguages',
      ) === 'string'
        ? this.appConfig
            .getConfigValueFromLocalOrCMSSource('i18n.i18nSupportedLanguages')
            .split(',')
        : (this.appConfig.getConfigValueFromLocalOrCMSSource(
            'i18n.i18nSupportedLanguages',
          ) as unknown as string[]) || [];

    const useExperimentalTranslation =
      booleanStringCheck(
        this.appConfig.getConfigValueFromLocalOrCMSSource(
          'i18n.useExperimentalTranslation',
        ),
      ) && supportedI18NNextLanguages?.includes(currentLanguage);

    const i18nSupportedPersonalizationsOn = booleanStringCheck(
      this.appConfig.getConfigValueFromLocalOrCMSSource(
        'i18n.i18nSupportedPersonalizationsOn',
      ),
    );

    let personalizations = '';
    if (personalizationTrait || routeData.personalization === 'true') {
      const targetedOrigin = routeData.targetedOrigin?.code || null;
      const firstVisit = this.userVisitService.visitStatus();
      const isVisitAfterThreshold =
        this.userVisitService.isVisitAfterThreshold();
      personalizations = this.getPersonalizations(
        personalization,
        {
          targetedOrigin,
          recentSearch,
          searchDestination,
          hasUpcomingTrip,
          mosaicStatus,
          travelBankBalance,
          firstVisit,
          isVisitAfterThreshold,
          trueBluePointsBalance,
        },
        loggedIn,
      );
    }

    if (currentLanguage !== 'en' && !i18nSupportedPersonalizationsOn) {
      personalizations = '';
    }

    const reqPath = routeData.endpoint;

    const abTest = this.abtestExperienceGeneratorService.setAbTests(reqPath);
    this.checkAbTestOverride(routeData, abTest);
    const toolkitParam = routeData.queryParams?.toolkit;
    // adds cms version, i18n prefix (for translation) & abTest posfix
    // pick one url for english and another for spanish and french
    const endpoint = this.cmsApiBuilderService.buildCmsUrl({
      reqPath,
      ab: abTest?.experience?.name || '',
      personalizations,
      toolkit: toolkitParam,
      translateLanguage: currentLanguage,
      translationEnabled: useExperimentalTranslation,
    });
    this.userVisitService.updateLastVisit();
    return this.httpService.get<any>(endpoint, {}, false, false).pipe(
      map(response => {
        response.templateName = routeData.template;
        response.endpoint = routeData.endpoint;

        // specifically written to handle google translate errors
        if (response.googleTranslateError) {
          console.error(
            'Google Translate Error',
            response.googleTranslateErrorData,
          );
        } else {
          console.log('cms.service => getTemplateData => endpoint:', endpoint);
        }

        /**
         * The preference center handles setting the page title differently. We are not currently fetching the data from the CMS.
         * Instead, a logic was implemented in preference-center-routing,module.ts to handle it by calling the i18N static files.
         */
        if (
          currentLanguage !== 'en' &&
          !this.routerService.currentUrl.includes('preference-center')
        ) {
          const defaultTitle =
            'Airline Tickets, Flights & Airfare: Book Direct - Official Site';
          const titleFromResponse = response.data?.title;
          const suffix = ' | JetBlue';

          let titleToSet = defaultTitle;

          if (titleFromResponse) {
            titleToSet = titleFromResponse.includes(suffix)
              ? titleFromResponse.replace(suffix, '')
              : titleFromResponse;
          }

          this.routerService.setPageTitle({ pageName: titleToSet });
        }

        return response;
      }),
      catchError(error => {
        const isDurable = includes(
          routeData.endpoint,
          keys(CMS_FALLBAK_TEMPLATES_DICT),
        );

        waitForFullStory(this.window).then(() => {
          registerLogFullStory(
            isDurable
              ? 'Homepage Fallback'
              : `CMS Template Failure: ${routeData.pageName || routeData.path}`,
            {
              page_path_str: this.window.location.href,
              error_message_str: error?.message,
              error_stack_str: error?.stack?.substring(0, 1000),
              error_status_code_str: error?.status,
            },
            this.window,
          );
        });

        if (this.appConfig.isCMSFallbackEnabled && isDurable) {
          this.storeService.dispatchAction(
            new ResolveFallbackTemplate(routeData.endpoint),
          );
          return observableOf();
        } else {
          this.storeService.dispatchAction(
            createErrorAction(GlobalActions.LOAD_TEMPLATE, error),
          );
          return observableOf(networkError());
        }
      }),
    );
  }

  getHeader(routeData: CmsTemplateRouteData) {
    const reqPath = '/application/header';
    const abTest = this.abtestExperienceGeneratorService.setAbTests(reqPath);
    const currentLanguage = this.languageService.getLanguage();
    const { personalizations, useExperimentalTranslation } =
      this.getPersonalizationsHeaderFooter(currentLanguage, routeData);

    // need to use location search here because getHeader is happening before angular router resolves
    // and the action to populate the store with the router data
    const queryParams = new URLSearchParams(location.search);
    this.checkAbTestOverride(
      {
        queryParams: {
          testname: queryParams.get('testname'),
          testvalue: queryParams.get('testvalue'),
        },
      },
      abTest,
    );
    // pick one url for english and another for spanish and french
    let endpoint = this.cmsApiBuilderService.buildCmsUrl({
      reqPath,
      ab: abTest?.experience?.name || '',
      personalizations,
      toolkit: queryParams.get('toolkit'),
      translateLanguage: currentLanguage,
      translationEnabled: useExperimentalTranslation,
    });

    // This is done only for dev to run  dev:header
    if (
      this.appConfig.environment === 'dev' &&
      this.isWebComponent === 'header'
    ) {
      endpoint = this.appConfig.baseDomain + endpoint;
    }
    return this.httpService.get(endpoint, {}, false, false).pipe(
      tap((response: any) => {
        // specifically written to handle google translate errors
        if (response.googleTranslateError) {
          console.error(
            'Google Translate Error',
            response.googleTranslateErrorData,
          );
        } else {
          console.log('cms.service => getHeader => endpoint:', endpoint);
        }
      }),
      catchError(error => {
        if (this.appConfig.isCMSFallbackEnabled) {
          this.storeService.dispatchAction(
            new ResolveFallbackHeader(CMS_HEADER_FALLBACK),
          );
          return observableOf();
        } else {
          this.storeService.dispatchAction(
            createErrorAction(GlobalActions.LOAD_HEADER, error),
          );
          return observableOf(networkError());
        }
      }),
    );
  }

  // added routeData required for personalizations
  getFooter(routeData: CmsTemplateRouteData) {
    const reqPath = '/application/footer';
    const abTest = this.abtestExperienceGeneratorService.setAbTests(reqPath);
    const queryParams = new URLSearchParams(location.search);
    const currentLanguage = this.languageService.getLanguage();
    const { personalizations, useExperimentalTranslation } =
      this.getPersonalizationsHeaderFooter(currentLanguage, routeData);

    // pick one url for english and another for spanish and french
    let endpoint = this.cmsApiBuilderService.buildCmsUrl({
      reqPath,
      ab: abTest?.experience?.name || '',
      personalizations,
      toolkit: queryParams.get('toolkit'),
      translateLanguage: currentLanguage,
      translationEnabled: useExperimentalTranslation,
    });
    // This is done only for dev to run  dev:footer
    if (
      this.appConfig.environment === 'dev' &&
      this.isWebComponent === 'footer'
    ) {
      endpoint = this.appConfig.baseDomain + endpoint;
    }
    return this.httpService.get<any>(endpoint, {}, false, false).pipe(
      tap(response => {
        // specifically written to handle google translate errors
        if (response.googleTranslateError) {
          console.error(
            'Google Translate Error',
            response.googleTranslateErrorData,
          );
        } else {
          console.log('cms.service => getFooter => endpoint:', endpoint);
        }
      }),
      catchError(error => {
        if (this.appConfig.isCMSFallbackEnabled) {
          this.storeService.dispatchAction(
            new ResolveFallbackFooter(CMS_FOOTER_FALLBACK),
          );
          return observableOf();
        } else {
          this.storeService.dispatchAction(
            createErrorAction(GlobalActions.LOAD_FOOTER, error),
          );
          return observableOf(networkError());
        }
      }),
    );
  }

  getCssOverrides(routePath: string) {
    // TODO: calling setAbTests is a unnecessary work thats already been done,
    // look into way to refactor to just 'get' abtest that has already been set
    const abTest = this.abtestExperienceGeneratorService.setAbTests(routePath);
    const overridesRequestEndpoint = `${
      this.appConfig.magnoliaUrl
    }/dam/ui-assets/css-overrides/${
      booleanStringCheck(abTest.applyCssOverride)
        ? abTest?.experience?.name || ABTest.A
        : ABTest.A
    }.css`;

    return this.httpService
      .getText(overridesRequestEndpoint, {}, false, true)
      .pipe(
        map(
          // map to return the css override boolean and the route path
          cssText => [
            cssText,
            booleanStringCheck(abTest.applyCssOverride),
            routePath,
          ],
        ),
      );
  }

  getPersonalizations(
    allowPersonalization: boolean | string,
    options?: PersonalizationOptions,
    loggedIn?: boolean,
  ): string {
    if (!booleanStringCheck(allowPersonalization)) {
      return '';
    }

    const personalizationMapping = {
      birthday: getIsBirthday(this.getCookie(IS_USER_BIRTHDAY)),
      cardType: getCardType(this.getCookie('cardStatus')),
      countryCode: getCountryCode(this.getCookie('jbCountryCode')),
      currentLang: getLanguage(this.languageService.getLanguage()),
      targetedOrigin: getTargetedOrigin(options?.targetedOrigin),
      visitor: getIsMosaic(this.getCookie(IS_USER_MOSAIC), loggedIn),
      recentSearch: getRecentSearches(options?.recentSearch),
      searchDestination: getRecentSearchDestination(options?.searchDestination),
      hasUpcomingTrip: getUpcomingTrip(options?.hasUpcomingTrip),
      mosaicStatus: getMosaicStatus(options?.mosaicStatus),
      travelBankBalance: getTravelBankBalance(options?.travelBankBalance),
      firstVisit: getIsFirstVisit(options?.firstVisit),
      lastVisitSixMonths: getIsLastVisitSixMonths(
        options?.isVisitAfterThreshold,
      ),
      trueBluePointsBalance: getPointsBalance(options?.trueBluePointsBalance),
    };

    const orderedKeys = PERSONALIZATION_DEFAULT_ORDER.split(',').map(item =>
      item.trim(),
    );
    const orderedResults = orderedKeys.map(key => personalizationMapping[key]);
    return orderedResults.join('&&');
  }

  // gets personalization values for header and footer
  getPersonalizationsHeaderFooter(
    currentLanguage: string,
    routeData: CmsTemplateRouteData,
  ) {
    const configSupportedLang =
      this.appConfig.getConfigValueFromLocalOrCMSSource(
        'i18n.i18nSupportedLanguages',
      );
    const supportedI18NNextLanguages =
      typeof configSupportedLang === 'string'
        ? configSupportedLang.split(',')
        : (configSupportedLang as unknown as string[]) || [];

    const useExperimentalTranslation =
      booleanStringCheck(
        this.appConfig.getConfigValueFromLocalOrCMSSource(
          'i18n.useExperimentalTranslation',
        ),
      ) && supportedI18NNextLanguages?.includes(currentLanguage);

    const i18nSupportedPersonalizationsOn = booleanStringCheck(
      this.appConfig.getConfigValueFromLocalOrCMSSource(
        'i18n.i18nSupportedPersonalizationsOn',
      ),
    );
    let personalizations = '';
    if (
      routeData &&
      (routeData.personalizationTrait || routeData.personalization === 'true')
    ) {
      const targetedOrigin = routeData.targetedOrigin?.code || null;
      const firstVisit = this.userVisitService.visitStatus();
      const isVisitAfterThreshold =
        this.userVisitService.isVisitAfterThreshold();

      const {
        personalization,
        recentSearch,
        searchDestination,
        hasUpcomingTrip,
        mosaicStatus,
        travelBankBalance,
        trueBluePointsBalance,
        loggedIn,
      } = routeData;

      personalizations = this.getPersonalizations(
        personalization,
        {
          targetedOrigin,
          recentSearch,
          searchDestination,
          hasUpcomingTrip,
          mosaicStatus,
          travelBankBalance,
          firstVisit,
          isVisitAfterThreshold,
          trueBluePointsBalance,
        },
        loggedIn,
      );
    }

    // copied from getTemplateData
    if (currentLanguage !== 'en' && !i18nSupportedPersonalizationsOn) {
      personalizations = '';
    }

    return {
      personalizations,
      useExperimentalTranslation,
    };
  }

  getCookie(cookie: string) {
    return this.cookieStorageService.getCookie(cookie);
  }

  // Get homepage pattern block response from cms.
  getHomePagePatternBlockCMS(): Observable<Image[]> {
    return this.httpService
      .get<any>(
        `${this.appConfig.magnoliaUrl}/.rest/jetblue/v4/${this.bgImagesURL}`,
        undefined,
        false,
        false,
      )
      .pipe(map(response => response['data']?.bgImages ?? []));
  }

  updateBackgroundIndex(images): number {
    return !!images && !!images.length
      ? this.generateRandomNumberInRange(0, images.length - 1)
      : 0;
  }

  /* Generates a random number inclusive of the provided min and max number */
  generateRandomNumberInRange(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getBookerData(bookerType: STANDALONE_BOOKERS): Observable<BookerResponse> {
    let bookerUrl;
    let abTest;
    const currentLanguage = this.languageService.getLanguage();
    const useExperimentalTranslation =
      booleanStringCheck(
        this.appConfig.getConfigValueFromLocalOrCMSSource(
          'i18n.useExperimentalTranslation',
        ),
      ) &&
      currentLanguage &&
      currentLanguage !== 'en';

    // eslint-disable-next-line default-case
    switch (bookerType) {
      case STANDALONE_BOOKERS.FLIGHTS:
        abTest = this.abtestExperienceGeneratorService.setAbTests(
          '/home/booker/flights',
        );
        bookerUrl = `component/search/bookAir/page/home/booker/flights/${
          abTest?.experience?.name || ''
        }`;
        break;
      case STANDALONE_BOOKERS.NGB:
        abTest =
          this.abtestExperienceGeneratorService.setAbTests('/home/booker/ngb');
        bookerUrl = `component/search/bookAir/page/home/booker/ngb/${
          abTest?.experience?.name || ''
        }`;
        break;
      case STANDALONE_BOOKERS.BFF:
        abTest =
          this.abtestExperienceGeneratorService.setAbTests('/home/booker/bff');
        bookerUrl = `component/search/bookAir/page/home/booker/bff/${
          abTest?.experience?.name || ''
        }`;
        break;
    }

    const translationPrefix = useExperimentalTranslation ? '/i18n-next' : '';
    const bookerUrlHasQueryParams = bookerUrl.includes('?');
    bookerUrl =
      currentLanguage && useExperimentalTranslation
        ? `${bookerUrl}${
            bookerUrlHasQueryParams ? '&' : '?'
          }translateLanguage=${currentLanguage}`
        : bookerUrl;

    let httpUrl = `${
      translationPrefix || this.appConfig.magnoliaUrl
    }/.rest/jetblue/v4/${bookerUrl}`;

    // This is done only for dev to run dev:booker
    if (isDevMode() && this.isWebComponent === 'booker') {
      httpUrl = `${this.appConfig.baseDomain}${this.appConfig.magnoliaUrl}/.rest/jetblue/v4/${bookerUrl}`;
    }

    return this.httpService.get<any>(httpUrl, undefined, false, false).pipe(
      map(response => {
        const booker = transformDynamicBookerData(response.data, 'bookerAir');
        return booker.bookerData;
      }),
    );
  }

  getBookerGlobalSettings() {
    const bookerUrl =
      'jetbluedotcom/customDelivery/globalFlightBookerSettings/global-booker-message';

    let httpUrl = `${this.appConfig.magnoliaUrl}/.rest/jetblue/v4/${bookerUrl}`;

    // This is done only for dev to run dev:booker
    if (isDevMode() && this.isWebComponent === 'booker') {
      httpUrl = `${this.appConfig.baseDomain}${this.appConfig.magnoliaUrl}/.rest/jetblue/v4/${bookerUrl}`;
    }

    return this.httpService.get<any>(httpUrl, undefined, false, false);
  }

  checkAbTestOverride(routeData, abTest: ABTestFullServedExperience) {
    if (
      routeData?.queryParams?.testname != null &&
      routeData?.queryParams?.testvalue != null &&
      abTest?.name === routeData?.queryParams?.testname
    ) {
      // override experience on abTest object sent on http request
      abTest.experience.name = routeData?.queryParams?.testvalue;
      // override the cookie
      this.cookieStorageService.setCookie(
        routeData.queryParams?.testname,
        routeData.queryParams?.testvalue,
      );
    }
  }
}
