import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JbButtonModule, JbIconModule } from 'jb-component-library';

import { CalendarModule } from '../calendar/calendar.module';
import { UIModule } from '../ui';
import { DialogTempModule } from './../dialog/dialog.module';
import { BestFareCalendarComponent } from './best-fare-calendar.component';
import { CalendarAggregatorModule } from './calendar-aggregator';
import {
  ProgressIndicatorBlockComponent,
  ProgressIndicatorComponent,
} from './progress-indicator';
import { TaxesFeesContentComponent } from './taxes-fees-content/taxes-fees-content.component';
import { WeekdaysBarComponent } from './weekdays-bar';

const JB_COMPONENT_LIBRARY_MODULES = [JbButtonModule, JbIconModule];

const MODULES = [
  CommonModule,
  CalendarAggregatorModule,
  CalendarModule,
  UIModule,
  DialogTempModule,
  ...JB_COMPONENT_LIBRARY_MODULES,
];

const DECLARATIONS = [
  BestFareCalendarComponent,
  ProgressIndicatorComponent,
  ProgressIndicatorBlockComponent,
  WeekdaysBarComponent,
  TaxesFeesContentComponent,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class BestFareCalendarModule {}
