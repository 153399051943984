import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImageLink } from '../types';

@Component({
  selector: 'jb-icon-link',
  templateUrl: './icon-link.component.html',
  styleUrls: ['./icon-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLinkComponent {
  @Input() link: ImageLink;
}
