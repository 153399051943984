<div
  class="flex flex-column flex-row-ns flex-wrap-ns mt2 mt3-ns mt4-l pb6 page-header-pb"
>
  <div class="jb-grid one-up grid-offset-l w-100 mb3 mb4-ns mh0-override">
    <h1 data-qaid="pageName" *ngIf="pageHeaderData.title" class="core-blue">
      {{ pageHeaderData.title }}
    </h1>
  </div>
  <div
    class="jb-grid w-100 gr-unset mh0-override"
    [ngClass]="{
      'one-up grid-offset-l':
        !(includeChapterSelector | jbStringToBoolean) || sectionsData.length < 4
    }"
  >
    <p
      *ngIf="!!pageHeaderData.content"
      class="klarheit charcoal body lh-copy"
      [ngClass]="
        (includeChapterSelector | jbStringToBoolean) && sectionsData.length >= 4
          ? 'pb5 pb0-ns mb0 col-8-m-left-temp'
          : 'mb0'
      "
    >
      {{ pageHeaderData.content }}
    </p>
    <jb-chapter-selector
      *ngIf="
        (includeChapterSelector | jbStringToBoolean) && sectionsData.length >= 4
      "
      class="relative pt5 pt0-ns col-4-m-right-temp bt bt-0-ns b--medium-gray"
    >
      <p  [ngClass]="chapterSelectorHeader ? 'mb2 b core-blue':'screen-reader-only'">{{ chapterSelectorHeader ? chapterSelectorHeader :'Contents' }}</p>
      <ul class="ma0 pl0 chapters-list royal-blue" #sidenavWrapper>
        <ng-container
          *ngFor="let section of sectionsData | slice: 0:8; let last = last"
        >
          <li class="body pv1 flex chapter-selector-link">
            <div *ngIf="last" class="last-vertical-line"></div>
            <a
              class="ttc royal-blue link chapter-selector-link"
              href="javascript:void(0)"
              routerLink="."
              queryParamsHandling="merge"
              [fragment]="section.data.title || section.title | dotStringToId"
            >
              {{
                section.data && section.data.title
                  ? section.data.title
                  : section.title
              }}
            </a>
          </li>
        </ng-container>
      </ul>
    </jb-chapter-selector>
  </div>
</div>
