import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, share, switchMap } from 'rxjs/operators';

import { HttpService } from '../../core/http';
import { ScheduleExtensionApi } from './api';
import {
  RequestExtensionDate,
  ScheduleExtensionActions,
} from './schedule-extension.actions';
import { ScheduleExtensionFacade } from './schedule-extension.facade';

@Injectable()
export class ScheduleExtensionEffects {
  requestExtensionDate$ = this.actions$.pipe(
    ofType<RequestExtensionDate>(
      ScheduleExtensionActions.REQUEST_EXTENSION_DATE,
    ),
    switchMap(() => this.scheduleExtensionApi.getExtensionDate()),
    share(),
  );

  constructor(
    private actions$: Actions,
    private scheduleExtensionApi: ScheduleExtensionApi,
    private scheduleExtensionFacade: ScheduleExtensionFacade,
  ) {}

  requestExtensionDateSuccess$ = createEffect(() =>
    this.requestExtensionDate$.pipe(
      filter(HttpService.isSuccessful),
      map(extensionDate =>
        this.scheduleExtensionFacade.loadExtensionDate(extensionDate),
      ),
    ),
  );
}
