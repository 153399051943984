<div class="jb-grid one-up grid-offset-l mb2 mb3-ns">
  <div class="flight-block-skeleton flex flex-column flex-row-ns ba b--light-gray br-rounded-4 bn-ns">
    <div class="flight-block-skeleton_image bg-off-white w-100-s"></div>
    <div class="flight-block-skeleton_content pt3 ph4 flex flex-column flex-row-ns justify-between-ns relative bt-ns br-ns bb-ns b--light-gray bg-white">
      <div class="w-100-s w-80-ns">
        <dot-text-block bgColor="#f6f6f6" radius="6" class="text-block-1"></dot-text-block>
        <dot-text-block bgColor="#f6f6f6" radius="6" class="text-block-2" ></dot-text-block>
      </div>
      <div class="w-100-s w-20-ns flex flex-column items-end-ns">
        <dot-text-block bgColor="#f6f6f6" radius="6" class="text-block-3"></dot-text-block>
        <dot-text-block bgColor="#f6f6f6" radius="6" class="text-block-4"></dot-text-block>
        <dot-text-block bgColor="#f6f6f6" radius="6" class="text-block-5"></dot-text-block>
      </div>
      <dot-text-block bgColor="#f6f6f6" radius="6" class="legal-link absolute"></dot-text-block>
    </div>
  </div>
</div>
