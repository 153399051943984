import { Injectable } from '@angular/core';
import { CONFIG_X_AUTH_TOKEN } from '@core/app-config/types/mocks';
import { HttpService, LocalStorageService } from '@core/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, of } from 'rxjs';

import { CartApi } from './api/cart.api';
import { CartActions, FetchCartFailure } from './cart.actions';
import { CartFacade } from './cart.facade';

@Injectable()
export class CartEffects {
  constructor(
    private actions$: Actions,
    private cartApi: CartApi,
    private cartFacade: CartFacade,
    private localStorageService: LocalStorageService,
  ) {}

  loadCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.FETCH_CART_REQUESTED),
        mergeMap(({ cartJwtToken, xAuthToken }) =>
          this.cartApi.getCart(cartJwtToken, xAuthToken).pipe(
            filter(HttpService.isSuccessful),
            map(response => {
              // Check if xAuthToken is stored in our LocalStorage
              const xAuthToken: string =
                this.localStorageService.getItem(CONFIG_X_AUTH_TOKEN);
              /*
                If the xAuthToken was not provided.
                Get the xAuthToken from this call and store it in our LocalStorage.
                This condition will fall when we are fetching the cart as a guest.
              */
              if (!xAuthToken) {
                this.localStorageService.setItem(
                  CONFIG_X_AUTH_TOKEN,
                  response.headers.get(CONFIG_X_AUTH_TOKEN),
                );
              }

              // Finish the cart flow dispatching success.
              this.cartFacade.dispatchFetchCartSuccess(response.body.carts);
            }),
            catchError(err => of(new FetchCartFailure(err))),
          ),
        ),
      ),
    { dispatch: false },
  );
}
