<div
  class="booker br3 mb5 bg-off-white pa5 flex flex-column flex-row-ns flex-wrap-ns justify-between-ns items-center"
  [ngClass]="textDark ? 'charcoal' : ''"
>
  <div
    [ngClass]="!!displayContent?.button ? 'w-60-ns' : 'w-100-ns'"
    class="items-center body lh-copy tc tl-ns mv3-ns mb3 mt5"
  >
    <jb-body-text [content]="[displayContent]"></jb-body-text>
  </div>
  <a
    jbButton
    *ngIf="!!displayContent?.button"
    [jbSitemapLinker]="displayContent?.button.href"
    [target]="displayContent?.button.newWindow"
    [dotLinkIcon]="displayContent?.button.newWindow"
    rel="noopener"
    theme="ctaPrimary"
    [isPaired]="false"
    class="mv3 tracked pa0"
    >{{ displayContent?.button.title }}</a
  >
</div>
