import { Injectable } from '@angular/core';
import { FlightTrackerService } from '@core/flight-tracker/flight-tracker.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, share, skip, switchMap, takeUntil, tap, timer } from 'rxjs';

import {
  FlightTrackerActions,
  RequestFlightStatusByNumber,
  RequestFlightStatusByNumberPoll,
  RequestFlightStatusByRoute,
  SetFlightStatus,
} from './flight-tracker.actions';
import { FlightTrackerFacade } from './flight-tracker.facade';

@Injectable()
export class FlightTrackerEffects {
  constructor(
    private actions$: Actions,
    private flightTrackerService: FlightTrackerService,
    private flightTrackerFacade: FlightTrackerFacade,
  ) {}

  getFlightStatusByNumberPoll$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestFlightStatusByNumberPoll>(
        FlightTrackerActions.REQUEST_FLIGHT_STATUS_BY_NUMBER_POLL,
      ),
      map(action => action.payload),
      switchMap(({ flightDate, flightNumber, pollingInterval }) => {
        // Polling interval in ms
        return timer(1, pollingInterval).pipe(
          switchMap(() => {
            this.flightTrackerFacade.formSubmitting(true);
            return this.flightTrackerService.getFlightStatusByFlight(
              flightDate,
              flightNumber,
            );
          }),
          map(flightStatus => ({
            type: FlightTrackerActions.SET_FLIGHT_STATUS,
            payload: {
              ...flightStatus,
            },
          })),
          share(),
          tap(() => this.flightTrackerFacade.formSubmitting(false)),
          takeUntil(this.flightTrackerService.stopPolling),
        );
      }),
    ),
  );

  getFlightStatusByNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestFlightStatusByNumber>(
        FlightTrackerActions.REQUEST_FLIGHT_STATUS_BY_NUMBER,
      ),
      map(action => action.payload),
      switchMap(({ flightDate, flightNumber }) => {
        this.flightTrackerFacade.formSubmitting(true);
        return this.flightTrackerService.getFlightStatusByFlight(
          flightDate,
          flightNumber,
        );
      }),
      map(flightStatus => ({
        type: FlightTrackerActions.SET_FLIGHT_STATUS,
        payload: {
          ...flightStatus,
        },
      })),
    ),
  );

  getFlightStatusByRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestFlightStatusByRoute>(
        FlightTrackerActions.REQUEST_FLIGHT_STATUS_BY_ROUTE,
      ),
      map(action => action.payload),
      switchMap(({ departureDate, originCode, destinationCode }) => {
        return this.flightTrackerService.getFlightStatusByRoute(
          departureDate,
          originCode,
          destinationCode,
        );
      }),
      map(flightStatus => ({
        type: FlightTrackerActions.SET_FLIGHT_STATUS,
        payload: {
          ...flightStatus,
        },
      })),
    ),
  );

  setFlightStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetFlightStatus>(FlightTrackerActions.SET_FLIGHT_STATUS),
      map(action => action.payload),
      skip(1),
      tap(data => {
        // Stop polling when user switches tabs on flight tracker
        if (data === undefined) {
          this.flightTrackerService.stopPolling.next(true);
        }
      }),
      map(() => ({
        type: FlightTrackerActions.FORM_SUBMITTING,
        payload: false,
      })),
    ),
  );
}
