import { Action } from '../shared/types/action.interface';
import { ResetPasswordErrorState, ResetPasswordRequestedState } from './types';

export enum ForgotPasswordActions {
  PASSWORD_RESET_LINK_FAILURE = '[Email Entry Page] Request Password Reset Link FAILURE',
  PASSWORD_RESET_LINK_REQUEST = '[Email Entry Page] Request Password Reset Link via email',
  PASSWORD_RESET_LINK_SUCCESS = '[Email Entry Page] Password link sent successfully',
  RESET_PASSWORD_FAILED = '[Password Changed By Reset] Reset Password Failed',
  RESET_PASSWORD_REQUESTED = '[Password Changed By Reset] Reset Password Requested',
  RESET_PASSWORD_SUCCEEDED = '[Password Changed By Reset] Reset Password Succeeded',
  SET_EMAIL_FROM_SIGN_IN_PAGE = '[Sign In Page Email] Set Email from Sign In Page',
  FORGOT_PASSWORD_PAGE_DESTROYED = '[Forgot password] Page destroyed',
}

export class PasswordResetLinkRequested implements Action {
  readonly type = ForgotPasswordActions.PASSWORD_RESET_LINK_REQUEST;
  constructor(public payload: string) {}
}
export class PasswordLinkRequestSucceeded implements Action {
  readonly type = ForgotPasswordActions.PASSWORD_RESET_LINK_SUCCESS;
  constructor(public traceId: string) {}
}

export class RequestPasswordResetLinkFailure implements Action {
  readonly type = ForgotPasswordActions.PASSWORD_RESET_LINK_FAILURE;
  // will be based on sever response interface
  constructor(
    public payload: any,
    public traceId: string,
  ) {}
}

export class ResetPasswordRequested implements Action {
  readonly type = ForgotPasswordActions.RESET_PASSWORD_REQUESTED;
  constructor(public payload: ResetPasswordRequestedState) {}
}

export class ResetPasswordRequestFailed implements Action {
  readonly type = ForgotPasswordActions.RESET_PASSWORD_FAILED;

  constructor(
    public payload: ResetPasswordErrorState,
    public traceId: string,
  ) {}
}

export class ResetPasswordRequestSucceeded implements Action {
  readonly type = ForgotPasswordActions.RESET_PASSWORD_SUCCEEDED;
  constructor(public traceId: string) {}
}

export class SetEmailFromSignInPage implements Action {
  readonly type = ForgotPasswordActions.SET_EMAIL_FROM_SIGN_IN_PAGE;
  constructor(public payload: string) {}
}

export class ForgotPasswordPageDestroyed implements Action {
  readonly type = ForgotPasswordActions.FORGOT_PASSWORD_PAGE_DESTROYED;
  constructor() {}
}

export type ForgotPasswordActionsUnion =
  | PasswordLinkRequestSucceeded
  | PasswordResetLinkRequested
  | RequestPasswordResetLinkFailure
  | ResetPasswordRequestFailed
  | ResetPasswordRequestSucceeded
  | ResetPasswordRequested
  | SetEmailFromSignInPage
  | ForgotPasswordPageDestroyed;
