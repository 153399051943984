import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PatternBlockComponent } from './pattern-block.component';

const MODULES = [CommonModule];

const DECLARATIONS = [PatternBlockComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class PatternBlockModule {}
