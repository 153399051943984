import { Injectable } from '@angular/core';

interface Component {
  name: string;
  data: any;
}

@Injectable({ providedIn: 'root' })
// TODO this whole service can be just util-functions
export class ComponentRegistryService {
  buildTabComponentRegistry(components: Component[]): {
    [key: string]: Object;
  } {
    return components.reduce((componentRegistry, component) => {
      componentRegistry[component.name] = component.data;
      return componentRegistry;
    }, {});
  }

  buildRegistry(area: { [key: string]: { component: any[] } }): {
    [key: string]: Object;
  } {
    return Object.keys(area).reduce((allComponents, key) => {
      const areaComponents = area[key].component || [];
      return {
        ...allComponents,
        ...areaComponents.reduce(
          (all, component) => ({
            ...all,
            [component.name]: component.data,
          }),
          {},
        ),
      };
    }, {});
  }

  buildSectionHeaderRegistry(data): { [key: string]: Object } {
    return data.reduce((allSections, item) => {
      // TODO: Remove this when all templates are dynamic and consistent
      if (!!item.data) {
        const areaSections = item.data['section'] || [];
        return {
          ...allSections,
          ...areaSections.reduce(
            (all, section) => ({
              ...all,
              [section.name]: section.data,
            }),
            {},
          ),
        };
      } else {
        return allSections;
      }
    }, {});
  }
}
