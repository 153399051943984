import { Action } from '../shared/types/action.interface';
import {
  CreditCardRetrievalRequestState,
  CreditCardRetrievalResponseState,
  PersonalInfoState,
  TravelPreferencesState,
} from './types';

export enum PreferenceCenterActions {
  GET_MEMBER_DATA = '[Preference Center - Personal Info] Get Member Data',
  STORE_MEMBER_DATA = '[Preference Center - Personal Info] Store Member Data',
  GET_PROFILE_PICTURE = '[Preference Center - Personal Info] Get Profile Picture',
  STORE_PROFILE_PICTURE = '[Preference Center - Personal Info] Store Profile Picture',
  GET_MEMBER_PREFERENCES = '[Preference Center - Travel] Get Member Preferences',
  STORE_MEMBER_PREFERENCES = '[Preference Center - Travel] Store Member Preferences',
  SAVE_MEMBER_DATA = '[Preference Center - Personal Info] Save Member Data',
  UPDATE_STORE_MEMBER_DATA = '[Preference Center - Personal Info] Update Store Member Data',
  SAVE_MEMBER_PREFERENCES = '[Preference Center - Travel] Save Member Preferences',
  UPDATE_STORE_MEMBER_PREFERENCES = '[Preference Center - Travel] Update Store Member Preferences',
  GET_FUTURE_RESERVATION_STATUS = '[Preference Center] Get Future Reservation Status',
  STORE_FUTURE_RESERVATION_STATUSE = '[Preference Center] Store Future Reservation Status',
  MEMBER_DATA_ERROR_STATE_RESET = '[Preference Center - Personal Info] Member Data Error State Reset',
  MEMBER_PREFERENCES_ERROR_STATE_RESET = '[Preference Center - Personal Info] Member Preferences Error State Reset',
  GET_MEMBER_PAYMENT = '[Preference Center - Payment] Get Member Payment',
  STORE_MEMBER_PAYMENT = '[Preference Center - Payment] Store Member Payment',
  SAVE_PAYMENTS_INFO = '[Preference Center - Payments] Save Payments Info',
  UPDATE_STORE_PAYMENTS_INFO = '[Preference Center - Payments] Update Store Payments Info',
  PAYMENT_INFO_ERROR_STATE_RESET = '[Preference Center - Payment Info] Payment Info Error State Reset',
  PAYMENT_INFO_STORE_ERROR_STATE_RESET = '[Preference Center - Payment Info] Payment Info Store Error State Reset',
  SAVE_PROFILE_PICTURE = '[Preference Center - Personal Info] Save Profile Picture',
  UPDATE_STORE_PROFILE_PICTURE = '[Preference Center - Profile Picture] Update Profile Picture',
  PROFILE_PICTURE_ERROR_STATE_RESET = '[Preference Center - Profile Picture] Profile Picture Error State Reset',
  DELETE_STORE_PROFILE_PICTURE = '[Preference Center - Personal Info] Delete Profile Picture]',
  UPDATE_DELETE_STORE_PROFILE_PICTURE = '[Preference Center - Profile Picture] Update Delete Profile Picture',
  DELETE_STORE_PROFILE_PICTURE_ERROR_STATE_RESET = '[Preference Center - Personal Info] Delete Profile Picture Error State Reset]',
}

export class GetMemberData implements Action {
  readonly type = PreferenceCenterActions.GET_MEMBER_DATA;
}

export class StoreMemberData implements Action {
  readonly type = PreferenceCenterActions.STORE_MEMBER_DATA;
  constructor(public payload: PersonalInfoState) {}
}

export class GetProfilePicture implements Action {
  readonly type = PreferenceCenterActions.GET_PROFILE_PICTURE;
}

export class StoreProfilePicture implements Action {
  readonly type = PreferenceCenterActions.STORE_PROFILE_PICTURE;
  constructor(public payload: Blob) {}
}

export class SaveMemberData implements Action {
  readonly type = PreferenceCenterActions.SAVE_MEMBER_DATA;
  constructor(public payload: PersonalInfoState) {}
}

export class UpdateStoreMemberData implements Action {
  readonly type = PreferenceCenterActions.UPDATE_STORE_MEMBER_DATA;
  constructor(public payload: PersonalInfoState) {}
}

export class GetMemberPreferences implements Action {
  readonly type = PreferenceCenterActions.GET_MEMBER_PREFERENCES;
}

export class StoreMemberPreferences implements Action {
  readonly type = PreferenceCenterActions.STORE_MEMBER_PREFERENCES;
  constructor(public payload: TravelPreferencesState) {}
}

export class SaveMemberPreferences implements Action {
  readonly type = PreferenceCenterActions.SAVE_MEMBER_PREFERENCES;
  constructor(public payload: TravelPreferencesState) {}
}

export class UpdateStoreMemberPreferences implements Action {
  readonly type = PreferenceCenterActions.UPDATE_STORE_MEMBER_PREFERENCES;
  constructor(public payload: TravelPreferencesState) {}
}

export class GetFutureReservationStatus implements Action {
  readonly type = PreferenceCenterActions.GET_FUTURE_RESERVATION_STATUS;
}

export class StoreFutureReservationStatus implements Action {
  readonly type = PreferenceCenterActions.STORE_FUTURE_RESERVATION_STATUSE;
  constructor(public payload: Boolean) {}
}

export class MemberDataErrorReset implements Action {
  readonly type = PreferenceCenterActions.MEMBER_DATA_ERROR_STATE_RESET;
}

export class MemberPreferencesErrorReset implements Action {
  readonly type = PreferenceCenterActions.MEMBER_PREFERENCES_ERROR_STATE_RESET;
}

export class GetMemberPayment implements Action {
  readonly type = PreferenceCenterActions.GET_MEMBER_PAYMENT;
  constructor(public payload: CreditCardRetrievalRequestState) {}
}

export class StoreMemberPayment implements Action {
  readonly type = PreferenceCenterActions.STORE_MEMBER_PAYMENT;
  constructor(public payload: CreditCardRetrievalResponseState) {}
}

export class SavePaymentsInfo implements Action {
  readonly type = PreferenceCenterActions.SAVE_PAYMENTS_INFO;
  constructor(public payload: Object) {}
}

export class UpdateStorePaymentsInfo implements Action {
  readonly type = PreferenceCenterActions.UPDATE_STORE_PAYMENTS_INFO;
  constructor(public payload: Object) {}
}

export class PaymentInfoErrorReset implements Action {
  readonly type = PreferenceCenterActions.PAYMENT_INFO_ERROR_STATE_RESET;
}

export class PaymentInfoStoreErrorReset implements Action {
  readonly type = PreferenceCenterActions.PAYMENT_INFO_STORE_ERROR_STATE_RESET;
}

export class SaveProfilePicture implements Action {
  readonly type = PreferenceCenterActions.SAVE_PROFILE_PICTURE;
  constructor(public payload: Object) {}
}

export class UpdateStoreProfilePicture implements Action {
  readonly type = PreferenceCenterActions.UPDATE_STORE_PROFILE_PICTURE;
  constructor(public payload: Object) {}
}
export class ProfilePictureErrorStateReset implements Action {
  readonly type = PreferenceCenterActions.PROFILE_PICTURE_ERROR_STATE_RESET;
}
export class DeleteProfilePicture implements Action {
  readonly type = PreferenceCenterActions.DELETE_STORE_PROFILE_PICTURE;
}
export class UpdateDeleteStoreProfilePicture implements Action {
  readonly type = PreferenceCenterActions.UPDATE_DELETE_STORE_PROFILE_PICTURE;
  constructor(public payload: Object) {}
}
export class DeleteProfilePictureErrorStateReset implements Action {
  readonly type =
    PreferenceCenterActions.DELETE_STORE_PROFILE_PICTURE_ERROR_STATE_RESET;
}

export type PreferenceCenterActionsUnion =
  | GetMemberData
  | StoreMemberData
  | GetProfilePicture
  | StoreProfilePicture
  | GetMemberPreferences
  | StoreMemberPreferences
  | SaveMemberData
  | UpdateStoreMemberData
  | SaveMemberPreferences
  | UpdateStoreMemberPreferences
  | GetFutureReservationStatus
  | StoreFutureReservationStatus
  | MemberDataErrorReset
  | MemberPreferencesErrorReset
  | GetMemberPayment
  | StoreMemberPayment
  | SavePaymentsInfo
  | UpdateStorePaymentsInfo
  | PaymentInfoErrorReset
  | SaveProfilePicture
  | UpdateStoreProfilePicture
  | ProfilePictureErrorStateReset
  | DeleteProfilePicture
  | UpdateDeleteStoreProfilePicture
  | DeleteProfilePictureErrorStateReset
  | PaymentInfoStoreErrorReset;
