import { Pipe, PipeTransform } from '@angular/core';
import { FlightDeal } from '@shared/types';
import { difference } from 'ramda';

@Pipe({
  name: 'DealLegalTextFormatter',
})
export class DealLegalTextFormatterPipe implements PipeTransform {
  // todo: might be better to have a subset type instead of FlightDeal of just the properties that are relevant
  transform(value: FlightDeal, baseFormat: string): string {
    // title isn't actually relevant, todo: remove it from the base function requirements
    return value
      ? this.formatLegalCopy(value, { content: baseFormat, title: '' })
      : '';
  }

  private formatLegalCopy = (
    flightDeal: FlightDeal,
    legalCopy: { content: string; title: string },
  ): string => {
    let noFlightsText = '';
    const noFlights = difference(
      [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      flightDeal.daysOfWeek ? flightDeal.daysOfWeek : [],
    );
    if (noFlights.length === 0) {
      noFlightsText = '';
    } else if (noFlights.length === 1) {
      noFlightsText = `No flights on ${noFlights}.`;
    } else {
      const lastItemOfNoFlight = noFlights.pop();
      noFlightsText = `No flights on ${noFlights.join(
        ', ',
      )} and ${lastItemOfNoFlight}.`;
    }

    return this.setDynamicLegalCopyData(
      { startDate: flightDeal.startDate, endDate: flightDeal.endDate },
      legalCopy,
      noFlightsText,
      {
        origin: flightDeal.originAirportCode
          ? flightDeal.originAirportCode
          : '',
        destination: flightDeal.destinationAirportCode
          ? flightDeal.destinationAirportCode
          : '',
      },
      {
        from: flightDeal.from ? flightDeal.from : '',
        to: flightDeal.to ? flightDeal.to : '',
      },
    );
  };

  private setDynamicLegalCopyData(
    promoDates: { startDate: string; endDate: string },
    dynamicLegalCopy: { content: string; title: string },
    noFlightsText = '',
    originAndDestinationCodes: { origin: string; destination: string },
    originAndDestination: { from: string; to: string },
  ): string {
    return dynamicLegalCopy.content
      .replace('[START_DATE]', promoDates.startDate)
      .replace('[END_DATE]', promoDates.endDate)
      .replace('[DAYS_OF_WEEK]', noFlightsText)
      .replace('[ORIGIN_CODE]', originAndDestinationCodes.origin)
      .replace('[DESTINATION_CODE]', originAndDestinationCodes.destination)
      .replace('[FROM]', originAndDestination.from)
      .replace('[TO]', originAndDestination.to);
  }
}
