import { ExperimentalTranslationEffect } from '@feature/ngx-translation/experimental-translation.effect';
import { BookerEffects } from '@store/booker/booker.effects';

import { AnalyticsEffects } from './analytics/analytics.effects';
import { AuthEffects } from './auth/auth.effects';
import { CartEffects } from './cart/cart.effects';
import { DealsEffects } from './deals/deals.effects';
import { FlightTrackerEffects } from './flight-tracker/flight-tracker.effects';
import { ForgotPasswordEffect } from './forgot-password/forgot-password.effects';
import { GlobalEffects } from './global/global.effects';
import { GlobalEffectsFooter } from './global/partials/global.effects.footer';
import { ItineraryEffects } from './itinerary/itinerary.effects';
import { MfaEffects } from './mfa/mfa.effects';
import { OriginsEffects } from './origins/origins.effects';
import { PreferenceCenterEffects } from './preference-center/preference-center.effects';
import { ProfileEffects } from './profile/profile.effects';
import { RequestAReceiptEffects } from './request-a-receipt/request-a-receipt.effects';
import { RouterEffects } from './router/router.effects';
import { ScheduleExtensionEffects } from './schedule-extension/schedule-extension.effects';

export const ROOT_EFFECTS = [
  AnalyticsEffects,
  AuthEffects,
  BookerEffects,
  CartEffects,
  DealsEffects,
  ExperimentalTranslationEffect,
  FlightTrackerEffects,
  ForgotPasswordEffect,
  GlobalEffects,
  GlobalEffectsFooter,
  ItineraryEffects,
  MfaEffects,
  OriginsEffects,
  PreferenceCenterEffects,
  ProfileEffects,
  RequestAReceiptEffects,
  RouterEffects,
  ScheduleExtensionEffects,
];
