import { Pipe, PipeTransform } from '@angular/core';
import { WindowSize } from '@core/index';

@Pipe({
  name: 'jbPointsPipe',
})
export class DynamicPointsPipe implements PipeTransform {
  transform(
    points: number,
    screenSize: WindowSize = { large: true },
    toFixed: number = 1,
  ): string {
    const unitMapping = !!screenSize.small ? 1000 : 1;
    const pointsOnScreen = points / unitMapping;
    if (!!screenSize.small) {
      pointsOnScreen.toFixed(toFixed).match(/0$/)
        ? pointsOnScreen.toFixed(0)
        : pointsOnScreen.toFixed(toFixed);
    }
    return pointsOnScreen.toLocaleString();
  }
}
