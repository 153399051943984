import { WindowSize } from '@core/window-actions/types/window-size.type';
import { DynamicLabel } from '@shared/types';

/**
 * Returns Dynamic Label for each screen sizes.
 * @param {DynamicLabel} labelContainer the labelContainer with its values
 * @param  {WindowSize} screenSize the window screen size
 * @returns {string} the Label value
 */
export function getDynamicLabel(
  labelContainer: DynamicLabel,
  screenSize: WindowSize = { large: true },
): string {
  if (!!screenSize.medium && !!labelContainer.tablet) {
    return labelContainer.tablet;
  } else if (!!screenSize.small && !!labelContainer.mobile) {
    return labelContainer.mobile;
  }

  return labelContainer.default;
}
