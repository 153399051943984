import { remap, removeEmptyValues } from '../utils';
import { analyticsDataMap } from './analytics-data-map.consts';
import { AnalyticsDataLayerValues, AnalyticsValues } from './types';

/**
 * Map application analytics values into an object with the appropriate
 * Analytics DataLayer keys.
 */
export function mapDataLayerVariables(
  values?: AnalyticsValues,
): AnalyticsDataLayerValues {
  if (!values) {
    return {};
  }
  const mappedValues = removeEmptyValues(
    remap<AnalyticsDataLayerValues>(analyticsDataMap, values),
  );

  return mappedValues;
}
