<ng-container *ngIf="dealLegalLinks$ | async as dealLegalLinks">
  <jb-dialog dialogType="content" *ngIf="formattedLegalCopy">
    <jb-dialog-header>{{ legalCopy.title }}</jb-dialog-header>
    <jb-dialog-content>
      <div class="klarheit body" [innerHtml]="formattedLegalCopy"></div>
    </jb-dialog-content>
  </jb-dialog>

  <jb-dialog dialogType="content" *ngIf="!legalCopy">
    <jb-dialog-header>JetBlue Fare Terms and Conditions</jb-dialog-header>
    <jb-dialog-content>
      <b
        >This fare is available on select dates between
        {{ dealLegalData.startDate }}</b
      >
      and <b> {{ dealLegalData.endDate }}. {{ noFlightsText }}</b> Fare may not
      be available during holidays or other peak travel days. Click on the fare
      to see a fare calendar showing the exact dates this fare is available. Any
      "Price Drop" that is shown is based on lowest bookable fare within the
      last 72 hours. Fare is subject to availability; may not be available on
      all flights; may change without notice; must be purchased at time of
      reservation; and is one-way, nonstop, nonrefundable and nontransferable.
      Reservations made one week or more prior to a flight’s scheduled departure
      may be canceled without penalty up to 24 hours after the reservation is
      made. Fare includes government taxes and fees. Fare shown refers to the
      BLUE fare which does not include a checked bag. Fare does not include fees
      for oversized/overweight/extra baggage or other fees for products/services
      sold separately.
      <a
        tabindex="0"
        [jbSitemapLinker]="dealLegalLinks.baggageFeesLink"
        target="_blank"
        class="underline hover-core-blue royal-blue"
        >See baggage fees and other optional services and fees.</a
      >
      Cancellations/changes must be made prior to scheduled departure (otherwise
      all money for fare is forfeited); change/cancel fee per person will apply
      (fees vary by fare option selected; for BLUE fares the fee is $75 (for
      fares up to $100) or $100 (for fares between $100 and $149.99) or $150
      (for fares of $150 or more). For changes for all fare options, any
      difference in fare between new fare and existing one will apply.
      Cancellations receive JetBlue travel credit, valid for one year. Proper
      documentation required for boarding.
      <a
        tabindex="0"
        [jbSitemapLinker]="dealLegalLinks.restrictionsApplyLink"
        target="_blank"
        class="underline hover-core-blue royal-blue"
        >Other restrictions apply.</a
      >
    </jb-dialog-content>
  </jb-dialog>
</ng-container>
