import { Inject, Injectable } from '@angular/core';
import { SITEMAP_DICTIONARY } from '@shared/ui/links/linkers/sitemap-dictionary.token';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { StoreService } from '../shared/store.service';
import * as RouterSelectors from './router.selectors';

@Injectable({ providedIn: 'root' })
export class RouterFacade {
  router = this.storeService.getState(RouterSelectors.router);
  currentRoute = this.storeService.getState(RouterSelectors.currentRoute);
  queryParams = this.storeService.getState(RouterSelectors.selectQueryParams);
  queryParamsUpperCase = this.storeService.getState(
    RouterSelectors.selectQueryParamsUpperCase,
  );
  validQueryParamsUpperCase = this.storeService
    .getState(RouterSelectors.selectValidQueryParamsUpperCase)
    .pipe(map(paramMap => paramMap || {}));
  routeParams = this.storeService.getState(RouterSelectors.selectRouteParams);
  routeData = this.storeService.getState(RouterSelectors.selectRouteData);
  urlWithQueryParams = this.storeService.getState(
    RouterSelectors.selectUrlWithQueryParams,
  );
  url = this.storeService.getState(RouterSelectors.selectUrl);

  isHomePage = this.storeService.getState(RouterSelectors.isHomePage);
  isSignInPage = this.storeService.getState(RouterSelectors.isSignInPage);
  isLogOutPage = this.storeService.getState(RouterSelectors.isLogOutPage);
  isBffPage = this.storeService.getState(RouterSelectors.isBffPage);
  isMaintenancePage = this.storeService.getState(
    RouterSelectors.isMaintenancePage,
  );
  currentPathTemplate = this.storeService
    .getState(RouterSelectors.selectRouteDataPath)
    .pipe(
      distinctUntilChanged(),
      map(routePath =>
        routePath ? this.sitemapDictionary[routePath] || undefined : undefined,
      ),
    );

  isPreferenceCenterPage = this.storeService.getState(
    RouterSelectors.isPreferenceCenterPage,
  );

  isForgotPasswordPage = this.storeService.getState(
    RouterSelectors.isForgotPasswordPage,
  );

  isAddTripEmailPage = this.storeService.getState(
    RouterSelectors.isAddTripEmailPage,
  );

  constructor(
    private storeService: StoreService,
    @Inject(SITEMAP_DICTIONARY) private sitemapDictionary,
  ) {}

  queryParam = paramKey =>
    this.storeService.getState(RouterSelectors.selectQueryParam(paramKey));
}
