import { WindowSize } from '../../../core';
import { BffForm } from '../../../feature/bff/bff.component-store.types';
import { ProgressIndicatorContent } from './types';

export interface ProgressIndicatorState extends BffForm {
  maxSeats: number;
  windowSize: WindowSize;
  content: ProgressIndicatorContent;
  isOneWay: boolean;
}

export const progressIndicatorInitialState: ProgressIndicatorState = {
  bookWithPoints: false,
  content: undefined,
  departBff: undefined,
  cityPair: undefined,
  maxSeats: 0,
  travelers: undefined,
  returnBff: undefined,
  tripType: undefined,
  windowSize: undefined,
  isOneWay: undefined,
};

export interface ProgressIndicatorViewModel extends ProgressIndicatorState {
  isDepartBffFocusable: boolean;
  isReturnBffFocusable: boolean;
}
