import { getDate, getMonth, getYear } from 'date-fns';
import { zipObj } from 'ramda';

import { convertToDate } from '../utils/date.utils';
import { BestFare } from './types/best-fare.type';

export function groupByDate(days: any[]) {
  const keys: string[] = days.map(day => getDateKey(day.date));
  return zipObj(keys, days);
}

export function getDateKey(date: Date) {
  const dateValue = convertToDate(date);
  return dateValue
    ? `${getMonth(dateValue)}-${getDate(dateValue)}-${getYear(dateValue)}`
    : '';
}

export function applyLowestFares(fares: BestFare[]): BestFare[] {
  const lowestFare = Math.min.apply(
    null,
    fares.filter(fare => !!fare.amount).map(fare => fare.amount),
  );
  return fares.map(fare => ({
    ...fare,
    isLowestFare: fare.amount === lowestFare,
  }));
}
