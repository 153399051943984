import { NgModule } from '@angular/core';
import { DotOktaContainerComponent } from '@shared/dot-okta-container';
import { JbButtonModule, JbIconModule } from 'jb-component-library';

import { JBLogoModule } from '../shared/jb-logo';
import { SKELETON_COMPONENTS } from './components';
import { SKELETON_TEMPLATES } from './templates';

const MODULES = [
  JBLogoModule,
  JbIconModule,
  JbButtonModule,
  DotOktaContainerComponent,
];

const DECLARATIONS = [...SKELETON_COMPONENTS, ...SKELETON_TEMPLATES];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class SkeletonModule {}
