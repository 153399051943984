import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import {
  JbIconModule,
  JbImageModule,
  JbLinkModule,
} from 'jb-component-library';

import { JBMessageBlockComponent } from './jb-message-block.component';

const MODULES = [
  CommonModule,
  RouterModule,
  JbLinkModule,
  JbIconModule,
  JbImageModule,
  LinkIconModule,
];

const DECLARATIONS = [JBMessageBlockComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class JBMessageBlockModule {}
