import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchBoxContainerModule } from '@dynamic-components/search-box-container/search-box-container.module';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import { LinksModule } from '@shared/ui/links';
import {
  JbExpansionPanelModule,
  JbIconModule,
  JbSelectModule,
} from 'jb-component-library';

import { FooterComponent } from './footer.component';

const MODULES = [
  CommonModule,
  SearchBoxContainerModule,
  JbIconModule,
  LinksModule,
  JbSelectModule,
  JbExpansionPanelModule,
  LinkIconModule,
];

const DECLARATIONS = [FooterComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class FooterModule {}
