<ng-container *ngIf="showAlert && travelAlert?.enabled === 'true'">
  <jb-travel-alert
    (closeAlert)="toggle()"
    class="klarheit travel-alert"
    data-qaid="travelAlert"
    [theme]="travelAlert.theme"
  >
    <jb-inner-html jbSmartLink [theme]="travelAlert.theme">
      <div jbSitemapNestedLinker [innerHTML]="travelAlert.content" class="innerTravelAlert"></div>
    </jb-inner-html>
  </jb-travel-alert>
</ng-container>
