import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { DialogDebugComponent } from '@shared/dialog/dialog-debug/dialog-debug.component';
import { JbDialogService } from 'jb-component-library';

/**
 * @description Component for debugging and editing content for debugging when appconfig
 * ui debugging is turned on
 * @WhereIsItUsed No where specifically, html attached to for debugging
 */
@Component({
  selector: 'div[dot-debug-content]', // eslint-disable-line
  templateUrl: './debug-content.component.html',
  styleUrls: ['./debug-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DOTDebugContentComponent {
  @Input('dot-debug-content') dotDebugContent: any;

  constructor(
    public appConfigService: AppConfigService,
    private dialogService: JbDialogService,
  ) {}

  onEditClick(event: any) {
    const componentRef: DialogDebugComponent = this.dialogService.openDialog(
      DialogDebugComponent,
      event.target,
    );
    componentRef.handleData(this.dotDebugContent);
  }
}
