<div class="menu-shadow bg-white br2 h-100" *ngIf="content && content?.link">
  <a
    [jbSitemapLinker]="(content?.link)[0]?.href"
    class="flex cover bg-center h5 relative"
    [target]="(content?.link)[0]?.newWindow"
  >
    <jb-image
      *ngIf="
        content?.image && content?.image?.length && (content?.image)[0]?.src;
        else background
      "
      [src]="(content?.image)[0]?.src | dynamicImageWidthPipe: 'image'"
      [alt]="(content?.image)[0]?.alt"
      [isLazy]="enableLazyLoadImage"
      class="br--top br2 self-center center brand-img background-img-cover"
      data-pin-no-hover="true"
      nopin="nopin"
    >
    </jb-image>
    <ng-template #background>
      <jb-image
        [src]="content?.backgroundImage | dynamicImageWidthPipe: 'image'"
        [isLazy]="enableLazyLoadImage"
        class="br--top br2 self-center center background-img-cover"
        data-pin-no-hover="true"
        nopin="nopin"
      >
      </jb-image
      ><!-- [stretchToWidth]="true" -->
    </ng-template>
    <span class="clip">{{ (content?.link)[0]?.title }}</span>
  </a>
  <div class="flex flex-column pv5 ph3 tc">
    <h3 class="pb2 mb0 core-blue heading-copy-typeface">
      {{ content?.title }}
    </h3>
    <p class="mt0 pb3 body charcoal">{{ content?.content }}</p>
    <ng-container [ngSwitch]="(content?.link)[0]?.newWindow">
      <div *ngSwitchCase="'_blank'" class="s-body">
        <a
          [target]="(content?.link)[0]?.newWindow"
          [dotLinkIcon]="(content?.link)[0]?.newWindow"
          rel="noopener"
          class="b items-baseline inline-flex no-underline tc underline-hover royal-blue"
          [jbSitemapLinker]="(content?.link)[0]?.href"
          >{{ (content?.link)[0]?.title }}</a
        >
      </div>
      <div *ngSwitchCase="'_document'" class="s-body">
        <a
          [target]="(content?.link)[0]?.newWindow"
          [dotLinkIcon]="(content?.link)[0]?.newWindow"
          rel="noopener"
          class="b items-baseline inline-flex no-underline tc underline-hover royal-blue"
          [jbSitemapLinker]="(content?.link)[0]?.href"
          >{{ (content?.link)[0]?.title }}</a
        >
      </div>
      <jb-arrow-link
        *ngSwitchDefault
        class="s-body"
        [link]="(content?.link)[0]"
      ></jb-arrow-link>
    </ng-container>
  </div>
</div>
