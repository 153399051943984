import { LegalCopy } from '@dynamic-components/isolated-flight-block-container/flight-block.type';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { AppState } from '../shared/types/app-state.type';
import { DealsState } from './types/deals-state.type';

export const deals: MemoizedSelector<AppState, DealsState> =
  createFeatureSelector('deals');

export const legalCopy: MemoizedSelector<AppState, LegalCopy> = createSelector(
  deals,
  state => state?.legalCopy,
);
