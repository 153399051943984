import { BookerRestrictedOriginsModalWarning } from '@core/app-config/types/booker-restricted-origins-modal-warning.type';
import { convertToDate } from '@core/utils/date.utils';
import { BOOKER_CONFIG } from '@shared/booker/booker.config';
import { WarningMessage } from '@shared/booker/types';
import { BookerResponse } from '@shared/booker/types/booker-response.type';
import { isOneWayTrip } from '@shared/booker/utils/booker-utils';
import { Airport } from '@store/origins/types';
import { isSameDay, isToday } from 'date-fns';

const bookerConfig = BOOKER_CONFIG;

export enum WarningTypeEnum {
  BOTH_INTERNATIONAL = 'bothInternationalFlights',
  CUBA = 'destinationCuba',
  INTERNATIONAL = 'internationalFlights',
  RESTRICTED_ORIGINS = 'restrictedOrigins',
  SAME_DAY = 'sameDay',
}

export function isRestrictedOrigin(
  originCode: string,
  bookerRestrictedOriginsModalWarning: BookerRestrictedOriginsModalWarning,
): WarningMessage {
  const upperCaseOriginCode = originCode;
  return bookerRestrictedOriginsModalWarning &&
    bookerRestrictedOriginsModalWarning[upperCaseOriginCode]
    ? {
        content:
          bookerRestrictedOriginsModalWarning[upperCaseOriginCode][0].content,
        continueButton:
          bookerRestrictedOriginsModalWarning[upperCaseOriginCode][0]
            .continueButton,
        linkButtons:
          bookerRestrictedOriginsModalWarning[upperCaseOriginCode][0]
            .linkButtons,
        title:
          bookerRestrictedOriginsModalWarning[upperCaseOriginCode][0].title,
        warningType: WarningTypeEnum.RESTRICTED_ORIGINS,
      }
    : null;
}

export function isTodayWarning(
  response: BookerResponse['warningMessages'],
  departDateString: string,
): WarningMessage {
  let todaysDateWarning;
  if (departDateString && isToday(convertToDate(departDateString))) {
    todaysDateWarning = {
      title: `${response.todaysDate.title}`,
      content: `${response.todaysDate.content}`,
    };
  }
  return todaysDateWarning;
}

export function isUnnCount(
  response: BookerResponse['warningMessages'],
  adultNum: number,
  childNum: number,
): WarningMessage {
  let unnCountWarning;
  if (!!unnCount(adultNum, childNum)) {
    unnCountWarning = {
      title: `${response.unnacompaniedMinor.title}`,
      content: response.unnacompaniedMinor.content,
    };
  }
  return unnCountWarning;
}

export function isOneWayAndInternational(
  response: BookerResponse['warningMessages'],
  tripType: string,
  origin: Airport,
  destination: Airport,
): WarningMessage {
  let owInternationalWarning;
  if (isOneWayTrip(tripType) && isInternational(origin, destination)) {
    owInternationalWarning = {
      title: `${response.owInternational.title}`,
      content: response.owInternational.content,
    };
  }
  return owInternationalWarning;
}

export function isCuba(
  response: BookerResponse['warningMessages'],
  destination: string,
): WarningMessage {
  let cubaWarning;
  if (!!bookerConfig.BOOKER_VACATIONS_CONFIG.CUBA[destination]) {
    cubaWarning = {
      title: `${response.cubaVacation.title}`,
      content: `${response.cubaVacation.content}`,
      warningType: WarningTypeEnum.CUBA,
    };
  }
  return cubaWarning;
}

export function isSameDayVacation(
  response: BookerResponse['warningMessages'],
  departDate: string,
  returnDate: string,
): WarningMessage {
  let sameDayVacationWarning;
  if (
    departDate &&
    returnDate &&
    isSameDay(new Date(departDate), new Date(returnDate))
  ) {
    sameDayVacationWarning = {
      title: `${response.sameDayVacation.title}`,
      content: response.sameDayVacation.content,
      warningType: WarningTypeEnum.SAME_DAY,
    };
  }
  return sameDayVacationWarning;
}

export function isBothInternationalFlights(
  response: BookerResponse['warningMessages'],
  origin: Airport,
  destination: Airport,
): WarningMessage {
  let bothInternationalFlights;
  if (origin && destination && isBothInternational(origin, destination)) {
    bothInternationalFlights = {
      title: `${response.bothInternationalFlights.title}`,
      content: response.bothInternationalFlights.content,
      warningType: WarningTypeEnum.BOTH_INTERNATIONAL,
    };
  }
  return bothInternationalFlights;
}

/**
 * Return unnacopanied minor count, if any
 */
export function unnCount(adultNum: number, childNum: number): number {
  let count = 0;
  const minors: number = childNum || 0;

  // if adt == 0 and child > 1
  if (minors > 0 && adultNum < 1) {
    count = minors;
  }

  return count;
}

/**
 * Check if flight is considered international travel. Exceptions apply (look at booker config).
 * For example, flying from STT to MCO, would not display warning.
 */

export function isInternational(origin: Airport, destination: Airport) {
  const originOverride = bookerConfig.INTERNATIONAL_EXCEPTIONS[origin?.code];
  const destinationOverride =
    bookerConfig.INTERNATIONAL_EXCEPTIONS[destination?.code];
  // If airport is in exception list, overwrite its country code
  origin = !!originOverride ? originOverride : origin.countryCode;
  destination = !!destinationOverride
    ? destinationOverride
    : destination?.countryCode;
  return origin !== destination;
}

function isBothInternational(origin: Airport, destination: Airport) {
  const originOverride = bookerConfig.INTERNATIONAL_EXCEPTIONS[origin.code];
  const destinationOverride =
    bookerConfig.INTERNATIONAL_EXCEPTIONS[destination.code];
  // If airport is in exception list, overwrite its country code
  const originCode = !!originOverride ? originOverride : origin.countryCode;
  const destinationCode = !!destinationOverride
    ? destinationOverride
    : destination.countryCode;
  return originCode !== 'US' && destinationCode !== 'US';
}
