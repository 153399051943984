import { Component, OnInit } from '@angular/core';
import { JbDialogService } from 'jb-component-library';

import { MOCK_VACATION_LEGAL_MODAL_DIALOG_LINK } from './vacation-legal-modal-dialog.link.mock';

// TODO: determine if this can be deleted
/**
 * @description Static legal text for vacations
 * @WhereIsItUsed Not used
 */
@Component({
  selector: 'jb-vacation-legal-modal-dialog',
  templateUrl: './vacation-legal-modal-dialog.component.html',
  styleUrls: ['./vacation-legal-modal-dialog.component.scss'],
})
export class VacationLegalModalDialogComponent implements OnInit {
  serviceFeeLink: string;
  termsConditionLink: string;

  constructor(public dialogService: JbDialogService) {}

  ngOnInit() {
    this.serviceFeeLink =
      MOCK_VACATION_LEGAL_MODAL_DIALOG_LINK.service_fees_link;
    this.termsConditionLink =
      MOCK_VACATION_LEGAL_MODAL_DIALOG_LINK.terms_and_condition_link;
  }

  closeDialog() {
    this.dialogService.closeDialog();
  }
}
