<div class="jb-max-width center page-margin">
  <div class="jb-grid">
    <div
      class="col-6-m col-4-l ba-ns bt bb b--medium-gray flex flex-column overflow-hidden br3-ns pb3"
    >
      <div class="h10 bg-off-white"></div>
      <dot-text-block
        class="mt7 mb3 ml2 ph3"
        [width]="50"
        [height]="12"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="90"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="88"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="87"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb4 ml2 ph3"
        [width]="30"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
    </div>
    <div
      class="col-6-m col-4-l ba-ns bt bb b--medium-gray flex flex-column overflow-hidden br3-ns pb3"
    >
      <div class="h10 bg-off-white"></div>
      <dot-text-block
        class="mt7 mb3 ml2 ph3"
        [width]="50"
        [height]="12"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="90"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="88"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="87"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb4 ml2 ph3"
        [width]="70"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
    </div>
    <div
      class="col-6-m col-4-l ba-ns bt bb b--medium-gray flex flex-column overflow-hidden br3-ns pb3"
    >
      <div class="h10 bg-off-white"></div>
      <dot-text-block
        class="mt7 mb3 ml2 ph3"
        [width]="50"
        [height]="12"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="90"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="88"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb2 ml2 ph3"
        [width]="87"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
      <dot-text-block
        class="mt1 mb4 ml2 ph3"
        [width]="50"
        [height]="10"
        bgColor="#eeeeee"
        gradientColor="#bbbbbb"
      ></dot-text-block>
    </div>
  </div>
</div>
