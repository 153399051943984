import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
@Pipe({
  name: 'dotFilterUndefined',
})
export class DotFilterUndefinedPipe implements PipeTransform {
  transform(value$: Observable<number>): Observable<number> {
    return value$.pipe(filter(value => !!value));
  }
}
