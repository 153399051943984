export const enterNewPasswordErrorMessages = {
  400: 'Please provide a valid password.',
  401: 'Unauthorized. Access token is missing or invalid.',
  403: 'You have accessed an account recovery link that has expired or been previously used.',
  404: 'Sorry, that page cannot be found.',
  500: 'We encountered an internal error. Please try again later.',
  generic: 'An error occurred, please try again later.',
  expiredLink:
    'Your password reset link has expired. Please try requesting a new link.',
};
