enum FARE_TYPES {
  DOLLARS = 'LOWEST',
  POINTS = 'POINTS',
}

enum TRIP_TYPES {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'RETURN',
}

export const BFF_SERVICE_CONFIG = {
  FARE_TYPES,
  TRIP_TYPES,
};
