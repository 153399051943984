import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { HttpService } from '@core/http';
import { networkError } from '@store/shared/action.utils';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { abtestConfigFactory } from '../../global-factories';
import { AbtestCampaign } from './abtest-campaign.type';

@Injectable({
  providedIn: 'root',
})
export class AbtestConfigProviderService {
  config: AbtestCampaign;

  private httpService: HttpService;
  private appConfigService: AppConfigService;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      // server side code req for ssr
      return;
    }
    this.httpService = inject(HttpService);
    this.appConfigService = inject(AppConfigService);
    this.init();
  }

  init() {
    // set up the config initially
    this.config = abtestConfigFactory();
  }

  // retrieve the config from server
  loadConfig(): Observable<any> {
    if (isPlatformServer(this.platformId)) {
      // server side code req for ssr
      return of(null);
    }
    return this.httpService
      .get<any>(`${this.appConfigService.abTestConfigUrl}`)
      .pipe(
        tap(response => (this.config = response)),
        catchError(() => {
          return of(networkError());
        }),
      );
  }

  getConfig(): AbtestCampaign {
    if (isPlatformServer(this.platformId)) {
      // server side code req for ssr
      return null;
    }
    return this.config;
  }
}
