<div class="page-margin jb-max-width center-l pt3">
  <dot-text-block
    class="db center w-20 pv10"
    bgColor="#eeeeee"
    [height]="18"
  ></dot-text-block>
</div>

<jb-product-block-skeleton></jb-product-block-skeleton>
<div class="bg-white min-h-10"></div>
