import {
  Component,
  // ViewChild,
  // ContentChild,
  // Renderer2,
  // ElementRef,
} from '@angular/core';
// import { WindowService } from '../../core';
// import { Subscription } from 'rxjs';

// TODO: to be deleted
@Component({
  selector: 'jb-chapter-selector',
  templateUrl: './jb-chapter-selector.component.html',
  styleUrls: ['./jb-chapter-selector.component.scss'],
})
export class JbChapterSelectorComponent {
  // @ContentChild('sidenavWrapper') sidenavWrapper: ElementRef;
  // @ViewChild('vl') verticalLine: ElementRef;
  // height: number;
  // isMobile: boolean;
  // titles = [];
  // private subscriptions: Subscription = new Subscription();
  // constructor()
  // private windowService: WindowService,
  // private renderer: Renderer2,
  // { }
  // this.subscriptions.add(
  //   this.windowService.windowSizeChanged.subscribe(size => {
  //     this.isMobile = size === 'small';
  //     if (!!this.sidenavWrapper) {
  //       // this.height = this.calcVerticalLineHeight();
  //     }
  //   }),
  // );
  // ngAfterViewChecked() {
  // stop executing the logic after vertical line is displayed
  // if (this.verticalLine.nativeElement.offsetHeight === 0) {
  //   this.renderer.setStyle(
  //     this.verticalLine.nativeElement,
  //     'height',
  //     `${this.calcVerticalLineHeight()}px`,
  //   );
  // }
  // }
  // calcVerticalLineHeight() {
  //   const listWrapperHeight = this.sidenavWrapper.nativeElement.offsetHeight;
  //   const navList = this.sidenavWrapper.nativeElement.querySelectorAll('li');
  //   const lastNavLinkHeight = navList[navList.length - 1].offsetHeight;
  //   return listWrapperHeight - lastNavLinkHeight;
  // }
}
