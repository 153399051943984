import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  NavigationBehaviorOptions,
  Router,
  UrlTree,
} from '@angular/router';
import {
  AppConfigService,
  CookieStorageService,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  LOCAL_STORAGE,
  WINDOW,
} from '@core/index';
import { getDomain } from '@feature/sign-in-page/sign-in-page.util';
import {
  anonymizeUserFullStory,
  registerLogFullStory,
} from '@shared/ui/utils/global-utils/full-story';
import { initialAuthState } from '@store/auth/auth.reducer';
import { equals, objOf } from 'ramda';
import {
  BehaviorSubject,
  fromEvent as observableFromEvent,
  Observable,
} from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { EventsService } from '../events';
import { WindowSize } from './types/window-size.type';

@Injectable({ providedIn: 'root' })
export class WindowService {
  // observes screen size when window loads and resizes
  windowSizeChanged: BehaviorSubject<WindowSize>;
  // additional behavior subject for easy isMobile check
  isMobile: BehaviorSubject<boolean>;
  // additional behavior subject for easy isTablet check
  isTablet: BehaviorSubject<boolean>;
  // additional behavior subject for easy isDesktop check
  isDesktop: BehaviorSubject<boolean>;
  navigator: Navigator;

  internalNavigate(url: string | UrlTree, extras?: NavigationBehaviorOptions) {
    return this.routes.navigateByUrl(url, extras);
  }

  constructor(
    @Inject(WINDOW) private window,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
    private appConfigService: AppConfigService,
    private routes: Router,
    private route: ActivatedRoute,
    private cookieStorageService: CookieStorageService,
    private eventsService: EventsService,
    @Inject(LOCAL_STORAGE) private localStorage,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return null;
    }
    // initialize behavior subjects
    this.navigator = this.window.navigator;
    const initialScreenSize = this.detectScreenType(this.window.innerWidth);
    this.windowSizeChanged = new BehaviorSubject<WindowSize>(initialScreenSize);
    this.isMobile = new BehaviorSubject<boolean>(!!initialScreenSize.small);
    this.isTablet = new BehaviorSubject<boolean>(!!initialScreenSize.medium);
    this.isDesktop = new BehaviorSubject<boolean>(!!initialScreenSize.large);

    // subscribe to window resize
    this.getWindowSize()
      .pipe(distinctUntilChanged(equals))
      .subscribe((size: WindowSize) => {
        this.isMobile.next(!!size.small);
        this.isTablet.next(!!size.medium);
        this.isDesktop.next(!!size.large);
        this.windowSizeChanged.next(size);
      });
  }

  getResizeEvent(): Observable<any> {
    return observableFromEvent(this.window, 'resize').pipe(
      startWith({
        currentTarget: {
          innerWidth: this.window.innerWidth,
          innerHeight: this.window.innerHeight,
        },
      }),
    );
  }

  // get stream of re-size events mapped to screen type
  getWindowSize(): Observable<WindowSize> {
    return this.getResizeEvent().pipe(
      // start observable with the current width
      map(event => this.detectScreenType(event['currentTarget'].innerWidth)),
      distinctUntilChanged(equals),
    );
  }

  /**
   * This function checks if the input string is a valid URL based on several criteria:
   * - It must be an HTTPS URL.
   * - It can be a `mailto:` link.
   * - It supports relative paths that may start with a slash.
   * - It accepts relative paths containing URL-encoded characters, with or without leading slashes.
   *
   * @param   {string} returnUrl The URL to validate.
   * @param {boolean} allowSpaces Flag to allow spaces in url, as needed by sabre dx endpoints
   * @returns {string|null}      Returns the original URL if it is valid according to the specified criteria, or `null` if it is not.
   */
  validateUrl(returnUrl: string, allowSpaces = false): string | null {
    // Regex to check if URL is either a valid https URL or a relative path without a scheme
    const validUrlPattern = allowSpaces
      ? /^(https:\/\/[^\s$.?#].*|\/[^:\s]*|mailto:[^\s]+)$/
      : /^(https:\/\/[^\s$.?#].[^\s]*|mailto:[^\s]+|\/?(?:[^:\s%]+|%[0-9A-Fa-f]{2})[^:\s]*)$/;

    if (validUrlPattern.test(returnUrl)) {
      return returnUrl;
    }

    return null;
  }

  navigateToOutsideUrl(url: string, allowSpacesInUrl = false): string {
    const validatedUrl = this.validateUrl(url, allowSpacesInUrl);
    let navigateTo =
      this.appConfigService.baseDomain ||
      `${window.location.protocol}//${window.location.host}`;

    if (validatedUrl) {
      this.window.document.body.style.display = 'none';
      navigateTo = validatedUrl;
      setTimeout(() => (this.window.location.href = validatedUrl), 10);
    } else {
      window.location.href = navigateTo;
    }

    return navigateTo;
  }

  // submits a form using plain vanilla javascript
  submitVanillaJsForm(id: string) {
    this.getFormWithId(id).submit();
  }

  getFormWithId(id: string) {
    return this.window.document.forms[id];
  }

  detectScreenType(size): WindowSize {
    let screenType: string;
    switch (true) {
      case size >= this.appConfigService.tabletBreakPoint:
        screenType = 'large';
        break;
      case size < this.appConfigService.tabletBreakPoint &&
        size >= this.appConfigService.mobileBreakPoint:
        screenType = 'medium';
        break;
      case size < this.appConfigService.mobileBreakPoint:
        screenType = 'small';
        break;
    }
    return objOf(screenType, true);
  }

  checkMobileOperatingSystem() {
    const userAgent =
      this.window.navigator.userAgent ||
      this.window.navigator.vendor ||
      this.window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !this.window.MSStream) {
      return 'iOS';
    } else if (/android/i.test(userAgent)) {
      return 'Android';
    } else {
      return 'Other';
    }
  }
  isCookieEnabled() {
    return (
      this.window.navigator.cookieEnabled &&
      'cookie' in this.window.document &&
      (this.window.document.cookie.length > 0 ||
        (this.window.document.cookie = 'test').indexOf.call(
          this.window.document.cookie,
          'test',
        ) > -1)
    );
  }

  redirectOktaAfterLogout() {
    this.route.queryParams.subscribe(params => {
      /**
       * The store sometimes is not updating our local storage in time..
       * Adding this workaround to force authJBDotcom to be the initialAuthState
       * REF: DOT-11034
       * */
      this.localStorage.setItem(
        'authJBDotcom',
        JSON.stringify(initialAuthState),
      );

      // Annonymize user from FS in the end of the logout flow.
      anonymizeUserFullStory(this.window, this.cookieStorageService);

      if (
        !this.isWhitelistedReturnUrl(params.returnUrl) ||
        this.ignoreReturnUrl(params.returnUrl)
      ) {
        this.window.location.href = this.window.location.origin;
        return;
      }

      this.window.location.href = decodeURIComponent(params.returnUrl);
    });
  }

  redirectToOktaLogoutPage() {
    const returnUrl = this.window.encodeURIComponent(
      `${this.window.location.href}`,
    );

    if (this.isWebComponent === 'header') {
      this.eventsService.dispatchCustomEvent(
        EventsService.CUSTOM_EVENTS.JB_HEADER_LOGOUT_CLICKED_OUTPUT_EVENT,
      );
    } else {
      registerLogFullStory(
        'Successful logout',
        {
          page_path_str: returnUrl,
        },
        this.window,
      );

      this.routes.navigate(['/logout'], {
        queryParams: {
          returnUrl,
        },
      });
    }
  }

  ignoreReturnUrl(url): boolean {
    if (!!url) {
      const decodedUrl = decodeURIComponent(url);
      const pagename =
        decodedUrl.indexOf('//') > -1
          ? decodedUrl.split('/')[3]
          : decodedUrl.split('/')[1];
      return pagename === 'preference-center';
    }

    return false;
  }

  isWhitelistedReturnUrl(url): boolean {
    if (!!url) {
      const decodedUrl = decodeURIComponent(url);
      // extract hostname from url and strictly check
      // whether domain name is among whitelisted domains or not
      let hostname =
        decodedUrl.indexOf('//') > -1
          ? decodedUrl.split('/')[2]
          : decodedUrl.split('/')[0];

      hostname = hostname.split(':')[0].split('?')[0];
      const urlDomain = getDomain(hostname);
      return this.appConfigService.whitelistedDomains.includes(
        urlDomain.toLowerCase(),
      );
    }
    return false;
  }
}
