import { TypedDictionary } from '@core/utils';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { toUpper } from 'ramda';

import { AppState } from '../shared/types/app-state.type';
import {
  buildAirportsByCountryMap,
  buildCountryByAirportMap,
  buildCountryListFromAirports,
  extractAirportHashMap,
} from './api/origins.utils';
import {
  Airport,
  CountryWithAirportCodes,
  CountryWithAirports,
  OriginsState,
  Region,
} from './types';

export const origins: MemoizedSelector<AppState, OriginsState> =
  createFeatureSelector('origins');

export const airports: MemoizedSelector<AppState, Airport[]> = createSelector(
  origins,
  state => state.airports.list,
);

export const airportsMap: MemoizedSelector<
  AppState,
  TypedDictionary<Airport>
> = createSelector(origins, state => state.airports.map);

export const countriesWithAirportCodes: MemoizedSelector<
  AppState,
  CountryWithAirportCodes[]
> = createSelector(origins, state => state.countries);

export const countryByAirportMap: MemoizedSelector<
  AppState,
  TypedDictionary<CountryWithAirportCodes>
> = createSelector(
  airports,
  countriesWithAirportCodes,
  buildCountryByAirportMap,
);

// Airports do not always have a countryCode on them, and when we dont have a countryCode, downstream
// validators and other checks may get confused, when pulling an airport by it's airportCode (eg. 'BOS' for boston airport)
// we need to make sure we get a version of that airport with a country code on it
// todo: update the Airport types to have one without a missing countryCode, OR, make countryCode necessary on all Airports
export const getAirportWithCode = code =>
  createSelector(airportsMap, countryByAirportMap, (airportMap, countryMap) => {
    const foundAirport = code && airportMap[toUpper(code)];
    return foundAirport && countryMap && countryMap[toUpper(code)]
      ? {
          ...foundAirport,
          countryCode: countryMap[toUpper(code)].code,
        }
      : foundAirport;
  });

export const airportsByCountryMap: MemoizedSelector<
  AppState,
  TypedDictionary<Airport[]>
> = createSelector(airports, countryByAirportMap, buildAirportsByCountryMap);

// TODO: fix "Failed to construct 'Worker'" error on dev2
// export const airportsByCountryMap = createSelector(
//   airports,
//   countryByAirportMap,
//   (airportList, countryAirportMap) => ({
//     airportList,
//     countryAirportMap,
//   }),
// );

export const countriesWithAirports: MemoizedSelector<
  AppState,
  CountryWithAirports[]
> = createSelector(airports, countryByAirportMap, buildCountryListFromAirports);

export const originAirports: (
  id: string,
) => MemoizedSelector<AppState, CountryWithAirports[]> = id =>
  createSelector(origins, o => o.originAirports[id]);

export const originAirportsMap: (
  id: string,
) => MemoizedSelector<AppState, TypedDictionary<Airport>> = id =>
  createSelector(origins, o =>
    o.originAirports[id]
      ? extractAirportHashMap(o.originAirports[id])
      : undefined,
  );

export const destinationAirports: (
  id: string,
) => MemoizedSelector<AppState, CountryWithAirports[]> = id =>
  createSelector(origins, o => o.destinationAirports[id] || []);

export const bookerAirDestinationAirports: MemoizedSelector<
  AppState,
  CountryWithAirports[]
> = createSelector(origins, state => state.destinationAirports['BOOKER_AIR']);

export const flattenedBookerAirDestinationAirports = createSelector(
  bookerAirDestinationAirports,
  airportsByCountry =>
    airportsByCountry?.reduce(
      (acc, { airports: airportsList }) => [...acc, ...airportsList],
      [],
    ) || [],
);

export const destinationAirportsMap: (
  id: string,
) => MemoizedSelector<AppState, TypedDictionary<Airport>> = id =>
  createSelector(origins, o =>
    o.destinationAirports[id]
      ? extractAirportHashMap(o.destinationAirports[id])
      : undefined,
  );

export const regions: MemoizedSelector<
  AppState,
  TypedDictionary<Region>
> = createSelector(origins, state => state.regions);

export const nearbyAirportCodes = createSelector(
  origins,
  state => state.nearbyAirports,
);

export const isOdServiceDown = createSelector(
  origins,
  state => state.odService.didAnyEndpointFail,
);
