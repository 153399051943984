import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jbStringToBoolean',
})
export class StringToBooleanPipe implements PipeTransform {
  transform(boolString: string) {
    return typeof boolString === 'string' ? boolString === 'true' : boolString;
  }
}
