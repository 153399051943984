import { Pipe, PipeTransform } from '@angular/core';
import { convertToDate } from '@core/utils/date.utils';
import { format, isValid } from 'date-fns';

/**
 * Create this custom date pipe since angular date pipe formats date differently
 * on google pixel chrome browser
 * Formatting info can be found at https://date-fns.org/v1.29.0/docs/format
 */
@Pipe({
  name: 'jbDate',
})
export class DatePipe implements PipeTransform {
  transform(date: string | Date, dateFormat = 'MM-dd-yyyy'): string {
    if (!date) {
      return '';
    } else {
      const processedDate = convertToDate(date);
      if (isValid(processedDate)) {
        return format(processedDate, dateFormat);
      }
      return date.toString();
    }
  }
}
