<jb-text-image
  [content]="bodyTextImage"
  [theme]="theme"
  [ngClass]="{
    tl: layout === layoutEnum.left,
    tr: layout === layoutEnum.right,
    tc: layout === layoutEnum.center
  }"
  [insideAccordion]="insideAccordion"
></jb-text-image>
