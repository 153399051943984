/* eslint-disable no-param-reassign */
import { isPlatformServer } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import {
  Inject,
  inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { AuthFacade } from '@store/auth/auth.facade';
import { OktaJWT } from '@store/auth/types';
import {
  hasErrorField,
  NetworkError,
} from '@store/shared/types/network-error.type';
import {
  Observable,
  of,
  Subscription,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { TraceIdService } from '../trace-id';
import { processErrorResponse } from './http.api-transform';

export type OptionsArgs = {
  headers?: HttpHeaders;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

@Injectable({ providedIn: 'root' })
export class HttpService implements OnDestroy {
  static isSuccessful<T>(response: T | NetworkError): response is T {
    return !HttpService.hasError(response);
  }

  static hasError<T>(response: T | NetworkError): response is NetworkError {
    return hasErrorField(response) && Boolean(response.error);
  }

  private oktaToken: OktaJWT;
  private subscriptions: Subscription;

  private http: HttpClient;
  private traceIdService: TraceIdService;
  private authFacade: AuthFacade;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.subscriptions = new Subscription();
    this.http = inject(HttpClient);
    this.traceIdService = inject(TraceIdService);
    this.authFacade = inject(AuthFacade);
    this.init();
  }

  init() {
    this.subscriptions.add(
      this.authFacade.oktaToken
        .pipe(filter<OktaJWT>(Boolean))
        .subscribe((token: any) => {
          console.log('HttpService init oktaToken', token);
          this.oktaToken = token;
        }),
    );
  }

  ngOnDestroy() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.subscriptions.unsubscribe();
  }

  /**
   * Function to call http get
   * @param { string } url url to call http get
   * @param { OptionsArgs } options request option
   * @param { boolean } jwt set to true for calls that need authorization
   * @param { boolean } plain set plain to true to prevent any customs headers from being added
   * @param { boolean } useOktaJWTToken set to true to use okta tokens instead of legacy
   * @return { Observable<T | ApiError> } if the request is successful, it would return observable
   * of type T. If not, it would throw observable of type ApiError.
   */
  get<T>(
    url: string,
    options: OptionsArgs = {},
    jwt?: boolean,
    plain?: boolean,
  ): Observable<T> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    if (!plain) {
      options = this.addTraceInfo(options);
      if (!!jwt) {
        options = this.addJwt(options);
      }
    }

    return this.http.get<T>(url, options).pipe(
      catchError((error: HttpErrorResponse) => {
        return observableThrowError(processErrorResponse(error));
      }),
    );
  }

  getText(
    url: string,
    options: OptionsArgs = {},
    jwt?: boolean,
    plain?: boolean,
  ): Observable<string> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    if (!plain) {
      options = this.addTraceInfo(options);
      if (!!jwt) {
        options = this.addJwt(options);
      }
    }

    return this.http.get(url, { ...options, responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        return observableThrowError(processErrorResponse(error));
      }),
    );
  }

  getObserveResponse<T>(
    url: string,
    options: OptionsArgs = {},
    jwt?: boolean,
    plain?: boolean,
  ): Observable<HttpResponse<T>> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    if (!plain) {
      options = this.addTraceInfo(options);
      if (!!jwt) {
        options = this.addJwt(options);
      }
    }

    return this.http
      .get<T>(url, { ...options, observe: 'response' })
      .pipe(
        catchError((error: HttpErrorResponse) => observableThrowError(error)),
      );
  }

  /**
   * Function to call http post
   * @param { string } url url to call http post
   * @param { any } body request body
   * @param { OptionsArgs } options request option
   * @param { boolean } jwt set to true for calls that need authorization
   * @param { boolean } useOktaJWTToken set to true to use okta tokens instead of legacy
   * @param { boolean } plain set plain to true to prevent any customs headers from being added
   * @return { Observable<T> } if the request is successful, it would return observable
   * of type T. If not, it would throw observable of type ApiError.
   */
  post<T>(
    url: string,
    body: any,
    options: OptionsArgs = {},
    jwt?: boolean,
    plain?: boolean,
  ): Observable<T> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    if (!plain) {
      options = this.addTraceInfo(options);
      if (!!jwt) {
        options = this.addJwt(options);
      }
    }

    return this.http
      .post<T>(url, body, options)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          observableThrowError(processErrorResponse(error)),
        ),
      );
  }

  /**
   * Function to call http post
   * @param { string } url url to call http post
   * @param { any } body request body
   * @param { OptionsArgs } options request option
   * @param { boolean } jwt set to true for calls that need authorization
   * @param { boolean } useOktaJWTToken set to true to use okta tokens instead of legacy
   * @return { Observable<HttpResponse<T>> } if the request is successful, it would return observable
   * of type HttpResponse<T>. If not, it would throw observable of type ApiError.
   */
  postObserveResponse<T>(
    url: string,
    body: any,
    options: OptionsArgs = {},
    jwt?: boolean,
  ): Observable<HttpResponse<T>> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    options = this.addTraceInfo(options);
    if (!!jwt) {
      options = this.addJwt(options);
    }

    return this.http
      .post<T>(url, body, { ...options, observe: 'response' })
      .pipe(
        catchError((error: HttpErrorResponse) =>
          observableThrowError(processErrorResponse(error)),
        ),
      );
  }

  /**
   * Function to call http delete
   * @param { string } url url to call http delete
   * @param { OptionsArgs } options request option
   */
  delete<T>(url: string, options: OptionsArgs = {}): Observable<T> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    options = this.addTraceInfo(options);

    return this.http
      .delete<T>(url, options)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          observableThrowError(processErrorResponse(error)),
        ),
      );
  }

  private addTraceInfo(options: OptionsArgs): OptionsArgs {
    const traceId = this.traceIdService.generateId();
    if (!options.headers) {
      const traceHeaders = {
        'X-B3-TraceId': traceId,
        'X-B3-SpanId': traceId,
      };

      return { ...options, headers: new HttpHeaders(traceHeaders) };
    }

    let headers = options.headers;
    headers = headers.append('X-B3-TraceId', traceId);
    headers = headers.append('X-B3-SpanId', traceId);

    return { ...options, headers };
  }

  private addJwt(options: OptionsArgs): OptionsArgs {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const headers = options.headers
      ? options.headers.append('Authorization', `Bearer ${this.oktaToken}`)
      : new HttpHeaders().set('Authorization', `Bearer ${this.oktaToken}`);
    return { ...options, headers };
  }
}
