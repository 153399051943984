import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LinksModule } from '../ui/links';
import { JBLogoComponent } from './jb-logo.component';

const MODULES = [CommonModule, RouterModule, LinksModule];

const DECLARATIONS = [JBLogoComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class JBLogoModule {}
