import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW } from '@core/injection-tokens';
import { WindowService } from '@core/window-actions';
import { Subscription } from 'rxjs';

export type ScrollBehavior = 'smooth' | 'jump';

@Injectable({
  providedIn: 'root',
})
export class ScrollService implements OnDestroy {
  private isMobile = false;
  private subscription: Subscription;

  constructor(
    @Inject(WINDOW) private window,
    private windowService: WindowService,
  ) {
    this.subscription = this.windowService.isMobile.subscribe(
      isMobile => (this.isMobile = isMobile),
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Scrolls to the given element adjusted
   * @param selector HTML element or attribute id
   * @param yOffset extra offset to add to the scroll position
   * @param behavior scroll behavior 'smooth' or 'jump'
   **/
  scrollToWithHeaderOffset(
    selector: string | HTMLElement,
    yOffset: number = 0,
    behavior: ScrollBehavior = 'smooth',
  ) {
    const element = this.getElement(selector);

    this.scrollToElement(
      selector,
      yOffset -
        (this.getHeaderOffset(this.isMobile) + this.getMarginTop(element)),
      behavior,
    );
  }

  /**
   * Scrolls to the given element
   * @param selector HTML element or attribute id
   * @param yOffset extra offset to add to the scroll position
   * @param behavior scroll behavior 'smooth' or 'jump'
   */
  scrollToElement(
    selector: string | HTMLElement,
    yOffset: number = 0,
    behavior: ScrollBehavior = 'smooth',
  ) {
    const element = this.getElement(selector);

    if (element) {
      const y =
        element.getBoundingClientRect().top + this.window.pageYOffset + yOffset;

      if (behavior === 'smooth') {
        this.scrollToY(y).then(() => {
          element.querySelector('h2')?.focus();
        });
      } else {
        this.jumpToY(y);
        element.querySelector('h2')?.focus();
      }
    }
  }

  /**
   * Instantly scroll to the given position
   * @param position Position to scroll to
   */
  jumpToY(position: number) {
    const targetPosition = Math.round(position);

    if (this.window.scrollTop !== targetPosition) {
      this.window.scrollTo({
        top: targetPosition,
        behavior: 'auto',
      });
    }
  }

  /**
   * Smoothly scrolls to the given position
   * @param position Position to scroll to
   * @returns Promise that resolves when the scroll is complete
   */
  async scrollToY(position: number): Promise<void> {
    const targetPosition = Math.round(position);

    if (this.window.scrollTop === targetPosition) {
      return new Promise(r => r());
    }

    this.window.scrollTo({
      top: position,
      behavior: 'smooth',
    });

    return new Promise(resolve => {
      let timeoutId;
      const finished = () => {
        this.window.removeEventListener('scroll', scrollHandler);
        resolve();
      };
      const scrollHandler = () => {
        clearTimeout(timeoutId);
        if (this.window.pageYOffset === targetPosition) {
          finished();
        } else {
          // if there are no movements for 100ms, we assume that the scrolling has finished
          timeoutId = setTimeout(() => finished(), 100);
        }
      };
      if (this.window.pageYOffset === targetPosition) {
        resolve();
      } else {
        this.window.addEventListener('scroll', scrollHandler, {
          passive: true,
        });
      }
    });
  }

  /**
   * Get the header element height by getting the HTML element offsetHeight
   * @param isMobile Is the viewport in mobile mode
   * @returns Header element height
   */
  getHeaderOffset(isMobile): number {
    return (
      (isMobile
        ? document.querySelector<HTMLElement>('jb-header-mobile')?.offsetHeight
        : document.querySelector<HTMLElement>('jb-header-desktop')
            ?.offsetHeight) || 0
    );
  }

  /**
   * Returns the margin top of the element
   * @param element HTML element
   * @returns margin as number
   */
  getMarginTop(element: HTMLElement | null): number {
    return element
      ? parseInt(
          this.window
            .getComputedStyle(element, null)
            .getPropertyValue('margin-top')
            .replace(/\D/g, ''), // replace the 'px' with nothing to parse the int
          10,
        )
      : 0;
  }

  /**
   * Get the header element height by getting the HTML element offsetHeight
   * @param selectorOrElement HTML element or attribute id
   * @returns HTML element
   */
  getElement(selectorOrElement: string | HTMLElement): HTMLElement | null {
    return typeof selectorOrElement === 'string'
      ? document.querySelector<HTMLElement>(`[id='${selectorOrElement}']`)
      : selectorOrElement;
  }
}
