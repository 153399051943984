<div class="bg-white charcoal">
  <div [ngStyle]="{ 'background-color': bgColor }">
    <div class="db-ns flex items-center justify-center flex-column mh4">
      <div
        *ngIf="profile.isMosaic === 'true'"
        class="flex flex-row justify-around items-center mb2 mt4"
      >
        <img
          [src]="loggedInSection?.topSection?.children?.image[0].srcMosaic"
          alt="M O S A I C"
          width="24"
          height="21"
        />
        <span
          *ngIf="profile.isMosaic"
          class="ml2 klarheit fw6"
          [ngClass]="theme === 'dark' ? 'white' : 'core-blue'"
          >{{ mosaicTier }}</span
        >
      </div>
      <div
        *ngIf="profile.isMosaic === 'false'"
        class="flex flex-row justify-center mt4 mb3"
      >
        <img
          [src]="loggedInSection?.topSection?.children?.image[0].srcDefault"
          alt="T R U E B L U E"
          width="75"
          height="8"
        />
      </div>

      <!-- User first name-->
      <div
        class="user-name mb2 f4 l-body-demi"
        [ngClass]="theme === 'dark' ? 'white' : 'core-blue'"
        data-qaid="firstNameLastInitial"
      >
        <span>{{ loggedInSection?.topSection?.children?.greeting }} </span
        >{{ firstName }}
      </div>
    </div>
    <!-- Trueblue number -->
    <div
      class="tb-number lh-copy s-body mv1-m mb1-l mt0-l mt1 mb2 legal flex justify-center"
      [ngClass]="theme === 'dark' ? 'white' : 'core-blue'"
    >
      {{ loggedInSection?.topSection?.children?.TrueBlueNrtxt
      }}<span data-qaid="trueBlueNumber">{{ tbNumber }}</span>
    </div>
    <!-- Tiles/Label/TravelBank -->
    <div class="flex justify-around flex-row mh4 mt3">
      <div>
        <div
          class="legal flex justify-center"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ loggedInSection?.topSection?.children?.myTiles }}
        </div>
        <div
          class="body-demi flex justify-center"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          <span>{{ profile.numberOfTiles }}</span>
        </div>
      </div>
      <div *ngIf="!!points">
        <hr class="bl-0 b--medium-gray bt-0 bb-0 vertical-line" />
      </div>
      <div *ngIf="!!points">
        <div
          class="legal flex justify-center"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ loggedInSection?.topSection?.children?.points }}
        </div>
        <div
          class="body-demi flex justify-center"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ points }} {{ loggedInSection?.topSection?.children?.pts }}
        </div>
      </div>
      <div *ngIf="travelBankBalance$ | dotFilterUndefined | async">
        <hr class="bl-0 b--medium-gray bt-0 bb-0 vertical-line" />
      </div>
      <div *ngIf="(travelBankBalance$ | dotFilterUndefined | async) as tbBalance">
        <div
          class="legal flex justify-center"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ loggedInSection?.topSection?.children?.travelBank }}
        </div>
        <div
          class="body-demi flex justify-center"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ tbBalance | currency }}
        </div>
      </div>
    </div>
    <div class="mh4 mt3">
      <a
        jbButton
        [theme]="buttonType"
        class="mb4 royal-blue"
        [isFull]="true"
        [size]="'small'"
        [jbSitemapLinker]="loggedInSection?.topSection?.children?.link[0].href"
        (click)="closeEvent.emit('close-modal')"
      >
        {{ loggedInSection?.topSection?.children?.link[0].title }}
      </a>
    </div>
  </div>
  <div class="mh4 mt3">
    <jb-body-text-container
      [content]="loggedInSection?.middleSection?.section?.bodyText"
      (click)="closeEvent.emit('close-modal')"
    >
    </jb-body-text-container>
  </div>
  <hr class="mv3 bl-0 br-0 bt-0 bb b--medium-gray" />
  <div class="mh4 mt3">
    <a
      href="javascript:void(0)"
      (click)="handleSignOut()"
      data-qaid="signOut"
      class="royal-blue no-underline underline-hover klarheit"
      >{{ loggedInSection?.signoutSection?.children?.title }}</a
    >
  </div>
  <!-- Bottom Section -->
  <div class="ws-normal pt4">
    <dot-tb-bottom-section
      [content]="loggedInSection?.bottomSection?.section"
    ></dot-tb-bottom-section>
  </div>
</div>
