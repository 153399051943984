import { DialogActionsBottomDirective } from './dialog-actions-bottom.directive';
import { DialogActionsTopDirective } from './dialog-actions-top.directive';
import { CloseDialogDirective } from './dialog-close.directive';
import { DialogTitleDirective } from './dialog-title.directive';

export {
  CloseDialogDirective,
  DialogActionsBottomDirective,
  DialogActionsTopDirective,
  DialogTitleDirective,
};

export const DIALOG_DIRECTIVES = [
  DialogTitleDirective,
  DialogActionsTopDirective,
  DialogActionsBottomDirective,
  CloseDialogDirective,
];
