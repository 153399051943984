import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JbOfferBlockModule, JbStarRatingModule } from 'jb-component-library';

import { DialogTempModule } from '../dialog';
import { JBInnerHtmlModule } from '../jb-inner-html';
import { UIModule } from '../ui/ui.module';
import { FareSaleModalDialogComponent } from './fare-sale-modal-dialog.component';

const DECLARATIONS = [FareSaleModalDialogComponent];
const MODULES = [
  CommonModule,
  DialogTempModule,
  JBInnerHtmlModule,
  UIModule,
  JbStarRatingModule,
  JbOfferBlockModule,
];

@NgModule({
  imports: [...MODULES],
  exports: [...DECLARATIONS],
  declarations: [...DECLARATIONS],
})
export class FareSaleModalDialogModule {
  static entry = FareSaleModalDialogComponent;
}
