<div
  *ngIf="!!legends"
  class="w-100"
  [ngClass]="column ? 'relative bg-off-white' : 'bg-light-gray'"
>
  <ul
    class="list w-100 flex legal ma0 pr0"
    [ngClass]="column ? 'flex-column pa3' : 'pl5 pv2'"
  >
    <li *ngIf="customLegendMessage" class="lh-solid">
      {{ customLegendMessage }}
    </li>
    <ng-container *ngIf="!hideLegend && !!legends && legends.length">
      <ng-container *ngFor="let legend of legends">
        <li
          *ngIf="!(filterOutPartner && legend.code.toUpperCase() === 'PARTNER')"
          class="klarheit pr3 flex items-center"
          [ngClass]="{ mv2: column }"
        >
          <jb-icon
            class="mr2 {{ legend.color }}"
            [iconSourceUrl]="legend.image.src"
            width="0.7em"
            height="0.7em"
          ></jb-icon>
          <span class="lh-solid">{{ legend?.title }}</span>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
