export const CMS_HOMEPAGE_FALLBACK = {
  endpoint: '/home',
  name: 'home',
  data: {
    h1title: 'JetBlue Home',
    navigationTitle: 'jetBlue Home',
    windowTitle: 'jetBlue Home',
    description:
      'Book JetBlue flights and vacation packages to 100+ destinations. Award-winning service, the most legroom in coach, free wi-fi, live TV, movies, snacks, and more.',
    title: 'Airline Tickets, Flights & Airfare: Book Direct - Official Site',
  },
  area: [
    {
      component: [
        {
          name: 'sectionHeader',
          data: {
            sectionHeader: [
              {
                name: 'section',
                data: {
                  theme: 'dark',
                  layout: 'center',
                  bgColor: '#00205b',
                  title: 'Travel on your mind?',
                  section: [
                    {
                      name: 'tabbedNavigationComponent',
                      data: {
                        theme: 'dark',
                        tabbedNavigationComponent: [
                          {
                            name: 'tabContent',
                            data: {
                              title: 'Flights',
                              icon: [
                                {
                                  src: 'flight',
                                },
                              ],
                              tabContent: [
                                {
                                  name: 'bookerAir',
                                  data: {
                                    bookerAir: [
                                      {
                                        bookerData: [
                                          {
                                            promoCode: [
                                              {
                                                label: 'Promo code',
                                                mobilePlaceholder: 'Promo code',
                                                desktopPlaceholder:
                                                  'Have a code?',
                                              },
                                            ],
                                            dateAggregator: [
                                              {
                                                dateacaaMessage:
                                                  'Please press down arrow key to interact with calendar',
                                                departureLabel: 'Depart',
                                                returnLabel: 'Return',
                                                errors: [
                                                  {
                                                    afterMaxDateError:
                                                      'Choose date before',
                                                    inValidDateError:
                                                      'Please enter a valid date.',
                                                    returnBeforeDepartError:
                                                      'Choose date after',
                                                    genericDateError:
                                                      'Please enter a date.',
                                                    beforeMinDateError:
                                                      'Choose date after',
                                                  },
                                                ],
                                                states: [
                                                  {
                                                    serviceLoading:
                                                      'Checking schedule...',
                                                    serviceSuccessNoFlights:
                                                      'No flights.',
                                                    serviceSuccessCash:
                                                      'One-way, per person, lowest recent fares shown.',
                                                    content: 'No flights.',
                                                    serviceFail:
                                                      "We couldn't check the schedule for this trip. Select any date to continue.",
                                                    serviceSuccessPoints:
                                                      'One-way, per person, lowest recent fares shown, plus taxes & fees from $5.60.',
                                                  },
                                                ],
                                              },
                                            ],
                                            showSchedule: 'false',
                                            separatePartnerCities: 'true',
                                            travelSelector: [
                                              {
                                                kids: 'Children',
                                                adult: 'Adult',
                                                age: 'Age',
                                                flightZeroPassengers:
                                                  'Ohh! You forgot to add a Traveler!',
                                                kid: 'Child',
                                                underage: '(Under 18)',
                                                lapInfant: 'Lap Infant',
                                                room: 'Room',
                                                travelers: 'Travelers',
                                                rooms: 'Rooms',
                                                adults: 'Adults',
                                                children: [
                                                  {
                                                    title: 'Children',
                                                    subtitle: '(Under 14)',
                                                  },
                                                ],
                                                lapInfants: [
                                                  {
                                                    title: 'Lap Infants',
                                                    subtitle: '(Under 2)',
                                                  },
                                                ],
                                                errors: [
                                                  {
                                                    noPassengerError:
                                                      'Please select a passenger.',
                                                    maxPassengerError:
                                                      'Maximum number of travelers exceeded.',
                                                    invalidChildAge:
                                                      "Child's age is required.",
                                                  },
                                                ],
                                                rules: [
                                                  {
                                                    label: 'Max-travelers',
                                                    id: 'MAX-TRAVELERS',
                                                    copy: 'Maximum 7 travelers',
                                                  },
                                                  {
                                                    label: 'MAX-Children',
                                                    id: 'MAX-CHILDREN',
                                                    copy: 'Maximum 3 children without an adult.',
                                                  },
                                                  {
                                                    label: 'Max-infants',
                                                    id: 'MAX-INFANTS',
                                                    copy: 'Maximum 3 lap infants, one per adult.',
                                                  },
                                                ],
                                                vacation_rules: [
                                                  {
                                                    label: 'Max-rooms',
                                                    id: 'MAX-ROOMS',
                                                    copy: 'Maximum 7 rooms.',
                                                  },
                                                  {
                                                    label: 'Max-travelers',
                                                    id: 'MAX-TRAVELERS',
                                                    copy: 'Maximum 7 travelers.',
                                                  },
                                                  {
                                                    label: 'Max-children',
                                                    id: 'MAX-CHILDREN',
                                                    copy: 'Maximum 6 children.',
                                                  },
                                                  {
                                                    label: 'Max-lap-infants',
                                                    id: 'MAX-LAP-INFANTS',
                                                    copy: 'Maximum 3 lap infants, one per adult.',
                                                  },
                                                  {
                                                    label: 'Min-adult',
                                                    id: 'MIN-ADULT',
                                                    copy: 'Minimum 1 adult per room.',
                                                  },
                                                  {
                                                    label: 'For-best-results',
                                                    id: 'FOR-BEST-RESULTS',
                                                    copy: 'For best results, maximum 4 travelers per room.',
                                                  },
                                                ],
                                                help: [
                                                  {
                                                    content:
                                                      '<div style="text-align:justify">Need <a href="/at-the-airport/accessibility-assistance" target="_blank">special assistance</a>?</div>\n<br />\nNeed help with <a href="/traveling-together/group-travel" target="_blank">8+ travelers</a>, <a href="/traveling-together/traveling-with-pets" target="_blank">pets</a>, <a href="/traveling-together/traveling-with-kids" target="_blank">kids or lap infants</a>?',
                                                  },
                                                ],
                                              },
                                            ],
                                            opacity: '0.9',
                                            bgColor: '#fff',
                                            isSubmitInside: 'true',
                                            citySelector: [
                                              {
                                                browseByRegions:
                                                  'Browse by regions',
                                                valueType: 'browseByRegions',
                                                swapLabel:
                                                  'Reverse origin and destination city or airport',
                                                listVersion: 'v1',
                                                from: [
                                                  {
                                                    label: 'From',
                                                    placeholder: 'Where from?',
                                                    message:
                                                      'use arrow keys to select drop menu options.',
                                                  },
                                                ],
                                                to: [
                                                  {
                                                    label: 'To',
                                                    placeholder: 'Where to?',
                                                    message:
                                                      'use arrow keys to select drop menu options',
                                                  },
                                                ],
                                                citySelector: [
                                                  {
                                                    recentSearchesHeading:
                                                      'RECENT',
                                                    nearbyHeading: 'NEARBY',
                                                    errorMessage:
                                                      'Please enter a valid city.',
                                                    noMatchText: [
                                                      {
                                                        invalidAirport:
                                                          'Hmmm... we couldn\'t find any results for "[SEARCHSTRING]".',
                                                        invalidRoute:
                                                          "Uh-oh. It doesn't look like we fly between those two locations. Please try  another city nearby.",
                                                      },
                                                    ],
                                                    regionsCopy: [
                                                      {
                                                        allRegionsTab:
                                                          'All Airports',
                                                        regions: 'Regions',
                                                        title:
                                                          'Select a region and city below',
                                                        cities: 'Cities',
                                                        openRegions:
                                                          'Browse by regions',
                                                      },
                                                    ],
                                                  },
                                                ],
                                                legends: [
                                                  {
                                                    color: 'fill-mint-green',
                                                    title: 'Mint Service',
                                                    code: 'mint',
                                                    image: [
                                                      {
                                                        alt: 'Mint',
                                                        src: '/magnoliapublic/dam/ui-assets/icons/mintIndicator.svg',
                                                        height: '0',
                                                        width: '0',
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    color: 'fill-slate-grey',
                                                    title: 'Partner Airline',
                                                    code: 'partner',
                                                    image: [
                                                      {
                                                        alt: 'partner',
                                                        src: '/magnoliapublic/dam/ui-assets/icons/partnerAirlineIndicator.svg',
                                                        height: '0',
                                                        width: '0',
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                            bookMessages: [
                                              {
                                                buttonTheme: 'primary',
                                                seasonalRouteMessage:
                                                  'This route is either seasonal or temporarily suspended for select dates.',
                                                bookWithPoints:
                                                  'Use TrueBlue points',
                                                submitButtonVacations:
                                                  'Search flights + hotel',
                                                roundTrip: 'Roundtrip',
                                                booker: 'Fly',
                                                oneWay: 'One-way',
                                                submitButtonAir:
                                                  'Search flights',
                                                multicity: [
                                                  {
                                                    removeFlight:
                                                      'Remove flight',
                                                    tripType: 'true',
                                                    flight: 'Flight',
                                                    addFlight: 'Add flight',
                                                    title: 'Multi-city',
                                                  },
                                                ],
                                                servicesFees: [
                                                  {
                                                    href: 'https://vacationsqa2.jetblue.com/B6/AirPackageErrorSearchForward.do',
                                                    title: 'Search vacations',
                                                  },
                                                ],
                                              },
                                            ],
                                            errorMessages: [
                                              {
                                                tooManyLapInfants:
                                                  'Please select more adults or fewer infants',
                                                minorAgeRestriction:
                                                  '(age must be between 2 to 18)',
                                                tooManyPassengers:
                                                  'Please select fewer passengers. The limit is 7.',
                                                daysOfWeekItinerary:
                                                  "The route you selected does not operate every day of the week.\\nIf you are flexible and want to see a full calendar view of our fares, check out the <a href='///best-fare-finder'>Best Fare Finder</a> or click continue.",
                                                genericCityError:
                                                  'Please enter a valid city',
                                                returnBeforeDepartError:
                                                  'Return must be after the depart date',
                                                arrivalCityError:
                                                  'Please enter a valid city',
                                                invalidChildAge:
                                                  'Please enter an age for child',
                                                seasonalItinerary:
                                                  "The route you selected only operates seasonally.\\nIf you are flexible with your dates and want to see a full calendar view of our fares, check out the <a href='///best-fare-finder'>Best Fare Finder</a> or click continue.",
                                                beforeMinDateError:
                                                  'Choose date after',
                                                afterMaxDateError:
                                                  'Choose date before',
                                                inValidDateError:
                                                  'Please enter a valid date',
                                                noPassengerError:
                                                  'Please select a passenger',
                                                genericDateError:
                                                  'Please enter a date',
                                                interlinePoints:
                                                  'TrueBlue points cannot be used to search for flights operated by our partner airlines. Please select dollars as your fare type to continue.',
                                                tooManyMinors:
                                                  'A maximum of 3 unnaccompanied minors is allowed',
                                                departCityError:
                                                  'Please enter a valid city',
                                              },
                                            ],
                                            warningMessages: [
                                              {
                                                airCancel: 'Cancel',
                                                vacationsOk: 'Ok',
                                                airSearch:
                                                  'Continue to flight results',
                                                airContinue: 'Continue',
                                                cubaVacation: [
                                                  {
                                                    title: 'Ack!',
                                                    content:
                                                      'Vacation packages (flights + hotel) are not currently available for Cuba. To book flights to Cuba, please start your booking from the Flights tab. To book flights + hotel to somewhere equally awesome, please select another destination.',
                                                  },
                                                ],
                                                todaysDate: [
                                                  {
                                                    content:
                                                      'We’ll only show you domestic flights scheduled to depart at least 30 minutes from now—or international flights departing in at least 60 minutes.',
                                                    title:
                                                      'Flying today, right?',
                                                  },
                                                ],
                                                sameDayVacation: [
                                                  {
                                                    content:
                                                      'You’ve selected a return date that’s the same day as your departure. To complete this booking (and enjoy a longer stay) please select a different return date.',
                                                    title:
                                                      'A same-day vacation? That’s not really a vacation.',
                                                  },
                                                ],
                                                shortTrip: [
                                                  {
                                                    content:
                                                      'Your return date is the same as your departure date. Is such a short trip intentional?',
                                                    title: 'Short Trip?',
                                                  },
                                                ],
                                                longTrip: [
                                                  {
                                                    title: 'Long Trip?',
                                                    content:
                                                      'You have selected a return date more than 4 weeks after your departure date. Is this intentional?',
                                                  },
                                                ],
                                                owInternational: [
                                                  {
                                                    content:
                                                      "Proof of return travel is often a requirement for one-way international flights like this. Please check with your destination country’s embassy to confirm their specific requirements. We don't want you to experience any difficulties at the airport.",
                                                    title:
                                                      'You may need proof of return travel',
                                                  },
                                                ],
                                                unnacompaniedMinor: [
                                                  {
                                                    title:
                                                      'Unaccompanied Minors',
                                                    content:
                                                      'Children 5 years and older may travel as unaccompanied minors on nonstop flights (except transatlantic flights). There is an additional fee, each way, for each unaccompanied minor.',
                                                  },
                                                ],
                                                bothInternationalFlights: [
                                                  {
                                                    title:
                                                      'Looks like you’ll make a stop in the U.S.',
                                                    content:
                                                      "International travelers connecting through a U.S. airport are required to have a valid passport and visa for entry into the U.S., and may need a visa for their final destination. You may also need to claim your checked bags upon arriving in the U.S. We don't want you to experience any difficulties at the airport.",
                                                  },
                                                ],
                                                ptpWarning: [
                                                  {
                                                    content:
                                                      'For flights originating in Pointe-à-Pitre, Guadeloupe (PTP), please book through our partner.',
                                                    title:
                                                      'Traveling from Pointe-à-Pitre, Guadeloupe?',
                                                    button: [
                                                      {
                                                        href: 'https://booking.jetblue.com/home/?refid=8382&refclickid=ptp',
                                                        newWindow: '_blank',
                                                        title:
                                                          'Book with our partner',
                                                      },
                                                    ],
                                                  },
                                                ],
                                                invalidFlightSearch: [
                                                  {
                                                    content:
                                                      'There are no flights available on the date(s) you selected, but you can check more options on our Best Fare Finder. Availability varies month to month.',
                                                    button: [
                                                      {
                                                        href: '/',
                                                        newWindow: '_self',
                                                        title:
                                                          'Explore more dates',
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                            enabled: 'true',
                                            showAirPromoCode: 'false',
                                            allowRWBRedemptionFlow: 'true',
                                            version: 'v2Condensed',
                                            ngbFlow: 'true',
                                            calendar: '2month',
                                            serviceOutage: [
                                              {
                                                bodyText: [
                                                  {
                                                    content:
                                                      'We are facing some technical issues.',
                                                    title: 'OD outage',
                                                    button: [
                                                      {
                                                        href: 'https://booking.jetblue.com/home/?refid=8382',
                                                        newWindow: '_blank',
                                                        title: 'Book flights',
                                                        type: 'external',
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                            vacationsLink: [
                                              {
                                                descriptionText:
                                                  'Want to get away for way less?',
                                                enableLink: 'false',
                                                linkLabel:
                                                  'Bundle flights + hotel',
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  backgroundOverlay: [
                    {
                      bgColorForOverlay: '#00205b',
                      opacity: '.7',
                      opacityType: 'solid',
                    },
                  ],
                  bgImages: [
                    {
                      src: '/magnoliapublic/dam/ui-assets/imagery/backgrounds/Palm-Trees.jpg',
                    },
                  ],
                },
              },
              {
                name: 'section',
                data: {
                  theme: 'light',
                  layout: 'center',
                  removeSectionSpacing: 'true',
                  bgColor: '#fff',
                  section: [
                    {
                      name: 'myTrips',
                      data: {
                        myTrips: {
                          confirmation: 'Confirmation',
                          flightStatus: 'Flight status',
                          gate: 'Gate',
                          departs: 'Departs',
                          departed: 'Departed',
                          heading: 'Your upcoming trip',
                          manageTrips: 'Manage Trips',
                          was: 'Was',
                          arrives: 'Arrives',
                          jbConfirmation: 'JB Confirmation',
                          to: 'to',
                          boarding: 'Boarding',
                          flight: 'Flight',
                          arrived: 'Arrived',
                          unavailable: 'Unavailable',
                          estimated: 'Estimated',
                          terminal: 'Terminal',
                          date: 'Date',
                          doorsClose: 'Doors close',
                          operatedBy: 'Operated by ',
                          doorsClosing: 'Doors closing',
                        },
                        configs: [
                          {
                            mybEnableHealthCheckService: 'true',
                            mybGoToDX: 'true',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  ],
};
