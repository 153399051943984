import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BookProfile } from '../cart/types/book-profile.interface';
import { StoreService } from '../shared/store.service';
import { TrueBlueProfile, TrueBlueState } from '../trueblue/types';
import {
  LoadProfile,
  LoggedInPaySucceeded,
  ProfileLoaded,
  RwbProfileFailed,
  RwbProfileRequested,
  RwbProfileSucceeded,
} from './profile.actions';
import * as ProfileSelectors from './profile.selectors';
import { Pay } from './types/profile-details-response.interface';

@Injectable({ providedIn: 'root' })
export class ProfileFacade {
  trueBlue: Observable<TrueBlueState> = this.storeService.getState(
    ProfileSelectors.trueBlue,
  );
  memberProfile: Observable<TrueBlueProfile> = this.storeService.getState(
    ProfileSelectors.memberProfile,
  );
  error: Observable<any> = this.storeService.getState(ProfileSelectors.error);
  profileIsLoading: Observable<boolean> = this.storeService.getState(
    ProfileSelectors.profileIsLoading,
  );
  trueBlueNumber: Observable<string> = this.storeService.getState(
    ProfileSelectors.trueBlueNumber,
  );
  dateOfBirth: Observable<string> = this.storeService.getState(
    ProfileSelectors.dateOfBirth,
  );

  travelBankBalance: Observable<string> = this.storeService.getState(
    ProfileSelectors.travelBankBalance,
  );

  constructor(private storeService: StoreService) {}

  loadProfile(authToken: string) {
    return new LoadProfile({ authToken });
  }

  profileLoaded(profile: TrueBlueProfile) {
    this.storeService.dispatchAction(new ProfileLoaded({ profile }));
  }

  dispatchFetchRwbProfile(accessToken: string, xAuthToken?: string): void {
    this.storeService.dispatchAction(
      new RwbProfileRequested(accessToken, xAuthToken),
    );
  }

  dispatchRwbProfileSuccess(response: BookProfile): void {
    this.storeService.dispatchAction(new RwbProfileSucceeded(response));
  }

  dispatchRwbProfileFailure(): void {
    this.storeService.dispatchAction(new RwbProfileFailed());
  }

  dispatchPayDataSuccessAction(payData: Pay[]): void {
    this.storeService.dispatchAction(new LoggedInPaySucceeded(payData));
  }
}
