export enum EventEnum {
  onclick = 'onclick',
  wheel = 'wheel',
  scroll = 'scroll',
  mouseenter = 'mouseenter',
  mousemove = 'mousemove',
  mousedown = 'mousedown',
  keyup = 'keyup',
  touchstart = 'touchstart',
  load = 'load',
}
