import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { WidgetService } from '@core/widgets';
import { Theme } from '@store/global/types/layout-theme.enum';

import { BodyText } from './types';

/**
 * @description formatted container for text with optional button and/or link
 * @WhereIsItUsed many components
 */
@Component({
  selector: 'jb-body-text',
  templateUrl: './jb-body-text.component.html',
  styleUrls: ['./jb-body-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JBBodyTextComponent {
  @Input('content') bodyTextContent: BodyText[];
  themeEnum = Theme;
  @Input('theme') theme = this.themeEnum.light;
  @HostBinding('class.dark-theme') get color() {
    return this.theme === this.themeEnum.dark;
  }
  // This input is for grid alignment when inside an accordion
  @Input('insideAccordion') insideAccordion = false;

  constructor(private widgetService: WidgetService) {}

  onClick(event: Event, showChat, isCookieConsent): void {
    if (showChat === 'true') {
      event.preventDefault();
      this.widgetService.openASAPP();
    }

    if (isCookieConsent === 'true') {
      this.widgetService.openCookieConsent();
    }
  }
}
