import { Legend } from '@shared/legend';

import { CitySelector } from '../../city-selector/types/city-selector.type';

export interface CityPairSelector {
  swapLabel: string;
  from: { [key: string]: string };
  to: { [key: string]: string };
  legends?: Legend | Legend[];
  citySelector: CitySelector;
  browseByRegions?: string;
  /*
   * If ValueType is set to browseByRegions, the browseByRegions field in this object will have the title,
   * if it is pageLink, the title field will
   */
  valueType: 'browseByRegions' | 'pageLink';
  title?: string;
  /*
   * this is the url that you navigate to in the instance in which valueType is pageLink
   */
  page?: string;
  enableFuzzySearch: string;
}

const DEFAULT_PAIR_VALUES = {
  FROM_AIRPORT: 'NYC',
  TO_AIRPORT: undefined,
};

export const CITY_PAIR_CONFIG = {
  DEFAULT_PAIR_VALUES,
};
