import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { AppConfigService, HttpService } from '../../../core';
import { ItineraryFacade } from '../itinerary.facade';
import { Flight } from '../types';

@Injectable({ providedIn: 'root' })
export class ItineraryApi {
  constructor(
    private httpService: HttpService,
    private itineraryFacade: ItineraryFacade,
    private appConfig: AppConfigService,
  ) {}

  itineraryCheck(): Observable<Flight[]> {
    const headers = new HttpHeaders({
      apiKey: this.appConfig.myTripsServiceApiKey,
    });
    const url = this.getUpcomingTripsUrl();

    return this.httpService.get<any>(url, { headers }, true, false).pipe(
      timeout(5000),
      catchError(res => {
        return of({ error: res });
      }),
    );
  }

  getUpcomingTripsUrl(): string {
    return `${this.appConfig.myTripsServiceUrl}/mytrips/v2/upcoming?hourLimit=168&noOfTrips=1`;
  }
}
