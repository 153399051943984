<div
  class="new-header book-subnav-wrapper w-100 list pa0 pt7-l pb6-l pt4-m mv0 mb4-m"
>
  <div class="jb-grid gr-unset gap-none-s fw4">
    <div class="col-8-l col-12-m bottom-divider pb3-l pb5-m">
      <!-- bubble on the left -->
      <ul class="pl0 list jb-grid">
        <li
          *ngFor="let link of subNav.section.leftSection.children"
          class="col-4-ns flex-ns justify-center-ns"
        >
          <jb-bubble-link
            [smaller]="true"
            class="tc-ns"
            [link]="link"
            [isMobile]="isMobile"
          ></jb-bubble-link>
        </li>
      </ul>
    </div>

    <!-- Links on the right -->
    <div class="pl4-l w-100 left-divider col-4-l">
      <ul
        class="pl0 list right-book-list mt0 mt2-ns mb2 mh0 mv0-ns jb-grid gap-row-none"
        [class.grid-auto-rows-mobile]="windowService.isMobile | async"
      >
        <li
          *ngFor="let link of subNav.section.rightSection.children"
          class="col-4-m"
        >
          <jb-icon-link [link]="link"></jb-icon-link>
        </li>
      </ul>
      <div class="tb-links-text mt1-ns f6 slate-gray mb3 mb0-ns">
        {{ subNav.section.rightSection.special.copy }}
      </div>
    </div>
  </div>
</div>
