import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogTempModule } from '../dialog';
import { JBInnerHtmlModule } from '../jb-inner-html';
import { UIModule } from '../ui/ui.module';
import { PromoLegalModalDialogComponent } from './promo-legal-modal-dialog.component';

const DECLARATIONS = [PromoLegalModalDialogComponent];
const MODULES = [CommonModule, DialogTempModule, JBInnerHtmlModule, UIModule];

@NgModule({
  imports: [...MODULES],
  exports: [...DECLARATIONS],
  declarations: [...DECLARATIONS],
})
export class PromoLegalModalDialogModule {
  static entry = PromoLegalModalDialogComponent;
}
