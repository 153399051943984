import { InjectionToken } from '@angular/core';

import { AppConfigGlobal } from './app-config';
import { CMSSitemapWrapper } from './cms/types';

export type InjectionTokenIsWebComponent =
  | 'header'
  | 'footer'
  | 'booker'
  | false;

export const LOCAL_STORAGE = new InjectionToken<Storage>('LOCAL_STORAGE');
export const SESSION_STORAGE = new InjectionToken<Storage>('SESSION_STORAGE');
export const WINDOW = new InjectionToken<Window>('WINDOW');
export const DOCUMENT = new InjectionToken<Document>('DOCUMENT');
export const IS_WEB_COMPONENT =
  new InjectionToken<InjectionTokenIsWebComponent>('IS_WEB_COMPONENT');

export const HOST = new InjectionToken<string>('HOST');
export const ENV_CONFIG = new InjectionToken<AppConfigGlobal>('ENV_CONFIG');
export const INITIAL_SITEMAP = new InjectionToken<CMSSitemapWrapper>(
  'INITIAL_SITEMAP',
);
