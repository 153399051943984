<ng-container *ngIf="template !== 'article' && template !== 'promo'">
  <div
    class="jb-page-header bb b--medium-gray"
    *ngIf="pageHeader && !primaryHeroData"
  >
    <div class="page-margin">
      <div class="jb-max-width center">
        <jb-page-header
          class="nmt5"
          [pageHeaderData]="pageHeader"
          [sectionsData]="sectionHeader"
          [chapterSelectorHeader]="chapterSelectorHeader"
          [includeChapterSelector]="includeChapterSelector"
        >
        </jb-page-header>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="template === 'article'">
  <div class="jb-page-header bb b--medium-gray" *ngIf="pageHeader">
    <div class="page-margin">
      <div class="jb-max-width center">
        <jb-page-header
          jbSitemapNestedLinker
          [pageHeaderData]="pageHeader"
        ></jb-page-header>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="template === 'promo'">
  <div class="jb-page-header" *ngIf="pageHeader">
    <div class="page-margin">
      <h1 class="mt4 mb6 core-blue tc">
        {{ pageHeader.title }}
      </h1>
    </div>
  </div>
</ng-container>
