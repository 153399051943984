import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  Renderer2,
} from '@angular/core';
import { AppConfigService, WINDOW, WindowService } from '@core/index';
import { Theme } from '@store/global/types/layout-theme.enum';

@Directive({
  selector: '[jbSmartLink]',
})
export class SmartLinkDirective implements AfterViewInit {
  themeEnum = Theme;
  @Input('theme') theme = this.themeEnum.light;
  links: any;
  element: any;
  externalLinkSrc: string;
  externalDocumentSrc: string;
  linkSrc: string;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private appConfig: AppConfigService,
    private windowService: WindowService,
    @Inject(WINDOW) private window,
  ) {}

  ngAfterViewInit() {
    this.externalLinkSrc = `<svg xmlns="http://www.w3.org/2000/svg" aria-label="${
      this.appConfig.linkDefaults.external
    }" width="7" height="7" viewBox="0 0 7 7"><g stroke="${
      this.theme === this.themeEnum.dark ? 'currentColor' : '#0033a0'
    }" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M.5 6.5l6-6"/><polyline points="2 .5 6.5 .5 6.5 5"/></g></svg>`;
    this.externalDocumentSrc = `<svg xmlns="http://www.w3.org/2000/svg" aria-label="${
      this.appConfig.linkDefaults.document
    }" width="8" height="10" viewBox="0 0 8 10"><g stroke="${
      this.theme === this.themeEnum.dark ? 'currentColor' : '#0033a0'
    }" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M.5 9.5h5a2 2 0 0 0 2-2V3.587L4.486.5H.5v9z"/><polyline points="4 4.25 4 .5 7 .5" transform="matrix(1 0 0 -1 0 4.75)"/></g></svg>`;
    this.element = this.elementRef;
    this.links = Array.prototype.slice.apply(
      this.element.nativeElement.getElementsByTagName('a'),
    );
    this.links.forEach((link: any) => {
      const target = link.getAttribute('target');

      if (!!target && target !== '_self') {
        this.makeLinksAccessible(link, target);
      }

      const displayASAPP = (() => {
        switch (this.appConfig.ASAPPChatInstead) {
          case 'both':
            return (
              this.windowService.checkMobileOperatingSystem() === 'iOS' ||
              this.windowService.checkMobileOperatingSystem() === 'Android'
            );
          case 'ios':
            return this.windowService.checkMobileOperatingSystem() === 'iOS';
          case 'android':
            return (
              this.windowService.checkMobileOperatingSystem() === 'Android'
            );
          default: {
            return false;
          }
        }
      })();

      if (displayASAPP) {
        const href = link.getAttribute('href');
        const isTel = href.substring(0, 4) === 'tel:';
        if (isTel) {
          const tel = href.substring(4, href.length).replace(/[^\d]/g, '');
          if (this.appConfig.ASAPPWhitelistedPhoneNumbers.includes(tel)) {
            this.displayASAPPonClick(link, tel);
          }
        }
      }
    });
  }

  displayASAPPonClick(link, tel): void {
    this.renderer.listen(link, 'click', e => {
      e.preventDefault();
      !!this.window['ASAPP'] &&
        this.window['ASAPP']('showChatInstead', {
          APIHostname: `${this.appConfig.widgets.asappConfig.hostname}`,
          AppId: `${this.appConfig.widgets.asappConfig.appId}`,
          phoneNumber: tel,
        });
    });
  }

  private makeLinksAccessible(link, target): void {
    switch (true) {
      case target === '_blank':
        this.linkSrc = this.externalLinkSrc;
        break;
      case target === '_document':
        this.linkSrc = this.externalDocumentSrc;
        break;
      default:
        break;
    }

    const el = link.innerHTML;
    link.innerHTML = el + this.linkSrc;
  }
}
