import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { values } from 'ramda';

/**
 * Function that will flag the control as Dirty
 * @param {UntypedFormGroup | AbstractControl} form form control
 * @returns {void}
 */
export function deepMarkAsDirty(
  control: AbstractControl | UntypedFormGroup,
): void {
  control.markAsDirty();
  const controls = (control as UntypedFormGroup).controls || {};
  values(controls).forEach(deepMarkAsDirty);
}
