import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { OAuthTokenService } from '@core/oauth-shared/oauth-token.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

type PostOptions = any;

@Injectable({ providedIn: 'root' })
export class TempAPITokenHandler {
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService,
    private oAuthTokenService: OAuthTokenService,
  ) {}

  getOauthToken(resource: string): Observable<any> {
    const tokenCall = this.retrieveTokenRequest(resource);
    return tokenCall.pipe(
      map(tokenResponse => {
        return new HttpHeaders({
          ...OAuthTokenService.extractTokenFromResponse(tokenResponse),
        });
      }),
    );
  }

  post(
    resource: string,
    requestUrl: string,
    requestBody: any,
    options?: PostOptions,
  ): Observable<any> {
    return this.getOauthToken(resource).pipe(
      mergeMap(headers =>
        this.httpClient.post(requestUrl, requestBody, { headers, ...options }),
      ),
    );
  }

  get(resource: string, requestUrl: string): Observable<any> {
    return this.getOauthToken(resource).pipe(
      mergeMap(headers => this.httpClient.get(requestUrl, { headers })),
    );
  }

  retrieveTokenRequest(resource: string): Observable<any> {
    const { oauthTokenApiKey, clientId, clientSecret } = this.appConfig.okta;
    return this.oAuthTokenService.postTokenRequest({
      resource,
      apiKey: oauthTokenApiKey,
      clientId,
      clientSecret,
    });
  }
}
