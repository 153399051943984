<jb-header
  *ngIf="(isCmsFailed$ | async) || (showHeader$ | async)"
  id="jb-header-id"
  [hideTb]="isCmsFailed$ | async"
  (headerLoaded)="handleHeaderLoaded()"
>
</jb-header>
<jb-header-skeleton
  *ngIf="(showHeaderSkeleton$ | async) && !(isCmsFailed$ | async)"
  [hideSuperGlobalNav]="hideSuperGlobalNav$ | async"
></jb-header-skeleton>
<main
  [attr.aria-hidden]="isModalOpen$ | async"
  [style.visibility]="(isModalOpen$ | async) ? 'hidden' : 'visible'"
>
  <ng-container *ngIf="pageData$ | async as pageData">
    <jb-sr-only
      *ngIf="
        !!pageData?.h1title && !!pageData.h1title.length && !(isBFFPage | async)
      "
    >
      <h1 id="first-focus">{{ pageData.h1title }}</h1>
    </jb-sr-only>
  </ng-container>
  <!-- router will inject dynamic component based on sitemap -->
  <!-- ngrx effect request cms page tempate -->
  <!-- router uses basci-tempate and renderer-template to render template content -->
  <router-outlet> </router-outlet>
  <!-- Base route skeletons -->
  <div *ngIf="isNavigating" [ngSwitch]="baseTemplate">
    <jb-bff-skeleton *ngSwitchCase="'best-fare-finder'"></jb-bff-skeleton>
    <jb-flights-skeleton *ngSwitchCase="'flights'"></jb-flights-skeleton>
    <jb-flights-destination-skeleton
      *ngSwitchCase="'destinations'"
    ></jb-flights-destination-skeleton>
    <jb-forgot-password-skeleton
      *ngSwitchCase="'forgot-password'"
    ></jb-forgot-password-skeleton>
    <div
      *ngSwitchDefault
      class="page-margin flex justify-center items-center vh-75"
    >
      <jb-icon
        class="fill-core-blue"
        name="loading"
        height="5em"
        width="5em"
      ></jb-icon>
    </div>
  </div>
</main>
<jb-footer
  id="jb-footer-id"
  class="jb-footer"
  *ngIf="(showFooter$ | async) || (isCmsFailed$ | async)"
  [hideLanguageSelect]="isCmsFailed$ | async"
  [attr.aria-hidden]="isModalOpen$ | async"
  [style.visibility]="(isModalOpen$ | async) ? 'hidden' : 'visible'"
>
</jb-footer>

<!-- Display AB Testing Button - Only for Lower envs -->
<div
  *ngIf="!appConfig.isProdEnv && pageCampaigns.length > 0"
  class="bg-white s-body ph1 tc ba db z-9999 fixed bottom-0 right-0"
>
  <button class="pointer" (click)="openABTestsDialog()">
    AB {{ pageCampaigns.length }}
  </button>
</div>
