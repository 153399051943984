import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';

import { JBBodyTextModule } from './../jb-body-text/jb-body-text.module';
import { UIModule } from './../ui/ui.module';
import { StaticBookerPodComponent } from './static-booker-pod.component';

@NgModule({
  imports: [CommonModule, UIModule, LinkIconModule, JBBodyTextModule],
  declarations: [StaticBookerPodComponent],
  exports: [StaticBookerPodComponent],
})
export class StaticBookerPodModule {}
