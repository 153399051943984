import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Layout, Theme } from '@store/global/types/layout-theme.enum';

import { BodyTextImage } from './types';

@Component({
  selector: 'jb-text-image-container',
  templateUrl: './body-text-image-container.component.html',
  styleUrls: ['./body-text-image-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyTextImageContainerComponent {
  @Input('content') bodyTextImage: BodyTextImage[];
  // This input is for grid alignment when inside an accordion
  @Input('insideAccordion') insideAccordion = false;
  @Input() layout: Layout;
  @Input() theme: Theme;
  layoutEnum = Layout;
}

export const bodyTextImageContainerResolver = (registry, data) => ({
  bodyTextImage: data.bodyTextWithImage,
  layout: data.layout,
  theme: data.theme,
});
