import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Optional,
} from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '@core/events/events.service';
import {
  HOST,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  WINDOW,
} from '@core/injection-tokens';

import { getPathIfInSitemap, handleRoute } from '../linker.utils';
import { SITEMAP_DICTIONARY } from '../sitemap-dictionary.token';

/**
 * Apply this in order to make any anchor tags contained within the parent scope
 * navigable via the Angular Router (a.k.a. RouterLink).
 *
 * This should only be applied to components that have children that are anchors
 * and we cannot directly access those elements in order to apply the
 * `SitemapLinkerDirective`.
 *
 * For example: Apply this a component in the Component Library that requires links.
 *
 * @example
 * <jb-breadcrumbs
 *    jbSitemapNestedLinker
 *    [item]="breadcrumbs"
 *    [theme]="pageHeaderCrumbTheme">
 * </jb-breadcrumbs>
 *
 * In the case above, we want the links in the breadcrumb to use `routerLink` if
 * possible, but we cannot use `SitemapLinkerDirective` to hook up the
 * `SITEMAP_DICTIONARY` because we do not have direct access to the anchor tags.
 */

@Directive({
  selector: '[jbSitemapNestedLinker]:not(a)',
})
export class SitemapNestedLinkerDirective implements AfterViewInit {
  readonly SAFE_REL = 'noopener noreferrer';
  anchors: HTMLLinkElement[];

  constructor(
    @Inject(SITEMAP_DICTIONARY) @Optional() private sitemapDictionary,
    @Inject(HOST) private host: string,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
    @Inject(WINDOW) private window,
    private eventsService: EventsService,
    public element: ElementRef,
    private router: Router,
  ) {}

  ngAfterViewInit() {
    this.anchors = Array.prototype.slice.apply(
      this.element.nativeElement.querySelectorAll('a[href]:not([routerlink])'),
    );
    this.anchors.forEach(anchor => {
      this.assignLinkAttribute(anchor);
    });
  }

  bindOnClick(path: string, url: URL) {
    return function (link: string, boundUrl: string, event: MouseEvent) {
      this.redirect(link, boundUrl, event);
    }.bind(this, path, url);
  }

  bindOnFocus(anchor: HTMLLinkElement, path: string, url: URL) {
    return function (link: string, boundUrl: string) {
      anchor.onkeydown = (event: KeyboardEvent) => {
        if (event.keyCode === 13) {
          // watch for "enter"
          this.redirect(link, boundUrl);
          event.preventDefault();
        }
      };
    }.bind(this, path, url);
  }

  bindOnBlur(anchor: HTMLLinkElement) {
    return function () {
      anchor.onkeydown = undefined;
    };
  }

  redirect(routerLink: string, url: URL, event: MouseEvent): void {
    handleRoute(
      this.isWebComponent,
      this.eventsService,
      url && url.toString(),
      routerLink,
      url,
      this.router,
      {
        event,
        window: this.window,
        safeRel: this.SAFE_REL,
      },
    );
  }

  private assignLinkAttribute(anchor: HTMLLinkElement) {
    const hrefValue = anchor.getAttribute('href');

    // No need to parse further
    if (hrefValue.startsWith('mailto:') || hrefValue.startsWith('tel:')) {
      return;
    }

    if (this.isWebComponent === 'header') {
      const originalHref = new URL(anchor.href);
      const path = getPathIfInSitemap(
        originalHref,
        this.sitemapDictionary,
        this.host,
      );
      anchor.removeAttribute('href');
      const href = path ? undefined : originalHref;
      anchor.onclick = this.bindOnClick(path, href);
      anchor.onfocus = this.bindOnFocus(anchor, path, href);
      anchor.onblur = this.bindOnBlur(anchor);
    } else {
      const anchorTarget = anchor.getAttribute('target');
      let anchorHref = anchor.getAttribute('href');
      const isTargetExternal =
        anchorTarget === '_blank' || anchorTarget === '_document';
      anchor.setAttribute('rel', this.getRel(isTargetExternal));
      if (this.sitemapDictionary && !isTargetExternal) {
        if (anchorHref.startsWith('/')) {
          anchorHref = `${this.host}${anchorHref}`;
        }
        if (anchorHref.startsWith('#') || anchorHref.startsWith('?')) {
          anchorHref = `${this.host}${this.window.location.pathname}${anchorHref}`;
        }

        try {
          if (anchorHref) {
            const originalHref = new URL(anchorHref);
            const path = getPathIfInSitemap(
              originalHref,
              this.sitemapDictionary,
              this.host,
            );
            if (path) {
              anchor.onclick = this.bindOnClick(path, originalHref);
              anchor.onfocus = this.bindOnFocus(anchor, path, originalHref);
              anchor.onblur = this.bindOnBlur(anchor);
            }
          }
        } catch (e) {
          // console.error(`Invalid link: "${anchor.href}":`);
          // console.error(e);
        }
      }
    }
  }

  private getRel(isTargetExternal: boolean): string {
    if (!isTargetExternal) {
      return null;
    }
    return this.SAFE_REL;
  }
}
