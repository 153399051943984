import { Component, Input } from '@angular/core';

import { trueBlueLogo } from './trueblue.logo';
import { TrueblueResponse } from './types';

/**
 * @description Wrapper for the login form
 * @WhereIsItUsed sign-in-page
 */
@Component({
  selector: 'jb-trueblue',
  templateUrl: './trueblue.component.html',
  styleUrls: ['./trueblue.component.scss'],
})
export class TrueblueComponent {
  @Input() isSignInPage = false;
  @Input() nextPage = '';
  @Input() trueBlueResponse: TrueblueResponse;

  // TODO: Replace icon after it's been added to component library
  trueblueLogo = trueBlueLogo;
}
