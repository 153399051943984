import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { DateService } from '@core/date/date.service';
import { DOCUMENT, WINDOW } from '@core/injection-tokens';
import { fromEvent, interval, merge, Observable, Subscription } from 'rxjs';
import {
  debounce,
  filter,
  first,
  last,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { AuthFacade } from '../auth.facade';
import { EventAction, EventEnum, OktaAccessTokenEntry } from '../types';

const INTERVAL = 1000;

@Injectable({ providedIn: 'root' })
export class AuthTimerService {
  // DOM and WINDOW events related to user actions
  private inactivityEvents: EventAction[] = [
    [this.document, EventEnum.onclick],
    [this.document, EventEnum.wheel],
    [this.document, EventEnum.scroll],
    [this.document, EventEnum.mouseenter],
    [this.document, EventEnum.mousemove],
    [this.document, EventEnum.mousedown],
    [this.document, EventEnum.keyup],
    [this.document, EventEnum.touchstart],
  ];
  private accessTokenExpiryTime: number;
  private timerSubscription: Subscription;
  private userEventSubscription: Subscription;
  private timerStartTimeStamp: number;

  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) private window,
    private authFacade: AuthFacade,
    private appConfig: AppConfigService,
    private dateService: DateService,
  ) {
    this.authFacade.accessToken
      .pipe(
        filter(token => Boolean(token) && Boolean(token.requestedAt)),
        first(),
      )
      .subscribe((accessToken: OktaAccessTokenEntry) => {
        this.setDefaultAccessTokenExpiryTime(
          accessToken.expiresAt,
          accessToken.requestedAt,
        );

        this.setTimerStartTimeStamp();
        this.startAuthTimer();
      });
  }

  startAuthTimer(): void {
    if (this.timerSubscription && !this.timerSubscription.closed) {
      return;
    }

    this.timerSubscription = this.timerObservable().subscribe(() => {
      this.authFacade.dispatchedUserSessionIdledAction();
    });
  }

  stopAuthTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  subscribeUserEvents(): void {
    this.userEventSubscription = this.createUserEventsStream()
      .pipe(first())
      .subscribe(() => {
        this.authFacade.dispatchedUserSessionRequestAction();
      });
  }

  unsubscribeUserEvents(): void {
    if (this.userEventSubscription) {
      this.userEventSubscription.unsubscribe();
    }
  }

  getTimerExpiry(): number {
    return this.timerStartTimeStamp + this.accessTokenExpiryTime * 1000;
  }

  setTimerStartTimeStamp(): void {
    this.timerStartTimeStamp = this.dateService.getNewDate().getTime();
  }

  private createUserEventsStream(): Observable<Event> {
    const fromEventArray: Observable<Event>[] = [];

    this.inactivityEvents.forEach((event: EventAction) =>
      fromEventArray.push(fromEvent(event[0], event[1])),
    );

    return merge(...fromEventArray);
  }

  private setDefaultAccessTokenExpiryTime(
    expiresAt: number,
    requestedAt: number,
  ): void {
    const expiryDate = new Date(expiresAt * 1000);

    this.accessTokenExpiryTime =
      Math.ceil((expiryDate.getTime() - requestedAt) / 1000) -
      this.appConfig.okta.auth.expire_early_time;
  }

  private timerObservable(): Observable<number> {
    return this.createUserEventsStream().pipe(
      startWith(true),
      debounce(() => interval(300)),
      tap(() => this.setTimerStartTimeStamp()),
      switchMap(() =>
        interval(INTERVAL).pipe(take(this.accessTokenExpiryTime), last()),
      ),
    );
  }
}
