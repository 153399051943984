import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BestFareDay, WindowSize } from '@core/index';
import { TravelerGroup } from '@dynamic-components/booker-v2/shared/traveler-selector/types';
import { DynamicLabel } from '@shared/types';
import { getDynamicLabel } from '@shared/ui/utils/global-utils/get-dynamic-label';
import { map, sum } from 'ramda';

@Pipe({
  name: 'jbPointsCashPipe',
})
export class PointsWithCashPipe implements PipeTransform {
  transform(
    totalFares: BestFareDay[],
    isPoints = false,
    screenSize: WindowSize = { large: true },
    pointsLabel: DynamicLabel,
    currencyCode = 'USD',
    travelers: TravelerGroup = { adults: 1, children: 0, infants: 0 },
    toFixed = 2,
  ): string {
    const multFactor = travelers.adults + travelers.children;
    const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');
    if (totalFares && totalFares.length) {
      const { amount, tax } = this.sumFaresField(totalFares);
      if (isPoints) {
        const unitMapping = !!screenSize.small ? 1000 : 1;
        const pointsOnScreen = amount / unitMapping;
        const label = getDynamicLabel(pointsLabel, screenSize);
        return pointsOnScreen != null
          ? `${(
              pointsOnScreen * multFactor
            ).toLocaleString()} ${label} + ${currencySymbol}${(
              tax * multFactor
            ).toFixed(toFixed)}`
          : `0 ${label}`;
      }
      const val = !!amount ? amount * multFactor : 0;
      return `${currencySymbol}${val.toFixed(toFixed)}`;
    }
    const emptyTotal = 0;
    return emptyTotal.toFixed(toFixed);
  }

  sumPropertyFieldInArray(arr: any[], field): number {
    return sum(map((e: any) => e[field], arr));
  }

  sumFaresField(fares: BestFareDay[]) {
    return {
      amount: this.sumPropertyFieldInArray(fares, 'amount'),
      tax: this.sumPropertyFieldInArray(fares, 'tax'),
    };
  }
}
