import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { extractAirportHashMap } from '@store/origins/api';
import { AppState } from '@store/shared/types/app-state.type';
import { addDays, format } from 'date-fns';

import { FlightTrackerState } from './types';

export const flightTracker: MemoizedSelector<AppState, FlightTrackerState> =
  createFeatureSelector('flightTracker');

export const getAllFlightData = createSelector(
  flightTracker,
  state => state.flightResults,
);

export const selectedTabId = createSelector(
  flightTracker,
  state => state.selectedTabId,
);

export const isFormSubmitting = createSelector(
  flightTracker,
  state => state.formSubmitting,
);

export const originAirportHashMap = createSelector(flightTracker, state =>
  extractAirportHashMap(state.originAirports['originAirports']),
);

export const destinationAirportHashMap = createSelector(
  flightTracker,
  state => {
    return extractAirportHashMap(
      state.destinationAirports['destinationAirports'],
    );
  },
);

export const flightTrackerResponse = createSelector(
  flightTracker,
  state => state.flightTrackerResponse,
);

export const today = createSelector(flightTracker, state => state.today);

export const dateOptions = createSelector(
  today,
  flightTrackerResponse,
  (today, flightTrackerResponse) =>
    [
      {
        id: flightTrackerResponse.yesterday,
        date: addDays(today, -1),
      },
      {
        id: flightTrackerResponse.today,
        date: today,
      },
      {
        id: flightTrackerResponse.tomorrow,
        date: addDays(today, 1),
      },
    ].map(({ id, date }) => ({
      id,
      name: `${id}, ${format(date, 'EEE MMM d')}`,
      value: format(date, 'yyyy-MM-dd'),
    })),
);
