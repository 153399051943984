import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogTempModule } from '../dialog';
import { VacationLegalModalDialogComponent } from './vacation-legal-modal-dialog.component';

const DECLARATIONS = [VacationLegalModalDialogComponent];
const MODULES = [CommonModule, DialogTempModule];

@NgModule({
  imports: [...MODULES],
  exports: [...DECLARATIONS],
  declarations: [...DECLARATIONS],
})
export class VacationLegalModalDialogModule {}
