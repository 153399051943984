import { SearchSection } from '@core/cms/types/cms-footer.response.type';
import { JbAutocompleteOptionsGroup } from 'jb-component-library';

export interface SearchBoxComponentState {
  content: SearchSection;
  userSearch: string;
  insertedOptions: JbAutocompleteOptionsGroup[];
  isInFooter: boolean;
  isInHero: boolean;
  isHelpPage: boolean;
  heroExists: boolean;
  groups: JbAutocompleteOptionsGroup[];
}

export const SearchBoxComponentInitialState: SearchBoxComponentState = {
  content: undefined,
  userSearch: undefined,
  insertedOptions: [],
  isInFooter: false,
  isInHero: false,
  isHelpPage: false,
  heroExists: false,
  groups: [],
};

export type SearchBoxContainerViewModel = SearchBoxComponentState;
