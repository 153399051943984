import {
  PreferenceCenterActions,
  PreferenceCenterActionsUnion,
} from './preference-center.actions';

export const initialPreferenceCenterState = {
  personalInfo: null,
  profilePicture: null,
  travelPreferences: null,
  futureReservationStatus: null,
  paymentInfo: null,
  paymentInfoApiError: null,
};

export function preferenceCenterReducer(
  state = initialPreferenceCenterState,
  action: PreferenceCenterActionsUnion,
) {
  switch (action.type) {
    case PreferenceCenterActions.STORE_MEMBER_DATA:
      return {
        ...state,
        personalInfo: action.payload,
      };
    case PreferenceCenterActions.STORE_PROFILE_PICTURE:
      return {
        ...state,
        profilePicture: action.payload,
      };
    case PreferenceCenterActions.STORE_MEMBER_PREFERENCES:
      return {
        ...state,
        travelPreferences: action.payload,
      };
    case PreferenceCenterActions.STORE_FUTURE_RESERVATION_STATUSE:
      return {
        ...state,
        futureReservationStatus: action.payload,
      };
    case PreferenceCenterActions.STORE_MEMBER_PAYMENT:
      return {
        ...state,
        paymentInfo: action.payload?.Response?.TravelBankBalance
          ? action.payload
          : {
              Response: {
                ...action.payload?.Response,
                TravelBankBalance: { BalanceRS: { AccountBalance: 0 } },
              },
            },
      };
    case PreferenceCenterActions.GET_MEMBER_PAYMENT:
      return {
        ...state,
        paymentInfo: false,
      };
    default:
      return state;
  }
}
