import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BookerResponse, WarningMessage } from '@shared/booker/types';
import { WarningTypeEnum } from '@shared/booker/utils';
import { JbDialogService } from 'jb-component-library';
import { Subject, Subscription } from 'rxjs';

/**
 * @description Display a warning dialog with provided information for use when booking a flight
 * @WhereIsItUsed
 * - booker air
 * - recent searches
 * - bff
 */
@Component({
  selector: 'jb-booker-air-warning-dialog',
  templateUrl: './booker-air-warning-dialog.component.html',
  styleUrls: ['./booker-air-warning-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JBBookerAirWarningDialogComponent implements OnDestroy {
  content: BookerResponse['warningMessages'];
  shouldContinue$ = new Subject<boolean>();
  showExternalLink = false;
  warningIndex = 0;
  warningTypesEnum = WarningTypeEnum;
  warningsContent: WarningMessage[];

  private subscription: Subscription = new Subscription();

  constructor(public dialogService: JbDialogService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleData = (
    content: BookerResponse['warningMessages'],
    warningsContent: WarningMessage[],
  ) => {
    this.content = content;
    this.warningsContent = warningsContent;
  };

  nextWarning() {
    this.warningIndex++;
  }

  // emit continue
  continue(shouldContinue: boolean, event) {
    event.stopPropagation();
    const dialogInstance = this.dialogService.getComponentReference();
    dialogInstance.shouldContinue$.next(shouldContinue);
  }
}
