<a
  #logoLink
  tabindex="0"
  [jbSitemapLinker]="logoData ? logoData.href : '/'"
  class="db"
  [target]="logoData?.newWindow"
>
  <img
    [hidden]="logoData && cmsLogo.complete"
    src="https://www.jetblue.com/ui-assets/jetblue-logo.svg"
    height="32"
    width="80"
    class="m-auto"
    alt="JetBlue Home"
  />
  <img
    #cmsLogo
    [hidden]="!logoData || !cmsLogo.complete"
    [src]="logoData?.image?.src || ''"
    height="32"
    width="80"
    class="m-auto"
    [alt]="logoData?.image?.alt"
  />
</a>
