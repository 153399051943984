import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { AppState } from '@store/shared/types/app-state.type';

import { MfaState } from './types/mfa-state.type';

export const mfa: MemoizedSelector<AppState, MfaState> =
  createFeatureSelector('mfa');
export const userFactors = createSelector(mfa, state => state.userFactors);

export const isMfaFactorsLoading = createSelector(
  mfa,
  state => state.isMfaFactorsLoading,
);

export const isMfaGroupsLoading = createSelector(
  mfa,
  state => state.isMfaGroupsLoading,
);

export const mfaFactorsResponseCode = createSelector(
  mfa,
  state => state.mfaFactorsResponseCode,
);

export const mfaGroupsResponseCode = createSelector(
  mfa,
  state => state.mfaGroupsResponseCode,
);
