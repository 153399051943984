import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogTempModule } from '@shared/dialog';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { DOTDebugContentComponent } from './debug-content.component';

const MODULES = [CommonModule, NgxJsonViewerModule, DialogTempModule];

const DECLARATIONS = [DOTDebugContentComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class DOTDebugContentModule {}
