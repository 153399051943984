<ng-container *ngIf="icon && icon.src">
  <jb-icon
    *ngIf="icon.isUrlSrc"
    [iconSourceUrl]="icon.src"
    [label]="icon.alt"
  ></jb-icon>
  <jb-icon
    *ngIf="!icon.isUrlSrc"
    [name]="icon.src"
    [label]="icon.alt"
  ></jb-icon>
</ng-container>

<jb-image
  *ngIf="img && img.src"
  [src]="img.src"
  [alt]="img.alt"
  [isLazy]="enableLazyLoadImage"
></jb-image>

<h3 class="l-heading mt4">{{ title }}</h3>
<p class="mt4 body">{{ body }}</p>

<span *ngIf="link && link.href" class="mt3">
  <ng-container *ngIf="!!link.isExternal === false">
    <a jbActionLink [routerLink]="link.href">
      <span>{{ link.text || link.href }}</span>
      <jb-icon class="pl1" name="rightFilled"></jb-icon>
    </a>
  </ng-container>
  <ng-container *ngIf="!!link.isExternal">
    <a jbActionLink [jbSitemapLinker]="link.href">
      <span>{{ link.text || link.href }}</span>
      <dot-link-icon newWindow="_blank"></dot-link-icon>
    </a>
  </ng-container>
</span>
