// eslint-disable max-classes-per-file

import { IdxTransaction, TokenResponse, Tokens } from '@okta/okta-auth-js';

import { Action } from '../shared/types/action.interface';
import {
  AuthProfile,
  LeanProfile,
  LoginCredentials,
  OktaPreExistingSessionCheckStatus,
} from './types';

export enum AuthActions {
  LOGIN_USER_OKTA = '[Sign-in Page] Login User Okta',
  LOGOUT_USER_GUARD = '[Route Guard] Logout User',
  OKTA_REDIRECT_TO_LOGOUT_PAGE = 'Okta Redirect To Logout Page',
  OKTA_USER_LOGGED_IN = 'Okta User Logged In',
  FINISH_POST_LOGIN_SETUP = 'Okta User Terms and Conditions Success',
  PROMPT_ACCEPTANCE = 'Okta Prompt Terms and Conditions',
  OKTA_START_LOGOUT = 'Okta Start Logout User',
  OKTA_START_LOGGING_OUT_CLASSIC = 'Okta Classic Start Logging User Out',
  OKTA_START_LOGGING_OUT_IDX = 'Okta Idx Start Logging User Out',
  OKTA_USER_LOGGED_OUT_SUCCESS = 'Okta User Logged Out Success',
  OKTA_USER_LOGGED_OUT_FAILURE = 'Okta User Logged Out Failure',
  OKTA_UID_VALUE = 'Okta UID value',
  OKTA_CLASSIC_REDIRECT = '[Sign-in Page] Redirect to Okta Classic',
  OKTA_IDX_REDIRECT = '[Sign-in Page] Idx Redirect to Okta',
  OKTA_SET_TOKEN = '[OKTA] OKTA_SET_TOKEN',
  OKTA_SET_TOKEN_SUCCESS = '[OKTA] OKTA_SET_TOKEN_SUCCESS',
  OKTA_SET_TOKEN_FAILURE = '[OKTA] OKTA_SET_TOKEN_FAILURE',
  OKTA_RENEW_TOKEN = '[OKTA] OKTA_RENEW_TOKEN',
  OKTA_RENEW_TOKEN_SUCCESS = '[OKTA] OKTA_RENEW_TOKEN_SUCCESS',
  OKTA_RENEW_TOKEN_FAILURE = '[OKTA] OKTA_RENEW_TOKEN_FAILURE',
  AUTHORIZE_USER = '[OKTA] AUTHORIZE_USER',
  LEAN_PROFILE_REQUESTED = '[OKTA] LEAN PROFILE REQUESTED',
  LEAN_PROFILE_SUCCEEDED = '[OKTA] LEAN PROFILE SUCCEEDED',
  LEAN_PROFILE_FAILED = '[OKTA] LEAN PROFILE FAILED',
  LEAN_PROFILE_PENDING = '[OKTA] LEAN PROFILE PENDING',
  OKTA_PRE_EXISTING_SESSION_STATUS_UPDATE = '[OKTA] Pre-existing session check update',
  USER_SESSION_IDLED = '[OKTA] User Session Idled',
  USER_SESSION_REQUESTED = '[OKTA] User Session Requested',
  LOGOUT_USER_FOR_HEADER = '[OKTA] Logout User For Header',
}

export class AuthorizeUser implements Action {
  readonly type = AuthActions.AUTHORIZE_USER;
}

export class LoginUserOkta implements Action {
  readonly type = AuthActions.LOGIN_USER_OKTA;
  constructor(public payload: LoginCredentials) {}
}

export class OktaRedirectToLogoutPage implements Action {
  readonly type = AuthActions.OKTA_REDIRECT_TO_LOGOUT_PAGE;
}

export class UserLoggedInOktaFlow implements Action {
  readonly type = AuthActions.OKTA_USER_LOGGED_IN;
  constructor(
    public payload: { tokens?: Tokens } & { sessionToken?: string },
  ) {}
}

export class UserPostLoginFlow implements Action {
  readonly type = AuthActions.OKTA_USER_LOGGED_IN;
  constructor(
    public payload: { tokens?: Tokens } & { sessionToken?: string },
  ) {}
}

export class UserTermsAndConditionsFlow implements Action {
  readonly type = AuthActions.PROMPT_ACCEPTANCE;
  constructor(public payload: { returnUrl: string }) {}
}

export class StartLogoutUserOktaFlow implements Action {
  readonly type = AuthActions.OKTA_START_LOGOUT;
}

export class StartLoggingOutClassic implements Action {
  readonly type = AuthActions.OKTA_START_LOGGING_OUT_CLASSIC;
}

export class StartLoggingOutIdx implements Action {
  readonly type = AuthActions.OKTA_START_LOGGING_OUT_IDX;
}

export class UserLoggedOutOktaSuccess implements Action {
  readonly type = AuthActions.OKTA_USER_LOGGED_OUT_SUCCESS;
}

export class UserLoggedOutOktaFailure implements Action {
  readonly type = AuthActions.OKTA_USER_LOGGED_OUT_FAILURE;
  constructor(public err: any) {}
}

export class OktaUIDValue implements Action {
  readonly type = AuthActions.OKTA_UID_VALUE;
  constructor(public payload: any) {}
}

export class OktaClassicRedirect implements Action {
  readonly type = AuthActions.OKTA_CLASSIC_REDIRECT;
  constructor(public payload: string) {}
}

export class OktaIdxRedirect implements Action {
  readonly type = AuthActions.OKTA_IDX_REDIRECT;
  constructor(public payload: { tokens?: Tokens }) {}
}

export class OktaRenewToken implements Action {
  readonly type = AuthActions.OKTA_RENEW_TOKEN;
  constructor(public key: string) {}
}

export class OktaRenewTokenSuccess implements Action {
  readonly type = AuthActions.OKTA_RENEW_TOKEN_SUCCESS;
  constructor(
    public key: string,
    public token: object,
  ) {}
}

export class OktaRenewTokenFailure implements Action {
  readonly type = AuthActions.OKTA_RENEW_TOKEN_FAILURE;
  constructor(public err: any) {}
}

export class OktaSetToken implements Action {
  readonly type = AuthActions.OKTA_SET_TOKEN;
  constructor(public sessionId?: string) {}
}

export class OktaSetTokenSuccess implements Action {
  readonly type = AuthActions.OKTA_SET_TOKEN_SUCCESS;
  constructor(
    public oktaResponse: TokenResponse | IdxTransaction | { tokens: Tokens },
  ) {}
}

export class OktaSetTokenFailure implements Action {
  readonly type = AuthActions.OKTA_SET_TOKEN_FAILURE;
  constructor(public err: any) {}
}

export class LeanProfileRequested implements Action {
  readonly type = AuthActions.LEAN_PROFILE_REQUESTED;
  constructor(public accessToken: string) {}
}

export class LeanProfileSuceeded implements Action {
  readonly type = AuthActions.LEAN_PROFILE_SUCCEEDED;
  constructor(
    public payload: {
      response: LeanProfile;
      authProfile: AuthProfile;
      requestToken?: string;
    },
  ) {}
}

export class LeanProfileFailed implements Action {
  readonly type = AuthActions.LEAN_PROFILE_FAILED;
  constructor(public payload: any) {}
}

export class LeanProfilePending implements Action {
  readonly type = AuthActions.LEAN_PROFILE_PENDING;
  constructor(public payload: boolean) {}
}
export class OktaPreExistingSessionCheckStatusUpdate implements Action {
  readonly type = AuthActions.OKTA_PRE_EXISTING_SESSION_STATUS_UPDATE;
  constructor(public payload: OktaPreExistingSessionCheckStatus) {}
}

export class UserSessionIdled implements Action {
  readonly type = AuthActions.USER_SESSION_IDLED;
}

export class UserSessionRequested implements Action {
  readonly type = AuthActions.USER_SESSION_REQUESTED;
}

export class LogoutUserForHeader implements Action {
  readonly type = AuthActions.LOGOUT_USER_FOR_HEADER;
}

export type AuthActionsUnion =
  | StartLogoutUserOktaFlow
  | StartLoggingOutClassic
  | UserLoggedOutOktaSuccess
  | UserLoggedOutOktaFailure
  | OktaUIDValue
  | UserLoggedInOktaFlow
  | OktaRedirectToLogoutPage
  | OktaClassicRedirect
  | OktaRenewToken
  | OktaRenewTokenSuccess
  | OktaRenewTokenFailure
  | OktaSetToken
  | OktaSetTokenSuccess
  | OktaSetTokenFailure
  | LeanProfileRequested
  | LeanProfileSuceeded
  | LeanProfileFailed
  | LeanProfilePending
  | OktaPreExistingSessionCheckStatusUpdate
  | UserSessionIdled
  | UserSessionRequested
  | LoginUserOkta
  | LogoutUserForHeader;
