import { keyBy, TypedDictionary } from '@core/utils';
import moize from 'moize';
import {
  clone,
  complement,
  compose,
  concat,
  dissoc,
  filter,
  find,
  flatten,
  groupBy,
  map,
  pathOr,
  pluck,
  prop,
  propEq,
  sortBy,
  uniq,
} from 'ramda';
import { scan } from 'rxjs/operators';

import {
  Airport,
  CountryWithAirportCodes,
  CountryWithAirports,
  Region,
  RegionsResponse,
} from '../types';
import { AirportListAndMap } from '../types/airport-list-and-map.type';

/**
 * Determines if matching origin and destination code
 * @param originCode { string } origin airport code
 * @param destinationCode { string } destination airport code
 * @returns { boolean } result of equality
 */
export const isCityPairRestricted = moize(
  (originCode: string, destinationCode: string): boolean => {
    if (!originCode || !destinationCode) {
      return false;
    }
    return originCode === destinationCode;
  },
);

/**
 * const to sort suggested airports.
 * heuristic 1: jb cities first
 * heuristic 2: sort alphabetically
 * @param airports { Airport[] } airports a list of airports.
 * @param separatePartnerCities { boolean } Separate partner cities from jb cities
 * @returns Airport[] sorted airports, jetblue cities on top, then sorted by
 * name.
 */
export const sortSuggestedAirports = moize(
  (airports: Airport[], separatePartnerCities: boolean = true): Airport[] => {
    if (separatePartnerCities) {
      const groupedList = compose(
        groupBy((airport: Airport) => airport.jb.toString()),
        sortBy(prop('name')),
      )(
        airports.filter(
          airport => !!airport && complement(propEq('jb', undefined)),
        ),
      ) as {
        [key: string]: Airport[];
      };
      return [...(groupedList['true'] || []), ...(groupedList['false'] || [])];
    } else {
      return sortBy(prop('name'))(airports);
    }
  },
);

/**
 * Sorts a collection of countries
 * heuristic 1: united states first
 * heuristic 2: remaining countries should be sorted alphabetically
 * @param countries { CountryWithAirports[] } an array of countries
 * @returns { CountryWithAirports[] } sorted array of countries
 */
export const sortSuggestedCountries = moize(
  (countries: CountryWithAirports[]): CountryWithAirports[] => {
    if (!countries.length) {
      return countries;
    }
    let americaIndex;
    for (const country of countries) {
      if (country.code === 'US') {
        americaIndex = countries.indexOf(country);
        break;
      }
    }
    const america: CountryWithAirports[] = countries.splice(americaIndex, 1);
    countries = sortBy(prop('name'))(countries); // sort alphabetically
    return concat(america, countries);
  },
);

/**
 * Sorts a collection of regions
 * heuristic 1: united states first
 * heuristic 2: remaining regions should be sorted alphabetically
 * @param countries { Region[] } an array of regions
 * @returns { Region[] } sorted array of regions
 */
export function sortRegions(regions: Region[]): Region[] {
  const regionsSorted = sortBy(prop('name'))(regions);
  const america = find<Region>(propEq('code', 'US'))(regionsSorted);
  if (!!america) {
    return uniq(concat([america], regionsSorted));
  }
  return regionsSorted;
}

/**
 * Takes an array of countries and extracts its containing airports as
 * a sorted array of airports
 * @param countries { CountryWithAirports[] } an array of countries
 * @returns { Airports[] } sorted array of contained airports
 */
export const extractAirports = moize(
  (countries: CountryWithAirports[]): Airport[] => {
    return flatten(pluck('airports')(countries)) as any;
  },
);

/**
 * Takes an array of airports and adds iconName to each airport
 * @param airports { Airport[] } an array of airports
 * @returns { Airport[] } containing airports with iconName
 */
export const addIcons = moize((airports: Airport[]): Airport[] => {
  return airports.map((airport: Airport) => {
    if (!(!airport.mint || !airport.jb)) {
      return { ...airport, iconName: 'mintIndicator' };
    } else if (!airport.jb) {
      return { ...airport, iconName: 'partnerAirlineIndicator' };
    }
    return airport;
  });
});

export const extractAndSortAirports = moize(
  (
    countries: CountryWithAirports[],
    separatePartnerCities: boolean = true,
  ): Airport[] =>
    addIcons(
      sortSuggestedAirports(extractAirports(countries), separatePartnerCities),
    ),
);

export const getAllAirportsSortedAndGroupedByPm = moize(
  (
    countries: CountryWithAirports[],
    separatePartnerCities: boolean = true,
  ): Airport[] => {
    const allAirportsSorted = extractAndSortAirports(
      countries,
      separatePartnerCities,
    );
    const allAirportsSortedWithoutPm = allAirportsSorted.filter(
      airport => !airport.pm,
    );
    const allPms = allAirportsSorted
      .filter(airport => airport.cc)
      .map(airport => airport.code);
    const airportsWithPm = allAirportsSorted.filter(airport =>
      allPms.includes(airport.pm),
    );

    for (let i = airportsWithPm.length - 1; i > -1; i--) {
      const airport = airportsWithPm[i];
      const pmIndex = allAirportsSortedWithoutPm.findIndex(
        pmAirport => pmAirport.code === airport.pm,
      );
      allAirportsSortedWithoutPm.splice(pmIndex + 1, 0, airport);
    }

    return allAirportsSortedWithoutPm;
  },
);

/**
 * Takes an array of countries and extract its containing airports as a hashmap
 * @param countries { CountryWithAirports[] } an array of countries
 * @returns { TypedDictionary<Airport> } containing airports as hashmap
 */
export const extractAirportHashMap = moize(
  (countries: CountryWithAirports[]): TypedDictionary<Airport> => {
    const airports = extractAirports(countries);
    return keyBy<CountryWithAirports>('code', airports as any) as any;
  },
);

/**
 * Takes an array of regions and extract its containing regions as a hashmap
 * @param countrresponseies { RegionsResponse } the api response
 * @returns { TypedDictionary<Region> } containing regions as hashmap
 */
export const extractRegionsData = moize(
  (response: RegionsResponse): TypedDictionary<Region> => {
    let regions: any[] = pathOr([], ['regions'], response);
    for (let i = 0; i < regions.length; i++) {
      regions[i] = dissoc('countries', regions[i]);
    }
    regions = sortRegions(regions);
    return keyBy('code', regions);
  },
);

/**
 * Takes an httpRetryMaxCount and returns a scan function that compares error counts
 * @param httpRetryMaxCount { number } the max retry count
 * @returns { scan(errorCount: number, err: Error): number || Error } the scan function
 */
export const scanErrorCount = httpRetryMaxCount =>
  scan((errorCount, err) => {
    if (errorCount >= httpRetryMaxCount) {
      throw err;
    }
    return errorCount + 1;
  }, 0);

/**
 * Takes an array of airport codes and transforms them into airports
 * @param airportHashMap { TypedDictionary<Airport> } collection of airports
 * @param codes { string[] } collection of airprot codes
 * @returns { Airport[] } airport codes as airports
 */
export function codesToAirports(
  airportHashMap: TypedDictionary<Airport>,
  codes: string[],
): Airport[] {
  let airports = [];
  if (!!codes && !!codes.length) {
    airports = filter(
      airport => !!airport,
      map(airportCode => airportHashMap[airportCode], codes),
    );
  }
  return airports;
}

/**
 * const to apply optional filters.
 * If optional filter not applied, will default to true and pass the filter test
 * @param countries { CountryWithAirports[] } collection of countries to be filtered
 * @param filterOutPartner { Boolean } filter list to only inclued jb airports
 * @param filterOutMac { Boolean } filter list to exclued macs
 * @returns { CountryWithAirports[] } filtered list
 */
export const applyFilters = moize(
  (
    countries: CountryWithAirports[],
    filterOutPartner: boolean = false,
    filterOutMac: boolean = false,
    filterOutMacExceptions: string[] = [],
  ): CountryWithAirports[] => {
    if (filterOutPartner || filterOutMac) {
      const filteredCountries = clone(countries);
      for (const country of filteredCountries) {
        country.airports = filter(airport => {
          let notMac = filterOutMac ? !airport.cc : true;
          const jbCity = filterOutPartner ? airport.jb : true;
          const isMacException =
            filterOutMac && !!filterOutMacExceptions
              ? filterOutMacExceptions.findIndex(
                  code => code === airport.code.toUpperCase(),
                ) !== -1
              : false;
          notMac = notMac || isMacException;
          return jbCity && notMac;
        }, country.airports).map(airport => {
          if (filterOutMac) {
            /**
             * CL's jb-autocomplete logic prevents showing Airports which have parent mac airport.
             * To filter out MACs and preserve airports under MAC
             * we need to delete 'pm' ( parent mac ) property
             */
            delete airport.pm;
          }
          return airport;
        });
      }
      return filteredCountries;
    }
    return countries;
  },
);

/**
 * Updates the "needle" property on any airport in a multi-airport-city (MAC)
 * with all the names of the respective airports so they are all searchable
 * @param airports { Airport[] } collection of airports to be filtered
 * @param airportsMap { TypedDictionary<Airport> } map of all airports by airport code
 * @returns { Airport[] } list of airports with updated needles, sorted alphabetically
 */
export const updateNeedleWithMacInfoAndSort = moize(
  (airports: Airport[], airportsMap: TypedDictionary<Airport>): Airport[] => {
    for (const airport of airports) {
      if (airport.cc) {
        let ccAirport: Airport;
        for (const ccCode of airport.cc) {
          ccAirport = airportsMap && airportsMap[ccCode];
          if (!!ccAirport) {
            // Update Parent Airport needle with CC Airport information
            airport.needle += ` ${ccAirport.code} ${ccAirport.name}`;

            // Update CC Airport needle with Parent Airport information
            const ccIndex = airports.findIndex(item => item.code === ccCode);
            if (ccIndex >= 0) {
              airports[ccIndex].needle += ` ${airport.code} ${airport.name}`;
            }
          }
        }
      }
    }
    return sortSuggestedAirports(airports);
  },
);

/**
 * Updates the "needle" property on any airport in a multi-airport-city (MAC)
 * with all the names of the respective airports so they are all searchable, and also
 * constructs a map of the airports
 * @param airports { Airport[] } collection of airports to be filtered
 * @returns { AirportListAndMap } list of airports with updated needles, sorted alphabetically
 */
export const updateNeedleWithMacInfoForListAndMapAndSort = moize(
  (airports: Airport[]): AirportListAndMap => {
    const airportsMap = keyBy<Airport>('code', airports);
    for (const airport of airports) {
      if (airport.cc) {
        let ccAirport: Airport;
        for (const ccCode of airport.cc) {
          ccAirport = airportsMap[ccCode];
          if (!!ccAirport) {
            // Update Parent Airport needle with CC Airport information
            airport.needle += ` ${ccAirport.code} ${ccAirport.name}`;
            airportsMap[airport.code].needle = airport.needle;

            // Update CC Airport needle with Parent Airport information
            const ccIndex = airports.findIndex(item => item.code === ccCode);
            if (ccIndex >= 0) {
              airports[ccIndex].needle += ` ${airport.code} ${airport.name}`;
            }
          }
        }
      }
    }
    return { list: sortSuggestedAirports(airports), map: airportsMap };
  },
);

/**
 * Takes an array of airports and produces a map, keyed by airport code
 * @param airports: { Airport[] } an array of airports
 * @returns { TypedDictionary<Airport> } the airport map keyed by airport code
 */
export const buildAirportMap = moize(
  (airports: Airport[]): TypedDictionary<Airport> =>
    airports.length > 0 ? keyBy('code', airports) : undefined,
);

/**
 * Takes an array of airports and map of countries keyed by airport code and
 * produces an array of countries with airports
 * @param airports: { Airport[] } a list of airports
 * @param countryByAirportMap: { TypedDictionary<CountryWithAirportCodes> }
 * a map of countries, keyed by airport code
 * @returns { CountryWithAirports[] } an array of countries with airports
 */
export function buildCountryListFromAirports(
  airports: Airport[],
  countryByAirportMap: TypedDictionary<CountryWithAirportCodes>,
): CountryWithAirports[] {
  const countryIndexList = {};
  return airports.length > 0 && Boolean(countryByAirportMap)
    ? sortSuggestedCountries(
        airports.reduce((accumulatedCountries, currentAirport) => {
          const country: CountryWithAirportCodes =
            countryByAirportMap[currentAirport.code];
          if (country) {
            const countryIndex: number = countryIndexList[country.code];
            if (countryIndex !== undefined) {
              accumulatedCountries[countryIndex].airports.push({
                ...currentAirport,
                countryCode: country.code,
              });
              return accumulatedCountries;
            }
            countryIndexList[country.code] = accumulatedCountries.length;
            return [
              ...accumulatedCountries,
              {
                ...country,
                airports: [
                  ...country.airports,
                  {
                    ...currentAirport,
                    countryCode: country.code,
                  },
                ],
              },
            ];
          }
          return accumulatedCountries;
        }, []),
      )
    : [];
}

/**
 * Takes an array of airports and an array of countries with airport codes and
 * produces a map of countries, keyed by airport code
 * @param airports: { Airport[] } a list of airports
 * @param countries: { CountryWithAirportCodes[] }
 * a map of countries, keyed by airport code
 * @returns { TypedDictionary<CountryWithAirportCodes> } an array of countries with airports
 */
export function buildCountryByAirportMap(
  airports: Airport[],
  countries: CountryWithAirportCodes[] = [],
): TypedDictionary<CountryWithAirportCodes> {
  if (airports.length > 0 && countries.length > 0) {
    const countryByAirport = {};
    countries.forEach(country => {
      country.airports.forEach(airportCode => {
        countryByAirport[airportCode] = { ...country };
        countryByAirport[airportCode].airports = [];
      });
    });
    return countryByAirport;
  }
  return undefined;
}

/**
 * Takes an array of airports and map of countries keyed by airport code and
 * produces a map of airports, keyed by country code
 * @param airports: { Airport[] } a list of airports
 * @param countryByAirportMap: { TypedDictionary<CountryWithAirportCodes> }
 * a map of countries, keyed by airport code
 * @returns { TypedDictionary<Airport[]> } a map of airports, keyed by country code
 */

export function buildAirportsByCountryMap(
  airports: Airport[],
  countryByAirportMap: TypedDictionary<CountryWithAirportCodes>,
): TypedDictionary<Airport[]> {
  return airports.length > 0 && Boolean(countryByAirportMap)
    ? airports.reduce((accumulatedCountries, currentAirport) => {
        const country: CountryWithAirportCodes =
          countryByAirportMap[currentAirport.code];
        return country
          ? {
              ...accumulatedCountries,
              [country.code]: [
                ...(accumulatedCountries[country.code] || []),
                { ...currentAirport, countryCode: country.code },
              ],
            }
          : accumulatedCountries;
      }, {})
    : undefined;
}

/**
 * Takes Airport Map and List and
 * produces new AirportMapList with country code
 * @param airportListAndMap: { AirportListAndMap } Airport List and Map
 * @param countryWithAirportCodes: CountryWithAirportCodes[]
 * @returns { AirportListAndMap } new List and Map of Airports with Country Code
 */
export function buildAirportMapListWithCountryCode(
  airportListAndMap: AirportListAndMap,
  countryWithAirportCodes: CountryWithAirportCodes[],
): AirportListAndMap {
  // Create new array and object for AirportList and AirportMap
  const airportsList = [];
  const airportsMap = {};
  // Go through airportListAndMap, filling in the countryCode for each one of them
  airportListAndMap.list.forEach(airport => {
    // Get the country object for selected airport
    const selectedCountry = countryWithAirportCodes.find(country =>
      country.airports.find(airportCode => airportCode === airport.code),
    );

    // Add airport to airport List<Array>.
    airportsList.push({
      ...airport,
      countryCode: selectedCountry?.code,
    });

    // Add airport to airport Map<Object>.
    airportsMap[airport.code] = {
      ...airport,
      countryCode: selectedCountry?.code,
    };
  });

  // Return new data with countryCodes
  return {
    list: airportsList,
    map: airportsMap,
  };
}
