import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
/**
 * Flattens an array of objects containing arrays of objects into a single-level object
 * with nested objects, converting string representations of booleans to actual booleans.
 *
 * @param objAsArray - The array of objects to flatten, with a generic type.
 * @returns The flattened object with dynamically inferred types.
 */
export const flattenCMSArrayObject = (
  objAsArray: { [key: string]: { [key: string]: any }[] }[],
) => {
  return objAsArray.reduce((acc: { [key: string]: any }, current) => {
    const currentEntriesFlattened = Object.entries(current).reduce(
      (newAcc, [key, [firstValue]]) => {
        if (!firstValue) return newAcc; // Skip if the array is empty or the value is undefined

        const parsedValue = Object.entries(firstValue).reduce(
          (valueAcc, [propKey, propValue]) => ({
            ...valueAcc,
            [propKey]:
              propValue === 'true' || propValue === 'false'
                ? booleanStringCheck(propValue)
                : propValue,
          }),
          {},
        );

        return { ...newAcc, [key]: parsedValue };
      },
      {},
    );

    return { ...acc, ...currentEntriesFlattened };
  }, {}) as any; // giving up on typing this for now, it's being a pain
};
