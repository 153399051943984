import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { JBInnerHtmlComponent } from './jb-inner-html.component';

const MODULES = [CommonModule];

const DECLARATIONS = [JBInnerHtmlComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class JBInnerHtmlModule {}
