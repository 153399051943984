import { map, mapObjIndexed } from 'ramda';

/**
 *  Similar to traverse(), except it'll receive a set of paths which we want to see flattened
 * @param {Set<string>} entriesToFlatten
 * @returns Updated object
 */
export const traverseFlattenPathAware = (entriesToFlatten: Set<string>) => {
  const conflicts: {
    path: string;
    length: number;
  }[] = [];

  const traverseInner = (x, breadcrumbs = []) => {
    if (x) {
      if (!!x.subNavSections) {
        x.subNavSections = x.subNavSections.section.map(section =>
          traverseInner(section, breadcrumbs),
        );
      }
      if (Array.isArray(x)) {
        const path = breadcrumbs.join('.');
        if (entriesToFlatten.has(path)) {
          const arrayLength = x.length;
          if (arrayLength > 1) {
            conflicts.push({ path, length: arrayLength });
          } else if (arrayLength === 1) {
            return traverseInner(x[0], breadcrumbs);
          }
        }
        return map(val => traverseInner(val, breadcrumbs))(x);
      }
      if (typeof x === 'object' && !(typeof x.getMonth === 'function')) {
        const updatedObj = x.data ? x.data : x;
        return mapObjIndexed((val, key) =>
          traverseInner(val, [...breadcrumbs, key]),
        )(updatedObj);
      }
      return x;
    }
  };

  return input => ({ result: traverseInner(input), conflicts });
};
