import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TextContentService } from '@core/text-content/text-content.service';
import { map, share } from 'rxjs/operators';

/**
 * @description Not found page that will redirect users in the following conditions:
 * - Navigating to a page that does not exist in the sitemap.
 * - Navigating to an existing page and for some reason the CMS data fails to retrieve its content.
 * @WhereIsItUsed
 * - /not-found.
 */
@Component({
  selector: 'jb-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {
  private textContentService = inject(TextContentService);

  pageData$ = this.textContentService
    .getJsonAsset('page-not-found-text')
    .pipe(share());

  headerContent$ = this.pageData$.pipe(
    map(pageData => ({
      title: pageData.title,
    })),
  );

  bodyTextImage$ = this.pageData$.pipe(
    map(pageData => [
      {
        content: pageData.content,
        type: 't2',
        title: '404 Error Message Body Text with Image',
        image: [
          {
            alt: '""',
            src: '/ui-assets/404.svg',
            maxHeight: '148px',
          },
        ],
      },
    ]),
  );
}
