import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { select, Selector, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AllActionsUnion } from '../actions';
import { AppState } from './types/app-state.type';

/**
 * This class is a thin wrapper around NGRX for easier testing and to help
 * facilitate the rest of the application to be composed of pure functions.
 */
@Injectable({ providedIn: 'root' })
export class StoreService {
  public store: Store<AppState>;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.store = inject(Store);
  }

  getState<S>(selector: Selector<AppState, S>): Observable<S> {
    if (isPlatformServer(this.platformId)) {
      return of(null);
    }
    return this.store.pipe(select(selector));
  }

  dispatchAction(action: AllActionsUnion | any) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.store.dispatch(action);
  }
}
