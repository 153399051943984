import { NgModuleRef, Type } from '@angular/core';
import { Templates } from '@dynamic-templates/types/templates.type';

import { CMSComponentData } from './cms-component-data.type';

export type ComponentDataResolver = (
  registry: Registry,
  data: any,
  template?: keyof Templates,
) => any;

export type DynamicComponent = {
  componentDataResolver: ComponentDataResolver;
};

export type DynamicModule = {
  entry: Type<any>;
} & DynamicComponent;

export type DynamicComponentConstructor = Type<any> & DynamicComponent;

export type DynamicModuleConstructor = Type<any> & DynamicModule;

export type DynamicItemConstructor =
  | DynamicModuleConstructor
  | DynamicComponentConstructor;

export type LoadedDynamicModuleRef = {
  ngModuleRef: NgModuleRef<DynamicModuleConstructor>;
  componentConstructor: DynamicModuleConstructor;
};

export type LoadedModuleAndData = {
  loadedModule: LoadedDynamicModuleRef | DynamicComponentConstructor;
  componentData: CMSComponentData;
};

export interface Registry {
  [key: string]: Object;
}

/**
 * Type guard for PromiseRejectedResult that checks for status of 'rejected';
 * @param input a result from preforming Promise.allSettled()
 * @returns Promise rejected result type
 */

/*
 */ export const isPromiseRejected = (
  input: PromiseSettledResult<unknown>,
): input is PromiseRejectedResult => input.status === 'rejected';

/**
 * Type guard for PromiseRejectedResult that checks for status of 'fulfilled';
 * @param input a result from preforming Promise.allSettled()
 * @returns Promise fulfilled result type
 */
export const isPromiseFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';

// Keys used internally by angular on definition classes
// can be used to determine what a class is before its processed
const NG_MOD_DEF_KEY = 'ɵmod';
const NG_COMP_DEF_KEY = 'ɵcmp';
// directive
// const NG_DIR_DEF_KEY =  'ɵdir'
// pipe
// const NG_PIPE_DEF_KEY = 'ɵpipe'
// factory
// const NG_FACTORY_DEF_KEY = 'ɵfac'

/** Type guard and function to determine if a object is an NgModule constructor */
export function isModuleConstructor(
  item: any,
): item is DynamicModuleConstructor {
  return !!item[NG_MOD_DEF_KEY];
}

/** Type guard and function to determine if an object is an NgComponent constructor */
export function isComponentConstructor(
  item: any,
): item is DynamicComponentConstructor {
  return !!item[NG_COMP_DEF_KEY];
}
