<div
  class="pb1 mb2 red s-body"
  tabindex="0"
  #genError
  (focus)="updateFocus()"
  [hidden]="errorMessage.length < 1"
  aria-live="polite"
  [innerHTML]="loginErrorMessage$ | async"
></div>
<form
  jbFocusInvalid
  (ngSubmit)="login()"
  [formGroup]="tbForm"
  #loginFormReference="ngForm"
  novalidate
>
  <!-- email field -->
  <jb-form-field-container class="mt4">
    <jb-input-label>
      {{ ((trueBlueResponse?.login)[0]?.email)[0]?.title }}
    </jb-input-label>
    <input
      #emailRef
      jbInput
      data-qaid="emailInput"
      class="input-height-override"
      formControlName="email"
      type="email"
    />
    <jb-error
      data-qaid="emailError"
      [showErrorIf]="loginFormReference.submitted && email.invalid"
    >
      {{ emailErrorMessage }}
    </jb-error>
  </jb-form-field-container>

  <!-- password field -->
  <jb-form-field-container class="mt3">
    <jb-input-label>
      {{ ((trueBlueResponse?.login)[0]?.password)[0]?.title }}
    </jb-input-label>
    <input
      #passwordRef
      jbInput
      [type]="isChecked ? 'text' : 'password'"
      class="input-height-override"
      formControlName="password"
      autocomplete="disabled"
    />
    <jb-error
      data-qaid="passwordInput"
      [showErrorIf]="loginFormReference.submitted && password.invalid"
    >
      {{ passwordErrorMessage }}
    </jb-error>
  </jb-form-field-container>

  <!-- Show password checkbox -->
  <jb-form-field-container class="mv2">
    <jb-checkbox [checked]="isChecked" (click)="isChecked = !isChecked">
      {{ ((trueBlueResponse?.login)[0]?.showPassword)[0]?.title }}
    </jb-checkbox>
  </jb-form-field-container>
  <!-- Forgot password link -->
  <a
    class="dib mt2 mb4 royal-blue s-body lh-solid no-underline underline-hover"
    data-qaid="forgotPasswordLink"
    [jbSitemapLinker]="forgotPasswordLink"
    (click)="beforeRedirectToForgotPassword()"
    (keydown.enter)="beforeRedirectToForgotPassword()"
  >
    {{ ((trueBlueResponse?.login)[0]?.forgotPassword)[0]?.title }}
  </a>

  <!-- captcha field forgot-password/email-entry -->
  <div
    #captchaRef
    *ngIf="showCaptcha"
    class="pb3"
    [tabIndex]="isCaptchInvalid() ? 0 : 1"
    [class.invalid-control]="isCaptchInvalid()"
  >
    <div class="captcha-container" data-qaid="captcha">
      <re-captcha
        formControlName="recaptchaReactive"
        (resolved)="resolved($event)"
      >
      </re-captcha>
    </div>
    <div class="dn pl3 pt2 captcha-err-msg s-body">
      Please verify that you are not a robot.
    </div>
  </div>
  <button
    jbButton
    theme="ctaPrimary"
    aria-live="assertive"
    #submitButton
    type="submit"
    data-qaid="loginButton"
    [ngClass]="{ 'o-70': submitting }"
    class="w-100-important tc"
  >
    <span class="clip" *ngIf="submitting" data-qaid="loadingMessage">{{
      ((trueBlueResponse?.login)[0]?.submitButton)[0]?.loading
    }}</span>
    <span class="clip" *ngIf="success" data-qaid="successMessage">{{
      ((trueBlueResponse?.login)[0]?.submitButton)[0]?.success
    }}</span>
    <ng-container *ngIf="!submitting">
      <span class="center s-body b white">{{
        ((trueBlueResponse?.login)[0]?.submitButton)[0]?.title
      }}</span>
    </ng-container>
    <jb-icon
      *ngIf="submitting"
      name="loading"
      data-qaid="loginSpinner"
      class="fill-white mw3 w-100 dib h1"
    ></jb-icon>
  </button>
</form>

<div [ngClass]="clm5 ? 'tc db-ns' : 'tc db-ns dn'">
  <span class="db tc s-body charcoal mt4 mb2">
    {{ trueBlueResponse?.bottomCopy }}</span
  >
  <a
    jbStandaloneLink
    [jbSitemapLinker]="joinUsLink"
    class="s-body b"
    data-qaid="joinNow"
  >
    {{ (trueBlueResponse?.join)[0]?.title }}
    <jb-icon name="rightFilled"></jb-icon>
  </a>
  <div class="flex justify-center flex-wrap w-100">
    <a
      [ngClass]="{
        pr5: i != 2 && i + 1 != l,
        'next-row': i == 3,
        'top-margin': i > 2
      }"
      *ngFor="
        let links of trueBlueResponse?.pageLink;
        let i = index;
        let l = count
      "
      jbStandaloneLink
      [jbSitemapLinker]="links.href"
      class="s-body privacy-policy mt4"
      data-qaid="privacyPolicy"
    >
      {{ links.title }}
    </a>
  </div>
</div>
