<!-- main navs : Header -->
<div class="w-100 bg-core-blue page-margin">
  <div
    class="sticky-header-desktop-content center justify-between flex flex-wrap jb-max-width bg-core-blue white"
  >
    <div class="sticky-header-desktop-items flex flex-auto items-center center">
      <jb-logo
        #headerLogo
        class="db"
        [logoData]="headerData?.jetblueLogo"
      ></jb-logo>
      <jb-subnav-desktop
        [headerData]="headerData"
        [hideTb] = 'hideTb'
        class="flex-auto"
      ></jb-subnav-desktop>
    </div>
  </div>
</div>
