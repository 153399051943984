import { Component } from '@angular/core';
import { InstallService } from '@core/install';

/**
 * @description Pop up displayed when user selects to install app as a progressive web app
 * @WhereIsItUsed Header component
 */
@Component({
  selector: 'jb-install-prompt',
  templateUrl: './install-prompt.component.html',
})
export class InstallPromptComponent {
  constructor(public installService: InstallService) {
    installService.checkUserAgent();
    installService.trackStandalone();
    window.addEventListener('beforeinstallprompt', e => {
      // show the add button
      installService.promptIntercepted = true;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
      e.preventDefault();
      // Stash the event so it can be displayed when the user wants.
      installService.deferredPrompt = e;
      installService.promptSaved = true;
    });
    window.addEventListener('appinstalled', evt => {
      installService.trackInstalled();
      // hide the add button
    });
  }
}
