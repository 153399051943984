import { EventEmitter, Injectable } from '@angular/core';
@Injectable()
export class DialogService {
  dialogChange: EventEmitter<boolean> = new EventEmitter();

  emitDialogCloseEvent(moveFocusBack): void {
    this.dialogChange.emit(moveFocusBack);
  }

  getDialogCloseEmitter(): EventEmitter<boolean> {
    return this.dialogChange;
  }
}
