import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { Action } from '../shared/types/action.interface';
import { AppState } from '../shared/types/app-state.type';
import { ApiState } from './types/api-state.type';

export const api: MemoizedSelector<AppState, ApiState> =
  createFeatureSelector('api');

export const askedFor: MemoizedSelector<AppState, Action[]> = createSelector(
  api,
  state => state.askedFor,
);
export const pending: MemoizedSelector<AppState, Action[]> = createSelector(
  api,
  state => state.pending,
);
export const errors: MemoizedSelector<AppState, Action[]> = createSelector(
  api,
  state => state.errors,
);

export const hasPending: MemoizedSelector<AppState, boolean> = createSelector(
  pending,
  state => state.length !== 0,
);
export const hasErrors: MemoizedSelector<AppState, boolean> = createSelector(
  errors,
  state => state.length !== 0,
);

export const actionIsPending: (
  actionType: string,
) => MemoizedSelector<AppState, boolean> = (actionType: string) =>
  createSelector(
    pending,
    pendingActions =>
      !!pendingActions.find(action => action.type === actionType),
  );
export const actionHasBeenAskedFor: (
  actionType: string,
) => MemoizedSelector<AppState, boolean> = (actionType: string) =>
  createSelector(
    askedFor,
    askedForActions =>
      !!askedForActions.find(action => action.type === actionType),
  );
export const actionHasError: (
  actionType: string,
) => MemoizedSelector<AppState, boolean> = (actionType: string) =>
  createSelector(
    errors,
    errorActions => !!errorActions.find(action => action.type === actionType),
  );

export const getErrorAction: <T>(
  actionType: string,
) => MemoizedSelector<AppState, T> = (actionType: string) =>
  createSelector(
    errors,
    errorActions =>
      errorActions.find(action => action.type === actionType) as any,
  );
