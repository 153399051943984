import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContentSectionHeader } from '@feature/types';
import { Hero } from '@shared/types';

@Component({
  selector: 'jb-page-header-container',
  templateUrl: './page-header-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderContainerComponent {
  primaryHeroData: Hero;
  pageHeader: ContentSectionHeader;
  sectionHeader: ContentSectionHeader[];
  chapterSelectorHeader: string;
  includeChapterSelector: string;
  template: string;
}

export const pageHeaderContainerResolver = (registry, data, template) => {
  const hero = registry['hero'];
  const primaryHeroData = registry['primaryHeroData'];
  const sectionHeader = registry['sectionHeader'];
  return {
    pageHeader: !!data.pageHeader
      ? data.pageHeader.constructor === Array
        ? data.pageHeader[0]
        : data.pageHeader
      : hero && hero.pageHeader
      ? hero.pageHeader[0]
      : data.title
      ? data
      : data.hero[0],
    primaryHeroData,
    sectionHeader: sectionHeader ? sectionHeader.sectionHeader : [],
    chapterSelectorHeader: sectionHeader ? sectionHeader.goToText : undefined,
    includeChapterSelector: sectionHeader
      ? sectionHeader.enableSelector
      : 'false',
    template,
  };
};
