import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jbTrimLeadingZeroes',
})
export class TrimLeadingZeroesPipe implements PipeTransform {
  transform(numberString: string) {
    return parseInt(numberString, 10).toString();
  }
}
