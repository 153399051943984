import { Component } from '@angular/core';

/**
 * @description Display formatted actions as children, to be used on dialogs
 * @WhereIsItUsed popup dialog
 */
@Component({
  selector: 'jb-dialog-temp-actions',
  template: `
    <div class="action-container">
      <ng-content></ng-content>
      <div></div>
    </div>
  `,
  styleUrls: ['./dialog-actions.component.scss'],
})
export class DialogTempActionsComponent {}
