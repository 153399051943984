import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { Observable } from 'rxjs';

import { RequestReceiptPayload } from '../types';

@Injectable({ providedIn: 'root' })
export class RequestAReceiptApi {
  receiptRequestUrl: string;
  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService,
  ) {
    this.receiptRequestUrl = this.appConfig.receiptRequestUrl;
  }

  requestReceiptFormSubmission(
    requestBody: RequestReceiptPayload,
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    const url = `${this.receiptRequestUrl}`;
    const body = requestBody;
    return this.httpClient.post(
      url,
      { ...body },
      {
        headers,
        observe: 'body',
      },
    );
  }
}
