import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { LocalStorageService } from '@core/storage';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class UserVisitService {
  private readonly LAST_VISIT_KEY: string;
  private readonly ONBOARDING_END_KEY: string;
  private localStorageService: LocalStorageService;
  private appConfigService: AppConfigService;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.LAST_VISIT_KEY = 'lastVisit';
    this.ONBOARDING_END_KEY = 'onboardingEnd';
    this.localStorageService = inject(LocalStorageService);
    this.appConfigService = inject(AppConfigService);
  }

  visitStatus(): boolean {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const lastVisitTime = this.getLastVisitTime();
    const onboardingEndTime = this.getOnboardingEndTime();

    if (!lastVisitTime) {
      this.updateLastVisit();
      return true;
    }

    return lastVisitTime <= onboardingEndTime;
  }

  private getOnboardingEndTime(): Date {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const onboardingEnd: string = this.localStorageService.getItem(
      this.ONBOARDING_END_KEY,
    );

    return onboardingEnd ? new Date(Number(onboardingEnd)) : new Date();
  }

  isVisitAfterThreshold(): boolean {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const lastVisitTime = this.getLastVisitTime();
    const visitThreshold = this.appConfigService.visitThreshold;
    const timeUnit = this.appConfigService.timeUnit;

    if (lastVisitTime && visitThreshold) {
      const now = new Date();
      let difference: number;

      switch (timeUnit) {
        case 'minutes':
          difference = differenceInMinutes(now, lastVisitTime);
          break;
        case 'hours':
          difference = differenceInHours(now, lastVisitTime);
          break;
        case 'days':
          difference = differenceInDays(now, lastVisitTime);
          break;
        case 'weeks':
          difference = differenceInWeeks(now, lastVisitTime);
          break;
        case 'months':
          difference = differenceInMonths(now, lastVisitTime);
          break;
        case 'quarters':
          difference = differenceInQuarters(now, lastVisitTime);
          break;
        case 'years':
          difference = differenceInYears(now, lastVisitTime);
          break;
        default:
          console.error(`Invalid time unit: ${timeUnit}`);
          return false;
      }

      return difference >= visitThreshold;
    }

    return false;
  }

  updateLastVisit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const currentTime = new Date().getTime();
    const onboardingEndTime = this.localStorageService.getItem(
      this.ONBOARDING_END_KEY,
    );

    this.localStorageService.setItem(
      this.LAST_VISIT_KEY,
      currentTime.toString(),
    );

    // Set the onboarding end date only if it's not already set
    if (!onboardingEndTime) {
      const fourWeeksLater = new Date(currentTime);

      // Adding 4 weeks
      fourWeeksLater.setDate(fourWeeksLater.getDate() + 28);

      this.localStorageService.setItem(
        this.ONBOARDING_END_KEY,
        fourWeeksLater.getTime().toString(),
      );
    }
  }

  private getLastVisitTime(): Date | null {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const lastVisit: string = this.localStorageService.getItem(
      this.LAST_VISIT_KEY,
    );

    return lastVisit ? new Date(Number(lastVisit)) : null;
  }
}
