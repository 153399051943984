import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

export function emailValidator(control: AbstractControl): {
  [key: string]: any;
} {
  // tslint:disable-next-line
  const emailRe =
    /^\s*[\w+%-]+(\.[\w+%-]+)*@\[?\w+(\.[\w-]+)*[\w-]*\.[A-Za-z0-9]{2,}\]?\s*$/;

  if (control.value && !control.value.match(emailRe)) {
    return { email: false };
  }

  return null;
}

@Directive({
  selector: '[jbValidateEmail]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidatorDirective),
      multi: true,
    },
  ],
})
export class EmailValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    return emailValidator(control);
  }
}

/* tslint:disable */
/** regex breakdown:
 -starts with word character or +, %, -
 -if there is a period in the email, needs to be followed by a word charachter or +, %, -
 -the email must contain one @ symbol
 -the content after the @ symbol can be contained in a bracket grouping []
 -a word character must follow the @ symbol
 -after a word character, . and - can be inlcluded post the @ symbol
 -all periods must be followed by a word character or -
 -an email must contain a period at some point after the @ symbol
 -an email must end with 2 or more alphanumeric characters after the final period

 positive test cases:
 -email@domain.com          -> Valid email
 -firstname.lastname@domain.com    -> Email contains dot in the address field
 -email@subdomain.domain.com  Email   -> contains dot with subdomain
 -firstname+lastname@domain.com    -> Plus sign is considered valid character
 -email@123.123.123.123        -> Domain is valid IP address
 -email@[123.123.123.123]      -> Square bracket around IP address is considered valid
 -1234567890@domain.com        -> Digits in address are valid
 -email@domain-one.com        -> Dash in domain name is valid
 -_______@domain.com          -> Underscore in the address field is valid
 -email@domain.name          -> .name is valid Top Level Domain name
 -email@domain.co.jp          -> Dot in Top Level Domain name also considered valid
 -firstname-lastname@domain.com    -> Dash in address field is valid

 negative test cases:
 -plainaddress                      -> Missing @ sign and domain
 -#@%^%#$@#$@#.com                  -> Garbage
 -@domain.com                      -> Missing username
 -Joe Smith <email@domain.com>      -> Encoded html within email is invalid
 -email.domain.com                  -> Missing @
 -email@domain@domain.com          -> Two @ signs
 -.email@domain.com                  -> Leading dot in address is not allowed
 -email.@domain.com                  -> Trailing dot in address is not allowed
 -email..email@domain.com          -> Multiple dots
 -あいうえお@domain.com              -> Unicode char as address
 -email@domain.com (Joe Smith)      -> Text followed email is not allowed
 -email@domain                      -> Missing top level domain (.com/.net/.org/etc)
 -email@-domain.com                  -> Leading dash in front of domain is invalid
 -email@domain..com                  -> Multiple dot in the domain portion is invalid
 */
