<ng-container *ngFor="let part of textParts; let i = index">
  <span class="white"
    >{{ part }}
    <ng-container *ngIf="i < textlinks.length">
      <a
        class="link underline white"
        *ngIf="!isShowInModal(textlinks[i]); else modalLink"
        [jbSitemapLinker]="textlinks[i].href"
        >{{ textlinks[i].title }}</a
      >
      <ng-template #modalLink>
        <a
          class="link pointer underline white"
          [jbSitemapLinker]="
            sanitizer.bypassSecurityTrustResourceUrl(textlinks[i].href)
          "
          (click)="onModalLinkClick(i, $event)"
          >{{ textlinks[i].title }}</a
        >
      </ng-template>
    </ng-container>
  </span>
</ng-container>
