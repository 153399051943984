import { getCurrencySymbol } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BestFareDay, WindowService, WindowSize } from '@core/index';
import { BffStore } from '@feature/bff/bff.component-store';
import { ComponentStore } from '@ngrx/component-store';
import { isAfter, isBefore } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import {
  isBeforeDepartDateUtil,
  isBetweenDepartAndReturnUtil,
  isBlanksBetweenDates,
  isDaysWithData,
  isDepartDateUtil,
  isOneWayWithData,
  isReturnDateUtil,
} from '../calendar.utils';
import { CalendarResponse } from '../types';
import {
  calendarDateBoxComponentInitialState,
  CalendarDateBoxComponentState,
  CalendarDateBoxComponentViewModel,
} from './calendar-date-box.component.state';

const DEBOUNCE = { debounce: true };

/**
 * @description Formatted single date for use in calendar
 * @WhereIsItUsed calendar component
 */
@Component({
  selector: 'jb-calendar-date-box',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-date-box.component.html',
  styleUrls: ['./calendar-date-box.component.scss'],
  providers: [ComponentStore],
})
export class CalendarDateBoxComponent {
  @Input() set day(value: BestFareDay) {
    this.store.patchState({ day: value });
  }

  @Input() set year(value: number) {
    this.store.patchState({ year: value });
  }

  @Input() set month(value: number) {
    this.store.patchState({ month: value });
  }

  @Input() set isLoading(value: boolean) {
    this.store.patchState({ isLoading: value });
  }

  @Input() set isLoaded(value: boolean) {
    this.store.patchState({ isLoaded: value });
  }

  @Input() set isOneWay(value: boolean) {
    this.store.patchState({ isOneWay: value });
  }

  @Input() set isPoints(value: boolean) {
    this.store.patchState({ isPoints: value });
  }

  @Input() set progressFocus(value: number) {
    this.store.patchState({ progressFocus: value });
  }

  @Input() set content(value: CalendarResponse) {
    this.store.patchState({ content: value });
  }

  @Input() set selectedDepart(value: BestFareDay) {
    this.store.patchState({ selectedDepart: value });
  }

  @Input() set selectedReturn(value: BestFareDay) {
    this.store.patchState({ selectedReturn: value });
  }

  @Input() set maxSeats(value: number) {
    this.store.patchState({ maxSeats: value });
  }

  @Input() set dayOfWeek(value: number) {
    this.store.patchState({ dayOfWeek: value });
  }

  readonly windowSizeStatus$ = this.store
    .select(state => state.windowSize)
    .pipe(map(windowSize => this.getWindowSizeStatus(windowSize)));

  readonly dayStatus$ = this.store.select(
    this.store.select(state => state.day),
    this.store.select(state => state.selectedDepart),
    this.store.select(state => state.selectedReturn),
    this.store.select(state => state.isLoaded),
    this.store.select(state => state.isOneWay),
    (day, selectedDepart, selectedReturn, isLoaded, isOneWay) =>
      this.getDayStatus(
        day,
        selectedDepart,
        selectedReturn,
        isLoaded,
        isOneWay,
      ),
    DEBOUNCE,
  );

  readonly background$ = this.store.select(
    this.store.select(state => state.isLoaded),
    this.store.select(state => state.windowSize),
    this.store.select(state => state.day),
    this.dayStatus$.pipe(
      map(dayStatus => Boolean(dayStatus.isBeforeDepartDate)),
    ),
    (isLoaded, windowSize, day, isBeforeDepartureDate) =>
      this.getBackground(day, windowSize, isLoaded, isBeforeDepartureDate),
  );

  readonly dateBoxClass$ = this.store
    .select(
      state => ({
        day: state.day,
        month: state.month,
        year: state.year,
        isLoading: state.isLoading,
        isOneWay: state.isOneWay,
        selectedDepart: state.selectedDepart,
        selectedReturn: state.selectedReturn,
        iOS: state.iOS,
      }),
      DEBOUNCE,
    )
    .pipe(map(x => this.getDateBoxClass(x)));

  readonly vm$: Observable<CalendarDateBoxComponentViewModel> = combineLatest([
    this.store.state$,
    this.dayStatus$,
    this.windowSizeStatus$,
    this.dateBoxClass$,
    this.background$,
    this.bffStore.currencyCode$.pipe(
      map(currencyCode => getCurrencySymbol(currencyCode, 'narrow')),
    ),
  ]).pipe(
    map(
      ([
        state,
        dayStatus,
        windowSizeStatus,
        dateBoxClass,
        background,
        currencySymbol,
      ]) => {
        return {
          ...state,
          ...dayStatus,
          ...windowSizeStatus,
          dateBoxClass,
          background,
          currencySymbol,
        };
      },
    ),
  );

  dayClicked = this.store.effect(trigger$ =>
    trigger$.pipe(
      withLatestFrom(this.store.state$),
      map(([_, state]) => state),
      filter(
        ({ day, isLoading, isOneWay, selectedDepart }) =>
          !day.disabled && !isLoading && !!day.amount,
      ),
      tap(({ day, isOneWay, iOS, selectedDepart, selectedReturn }) =>
        this.selectDay(day, isOneWay, iOS, selectedDepart, selectedReturn),
      ),
    ),
  );

  constructor(
    private readonly windowService: WindowService,
    public readonly bffStore: BffStore,
    public readonly store: ComponentStore<CalendarDateBoxComponentState>,
  ) {
    store.setState(calendarDateBoxComponentInitialState);

    this.store.patchState(
      this.windowService.windowSizeChanged.pipe(
        map(windowSize => ({ windowSize })),
      ),
    );

    this.store.patchState({
      iOS: this.windowService.checkMobileOperatingSystem() === 'iOS',
    });
  }

  getDateBoxClass({
    day,
    month,
    year,
    selectedDepart,
    selectedReturn,
    isLoading,
    isOneWay,
    iOS,
  }: {
    day: BestFareDay;
    month: number;
    year: number;
    selectedDepart: BestFareDay;
    selectedReturn: BestFareDay;
    isLoading: boolean;
    isOneWay: boolean;
    iOS: boolean;
  }): Record<string, boolean> {
    let dateBoxClass = {
      'ract-hover': false,
      'arrow-right-hover': false,
      'arrow-right-focus': false,
      'arrow-left-hover': false,
      'arrow-left-focus': false,
      'ract-fill': false,
      'ract-fill-in-range': false,
      'arrow-left-in-range': false,
      'date-box-no-hover': !!day.disabled,
      'disable-click': true,
      pointer: false,
    };
    // only set class for valid date
    if (!day.disabled && !isLoading) {
      // No hover over class for dates without amount
      if (day.amount != null && !isOneWay) {
        // there are too many conditions in this function.
        // It will not pass linting and needs to be cleaned up
        // This is a first attempt to do some cleaning
        dateBoxClass = {
          ...dateBoxClass,
          ...isDaysWithData(day, selectedDepart, selectedReturn, isOneWay, iOS),
        };
      }
      // set for one-way dates
      if (day.amount != null && isOneWay) {
        dateBoxClass = {
          ...dateBoxClass,
          ...isOneWayWithData(day, selectedDepart, isOneWay, iOS),
        };
      }
      // set for date in between depart and return date
      if (
        selectedReturn &&
        selectedDepart &&
        isBetweenDepartAndReturnUtil(selectedReturn, selectedDepart, day)
      ) {
        dateBoxClass = {
          ...dateBoxClass,
          ...{
            'ract-fill-in-range': true,
            'arrow-left-in-range': true,
          },
        };
      }
    }
    // set class for blank dates when they are in between depart and return date
    if (
      day.dateOfMonth <= 0 &&
      selectedDepart &&
      selectedReturn &&
      !isLoading
    ) {
      dateBoxClass = {
        ...dateBoxClass,
        ...isBlanksBetweenDates(
          day,
          selectedDepart,
          selectedReturn,
          month,
          year,
        ),
      };
    }
    return dateBoxClass;
  }

  getBackground(
    day: BestFareDay,
    windowSize: WindowSize,
    isLoaded: boolean,
    isBeforeDepartureDate: boolean,
  ) {
    if (!isBeforeDepartureDate && !isLoaded) {
      return '#E8E8E9';
    }
    if (!!windowSize.large && !!day.date) {
      switch (true) {
        case !!day.holiday?.image && day.holiday.name === 'BIRTHDAY':
          return `url(${day.holiday.image.src}) center no-repeat #fff`;
        default:
          return '';
      }
    }
    return '';
  }

  getWindowSizeStatus(windowSize: WindowSize): {
    isTablet: boolean;
    isMobile: boolean;
  } {
    return {
      isMobile: !!windowSize.small,
      isTablet: !!windowSize.medium,
    };
  }

  getDayStatus(
    day: BestFareDay,
    selectedDepart: BestFareDay,
    selectedReturn: BestFareDay,
    isLoaded: boolean,
    isOneWay: boolean,
  ): {
    isBetweenDepartAndReturn: boolean;
    isBeforeDepartDate: boolean;
    isDepartDate: boolean;
    isFocusable: boolean;
    isReturnDate: boolean;
  } {
    const isBeforeDepartDate =
      !isOneWay &&
      selectedDepart &&
      isBeforeDepartDateUtil(selectedDepart, day);
    return {
      isDepartDate: isDepartDateUtil(day, selectedDepart),
      isReturnDate: isReturnDateUtil(day, selectedReturn),
      isBeforeDepartDate,
      isBetweenDepartAndReturn:
        selectedReturn && selectedDepart
          ? isBefore(day.date, selectedReturn.date) &&
            isAfter(day.date, selectedDepart.date)
          : false,

      isFocusable:
        !day.disabled &&
        !isBeforeDepartDate &&
        isLoaded &&
        day.dateOfMonth > 0 &&
        !!day.amount,
    };
  }

  selectDay(
    selectedDay: BestFareDay,
    isOneWay: boolean,
    iOS: boolean,
    selectedDepart: BestFareDay,
    selectedReturn: BestFareDay,
  ) {
    // Always change return value when depart fare is set
    // Only progress bar will have the ability to change depart fare
    if (selectedDepart && !isOneWay) {
      // Cannot select date prior to depart date
      if (isBefore(selectedDay.date, selectedDepart.date) || isOneWay) {
        return;
      }
      const prevReturnVal = selectedReturn;
      // clear out prev selection unless prev selection was same day depart/return
      if (!!selectedReturn && prevReturnVal.date !== selectedDepart.date) {
        prevReturnVal.selected = false;
      }
      this.bffStore.updateReturnBff({ ...selectedDay, selected: true });
    } else {
      if (!!selectedDepart) {
        selectedDepart.selected = false;
      }
      selectedDay.selected = true;
      // when depart day is selected, mouse is hovering over a same day selection
      this.bffStore.updateDepartBff({
        ...selectedDay,
        selected: true,
        sameDaySelected: !iOS && !isOneWay && !!selectedDay.amount,
      });
    }
  }
}
