import { traverseFlattenPathAware } from '@shared/ui/utils/global-utils/traverse-flatten-path-aware';
import { parseImagePaths } from '@shared/utils/parse-image-paths';
import { pipe } from 'ramda';

import {
  FooterResponse,
  FooterResponseRaw,
} from '../types/cms-footer.response.type';

export function parseFooter(input: FooterResponseRaw): FooterResponse {
  const parsed = pipe(liftRootEntries, flattenAll, parseImages)(input);
  return parsed;
}

function liftRootEntries(data: FooterResponseRaw): any {
  return data.component.reduce((acc, component) => {
    return {
      ...acc,
      [component.name]: component.data,
    };
  }, {});
}

function flattenAll(input: any) {
  const toFlatten = new Set([
    'footnoteSection.bottomFootNote',
    'footnoteSection.bottomFootNote.copy',
    'footnoteSection.utilityNavigation',
    'footnoteSection.utilityNavigation.image',
    'socialSection.icon',
    'socialSection.icon.socialIcons.image',
    'socialSection.social',
    'socialSection.social.image',
    'footnoteSection.topFootNote',
    'footnoteSection.bottomFootNote.copy.image',
  ]);

  const parseResult = traverseFlattenPathAware(toFlatten)(input);
  // TODO ? This should ideally be delegated to a tracing subsystem.
  parseResult.conflicts.forEach(x =>
    console.warn('CMS Header Parse Conflict : ', x),
  );
  return parseResult.result;
}

function parseImages(res: FooterResponse) {
  parseImagePaths(res, 'https://www.jetblue.com');
  return res;
}
