import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { isOver21 } from '@shared/trueblue/tb-logged-in/tb-logged-in.utils';
import { TrueBlueLoggedInSection } from '@shared/trueblue/tb-logged-in/types/new-tb-logged-in.response.type';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { PreferenceCenterFacade } from '@store/preference-center/preference-center.facade';
import { ProfileFacade } from '@store/profile/profile.facade';
import { combineLatest, filter, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'dot-tb-signed-in',
  templateUrl: './tb-signed-in.component.html',
  styleUrls: ['./tb-signed-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TbSignedInComponent implements OnDestroy {
  loggedInSection: TrueBlueLoggedInSection;
  @Input() set trueBlueLoggedInSection(
    trueBlueLoggedInSection: TrueBlueLoggedInSection,
  ) {
    this.loggedInSection = trueBlueLoggedInSection;
    this.bgColor =
      trueBlueLoggedInSection?.topSection?.children?.backgroundColor?.[0]
        ?.bgColorTrueblue;
    this.theme = trueBlueLoggedInSection?.topSection?.children?.trueBlueTheme;
    if (this.profile && this.profile.isMosaic.toLowerCase() === 'true') {
      this.bgColor =
        trueBlueLoggedInSection?.topSection?.children?.backgroundColor?.[0]
          ?.bgColorMosaic;
      this.theme = trueBlueLoggedInSection?.topSection?.children?.mosaicTheme;
    }
    const buttomStyle =
      trueBlueLoggedInSection?.topSection?.children?.link?.[0]?.buttonStyle;
    switch (buttomStyle) {
      case 'secondary':
        this.buttonType = 'ctaSecondary';
        break;
      case 'primary':
        this.buttonType = 'ctaPrimary';
        break;
      default:
        this.buttonType = buttomStyle;
    }
  }
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  travelBankBalance$: Observable<number>;
  profile: AuthProfile;
  profilePicture$: Observable<string>;
  firstName: string;
  points: string;
  tbNumber: string;
  firstNameInitial: string;
  lastNameInitial: string;
  enrollChild: boolean;
  bgColor: string;
  buttonType = 'standard';
  theme: string;
  mosaicTier: string;

  private subscriptions: Subscription = new Subscription();
  constructor(
    private authFacade: AuthFacade,
    private profileFacade: ProfileFacade,
    private preferenceCenterFacade: PreferenceCenterFacade,
  ) {
    this.travelBankBalance$ = this.preferenceCenterFacade.travelBankBalance;
    this.subscriptions.add(
      combineLatest([
        this.authFacade.authProfile.pipe(filter<AuthProfile>(Boolean)),
        this.profileFacade.profileIsLoading,
        this.profileFacade.memberProfile,
      ]).subscribe(([authProfile, tbProfileIsLoading, tbProfile]) => {
        this.profile = authProfile;
        this.mosaicTier = this.profile.mosaicTier;
        this.enrollChild = isOver21(this.profile.birthDate);
        this.firstName =
          this.profile.fname.charAt(0).toUpperCase() +
          this.profile.fname.slice(1);
        this.firstNameInitial = this.profile.fname.charAt(0).toUpperCase();
        this.lastNameInitial = this.profile.lname.charAt(0).toUpperCase();
        if (!!tbProfile) {
          const curLoyalty = (tbProfile?.loyalty || []).find(
            loyalty => loyalty.membershipId === this.profile.membershipid,
          );
          this.points = curLoyalty?.amount
            ? parseInt(curLoyalty.amount, 10).toLocaleString()
            : null;
        } else if (!tbProfileIsLoading) {
          this.points = this.profile.points
            ? parseInt(this.profile.points, 10).toLocaleString()
            : null;
        } else {
          this.points = null;
        }
        this.tbNumber = this.profile.membershipid;
      }),
    );
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleSignOut() {
    this.authFacade.redirectToLogoutPageOktaFlow();
  }
}
