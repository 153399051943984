import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { HttpService } from '@core/http';
import { FlightTrackerHttpErrorResponse } from '@dynamic-components/flight-tracker/flight-tracker.type';
import {
  FlightTrackerFlight,
  FlightTrackerResult,
} from '@dynamic-components/flight-tracker/types/flight-tracker-result.type';
import format from 'date-fns/format';
import { partition } from 'ramda';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FlightTrackerService {
  readonly headers = new HttpHeaders({
    'Content-Type': 'application/json',
    apikey: this.appConfig.flightStatusApiKey,
  });

  stopPolling: Subject<boolean>;

  constructor(
    private http: HttpService,
    private appConfig: AppConfigService,
  ) {
    this.stopPolling = new Subject();
  }

  getFlightStatusByFlight(
    departureDate: string,
    flightNumber,
  ): Observable<{
    departureDate: string;
    connectingFlightResults: FlightTrackerFlight[];
    directFlightResults: FlightTrackerFlight[];
    error?: FlightTrackerHttpErrorResponse;
  }> {
    return this.http
      .getObserveResponse<FlightTrackerResult>(
        `${this.appConfig.flightStatusServiceUrl}/get-by-number?date=${departureDate}&number=${flightNumber}`,
        { headers: this.headers },
        false,
        true,
      )
      .pipe(
        map(results => ({
          departureDate,
          ...this.sortFlights(results.body.flights),
        })),
        catchError(this.handleErrors),
      );
  }

  getFlightStatusByRoute(
    departureDate: string,
    depCode: string,
    arrCode: string,
  ): Observable<{
    departureDate: string;
    connectingFlightResults: FlightTrackerFlight[];
    directFlightResults: FlightTrackerFlight[];
    error?: FlightTrackerHttpErrorResponse;
  }> {
    return this.http
      .getObserveResponse<FlightTrackerResult>(
        `${this.appConfig.flightStatusServiceUrl}/get-by-citypair?departure=${depCode}&arrival=${arrCode}&date=${departureDate}`,
        { headers: this.headers },
        false,
        true,
      )
      .pipe(
        map(results => ({
          departureDate,
          ...this.sortFlights(results.body.flights),
        })),
        catchError(this.handleErrors),
      );
  }

  private sortFlights(flights: FlightTrackerFlight[]): {
    connectingFlightResults: FlightTrackerFlight[];
    directFlightResults: FlightTrackerFlight[];
  } {
    const [connectingFlightResults, directFlightResults] = partition(
      flight => flight.legs?.length > 1,
      flights,
    );
    return {
      connectingFlightResults,
      directFlightResults,
    };
  }

  private formatDate(date: Date) {
    return format(date, 'yyyy-MM-dd');
  }

  private handleErrors(error: any) {
    return of({
      departureDate: undefined,
      connectingFlightResults: [],
      directFlightResults: [],
      error,
    });
  }
}
