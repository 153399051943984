export function isOver21(birthDate: string | Date): boolean {
  const birthDateObj = new Date(birthDate);
  const today = new Date();
  const thisYearBirthday = new Date(
    today.getFullYear(),
    birthDateObj.getMonth(),
    birthDateObj.getDate(),
  );
  // Calculate age
  let age = today.getFullYear() - birthDateObj.getFullYear();
  // Adjust age if this year's birthday has not yet occurred
  if (today < thisYearBirthday) {
    age--;
  }
  return age > 21;
}
