import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { Observable } from 'rxjs';

import { ResetPasswordRequestedState } from '../types';
import { TempAPITokenHandler } from './../../shared/temp-api-token-handler/temp-api-token-handler';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordApi {
  private oktaUrl: string;

  constructor(
    private appConfig: AppConfigService,
    private tempAPITokenHandler: TempAPITokenHandler,
  ) {
    this.oktaUrl = this.appConfig.oktaJbWrapperServiceUrl;
  }

  resetPassword(emailId: string): Observable<any> {
    const requestUrl = `${this.oktaUrl}/okta/password-reset`;
    const requestBody = {
      userEmail: emailId,
    };

    return this.tempAPITokenHandler.post(
      this.appConfig.okta.resource.emailReset,
      requestUrl,
      requestBody,
      {
        observe: 'response',
      },
    );
  }

  resetUserPasswordRequest(
    resetPasswordObj: ResetPasswordRequestedState,
  ): Observable<any> {
    const requestUrl = `${this.oktaUrl}/okta/reset-user-password`;
    const requestBody = {
      newPassword: resetPasswordObj.newPassword,
      recoveryToken: resetPasswordObj.recoveryToken,
    };

    return this.tempAPITokenHandler.post(
      this.appConfig.okta.resource.forgotPasswordReset,
      requestUrl,
      requestBody,
      {
        observe: 'response',
      },
    );
  }
}
