<div
  *ngIf="!hideSuperGlobalNav"
  class="skeleton-super-global-nav bg-off-white"
></div>
<!-- Desktop -->
<div class="db-ns dn bg-core-blue page-margin z-max skeleton-navbar sticky">
  <div
    class="sticky-header-desktop-items flex flex-auto items-center center bg-core-blue jb-max-width"
  >
    <jb-logo></jb-logo>
  </div>
</div>
<!-- Mobile -->
<div
  class="z-max dn-ns db w-100 flex items-center mobile-header-wrapper bg-core-blue z-2 ph3 sticky"
>
  <jb-logo></jb-logo>
</div>
