import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, share, withLatestFrom } from 'rxjs/operators';

import { CreditCardRetrievalRequestState } from '../../store/preference-center/types/credit-card-retrieval-request-state.type';
import { AuthFacade } from '../auth/auth.facade';
import { PreferenceCenterApi } from './api/preference-center.api';
import {
  DeleteProfilePicture,
  GetFutureReservationStatus,
  GetMemberData,
  GetMemberPayment,
  GetMemberPreferences,
  GetProfilePicture,
  PreferenceCenterActions,
  SaveMemberData,
  SaveMemberPreferences,
  SavePaymentsInfo,
  SaveProfilePicture,
} from './preference-center.actions';
import { PreferenceCenterFacade } from './preference-center.facade';

@Injectable()
export class PreferenceCenterEffects {
  token: string;

  memberInfo$ = this.actions$.pipe(
    ofType<GetMemberData>(PreferenceCenterActions.GET_MEMBER_DATA),
    mergeMap(() => this.preferenceCenterApi.getMemberInfo()),
    share(),
  );

  profilePicture$ = this.actions$.pipe(
    ofType<GetProfilePicture>(PreferenceCenterActions.GET_PROFILE_PICTURE),
    mergeMap(() => this.preferenceCenterApi.getProfilePicture()),
    share(),
  );

  memberInfoSave$ = this.actions$.pipe(
    ofType<SaveMemberData>(PreferenceCenterActions.SAVE_MEMBER_DATA),
    mergeMap(({ payload }) => this.preferenceCenterApi.saveMemberInfo(payload)),
    share(),
  );

  memberPreferences$ = this.actions$.pipe(
    ofType<GetMemberPreferences>(
      PreferenceCenterActions.GET_MEMBER_PREFERENCES,
    ),
    mergeMap(() => this.preferenceCenterApi.getMemberPreferences()),
    share(),
  );

  memberPreferencesSave$ = this.actions$.pipe(
    ofType<SaveMemberPreferences>(
      PreferenceCenterActions.SAVE_MEMBER_PREFERENCES,
    ),
    mergeMap(({ payload }) =>
      this.preferenceCenterApi.saveMemberPreferences(payload),
    ),
    share(),
  );

  futureReservationStatus$ = this.actions$.pipe(
    ofType<GetFutureReservationStatus>(
      PreferenceCenterActions.GET_FUTURE_RESERVATION_STATUS,
    ),
    mergeMap(() => this.preferenceCenterApi.getFutureReservationStatus()),
    share(),
  );

  paymentInfo$ = this.actions$.pipe(
    ofType<GetMemberPayment>(PreferenceCenterActions.GET_MEMBER_PAYMENT),
    mergeMap(({ payload }) =>
      this.preferenceCenterApi.getSavedCreditCards(payload),
    ),
    share(),
  );

  paymentsInfoSave$ = this.actions$.pipe(
    ofType<SavePaymentsInfo>(PreferenceCenterActions.SAVE_PAYMENTS_INFO),
    mergeMap(({ payload }) =>
      this.preferenceCenterApi.savePaymentsInfo(payload),
    ),
    share(),
  );

  profilePictureSave$ = this.actions$.pipe(
    ofType<SaveProfilePicture>(PreferenceCenterActions.SAVE_PROFILE_PICTURE),
    mergeMap(({ payload }) =>
      this.preferenceCenterApi.saveProfilePicture(payload),
    ),
    share(),
  );

  deleteProfilePicture$ = this.actions$.pipe(
    ofType<DeleteProfilePicture>(
      PreferenceCenterActions.DELETE_STORE_PROFILE_PICTURE,
    ),
    mergeMap(() => this.preferenceCenterApi.deleteProfilePicture()),
    share(),
  );
  constructor(
    private actions$: Actions,
    public preferenceCenterApi: PreferenceCenterApi,
    private preferenceCenterFacade: PreferenceCenterFacade,
    private authFacade: AuthFacade,
  ) {}

  memeberInfoFetched$ = createEffect(() =>
    this.memberInfo$.pipe(
      filter(memberInfo => !Boolean(memberInfo.error)),
      map(memberInfo => {
        return this.preferenceCenterFacade.storeMemberData(memberInfo);
      }),
    ),
  );

  profilePictureFetched$ = createEffect(() =>
    this.profilePicture$.pipe(
      filter(profilePicture => !Boolean(profilePicture.error)),
      map(profilePicture => {
        return this.preferenceCenterFacade.storeProfilePicture(profilePicture);
      }),
    ),
  );

  memberInfoUpdated$ = createEffect(() =>
    this.memberInfoSave$.pipe(
      filter(memberInfo => !Boolean(memberInfo.error)),
      map(memberInfo => {
        return new GetMemberData();
      }),
    ),
  );

  memeberPreferencesFetched$ = createEffect(() =>
    this.memberPreferences$.pipe(
      filter(memberPreferences => !Boolean(memberPreferences.error)),
      map(memberPreferences => {
        return this.preferenceCenterFacade.storeMemberPreferencesData(
          memberPreferences,
        );
      }),
    ),
  );

  memberPreferencesUpdated$ = createEffect(() =>
    this.memberPreferencesSave$.pipe(
      filter(memberInfo => !Boolean(memberInfo.error)),
      map(memberInfo => {
        return new GetMemberPreferences();
      }),
    ),
  );

  futureReservationStatusFetched$ = createEffect(() =>
    this.futureReservationStatus$.pipe(
      filter(
        futureReservationStatus => !Boolean(futureReservationStatus.error),
      ),
      map(futureReservationStatus => {
        return this.preferenceCenterFacade.storeFutureReservationStatus(
          futureReservationStatus,
        );
      }),
    ),
  );

  paymentInfoFetched$ = createEffect(() =>
    this.paymentInfo$.pipe(
      filter(paymentInfo => !Boolean(paymentInfo.error)),
      map(paymentInfo => {
        return this.preferenceCenterFacade.storeMemberPayment(
          JSON.parse(paymentInfo),
        );
      }),
    ),
  );

  paymentsInfodated$ = createEffect(() =>
    this.paymentsInfoSave$.pipe(
      filter(memberInfo => !Boolean(memberInfo.error)),
      withLatestFrom(this.authFacade.oktaToken),
      map(([memberInfo, oktatoken]) => {
        const request: CreditCardRetrievalRequestState = {
          RequestToken: {
            Token: oktatoken,
            LogoutUrl: 'www.jetblue.com',
            RetrieveFamilyInformation: false,
          },
        };
        return new GetMemberPayment(request);
      }),
    ),
  );

  profilePictureUpdate$ = createEffect(() =>
    this.profilePictureSave$.pipe(
      filter(pic => !Boolean(pic.error)),
      map(pic => {
        return new GetProfilePicture();
      }),
    ),
  );

  profilePictureDelete$ = createEffect(() =>
    this.deleteProfilePicture$.pipe(
      filter(pic => !Boolean(pic.error)),
      map(pic => {
        return new GetProfilePicture();
      }),
    ),
  );
}
