<button
  (click)="toggleLoggedInModal()"
  class="pa0 logged-in-button flex items-center white no-underline profile-container relative"
>
  <span class="flex justify-end items-center mr0-ns nt2">
    <jb-icon
      *ngIf="profile.isMosaic === 'true'"
      name="avatarMosaicStatus"
      class="nr2 z-2 mosaic-logo mt3"
      width="22"
      label="mosaic"
      data-qaid="mosaicStatus"
    >
    </jb-icon>
    <span class="flex justify-center z-1 white f5 mr0-m br-100 mt2 mt0-ns">
      <jb-avatar
        *ngIf="!(profilePicture$ | async) && !profile.profileImage"
        initials="{{ firstNameInitial }}{{ lastNameInitial }}"
        size="small"
      >
      </jb-avatar>
      <jb-avatar
        size="small"
        *ngIf="(profilePicture$ | async) || profile.profileImage as pic"
        [src]="pic"
      >
      </jb-avatar>
    </span>
    <hr
      [ngClass]="(isModalOpen$ | async) ? 'w-100' : 'w-0'"
      class="db absolute bottom-0 hr-underline"
    />
  </span>
</button>
<div *ngIf="isModalOpen$ | async" class="logged-in-modal bg-white">
  <dot-tb-signed-in
    [trueBlueLoggedInSection]="trueBlueLoggedInSection"
  ></dot-tb-signed-in>
</div>
