import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BodyTextContainerComponent } from '@dynamic-components/body-text-container';
import { UIModule } from '@shared/ui';

import { TbBottomSectionModule } from '../shared/tb-bottom-section/tb-bottom-section.module';
import { TbSignedInComponent } from './tb-signed-in.component';

const MODULES = [
  CommonModule,
  UIModule,
  BodyTextContainerComponent,
  TbBottomSectionModule,
];

const DECLARATIONS = [TbSignedInComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class TbSignedInModule {}
