import { isPlatformServer } from '@angular/common';

/**
 * Base wrapper class for LocalStorage and SessionStorage
 */
export class StorageService {
  storage: Storage;
  storageType: 'local' | 'session';
  platformId: Object; // see constructor in child class

  clear() {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return;
    }
    this.storage.clear();
  }

  getItem<T>(key: string): T {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return null;
    }
    try {
      return JSON.parse(this.storage.getItem(key)) as T;
    } catch (error) {
      console.warn(
        `Failed to get item from ${this.storageType}storage:`,
        error,
      );
      return undefined;
    }
  }

  key(index: number): string {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return null;
    }
    return this.storage.key(index);
  }

  removeItem(key: string) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return;
    }
    try {
      this.storage.removeItem(key);
    } catch (error) {
      console.warn(
        `Failed to remove item from ${this.storageType}storage:`,
        error,
      );
    }
  }

  setItem(key: string, data: any) {
    if (isPlatformServer(this.platformId)) {
      // req for ssr
      return;
    }
    try {
      this.storage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.warn(`Failed to set item to ${this.storageType}storage:`, error);
    }
  }
}
