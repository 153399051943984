import { Inject, Injectable } from '@angular/core';
import { WindowService } from '@core/window-actions';

import { DOCUMENT } from '../injection-tokens';

/**
 * Utility service that allows you to operate with DOM
 */

@Injectable({ providedIn: 'root' })
export class DocumentService {
  html;
  /** Width of the useable document */
  width: number;
  /** Height of the useable document */
  height: number;

  constructor(
    @Inject(DOCUMENT) private document,
    private windowService: WindowService,
  ) {
    this.html = this.document.documentElement;

    this.windowService.getResizeEvent().subscribe(event => {
      this.setDocWidthHeight(
        event.currentTarget.innerWidth,
        event.currentTarget.innerHeight,
      );
    });
  }

  setDocWidthHeight(width, height) {
    this.html.style.setProperty('--viewportWidthOnDocument', `${width}px`);
    this.html.style.setProperty('--viewportHeightOnDocument', `${height}px`);

    this.width = width;
    this.height = height;
  }

  // returns an object of sticky headers for desktop and mobile (will need this for bff as well)
  getStickyHeaders() {
    const headerDesktop = this.html.querySelector('.sticky-header-desktop');
    const headerMobile = this.html.querySelector('.sticky-header-mobile');

    return !!headerDesktop || !!headerMobile
      ? {
          desktop: headerDesktop,
          mobile: headerMobile,
        }
      : undefined;
  }
}
