import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { CmsApiBuilderService } from '@core/cms/builders/cms-api-builder.service';
import { HttpService } from '@core/http';
import { LanguageService } from '@core/language/language.service';
import { LegalCopy } from '@dynamic-components/isolated-flight-block-container/flight-block.type';
import { FlightDeal } from '@shared/types/flight-deal.type';
import { Observable } from 'rxjs';

import { GeoData } from './types/geo-data.type';

@Injectable({ providedIn: 'root' })
export class DealsService {
  constructor(
    private appConfig: AppConfigService,
    private httpService: HttpService,
    private cmsApiBuilderService: CmsApiBuilderService,
    private languageService: LanguageService,
  ) {}

  /**
   * Makes a call to the api to retrieve an array of Deals
   * Forwards errors.
   * @returns {Observable<FlightDeal[]>}
   */
  getDeals(
    destinationCode: string,
    parms: GeoData,
    isPointDeals = false,
  ): Observable<FlightDeal[]> {
    /**
     * Destination Airport IATA-Code i.e. FLL (Fort Lauderdale)
     *   Params ::
     *   latitude - latitude coords
     *   longitude - longitude coords
     *   radius - number of miles to search from lat/long position - (optional: Default 300)
     */
    let params = new HttpParams();
    for (const key in parms) {
      if (parms.hasOwnProperty(key) && parms[key]) {
        params = params.append(key, parms[key]);
      }
    }

    return this.httpService.get<any>(
      `${
        this.appConfig.dealsServiceUrl
      }/byGeo/${destinationCode}?points=${isPointDeals}&${params.toString()}`,
    );
  }

  getDealsByOrigin(
    origin,
    destination,
    isPointDeals = false,
  ): Observable<FlightDeal[]> {
    return this.httpService.get<any>(
      `${this.appConfig.dealsServiceUrl}/byOriginandDestination/${origin}/${destination}?points=${isPointDeals}`,
    );
  }

  getFlightsDeals(
    destination,
    isPointDeals = false,
    count = 0,
  ): Observable<FlightDeal[]> {
    if (count > 0) {
      return this.httpService.get<any>(
        `${this.appConfig.flightListServiceUrl}/byOrigin/${destination}?points=${isPointDeals}&count=${count}`,
      );
    } else {
      return this.httpService.get<any>(
        `${this.appConfig.flightListServiceUrl}/byOrigin/${destination}?points=${isPointDeals}`,
      );
    }
  }

  getLegalCopy(): Observable<LegalCopy> {
    const url = this.cmsApiBuilderService.buildCmsUrl({
      reqPath: '/destCommon/customDelivery/flightGenericRestrictions',
      ab: '',
      translationEnabled: this.appConfig.useExperimentalTranslation,
      translateLanguage: this.languageService.getLanguage(),
    });
    return this.httpService.get<LegalCopy>(url);
  }
}
