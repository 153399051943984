import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
} from '@angular/core';
import { AppConfigService } from '@core/app-config';
import { DateService } from '@core/date/date.service';
import { WindowService } from '@core/window-actions/window.service';
import { BffStore } from '@feature/bff/bff.component-store';
import { CalendarMonth } from '@feature/bff/bff.component-store.types';
import { ComponentStore } from '@ngrx/component-store';
import { BOOKER_CONFIG } from '@shared/booker';
import { isOneWayTrip } from '@shared/booker/utils/booker-utils';
import { Airport } from '@store/origins/types';
import { ProfileFacade } from '@store/profile/profile.facade';
import { RouterFacade } from '@store/router/router.facade';
import { ScheduleExtensionFacade } from '@store/schedule-extension/schedule-extension.facade';
import { isAfter, isBefore, startOfToday, subDays } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { IntersectionStatus } from './../intersection-observer/from-intersection-observer';
import {
  calendarAggregatorComponentInitialState,
  CalendarAggregatorComponentState,
  CalendarAggregatorComponentViewModel,
} from './calendar-aggregator.component.state';
import { parseDateQueryParameter } from './calendar-aggregator.utils';
import { CalendarAggregator } from './types';

/**
 * @description Wrapper component for calendar component, responsible for showing 1-2 calendar's at once
 * correctly on different viewports
 * @WhereIsItUsed best fare calendar
 */
@Component({
  selector: 'jb-calendar-aggregator',
  templateUrl: './calendar-aggregator.component.html',
  styleUrls: ['./calendar-aggregator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore],
})
export class CalendarAggregatorComponent {
  @Input() set content(content: CalendarAggregator) {
    this.store.patchState({ content });
  }

  @Input() set dealStartDate(dealStartDate: Date) {
    this.store.patchState({ dealStartDate });
  }

  @Input() formValue: any;

  intersectionStatus = IntersectionStatus;
  canAutoLoad: boolean;

  readonly isSeasonalRoute$ = this.store.select(
    this.store.select(state => state.form?.cityPair?.origin),
    this.store.select(state => state.form?.cityPair?.destination),
    (fromAirport, toAirport) => this.getIsSeasonalRoute(fromAirport, toAirport),
  );

  readonly minDate$ = this.store.select(
    this.store.select(state => state.paramDate),
    this.store.select(state => state.today),
    this.store
      .select(state => state.maxDate)
      .pipe(filter(maxDate => !!maxDate)),
    (paramDate, today, maxDate) => this.getMinDate(paramDate, today, maxDate),
  );

  trackMonthsBy(index, month: CalendarMonth): string {
    // concat numbers together instead of add, and then convert back to a number
    return '' + month.year + month.month;
  }

  readonly vm$: Observable<CalendarAggregatorComponentViewModel> =
    combineLatest([this.store.state$, this.isSeasonalRoute$]).pipe(
      map(([state, isSeasonalRoute]) => ({
        ...state,
        isSeasonalRoute,
      })),
    );

  constructor(
    private windowService: WindowService,
    private profileFacade: ProfileFacade,
    private routerFacade: RouterFacade,
    private scheduleExtensionFacade: ScheduleExtensionFacade,
    private zone: NgZone,
    private dateService: DateService,
    private bffStore: BffStore,
    private appConfigService: AppConfigService,
    private store: ComponentStore<CalendarAggregatorComponentState>,
  ) {
    this.store.setState(calendarAggregatorComponentInitialState);

    this.store.patchState({ today: startOfToday() });

    this.store.patchState(
      this.profileFacade.dateOfBirth.pipe(
        map(dateOfBirth => ({ dateOfBirth })),
      ),
    );

    this.store.patchState(
      this.bffStore.calendars$.pipe(map(calendars => ({ calendars }))),
    );

    this.store.patchState(
      this.windowService.isMobile.pipe(map(isMobile => ({ isMobile }))),
    );

    this.store.patchState(
      this.routerFacade
        .queryParam(BOOKER_CONFIG.URL_PARAMETERS.DEPARTURE_DATE)
        .pipe(
          map(parseDateQueryParameter),
          filter(paramDate => !isNaN(paramDate?.valueOf())),
          map(paramDate => ({ paramDate })),
        ),
    );

    this.store.patchState(
      this.scheduleExtensionFacade.extensionDate.pipe(
        filter(scheduleExtension => !!scheduleExtension?.date),
        map(scheduleExtension => ({ maxDate: scheduleExtension.date })),
      ),
    );

    this.store
      .select(
        this.store.select(state => state.content),
        this.store.select(state => state.maxDate),
        (content, maxDate) => ({
          content,
          maxDate,
        }),
      )
      .pipe(
        filter(({ content, maxDate }) => !!maxDate && !!content?.holidays),
        first(),
      )
      .subscribe(({ maxDate, content }) => {
        this.bffStore.initializeCalendars({
          fromDate: this.dateService.getNewDate(),
          maxDate,
          holidays: content.holidays,
        });
      });

    this.minDate$
      .pipe(
        filter(minDate => !!minDate),
        first(),
      )
      .subscribe(minDate => {
        this.bffStore.fetchFares({
          month: minDate.getMonth(),
          year: minDate.getFullYear(),
          scrollToCalendar: true,
          additionalMonths:
            (this.appConfigService.bffDefaultMonthsToLoad ?? 1) - 1,
        });
      });

    this.canAutoLoad = this.appConfigService.bffAutoLoadMonths;
  }

  onVisibilityChanged(calendar: any, status: IntersectionStatus): void {
    const departBffSelectedObj = this.isDepartureBffSelected(
      this.formValue.departBff,
    );
    const returnBffSelected = this.isReturnBffDateSelected(
      this.formValue.returnBff,
    );

    if (departBffSelectedObj.departDateSelected && !returnBffSelected) {
      const departBffDate = departBffSelectedObj.departDate;
      const calendarMonthToCompare = new Date(calendar.year, calendar.month, 1);
      const isOneWay = isOneWayTrip(this.formValue.tripType);

      const isCalendarBeforeDepartDate = isBefore(
        calendarMonthToCompare,
        departBffDate,
      );

      if (
        !calendar.isLoaded &&
        !calendar.isLoading &&
        ((isOneWay && isCalendarBeforeDepartDate) ||
          !isCalendarBeforeDepartDate) &&
        status === IntersectionStatus.Visible
      ) {
        this.fetchFares(calendar.month, calendar.year);
      }
    } else if (
      !calendar.isLoaded &&
      !calendar.isLoading &&
      !returnBffSelected &&
      status === IntersectionStatus.Visible
    ) {
      this.fetchFares(calendar.month, calendar.year);
    }
  }

  fetchFares(month: number, year: number) {
    this.bffStore.fetchFares({ month, year, scrollToCalendar: false });
  }

  getMinDate(paramDate: Date, today: Date, maxDate: Date) {
    return paramDate && isAfter(paramDate, today)
      ? isBefore(paramDate, maxDate)
        ? paramDate
        : subDays(maxDate, 1) // if route parameter is past the max date, open last calendar
      : today;
  }

  getIsSeasonalRoute(fromAirport: Airport, toAirport: Airport) {
    return fromAirport?.seasonal || toAirport?.seasonal;
  }

  isReturnBffDateSelected(returnBff): boolean {
    let returnDateSelected: boolean;
    if (returnBff == null || typeof returnBff === 'undefined') {
      returnDateSelected = false;
      return returnDateSelected;
    } else {
      returnDateSelected = true;
      return returnDateSelected;
    }
  }

  isDepartureBffSelected(departBff): {
    departDateSelected: boolean;
    departDate?: Date;
  } {
    let departDateSelectedObj: {
      departDateSelected: boolean;
      departDate?: Date;
    };

    if (departBff == null || typeof departBff === 'undefined') {
      departDateSelectedObj = {
        departDateSelected: false,
      };
      return departDateSelectedObj;
    } else {
      departDateSelectedObj = {
        departDateSelected: true,
        departDate: departBff.date,
      };
      return departDateSelectedObj;
    }
  }
}
