import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IntersectionObserverDirective } from './intersection-observer.directive';

const MODULES = [CommonModule];

const DECLARATIONS = [IntersectionObserverDirective];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
})
export class IntersectionObserverModule {}
