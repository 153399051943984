<!-- Dynamic route skeletons -->
<div class="w-100" *ngIf="showSkeletons$ | async" [ngSwitch]="dynamicTemplate">
  <jb-main-skeleton *ngSwitchCase="'main'"></jb-main-skeleton>
  <jb-info-skeleton *ngSwitchCase="'info'"></jb-info-skeleton>
  <jb-overview-skeleton *ngSwitchCase="'overview'"></jb-overview-skeleton>
  <jb-promo-skeleton *ngSwitchCase="'promo'"></jb-promo-skeleton>
  <jb-article-skeleton *ngSwitchCase="'article'"></jb-article-skeleton>
  <jb-article-skeleton *ngSwitchCase="'simple'"></jb-article-skeleton>
  <jb-hero-skeleton *ngSwitchCase="'faresale'"></jb-hero-skeleton>
  <jb-list-template-skeleton *ngSwitchCase="'list'"></jb-list-template-skeleton>
  <jb-signin-skeleton *ngSwitchCase="'signin'"></jb-signin-skeleton>
  <div
    *ngSwitchDefault
    class="page-margin flex justify-center items-center vh-100"
  >
    <jb-icon
      class="fill-core-blue"
      name="loading"
      height="5em"
      width="5em"
    ></jb-icon>
  </div>
</div>
<jb-renderer-template
  class="w-100"
  [ngClass]="{ 'screen-reader-only': !(isRendered$ | async) }"
  *ngIf="componentsToRender && componentRegistry"
  [components]="componentsToRender"
  [registry]="componentRegistry"
  [template]="dynamicTemplate"
  [priority]="'primary'"
></jb-renderer-template>
