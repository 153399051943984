import { Injectable } from '@angular/core';
import { FlightTrackerCopy } from '@dynamic-components/flight-tracker/types/flight-tracker-copy.type';
import { BookerResponse } from '@shared/booker';
import { StoreService } from '@store/shared/store.service';

import {
  FormSubmittingAction,
  RequestFlightStatusByNumber,
  RequestFlightStatusByNumberPoll,
  RequestFlightStatusByRoute,
  SetFlightStatus,
  SetTabId,
  UpdateBookerResponse,
  UpdateDestinationAirports,
  UpdateFlightTrackerResponse,
  UpdateOriginAirports,
} from './flight-tracker.actions';
import * as FlightTrackerSelectors from './flight-tracker.selectors';
import { FlightResults } from './types';

@Injectable({ providedIn: 'root' })
export class FlightTrackerFacade {
  constructor(private storeService: StoreService) {}

  flightTracker = this.storeService.getState(
    FlightTrackerSelectors.flightTracker,
  );

  getAllFlightData = this.storeService.getState(
    FlightTrackerSelectors.getAllFlightData,
  );

  selectedTabId = this.storeService.getState(
    FlightTrackerSelectors.selectedTabId,
  );

  isFormSubmitting = this.storeService.getState(
    FlightTrackerSelectors.isFormSubmitting,
  );

  dateOptions = this.storeService.getState(FlightTrackerSelectors.dateOptions);

  today = this.storeService.getState(FlightTrackerSelectors.today);

  flightTrackerResponse = this.storeService.getState(
    FlightTrackerSelectors.flightTrackerResponse,
  );

  destinationAirportHashMap = this.storeService.getState(
    FlightTrackerSelectors.destinationAirportHashMap,
  );

  originAirportsHashMap = this.storeService.getState(
    FlightTrackerSelectors.originAirportHashMap,
  );

  requestFlightStatusByNumber(flightNumber: string, flightDate: string) {
    this.storeService.dispatchAction(
      new RequestFlightStatusByNumber({ flightNumber, flightDate }),
    );
  }

  requestFlightStatusByNumberPoll(
    flightNumber: string,
    flightDate: string,
    pollingInterval: number,
  ) {
    this.storeService.dispatchAction(
      new RequestFlightStatusByNumberPoll({
        flightNumber,
        flightDate,
        pollingInterval,
      }),
    );
  }

  requestFlightStatusByRoute(
    departureDate: string,
    originCode: string,
    destinationCode: string,
  ) {
    this.storeService.dispatchAction(
      new RequestFlightStatusByRoute({
        departureDate,
        originCode,
        destinationCode,
      }),
    );
  }

  setFlightStatus(flightStatus: FlightResults) {
    this.storeService.dispatchAction(new SetFlightStatus(flightStatus));
  }

  setSelectedTabId(payload: number) {
    this.storeService.dispatchAction(new SetTabId(payload));
  }

  formSubmitting(payload: boolean) {
    this.storeService.dispatchAction(new FormSubmittingAction(payload));
  }

  setOrigins(payload: any) {
    this.storeService.dispatchAction(new UpdateOriginAirports(payload));
  }

  setDestinations(payload: any) {
    this.storeService.dispatchAction(new UpdateDestinationAirports(payload));
  }

  setFlightTrackerResponse(payload: FlightTrackerCopy) {
    this.storeService.dispatchAction(new UpdateFlightTrackerResponse(payload));
  }

  setBookerResponse(payload: BookerResponse) {
    this.storeService.dispatchAction(new UpdateBookerResponse(payload));
  }
}
