import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { DynamicComponentsService } from '@core/dynamic-components';
import { ComponentRegistryService } from '@core/dynamic-components/component-registry.service';
import { CMSComponentData } from '@core/dynamic-components/types';
import { ScrollService } from '@core/scroll';
import { PageHeaderContainerComponent } from '@dynamic-components/page-header-container/page-header-container.component';
import { RendererTemplateComponent } from '@dynamic-templates/renderer-template/renderer-template.component';
import { Templates } from '@dynamic-templates/types/templates.type';
import { SkeletonModule } from '@skeleton/skeleton.module';
import { AnalyticsFacade } from '@store/analytics/analytics.facade';
import { PAGES_WITH_THEIR_OWN_CONTENT_DONE_LOADING_CALL } from '@store/analytics/utils';
import { GlobalFacade } from '@store/global/global.facade';
import { RouterFacade } from '@store/router/router.facade';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  delay,
  filter,
  finalize,
  map,
  take,
  withLatestFrom,
} from 'rxjs/operators';

/**
 * @description Renderer template wrapper with skeletons for page template types
 * @WhereIsItUsed Dynamic pages
 */
@Component({
  standalone: true,
  imports: [SkeletonModule, RendererTemplateComponent],
  selector: 'jb-basic-template',
  templateUrl: './basic-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicTemplateComponent implements OnInit, AfterViewInit {
  componentRegistry;
  componentsToRender;
  dynamicTemplate: keyof Templates | string;
  componentCreated = new Subject<ComponentRef<any>>();
  subscriptions = new Subscription();

  isRendered$: Observable<boolean>;
  showSkeletons$: Observable<boolean>;
  isCookieConsentOpen$: Observable<boolean>;
  private readyToInit = new BehaviorSubject({
    ready: false,
    value: {},
    pageTitle: undefined,
    dynamicTemplate: '',
  });

  constructor(
    private dynamicComponentsService: DynamicComponentsService,
    private componentRegistryService: ComponentRegistryService,
    private globalFacade: GlobalFacade,
    private routerFacade: RouterFacade,
    private element: ElementRef,
    private scrollService: ScrollService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private analyticsFacade: AnalyticsFacade,
  ) {
    this.routerFacade.currentPathTemplate.subscribe(template => {
      this.dynamicTemplate = template;
      this.cdRef.markForCheck();
    });

    // observes when dynamic component metadata, from cms, is loaded
    const defaultRendererProcess$ = this.globalFacade.template.pipe(
      filter(Boolean),
      withLatestFrom(this.globalFacade.lastRequestedEndpoint),
      filter(
        ([templateData, lastRequestedEndpoint]: [any, string]) =>
          templateData.endpoint === lastRequestedEndpoint,
      ),
    );

    this.subscriptions.add(
      defaultRendererProcess$.subscribe(
        ([templateData]) => {
          // dynamic component metadata is ready
          const value =
            this.dynamicTemplate === 'signin'
              ? [templateData]
              : templateData.area;
          const pageTitle = templateData.data
            ? templateData.data.title
            : undefined;
          this.readyToInit.next({
            ready: true,
            value,
            pageTitle,
            dynamicTemplate: this.dynamicTemplate,
          });
          this.cdRef.markForCheck();
        },
        err => {
          console.error(err);
        },
      ),
    );

    // More or less reliable, but not 100% solution to detect if page is rendered.
    // It fires when all the dynamic components are inserted into the DOM,
    // but not necessarily when they finished rendering themselves.
    this.isRendered$ = this.globalFacade.templateRendered.pipe();

    // show skeletons after page data has come in and isRendered is false
    // page data required to resolve so header knows whether or not to show
    // using default values results in page jumping when the showHeader property
    // resolves and the default was incorrect
    this.showSkeletons$ = combineLatest([
      this.isRendered$.pipe(map(value => !value)),
      this.globalFacade.pageData.pipe(filter(value => !!value)),
    ]).pipe(map(arr => arr.every(v => !!v)));

    this.isCookieConsentOpen$ = new Observable<boolean>(observer => {
      const fsInterval = setInterval(() => {
        if (
          document.getElementsByClassName('truste_box_overlay_inner').length
        ) {
          observer.next(true);
        } else {
          clearInterval(fsInterval);
          observer.next(false);
        }
      }, 1000);
    });
  }

  ngOnInit() {
    this.globalFacade.setPrimaryRenderingStatus(false);

    combineLatest([this.isRendered$, this.globalFacade.template])
      .pipe(
        filter(([isRendered, template]) => isRendered && !!template),
        take(1),
        map(([_, template]) => template.name),
      )
      .subscribe(templateName => {
        if (
          !PAGES_WITH_THEIR_OWN_CONTENT_DONE_LOADING_CALL.includes(templateName)
        ) {
          this.analyticsFacade.priorityContentLoadFinishEventCall();
        }
        this.analyticsFacade.allContentLoadFinishEventCall();
      });

    this.subscriptions.add(
      this.componentCreated
        .pipe(
          filter(
            component =>
              component.instance instanceof PageHeaderContainerComponent,
          ),
          delay(0),
        )
        .subscribe(
          component =>
            (component.instance.sectionsHTML =
              this.element.nativeElement.querySelectorAll('.list-of-sections')),
        ),
    );
  }

  ngAfterViewInit() {
    this.initComponentsAfterViewInit();

    const subscribeToAnchorScroll = () => {
      this.subscriptions.add(
        this.router.events // handle same route fragment navigation
          .subscribe(event => {
            if (event instanceof Scroll && event.anchor) {
              this.scrollService.scrollToWithHeaderOffset(event.anchor);
            }
          }),
      );
    };

    combineLatest([
      // Something immediately sets this to null. So we just take first one.
      this.route.fragment.pipe(take(1)),
      this.isCookieConsentOpen$,
      this.isRendered$,
    ]) // get latest emission from either source
      .pipe(
        filter(
          ([_, isCookieConsentOpen, isRendered]) =>
            !isCookieConsentOpen && isRendered,
        ),
        map(([fragment]) => fragment), // map to only return fragment and remove query params
        take(1), // handle the initial scroll to fragment upon page load
        finalize(subscribeToAnchorScroll), // handle subsequent router link clicks to scroll to fragment
      )
      .subscribe(fragment => {
        if (fragment) {
          // Need time for DOM to settle. So that we don't have content jumping.
          // Smooth scrolling is animated over a short period, which might provide just enough delay to avoid a race condition with the browser's scroll restoration.
          requestAnimationFrame(() => {
            this.scrollService.scrollToWithHeaderOffset(
              fragment.split('?')[0],
              0,
              'smooth',
            );
          });
        }
      });
  }

  initComponentsAfterViewInit() {
    // when dynamic component metadata is ready "readyToInit" will fire
    this.subscriptions.add(
      this.readyToInit
        .pipe(filter(({ ready }) => ready))
        .subscribe(({ value, pageTitle, dynamicTemplate }) => {
          this.initComponents(value, pageTitle, dynamicTemplate);
          this.cdRef.markForCheck();
        }),
    );
  }

  initComponents(areas, pageTitle, dynamicTemplate) {
    // group dynamic components to render then pass them to renderer-template
    this.componentRegistry = this.componentRegistryService.buildRegistry(areas);
    this.componentsToRender = areas.reduce((acc, area) => {
      let components: CMSComponentData[] = area.component || [];
      // TODO: remove this workaround
      components = this.dynamicComponentsService.insertPageHeader(
        components,
        pageTitle,
        dynamicTemplate,
      );
      return [...acc, ...components];
    }, []);
  }
}
