type ComponentMap = {
  [key: string]: {
    loadModule: () => Promise<any>;
  };
};

/*
  ! * IMPORTANT * !
  All dynamic components must be added here.
*/
const _map: ComponentMap = {
  breadcrumbs: {
    loadModule: () =>
      import(
        '../../dynamic-components/breadcrumb-container/breadcrumb-container.module'
      ).then(m => m.BreadcrumbContainerModule),
  },
  hero: {
    loadModule: () =>
      import(
        '../../dynamic-components/hero-container/hero-container.module'
      ).then(m => m.HeroContainerModule),
  },
  pageHeader: {
    loadModule: () =>
      import(
        '../../dynamic-components/page-header-container/page-header-container.module'
      ).then(m => m.PageHeaderContainerModule),
  },
  primaryBlock: {
    loadModule: () =>
      import(
        '../../dynamic-components/primary-nav-block-list-container/primary-nav-block-list-container.module'
      ).then(m => m.PrimaryNavBlockListContainerModule),
  },
  secondaryBlock: {
    loadModule: () =>
      import(
        '../../dynamic-components/secondary-nav-block-list-container/secondary-nav-block-list-container.module'
      ).then(m => m.SecondaryNavBlockListContainerModule),
  },
  legalBlock: {
    loadModule: () =>
      import(
        '../../dynamic-components/dynamic-jb-legal-block/dynamic-jb-legal-block.module'
      ).then(m => m.DynamicJbLegalBlockModule),
  },
  sectionHeader: {
    loadModule: () =>
      import(
        '../../dynamic-components/section-header-container/section-header-container.module'
      ).then(m => m.SectionHeaderContainerModule),
  },
  fareSaleBlock: {
    loadModule: () =>
      import(
        '../../dynamic-components/fare-sale-block/fare-sale-block.module'
      ).then(m => m.FareSaleBlockModule),
  },
  pageRelatedContent: {
    loadModule: () =>
      import(
        '../../dynamic-components/page-related-content-block-container/page-related-content-block-container.module'
      ).then(m => m.PageRelatedContentBlockContainerModule),
  },
  promoBlock: {
    loadModule: () =>
      import('../../dynamic-components/promo-block/promo-block.module').then(
        m => m.PromoBlockModule,
      ),
  },
  travelAlert: {
    loadModule: () =>
      import('../../shared/travel-alerts/travel-alerts.module').then(
        m => m.TravelAlertsModule,
      ),
  },
  marketingPrimary: {
    loadModule: () =>
      import(
        '../../dynamic-components/homepage/marketing-primary/marketing-primary.module'
      ).then(m => m.MarketingPrimaryModule),
  },
  marketingSecondary: {
    loadModule: () =>
      import(
        '../../dynamic-components/homepage/marketing-secondary/marketing-secondary.module'
      ).then(m => m.MarketingSecondaryModule),
  },
  myTrips: {
    loadModule: () =>
      import(
        '../../dynamic-components/homepage/travel-modes/travel-modes.module'
      ).then(m => m.TravelModesContainerModule),
  },
  tabbedNavigationComponent: {
    loadModule: () =>
      import(
        '../../dynamic-components/homepage/tab-component/tab-component.module'
      ).then(m => m.TabComponentModule),
  },
  loyalty: {
    loadModule: () =>
      import(
        '../../dynamic-components/homepage/loyalty-pod/loyalty-pod.module'
      ).then(m => m.LoyaltyPodModule),
  },
  bookRecall: {
    loadModule: () =>
      import(
        '../../dynamic-components/homepage/book-recall/book-recall.module'
      ).then(m => m.BookRecallModule),
  },
  calloutBlock: {
    loadModule: () =>
      import(
        '../../dynamic-components/callout-block-container/callout-block-container.module'
      ).then(m => m.JbCalloutBlockContainerModule),
  },
  productBlock: {
    loadModule: () =>
      import(
        '../../dynamic-components/isolated-product-block-container/isolated-product-block-container.module'
      ).then(m => m.IsolatedProductBlockContainerModule),
  },
  flightBlock: {
    loadModule: () =>
      import(
        '../../dynamic-components/isolated-flight-block-container/isolated-flight-block-container.module'
      ).then(m => m.IsolatedFlightBlockContainerModule),
  },
  bodyText: {
    loadModule: () =>
      import(
        '../../dynamic-components/body-text-container/body-text-container.component'
      ).then(c => c.BodyTextContainerComponent),
  },
  bodyTextWithImage: {
    loadModule: () =>
      import(
        '../../dynamic-components/body-text-image-container/body-text-image-container.module'
      ).then(m => m.BodyTextImageContainerModule),
  },
  iconArray: {
    loadModule: () =>
      import(
        '../../dynamic-components/logo-icon-array-container/logo-icon-array-container.module'
      ).then(m => m.LogoIconArrayContainerModule),
  },
  contextualHelp: {
    loadModule: () =>
      import(
        '../../dynamic-components/contextual-help-container/contextual-help-container.module'
      ).then(m => m.ContextualHelpContainerModule),
  },
  accordion: {
    loadModule: () =>
      import(
        '../../dynamic-components/accordion-container/accordion-container.module'
      ).then(m => m.AccordionContainerModule),
  },
  image: {
    loadModule: () =>
      import(
        '../../dynamic-components/image-container/image-container.module'
      ).then(m => m.ImageContainerModule),
  },
  video: {
    loadModule: () =>
      import(
        '../../dynamic-components/video-container/video-container.module'
      ).then(m => m.VideoContainerModule),
  },
  list: {
    loadModule: () =>
      import(
        '../../dynamic-components/list-container/list-container.module'
      ).then(m => m.ListContainerModule),
  },
  table: {
    loadModule: () =>
      import(
        '../../dynamic-components/table-container/table-container.module'
      ).then(m => m.TableContainerModule),
  },
  jtpContainer: {
    loadModule: () =>
      import(
        '../../dynamic-components/jtp-sdk-container/jtp-sdk-container.module'
      ).then(m => m.JtpSdkContainerModule),
  },
  emContainer: {
    loadModule: () =>
      import(
        '../../dynamic-components/every-mundo-container/every-mundo-container.module'
      ).then(m => m.EveryMundoContainerModule),
  },
  sectionRelatedContent: {
    loadModule: () =>
      import(
        '../../dynamic-components/section-related-content-block-container/section-related-content-block-container.module'
      ).then(m => m.SectionRelatedContentBlockContainerModule),
  },
  form: {
    loadModule: () =>
      import(
        '../../dynamic-components/form-container/form-container.module'
      ).then(m => m.FormContainerModule),
  },
  login: {
    loadModule: () =>
      import('../../feature/sign-in-page/sign-in-page.module').then(
        m => m.SignInPageModule,
      ),
  },
  promoHeader: {
    loadModule: () =>
      import(
        '../../dynamic-components/promo-header-container/promo-header-container.module'
      ).then(m => m.PromoHeaderContainerModule),
  },
  contactUsReceipt: {
    loadModule: () =>
      import(
        '../../dynamic-components/contact-us-receipt/contact-us-receipt.module'
      ).then(m => m.ContactUsReceiptModule),
  },
  groupTravel: {
    loadModule: () =>
      import('../../dynamic-components/group-travel/group-travel.module').then(
        m => m.GroupTravelModule,
      ),
  },
  contactUs: {
    loadModule: () =>
      import('../../dynamic-components/contact-us/contact-us.module').then(
        m => m.ContactUsModule,
      ),
  },
  bookerAir: {
    loadModule: () =>
      import('../../dynamic-components/booker-v2/booker-air.module').then(
        m => m.BookerAirModule,
      ),
  },
  bookerHotelPoints: {
    loadModule: () =>
      import('../../dynamic-components/booker-v2/booker-hotel.module').then(
        m => m.BookerHotelModule,
      ),
  },
  travelBankLookup: {
    loadModule: () =>
      import(
        '../../dynamic-components/travel-bank-lookup/travel-bank-lookup.module'
      ).then(m => m.TravelBankLookupModule),
  },
  bookerHotels: {
    loadModule: () =>
      import(
        '../../dynamic-components/booker-v2/bookers/flights-hotel/booker-flights-hotel.module'
      ).then(m => m.BookerFlightsHotelModule),
  },
  search: {
    loadModule: () =>
      import(
        '../../dynamic-components/search-box-container/search-box-container.module'
      ).then(m => m.SearchBoxContainerModule),
  },
  searchResults: {
    loadModule: () =>
      import(
        '../../dynamic-components/search-results-container/search-results-container.module'
      ).then(m => m.SearchResultsContainerModule),
  },
  feedback: {
    loadModule: () =>
      import('../../dynamic-components/feedback/feedback.module').then(
        m => m.FeedbackModule,
      ),
  },
  routeMap: {
    loadModule: () =>
      import('../../dynamic-components/route-map/route-map.module').then(
        m => m.RouteMapModule,
      ),
  },
  manageFlights: {
    loadModule: () =>
      import(
        '../../dynamic-components/manage-flights/manage-flights.module'
      ).then(m => m.ManageFlightsModule),
  },
  ManageFlightsEmail: {
    loadModule: () =>
      import(
        '../../dynamic-components/manage-flights-email/manage-flights-email.module'
      ).then(m => m.ManageFlightsEmailModule),
  },
  flightTracker: {
    loadModule: () =>
      import(
        '../../dynamic-components/flight-tracker/flight-tracker.module'
      ).then(m => m.FlightTrackerModule),
  },
  covidInfo: {
    loadModule: () =>
      import('../../dynamic-components/covid-info/covid-info.module').then(
        m => m.CovidInfoModule,
      ),
  },
  recentSearches: {
    loadModule: () =>
      import(
        '../../dynamic-components/recent-searches/recent-searches.module'
      ).then(m => m.RecentSearchModule),
  },
  pageHero: {
    loadModule: () =>
      import('../../dynamic-components/page-hero/page-hero.module').then(
        m => m.PageHeroModule,
      ),
  },
  button: {
    loadModule: () =>
      import('../../dynamic-components/button/button.module').then(
        m => m.ButtonModule,
      ),
  },
  signinReset: {
    loadModule: () =>
      import('../../dynamic-components/signin-reset/signin-reset.module').then(
        m => m.SignInResetModule,
      ),
  },
  signinNewPassword: {
    loadModule: () =>
      import(
        '../../dynamic-components/signin-new-password/siginin-new-password.module'
      ).then(m => m.SignInNewPasswordModule),
  },
};

export const dynamicComponentMap = new Map(Object.entries(_map));
export type DynamicComponentMap = typeof dynamicComponentMap;
