import { BestFareDay } from '../../core';
import {
  BffForm,
  CalendarMonth,
} from '../../feature/bff/bff.component-store.types';
import { CalendarResponse } from './types';

export interface CalendarComponentState {
  content: CalendarResponse;
  form: BffForm;
  calendarIndex: number;
  maxSeats: number;
  maxDate: Date;
  isLastMonthOfCalendar: boolean;
  iOS: boolean;
  legalCopy: string;
}

export const calendarComponentInitialState: CalendarComponentState = {
  form: undefined,
  content: undefined,
  iOS: undefined,
  isLastMonthOfCalendar: undefined,

  maxDate: undefined,
  maxSeats: undefined,
  calendarIndex: undefined,
  legalCopy: undefined,
};

export interface CalendarComponentViewModel extends CalendarComponentState {
  id: string;
  minHeight: string;
  calendarMonth: CalendarMonth;
  showLoadButton: boolean;
  hasFlights: boolean;
  daysOfMonthGroupedByWeek: BestFareDay[][];
  isOneWay: boolean;
  isFirstLoadedCalendarMonth: boolean;
}
