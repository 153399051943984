import { Action } from '@ngrx/store';

export enum AnalyticsActions {
  LOGIN_BUTTON_CLICKED = '[Analytics Effect] Login Button Clicked',
  LOGIN_FAILED = '[Analytics Effect] Login Failed',
  TRACK_SUBMIT_CALLED = '[Analytics Effect] Track Submit Called',
  TB_LOGIN_DETAILS = '[Analytics Effect] TB Login Details',
  FORGOT_PASSWORD_DETAILS = '[Analytics Effect] Forgot Password Details',
  FORGOT_PASSWORD_SERVICE_ERROR = '[Analytics Effect] Forgot Password Service Error',
  BFF_ANALYTICS_EVENT_CALL = '[Analytics Effect] BFF Analytics Event Called',
  HEADER_INIT_EVENT_CALL = '[Analytics Effect] Header Component Init',
  HEADER_FINISHED_INIT_EVENT_CALL = '[Analytics Effect] Header Component Finished Init',
  BOOKER_LOAD_START = '[Analytics Effect] Booker Component Started Init',
  BOOKER_LOAD_DONE = '[Analytics Effect] Booker Component Finished Init',
  CONTENT_LOAD_START = '[Analytics Effect] Skeleton Started Init',
  PRIORITY_CONTENT_LOAD_DONE = '[Analytics Effect] Skeleton Finished Init',
  ALL_CONTENT_LOAD_DONE = '[Analytics Effect] All Content Loaded',
  LOG_PROFILE_INFO = '[Analytics Effect] Log Profile Information on FS',
}

export class LoginButtonClicked implements Action {
  readonly type = AnalyticsActions.LOGIN_BUTTON_CLICKED;
  constructor(public payload: string) {}
}

export class LoginFailed implements Action {
  readonly type = AnalyticsActions.LOGIN_FAILED;
  constructor(public payload: string) {}
}

export class TrackSubmitEventCall implements Action {
  readonly type = AnalyticsActions.TRACK_SUBMIT_CALLED;
}

export class TbLoginDetails implements Action {
  readonly type = AnalyticsActions.TB_LOGIN_DETAILS;
  constructor(public payload: string) {}
}

export class ForgotPasswordDetails implements Action {
  readonly type = AnalyticsActions.FORGOT_PASSWORD_DETAILS;
  constructor(public payload: string) {}
}

export class ForgotPasswordServiceErrorTracking implements Action {
  readonly type = AnalyticsActions.FORGOT_PASSWORD_SERVICE_ERROR;
  constructor(public payload: string) {}
}

export class BffAnalyticsEventCall implements Action {
  readonly type = AnalyticsActions.BFF_ANALYTICS_EVENT_CALL;
  constructor(public payload: any) {}
}

export class HeaderStart implements Action {
  readonly type = AnalyticsActions.HEADER_INIT_EVENT_CALL;
  constructor() {}
}
export class HeaderLoadFinished implements Action {
  readonly type = AnalyticsActions.HEADER_FINISHED_INIT_EVENT_CALL;
  constructor() {}
}

export class BookerLoadStart implements Action {
  readonly type = AnalyticsActions.BOOKER_LOAD_START;
  constructor(public payload: string) {}
}
export class BookerLoadFinished implements Action {
  readonly type = AnalyticsActions.BOOKER_LOAD_DONE;
  constructor(public payload: string) {}
}
export class ContentLoadStart implements Action {
  readonly type = AnalyticsActions.CONTENT_LOAD_START;
  constructor() {}
}
export class PriorityContentLoadFinish implements Action {
  readonly type = AnalyticsActions.PRIORITY_CONTENT_LOAD_DONE;
  constructor() {}
}
export class AllContentLoadFinish implements Action {
  readonly type = AnalyticsActions.ALL_CONTENT_LOAD_DONE;
  constructor() {}
}

export class LogProfileInfoEventCall implements Action {
  readonly type = AnalyticsActions.LOG_PROFILE_INFO;
  constructor(public payload: boolean) {}
}

export type AnaylticsActionsUnion =
  | LoginButtonClicked
  | LoginFailed
  | TrackSubmitEventCall
  | TbLoginDetails
  | ForgotPasswordDetails
  | ForgotPasswordServiceErrorTracking
  | BffAnalyticsEventCall
  | HeaderStart
  | HeaderLoadFinished
  | BookerLoadStart
  | BookerLoadFinished
  | ContentLoadStart
  | PriorityContentLoadFinish
  | AllContentLoadFinish
  | LogProfileInfoEventCall;
