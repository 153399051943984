import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JBBodyTextModule } from '@shared/jb-body-text';
import { UIModule } from '@shared/ui';

import { TbBottomSectionModule } from '../shared/tb-bottom-section/tb-bottom-section.module';
import { TbSignedOutComponent } from './tb-signed-out.component';

const MODULES = [
  CommonModule,
  UIModule,
  JBBodyTextModule,
  TbBottomSectionModule,
];

const DECLARATIONS = [TbSignedOutComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class TbSignedOutModule {}
