import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  addDays,
  addMinutes,
  addYears,
  getDate,
  getDay,
  getMonth,
  getYear,
  isLeapYear,
  parseISO,
  startOfDay,
} from 'date-fns';
import { CookieOptions, CookieService } from 'ngx-cookie';

import { AppConfigService } from '../app-config';
import { DateService } from '../date/date.service';
import { DOCUMENT } from '../injection-tokens';
import { IS_USER_BIRTHDAY, IS_USER_MOSAIC } from './cookie-storage.keys';

@Injectable({ providedIn: 'root' })
export class CookieStorageService {
  COOKIE_DOMAIN: string;
  cookieOptions: CookieOptions;
  isSecure: boolean;

  // nearby airports expires after 6 hours
  nearbyAirportsExpiration = addMinutes(new Date(), 360).toString();

  private appConfig: AppConfigService;
  private cookieService: CookieService;
  private document: Document;
  private dateService: DateService;

  constructor(@Inject(PLATFORM_ID) public platformId: Object) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.appConfig = inject(AppConfigService);
    this.cookieService = inject(CookieService);
    this.document = inject(DOCUMENT);
    this.dateService = inject(DateService);
    this.init();
  }

  init() {
    this.isSecure = true;
    this.COOKIE_DOMAIN = this.setCookieDomain(this.document);
    if (
      this.appConfig.environment === 'local-dev' ||
      this.appConfig.environment === 'local-dev-no-ssr'
    ) {
      this.isSecure = false;
    }
    this.cookieOptions = {
      domain: this.COOKIE_DOMAIN,
      path: '/',
      secure: this.isSecure,
    };
  }

  setCookieDomain(doc: any): string {
    if (isPlatformServer(this.platformId)) {
      // server side only code, req for ssr
      return null;
    }
    let result: string;
    const hostname = doc.location.hostname;

    // If the hostname is localhost or an IP address, use the hostname directly
    if (
      hostname === 'localhost' ||
      /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)
    ) {
      result = hostname;
    } else {
      // If the hostname has subdomains, set the domain to the root domain and all subdomains
      const parts = hostname.split('.');
      if (parts.length >= 2) {
        result = `.${parts.slice(-2).join('.')}`;
      } else {
        // If the hostname doesn't have subdomains, use the hostname directly
        result = hostname;
      }
    }

    return result;
  }

  removeCookie(cookieKey: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.remove(cookieKey, this.cookieOptions);
  }

  setCookiePolicyDialogCookie() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put('jbCookiePolicy', 'checked', {
      ...this.cookieOptions,
      expires: addYears(new Date(), 1).toString(),
    });
  }

  setB6Cookie() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const tomorrowDate = this.dateService.getNewDate();
    tomorrowDate.setDate(this.dateService.getNewDate().getDate() + 1);

    const b6authCookieOption: CookieOptions = {
      ...this.cookieOptions,
      expires: tomorrowDate,
      sameSite: 'none',
    };
    this.cookieService.put(
      this.appConfig.okta.oktaAuthCookie,
      'true',
      b6authCookieOption,
    );
  }

  setCaptchaTokenCookie(token: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const date = new Date();
    date.setTime(
      date.getTime() + this.appConfig.widgets.captchaConfig.resolvedDuration,
    );
    const captchaCookieOption = {
      ...this.cookieOptions,
      expires: date,
    };
    this.cookieService.put('jbCaptchaToken', token, captchaCookieOption);
  }

  setCookie(key: string, value: string, expires?: Date) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put(key, value, {
      ...this.cookieOptions,
      expires,
    });
  }

  setCookieWithFullDomain(key: string, value: string, expires?: Date) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put(key, value);
  }

  getCookie(key: string) {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    return this.cookieService.get(key);
  }

  getCountryCodeCookie(): string {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    return (
      this.appConfig.overrides?.countryCode ||
      this.cookieService.get('jbCountryCode')
    );
  }

  setNearbyAirportsCookie(nearbyAirports: string[]) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put('jbNearbyAirports', JSON.stringify(nearbyAirports), {
      ...this.cookieOptions,
      expires: this.nearbyAirportsExpiration,
    });
  }

  getNearbyAirportsCookie(): string[] {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const cookieVal = this.cookieService.get('jbNearbyAirports');
    return cookieVal ? JSON.parse(cookieVal) : null;
  }

  setBaseAirportCookie(preferredAirport: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put(
      'base_airport',
      preferredAirport,
      this.cookieOptions,
    );
  }

  getBaseAirportCookie(): string {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    return this.cookieService.get('base_airport');
  }

  setTrueBlueCookie(
    points: string,
    fname: string,
    isMosaic: string,
    prefAirports: string[],
    membershipId: string,
  ) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const bff = isMosaic.toLowerCase() === 'true' ? '&TrueBlueBFF=true' : '';
    let airport = '';
    if (!!prefAirports && prefAirports.length >= 1) {
      airport = `&PreferredAirports=${prefAirports[0]}`;
    }

    const value = `TrueBluePoints=${points}&FirstName=${fname}${bff}${airport}\
&MembershipID=${membershipId}`;

    this.cookieService.put('jbTrueBlueCookie', value, {
      ...this.cookieOptions,
      storeUnencoded: true,
    });
  }

  setTrackingCookie(keyName: string, clickTrackingValue: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put(keyName, clickTrackingValue, {
      ...this.cookieOptions,
    });
  }

  setProfileInfoCookie(name: string, type: any) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    // a year from now on expiry
    const today = new Date();
    const dd = getDay(today);
    const mm = getMonth(today) + 1;
    const yyyy = getYear(today) + 1;
    const date = `${mm}-${dd}-${yyyy}`;
    this.cookieOptions.expires = date;
    this.cookieService.put(name, type, {
      ...this.cookieOptions,
    });
  }

  setAnalyticsPrevPageCookie(previousPage: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put('gpv_pn', previousPage, {
      ...this.cookieOptions,
    });
  }

  getAnalyticsPrevPageCookie(): string {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const cookieVal = this.cookieService.get('gpv_pn');
    return cookieVal ? cookieVal : undefined;
  }

  setIsBirthdayCookie(birthdayStr: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const birthdayDate = parseISO(birthdayStr);
    const now = this.getNow();
    const tomorrow = startOfDay(addDays(now, 1));
    let flag;

    if (
      isLeapYear(birthdayDate) &&
      !isLeapYear(now) &&
      getMonth(birthdayDate) === 1 &&
      getDate(birthdayDate) === 29
    ) {
      const dayAfterBirthday = addDays(birthdayDate, 1);
      flag =
        getDate(now) === getDate(dayAfterBirthday) &&
        getMonth(now) === getMonth(dayAfterBirthday);
    } else {
      flag =
        getDate(now) === getDate(birthdayDate) &&
        getMonth(now) === getMonth(birthdayDate);
    }

    this.cookieService.put(IS_USER_BIRTHDAY, flag.toString(), {
      ...this.cookieOptions,
      expires: tomorrow,
    });
  }

  setUserMemberStatusCookie(isMosaic: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.cookieService.put(IS_USER_MOSAIC, isMosaic.toLowerCase(), {
      ...this.cookieOptions,
    });
  }

  shouldEnableCaptcha() {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const blacklistedCountries =
      this.appConfig.widgets.captchaConfig.blacklistedCountries
        .split(',')
        .map(cc => cc.toLocaleLowerCase());
    const countryCode = this.getCountryCodeCookie();
    const captchaToken = this.getCookie('jbCaptchaToken');
    const lowerCaseCode = !!countryCode ? countryCode.toLowerCase() : '';
    if (
      captchaToken == null &&
      !!countryCode &&
      this.appConfig.loadCaptchaWidget
    ) {
      return blacklistedCountries.indexOf(lowerCaseCode) !== -1;
    }
    return false;
  }

  private getNow() {
    return new Date();
  }
}

// Shared across server & browser.
export const clearPersonalizationCookies = (clearCb: (key: string) => any) => {
  [IS_USER_MOSAIC, IS_USER_BIRTHDAY].forEach(clearCb);
};
