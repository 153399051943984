import { AuthProfile } from '@store/auth/types';

import { TrueBlueProfile } from '../types';

export function adaptProfile(authProfile: AuthProfile): TrueBlueProfile {
  const { fname, lname, membershipid, birthDate, points } = authProfile;

  return {
    firstName: fname,
    lastName: lname,
    loyalty: [
      {
        amount: `${points}`,
        membershipId: membershipid,
      },
    ],
    dateOfBirth: birthDate,
  };
}
