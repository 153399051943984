import moize from 'moize';
import { keys, toUpper } from 'ramda';
/**
 * Returns Object with Uppercase keys.
 * @param {any} obj object
 * @returns {any} Object with a uppercase key
 */
export const toUpperObject = moize((obj: any): any => {
  const objKeys = keys(obj);
  const upperCaseObject = {};
  objKeys.forEach((key: any) => {
    upperCaseObject[toUpper(key)] = obj[key];
  });
  return upperCaseObject;
});
