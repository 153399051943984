<div class="sgn-container bb border-color-medium-gray tc tl-ns">
  <ng-container *ngFor="let item of viewData">
    <a
      class="sgn-item"
      [class.bg-light-gray]="!item.isExternalURL"
      [jbSitemapLinker]="item.href"
      [target]="item.newWindow"
    >
      <img [src]="item.logo" [alt]="item.altText" height="24">
    </a>
  </ng-container>
</div>
