<div
  *ngIf="vm$ | async as vm"
  class="flex flex-row relative bg-white jb-max-width center"
>
  <div
    class="relative mr4"
    [ngClass]="
      vm.isOneWay ? 'col-8-l col-8-m col-3-s' : 'col-6-l col-6-m col-2-s'
    "
  >
    <jb-progress-indicator-block
      class="db w-100"
      [ngClass]="{ pointer: !!vm.departBff }"
      [bff]="vm.departBff"
      [content]="vm.content"
      [windowSize]="vm.windowSize"
      [isPoints]="vm.bookWithPoints"
      [isEditable]="!!vm.departBff"
      [isActive]="!vm.departBff || vm.isOneWay"
      (click)="clearSelectedFares()"
      (keypress)="handleKeyPress($event, clearSelectedFares)"
      [type]="progressIndicatorType.DEPART"
      [maxSeats]="vm.maxSeats"
      role="button"
      aria-label="reset selection"
      tabindex="{{ vm.isDepartBffFocusable | jbTabindex }}"
    >
    </jb-progress-indicator-block>
    <div class="absolute top-2 right--1">
      <!-- replace with Edit jb-icon once jb-component library issue is fix -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10.9 6.2"
        height="10"
        width="10"
        focusable="false"
      >
        <path
          d="M10.6 2.5L8.4.2c-.2-.2-.6-.2-.8 0s-.2.5 0 .7l1.7 1.7H.5c-.2 0-.5.2-.5.5s.2.5.5.5h8.8L7.6 5.3c-.1.2-.1.5 0 .7.1.1.3.1.4.1s.3 0 .4-.1l2.3-2.3c.3-.4.3-.9-.1-1.2z"
        ></path>
      </svg>
    </div>
  </div>
  <div
    class="return-block"
    [ngClass]="
      vm.isOneWay ? 'col-4-l col-4-m col-1-s' : 'col-6-l col-6-m col-2-s'
    "
  >
    <jb-progress-indicator-block
      class="db w-100"
      [ngClass]="{ pointer: !!vm.departBff && vm.isOneWay }"
      [bff]="vm.returnBff"
      [content]="vm.content"
      [windowSize]="vm.windowSize"
      [isPoints]="vm.bookWithPoints"
      [isActive]="!!vm.departBff && !vm.isOneWay"
      (keypress)="handleKeyPress($event, makeRoundTrip)"
      (click)="makeRoundTrip()"
      [disable]="!vm.departBff || vm.isOneWay"
      [type]="
        vm.isOneWay
          ? progressIndicatorType.RETURN_ONE_WAY
          : progressIndicatorType.RETURN_RT
      "
      [maxSeats]="vm.maxSeats"
      role="button"
      aria-label="add return date"
      tabindex="{{ vm.isReturnBffFocusable | jbTabindex }}"
    >
    </jb-progress-indicator-block>
  </div>
</div>
