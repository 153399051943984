/* eslint-disable complexity */
import { OriginsActions, OriginsActionsUnion } from './origins.actions';
import { OriginsState } from './types';

export const initialOriginsState: OriginsState = {
  odService: {
    didAnyEndpointFail: false,
    individualEndpointsStatuses: {
      didCountriesEndpointFail: false,
      didOriginsEndpointFail: false,
      didDestinationsEndpointFail: false,
      didRegionsEndpointFail: false,
      didAirportsEndpointFail: false,
    },
  },
  airports: { list: [], map: undefined },
  countries: [],
  originAirports: {},
  destinationAirports: {},
  regions: {},
  nearbyAirports: null,
};

export function originsReducer(
  state = initialOriginsState,
  action: OriginsActionsUnion,
) {
  const {
    odService: {
      individualEndpointsStatuses: {
        didCountriesEndpointFail,
        didOriginsEndpointFail,
        didDestinationsEndpointFail,
        didRegionsEndpointFail,
        didAirportsEndpointFail,
      },
    },
  } = state;
  switch (action.type) {
    case OriginsActions.LOAD_COUNTRIES:
      return {
        ...state,
        countries: [...(action['error'] ? state.countries : action.payload)],
        odService: {
          didAnyEndpointFail:
            !!action['error'] ||
            didOriginsEndpointFail ||
            didDestinationsEndpointFail ||
            didRegionsEndpointFail ||
            didAirportsEndpointFail,
          individualEndpointsStatuses: {
            ...state.odService.individualEndpointsStatuses,
            didCountriesEndpointFail: !!action['error'],
          },
        },
      };
    case OriginsActions.REQUEST_ORIGIN_AIRPORTS:
      return {
        ...state,
        originAirports: {
          ...state.originAirports,
          [action.payload.id]: [],
        },
      };
    case OriginsActions.LOAD_ORIGIN_AIRPORTS:
      return {
        ...state,
        originAirports: {
          ...state.originAirports,
          [action.payload.id]: [
            ...(action['error']
              ? state.originAirports[action.payload.id] || []
              : action.payload.countryWithAirports),
          ],
        },
        odService: {
          didAnyEndpointFail:
            didCountriesEndpointFail ||
            !!action['error'] ||
            didDestinationsEndpointFail ||
            didRegionsEndpointFail ||
            didAirportsEndpointFail,
          individualEndpointsStatuses: {
            ...state.odService.individualEndpointsStatuses,
            didOriginsEndpointFail: !!action['error'],
          },
        },
      };
    case OriginsActions.LOAD_DESTINATION_AIRPORTS:
      return {
        ...state,
        destinationAirports: {
          ...state.destinationAirports,
          [action.payload.id]: [
            ...(action['error']
              ? state.destinationAirports[action.payload.id] || []
              : action.payload.countryWithAirports),
          ],
        },
        odService: {
          didAnyEndpointFail:
            didCountriesEndpointFail ||
            didOriginsEndpointFail ||
            !!action['error'] ||
            didRegionsEndpointFail ||
            didAirportsEndpointFail,
          individualEndpointsStatuses: {
            ...state.odService.individualEndpointsStatuses,
            didDestinationsEndpointFail: !!action['error'],
          },
        },
      };
    case OriginsActions.LOAD_AIRPORTS: {
      return {
        ...state,
        airports: action['error'] ? state.airports : action.payload,
        odService: {
          didAnyEndpointFail:
            didCountriesEndpointFail ||
            didOriginsEndpointFail ||
            didDestinationsEndpointFail ||
            didRegionsEndpointFail ||
            !!action['error'],
          individualEndpointsStatuses: {
            ...state.odService.individualEndpointsStatuses,
            didAirportsEndpointFail: !!action['error'],
          },
        },
      };
    }
    case OriginsActions.LOAD_REGIONS:
      return {
        ...state,
        regions: {
          ...(action['error'] ? state.regions : action.payload),
        },
        odService: {
          didAnyEndpointFail:
            didCountriesEndpointFail ||
            didOriginsEndpointFail ||
            didDestinationsEndpointFail ||
            !!action['error'] ||
            didAirportsEndpointFail,
          individualEndpointsStatuses: {
            ...state.odService.individualEndpointsStatuses,
            didRegionsEndpointFail: !!action['error'],
          },
        },
      };
    case OriginsActions.LOAD_NEARBY_AIRPORTS:
      return {
        ...state,
        nearbyAirports: action['error'] ? [] : action.payload,
      };
    default:
      return state;
  }
}
