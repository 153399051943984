<jb-dialog
  dialogType="content"
  contentModalWidth="100%"
  contentModalMaxWidth="1440px"
  contentModalHeight="80%"
  contentModalMaxHeight="800px"
>
  <jb-dialog-header>AB Tests for "{{ pathName }}"</jb-dialog-header>
  <jb-dialog-content>
    <div *ngFor="let campaign of selectedPageCampaigns; let i = index">
      <div class="mb3">
        <jb-expandable-container [isExpanded]="openedContainers[i]">
          <div class="pa4">
            <div
              [ngClass]="
                (isMobile$ | async)
                  ? 'flex-column items-start'
                  : 'flex-row items-end'
              "
              class="flex justify-between w-100"
            >
              <div>
                <h4 class="self-center">
                  {{ campaign.name }}
                </h4>

                <p *ngIf="errors[i]" class="copy-xs red">Invalid Experience</p>
              </div>
              <form
                [ngClass]="
                  (isMobile$ | async) ? 'justify-between w-100 mt2' : ''
                "
                class="flex"
                (submit)="updateExperience($event, campaign, i)"
              >
                <div></div>
                <jb-form-field-container type="primary" class="flex">
                  <input
                    class="input-width"
                    jbInput
                    type="text"
                    [value]="campaign.cookieValue"
                  />
                  <button
                    jbButton
                    theme="ctaPrimary"
                    [isPaired]="true"
                    type="submit"
                  >
                    <jb-icon name="checkmark"></jb-icon>
                  </button>
                </jb-form-field-container>

                <div class="dib b--medium-gray pl2">
                  <jb-expandable-button
                    class="dib"
                    [value]="openedContainers[i]"
                    (toggle)="changeToggle($event, i)"
                  ></jb-expandable-button>
                </div>
              </form>
            </div>
            <jb-expandable-section [isExpanded]="openedContainers[i]">
              <div class="mt3">
                <h3>Details</h3>
                <pre>{{ campaign | json }}</pre>
              </div>
            </jb-expandable-section>
          </div>
        </jb-expandable-container>
      </div>
    </div>
  </jb-dialog-content>
</jb-dialog>
