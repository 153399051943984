import { FlightTrackerCopy } from '@dynamic-components/flight-tracker/types/flight-tracker-copy.type';
import { Action } from '@ngrx/store';
import { BookerResponse } from '@shared/booker';
import { CountryWithAirports } from '@store/origins/types';

import { FlightResults } from './types';

export enum FlightTrackerActions {
  REQUEST_FLIGHT_STATUS_BY_NUMBER = '[Flight Status Component] Request flight status by flight number',
  REQUEST_FLIGHT_STATUS_BY_NUMBER_POLL = '[Flight Status Component] Request flight status by flight number and poll',
  SET_FLIGHT_STATUS = '[Flight Status Component] Set flight status',
  SET_TAB = '[Flight Status Component] Set selected tab id',
  FORM_SUBMITTING = '[Flight Status Component] Set form submission state',
  UPDATE_DESTINATION_AIRPORTS = '[Flight Status Component] Update destination airports',
  UPDATE_ORIGIN_AIRPORTS = '[Flight Status Component] Update origin airports',
  REQUEST_FLIGHT_STATUS_BY_ROUTE = '[Flight Status Component] Request flight status by route',
  UPDATE_FLIGHT_TRACKER_RESPONSE = '[Flight Status Component] Update flight tracker static text response',
  UPDATE_BOOKER_RESPONSE = '[Flight Status Component] Update booker static text response',
}

export class RequestFlightStatusByNumber implements Action {
  readonly type = FlightTrackerActions.REQUEST_FLIGHT_STATUS_BY_NUMBER;
  constructor(
    public payload: {
      flightDate: string;
      flightNumber: string;
    },
  ) {}
}

export class RequestFlightStatusByNumberPoll implements Action {
  readonly type = FlightTrackerActions.REQUEST_FLIGHT_STATUS_BY_NUMBER_POLL;
  constructor(
    public payload: {
      flightDate: string;
      flightNumber: string;
      pollingInterval: number;
    },
  ) {}
}

export class RequestFlightStatusByRoute implements Action {
  readonly type = FlightTrackerActions.REQUEST_FLIGHT_STATUS_BY_ROUTE;
  constructor(
    public payload: {
      departureDate: string;
      originCode: string;
      destinationCode: string;
    },
  ) {}
}

export class SetFlightStatus implements Action {
  readonly type = FlightTrackerActions.SET_FLIGHT_STATUS;
  constructor(public payload: FlightResults) {}
}

export class SetTabId implements Action {
  readonly type = FlightTrackerActions.SET_TAB;
  constructor(public payload: number) {}
}

export class FormSubmittingAction implements Action {
  readonly type = FlightTrackerActions.FORM_SUBMITTING;
  constructor(public payload: boolean) {}
}

export class UpdateOriginAirports implements Action {
  readonly type = FlightTrackerActions.UPDATE_ORIGIN_AIRPORTS;
  constructor(public payload: CountryWithAirports[]) {}
}

export class UpdateDestinationAirports implements Action {
  readonly type = FlightTrackerActions.UPDATE_DESTINATION_AIRPORTS;
  constructor(public payload: CountryWithAirports[]) {}
}

export class UpdateFlightTrackerResponse implements Action {
  readonly type = FlightTrackerActions.UPDATE_FLIGHT_TRACKER_RESPONSE;
  constructor(public payload: FlightTrackerCopy) {}
}

export class UpdateBookerResponse implements Action {
  readonly type = FlightTrackerActions.UPDATE_BOOKER_RESPONSE;
  constructor(public payload: BookerResponse) {}
}

export type FlightTrackerActionsUnion =
  | RequestFlightStatusByNumber
  | RequestFlightStatusByNumberPoll
  | RequestFlightStatusByRoute
  | SetFlightStatus
  | SetTabId
  | FormSubmittingAction
  | UpdateDestinationAirports
  | UpdateOriginAirports
  | UpdateFlightTrackerResponse
  | UpdateBookerResponse;
