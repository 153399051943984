import { BasicTemplateComponent } from '@dynamic-templates/basic-template';

import { CMSSiteMap, ParsedSiteMap } from '../types';

export function parseSitemap(routesData: CMSSiteMap[]): ParsedSiteMap[] {
  return routesData.reduce((memo, route) => {
    return memo.concat(getPageRoutes(route));
  }, []);
}

function getRoute(page: CMSSiteMap, pagePath: string) {
  return {
    path: pagePath,
    component: BasicTemplateComponent,
    data: {
      personalization: page.personalization,
      personalizationTrait: page.personalizationTrait,
      pageName: page.pageName,
      path: `/${pagePath}`,
      template: page.template,
      endpoint: page.endpoint,
    },
  };
}

function getPageRoutes(page: CMSSiteMap): ParsedSiteMap[] {
  const pagePath = page.path.replace(/^\//, '');
  const lowerCasePagePath = pagePath.toLowerCase();
  let routes: ParsedSiteMap[] = [getRoute(page, pagePath)];
  if (pagePath !== lowerCasePagePath) {
    routes.push(getRoute(page, lowerCasePagePath));
  }

  if (page.children) {
    routes = routes.concat(
      page.children.reduce((memo: ParsedSiteMap[], currentPage: CMSSiteMap) => {
        return memo.concat(getPageRoutes(currentPage));
      }, []),
    );
  }

  return routes;
}
