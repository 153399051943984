import { path } from 'ramda';

import { enterNewPasswordErrorMessages } from './errors/enter-new-password-error-messages';
import { expiredPassword } from './errors/enter-new-password-predefined-errors';
import { ResetPasswordErrorState } from './types';

function isExpiredResetLink(error) {
  return (
    path(['error', 'error', 'code'])(error) === 'RESETPASSWORD_LINK_EXPIRED'
  );
}

export function transformPasswordRequestError(error): ResetPasswordErrorState {
  // DOT-5579
  if (isExpiredResetLink(error)) {
    return expiredPassword;
  }

  return {
    httpStatus: error.status,
    errorMessage:
      enterNewPasswordErrorMessages[error.status] ||
      enterNewPasswordErrorMessages.generic,
  };
}
