<div
  class="subnav-container jb-max-width pv2-ns center relative cf"
  [ngClass]="{ 'page-margin': !isMobile }"
>
  <ng-container [ngSwitch]="subNavSection.template">
    <jb-book-subnav-template
      [id]="subnavWrapperId"
      *ngSwitchCase="'BOOK'"
      [section]="subNavSection"
      [isMobile]="isMobile"
    ></jb-book-subnav-template>

    <jb-travel-info-subnav-template
      [id]="subnavWrapperId"
      *ngSwitchCase="'TRAVEL_INFO'"
      [section]="subNavSection"
      [isMobile]="isMobile"
    ></jb-travel-info-subnav-template>
  </ng-container>
</div>
