import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppConfigService } from '@core/app-config';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { PageData } from '@store/global/types';

@Injectable({ providedIn: 'root' })
export class SeoService {
  constructor(
    private meta: Meta,
    private appConfig: AppConfigService,
  ) {}

  updatePageDescription(description: string) {
    const defaultCopy =
      'JetBlue offers flights to 90+ destinations with free inflight entertainment, free brand-name snacks and drinks, lots of legroom and award-winning service.';
    this.meta.updateTag({
      name: 'description',
      content: !!description ? description : defaultCopy,
    });
  }

  updateRobotsNoIndex(pageData: PageData) {
    const deIndexTag = { name: 'robots', content: 'noindex' };
    if (
      booleanStringCheck(pageData?.hidePageinSEO) ||
      !this.appConfig.isProdEnv
    ) {
      this.meta.addTag(deIndexTag);
    } else if (
      this.appConfig.isProdEnv &&
      !!this.meta.getTag("name='robots'")
    ) {
      this.meta.removeTag("name='robots'");
    }
  }

  addOpenGraphMetaData(pageData: PageData) {
    const tags = this.getOpenGraphTags(pageData);
    tags.forEach(tag => {
      const { content, property } = tag;
      if (
        content === undefined &&
        !!this.meta.getTag(`property='${property}'`)
      ) {
        this.meta.removeTag(`property='${property}'`);
      }
      if (content && !!this.meta.getTag(`property='${property}'`)) {
        this.meta.updateTag(tag);
      }
      if (content && !this.meta.getTag(`property='${property}'`)) {
        this.meta.addTag(tag);
      }
    });
  }

  getOpenGraphTags(pageData: PageData) {
    const { description, video, title, imageAlt, image } = pageData;
    const openGraphTags = [
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'og:image',
        content: image,
      },
      {
        property: 'og:video',
        content: video,
      },
      {
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        property: 'twitter:image:alt',
        content: imageAlt,
      },
      {
        property: 'twitter:site',
        content: '@jetblue',
      },
    ];
    return openGraphTags;
  }
}
