import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  CONFIG_GUEST_CART_TOKEN,
  CONFIG_X_AUTH_TOKEN,
} from '@core/app-config/types/mocks/app-config-constants';
import {
  AppConfigService,
  CookieStorageService,
  EventsService,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  LocalStorageService,
  WindowService,
} from '@core/index';
import { HeaderService } from '@shared/header/header.service';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { AnalyticsFacade } from '@store/analytics/analytics.facade';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { CartFacade } from '@store/cart/cart.facade';
import { GlobalFacade } from '@store/global/global.facade';
import { ProfileFacade } from '@store/profile';
import { adaptProfile } from '@store/trueblue/api/profile.transform';
import { JbDialogService } from 'jb-component-library';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

import { LinkExternal } from '../ui';
import { parseImagePaths } from './../utils/parse-image-paths';
import { HeaderStyleService } from './header.component.service';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { HeaderInnerType } from './types/header.inner.type';

@Component({
  selector: 'jb-header',
  templateUrl: './header.component.html',
  host: {
    role: 'banner',
  },
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  private _destroy = inject(DestroyRef);

  headerResponse: HeaderInnerType;
  clm5: boolean;
  // will be removed as soon as 'Enroll a Child' link is a part of response
  enrollChildLink = {
    newWindow: '_self',
    type: 'BOT_LINK',
    title: 'Enroll a Child',
    href: 'https://trueblue.jetblue.com/group/trueblue/create-child-account',
  };
  rootImagePath = 'https://www.jetblue.com';

  hideTravelAlert$: Observable<boolean>;
  hideSuperGlobalNav$: Observable<boolean>;
  hideCountdownClock$: Observable<boolean>;

  @Input() hideTb = false;

  @ViewChild('headerTravelAlerts') travelAlerts: ElementRef;
  @ViewChild('firstFocusLink', { static: true }) firstFocusLink: ElementRef;
  @ViewChild('headerDesktop') headerDesktop: HeaderDesktopComponent;
  @ViewChild('headerMobile') headerMobile: HeaderMobileComponent;

  @Output() linkClicked = new EventEmitter<LinkExternal>();
  @Input() set showMenu(showMenu: string | boolean) {
    this._showMenu = booleanStringCheck(showMenu);
  }
  _showMenu = true;

  isMobile$ = this.windowService.isMobile;
  isModalOpen$ = this.headerService.isModalOpen$;

  constructor(
    public dialogService: JbDialogService,
    private appConfig: AppConfigService,
    private globalFacade: GlobalFacade,
    private windowService: WindowService,
    private cookieStorageService: CookieStorageService,
    private cartFacade: CartFacade,
    private authFacade: AuthFacade,
    private eventsService: EventsService,
    private analyticsFacade: AnalyticsFacade,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
    private headerStyleService: HeaderStyleService,
    private headerService: HeaderService,
    private cdRef: ChangeDetectorRef,
    private profileFacade: ProfileFacade,
    private localStorageService: LocalStorageService,
  ) {
    const template$ = this.globalFacade.template.pipe(
      filter(template => template && template.data), // run only when data property present on template
      map(template => template.data),
      shareReplay(),
    );

    this.hideSuperGlobalNav$ = template$.pipe(
      map(data => {
        return booleanStringCheck(data.hideSuperGlobalNav);
      }),
    );
    this.hideTravelAlert$ = template$.pipe(
      map(data => booleanStringCheck(data.hideTravelAlert)),
    );
    this.hideCountdownClock$ = template$.pipe(
      map(data => booleanStringCheck(data.hideCountdownClock)),
    );
  }

  ngAfterViewInit(): void {
    this.globalFacade.setRenderedHeader(true);
  }

  ngOnInit() {
    // DOT-9194 Temp fix for header not sticking to top of page
    // on true blue member portal we need to override inline styles
    // TODO: create task to sunset this
    if (this.isWebComponent === 'header') {
      this.headerStyleService.addStyleOverride();
    }

    const header$ = this.globalFacade.header;

    header$
      .pipe(takeUntilDestroyed(this._destroy), filter(Boolean))
      .subscribe((header: HeaderInnerType) => {
        if (header.travelAlerts) {
          this.globalFacade.setHeaderTravelAlertsContent(header.travelAlerts);
        }

        this.headerResponse = header;
        this.cdRef.detectChanges();
        if (this.rootImagePath) {
          parseImagePaths(this.headerResponse, this.rootImagePath);
        }
        this.analyticsFacade.headerLoadFinishedEventCall();
      });

    /* This subscription will be used to verify our cart status (Guest Cart and Auth User Cart) */
    combineLatest([
      this.authFacade.oktaToken,
      this.authFacade.isUserProperlyLoggedIn,
    ])
      .pipe(takeUntilDestroyed(this._destroy), distinctUntilChanged())
      .subscribe(([token, isUserProperlyLoggedIn]) => {
        // Get guestCartToken from our cookies, if any.
        const cartJwtToken = this.cookieStorageService.getCookie(
          CONFIG_GUEST_CART_TOKEN,
        );

        // Get xAuthToken from our Local Storage, if any.
        const xAuthToken: string =
          this.localStorageService.getItem(CONFIG_X_AUTH_TOKEN);

        /*
        If user is authenticated and fully loaded,
        remove guestCartToken from our cookies and fetch RWB Profile.
        RWB profile is used to convert guest cart to authenticated user cart.
        The magic is happening on our xAuthToken.
        The xAuthToken passed here is the same xAuthToken obtained by calling getCarts using cartJwtToken.
        To summarize, when calling the getCarts API as a guest,
        the header response will contain the xAuthToken. This xAuthToken is used here.
        The backend will handle updating the user cart.
        Note: This xAuthToken is optional. If we do not pass it here.
        The only thing will happen is that the cart will not be updated.
        */
        if (token && isUserProperlyLoggedIn) {
          this.cookieStorageService.removeCookie(CONFIG_GUEST_CART_TOKEN);
          this.profileFacade.dispatchFetchRwbProfile(token, xAuthToken);
        }

        /*
        If user is not authenticated and there is a cookie called cartJWTCookie.
        Fetch this guestCart using our getCarts API.
        The response from this call will create our xAuthToken.
        The xAuthToken will be stored in our LocalStorage.
        This xAuthToken is used to update the user's cart when calling "dispatchFetchRwbProfile".
        */
        if (!token && cartJwtToken) {
          this.cartFacade.dispatchFetchCartRequested({ cartJwtToken });
        }
      });

    this.eventsService
      .subscribeToEvent(
        EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_SUCCESS_INPUT_EVENT,
      )
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe((event: CustomEvent) => {
        if (event.detail?.accessToken) {
          // Dispatch an action to Lean Profile
          this.authFacade.fetchLeanProfile(event.detail.accessToken);
        }
      });

    this.eventsService
      .subscribeToEvent(
        EventsService.CUSTOM_EVENTS.JB_HEADER_LOGOUT_SUCCESS_INPUT_EVENT,
      )
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe((_: CustomEvent) => {
        this.authFacade.logoutUserForHeader();
      });

    /*
    Make sure that our Profile State is always up to date with the latest authProfile from our LocalStorage.
    Without this logic, we may lose the user points and data after navigating or after changing MFA.
    Ref: DOT-11197
    */
    this.authFacade.authProfile
      .pipe(
        takeUntilDestroyed(this._destroy),
        distinctUntilChanged(
          (prev, curr) => prev?.membershipid === curr?.membershipid,
        ),
        filter<AuthProfile>(Boolean),
      )
      .subscribe(authProfile => {
        this.profileFacade.profileLoaded(adaptProfile(authProfile));
      });

    this.authFacade.authProfileError
      .pipe(takeUntilDestroyed(this._destroy), filter(Boolean))
      .subscribe((error: any) => {
        this.eventsService.dispatchCustomEvent(
          EventsService.CUSTOM_EVENTS.JB_HEADER_AUTH_ERROR_OUTPUT_EVENT,
          error,
        );
      });

    this.windowService.isMobile
      .pipe(
        takeUntilDestroyed(this._destroy),
        filter(isMobile => !isMobile),
      )
      .subscribe(() => {
        // Close all mobile header modals/nav menu when screen size is not small
        this.headerService.closeAllModals();
      });
    this.clm5 = this.appConfig.clm5;
  }

  ngOnDestroy(): void {
    this.globalFacade.setRenderedHeader(false);
  }
}
