import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SuperGlobalNav } from '@shared/header/types/header.inner.type';

/**
 * @description Formatted component with links to other external related webpages in jetblue
 * to be used in the header component
 * @WhereIsItUsed Header component
 */
@Component({
  selector: 'jb-super-global-nav',
  templateUrl: './super-global-nav.component.html',
  styleUrls: ['./super-global-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperGlobalNavComponent {
  @Input() set data(sgv: SuperGlobalNav) {
    this.viewData = sgv.logos.map(item => ({
      ...item,
      isExternalURL: this.isExternalURL(item.href),
    }));
  }

  /**
   * Check if a url is in an external format.
   * @param {string} url Url to check
   * @returns {boolean}
   */
  isExternalURL(url: string): boolean {
    return /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i.test(url);
  }

  viewData = [];
}
