import { Injectable } from '@angular/core';
import {
  IDToken,
  IdxTransaction,
  TokenResponse,
  Tokens,
} from '@okta/okta-auth-js';
import { Observable } from 'rxjs';

import { StoreService } from '../shared/store.service';
import {
  AuthorizeUser,
  LeanProfileRequested,
  LoginUserOkta,
  LogoutUserForHeader,
  OktaClassicRedirect,
  OktaIdxRedirect,
  OktaPreExistingSessionCheckStatusUpdate,
  OktaRedirectToLogoutPage,
  OktaSetToken,
  OktaSetTokenFailure,
  OktaSetTokenSuccess,
  OktaUIDValue,
  StartLogoutUserOktaFlow,
  UserLoggedInOktaFlow,
  UserLoggedOutOktaSuccess,
  UserSessionIdled,
  UserSessionRequested,
} from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import {
  AuthProfile,
  AuthState,
  OktaAccessTokenEntry,
  OktaJWT,
  OktaPreExistingSessionCheckStatus,
} from './types';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  auth: Observable<AuthState> = this.storeService.getState(AuthSelectors.auth);
  oktaSessionCheckStatus = this.storeService.getState(
    AuthSelectors.oktaSessionCheckStatus,
  );
  authProfile: Observable<AuthProfile> = this.storeService.getState(
    AuthSelectors.authProfile,
  );
  leanProfileOktaStaging = this.storeService.getState(
    AuthSelectors.leanProfileOktaStaging,
  );
  oktaToken: Observable<OktaJWT> = this.storeService.getState(
    AuthSelectors.oktaToken,
  );
  tbNumber: Observable<string> = this.storeService.getState(
    AuthSelectors.tbNumber,
  );
  profilePictureBlob = this.storeService.getState(
    AuthSelectors.profilePictureBlob,
  );
  error = this.storeService.getState(AuthSelectors.error);
  loginError = this.storeService.getState(AuthSelectors.loginError);

  uid: Observable<string> = this.storeService.getState(AuthSelectors.uid);
  accessToken: Observable<OktaAccessTokenEntry> = this.storeService.getState(
    AuthSelectors.accessToken,
  );

  isUserProperlyLoggedIn: Observable<boolean> = this.storeService.getState(
    AuthSelectors.userLoggedInOktaFlow,
  );

  isProfileLoaded: Observable<boolean> = this.storeService.getState(
    AuthSelectors.userProfileLoaded,
  );

  profileIsPending: Observable<boolean> = this.storeService.getState(
    AuthSelectors.isFetchingProfile,
  );

  authProfileError: Observable<any> = this.storeService.getState(
    AuthSelectors.authProfileError,
  );

  oktaError: Observable<any> = this.storeService.getState(
    AuthSelectors.oktaError,
  );

  idToken: Observable<IDToken> = this.storeService.getState(
    AuthSelectors.idToken,
  );

  constructor(private storeService: StoreService) {}

  loginUser(email: string, password: string) {
    this.storeService.dispatchAction(new LoginUserOkta({ email, password }));
  }

  redirectToLogoutPageOktaFlow() {
    this.storeService.dispatchAction(new OktaRedirectToLogoutPage());
  }

  logoutUserOktaOrLegacyGuard() {
    this.redirectToLogoutPageOktaFlow();
  }

  startLogoutUserOktaFlow() {
    this.storeService.dispatchAction(new StartLogoutUserOktaFlow());
  }

  setOktaUID(uid: string) {
    this.storeService.dispatchAction(new OktaUIDValue(uid));
  }

  authorizeUser() {
    this.storeService.dispatchAction(new AuthorizeUser());
  }

  setToken(sessionId?: string) {
    this.storeService.dispatchAction(new OktaSetToken(sessionId));
  }

  setTokenSuccess(res: TokenResponse | IdxTransaction | { tokens: Tokens }) {
    this.storeService.dispatchAction(new OktaSetTokenSuccess(res));
  }

  setTokenFailure(err) {
    this.storeService.dispatchAction(new OktaSetTokenFailure(err));
  }

  userLoggedOutOktaSuccess() {
    this.storeService.dispatchAction(new UserLoggedOutOktaSuccess());
  }

  oktaPreExistingSessionCheckUpdate(status: OktaPreExistingSessionCheckStatus) {
    this.storeService.dispatchAction(
      new OktaPreExistingSessionCheckStatusUpdate(status),
    );
  }

  dispatchedUserSessionIdledAction(): void {
    this.storeService.dispatchAction(new UserSessionIdled());
  }

  dispatchedUserSessionRequestAction(): void {
    this.storeService.dispatchAction(new UserSessionRequested());
  }

  /*
  Effect userLoggedInOkta$ will trigger this facade.
  It will accept both ways of auth (classic and Idx)
  */
  userLoggedInWithOkta(
    authResponse: {
      tokens?: Tokens;
    } & { sessionToken?: string },
  ): void {
    this.storeService.dispatchAction(new UserLoggedInOktaFlow(authResponse));
  }

  idxRedirect(authResponse: { tokens?: Tokens }) {
    return new OktaIdxRedirect(authResponse);
  }

  sessionCookieRedirect(sessionToken: string) {
    return new OktaClassicRedirect(sessionToken);
  }

  fetchLeanProfile(accessToken: string): void {
    this.storeService.dispatchAction(new LeanProfileRequested(accessToken));
  }

  logoutUserForHeader(): void {
    this.storeService.dispatchAction(new LogoutUserForHeader());
  }
}
